import ShieldExclamation from 'src/assets/icons/appetite-lite/shield-exclamation.svg?react';
import TelematicsData from 'src/assets/icons/appetite-lite/telematics-data.svg?react';
import { OtherConsiderationsProps } from '../constants/types';

const considerations = (score: string) => [
  {
    Icon: TelematicsData,
    title: 'Telematics data and visibility',
    description:
      score === 'Preferred' || score === 'Acceptable'
        ? 'Telematics is a better predictor of risk! We’d like to see Telematics data that looks just as good as the rest of the account.'
        : 'Telematics is a better predictor of risk. A clean driver behavior history could improve the outlook on this account.',
  },
  {
    Icon: ShieldExclamation,
    title: '5 additional risk factors',
    description:
      'We also consider factors such as power unit growth, MVRs, AL loss severity, and geographical exposure.',
  },
];

const OtherConsiderations = ({ score }: OtherConsiderationsProps) => {
  return (
    <div className="px-4 py-2 space-y-4">
      <div className="mb-2 text-xl font-bold text-text-primary">
        Other considerations
      </div>
      <p className="text-sm text-text-primary">
        Underwriting will consider additional factors once the full application
        is complete.
      </p>
      <div className="space-y-4">
        {considerations(score).map(({ Icon, title, description }, index) => (
          <div key={`consideration-${index}`}>
            <div className="flex items-start">
              <Icon className="flex-shrink-0 mr-2" />
              <div>
                <div className="flex items-center mt-1 mb-4">
                  <div className="text-sm font-bold">{title}</div>
                </div>
                <p className="text-xs">{description}</p>
              </div>
            </div>
            {index < considerations(score).length - 1 && (
              <hr className="my-4" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherConsiderations;
