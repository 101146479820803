import {
  Select,
  FormControl,
  Button,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { Dialog, InputWithLabel, EMAIL_REGEX } from '@nirvana/ui-kit';
import { Controller, useForm } from 'react-hook-form-v7';
import { RoleGroupEnum, useInviteUserMutation } from 'src/types/graphql-types';
import { useAnalytics } from 'src/helpers/analytics';
import { USER_INVITE_NEW_USER_CLICK } from 'src/features/telematics/events';
import { agencyRoles, roleLabels } from './constants/agencyRoleGroup';

type UserInviteDialogProps = {
  open: boolean;
  onClose: () => void;
  agencyID: string | undefined;
  inviterUserID: string | undefined;
};

type UserInviteRequest = {
  email: string;
  role: RoleGroupEnum;
};

const UserInviteDialog = ({
  open,
  onClose,
  agencyID,
  inviterUserID,
}: UserInviteDialogProps) => {
  const [userInvite] = useInviteUserMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { capture } = useAnalytics();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserInviteRequest>({
    defaultValues: {
      email: '',
      role: '' as RoleGroupEnum,
    },
    mode: 'onSubmit',
  });

  const onSubmit = (data: UserInviteRequest) => {
    capture(USER_INVITE_NEW_USER_CLICK, {
      ...data,
      agencyID,
      inviterUserID,
    });

    userInvite({
      variables: {
        ...data,
        agencyID,
      },
      onCompleted: () => {
        onClose();
        enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(
          'Unexpected error while sending the invitation. Please try again later.',
          { variant: 'error' },
        );
      },
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={<p className="text-base font-bold">Invite Teammate</p>}
    >
      <div className="space-y-6 w-[536px] h-[300px]">
        <hr className="-mt-6 -mx-6" />
        <div className="space-y-2">
          <p className="text-xl font-bold">Invite Teammates</p>
          <p>Enter email and select a role.</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-10">
            <div className="flex items-center space-x-4">
              <InputWithLabel
                noMargin
                label="Email"
                placeholder="Enter Email"
                formControlProps={{
                  fullWidth: true,
                }}
                {...register('email', {
                  required: 'Please enter a email',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Invalid email address',
                  },
                })}
                error={!!errors.email}
                helperText={(errors?.email?.message as string) ?? ' '}
              />

              <FormControl fullWidth>
                <p className="text-xs text-primary-main pb-1">Role</p>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      defaultValue=""
                      variant="outlined"
                      renderValue={(selected) =>
                        selected ? (
                          roleLabels[selected]
                        ) : (
                          <p className="text-text-hint">Select Role</p>
                        )
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          classes: '',
                        },
                      }}
                      error={!!errors.role}
                    >
                      {agencyRoles.map(({ role, description }) => (
                        <MenuItem key={role} value={role}>
                          <div>
                            <p>{roleLabels[role]}</p>
                            <span className="text-xs text-text-hint">
                              {description}
                            </span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: 'Please select role' }}
                />
                <FormHelperText error>
                  {errors?.role?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            </div>

            <FormControl className="flex items-end">
              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>
            </FormControl>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default UserInviteDialog;
