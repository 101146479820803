import { useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import constate from 'constate';
import { ApplicationDetail } from '@nirvana/api/quoting';

import {
  fetchApplicationDetails,
  fetchAvailableProducers,
} from '../queries/application';

function useApplicationDetails({ applicationId }: { applicationId: string }) {
  const navigate = useNavigate();
  const [isApplicationPanic, setApplicationPanic] = useState(false);
  const {
    data: applicationDetails,
    isLoading: isApplicationLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['application', applicationId],
    queryFn: () => fetchApplicationDetails(applicationId),
    onSuccess: (data) => {
      if (data.appStatus === 'AppStatePanic') {
        navigate(`/non-fleet/application/${applicationId}/panic`);
      } else if (data.appStatus === 'AppStateDeclined') {
        navigate(`/non-fleet/application/${applicationId}/decline`);
      }
    },
    onError: (ex: AxiosError) => {
      if (ex.response?.status === 500) {
        setApplicationPanic(true);
      }
    },
  });

  const { data: producers, isLoading: isProducersLoading } = useQuery(
    ['producers'],
    fetchAvailableProducers,
  );

  return {
    applicationId,
    applicationDetails,
    applitionHeaderInfo: {
      summary: {
        shortID: applicationDetails?.shortID,
        companyName:
          applicationDetails?.admitted?.operationsForm?.companyInfo?.name,
        dotNumber:
          applicationDetails?.admitted?.operationsForm?.companyInfo?.dotNumber,
        underwriterName: applicationDetails?.underwriterName,

        underwriterEmail: applicationDetails?.underwriterEmail,
      },
    } as ApplicationDetail,
    producers,
    isLoading: isApplicationLoading || isProducersLoading,
    error,
    refetch,
    isApplicationPanic,
    setApplicationPanic,
  };
}

export const [ApplicationDetailsProvider, useApplicationDetailsContext] =
  constate(useApplicationDetails);
