import {
  Autocomplete as MuiAutoComplete,
  OutlinedInput,
  AutocompleteProps,
  InputProps,
} from '@material-ui/core';

export interface IAutocomplete<T> {
  value: string;
  list: T[];
  placeholder: string;
  focusPlaceholder: string;
  autoCompleteProps?: Omit<
    AutocompleteProps<T, boolean, boolean, boolean>,
    'renderInput' | 'options'
  >;
  InputProps?: Partial<InputProps>;
  error?: boolean;
}

/**
 * Autocomplete input with selected option block (Refer: https://react-table.tanstack.com/docs/overview).
 * @component
 *
 * @param {Object} props
 * @param {string} props.value - Filter value typed in the text input.
 * @param {Function} props.onChange - Filter function provided by react-table.
 *
 * @example
 * const {setAutocomplete} = useTable({}, useAutocomplete);
 * return <Autocomplete value="Test" onChange={setAutocomplete} />
 */
const Autocomplete = ({
  list = [],
  placeholder = '',
  focusPlaceholder = '',
  value = '',
  InputProps = {},
  autoCompleteProps,
  error = false,
}: IAutocomplete<any>) => {
  return (
    <MuiAutoComplete
      {...autoCompleteProps}
      freeSolo
      value={value}
      options={list}
      openOnFocus={true}
      blurOnSelect={true}
      renderInput={(params) => {
        return (
          <OutlinedInput
            {...params}
            {...params.InputProps}
            {...InputProps}
            type="text"
            placeholder={placeholder}
            error={error}
            inputProps={{
              ...params.inputProps,
              onFocus: (e: any) => {
                if (params.inputProps && params.inputProps.onFocus) {
                  params.inputProps.onFocus(e);
                }
                e.target.placeholder = focusPlaceholder;
              },
              onBlur: (e: any) => {
                if (params.inputProps && params.inputProps.onBlur) {
                  params.inputProps.onBlur(e);
                }
                e.target.placeholder = placeholder;
              },
            }}
          />
        );
      }}
    />
  );
};

export default Autocomplete;
