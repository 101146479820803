import {
  CoverageType,
  CoverageVariablesOptions,
  CurrentDeductibleRecord,
  IndicationOption,
  IndicationOptions,
} from '@nirvana/api/quoting';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/redux/reducers';
import {
  IUpdateApplicationPayload,
  submitIndication,
  updateApplication,
} from '../actions';
import { fetchState } from './index';

export interface indicationOptionsError {
  message: string;
}

export declare type RequestStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export const updateIndication = createAsyncThunk(
  'indicationOptions/updateIndication',
  async (data: IUpdateApplicationPayload, thunkAPI: any) => {
    await thunkAPI.dispatch(updateApplication(data));
    await thunkAPI.dispatch(submitIndication(data.applicationID));
    await thunkAPI.dispatch(fetchState({ applicationID: data.applicationID }));
  },
);

export type VariablesOptionsTypes = 'deductible' | 'limit';

export interface IndicationOptionsState {
  isLoading: boolean;
  isUpdatingIndication: boolean;
  status: RequestStatus;
  error?: indicationOptionsError;
  list: Array<IndicationOption>;
  selectedDeductibles: Array<CurrentDeductibleRecord>;
  coverageVariablesOptions: Array<CoverageVariablesOptions>;
  coveragesWithCombinedDeductibles?: Array<Array<CoverageType>>;
  isLoadingIndication: boolean;
}

type VariablesOptionsUpdate = {
  coverage: CoverageType;
  amount: number;
  type: VariablesOptionsTypes;
};

type DeductibleUpdateMap = {
  [key: string]: number;
};

export interface DeductibleUpdateRecord {
  deductibles: DeductibleUpdateMap;
  coveragesWithCombinedDeductibles: CoverageType[][];
}

export const initialState: IndicationOptionsState = {
  isLoading: false,
  isUpdatingIndication: false,
  status: 'idle',
  list: [],
  selectedDeductibles: [],
  coverageVariablesOptions: [],
  isLoadingIndication: false, // Something is loading/updating/animating
};

const indicationOptionsSlice = createSlice({
  name: 'indicationOptions',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setLoadingIndication: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingIndication = payload;
    },
    setStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.status = payload;
    },
    setListSuccess: (state, { payload }: PayloadAction<IndicationOptions>) => {
      state.list = payload.options;
      state.coverageVariablesOptions = payload.coverageVariablesOptions;
      state.coveragesWithCombinedDeductibles =
        payload.coveragesWithCombinedDeductibles;
    },
    setListFail: (
      state,
      { payload }: PayloadAction<indicationOptionsError>,
    ) => {
      state.error = payload;
    },
    resetList: (state) => {
      state.list = [];
    },
    updateVariablesOptions: (
      state,
      { payload }: PayloadAction<VariablesOptionsUpdate>,
    ) => {
      state.coverageVariablesOptions = state.coverageVariablesOptions.map(
        (record) => {
          if (record.coverage === payload.coverage) {
            let newRecord: any = { ...record };

            if (payload.type === 'deductible') {
              newRecord = {
                ...record,
                deductibles: {
                  ...record.deductibles,
                  current: payload.amount,
                },
              };
            } else if (payload.type === 'limit') {
              newRecord = {
                ...record,
                limits: {
                  ...record.limits,
                  current: payload.amount,
                },
              };
            }

            return newRecord;
          }

          return record;
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateIndication.pending, (state) => {
      state.list = [];
      state.isUpdatingIndication = true;
    });

    builder.addCase(updateIndication.fulfilled, (state) => {
      state.isUpdatingIndication = false;
    });

    builder.addCase(updateIndication.rejected, (state) => {
      state.isUpdatingIndication = false;
    });
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = indicationOptionsSlice;

// Extract and export each action creator by name
export const {
  setLoading,
  setLoadingIndication,
  setStatus,
  setListSuccess,
  setListFail,
  resetList,
  updateVariablesOptions,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const indicationOptionsSelector = (state: RootState) =>
  state.indicationOptions;
