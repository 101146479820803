import * as React from 'react';
import {
  FormControl,
  FormControlProps,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import clsx from 'clsx';

const inputLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    transform: 'none',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
  },
  successText: {
    color: `${theme.palette.success.main} !important`,
  },
}));

export interface InputWithoutLabelProps extends OutlinedInputProps {
  formControlProps?: FormControlProps;
  helperText?: string;
  success?: boolean;
  error?: boolean;
}

/**
 * Common text input UI component, which is an extension of Mui Input
 * Refer: https://next.material-ui.com/components/text-fields for usage and props
 * Note: This component makes sure that we always render an outlined text input.
 * @component
 *
 * @param {Object} props - Material UI Input props.
 *
 * @example
 * return <InputWithoutLabel placeholder="Enter something" />
 */
const InputWithoutLabel = React.forwardRef(
  (
    {
      formControlProps,
      helperText,
      success,
      error,
      ...rest
    }: InputWithoutLabelProps,
    ref: any,
  ) => {
    const inputLabelClasses = inputLabelStyles();

    return (
      <>
        <FormControl {...formControlProps} variant="standard">
          <OutlinedInput {...rest} error={error} ref={ref} />
          {!!helperText && (
            <FormHelperText
              error={error}
              className={clsx({ [inputLabelClasses.successText]: success })}
            >
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      </>
    );
  },
);

export default InputWithoutLabel;
