import { Fragment } from 'react';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form-v7';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { formatISO, isValid } from 'date-fns';
import { DriverDetails } from '@nirvana/api/non-fleet';

import Button from 'src/components/button';
import { InputWithoutLabel as OutlinedInput } from 'src/components/input';
import IconPlus from 'src/assets/icons/plus-primary.svg';
import DeleteIcon from 'src/assets/icons/delete.svg';

import {
  DatePicker,
  InputNumeric,
  Checkbox,
  Show,
  constants,
} from '@nirvana/ui-kit';

import FlatFileUploader from './flatFileUploader';

const defaultValues = {
  licenseNumber: '',
  firstName: '',
  lastName: '',
  licenseState: '',
  licenseIssueYear: '',
  dateOfBirth: '',
  ownerOperator: false,
  violations: {
    total: '',
    recklessnessOrPhoneUsage: '',
    atFaultAccidents: '',
  },
};

const Driver = () => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const formErrors = (errors?.driversForm as any)?.drivers;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'driversForm.drivers',
    rules: {
      required: 'Please add at least one driver',
    },
    shouldUnregister: true,
  });

  const handeFileUploadSuccess = (data?: DriverDetails[]) => {
    setValue('driversForm.drivers', data);
  };

  return (
    <Box mt={12}>
      <Grid container direction="column" alignItems="center" spacing={4}>
        <FlatFileUploader onSuccess={handeFileUploadSuccess} />

        <Show when={fields.length === 0}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => append(defaultValues)}
              startIcon={<img src={IconPlus} alt="Add Driver" />}
            >
              Add a Driver
            </Button>

            {!!formErrors?.root?.message && (
              <FormHelperText error>{formErrors.root.message}</FormHelperText>
            )}
          </Grid>
        </Show>

        <Show when={fields.length > 0}>
          <Grid item container flexDirection="column" spacing={2}>
            <Grid
              item
              container
              alignItems="flex-end"
              spacing={1}
              flexWrap="nowrap"
            >
              <Grid item xs={3}>
                <FormHelperText>CDL Number</FormHelperText>
              </Grid>

              <Grid item xs={4}>
                <FormHelperText>Driver&apos;s Full Name</FormHelperText>
              </Grid>

              <Grid item xs={2}>
                <FormHelperText>License State</FormHelperText>
              </Grid>

              <Grid item xs={2}>
                <FormHelperText>Year Issued</FormHelperText>
              </Grid>

              <Grid item xs={3}>
                <FormHelperText>Date of Birth</FormHelperText>
              </Grid>

              <Grid item xs={2}>
                <FormHelperText>Driver is an owner operator</FormHelperText>
              </Grid>

              <Grid item xs={1} />
            </Grid>

            {fields.map((item, index) => (
              <Fragment key={item.id}>
                <Grid
                  item
                  container
                  spacing={1}
                  alignItems="flex-start"
                  flexWrap="nowrap"
                >
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <OutlinedInput
                        placeholder="CDL Number"
                        {...register(
                          `driversForm.drivers.${index}.licenseNumber`,
                          { required: 'Please enter DL number' },
                        )}
                        error={!!formErrors?.[index]?.licenseNumber?.message}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <OutlinedInput
                        placeholder="First"
                        error={!!formErrors?.[index]?.firstName?.message}
                        {...register(`driversForm.drivers.${index}.firstName`, {
                          required: 'Please enter first name',
                        })}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <OutlinedInput
                        placeholder="Last"
                        error={!!formErrors?.[index]?.lastName?.message}
                        {...register(`driversForm.drivers.${index}.lastName`, {
                          required: 'Please enter last name',
                        })}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        defaultValue=""
                        name={`driversForm.drivers.${index}.licenseState`}
                        rules={{ required: 'Please select state' }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              displayEmpty
                              variant="outlined"
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                              error={
                                !!formErrors?.[index]?.licenseState?.message
                              }
                            >
                              <MenuItem value="">
                                <Typography color="text.hint">State</Typography>
                              </MenuItem>
                              {constants.usStates.map((record) => (
                                <MenuItem value={record.code} key={record.code}>
                                  {record.name}
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        defaultValue=""
                        name={`driversForm.drivers.${index}.licenseIssueYear`}
                        rules={{ required: 'Please enter year' }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputNumeric
                              placeholder="Year"
                              thousandSeparator={false}
                              value={value}
                              onChange={(e) => onChange(+e.target.value)}
                              error={
                                !!formErrors?.[index]?.licenseIssueYear?.message
                              }
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name={`driversForm.drivers.${index}.dateOfBirth`}
                      rules={{ required: 'Please enter date of birth' }}
                      render={({ field }) => (
                        <DatePicker
                          value={field.value}
                          InputProps={{
                            error: !!formErrors?.[index]?.dateOfBirth?.message,
                          }}
                          OpenPickerButtonProps={{
                            size: 'small',
                            sx: { padding: 0, width: '18px' },
                          }}
                          maxDate={new Date()}
                          onChange={(value) =>
                            field.onChange(
                              value && isValid(value)
                                ? formatISO(value, {
                                    representation: 'date',
                                  })
                                : '',
                            )
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid container item xs={2} justifyContent="center">
                    <Controller
                      control={control}
                      name={`driversForm.drivers.${index}.ownerOperator`}
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton size="small" onClick={() => remove(index)}>
                      <img src={DeleteIcon} alt="Delete" />
                    </IconButton>
                  </Grid>
                </Grid>
                {!!formErrors?.[index] && (
                  <FormHelperText error sx={{ pl: 2 }}>
                    {
                      formErrors?.[index]?.[Object.keys(formErrors?.[index])[0]]
                        ?.message
                    }
                  </FormHelperText>
                )}
              </Fragment>
            ))}
          </Grid>
        </Show>
      </Grid>

      <Show when={fields.length > 0}>
        <Grid container my={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => append(defaultValues)}
              startIcon={<img src={IconPlus} alt="Add Driver" />}
            >
              Add a Driver
            </Button>
          </Grid>
        </Grid>
      </Show>
    </Box>
  );
};

export default Driver;
