import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import Button from 'src/components/button';
import { Dialog } from '@nirvana/ui-kit';
import IconApplicationError from 'src/assets/icons/application-error.svg';
import { useStyles } from '../appetiteCheck/styles';

export interface IAppetiteError {
  open: boolean;
  onClose: () => void;
}

const AppetiteLiteError = ({ open, onClose }: IAppetiteError) => {
  const classes = useStyles();

  const options = [
    'Logging',
    'Driveaway',
    'PPT vehicles',
    'Auto-haulers',
    'Livestock',
    'Dump',
    'Waste',
    'Hazardous Materials',
    'Hot Shot',
    'Team-driving',
    'Expedited/residential delivery',
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      primaryAction={
        <Button variant="contained" color="primary" onClick={onClose}>
          Back to Home
        </Button>
      }
      disableBackdropClose
    >
      <Box pt={4} position="relative" px={10} pb={2}>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          edge="end"
          size="small"
        >
          <Close />
        </IconButton>
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <img src={IconApplicationError} alt="Application Error" />
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              color="textPrimary"
              textAlign="center"
              gutterBottom
            >
              Unfortunately, this application doesn&apos;t fit our appetite
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              textAlign="center"
            >
              Since the insured has more than 5% of operations in one of the
              following classes:
            </Typography>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Box className={classes.wrapper} px={3} py={2}>
              <ul className={clsx(classes.reasonsList, classes.splitThree)}>
                {options.map((item, index) => (
                  <li key={`${item}-${index}`}>
                    <Typography
                      variant="caption"
                      fontWeight="fontWeightRegular"
                      color="textSecondary"
                    >
                      {item}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default AppetiteLiteError;
