import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Snackbar,
  Select,
  Typography,
  Alert,
} from '@material-ui/core';
import {
  Vehicle,
  VehicleClass,
  VehicleType,
  WeightClass,
} from '@nirvana/api/endorsementapp';
import { InputNumeric, InputWithLabel, Show, Tooltip } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { FocusEvent, ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form-v7';
import IconExclamation from 'src/assets/icons/exclamation-warning.svg';
import IconInfo from 'src/assets/icons/info-warning.svg';
import TrashIcon from 'src/assets/icons/trash.svg';
import {
  decodeVehicleVINs,
  fetchVehicleInfo,
} from 'src/features/admitted/queries/application';
import { vehicleConstants } from 'src/features/policy/constants/vehicle';
import { getWeightClassLabel, getWeightClassOptions } from './utils';

type EquipmentsFormProps = {
  title: string;
  equipment?: Vehicle;
  onSubmit: (data: Vehicle) => void;
  onCancel: () => void;
  onRemove?: (driver: Vehicle) => void;
};

const EquipmentForm = ({
  title,
  equipment,
  onSubmit,
  onCancel,
  onRemove,
}: EquipmentsFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<Vehicle>();
  const vehiclesValues = watch();
  const [formWarning, setFormWarning] = useState<ReactNode>();
  const [hasLimitWarning, setHasLimitWarning] = useState(false);

  const { mutate: fetchVehicleInfoMutate } = useMutation(fetchVehicleInfo);
  const { mutate: decodeVINMutate } = useMutation(decodeVehicleVINs);

  useEffect(() => {
    if (equipment) {
      reset(equipment);
    }
  }, [equipment, reset]);

  const handleVINBlur = async (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const vinValue = e.target.value.trim();

    // If VIN is empty, clear the associated warnings and do nothing
    if (!vinValue) {
      setFormWarning(undefined);
      return;
    }

    const vinArray = [vinValue];

    // Call the VIN decoding API only if VIN is provided
    decodeVINMutate(vinArray, {
      onSuccess: (vinDecodingData) => {
        const vinResponse = vinDecodingData.find(
          (item) => item.vin === vinValue,
        );

        if (vinResponse?.hasDecodingError) {
          // Show "Invalid VIN" only if the API returns an error
          setFormWarning(
            <Box
              display="flex"
              alignItems="center"
              className="flex items-center text-warning-main"
            >
              <img
                src={IconExclamation}
                alt="Warning"
                className="w-3 h-3 mr-1"
              />
              <span className="text-xs">Invalid VIN</span>
            </Box>,
          );
          return;
        }

        // If VIN is valid, clear any VIN-specific warning
        setFormWarning(undefined);

        // Fetch the vehicle info after VIN is successfully decoded
        fetchVehicleInfoMutate(
          { vins: vinValue },
          {
            onSuccess: ({ data }) => {
              if (data?.length) {
                const { type, modelYear, make, model, weightClass } = data[0];

                setValue('vehicleType', type as unknown as VehicleType);
                setValue('year', +modelYear);
                setValue('make', make);
                setValue('model', model);

                if (type && weightClass) {
                  setValue(
                    'weightClass',
                    weightClass?.replace('WeightClassV1_', '') as WeightClass,
                  );
                }
              }
            },
          },
        );
      },
    });
  };

  const handleStatedValueBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = +e.target.value;

    if (value === 0) {
      setFormWarning('Vehicle will not be covered for Auto Physical Damage');
    } else if (
      (vehiclesValues.vehicleType === VehicleType.Truck ||
        vehiclesValues.vehicleType === VehicleType.Tractor) &&
      value > 250000
    ) {
      setFormWarning(
        <Box display="flex" alignItems="center">
          Higher than usual&nbsp;
          <Tooltip
            title={
              <p>
                The stated value of trucks/tractors usually does not exceed 250k
              </p>
            }
          >
            <img src={IconInfo} alt="more info" />
          </Tooltip>
        </Box>,
      );
      setHasLimitWarning(true);
    } else if (
      (vehiclesValues.vehicleType === VehicleType.Pickup ||
        vehiclesValues.vehicleType === VehicleType.Trailer) &&
      value > 100000
    ) {
      setFormWarning(
        <Box display="flex" alignItems="center">
          Higher than usual&nbsp;
          <Tooltip
            title={
              <p>
                The stated value of pickup/trailers usually does not exceed 100k
              </p>
            }
          >
            <img src={IconInfo} alt="more info" />
          </Tooltip>
        </Box>,
      );
      setHasLimitWarning(true);
    } else {
      setFormWarning(undefined);
      setHasLimitWarning(false);
    }
  };

  return (
    <form
      className="p-6 space-y-3 overflow-hidden border rounded-lg border-text-disabled "
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex items-center justify-between ">
        <p className="text-sm text-black">{title}</p>
        <div className="space-x-4">
          <Button size="small" type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="small" type="submit" variant="contained">
            Save
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-8 gap-4">
        <FormControl className="col-span-3">
          <InputWithLabel
            label="VIN"
            placeholder="Please enter VIN"
            {...register('vin', {
              required: 'Please enter VIN',
              validate: (value) => {
                if (!value) {
                  return 'Please enter a VIN';
                }

                return true;
              },
              setValueAs: (value) => {
                return value?.trim();
              },
            })}
            onBlur={(e) => handleVINBlur(e)}
            error={!!errors?.vin?.message}
          />
        </FormControl>

        <FormControl className="col-span-1">
          <FormHelperText className="text-primary-main">Year</FormHelperText>
          <Controller
            control={control}
            name={'year'}
            rules={{
              required: 'Please enter year',
              validate: {
                validYear: (value) => {
                  if (value && (value < 1900 || value > 2100)) {
                    return 'Please enter a valid year for the vehicle';
                  }
                },
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <InputNumeric
                  placeholder="Year"
                  thousandSeparator={false}
                  value={value}
                  onChange={(e) =>
                    onChange(e.target.value ? +e.target.value : '')
                  }
                  error={!!errors?.year?.message}
                />
              );
            }}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="Make"
            {...register('make', {
              required: 'Please enter make',
            })}
            error={!!errors.make?.message}
          />
        </FormControl>
        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="Model"
            {...register('model', {
              required: 'Please enter model',
            })}
            error={!!errors.model?.message}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <FormHelperText className="text-primary-main">Type</FormHelperText>
          <Controller
            control={control}
            defaultValue={undefined}
            name={'vehicleType'}
            rules={{ required: 'Please select vehicle type' }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  displayEmpty
                  variant="outlined"
                  value={value ?? ''}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setValue('vehicleClass', '' as VehicleClass);
                    setValue('weightClass', '' as WeightClass);
                  }}
                  error={!!errors.vehicleType?.message}
                >
                  <MenuItem value="">
                    <Typography color="text.hint">Select Type</Typography>
                  </MenuItem>
                  {vehicleConstants?.vehicleTypes?.map((record) => (
                    <MenuItem value={record.value + ''} key={record.value}>
                      {record.label}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <FormHelperText className="text-primary-main">Class</FormHelperText>
          <Controller
            control={control}
            defaultValue={undefined}
            name={'vehicleClass'}
            rules={{
              required: 'Please select vehicle class',
            }}
            render={({ field: { onChange, value }, fieldState }) => {
              return (
                <Select
                  displayEmpty
                  variant="outlined"
                  value={value ?? ''}
                  disabled={!vehiclesValues.vehicleType}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setValue('weightClass', '' as WeightClass);
                  }}
                  error={!!fieldState.error}
                >
                  <MenuItem value="">
                    <Typography color="text.hint">Select Class</Typography>
                  </MenuItem>
                  {(
                    (vehiclesValues?.vehicleType &&
                      vehicleConstants?.vehicleClassByType?.[
                        vehiclesValues.vehicleType
                      ]) ||
                    []
                  ).map((record: any) => (
                    <MenuItem value={record.value} key={record.value}>
                      {record.label}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <FormHelperText className="text-primary-main">
            GVW, lbs
          </FormHelperText>
          <Controller
            control={control}
            defaultValue={undefined}
            name={'weightClass'}
            rules={{ required: 'Please select weight class' }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <Select
                displayEmpty
                variant="outlined"
                value={value ?? ''}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                inputRef={ref}
                error={!!error}
                renderValue={getWeightClassLabel}
              >
                <MenuItem value="">
                  <Typography color="text.hint">Select GVW</Typography>
                </MenuItem>
                {getWeightClassOptions(
                  vehiclesValues.vehicleType,
                  vehiclesValues.vehicleClass,
                ).map((record) => (
                  <MenuItem value={record.value} key={record.value}>
                    <Box>
                      {record.label}
                      {!!record?.subtext && (
                        <FormHelperText>{record.subtext}</FormHelperText>
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <FormControl className="col-span-2">
          <FormHelperText className="text-primary-main">
            Stated Value
          </FormHelperText>
          <Controller
            control={control}
            defaultValue={undefined}
            name={'statedValue'}
            rules={{
              required: 'Please enter stated value',
              validate: {
                minValue: (value) => {
                  // Value should be greather than or equal to 5000 or 0
                  if (value && value < 5000) {
                    return 'Please enter a valid stated amount. Minimum value is $5,000';
                  }
                },
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <InputNumeric
                  prefix="$"
                  placeholder="e.g. $90,000"
                  value={value}
                  decimalScale={0}
                  onChange={(e) => onChange(+e.target.value)}
                  onBlur={handleStatedValueBlur}
                  error={!!errors?.statedValue?.message}
                />
              );
            }}
          />
        </FormControl>
      </div>
      <Show when={!!onRemove}>
        <Button
          size="small"
          type="button"
          onClick={onRemove && equipment ? () => onRemove(equipment) : onCancel}
          startIcon={<img src={TrashIcon} />}
          className="-ml-4 leading-4"
        >
          Remove Equipment
        </Button>
      </Show>

      {hasLimitWarning && (
        <FormHelperText sx={{ pl: 2, color: 'warning.main' }}>
          {formWarning}
        </FormHelperText>
      )}

      {!!errors && (
        <FormHelperText error sx={{ pl: 2 }}>
          {(errors as any)?.[Object.keys(errors)[0]]?.message}
        </FormHelperText>
      )}

      <Snackbar
        open={hasLimitWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ mb: 8 }}
      >
        <Alert
          severity="warning"
          icon={<img src={IconExclamation} />}
          variant="outlined"
          sx={{ bgcolor: 'warning.extraLight' }}
        >
          <Typography color="warning.main">
            The stated value of the equipment seem higher than usual.
            <br />
            Please check highlighted value before you proceed
          </Typography>
        </Alert>
      </Snackbar>
    </form>
  );
};

export default EquipmentForm;
