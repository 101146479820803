import {
  ApplicationDetail,
  CoverageRecord,
  IndicationForm,
} from '@nirvana/api/quoting';

import { utils as UtilsHelper } from 'src/helpers';
import { FleetType } from 'src/helpers/featureFlags';

import Operations from '../operations';
import IndicationOptions from '../indicationOptions';
import ClassesAndCommodities from '../commodities';
import LossHistory from '../lossHistory';
import AdditionalInformation from '../additionalInformation';
import Review from '../review';

type GetSteps = {
  application?: ApplicationDetail;
  childFormRef: any;
  coveragesRequired?: CoverageRecord[];
  fleetType: FleetType;
  handleSectionEdit: (key: string) => void;
  onPanic: () => void;
  isPricelessIndication: boolean;
};

type GetStepsResponse = {
  key: keyof IndicationForm | keyof ApplicationDetail | 'applicationSummary';
  label: string;
  component: JSX.Element;
};

export const getSteps = ({
  application,
  childFormRef,
  coveragesRequired = [],
  fleetType,
  handleSectionEdit,
  onPanic,
  isPricelessIndication,
}: GetSteps): GetStepsResponse[] => {
  const applicationId = application?.summary.applicationID;

  let steps: GetStepsResponse[] = [
    {
      key: 'operationsForm',
      label: 'Operations',
      component: <Operations childFormRef={childFormRef} />,
    },
    {
      key: 'classesAndCommoditiesForm',
      label: 'Classes & Commodities',
      component: (
        <ClassesAndCommodities
          coverages={coveragesRequired.map((record) => record.coverageType)}
          isLegacyApplication={
            !!application?.indicationForm?.classesAndCommoditiesForm
              ?.primaryCommodity
          }
          applicationId={applicationId}
        />
      ),
    },
    {
      key: 'lossRunSummaryForm',
      label: 'Loss History',
      component: (
        <LossHistory
          coverages={coveragesRequired.map((record) => record.coverageType)}
          lossSummary={application?.indicationForm?.lossRunSummaryForm}
          effectiveDate={application?.summary.effectiveDate}
        />
      ),
    },
    {
      key: 'selectedIndication',
      label: isPricelessIndication ? 'Packages' : 'Indication',
      component: (
        <IndicationOptions
          coverages={coveragesRequired.map((record) => record.coverageType)}
          applicationId={applicationId}
          fleetType={fleetType}
          onPanic={onPanic}
        />
      ),
    },
    {
      key: 'additionalInfoForm',
      label: 'Additional information',
      component: (
        <AdditionalInformation
          applicationId={applicationId}
          coverages={coveragesRequired}
          effectiveDate={application?.summary.effectiveDate}
          telematicsInfo={application?.telematicsInfo}
        />
      ),
    },
    {
      key: 'applicationSummary',
      label: 'Review',
      component: (
        <Review
          coverages={coveragesRequired.map((record) => record.coverageType)}
          onEdit={handleSectionEdit}
          title="Review"
          subtitle={
            <>
              You’re almost there! Review the application before you submit.
              <br />
              Any changes to the application may change the quote.
            </>
          }
          isEditable={true}
        />
      ),
    },
  ];

  const indexOfIndication = steps.findIndex(
    (record) => record.key === 'selectedIndication',
  );

  // Reorder in case of non-fleet
  if (fleetType === 'retail-non-fleet' || fleetType === 'wholesale-non-fleet') {
    steps = UtilsHelper.arrayMove(
      steps,
      indexOfIndication,
      indexOfIndication + 1,
    );
  }

  return steps;
};
