import { useState } from 'react';
import { Box, Grid, IconButton, Link, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { VehicleDetails } from '@nirvana/api/non-fleet';
import { Dialog } from '@nirvana/ui-kit';

import Button, { FlatFileUpload } from 'src/components/button';

import { useStyles } from './styles';

type FlatFileUploaderProps = {
  onSuccess: (driversList?: VehicleDetails[]) => void;
  onClose: () => void;
  open: boolean;
  skipStatedValue?: boolean;
};

const FlatFileUploader = ({
  open,
  onSuccess,
  onClose,
  skipStatedValue = false,
}: FlatFileUploaderProps) => {
  const classes = useStyles();
  const [renderKey, setRenderKey] = useState(1);

  return (
    <Dialog
      title=""
      open={open}
      maxWidth="xs"
      onClose={onClose}
      primaryAction={
        <Box onClick={onClose} key={renderKey}>
          <FlatFileUpload
            variant="contained"
            type="Equipment"
            title="Upload Equipment List"
            fields={[
              {
                label: 'VIN',
                alternates: [
                  'Chassis',
                  'VIN#',
                  'VIN Number',
                  '17 Digit VIN',
                  '17 Digit VIN#',
                  'Trailer#',
                  'Serial',
                  'Serial#',
                  'Full VIN#',
                ],
                key: 'vin',
                description: 'Uniquely identifies your vehicle',
                validators: [
                  {
                    validate: 'required',
                    error:
                      'VIN is required for power units. For trailers without VINs, use "Trailer#" (example "Trailer1", "Trailer2", etc...)',
                  },
                  {
                    validate: 'regex_matches',
                    error:
                      'A valid VIN is required for power units. For trailers without VINs, use "Trailer#" (no spaces, example "Trailer1", "Trailer2", etc...)',
                    regex:
                      '^(?!(T|t)otal|TOTAL|(V|v)in|VIN|(V|v)alue|VALUE)([a-zA-Z0-9])[a-zA-Z0-9]*([a-zA-Z0-9])$',
                  },
                  {
                    validate: 'unique',
                    error:
                      'Duplicate VINs are not allowed. For trailers, use "Trailer#" (example "Trailer1", "Trailer2", etc...)',
                  },
                ],
              },
              ...(!skipStatedValue
                ? [
                    {
                      label: 'Stated Value',
                      alternates: [
                        'Amount',
                        'Cost',
                        'Renewal Value',
                        'Stated Value',
                        'Stated Amount',
                        'Stated Amt',
                        'Value',
                        'Renewal',
                        'Renewal Physical Damage',
                        'Physical Damage Value',
                        'Physical Damage Amount',
                      ],
                      key: 'statedValue',
                      description: 'Stated value for your vehicle',
                      validators: [
                        {
                          validate: 'regex_matches',
                          error: 'Stated value should be a valid amount.',
                          regex: '^(\\d*\\.?\\d+)(,\\d*\\.?\\d*)*$',
                        },
                        {
                          validate: 'regex_matches',
                          error:
                            'Valid stated value is required for Physical Damage. For no Physical Damage, use "0".',
                          regex:
                            '^(?!(V|v)alue|VALUE|(R|r)enewal|RENEWAL|(S|s)tated|STATED|(C|c)ost|COST|(A|a)mount)|AMOUNT',
                        },
                      ],
                    },
                  ]
                : []),
            ]}
            onRecordChange={(record) => {
              return {
                vin: {
                  value:
                    typeof record.vin === 'string'
                      ? record.vin.toUpperCase()
                      : record.vin,
                  info: [{ message: 'Converted to uppercase', level: 'info' }],
                },
              };
            }}
            fieldHooks={{
              vin: (values) => {
                return values.map(([item, index]) => {
                  const newItem =
                    typeof item === 'string'
                      ? item.replace(/[$,.\s]/g, '').toUpperCase()
                      : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message:
                              'Removed invalid characters and whitespaces',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
              statedValue: (values) => {
                return values.map(([item, index]) => {
                  const newItem =
                    typeof item === 'string'
                      ? ~~item.replace(/[$,]/g, '')
                      : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else if (
                    newItem &&
                    !/^(\d*\.?\d+)(,\d*\.?\d*)*$/.test(newItem.toString())
                  ) {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Stated value should be a valid number.',
                            level: 'error',
                          },
                        ],
                      },
                    ];
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Removed invalid characters and decimals',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
            }}
            value={{} as any}
            onChange={(results) => {
              if (results) {
                const equipmentList = {
                  info: results.validData.map(
                    ({
                      vin,
                      statedValue,
                      ...rest
                    }: Partial<VehicleDetails>) => {
                      const newData: Partial<VehicleDetails> = {
                        ...rest,
                        vin: vin,
                      };

                      if (statedValue) {
                        newData.statedValue =
                          typeof statedValue === 'number'
                            ? statedValue
                            : ~~(statedValue as any).replace(/[$,]/g, '') || 0;
                      }

                      return newData;
                    },
                  ),
                  flatfileMetadata: {
                    flatfileHandle: results.$meta.batchID,
                    fileMetadata: {
                      name: results.$meta.filename,
                    },
                  },
                };

                onSuccess(equipmentList.info);
              } else {
                onSuccess(undefined);
              }

              setRenderKey((prevState) => prevState + 1);
            }}
            render={(_, launch) => {
              return (
                <Button variant="contained" onClick={launch}>
                  Proceed
                </Button>
              );
            }}
          />
        </Box>
      }
      classes={{ paper: classes.dialogPaper }}
    >
      <Box className={classes.dialogContent}>
        <IconButton
          className={classes.dialogCloseButton}
          onClick={onClose}
          edge="end"
          size="small"
        >
          <Close />
        </IconButton>
        <Grid container direction="column" spacing={3}>
          <Grid item container alignItems="center" flexWrap="nowrap">
            <Typography variant="h5" color="textPrimary">
              Upload equipment list
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <Typography variant="body2" mb={2} component="p">
                  Please upload the equipment list in <strong>CSV</strong> or{' '}
                  <strong>Excel</strong> format.
                </Typography>

                <Typography variant="body2" gutterBottom>
                  <strong>
                    <u>NOTE:</u>{' '}
                  </strong>
                  To ensure the upload works properly, please make sure that:
                  <ol>
                    <li>
                      - Tractors and trailers are on the same tab or sheet in
                      the file.
                    </li>
                    <li>
                      - There are NO merged cells in your file. For instructions
                      on how to remove merged cells, please{' '}
                      <Link
                        href="https://www.officetooltips.com/excel_2016/tips/unmerging_all_merged_cells.html"
                        target="_blank"
                      >
                        check here
                      </Link>
                      .
                    </li>
                  </ol>
                </Typography>
                <Typography variant="body2">
                  If you prefer, you can download and use our{' '}
                  <Link
                    href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/Nirvana_nf_upload_template_first_last_name.xlsx"
                    target="_blank"
                  >
                    <strong>template here</strong>
                  </Link>{' '}
                  to make sure the upload works.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default FlatFileUploader;
