import { AppetiteLiteInfoScoreEnum } from '@nirvana/api/appetite-lite';
import FurtherReviewIcon from 'src/assets/icons/appetite-lite/further-review-needed.svg?react';
import LoveItIcon from 'src/assets/icons/appetite-lite/love-it.svg?react';
import HighRiskIcon from 'src/assets/icons/appetite-lite/risk-high.svg?react';
import LowRiskIcon from 'src/assets/icons/appetite-lite/risk-low.svg?react';
import ModerateRiskIcon from 'src/assets/icons/appetite-lite/risk-moderate.svg?react';
import RiskyIcon from 'src/assets/icons/appetite-lite/risky-outlook.svg?react';
import UnknownIcon from 'src/assets/icons/appetite-lite/risk-unknown.svg?react';
import { RiskDetailsProps } from './constants/types';

export function getColorSettings(
  actionType: AppetiteLiteInfoScoreEnum | undefined,
) {
  const preferredAcceptableColors = {
    border: 'border-green-light',
    bg: 'bg-green-tint2',
  };

  const highRiskColors = {
    border: 'border-warning-tint3',
    bg: 'bg-error-tint2',
  };

  const actionColorMapping = {
    Preferred: preferredAcceptableColors,
    Acceptable: preferredAcceptableColors,
    HighRisk: highRiskColors,
    OutOfAppetite: highRiskColors,
    Marginal: {
      border: 'border-text-disabled',
      bg: 'bg-text-darkGrey',
    },
    Unknown: {
      border: 'border-text-disabled',
      bg: 'bg-primary-extraLight',
    },
  };

  const colorSettings = actionType ? actionColorMapping[actionType] : undefined;
  return (
    colorSettings ?? {
      border: 'border-warning-tint2',
      bg: 'bg-warning-tint1',
    }
  );
}

export const getScoreTypeIcon = (
  scoreType: AppetiteLiteInfoScoreEnum | undefined,
) => {
  switch (scoreType) {
    case AppetiteLiteInfoScoreEnum.Acceptable:
    case AppetiteLiteInfoScoreEnum.Preferred:
      return <LoveItIcon />;
    case AppetiteLiteInfoScoreEnum.Marginal:
      return <FurtherReviewIcon />;
    case AppetiteLiteInfoScoreEnum.HighRisk:
    case AppetiteLiteInfoScoreEnum.OutOfAppetite:
      return <RiskyIcon />;
    case AppetiteLiteInfoScoreEnum.Unknown:
      return <UnknownIcon />;
  }
};

export function getFormattedScoreType(
  scoreType: AppetiteLiteInfoScoreEnum | undefined,
): string {
  const scoreTypeFormatting: { [key in AppetiteLiteInfoScoreEnum]: string } = {
    [AppetiteLiteInfoScoreEnum.Preferred]: 'Love it',
    [AppetiteLiteInfoScoreEnum.Acceptable]: 'Love it',
    [AppetiteLiteInfoScoreEnum.Marginal]: 'Further Review Needed',
    [AppetiteLiteInfoScoreEnum.HighRisk]: 'Risky outlook',
    [AppetiteLiteInfoScoreEnum.OutOfAppetite]: 'Out of appetite',
    [AppetiteLiteInfoScoreEnum.Unknown]: 'Unknown outlook',
  };
  if (scoreType === undefined) {
    return '';
  }
  return scoreTypeFormatting[scoreType];
}

export const getRecommendationText = (
  scoreType: AppetiteLiteInfoScoreEnum | undefined,
) => {
  switch (scoreType) {
    case AppetiteLiteInfoScoreEnum.Acceptable:
    case AppetiteLiteInfoScoreEnum.Preferred:
      return "This account's initial risk profile looks great! We highly recommend submitting this account, so we can evaluate the full picture of risk.";
    case AppetiteLiteInfoScoreEnum.Marginal:
      return 'This account has qualities that we like and don’t like. We recommend submitting for further underwriting review of the full application.';
    case AppetiteLiteInfoScoreEnum.HighRisk:
      return "This account has several risky traditional factors. Submit an application if there's more context or data we should review.";
    case AppetiteLiteInfoScoreEnum.OutOfAppetite:
      return "This account doesn't meet our minimum required years in business, but we would love to see this account once they do.";
    case AppetiteLiteInfoScoreEnum.Unknown:
      return 'We recommend submitting for further underwriting review of the full application.';
  }
};

export function getRiskIcon(highCount: number, moderateCount: number) {
  if (highCount > 0) {
    return <HighRiskIcon />;
  } else if (moderateCount > 0) {
    return <ModerateRiskIcon />;
  }
  return <LowRiskIcon />;
}

export const getRiskCount = (risk: RiskDetailsProps['risk']): string => {
  if (risk.high.count > 0 && risk.moderate.count > 0) {
    return `${risk.high.count} high risk${risk.high.count > 1 ? 's' : ''}, ${
      risk.moderate.count
    } moderate risk${risk.moderate.count > 1 ? 's' : ''}`;
  } else if (risk.high.count > 0) {
    return `${risk.high.count} high risk${risk.high.count > 1 ? 's' : ''}`;
  } else if (risk.moderate.count > 0) {
    return `${risk.moderate.count} moderate risk${
      risk.moderate.count > 1 ? 's' : ''
    }`;
  } else {
    return '0 moderate or high risks';
  }
};

export const getRiskSummaries = (risk: RiskDetailsProps['risk']): string[] => {
  if (risk.high.count > 0 && risk.moderate.count > 0) {
    return [`High: ${risk.high.summary}`, `Moderate: ${risk.moderate.summary}`];
  } else if (risk.high.count > 0) {
    return [`High: ${risk.high.summary}`];
  } else if (risk.moderate.count > 0) {
    return [`${risk.moderate.summary}`];
  } else {
    return ['All preliminary 8 risk factors are low risk'];
  }
};
