import { RejectedApplicationResponse } from '@nirvana/api/non-fleet';
import { Dialog } from '@nirvana/ui-kit';
import { useFormContext } from 'react-hook-form-v7';

import Button from 'src/components/button';

import { useEffect } from 'react';
import { useAnalytics } from '../../../../helpers/analytics';
import { APPETITE_ERROR_MODAL_VIEW } from '../../../telematics/events';
import AppetiteError from './appetiteCheck/error';

interface AppetiteErrorDialogProps {
  error?: RejectedApplicationResponse;
  open: boolean;
  onClose: () => void;
}

const defaultValues = {
  dotNumber: '',
  companyName: '',
  effectiveDate: null,
  numberOfPowerUnits: '',
  hasUndesiredOperations: '',
};

const AppetiteErrorDialog = ({
  error,
  open,
  onClose,
}: AppetiteErrorDialogProps) => {
  const { reset } = useFormContext();
  const { capture } = useAnalytics();

  useEffect(() => {
    if (open) {
      capture(APPETITE_ERROR_MODAL_VIEW);
    }
  }, [open]);

  function handleClose() {
    reset(defaultValues);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      primaryAction={
        <Button variant="contained" color="primary" onClick={handleClose}>
          Back to Home
        </Button>
      }
      disableBackdropClose
    >
      <AppetiteError onClose={handleClose} applicationError={error} />
    </Dialog>
  );
};

export default AppetiteErrorDialog;
