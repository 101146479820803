import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import List from './components/list';
import { Instructions } from './components/Instructions';

export const Claims = () => {
  const getFeatureValue = useFeatureFlag();

  // New nirvana claims portal
  const isClaimsPortalEnabled = getFeatureValue(Feature.CLAIMS_PORTAL, false);

  return isClaimsPortalEnabled ? <List /> : <Instructions />;
};
