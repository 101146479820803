import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

/*
 * Check whether a render is initial mount or re-render
 */
export const useInitialMount = () => {
  // refs exist across component re-renders, so
  // we can use it to store a value for the
  // subsequent renders. We're tracking if it's
  // the first render, which is initially `true`
  const isFirst = useRef(true);

  // the very first render, the ref will be
  // `true`. but we immediately set it to `false`
  // so that every render after will be `false`
  if (isFirst.current) {
    isFirst.current = false;

    // return true the very first render
    return true;
  }

  // return false every following render
  return false;
};

/*
 * To know the previous value of props or state to compare it to a current value
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const prevRef = useRef<T>();
  const curRef = useRef(value);
  const isInitialMount = useInitialMount();

  if (!isInitialMount && curRef.current !== value) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
};
