import { useImpler } from '@impler/react';
import { CircularProgress } from '@material-ui/core';
import { lightTheme } from '@nirvana/ui-kit';
import { useRef } from 'react';
import Button from 'src/components/button';

const schema = [
  {
    key: 'vin',
    name: 'VIN',
    alternateKeys: [
      'Chassis',
      'VIN#',
      'VIN Number',
      '17 Digit VIN',
      '17 Digit VIN#',
      'Trailer#',
      'Serial',
      'Serial#',
      'Full VIN#',
    ],
    isRequired: true,
    isUnique: true,
    defaultValue: '<<null>>',
    type: 'Regex',
    regex:
      '^(?!(T|t)otal|TOTAL|(V|v)in|VIN|(V|v)alue|VALUE)([a-zA-Z0-9])[a-zA-Z0-9]*([a-zA-Z0-9])$',
  },
  {
    key: 'statedValue',
    name: 'Stated Value',
    alternateKeys: [
      'Amount',
      'Cost',
      'Renewal Value',
      'Stated Value',
      'Stated Amount',
      'Stated Amt',
      'Value',
      'Renewal',
      'Renewal Physical Damage',
      'Physical Damage Value',
      'Physical Damage Amount',
    ],
    isRequired: true,
    isUnique: false,
    defaultValue: '<<null>>',
    type: 'Regex',
    regex: '\\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\\.[0-9]{2})?(?![,.]?\\d)',
  },
];

type FileUploadMetadata = {
  fileId: string;
  filename: string;
};

type ImplerProps = {
  isApdCoverage?: boolean;
  onChange: (
    result: Record<string, any>[],
    metadata?: FileUploadMetadata,
  ) => void;
  onMetaChange?: (metadata?: FileUploadMetadata) => void;
};

const Impler = ({ isApdCoverage, onChange, onMetaChange }: ImplerProps) => {
  const fileMetadataRef = useRef<FileUploadMetadata | undefined>(undefined);

  const { closeWidget, showWidget, isImplerInitiated } = useImpler({
    templateId: import.meta.env.VITE_IMPLER_EQUIPMENTS_TEMPLATE_ID,
    projectId: import.meta.env.VITE_IMPLER_PROJECT_ID,
    accessToken: import.meta.env.VITE_IMPLER_ACCESS_TOKEN,
    primaryColor: lightTheme.palette.primary.main,
    title: 'Equipments List',
    extra: { isApdCoverage },
    texts: {
      COMMON: {
        UPLOAD_AGAIN: 'Restart',
      },
      FILE_DROP_AREA: {
        DROP_FILE: 'Drag and drop your file here',
        FILE_SELECTED: 'File uploaded',
      },
      PHASE1: {
        SELECT_SHEET_NAME: 'Select a sheet',
        SELECT_SHEET_NAME_PLACEHOLDER: 'Select sheet',
        SEE_MAPPING: 'Proceed',
      },
      PHASE2: {
        REVIEW_DATA: 'Proceed',
        IN_SCHEMA_TITLE: 'Required fields',
        IN_SHEET_TITLE: 'Select a field from the sheet',
      },
      PHASE3: {
        RE_REVIEW_DATA: 'Proceed',
        COMPLETE: 'Complete',
        ALL_RECORDS_VALID_TITLE: 'Validation successful!',
        ALL_RECORDS_VALID_DETAILS:
          'Importing {total} rows. Are you sure you want to proceed?',
      },
    },
    onUploadComplete: (data: any) => {
      const metadata = {
        fileId: data._id,
        filename: data.originalFileName,
      };
      fileMetadataRef.current = metadata;

      if (onMetaChange) {
        onMetaChange(metadata);
      }
    },
    onDataImported: (data: any) => {
      onChange(data, fileMetadataRef.current);
      closeWidget();
    },
  });

  return (
    <div>
      <Button
        variant="contained"
        type="button"
        disabled={!isImplerInitiated}
        onClick={() =>
          showWidget({ schema: schema as any, colorScheme: 'light' })
        }
        startIcon={
          !isImplerInitiated && (
            <CircularProgress className="text-white" size={18} />
          )
        }
      >
        {isImplerInitiated ? 'Proceed' : 'Initializing...'}
      </Button>
    </div>
  );
};

export default Impler;
