import { useParams } from 'react-router-dom';
import { EndorsementType } from '../endorsement-summary/layout';

export const usePageInfo = () => {
  const { endorsementType } = useParams();

  switch (endorsementType) {
    case EndorsementType.Drivers:
      return {
        title: 'Update drivers',
        description: 'Make changes to your drivers list. ',
      };
    case EndorsementType.Coverages:
      return {
        title: 'Update Coverages',
        description: 'Make changes to your limits',
      };
    case EndorsementType.Equipment:
      return {
        title: 'Update Equipment',
        description: 'Make changes to your Equipment list.',
      };
    case EndorsementType.Miscellaneous:
      return {
        title: 'Update Miscellaneous Info',
        description:
          'Make changes Additional Insureds, Loss Payee, and addresses ',
      };

    default:
      return {
        title: 'Modify Policy',
        description: 'Make your desired changes to your existing policy',
      };
  }
};
