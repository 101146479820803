import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProgramType, TelematicsConsentInfo } from '@nirvana/api/quoting';

import { hooks as Hooks } from 'src/helpers';

import {
  fetchApplicationById,
  RequestStatus,
  telematicsSelector,
} from '../slices';

type InitialApplicationState = {
  consentInfo?: TelematicsConsentInfo;
  status?: RequestStatus;
};

const initialApplicationState: InitialApplicationState = {};

const ApplicationContext = React.createContext<InitialApplicationState>({
  ...initialApplicationState,
});

const ApplicationProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const query = Hooks.useQuery();
  const applicationId = query.get('token');
  const programType = (query.get('programType') as ProgramType) || undefined;
  const telematics = useSelector(telematicsSelector);
  const { consentInfo, status } = telematics;

  React.useEffect(() => {
    if (applicationId) {
      dispatch(fetchApplicationById({ applicationId, programType }));
    }
  }, [dispatch, applicationId, programType]);

  return (
    <ApplicationContext.Provider
      value={{
        consentInfo,
        status,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export { ApplicationContext as Context, ApplicationProvider as Provider };
