import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProgramType,
  TelematicsConsentInfo,
  TelematicsFleetInfoResponse,
  TSP,
  TSPConnectionCompleteResponse,
  TSPConnectionResponse,
  TSPRecord,
} from '@nirvana/api/quoting';

import { RootState } from 'src/redux/reducers';

import { fetchBasicApplicationInfo } from '../actions';

export interface TelematicsError {
  message: string;
}

export declare type RequestStatus =
  | 'idle'
  | 'loading'
  | 'succeeded'
  | 'failed'
  | 'expired'
  | 'revoked';

export interface TelematicsState {
  consentInfo?: TelematicsConsentInfo;
  connections: { [key: string]: TSPConnectionResponse };
  error?: TelematicsError;
  providers: Array<TSPRecord>;
  status: RequestStatus;
  applicationInfoStatus: RequestStatus;
  telematicsResponse?: TSPConnectionCompleteResponse;
  telematicsInfo?: TelematicsFleetInfoResponse;
}

export const initialState: TelematicsState = {
  status: 'idle',
  applicationInfoStatus: 'idle',
  providers: [],
  connections: {},
};

export const fetchApplicationById = createAsyncThunk(
  'telematics/fetchApplicationById',
  async (
    data: { applicationId: string; programType?: ProgramType },
    thunkAPI: any,
  ) => {
    await thunkAPI.dispatch(
      fetchBasicApplicationInfo(data.applicationId, data.programType),
    );
  },
);

const telematicsSlice = createSlice({
  name: 'telematics',
  initialState,
  reducers: {
    setStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.status = payload;
    },
    setError: (state, { payload }: PayloadAction<TelematicsError>) => {
      state.error = payload;
    },
    setTelematicsResponse: (
      state,
      { payload }: PayloadAction<TSPConnectionCompleteResponse>,
    ) => {
      state.telematicsResponse = payload;
    },
    setBasicConsentInfo: (
      state,
      { payload }: PayloadAction<TelematicsConsentInfo>,
    ) => {
      state.consentInfo = payload;
    },
    setTSPProviders: (state, { payload }: PayloadAction<Array<TSPRecord>>) => {
      state.providers = payload;
    },
    setTSPProviderConnection: (
      state,
      {
        payload,
      }: PayloadAction<{
        provider: TSP;
        connection: TSPConnectionResponse;
      }>,
    ) => {
      state.connections = {
        [payload.provider]: payload.connection,
      };
    },
    setTelematicsInfo: (
      state,
      { payload }: PayloadAction<TelematicsFleetInfoResponse>,
    ) => {
      state.telematicsInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApplicationById.pending, (state) => {
      state.applicationInfoStatus = 'loading';
    });

    builder.addCase(fetchApplicationById.fulfilled, (state) => {
      state.applicationInfoStatus = 'succeeded';
    });

    builder.addCase(fetchApplicationById.rejected, (state) => {
      state.applicationInfoStatus = 'failed';
    });
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = telematicsSlice;

// Extract and export each action creator by name
export const {
  setBasicConsentInfo,
  setTSPProviders,
  setTSPProviderConnection,
  setStatus,
  setError,
  setTelematicsResponse,
  setTelematicsInfo,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const telematicsSelector = (state: RootState) => state.telematics;
