import { Box, makeStyles } from '@material-ui/core';
import Complete from 'src/features/application/components/create/complete';

const useStyles = makeStyles({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Success = () => {
  const classes = useStyles();

  return (
    <Box flex={1} className={classes.center}>
      <Complete
        title="Congratulations! Your application is under review"
        subtitle="We're on it! We will notify you when the quote is ready to bind."
      />
    </Box>
  );
};

export default Success;
