import { z } from 'zod';

const getOperationsValidationSchema = (hasGl?: boolean) =>
  z.object({
    projectedMileage: z.number().min(0),
    projectedNumberOfPowerUnits: z.number().min(0),
    equipmentList: z.object({
      info: z.array(z.any()).min(0),
      flatFileMetadata: z.any(),
    }),
    terminalLocations: hasGl
      ? z.unknown().refine((val) => val !== null && val !== undefined)
      : z.any(),
  });

export default getOperationsValidationSchema;
