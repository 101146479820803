import { AxiosResponse } from 'axios';

import {
  Configuration,
  ApplicationApi,
  IndicationOptions,
} from '@nirvana/api/quoting';
import { RootState } from 'src/redux/reducers';

import {
  setLoading,
  setStatus,
  setListSuccess,
  setListFail,
} from '../slices/indicationOptions';

export const resetStatus = () => (dispatch: any) => {
  dispatch(setLoading(false));
  dispatch(setStatus('idle'));
};

export const fetchIndicationOptions =
  (applicationId: string) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { auth } = getState();

      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      const response: AxiosResponse<IndicationOptions> =
        await apiService.applicationApplicationIDIndicationOptionsGet(
          applicationId,
        );
      const { data } = response;

      // Dispatch action to store supported operations in global app state
      setTimeout(() => {
        dispatch(setListSuccess(data));
        dispatch(setLoading(false));
      }, 1500);
    } catch (ex: any) {
      return dispatch(setListFail({ message: ex.message }));
    }
  };
