import { makeStyles } from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  inputLabel: {
    ...theme.typography.body1,
    whiteSpace: 'normal',
    color: theme.palette.text.hint,
  },
  card: {
    border: '1px solid #E6E7EF',
    boxShadow: '0px 4px 6px rgba(128, 145, 196, 0.16)',
  },
  cardHeader: {
    borderBottom: '1px solid #E6E7EF',
  },
  cardHeaderContent: {
    flex: 'unset',
  },
  editButton: {
    color: theme.palette.info.main,
    marginLeft: '12px',
  },
  footer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'end',
    backgroundColor: theme.palette.primary.extraLight,
    borderTop: '1px solid #E6E7EF',
    borderColor: theme.palette.border.main,
    height: 72,
    padding: theme.spacing(2, 2),
    width: '100%',
  },
}));
