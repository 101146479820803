import { useState } from 'react';
import { Button, FormHelperText, Grid, Typography } from '@material-ui/core';
import { VehicleDetails } from '@nirvana/api/non-fleet';
import IconFileUpload from 'src/assets/icons/upload-primary.svg';
import { FlatFileUpload } from 'src/components/button';

import { useStyles } from './styles';

const FlatFileUploader = ({
  onSuccess,
}: {
  onSuccess: (data?: VehicleDetails[]) => void;
}) => {
  const classes = useStyles();
  const [renderKey, setRenderKey] = useState(1);

  return (
    <Grid item>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <Typography variant="subtitle1">
            Please upload the list of vehicles
          </Typography>
        </Grid>
        <Grid item key={renderKey}>
          <FlatFileUpload
            variant="contained"
            type="Equipment"
            title="Upload Equipment List"
            fields={[
              {
                label: 'VIN',
                alternates: [
                  'Chassis',
                  'VIN#',
                  'VIN Number',
                  '17 Digit VIN',
                  '17 Digit VIN#',
                  'Trailer#',
                  'Serial',
                  'Serial#',
                  'Full VIN#',
                ],
                key: 'vin',
                description: 'Uniquely identifies your vehicle',
                validators: [
                  {
                    validate: 'required',
                    error:
                      'VIN is required for power units. For trailers without VINs, use "Trailer#" (example "Trailer1", "Trailer2", etc...)',
                  },
                  {
                    validate: 'regex_matches',
                    error:
                      'A valid VIN is required for power units. For trailers without VINs, use "Trailer#" (no spaces, example "Trailer1", "Trailer2", etc...)',
                    regex:
                      '^(?!(T|t)otal|TOTAL|(V|v)in|VIN|(V|v)alue|VALUE)([a-zA-Z0-9])[a-zA-Z0-9]*([a-zA-Z0-9])$',
                  },
                  {
                    validate: 'unique',
                    error:
                      'Duplicate VINs are not allowed. For trailers, use "Trailer#" (example "Trailer1", "Trailer2", etc...)',
                  },
                ],
              },
              {
                label: 'Stated Value',
                alternates: [
                  'Amount',
                  'Cost',
                  'Renewal Value',
                  'Stated Value',
                  'Stated Amount',
                  'Stated Amt',
                  'Value',
                  'Renewal',
                  'Renewal Physical Damage',
                  'Physical Damage Value',
                  'Physical Damage Amount',
                ],
                key: 'statedValue',
                description: 'Stated value for your vehicle',
                validators: [
                  {
                    validate: 'regex_matches',
                    error: 'Stated value should be a valid amount.',
                    regex: '^(\\d*\\.?\\d+)(,\\d*\\.?\\d*)*$',
                  },
                  {
                    validate: 'regex_matches',
                    error:
                      'Valid stated value is required for Physical Damage. For no Physical Damage, use "0".',
                    regex:
                      '^(?!(V|v)alue|VALUE|(R|r)enewal|RENEWAL|(S|s)tated|STATED|(C|c)ost|COST|(A|a)mount)|AMOUNT',
                  },
                ],
              },
            ]}
            fieldHooks={{
              vin: (values) => {
                return values.map(([item, index]) => {
                  const newItem =
                    typeof item === 'string'
                      ? item.replace(/[$,.\s]/g, '')
                      : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message:
                              'Removed invalid characters and whitespaces',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
              statedValue: (values) => {
                return values.map(([item, index]) => {
                  const newItem =
                    typeof item === 'string'
                      ? ~~item.replace(/[$,]/g, '')
                      : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else if (
                    newItem &&
                    !/^(\d*\.?\d+)(,\d*\.?\d*)*$/.test(newItem.toString())
                  ) {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Stated value should be a valid number.',
                            level: 'error',
                          },
                        ],
                      },
                    ];
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Removed invalid characters and decimals',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
            }}
            value={{} as any}
            onChange={(results) => {
              if (results) {
                const equipmentList = {
                  info: results.validData.map(
                    ({
                      vin,
                      statedValue,
                      ...rest
                    }: Partial<VehicleDetails>) => {
                      const newData: Partial<VehicleDetails> = {
                        ...rest,
                        vin: vin,
                      };

                      if (statedValue) {
                        newData.statedValue =
                          typeof statedValue === 'number'
                            ? statedValue
                            : ~~(statedValue as any).replace(/[$,]/g, '') || 0;
                      }

                      return newData;
                    },
                  ),
                  flatfileMetadata: {
                    flatfileHandle: results.$meta.batchID,
                    fileMetadata: {
                      name: results.$meta.filename,
                    },
                  },
                };

                onSuccess(equipmentList.info);
              } else {
                onSuccess(undefined);
              }

              setRenderKey((prevState) => prevState + 1);
            }}
            render={(_, launch) => {
              return (
                <Button
                  variant="outlined"
                  onClick={launch}
                  startIcon={<img src={IconFileUpload} alt="File Upload" />}
                >
                  Upload
                </Button>
              );
            }}
          />
        </Grid>
        <Grid item>
          <FormHelperText>
            or click on the button below to add them
          </FormHelperText>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FlatFileUploader;
