import {
  EndorsementRequestDetails,
  EndorsementRequestState,
} from '@nirvana/api/endorsementapp';
import { Show, TableV8 } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { endorsementColumns } from '../constants/endorsement';
import { listEndorsements } from '../queries/endorsement';

export default function EndorsementTab() {
  const [searchParams] = useSearchParams();
  const { policyId = '' } = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(
    ['endorsements-list', policyId],
    () => listEndorsements(policyId),
    {
      select: (data) => data.endorsementRequests,
      enabled: !!policyId,
    },
  );

  const isEndorsementEditAllowed = (
    endorsementState: EndorsementRequestState,
  ) => {
    if (
      endorsementState ===
        EndorsementRequestState.EndorsementRequestStateCreated ||
      endorsementState ===
        EndorsementRequestState.EndorsementRequestStateUnderUwReview
    ) {
      return true;
    }

    return false;
  };

  const handleRowClick = (row: EndorsementRequestDetails) => {
    if (isEndorsementEditAllowed(row.state)) {
      navigate(`/policies/${policyId}/endorsement/${row.endorsementRequestID}`);
    }
  };

  return (
    <Show when={searchParams.get('tab') === 'endorsements'}>
      <h2 className="mb-5 text-xl font-medium">Endorsements</h2>

      <div className="overflow-hidden bg-white border rounded-lg border-text-disabled">
        <TableV8
          columns={endorsementColumns}
          data={data ?? []}
          onRow={(row) => {
            return {
              className: isEndorsementEditAllowed(row.state)
                ? 'cursor-pointer'
                : 'cursor-not-allowed',
              onClick: () => handleRowClick(row),
            };
          }}
        />
      </div>
    </Show>
  );
}
