import * as React from 'react';
import { Column, useSortBy, useTable } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import Show from '../show';
import Switch from '../switch-match';
import { cn } from '../../utils/tw-merge';
import TableSkeletonLoader from '../table-skeleton-loader';
import SortableHeader from './sortable-header';

type RowOptions = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
};

type CellOptions = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLTableCellElement>) => void;
};

type TableProps<T extends object> = {
  /** Columns prop allows you to define column header and cell styles for that column
   * For more details, refer: https://react-table-v7.tanstack.com/docs/api/useTable#column-options
   */
  columns: Array<Column<T>>;
  data: Array<T>;
  isLoading?: boolean;
  onRow?: (row: T, index: number) => RowOptions;
  onCell?: (cell: any) => CellOptions;
  hasNextPage: boolean;
  onLoadMore: (
    startIndex: number,
    stopIndex: number,
  ) => void | Promise<void> | undefined;
  sortAllowedColumns?: string[];
};

export default function Table<T extends object>({
  columns,
  data,
  isLoading,
  onCell,
  onRow,
  hasNextPage,
  onLoadMore,
  sortAllowedColumns,
}: TableProps<T>) {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    footerGroups,
  } = useTable(
    { columns, data, defaultColumn: { Header: SortableHeader } },
    useSortBy,
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      let className, onClick;
      if (onRow) {
        className = onRow(row.original, index).className;
        onClick = onRow(row.original, index).onClick;
      }

      return (
        <div
          {...row.getRowProps({ style })}
          key={index}
          className={cn(
            'text-sm text-text-primary flex items-center',
            className,
          )}
          onClick={onClick}
        >
          {row.cells.map((cell, index) => {
            let className, onClick;
            if (onCell) {
              className = onCell(cell.value).className;
              onClick = onCell(cell.value).onClick;
            }
            return (
              <div
                {...cell.getCellProps({
                  style: {
                    width: cell.column.width,
                  },
                })}
                key={index}
                className={cn('px-4 py-2 font-normal', className)}
                onClick={onClick}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, onCell, onRow],
  );

  const itemCount = hasNextPage ? rows.length + 1 : rows.length;

  return (
    <div {...getTableProps} className="flex flex-col w-full h-full">
      <div>
        {headerGroups.map((headerGroup, idx) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className="flex overflow-hidden rounded-lg"
            key={idx}
          >
            {headerGroup.headers.map((column, idx) => (
              <div
                {...column.getHeaderProps({
                  ...column.getSortByToggleProps,
                  style: {
                    width: column.width,
                  },
                })}
                key={idx}
                className="sticky top-0 z-10 px-4 py-3 text-xs font-normal text-left bg-primary-extraLight text-secondary-dark"
                onClick={() => {
                  if (sortAllowedColumns?.includes(column.id)) {
                    column.toggleSortBy();
                  }
                }}
              >
                <span
                  className={cn('flex items-center h-5', {
                    'font-bold text-secondary-dark': column.isSorted,
                    'cursor-pointer': sortAllowedColumns?.includes(column.id),
                  })}
                >
                  {column.render('Header')}

                  {sortAllowedColumns?.includes(column.id) && (
                    <span className="flex flex-col items-center ml-2">
                      <KeyboardArrowUp
                        className={cn('!p-0 !m-0 text-sm', {
                          'opacity-40': !column.isSorted || column.isSortedDesc,
                        })}
                      />

                      <KeyboardArrowDown
                        className={cn('!p-0 !m-0 text-sm', {
                          'opacity-40':
                            !column.isSorted || !column.isSortedDesc,
                        })}
                      />
                    </span>
                  )}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps()} className="flex-1">
        <Switch>
          <Switch.Match when={isLoading}>
            <table className="w-full">
              <TableSkeletonLoader columns={columns.length} />
            </table>
          </Switch.Match>

          <Switch.Match when={data}>
            <InfiniteLoader
              isItemLoaded={(index) => index < rows.length}
              itemCount={itemCount}
              loadMoreItems={onLoadMore}
            >
              {({ onItemsRendered, ref }) => (
                <AutoSizer className="scrollableTableContent">
                  {({ width, height }) => (
                    <List
                      height={height}
                      width={width}
                      itemCount={rows.length}
                      itemSize={60}
                      ref={ref}
                      onItemsRendered={onItemsRendered}
                    >
                      {RenderRow}
                    </List>
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </Switch.Match>
        </Switch>
      </div>

      <Show when={columns.some((column) => !!column.Footer)}>
        <div>
          {footerGroups.map((group, idx) => (
            <div {...group.getFooterGroupProps()} key={idx}>
              {group.headers.map((column, idx) => (
                <div
                  {...column.getFooterProps()}
                  key={idx}
                  className="px-4 py-2 font-medium border-primary-extraLight border-y text-text-primary"
                >
                  {column.render('Footer')}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Show>
    </div>
  );
}
