import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useStyles } from '@nirvana/ui-kit/src/components/applicationHeader/styles';

export default function ApplicationHeaderClearedApplicationExistsDeclined() {
  const classes = useStyles();

  return (
    <>
      <div className="flex items-center justify-around h-12 bg-warning-light">
        <div className="flex items-center">
          <span className="mr-2">
            <InfoOutlinedIcon
              className={classes.declinedIcon}
              fontSize="small"
            />
          </span>
          <span className="mr-2 font-semibold">
            Application previously declined
          </span>{' '}
          Proceed only if you have new information that significantly changes
          the risk outlook.
        </div>
      </div>
    </>
  );
}
