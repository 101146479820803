import { TableView } from 'src/components/table';
import { application as ApplicationHelper } from 'src/helpers';
import Section from 'src/features/application/components/create/review/section';
import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';

const ClassesAndCommoditiesView = () => {
  const { applicationDetails } = useApplicationDetailsContext();
  const classesAndCommodities =
    applicationDetails?.nrb?.classesAndCommoditiesForm;

  return (
    <Section
      onEdit={() => {}}
      title="Classes & Commodities"
      applicationData={{} as any}
      data={[
        {
          key: 'restrictedClasses',
          label: 'Restricted Classes',
          renderValue: () =>
            classesAndCommodities?.classInfo?.ownsRestrictedClasses
              ? 'Yes'
              : 'No',
        },
        {
          key: 'primaryOperatingClass',
          label: 'primary Operating Class',
          renderValue: () =>
            ApplicationHelper.getOperatingClassLabel(
              classesAndCommodities?.classInfo?.primaryOperatingClass,
            ),
        },
        {
          key: 'restrictedCommodities',
          label: 'Restricted Commodities',
          renderValue: () =>
            classesAndCommodities?.commodityInfo?.haulsRestrictedCommodities
              ? 'Yes'
              : 'No',
        },
        {
          key: 'commoditiesHauled',
          label: 'Commodities Hauled',
          renderValue: () => (
            <TableView
              headers={[
                { key: 'commodity', content: 'Commodity' },
                { key: 'category', content: 'Category' },
                { key: '%OfHauls', content: '% of Hauls' },
              ]}
              rows={
                classesAndCommodities?.commodityInfo?.commodityDistribution?.map(
                  (item) => ({
                    columns: [
                      { key: 'commodity', content: item.name },
                      { key: 'category', content: item.categoryLabel },
                      { key: '%OfHauls', content: `${item.percentage}%` },
                    ],
                  }),
                ) ?? []
              }
            />
          ),
        },
      ]}
    />
  );
};

export default ClassesAndCommoditiesView;
