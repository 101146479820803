import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import { CoverageType } from '@nirvana/api/quoting';

import { indicationOptionsSelector } from 'src/features/application/slices/indicationOptions';

import LimitsSkeleton from './skeleton';
import LimitsSelector from './selector';
import { useStyles } from './../styles';

export interface ILimits {
  loading: boolean;
  hasMTC: boolean;
  onChange: (e: any) => void;
}

const Limits = ({ loading, hasMTC, onChange }: ILimits) => {
  const classes = useStyles();
  const indicationOptionsData = useSelector(indicationOptionsSelector);
  const { coverageVariablesOptions } = indicationOptionsData;
  const limitsByCoverage = useMemo(() => {
    const result = {};

    for (let i = 0, l = coverageVariablesOptions.length; i < l; i += 1) {
      if (
        coverageVariablesOptions[i].coverage ===
          CoverageType.CoverageGeneralLiability ||
        !coverageVariablesOptions[i].limits
      ) {
        continue;
      }

      result[coverageVariablesOptions[i].coverage] =
        coverageVariablesOptions[i].limits;
    }

    return result;
  }, [coverageVariablesOptions]);

  if (!hasMTC) {
    return null;
  }

  if (!Object.keys(limitsByCoverage).length) {
    return <LimitsSkeleton />;
  }

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={5}>
        <InputLabel
          htmlFor="deductiblesOnCoverage-input"
          className={classes.inputLabel}
        >
          Limits
        </InputLabel>
        <FormHelperText>Coverage limit per claim</FormHelperText>
      </Grid>
      <Grid item xs={7} container spacing={2} alignItems="center">
        {Object.keys(limitsByCoverage).map((coverage) => {
          const deductibleRecord = limitsByCoverage[coverage];

          return (
            <Grid item xs={6} key={coverage}>
              <LimitsSelector
                coverage={coverage as CoverageType}
                disabled={loading}
                options={deductibleRecord.options || []}
                value={deductibleRecord.current}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Limits;
