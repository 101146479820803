import { createColumnHelper } from '@tanstack/react-table';
import { Chip, IChipProps } from '@nirvana/ui-kit';
// import DownloadIcon from 'src/assets/icons/download.svg';
import {
  EndorsementRequestDetails,
  EndorsementRequestState,
} from '@nirvana/api/endorsementapp';
import { format, parseISO } from 'date-fns';

import ErrorIcon from 'src/assets/icons/cross-circle-sm.svg';
import SuccessIcon from 'src/assets/icons/check-circle-sm.svg';

function getEndorsementStatusChipProps(status: string): IChipProps {
  switch (status) {
    case EndorsementRequestState.EndorsementRequestStateCreated:
      return { label: 'Created', color: 'info' };
    case EndorsementRequestState.EndorsementRequestStateApproved:
      return { label: 'Approved', color: 'success' };
    case EndorsementRequestState.EndorsementRequestStateDeclined:
      return {
        label: 'Declined',
        color: 'error',
        icon: <img src={ErrorIcon} />,
      };
    case EndorsementRequestState.EndorsementRequestStateUnderUwReview:
      return { label: 'UW Review', color: 'info' };
    case EndorsementRequestState.EndorsementRequestStateBound:
      return {
        label: 'Bound',
        color: 'success',
        icon: <img src={SuccessIcon} />,
      };
    case EndorsementRequestState.EndorsementRequestStateClosed:
      return { label: 'Closed', color: 'grey' };
    case EndorsementRequestState.EndorsementRequestStateInvalid:
      return { label: 'Invalid', color: 'error' };
    default:
      return { label: status, color: 'warning' };
  }
}

const columnHelper = createColumnHelper<EndorsementRequestDetails>();

export const endorsementColumns = [
  columnHelper.accessor('endorsementNumber', {
    size: 500,
    header: 'ID & Changes',
    cell: ({ getValue, row }) => (
      <div className="text-text-primary">
        <p className="mb-0.5 font-bold">#{getValue()}:</p>
        <p className="text-xs">
          {row.original.endorsementDescription?.join(', ')}
        </p>
      </div>
    ),
  }),
  // columnHelper.accessor('premium', { header: 'Premium' }),
  columnHelper.accessor('effectiveDate', {
    header: 'Effective Date',
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <p className="text-text-primary">
          {value ? format(parseISO(value), 'MMM dd, yyyy') : '-'}
        </p>
      );
    },
  }),
  columnHelper.accessor('state', {
    header: 'Status',
    cell: ({ getValue }) => (
      <Chip {...getEndorsementStatusChipProps(getValue())} />
    ),
  }),
  // columnHelper.display({
  //   id: 'download',
  //   cell: () => (
  //     <IconButton size="small">
  //       <img src={DownloadIcon} alt="Download File" width={16} />
  //     </IconButton>
  //   ),
  // }),
];
