import ApplicationContainer from '../applicationContainer';
import ApplicationCreate from './create';

const NFCreate = () => {
  return (
    <ApplicationContainer>
      <ApplicationCreate />
    </ApplicationContainer>
  );
};

export default NFCreate;
