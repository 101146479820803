import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { ArrowForwardIosRounded } from '@material-ui/icons';
import { Dialog, InputNumeric, Switch } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import React, { ChangeEvent, FocusEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { useNavigate } from 'react-router-dom';
import CheckIcon from 'src/assets/icons/appetite-lite/check.svg?react';
import { prefillApplication } from 'src/features/nonFleet/queries/application';
import {
  APPETITE_LITE_CONTINUE_CLICK,
  APPETITE_LITE_DOT_NUMBER_INPUT,
  APPETITE_LITE_MODAL_CLOSE,
} from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import AppetiteLiteError from './appetiteLite-error-modal/appetiteLite-error-modal';

const defaultValues = {
  dotNumber: '',
  companyName: '',
  hasUndesiredOperations: undefined,
};

const options = [
  'Logging',
  'Dump',
  'Driveaway',
  'Hazardous Materials',
  'Waste',
  'Hot Shot/Expedited',
  'PPT vehicles',
  'Auto-haulers',
  'Team Driving',
  'Livestock',
  'Residential Delivery',
];

interface CheckAppetiteModalProps {
  open: boolean;
  onClose: (navigateBack?: boolean) => void;
}

interface FormData {
  dotNumber: string;
  companyName: string;
  hasUndesiredOperations: 'true' | 'false';
}

const CheckAppetiteModal: React.FC<CheckAppetiteModalProps> = ({
  open,
  onClose,
}) => {
  const [errorsState, setErrorsState] = useState({
    dotNotFoundError: false,
    unitCountError: false,
    stateActiveError: false,
    isChecking: false,
    isDotChanged: false,
    hasAppetiteError: false,
    usState: null as string | null,
  });

  const { capture } = useAnalytics();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    reset,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useFormContext<FormData>();

  const { mutate: prefillDotNumber } = useMutation(prefillApplication, {
    onSuccess: (data) => {
      setErrorsState((prev) => ({
        ...prev,
        isChecking: false,
        isDotChanged: false,
      }));

      if (!data.isStateActive) {
        setErrorsState((prev) => ({
          ...prev,
          stateActiveError: true,
          usState: data.usState,
        }));
        setValue('companyName', '');
      } else if (data.last_reported_power_unit_count <= 10) {
        setErrorsState((prev) => ({ ...prev, unitCountError: true }));
        setValue('companyName', '');
      } else {
        setValue('companyName', data.name, { shouldValidate: true });
        setErrorsState({
          dotNotFoundError: false,
          unitCountError: false,
          stateActiveError: false,
          isChecking: false,
          isDotChanged: false,
          hasAppetiteError: false,
          usState: null,
        });
      }
    },
    onError: () => {
      setErrorsState((prev) => ({
        ...prev,
        isChecking: false,
        dotNotFoundError: true,
        isDotChanged: false,
      }));
      setValue('companyName', '');
    },
  });

  const handleDotBlur = (event: FocusEvent<HTMLInputElement>) => {
    const dotNumber = event.target.value;
    if (dotNumber) {
      setErrorsState((prev) => ({ ...prev, isChecking: true }));
      capture(APPETITE_LITE_DOT_NUMBER_INPUT, { dotNumber });
      prefillDotNumber({ dotNumber: +dotNumber });
    } else {
      setValue('companyName', '');
    }
  };

  const handleDotChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrorsState({
      dotNotFoundError: false,
      unitCountError: false,
      stateActiveError: false,
      isDotChanged: true,
      isChecking: false,
      hasAppetiteError: false,
      usState: null,
    });
    setValue('dotNumber', event.target.value);
  };

  const handleClose = (navigateBack = false) => {
    capture(APPETITE_LITE_MODAL_CLOSE);
    reset(defaultValues);
    onClose(navigateBack);
  };

  const handleContinue = (data: FormData) => {
    capture(APPETITE_LITE_CONTINUE_CLICK, { dotNumber: data.dotNumber });
    if (data.hasUndesiredOperations === 'true') {
      setErrorsState((prev) => ({ ...prev, hasAppetiteError: true }));
    } else {
      navigate(`/appetite-lite/${data.dotNumber}`);
      handleClose(false);
    }
  };

  const companyName = watch('companyName');
  const dotNumber = watch('dotNumber');
  const hasUndesiredOperations = watch('hasUndesiredOperations');

  const isContinueDisabled =
    !dotNumber ||
    !companyName ||
    errorsState.dotNotFoundError ||
    errorsState.unitCountError ||
    errorsState.stateActiveError ||
    hasUndesiredOperations === undefined ||
    errorsState.isDotChanged;

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={() => handleClose(true)}
        PaperProps={{ style: { width: '40rem', maxHeight: '80vh' } }}
        title={<p className="text-sm font-bold">Check Appetite</p>}
        primaryAction={
          <Button
            variant="contained"
            onClick={handleSubmit(handleContinue)}
            endIcon={<ArrowForwardIosRounded />}
            disabled={isContinueDisabled}
          >
            Continue
          </Button>
        }
        disableBackdropClose
      >
        <hr className="-mx-6 -mt-6" />
        <Box position="relative">
          <div className="flex flex-col pt-4">
            <div className="mb-4">
              <div className="mb-4 text-xl font-bold text-text-primary">
                Check your insured&apos;s risk
              </div>
              <p className="text-sm text-text-primary">
                Enter the DOT to see how FMCSA carrier and insurance data
                affects how we consider risk for the account.
              </p>
            </div>

            <div className="mb-4">
              <FormControl fullWidth error={!!errors.dotNumber}>
                <InputNumeric
                  placeholder="Enter DOT"
                  {...register('dotNumber', {
                    required: 'Please enter a valid DOT number',
                  })}
                  onBlur={handleDotBlur}
                  onChange={handleDotChange}
                  decimalScale={0}
                  thousandSeparator={false}
                  error={
                    !!errors.dotNumber ||
                    errorsState.dotNotFoundError ||
                    errorsState.unitCountError ||
                    errorsState.stateActiveError
                  }
                />
                <FormHelperText>
                  <Switch>
                    <Switch.Match when={errors?.dotNumber?.message}>
                      <span>{errors?.dotNumber?.message}</span>
                    </Switch.Match>
                    <Switch.Match
                      when={errorsState.dotNotFoundError && dotNumber}
                    >
                      <span className="text-error-main">
                        DOT not found. Try again with a valid Fleet DOT
                      </span>
                    </Switch.Match>
                    <Switch.Match
                      when={errorsState.unitCountError && dotNumber}
                    >
                      <span className="text-error-main">
                        DOT has &lt;10 units according to FMCSA. Appetite
                        checker is only available for fleets with 10+ units.
                      </span>
                    </Switch.Match>
                    <Switch.Match
                      when={errorsState.stateActiveError && dotNumber}
                    >
                      <span className="text-error-main">
                        Nirvana is not live in {errorsState.usState}. Please
                        check back in the future
                      </span>
                    </Switch.Match>
                    <Switch.Match when={errorsState.isChecking}>
                      <CircularProgress size={16} />
                    </Switch.Match>
                    <Switch.Match when={companyName}>
                      <div className="flex items-center">
                        <CheckIcon className="mr-1" />
                        <span className="text-xs text-text-primary">
                          {companyName}
                        </span>
                      </div>
                    </Switch.Match>
                  </Switch>
                </FormHelperText>
              </FormControl>
            </div>

            <div className="mb-4">
              <p className="my-4 text-sm text-text-primary">
                Does the insured have{' '}
                <span className="font-semibold">more than 5%</span> of their
                operations in one of the listed classes?
              </p>
              <div className="flex justify-between">
                <FormControl
                  component="fieldset"
                  error={!!errors.hasUndesiredOperations}
                >
                  <Controller
                    name="hasUndesiredOperations"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: 'Please select an option' }}
                    render={({ field }) => (
                      <RadioGroup {...field} aria-label="restricted-classes">
                        <FormControlLabel
                          value="false"
                          control={<Radio color="primary" size="small" />}
                          label="No"
                          className="mb-2"
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio color="primary" size="small" />}
                          label="Yes"
                          className="mb-2"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText>
                    {errors.hasUndesiredOperations?.message}
                  </FormHelperText>
                </FormControl>
                <div className="flex flex-wrap w-2/3 p-4 rounded-md bg-primary-extraLight">
                  {options.map((option, index) => (
                    <div key={`option-${index}`} className="w-1/2 mb-2">
                      <span>• {option}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Dialog>
      <AppetiteLiteError
        open={errorsState.hasAppetiteError}
        onClose={() => {
          setErrorsState((prev) => ({ ...prev, hasAppetiteError: false }));
          handleClose(false);
        }}
      />
    </>
  );
};

export default CheckAppetiteModal;
