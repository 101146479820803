import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import { AdmittedAppPackages } from '@nirvana/api/non-fleet';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { Show } from '@nirvana/ui-kit';

import PlanSelector from './selector';
import PlanSkeleton from './skeleton';

export interface IPlans {
  loading: boolean;
  options?: AdmittedAppPackages[];
}

const Plans = ({ loading, options = [] }: IPlans) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <InputLabel
            sx={{
              color: 'text.primary',
              fontWeight: 'fontWeightMedium',
              whiteSpace: 'normal',
            }}
          >
            Plans
          </InputLabel>
          <FormHelperText>
            Please select the coverage plan for the insured
          </FormHelperText>
        </Grid>
      </Grid>
      <Show when={(errors?.indicationForm as any)?.selectedIndication?.id}>
        <Grid item>
          <FormHelperText error>
            {(errors?.indicationForm as any)?.selectedIndication?.id?.message}
          </FormHelperText>
        </Grid>
      </Show>

      <Show when={!loading} fallback={<PlanSkeleton />}>
        <Grid item container spacing={1} alignItems="flex-start">
          {options.map((record) => {
            return (
              <Controller
                key={record.id}
                control={control}
                name="indicationForm.selectedIndication.id"
                rules={{
                  required: 'Please select a plan',
                }}
                defaultValue=""
                render={({ field: { value, onChange }, fieldState }) => {
                  return (
                    <PlanSelector
                      value={value}
                      planDetails={record}
                      onChange={(planDetails) => {
                        onChange(planDetails.id);
                      }}
                      error={!!fieldState.error?.message}
                    />
                  );
                }}
              />
            );
          })}
        </Grid>
      </Show>
    </Grid>
  );
};

export default Plans;
