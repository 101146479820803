import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

import { CoverageType, PrimaryCommodityHauled } from '@nirvana/api/quoting';

import { fetchOperations } from '../../../actions/classesAndCommodities';
import { operationSelector } from '../../../slices/classesAndCommodities';
import OperatingClasses from './classes';
import PrimaryCommodity from './primaryCommodity';
import PrimaryCategory from './primaryCategory';
import CommodityDistribution from './commodityDistribution';

export interface IOperatingClassProps {
  coverages: CoverageType[];
  isLegacyApplication: boolean;
  applicationId: string;
  watch: any;
}

export type CommodityOption = {
  classId: string;
  commodity: PrimaryCommodityHauled;
};

/**
 * Form to capture class wise percentage distribution and primary commodity.
 * Corresponds to OC & Commodity (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A12317&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const ClassesAndCommodities = ({
  coverages = [],
  isLegacyApplication,
  applicationId,
  watch,
}: IOperatingClassProps) => {
  const [commodityOptions, setCommodityOptions] = React.useState<
    CommodityOption[]
  >([]);
  const dispatch = useDispatch();
  const operation = useSelector(operationSelector);
  const { control, setValue } = useFormContext();
  const { commoditiesByClass, list, categories } = operation;
  const hasMTC = React.useMemo(() => {
    return coverages.includes(CoverageType.CoverageMotorTruckCargo);
  }, [coverages]);
  const values = watch(['operatingClassDistribution'], {
    operatingClassDistribution: [],
  });
  const totalFleetPercentage: number =
    values &&
    values.operatingClassDistribution &&
    values.operatingClassDistribution.reduce(
      (acc: number, record: { percentageOfFleet: number }) =>
        acc + (+record?.percentageOfFleet || 0),
      0,
    );

  // Fetch list of supported operations on mount
  React.useEffect(() => {
    // Fetch list of operations
    dispatch(fetchOperations({ applicationId }));
  }, [dispatch, applicationId]);

  React.useEffect(() => {
    if (totalFleetPercentage > 0) {
      const maxPercentage: number = Math.max.apply(
        null,
        values.operatingClassDistribution.map(
          (record: any) => (record && record.percentageOfFleet) || 0,
        ),
      );

      // Create a list of commodities for selection, by finding the
      // class that has the highest percentage of distribution.
      // Edge case: In case 2 or more classes have the exact same percentage
      // then the commodities of both classes are merged together for selection.
      let commodities: Array<CommodityOption> = [];
      for (
        let i = 0, l = values.operatingClassDistribution.length;
        i < l;
        i += 1
      ) {
        const record = values.operatingClassDistribution[i];
        if (record && record.percentageOfFleet === maxPercentage) {
          commodities = commodities.concat(
            commoditiesByClass[record.operatingClass].map(
              (commodity: PrimaryCommodityHauled) => ({
                classId: record.operatingClass,
                commodity,
              }),
            ),
          );
        }
      }

      setCommodityOptions(commodities);
    } else {
      setCommodityOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFleetPercentage, commoditiesByClass]);

  React.useEffect(() => {
    if (hasMTC || !isLegacyApplication) {
      const { operatingClassDistribution } = values;

      let maxClass = { operatingClass: '', percentageOfFleet: -1 };
      for (let i = 0, l = operatingClassDistribution.length; i < l; i += 1) {
        if (
          operatingClassDistribution[i].percentageOfFleet >
          maxClass.percentageOfFleet
        ) {
          maxClass = operatingClassDistribution[i];
        }
      }

      setValue('primaryOperatingClass', maxClass.operatingClass);
    }
  }, [hasMTC, values, setValue, isLegacyApplication]);

  return (
    <form>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h4" fontWeight="fontWeightBold">
            Classes & Commodities
          </Typography>
        </Grid>
        <Grid item>
          <OperatingClasses
            list={list}
            totalFleetPercentage={totalFleetPercentage}
            layout={!hasMTC ? 'row' : 'column'}
          />
        </Grid>

        {isLegacyApplication && !hasMTC ? (
          <PrimaryCommodity list={commodityOptions} layout="row" />
        ) : !hasMTC ? (
          <PrimaryCategory list={categories} layout="row" />
        ) : null}

        {hasMTC && <CommodityDistribution list={categories} />}

        <Controller
          control={control}
          name="primaryOperatingClass"
          defaultValue=""
          render={(props) => <input type="hidden" value={props.value} />}
        />
      </Grid>
    </form>
  );
};

const ClassesAndCommoditiesContainer = (props: any) => {
  const methods = useFormContext();

  return <ClassesAndCommodities {...methods} {...props} />;
};

export default ClassesAndCommoditiesContainer;
