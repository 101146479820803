import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from '@nirvana/ui-kit';

export const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
  },
}));
