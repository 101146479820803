import React, { FC, ReactNode } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightMedium,
  },
  pageHeader: {
    justifyContent: 'space-between',
  },
  grow: {
    flex: 1,
  },
}));

export interface IPageHeaderProps {
  title?: string | ReactNode;
  actions?: ReactNode;
}

/**
 * Header component that can be imported on any page which has a title and/or some actions.
 * Using this component help maintain consistency across the page titles in the application.
 * @component
 *
 * @param {Object} props
 * @param {string} props.title - Title of page.
 * @param {Object} props.actions - React node as actions to be rendered.
 *
 * @example
 * return <PageHeader title="Applications" />
 */
const PageHeader: FC<IPageHeaderProps> = ({ title, actions }) => {
  const classes = useStyles();

  let titleDom = title;
  if (typeof title === 'string') {
    titleDom = (
      <Typography variant="h3" color="textPrimary" className={classes.title}>
        {title}
      </Typography>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      alignItems="flex-end"
      className={classes.pageHeader}
      wrap="nowrap"
    >
      <Grid item>{titleDom}</Grid>
      <Grid className={classes.grow} />
      <Grid item>{actions}</Grid>
    </Grid>
  );
};

export default PageHeader;
