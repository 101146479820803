import { Dialog } from '@nirvana/ui-kit';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import Button from 'src/components/button';

import ClearanceError from './clearanceError';

interface AppetiteErrorDialogProps {
  open: boolean;
  onClose: () => void;
}

const ClearanceConflictDialog = ({
  open,
  onClose,
}: AppetiteErrorDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      primaryAction={
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          endIcon={<ArrowForwardIosRoundedIcon />}
        >
          Continue
        </Button>
      }
      disableBackdropClose
    >
      <ClearanceError onClose={onClose} />
    </Dialog>
  );
};

export default ClearanceConflictDialog;
