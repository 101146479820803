import { useMemo, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

import { Show, TableBasic } from '@nirvana/ui-kit';
import { FormRecord } from '@nirvana/api/non-fleet';
import IconExternalLink from 'src/assets/icons/externalLink.svg';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  GroupHeader,
} from './styles';

interface IQuoteForms {
  formDetails?: FormRecord[];
}

const QuoteForms = ({ formDetails }: IQuoteForms) => {
  const [expandedPanel, setExpandedPanel] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const normalizedSearchTerm = useMemo(
    () => searchTerm.trim().toLowerCase().replace(/\s+/g, ' '),
    [searchTerm],
  );

  const formsByCoverage = useMemo(() => {
    return formDetails?.reduce((acc, record) => {
      if (!acc[record.coverage]) {
        acc[record.coverage] = [];
      }

      acc[record.coverage].push(record);

      return acc;
    }, {} as { [key: string]: FormRecord[] });
  }, [formDetails]);

  const handleExpandChange =
    (panelIndex: number) => (e: any, expanded: boolean) => {
      if (expanded) {
        setExpandedPanel(panelIndex);
      } else {
        setExpandedPanel(-1);
      }
    };

  return (
    <Accordion expanded={expandedPanel === 1} onChange={handleExpandChange(1)}>
      <AccordionSummary
        id="coverage-header"
        expanded={expandedPanel === 1}
        aria-controls="coverage-content"
      >
        Forms
      </AccordionSummary>
      <AccordionDetails>
        <Container maxWidth="md">
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Box width={220}>
                <OutlinedInput
                  fullWidth
                  placeholder="Search"
                  endAdornment={<Search />}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Box>
            </Grid>

            {[
              {
                coverages: [
                  'CoverageAutoPhysicalDamage',
                  'CoverageAutoLiability',
                ],
                label: 'Auto Liability + Physical Damage',
              },
              {
                coverages: ['CoverageMotorTruckCargo'],
                label: 'Motor Truck Cargo',
              },
              {
                coverages: ['CoverageGeneralLiability'],
                label: 'General Liability',
              },
            ].map(({ coverages, label }) => {
              const forms = coverages
                .reduce((acc, coverage) => {
                  if (formsByCoverage?.[coverage]) {
                    acc.push(...formsByCoverage[coverage]);
                  }
                  return acc;
                }, [] as FormRecord[])
                .filter((record) => {
                  const name = record.name ? record.name.toLowerCase() : '';
                  const code = record.code
                    ? record.code.toLowerCase().replace(/[^a-z0-9\s]/gi, '')
                    : '';
                  return (
                    name.includes(normalizedSearchTerm) ||
                    code.includes(normalizedSearchTerm)
                  );
                });

              if (!forms.length) return null;

              return (
                <Grid item key={label}>
                  <GroupHeader>
                    <Typography
                      variant="caption"
                      fontWeight="fontWeightBold"
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {label}
                    </Typography>
                  </GroupHeader>
                  <TableBasic
                    styles={{ position: 'relative', zIndex: 2 }}
                    headers={[
                      { key: 'code', content: <>Code</> },
                      { key: 'name', content: <>Name</> },
                    ]}
                    rows={forms.map((record) => ({
                      key: `coverage-${record.coverage}`,
                      columns: [
                        {
                          content: (
                            <Box py={1}>
                              <Typography>{record.code}</Typography>
                            </Box>
                          ),
                          styles: { width: '30%' },
                        },
                        {
                          content: (
                            <Box py={1} display="flex" alignItems="flex-start">
                              <Typography>{record.name}</Typography>
                              <Show when={record.url}>
                                <IconButton
                                  size="small"
                                  href={record.url}
                                  target="_blank"
                                  sx={{
                                    marginLeft: 1,
                                  }}
                                >
                                  <img src={IconExternalLink} />
                                </IconButton>
                              </Show>
                            </Box>
                          ),
                          styles: { width: '70%' },
                        },
                      ],
                    }))}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuoteForms;
