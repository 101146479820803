import { sub } from 'date-fns';

export interface DateRange {
  key: string;
  from: Date;
  to: Date;
}

export const getDateRanges = (effectiveDate: Date): DateRange[] => {
  return [
    {
      key: '1',
      from: sub(effectiveDate, {
        years: 1,
      }),
      to: effectiveDate,
    },
    {
      key: '2',
      from: sub(effectiveDate, {
        years: 2,
      }),
      to: sub(effectiveDate, {
        years: 1,
      }),
    },
    {
      key: '3',
      from: sub(effectiveDate, {
        years: 3,
      }),
      to: sub(effectiveDate, {
        years: 2,
      }),
    },
    {
      key: '4',
      from: sub(effectiveDate, {
        years: 4,
      }),
      to: sub(effectiveDate, {
        years: 3,
      }),
    },
    {
      key: '5',
      from: sub(effectiveDate, {
        years: 5,
      }),
      to: sub(effectiveDate, {
        years: 4,
      }),
    },
  ];
};

export const getFirstErrorMessage = (
  errors: any,
  index: number,
  fieldName: string,
) => {
  const fields = errors?.lossRunSummaryForm?.[index]?.lossRunSummary || [];
  for (let i = 0, l = fields.length; i < l; i += 1) {
    if (fields[i]) {
      return fields[i]?.[fieldName]?.message;
    }
  }

  return '';
};
