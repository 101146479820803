import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import IconSuccess from 'src/assets/icons/success-3d.svg';
import { useAnalytics } from 'src/helpers/analytics';
import { TSPRecord } from '@nirvana/api/quoting';
import { hooks } from 'src/helpers';
import { SUCCESS_VIEW_TELEMATICS } from 'src/features/telematics/events';

const Success = ({ selectedProvider }: { selectedProvider: TSPRecord }) => {
  const query = hooks.useQuery();
  const { capture } = useAnalytics();
  const applicationId = query.get('token') || '';

  useEffect(() => {
    capture(SUCCESS_VIEW_TELEMATICS, {
      provider: selectedProvider.tsp,
      applicationId,
    });
  }, [applicationId, capture, selectedProvider.tsp]);

  return (
    <Grid container direction="column" spacing={2} mb={1}>
      <Grid item alignSelf={'center'}>
        <Typography
          textAlign={'center'}
          variant="caption"
          lineHeight={1.25}
          component="p"
          maxWidth={320}
        >
          Your connection with <strong>{selectedProvider.name}</strong> has been
          established successfully
        </Typography>
      </Grid>
      <Grid item>
        <Box
          display="flex"
          flexDirection="column"
          alignItems={'center'}
          justifyContent="center"
          my={8}
        >
          <img src={IconSuccess} alt="Success" width={115} />
          <Typography mt={1}>Success!</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Success;
