import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  List,
  ListItem,
  makeStyles,
  FormControl,
} from '@material-ui/core';
import { ITheme, Show } from '@nirvana/ui-kit';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { useApplicationDetailsContext } from 'src/features/nonFleet/hooks/useApplicationDetails';
import { getCommoditiesItems } from '../constants/restrictedItems';

const useStyles = makeStyles((theme: ITheme) => ({
  classItem: {
    ...theme.typography.caption,
    display: 'list-item',
    listStyle: 'inside',
    padding: theme.spacing(0.4, 0),
    color: theme.palette.text.hint,
  },
}));

const RestrcitedCommodities = () => {
  const classes = useStyles();
  const { applicationDetails } = useApplicationDetailsContext();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const commodityItems = getCommoditiesItems(
    Object.values(
      applicationDetails?.nrb?.operationsForm?.coverageInfo?.primaryCovs ?? {},
    )
      ?.filter((record) => record.isRequired)
      .map((record) => record.coverageType),
  );

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={5}>
        <Box paddingTop="10px">
          <InputLabel htmlFor="restricted-classes">
            Restricted Commodities
          </InputLabel>
          <FormHelperText style={{ width: 288 }}>
            Does the insured haul any of the following categories of
            commodities?
          </FormHelperText>
        </Box>
      </Grid>

      <Grid item xs={7} container flexDirection={'column'} spacing={2}>
        <Grid item>
          <FormControl>
            <Controller
              control={control}
              rules={{
                validate: (value) =>
                  value !== undefined || 'Please select an option!',
              }}
              name="classesAndCommoditiesForm.commodityInfo.haulsRestrictedCommodities"
              render={({ field: { value, onChange } }) => (
                <RadioGroup
                  row
                  value={`${value}`}
                  aria-label="restricted-commodities"
                  onChange={(e) => onChange(e.target.value === 'true')}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" size="small" />}
                    label="No"
                  />
                </RadioGroup>
              )}
            />
            <Show
              when={
                (errors?.classesAndCommoditiesForm as any)?.commodityInfo
                  ?.haulsRestrictedCommodities?.message
              }
            >
              {(message) => <FormHelperText error>{message}</FormHelperText>}
            </Show>
          </FormControl>
        </Grid>

        <Grid item container spacing={4}>
          {[
            commodityItems.slice(0, commodityItems.length / 2),
            commodityItems.slice(commodityItems.length / 2),
          ].map((list, index) => {
            return (
              <Grid item key={index} xs={6}>
                <List>
                  {list.map((c) => (
                    <ListItem className={classes.classItem} key={c}>
                      {c}
                    </ListItem>
                  ))}
                </List>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RestrcitedCommodities;
