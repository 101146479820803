import { makeStyles } from '@material-ui/core';
import { ITheme } from '@nirvana/ui-kit';

export const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    background: theme.palette.success.light,
    border: `1px solid ${theme.palette.success.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  error: {
    borderColor: theme.palette.error.main,
  },
}));
