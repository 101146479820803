import { NavLink, useParams } from 'react-router-dom';
import { Grid, Typography, Button, makeStyles, Box } from '@material-ui/core';
import IconSubmitDeclined from 'src/assets/icons/Submit-Declined.svg';
import { ITheme } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { fetchApplicationDetails } from '../queries/application';

const useStyles = makeStyles((theme: ITheme) => ({
  icon: {
    marginBottom: theme.spacing(2),
  },
  errorList: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    '& ul': {
      listStyleType: 'disc',
      paddingLeft: theme.spacing(3),
    },
  },
  backButton: {
    textTransform: 'none',
  },
}));

/**
 * The decline screen that is displayed on the declining of the application creation process
 * Corresponds to Review Page (https://www.figma.com/file/EEV3Aq0aYN8mZJu8pL5kc7/Nirvana-Tech-POC?node-id=2389%3A0&t=EdDsjyZZtwC2igCr-0)
 * @component
 */

const NFApplicationDecline = () => {
  const classes = useStyles();
  const { applicationId = '' } = useParams();

  const { data } = useQuery({
    queryKey: ['application', applicationId],
    queryFn: () => fetchApplicationDetails(applicationId),
  });

  return (
    <Grid container direction="column" spacing={3} alignItems="center" mt={6}>
      <Grid item>
        <img src={IconSubmitDeclined} className={classes.icon} alt="Declined" />
      </Grid>
      <Grid item>
        <Typography
          variant="h4"
          fontWeight="bold"
          align="center"
          maxWidth={700}
          mb={2}
        >
          Unfortunately, this application is outside our appetite for now
        </Typography>
        {!!data?.declineReason && (
          <Typography
            mx="auto"
            variant="body2"
            maxWidth={620}
            align="center"
            color="textSecondary"
          >
            <Box className={classes.errorList}>
              {data?.declineReason.split('\n').map((item, idx) => (
                <p key={idx}>{item}</p>
              ))}
            </Box>
          </Typography>
        )}
      </Grid>
      <Grid item>
        <NavLink to="/applications/list" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.backButton}
          >
            Back to Home
          </Button>
        </NavLink>
      </Grid>
    </Grid>
  );
};

export default NFApplicationDecline;
