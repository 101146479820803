import { Flatfile } from '@flatfile/api';

const workbook: Pick<
  Flatfile.CreateWorkbookConfig,
  'name' | 'labels' | 'sheets' | 'actions'
> = {
  name: 'All Data',
  labels: ['pinned'],
  sheets: [
    {
      name: 'Drivers',
      slug: 'drivers',
      mappingConfidenceThreshold: 0.8,
      fields: [
        // {
        //   key: 'fullName',
        //   alternativeNames: ['Name', 'Driver', 'Drivers Name'],
        //   type: 'string',
        //   label: 'Full Name',
        //   constraints: [
        //     {
        //       type: 'required',
        //     },
        //   ],
        // },
        {
          key: 'firstName',
          alternativeNames: ['First Name'],
          type: 'string',
          label: 'First Name',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'lastName',
          alternativeNames: ['Name', 'Driver', 'Drivers Name'],
          type: 'string',
          label: 'Last Name',
        },
        {
          key: 'dlNumber',
          alternativeNames: [
            'driver',
            'license',
            'License Number',
            'Driver License',
            'DL License',
            'CDL Number',
            'CDL License',
          ],
          type: 'string',
          label: 'DL Number',
          constraints: [
            {
              type: 'required',
            },
            {
              type: 'unique',
            },
          ],
        },
        {
          key: 'usState',
          alternativeNames: [
            'state',
            'DL State',
            'CDL State',
            'License State',
            'State License',
            'Licenced Stated',
            'st',
            'St.',
          ],
          type: 'string',
          label: 'State',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'dateHired',
          alternativeNames: [
            'Hiring Date',
            'Hired Date',
            'Date',
            'DOH',
            'D.O.H.',
            'Date hired',
            'Date of Hire',
          ],
          type: 'date',
          label: 'Date of Hire',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'dateOfBirth',
          alternativeNames: [
            'DOB',
            'Date of birth',
            'Birth date',
            'D.O.B.',
            'Birthdate',
          ],
          type: 'date',
          label: 'Date of Birth',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'yearsOfExperience',
          alternativeNames: [
            'Years of Experience',
            'Years of experience',
            'Years Experience',
            'Years experience',
            'Experience',
            'Experience (years)',
            'Experience (Yrs)',
            'YOE',
            'Drivers Experience',
            'YOE for Drivers',
            'YOE [DRIVERS]',
            'License Recieved',
            'Total Driving Experience',
          ],
          type: 'number',
          label: 'CDL Years of Experience',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit',
      description: 'Submit drivers data',
      primary: true,
      requireAllValid: true,
    },
  ],
};

export default workbook;
