import { Avatar, Box, Divider, Grid, Typography } from '@material-ui/core';
import { TelematicsConsentInfo } from '@nirvana/api/quoting';
import BagIcon from '@nirvana/ui-kit/src/assets/icons/bag.svg';
import Background from 'src/assets/images/connected-background.jpg';
import { useIntercom } from 'react-use-intercom';
import { Branding, BrandingTypes, Show } from '@nirvana/ui-kit';
import { useEffect, useState } from 'react';
import useNavigateParams from 'src/hooks/useNavigateParams';
import { RequestStatus } from '../slices';

type PreviouslyConnectedProps = {
  consentInfo?: TelematicsConsentInfo;
  status?: RequestStatus;
};

export default function PreviouslyConnected({
  consentInfo,
  status,
}: PreviouslyConnectedProps) {
  const { show } = useIntercom();
  const navigateWithParams = useNavigateParams();

  const [secondsLeft, setSecondsLeft] = useState(5);
  useEffect(() => {
    if (status === 'succeeded') {
      navigateWithParams('/telematics/connect');
    }
  }, [status, navigateWithParams]);

  // countdown and redirect
  useEffect(() => {
    const countdownTimer = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          clearInterval(countdownTimer); // Stop the countdown at 1
          return 0;
        }
      });
    }, 1000);

    const redirectTimer = setTimeout(() => {
      window.location.href =
        'https://safety.nirvanatech.com/?utm_source=agents&utm_campaign=expired_link&utm_medium=link';
    }, 5000);

    return () => {
      clearInterval(countdownTimer);
      clearTimeout(redirectTimer);
    };
  }, []);

  return (
    <>
      <Show when={consentInfo?.dotNumber && consentInfo?.companyName}>
        <div className="absolute right-5 top-4">
          <Box>
            <Grid container flexWrap="nowrap" spacing={2} alignItems="center">
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ width: 20, height: 20, bgcolor: '#E6E7EF' }}>
                    <img src={BagIcon} alt="company" />
                  </Avatar>
                  <Typography variant="caption" color="secondary" ml={1}>
                    {consentInfo?.companyName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Divider flexItem sx={{ height: 16 }} orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="caption" color="secondary">
                  DOT: {consentInfo?.dotNumber}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Show>

      <div className="flex w-screen h-screen">
        <div className="relative flex-1 bg-none">
          <img
            className="absolute object-cover object-left w-full h-full"
            src={Background}
          />
          <div className="absolute top-4 left-5">
            <a
              className="flex"
              href="https://safety.nirvanatech.com/?utm_source=agents&utm_campaign=expired_link&utm_medium=link"
            >
              <Branding brandingType={BrandingTypes.Safety} />
            </a>
          </div>
          <div className="absolute w-full max-w-md text-3xl font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <p className="mb-8 text-primary-extraLight">Welcome back!</p>
            <p className="text-primary-extraLight/70">
              Based on our info, you&apos;re already successfully connected with
              a primary TSP.
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center flex-1">
          <div className="w-full max-w-md">
            <p className="mb-10 text-2xl" data-testid="connected-message">
              You’re all set for now. You will be redirected to the Safety
              platform in {secondsLeft}{' '}
              {secondsLeft === 1 ? 'second' : 'seconds'}.
            </p>

            <button
              className="font-semibold cursor-pointer text-primary-main hover:underline hover:text-primary-dark"
              onClick={show}
            >
              Need more help?
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
