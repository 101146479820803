import { makeStyles } from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
  wrapper: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: 4,
  },
  reasonsList: {
    display: 'grid',
    rowGap: theme.spacing(2),
    margin: 0,
  },
  centeralize: {
    justifyContent: 'center',
  },
  splitThree: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
}));
