import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import Button from 'src/components/button';
import ServerErrorIcon from 'src/assets/icons/server-error.svg';

const ServerError = () => {
  const navigate = useNavigate();

  return (
    <Grid
      item
      container
      flexGrow={1}
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="center"
      spacing={9}
    >
      <Grid item xs={6} textAlign="right" pr={6}>
        <img src={ServerErrorIcon} alt="Server Error" />
      </Grid>
      <Grid item xs={6} textAlign="left">
        <Typography
          variant="h4"
          color="textPrimary"
          mb={1}
          fontWeight="fontWeightBold"
        >
          Well, this is embarrassing
        </Typography>

        <Typography variant="body2" color="textSecondary" mb={5} maxWidth={520}>
          Our team has been notified about this problem and we&apos;re working
          hard to fix it.
          <br />
          We will reach out when the problem has been fixed so you can try
          again.
        </Typography>

        <Button
          color="primary"
          onClick={() => navigate('/')}
          variant="contained"
        >
          Go to Home
        </Button>
      </Grid>
    </Grid>
  );
};

export default ServerError;
