import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';

import { ProgramType } from '@nirvana/api/endorsementapp';
import { Chip, Dialog, Show } from '@nirvana/ui-kit';

import AddCoverageIcon from 'src/assets/icons/add-coverage.svg?react';
import UpdatePolicyIcon from 'src/assets/icons/update-policy.svg?react';
import { createEndorsement } from '../queries/endorsement';

type CreateEndorsementDialogProps = {
  open: boolean;
  onClose: () => void;
  policyId: string;
};

export default function CreateEndorsementDialog({
  open,
  onClose,
  policyId,
}: CreateEndorsementDialogProps) {
  const navigate = useNavigate();
  const { mutate } = useMutation(createEndorsement, {
    onSuccess: (response) => {
      navigate(
        `/policies/${policyId}/endorsement/${response.endorsementRequestID}`,
      );
    },
    onError: () => {
      // Unable to create endorsement request
      // TODO: Show error

      onClose();
    },
  });

  const handleEndorsementSelect = (endorsementType: string) => (e: any) => {
    e?.preventDefault();

    if (endorsementType === 'update-policy') {
      mutate({
        policyId,
        payload: {
          programType: ProgramType.ProgramTypeNonFleetAdmitted,
        },
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="relative pb-8">
        <h2 className="mb-4 text-base font-bold">New Endorsement</h2>
        <IconButton
          size="small"
          onClick={onClose}
          className="absolute right-0 -top-1"
        >
          <Close />
        </IconButton>

        <p className="mb-4 text-xl">What would you like to do?</p>

        <div className="flex items-center gap-6">
          {[
            {
              id: 'add-coverage',
              to: '',
              title: 'Add New Coverage',
              description: 'APD, MTC, GL and more',
              icon: <AddCoverageIcon />,
            },
            {
              id: 'update-policy',
              to: '',
              title: 'Update Existing Policy',
              description: 'Vehicles, Drivers, Limits, and more',
              icon: <UpdatePolicyIcon />,
            },
          ].map(({ id, to, title, description, icon }) => (
            <Link
              to={to}
              onClick={handleEndorsementSelect(id)}
              key={title}
              className={clsx(
                'flex flex-col items-center justify-center w-64 h-64 border rounded-lg border-text-disabled hover:border-2 hover:border-[#D7DCFE] hover:bg-primary-extraLight',
                id === 'add-coverage'
                  ? 'bg-background-default text-text-hint cursor-not-allowed'
                  : 'cursor-pointer',
              )}
            >
              {icon}
              <p className="mt-5 mb-1 text-base font-bold">{title}</p>
              <p className="mb-2">{description}</p>

              <Show when={id === 'add-coverage'}>
                <Chip label="Coming Soon" />
              </Show>
            </Link>
          ))}
        </div>
      </div>
    </Dialog>
  );
}
