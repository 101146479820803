import { ForgotPasswordRequest, VerifyTokenRequest } from '@nirvana/api/auth';
import AuthService from 'src/helpers/authService';

export const forgotPassword = async (payload: ForgotPasswordRequest) => {
  const { data } = await AuthService.authForgotPasswordPost(payload);
  return data;
};

export const resetPassword = async (payload: VerifyTokenRequest) => {
  const { data } = await AuthService.authVerifyTokenPost(payload);
  return data;
};

export const agentDetailStatus = async () => {
  const { data } = await AuthService.agentDetailsGet();
  return data;
};
