import { Dialog, Show } from '@nirvana/ui-kit';

import Button from 'src/components/button';

import { DialogActions } from '@material-ui/core';
import ClearanceConflictIcon from 'src/assets/icons/clearance-conflict-declined.svg';

interface ClearanceConflictDialogDeclinedProps {
  open: boolean;
  onClose: () => void;
  handleCreateApplication?: () => void;
  accountName: number | string;
}

const ClearanceConflictDialogDeclined = ({
  onClose,
  open,
  handleCreateApplication,
  accountName,
}: ClearanceConflictDialogDeclinedProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <div className="flex flex-col justify-around items-center space-y-6 p-6 w-[758px] h-[400px]">
        <img src={ClearanceConflictIcon} />

        <div className="text-xl font-semibold text-center">
          <p>
            A cleared submission for {accountName} has already been declined
          </p>
          <div className="mt-4 mb-4 ml-10 space-y-6 text-base font-normal text-left">
            <div>
              However, you may continue submitting if you have all of the
              following:
              <div className="mt-4 space-y-2 list-decimal">
                <p>
                  1. New information or unique circumstances about the insured.
                </p>

                <p>
                  2.{' '}
                  <a
                    className="text-primary-main"
                    href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/assets/agent-resources/BOR+Rules.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    BOR Letter
                  </a>{' '}
                  from the insured.
                </p>
              </div>
            </div>
            <div className="font-normal text-l">
              <div>
                Please reach out to{' '}
                <a
                  className="underline text-primary-main"
                  href="mailto:support@nirvanatech.com"
                >
                  support@nirvanatech.com
                </a>{' '}
                if you have any questions, or view our Guide to clearance{' '}
                <a
                  className="text-primary-main"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://intercom.help/nirvana-insurance/en/articles/9435293-guide-to-clearance"
                >
                  here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogActions className="flex flex-row justify-end mt-4 space-x-5 py-9 items-right">
        <Show when={!!handleCreateApplication}>
          <>
            <Button
              variant="outlined"
              onClick={handleClose}
              color="primary"
              className="h-10"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateApplication}
              className="h-10"
            >
              Continue
            </Button>
          </>
        </Show>
      </DialogActions>
    </Dialog>
  );
};

export default ClearanceConflictDialogDeclined;
