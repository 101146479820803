import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import AddIcon from 'src/assets/icons/add.svg?react';

import PolicyTab from './components/policy-tab';
import SummaryCard from './components/summary-card';
import EndorsementTab from './components/endorsement-tab';
import CreateEndorsementDialog from './components/create-endorsement-dialog';
import BusinessOwnerDetail from './components/business-owner-detail';
import { fetchPolicyDetail } from './queries/policy';

const tabs = [
  { label: 'Policy', value: 'policy' },
  { label: 'Endorsements', value: 'endorsements' },
  { label: 'Billing', value: 'billing' },
];

export default function Policy() {
  const { policyId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(
    searchParams.get('tab') || 'policy',
  );

  const [isCreateEndorsementDialogOpen, setIsCreateEndorsementDialogOpen] =
    useState(false);

  const { data } = useQuery(['policy-detail', policyId], () =>
    fetchPolicyDetail(policyId),
  );

  useEffect(() => {
    setSearchParams({ tab: currentTab });
  }, [currentTab, setSearchParams]);

  return (
    <>
      <div className="p-10">
        <section className="flex items-center mb-6 space-x-3">
          <h1 className="text-[28px] font-bold flex-1">{data?.companyName}</h1>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => setIsCreateEndorsementDialogOpen(true)}
          >
            Create Endorsement
          </Button>
        </section>
        <div className="flex">
          <BusinessOwnerDetail data={data} />
          <SummaryCard data={data} />
        </div>

        <div className="flex">
          <ul className="w-48">
            {tabs.map(({ label, value }) => (
              <li
                key={value}
                onClick={() => {
                  if (value === 'billing') {
                    window.open('https://dashboard.useascend.com/programs');
                  } else {
                    setCurrentTab(value);
                  }
                }}
                className={clsx(
                  'p-3 border-l-4 cursor-pointer',
                  value === currentTab
                    ? 'font-semibold text-primary-main border-primary-main bg-primary-extraLight'
                    : 'border-transparent',
                )}
              >
                {label}
              </li>
            ))}
          </ul>

          <div className="flex-1 py-2 pl-8">
            <PolicyTab
              coverages={data?.coverages}
              drivers={data?.drivers}
              vehicles={data?.vehicles}
            />
            <EndorsementTab />
          </div>
        </div>
      </div>

      <CreateEndorsementDialog
        open={isCreateEndorsementDialogOpen}
        onClose={() => setIsCreateEndorsementDialogOpen(false)}
        policyId={policyId}
      />
    </>
  );
}
