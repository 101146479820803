import { useState } from 'react';
import {
  InputAdornment,
  OutlinedInput,
  Link,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Show } from '@nirvana/ui-kit';
import { HiCheck, HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { useClipboard } from 'use-clipboard-copy';
import IconEdit from 'src/assets/icons/edit.svg';
import { useAnalytics } from 'src/helpers/analytics';
import {
  COPY_TELEMATICS_LINK,
  EDIT_TELEMATICS_CONNECTION_LINK,
} from 'src/features/telematics/events';
import ConsentSuccessful from '../additionalInformation/consent-successful';

export const EditTelematics = ({
  email,
  name,
  url,
  applicationId,
  step,
}: {
  email: string;
  name: string;
  url: string;
  applicationId: string;
  step?: string;
}) => {
  const clipboard = useClipboard({ copiedTimeout: 3000 });
  const [consentSuccessful, setConsentSuccessful] = useState(false);

  const { capture } = useAnalytics();

  return (
    <>
      <div className="mt-4 border border-gray-300 rounded-xl">
        <div className="p-4 ">
          <div>
            <div className="flex justify-between ">
              <Typography variant="body1" color="secondary">
                {name}
              </Typography>

              <IconButton
                size="small"
                onClick={() => {
                  setConsentSuccessful(true);
                  capture(EDIT_TELEMATICS_CONNECTION_LINK, {
                    applicationId,
                    step,
                  });
                }}
                color="primary"
                className="cursor-pointer fill-text-darkGrey"
              >
                <img src={IconEdit} alt="Edit" />
              </IconButton>
            </div>

            <p className="truncate text-text-primary">{email}</p>
          </div>
        </div>
      </div>
      <OutlinedInput
        readOnly
        value={url}
        className="mt-4 bg-primary-extraLight text-text-hint"
        endAdornment={
          <InputAdornment>
            <Link
              underline="none"
              sx={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
              }}
              onClick={() => {
                clipboard.copy(url);
                capture(COPY_TELEMATICS_LINK, {
                  applicationId,
                  step,
                });
              }}
            >
              <Show
                when={clipboard.copied}
                fallback={
                  <>
                    <Typography mr={0.5} color="primary" variant="caption">
                      Copy Link
                    </Typography>
                    <HiOutlineDocumentDuplicate />
                  </>
                }
              >
                <Typography variant="caption" color="primary" mr={0.5}>
                  Link Copied
                </Typography>
                <HiCheck />
              </Show>
            </Link>
          </InputAdornment>
        }
      />
      <ConsentSuccessful
        open={consentSuccessful}
        connectionURL={url}
        onClose={() => setConsentSuccessful(false)}
      />
    </>
  );
};
