import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormHelperText, Grid, Typography } from '@material-ui/core';
import { Create } from '@material-ui/icons';

import { Show } from '@nirvana/ui-kit';
import Button from 'src/components/button';

import {
  DriverSection,
  EquipmentSection,
  IndicationSection,
  OperationsView,
} from '../create/review/sections';
import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';

const Review = () => {
  const navigate = useNavigate();
  const { applicationDetails } = useApplicationDetailsContext();
  const [isEditable, setEditbale] = useState<boolean>(false);

  const isQuoteGenerated =
    applicationDetails?.appStatus === 'AppStateBound' ||
    applicationDetails?.appStatus === 'AppStatePolicyCreated';

  const handleEdit = (stepKey: string) => {
    navigate(
      `/non-fleet/application/${applicationDetails?.applicationID}/create`,
      {
        state: {
          activeStep: stepKey,
        },
      },
    );
  };

  if (!applicationDetails?.admitted) {
    return null;
  }

  return (
    <Grid item>
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h4"
              fontWeight="fontWeightBold"
              color="textPrimary"
              gutterBottom
            >
              {isQuoteGenerated ? 'Application' : 'Application under review'}
            </Typography>
            <Show when={!isQuoteGenerated}>
              <FormHelperText sx={{ marginBottom: '12px' }}>
                Your application was submitted successfuly and is under review
              </FormHelperText>
            </Show>
          </Grid>

          <Show when={!isEditable && !isQuoteGenerated}>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<Create />}
                onClick={() => setEditbale(true)}
              >
                Modify Application
              </Button>
            </Grid>
          </Show>
        </Grid>

        <OperationsView
          applicationData={applicationDetails?.admitted}
          onEdit={handleEdit}
          editable={isEditable}
        />
        <EquipmentSection
          applicationData={applicationDetails?.admitted}
          onEdit={handleEdit}
          editable={isEditable}
        />
        <DriverSection
          applicationData={applicationDetails?.admitted}
          onEdit={handleEdit}
          editable={isEditable}
        />
        <IndicationSection
          applicationData={applicationDetails?.admitted}
          onEdit={handleEdit}
          editable={isEditable}
        />
      </Grid>
    </Grid>
  );
};

export default Review;
