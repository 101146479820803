import * as React from 'react';
import NumberFormat from 'react-number-format';
import {
  FormControl,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { CoverageType } from '@nirvana/api/quoting';

import { application as ApplicationHelper } from 'src/helpers';

import { useStyles } from './../styles';

export interface ILimitsSelector {
  coverage: CoverageType;
  disabled: boolean;
  options: number[];
  value: number;
  onChange: (e: any) => void;
  classes?: any;
}

const LimitsSelector = ({
  coverage,
  disabled,
  options,
  value,
  onChange,
  classes: propClasses = {},
}: ILimitsSelector) => {
  const classes = useStyles();

  return (
    <FormGroup>
      <FormHelperText className={classes.coverageInputLabel}>
        {ApplicationHelper.getIndicationOptionsFeatureLabel(coverage)}
      </FormHelperText>
      <FormControl
        margin="dense"
        classes={{
          ...propClasses,
        }}
      >
        <Select
          name={coverage}
          disabled={disabled}
          value={value}
          renderValue={(value: any) => {
            return (
              <NumberFormat
                value={value}
                displayType={'text'}
                thousandSeparator={true}
                prefix="$"
              />
            );
          }}
          onChange={onChange}
          variant="outlined"
        >
          {options?.map((amount: number) => (
            <MenuItem value={amount} key={amount}>
              <NumberFormat
                value={amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormGroup>
  );
};

export default LimitsSelector;
