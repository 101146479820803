import {
  RenewalAdditionalForm,
  RenewalClassesAndCommoditiesForm,
  RenewalCoverageForm,
  RenewalLossForm,
  RenewalOperationsForm,
  SectionCompletionMap,
  RenewalFilesForm,
} from '@nirvana/api/quoting';
import apiService from 'src/helpers/fleetApiService';

interface UpdateCoveragesParams {
  applicationID: string;
  coverages: RenewalCoverageForm;
}

interface UpdateOperationsParams {
  applicationID: string;
  operations: RenewalOperationsForm;
}

export const updateCoverages = async ({
  applicationID,
  coverages,
}: UpdateCoveragesParams) => {
  const { data } = await apiService.applicationApplicationIDV2RenewPatch(
    applicationID,
    {
      renewalCoverageForm: coverages,
    },
  );

  return data;
};

export const updateOperations = async ({
  applicationID,
  operations,
}: UpdateOperationsParams) => {
  const { data } = await apiService.applicationApplicationIDV2RenewPatch(
    applicationID,
    {
      renewalOperationsForm: operations,
    },
  );

  return data;
};

export const updateLossHistory = async ({
  applicationID,
  lossHistory,
}: {
  applicationID: string;
  lossHistory: RenewalLossForm;
}) => {
  const { data } = await apiService.applicationApplicationIDV2RenewPatch(
    applicationID,
    {
      renewalLossForm: lossHistory,
    },
  );

  return data;
};

export const updateClassesAndCommodities = async ({
  applicationID,
  classesAndCommodities,
}: {
  applicationID: string;
  classesAndCommodities: RenewalClassesAndCommoditiesForm;
}) => {
  const { data } = await apiService.applicationApplicationIDV2RenewPatch(
    applicationID,
    {
      renewalClassesAndCommoditiesForm: classesAndCommodities,
    },
  );

  return data;
};

export const updateAdditionalForm = async ({
  applicationID,
  additionalForm,
}: {
  applicationID: string;
  additionalForm: RenewalAdditionalForm;
}) => {
  const { data } = await apiService.applicationApplicationIDV2RenewPatch(
    applicationID,
    {
      renewalAdditionalForm: additionalForm,
    },
  );

  return data;
};

export const updateSectionCompletionMap = async ({
  applicationID,
  sectionCompletionMap,
}: {
  applicationID: string;
  sectionCompletionMap: SectionCompletionMap;
}) => {
  const { data } = await apiService.applicationApplicationIDV2RenewPatch(
    applicationID,
    {
      sectionCompletionMap,
    },
  );

  return data;
};

export const updateFiles = async ({
  applicationID,
  files,
}: {
  applicationID: string;
  files: RenewalFilesForm;
}) => {
  const { data } = await apiService.applicationApplicationIDV2RenewPatch(
    applicationID,
    {
      renewalFilesForm: files,
    },
  );

  return data;
};

export const getIndicationOptions = async ({
  applicationID,
}: {
  applicationID: string;
}) => {
  const { data } =
    await apiService.applicationApplicationIDIndicationOptionsGet(
      applicationID,
    );

  return data;
};

export const submitRenewalApplication = async ({
  applicationID,
}: {
  applicationID: string;
}) => {
  const { data } = await apiService.applicationApplicationIDV2RenewSubmitPost(
    applicationID,
  );

  return data;
};

export const getApplicationById = async ({
  applicationID,
}: {
  applicationID: string;
}) => {
  const { data } = await apiService.applicationApplicationIDGet(applicationID);

  return data;
};
