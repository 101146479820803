import { Divider, Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form-v7';
import { CoverageDetails, CoverageType } from '@nirvana/api/non-fleet';

import BusinessOwner from './business-owner';
import Commodities from './commodities';
import Coverage from './coverage';
import OperatingArea from './operating-area';
import TerminalLocation from './terminal-location';
import LossRuns from './loss-runs';

const Operations = () => {
  const { watch } = useFormContext();
  const enabledCoverages = watch('operationsForm.coverages', [])
    .filter((record: CoverageDetails) => !!record.isRequired)
    .map((record: CoverageDetails) => record.coverageType);

  return (
    <Grid container direction="column" spacing={4} pt={4}>
      <Grid item container spacing={3}>
        <Coverage />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <BusinessOwner />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <OperatingArea />

        <TerminalLocation />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <LossRuns />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <Commodities
          mtcEnabled={enabledCoverages.includes(
            CoverageType.CoverageMotorTruckCargo,
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Operations;
