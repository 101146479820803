import { makeStyles, styled, Chip } from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

// reused in renewals as well
export const useStyles = makeStyles((theme: ITheme) => ({
  checkbox: {
    backgroundColor: theme.palette.common.white,
    height: '100%',

    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
  },
  selected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
  checkboxContent: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
  },
  checkboxIcon: {
    width: theme.typography.pxToRem(80),
  },
  cardActionArea: {
    height: '100%',
  },
  blank: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputLabel: {
    whiteSpace: 'normal',
    fontWeight: theme.typography.fontWeightMedium,
  },
  totalItem: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    fontWeight: theme.typography.fontWeightRegular,
    height: '100%',
    padding: theme.spacing(1, 2),
  },
  totalLabel: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: '5px 0 0 5px',
  },
  totalValue: {
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: '0 5px 5px 0',
  },
  formControl: {
    width: theme.typography.pxToRem(220),
  },
  chip: {
    borderRadius: 5,
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.4, 0),
    border: 0,
    backgroundColor: theme.palette.primary.extraLight,

    '& .MuiChip-label': {
      fontWeight: 400,
    },
  },
  totalValueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: 5,
    padding: theme.spacing(0.625, 2.25),
  },
  textareaRoot: {
    alignItems: 'flex-start',
    padding: `${theme.spacing(1)} !important`,
  },
  textAreaInput: {
    padding: `${theme.spacing(1)} !important`,
  },
}));

export const ChipContainer = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.25),
  listStyle: 'none',
  display: 'inline-block',
}));

export const AutoCompleteChip = styled(Chip)(
  ({ theme }: { theme: ITheme }) => ({
    '&.MuiChip-root': {
      backgroundColor: theme.palette.primary.extraLight,
      color: theme.palette.text.primary,
      padding: theme.spacing(0.75, 1.25),
      borderRadius: theme.typography.pxToRem(4),
      margin: 0,
      height: 'auto',
      minHeight: theme.spacing(4),
      maxWidth: '100%',
    },

    '& .MuiChip-deleteIcon': {
      marginLeft: theme.spacing(2),
      flexShrink: 0,
    },

    '& .MuiChip-label': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      padding: 0,
      overflow: 'unset',
      whiteSpace: 'normal',
    },
  }),
);
