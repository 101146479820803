import { useSearchParams } from 'react-router-dom';

import { formatNumber, Show, TableV8 } from '@nirvana/ui-kit';
import { Coverage, Driver, Vehicle } from '@nirvana/api/endorsementapp';

import { getVehicleColumns } from '../constants/vehicle';
import { getDriversColumns } from '../constants/driver';
import { getDescriptionByCoverage } from '../utils';

type PolicyTabProps = {
  coverages?: Coverage[];
  drivers?: Driver[];
  vehicles?: Vehicle[];
};

export default function PolicyTab({
  coverages,
  drivers,
  vehicles,
}: PolicyTabProps) {
  const [searchParams] = useSearchParams();

  return (
    <Show when={searchParams.get('tab') === 'policy'}>
      <h2 className="mb-5 text-xl font-medium">Coverage Lines</h2>

      <div className="grid grid-cols-2 gap-6 mb-5">
        {coverages?.map(({ name, limits, deductible }) => (
          <div
            key={name}
            className="grid grid-cols-2 gap-4 p-4 bg-white rounded-lg shadow-lg"
          >
            <div className="min-h-[9rem]">
              <p className="mb-1 font-bold">{name}</p>
              <p className="text-xs text-text-hint">
                {getDescriptionByCoverage(name)}
              </p>
            </div>

            <div>
              <div className="mb-3">
                <p className="mb-1 text-xs text-text-hint">Limit</p>
                <p className="font-medium whitespace-pre-wrap">
                  {limits || 'NA'}
                </p>
              </div>

              <div>
                <p className="mb-1 text-xs text-text-hint">Deductible</p>
                <p className="font-medium">${formatNumber(deductible)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <h2 className="mb-5 text-xl font-medium">Vehicles</h2>

      <div className="mb-5 overflow-hidden bg-white border rounded-lg border-text-disabled">
        <TableV8 columns={getVehicleColumns({})} data={vehicles ?? []} />
      </div>

      <h2 className="mb-5 text-xl font-medium">Drivers</h2>

      <div className="mb-5 overflow-hidden bg-white border rounded-lg border-text-disabled">
        <TableV8 columns={getDriversColumns({})} data={drivers ?? []} />
      </div>
    </Show>
  );
}
