import * as React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { TableBasic } from '@nirvana/ui-kit';
import IconExternalLink from 'src/assets/icons/externalLink.svg';

import { FormRecord } from '@nirvana/api/quoting';

interface IFormsByCoverage {
  coverage: FormRecord[];
  title: string;
}

const FormsByCoverage = ({ coverage = [], title }: IFormsByCoverage) => {
  return (
    <Box mb={2}>
      <Box
        sx={{
          backgroundColor: 'primary.tint3',
          padding: 1,
          textAlign: 'center',
          borderRadius: 1,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Typography
          variant="caption"
          color="textSecondary"
          fontWeight="fontWeightBold"
          sx={{ textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
      </Box>
      <TableBasic
        headers={[
          {
            key: 'formCode',
            content: <>Code</>,
          },
          {
            key: 'formName',
            content: <>Name</>,
          },
        ]}
        rows={
          coverage?.map((record: FormRecord, index: number) => ({
            key: `forms-${record.code}-${index}`,
            columns: [
              {
                content: (
                  <Box py="2px">
                    <Typography variant="body2">{record.code}</Typography>
                  </Box>
                ),
                styles: { width: '25%' },
              },
              {
                content: (
                  <Box>
                    <Box py="2px" display="flex" alignItems="flex-start">
                      <Typography variant="body2">{record.name}</Typography>

                      <IconButton
                        size="small"
                        href={record.url}
                        target="_blank"
                        sx={{
                          marginLeft: 1,
                        }}
                      >
                        <img src={IconExternalLink} />
                      </IconButton>
                    </Box>
                  </Box>
                ),
              },
            ],
          })) ?? []
        }
      />
    </Box>
  );
};

export default FormsByCoverage;
