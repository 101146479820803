import { useEffect, useMemo, useState } from 'react';
import { Box, Divider, FormHelperText, Grid } from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AdmittedApp,
  ApplicationDetails,
  CoverageType,
  GetAdmittedAppIndicationResponse,
  PageState,
} from '@nirvana/api/non-fleet';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { useNavigate } from 'react-router-dom';

import {
  fetchIndication,
  submitQuote,
  updateApplicationDetails,
} from 'src/features/admitted/queries/application';
import { useApplicationDetailsContext } from 'src/features/admitted/hooks/useApplicationDetails';
import Loader from 'src/features/application/components/create/indicationOptions/indicationLoader';

import Deductibles from './deductibles';
import Limits from './limits';
import Plans from './plans';
import TelematicsConnection from './plans/telematics-connection';
import { TelematicsCard } from './telematicsCard';

interface IIndication {
  applicationId: string;
  onLoadingChange?: (isLoading: boolean) => void;
}

const Indication = ({ applicationId, onLoadingChange }: IIndication) => {
  const { control, setValue, getValues } = useFormContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { applicationDetails } = useApplicationDetailsContext();
  const enableCombinedDeductibles = useMemo(() => {
    const enabledCoverages =
      applicationDetails?.admitted?.operationsForm?.coverages
        ?.filter((record) => record.isRequired)
        .map((record) => record.coverageType) ?? [];

    return (
      enabledCoverages.includes(CoverageType.CoverageMotorTruckCargo) &&
      enabledCoverages.includes(CoverageType.CoverageAutoPhysicalDamage)
    );
  }, [applicationDetails?.admitted?.operationsForm?.coverages]);

  const [
    isTelematicsConnectionDrawerOpen,
    setIsTelematicsConnectionDrawerOpen,
  ] = useState(false);
  const [indicationStatus, setIndicationStatus] = useState('running');
  const [loaderAnimationStatus, setLoaderAnimationStatus] = useState('idle');
  const [isUpdating, setIsUpdating] = useState(false);

  const { data, isLoading: isLoadingPackages } = useQuery({
    queryKey: ['indication', applicationId],
    queryFn: () => fetchIndication(applicationId),
    enabled: indicationStatus === 'running',
    refetchInterval: 10 * 1000,
    onSuccess: (data: GetAdmittedAppIndicationResponse) => {
      setIndicationStatus(data.status);
    },
  });

  const { mutate: submitIndicationMutation } = useMutation(submitQuote, {
    onSuccess: () => {
      setIndicationStatus('running');
      setIsUpdating(false);
    },
    onError: () => {
      navigate(`/non-fleet/application/${applicationId}/decline`);
    },
  });

  const { mutate: updateApplicationMutation } = useMutation(
    updateApplicationDetails,
    {
      onMutate: async (newApplication) => {
        // Optimistically update the isAPDMTCDeductibleCombined value

        await queryClient.cancelQueries({
          queryKey: ['application', applicationId],
        });

        const previousApplication = queryClient.getQueryData([
          'application',
          applicationId,
        ]);

        queryClient.setQueryData(
          ['application', applicationId],
          (old?: ApplicationDetails) => {
            const updated = { ...old };
            if (updated?.admitted?.indicationForm?.coverages) {
              updated.admitted.indicationForm.coverages.isAPDMTCDeductibleCombined =
                newApplication.payload.admitted?.indicationForm?.coverages?.isAPDMTCDeductibleCombined;
            }

            return updated;
          },
        );

        // Return a context object with the snapshotted value
        return { previousApplication };
      },
      onSuccess: () => {
        submitIndicationMutation(applicationId);
      },
    },
  );

  const isLoading = useMemo(
    () => isLoadingPackages || isUpdating || indicationStatus === 'running',
    [isLoadingPackages, isUpdating, indicationStatus],
  );

  const onSubmit = (data: AdmittedApp) => {
    updateApplicationMutation({
      applicationId,
      payload: {
        pageState: PageState.PageStateSubmitted,
        admitted: {
          ...data,
        },
      },
    });
  };

  const handleDeductibleLimitChange = () => {
    setIsUpdating(true);
    onSubmit(getValues());
    setValue('indicationForm.selectedIndication.id', '');
  };

  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange(isLoading || loaderAnimationStatus === 'loading');
    }
  }, [isLoading, indicationStatus, loaderAnimationStatus, onLoadingChange]);

  useEffect(() => {
    if (
      indicationStatus === 'success' &&
      loaderAnimationStatus === 'complete'
    ) {
      setLoaderAnimationStatus('idle');
      queryClient.invalidateQueries(['application', applicationId]);
    }
  }, [
    indicationStatus,
    loaderAnimationStatus,
    applicationId,
    queryClient,
    setValue,
  ]);

  if (
    applicationDetails?.appStatus !== 'AppStateQuoteGenerated' &&
    applicationDetails?.appStatus !== 'AppStateUnderUWReview' &&
    (indicationStatus === 'running' || loaderAnimationStatus === 'loading')
  ) {
    return (
      <>
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item position="relative">
            <Box>
              <Loader
                onStatusChange={setLoaderAnimationStatus}
                steps={[
                  'Pulling FMCSA inspections & crashes',
                  'Pulling BASIC scores',
                  'Pulling company history',
                  'Processing drivers',
                  'Generating an indication',
                ]}
              />
            </Box>
          </Grid>
        </Box>
      </>
    );
  }

  return (
    <Box mt={4}>
      <Grid container spacing={4} direction="column">
        <Grid item>
          <Deductibles
            options={data?.options}
            loading={isLoadingPackages}
            disabled={isLoading}
            enableCombinedDeductibles={enableCombinedDeductibles}
            onChange={handleDeductibleLimitChange}
          />
        </Grid>

        <Grid item>
          <Limits
            options={data?.options}
            loading={isLoadingPackages}
            disabled={isLoading}
            onChange={handleDeductibleLimitChange}
          />
        </Grid>

        <Grid item>
          <Divider />
        </Grid>

        <Grid item>
          <Controller
            control={control}
            name="indicationForm.telematicsInfo"
            rules={{
              validate: {
                required: (value) => {
                  return !!value?.link || 'Please connect the telematics';
                },
              },
            }}
            render={({ field: { value }, fieldState }) => {
              return (
                <>
                  <TelematicsCard
                    telematicsInfo={value}
                    onEditTelematics={() => {
                      setIsTelematicsConnectionDrawerOpen(true);
                    }}
                    error={!!fieldState.error?.message}
                  />
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </>
              );
            }}
          />
        </Grid>

        <Grid item>
          <Plans options={data?.packages} loading={isLoading} />
        </Grid>
      </Grid>

      <TelematicsConnection
        open={isTelematicsConnectionDrawerOpen}
        applicationId={applicationId}
        telematicsInfo={
          applicationDetails?.admitted?.indicationForm?.telematicsInfo
        }
        onClose={(isLinkGenerated) => {
          setIsTelematicsConnectionDrawerOpen(false);
          if (isLinkGenerated) {
            setValue(
              'indicationForm.telematicsInfo',
              applicationDetails?.admitted?.indicationForm?.telematicsInfo,
            );
          }
        }}
      />
    </Box>
  );
};

export default Indication;
