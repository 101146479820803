import * as React from 'react';

import LogoWhite from './../../assets/logo/Nirvana_Lockup--Logotype--Inverted.svg';
import LogoPrimary from './../../assets/logo/Nirvana_Lockup--Logotype.svg';
import LogoIcon from './../../assets/logo/Nirvana_Lockup--Tertiary_Lockup.svg';

/**
 * Nirvana branding. Can be a logo or text.
 * @component
 *
 * @param {boolean} [inverted] - Optional. Inverses color of logo.
 * @param {boolean} [icon] - Optional. Show/Hide icon.
 *
 * @example
 * return <Branding />
 */
const Branding = ({
  inverted = false,
  icon = false,
}: {
  inverted?: Boolean;
  icon?: Boolean;
}) => {
  if (icon) {
    return <img src={LogoIcon} alt="Nirvana" />;
  }

  if (inverted) {
    return <img src={LogoWhite} alt="Nirvana" />;
  }

  return <img src={LogoPrimary} alt="Nirvana" />;
};

export default Branding;
