import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Dialog, ITheme, Show } from '@nirvana/ui-kit';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form-v7';
import IconApplicationError from 'src/assets/icons/application-error.svg';
import { useAnalytics } from '../../../../helpers/analytics';
import { NF_APPETITE_ERROR_MODAL_VIEW } from '../../../telematics/events';

const useStyles = makeStyles((theme: ITheme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.extraLight,
    padding: theme.spacing(2, 0),
    borderRadius: 4,
  },
  classItem: {
    ...theme.typography.caption,
    display: 'list-item',
    listStyle: 'inside',
    padding: theme.spacing(0.4, 0),
    color: theme.palette.text.secondary,
  },
}));

const defaultValues = {
  dotNumber: '',
  companyName: '',
  effectiveDate: null,
  numberOfPowerUnits: '',
  hasUndesiredOperations: '',
};

interface NFAppetiteErrorDialogProps {
  open: boolean;
  onClose: () => void;
  errorMessage?: string;
}

const NFAppetiteErrorDialog = ({
  open,
  onClose,
  errorMessage,
}: NFAppetiteErrorDialogProps) => {
  const classes = useStyles();
  const { reset } = useFormContext();
  const { capture } = useAnalytics();

  useEffect(() => {
    if (open) {
      capture(NF_APPETITE_ERROR_MODAL_VIEW);
    }
  }, [open]);

  function handleClose() {
    reset(defaultValues);
    onClose();
  }

  const isUndesired = errorMessage?.includes('Undesired operations');

  const options = [
    [
      'Boats & other watercraft',
      'Courier Operations',
      'Double or Triple Trailers',
      'Towing/Repossession vehicles',
      'Mobile home or building movers',
    ],
    [
      'Buses, taxis or other people transport',
      'Collection vehicles for residential removal',
      'Freight forwarders',
      'Mobile cranes or similar equipment',
      'Vehical leasing firms',
    ],
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      primaryAction={
        <Button variant="contained" color="primary" onClick={handleClose}>
          Back to Home
        </Button>
      }
      disableBackdropClose
    >
      <Box pt={4} position="relative" px={10} pb={2}>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          edge="end"
          size="small"
        >
          <Close />
        </IconButton>

        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <img src={IconApplicationError} alt="Application Error" />
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              color="textPrimary"
              textAlign="center"
              gutterBottom
            >
              Unfortunately, this application doesn&apos;t meet our appetite at
              this time
            </Typography>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Box className={classes.wrapper}>
              <Show
                when={isUndesired}
                fallback={
                  <Box>
                    {errorMessage?.split('\n').map((line, index) => (
                      <Typography key={index} align="center">
                        {line}
                      </Typography>
                    ))}
                  </Box>
                }
              >
                {options.map((option, index) => (
                  <List key={index}>
                    {option.map((item) => (
                      <ListItem className={classes.classItem} key={item}>
                        {item}
                      </ListItem>
                    ))}
                  </List>
                ))}
              </Show>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default NFAppetiteErrorDialog;
