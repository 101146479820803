import { Box, Grid, Link, Typography, makeStyles } from '@material-ui/core';
import { ITheme } from '@nirvana/ui-kit';

import ImageFilePDF from 'src/assets/images/file-pdf.svg';
import IconPDFSuccess from 'src/assets/icons/pdf-success.svg';
import IconDownload from 'src/assets/icons/download.svg';

const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    width: '290px',
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 2px 4px rgba(128, 145, 196, 0.06)',
  },
  fileIcon: {
    padding: theme.spacing(3, 2),
    paddingBottom: 0,
  },
  fileMeta: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -4px 4px rgba(128, 145, 196, 0.13)',
    padding: theme.spacing(2),
  },
  filename: {
    color: theme.palette.text.primary,
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none !important',
  },
}));

interface IPDFDownloadProps {
  filename: string;
  onClick?: () => void;
}

const PdfDownload = ({ filename, onClick }: IPDFDownloadProps) => {
  const classes = useStyles();

  return (
    <Link onClick={onClick} className={classes.link}>
      <Box className={classes.container}>
        <img src={ImageFilePDF} alt="PDF cover" className={classes.fileIcon} />
        <Box className={classes.fileMeta}>
          <Grid container spacing={2}>
            <Grid item>
              <img src={IconPDFSuccess} alt="PDF" width={24} />
            </Grid>
            <Grid item flex={1}>
              <Typography variant="caption" className={classes.filename}>
                {filename}
              </Typography>
            </Grid>
            <Grid item>
              <img src={IconDownload} alt="Download" width={16} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Link>
  );
};

export default PdfDownload;
