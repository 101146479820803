import * as React from 'react';
import { NavLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  makeStyles,
  Grid,
  Divider,
  Typography,
  Avatar,
} from '@material-ui/core';

import { ITheme } from 'src/assets/themes';
import BagIcon from '@nirvana/ui-kit/src/assets/icons/bag.svg';
import Branding from '../branding';

export const useStyles = makeStyles((theme: ITheme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    boxShadow: 'none',
  },
  toolbar: {
    minHeight: theme.typography.pxToRem(56),
    display: 'flex',
    alignItems: 'center',
  },
  brandLogo: {
    display: 'flex',
    alignItems: 'center',
  },
}));

/**
 * The global header component that is displayed on authorized pages of the application.
 * @component
 *
 * @example
 * return <Header />
 */
const TransparentHeader = ({ application }: any) => {
  const classes = useStyles();

  return (
    <>
      <AppBar
        position="static"
        className={classes.appbar}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <Toolbar className={classes.toolbar}>
          <NavLink to="/applications/list" className={classes.brandLogo}>
            <Branding icon />
          </NavLink>
          <div className={classes.grow} />
          {!!application && (
            <Box>
              <Grid container flexWrap="nowrap" spacing={2} alignItems="center">
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: '#E6E7EF',
                      }}
                    >
                      <img src={BagIcon} alt="company" />
                    </Avatar>
                    <Typography variant="caption" color="secondary" ml={1}>
                      {application?.companyName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{
                      height: 16,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="caption" color="secondary">
                    DOT: {application?.dotNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TransparentHeader;
