import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Box, CircularProgress } from '@material-ui/core';
import useSnackbar from 'src/hooks/useSnackbar';
import { fetchHubspotLoginURL } from '../queries/login';

const Hubspot = () => {
  const enqueueSnackbar = useSnackbar();
  const [searchParams] = useSearchParams();
  const redirectURL = searchParams.get('redirect_url') || '';

  // Hubspot requires us to echo the 'redirect_url' query param back to them:
  // https://knowledge.hubspot.com/website-pages/set-up-single-sign-on-sso-to-access-private-content#set-up-sso-for-a-jwt-based-application
  const { isLoading } = useQuery(
    ['hubspot-sso-login', redirectURL],
    () =>
      fetchHubspotLoginURL({
        redirectURL: redirectURL,
      }),
    {
      onSuccess: (response) => {
        window.location.href = response.absoluteLoginUrl;
      },
      onError: () => {
        enqueueSnackbar(
          'Hubspot login error',
          'Failed to fetch personalized hubspot login URL. Please try again later.',
          { variant: 'error' },
        );
      },
    },
  );

  if (isLoading) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return null;
};

export default Hubspot;
