import * as React from 'react';
import { Navigate, NavigateProps, useSearchParams } from 'react-router-dom';

const NavigateWithQuery = ({ to, ...rest }: NavigateProps) => {
  const [searchParams] = useSearchParams();
  const searchString = searchParams.toString();

  return (
    <Navigate
      {...rest}
      to={{
        pathname: typeof to === 'string' ? to : to.pathname,
        search: searchString ? `?${searchString}` : '',
      }}
    />
  );
};

export default NavigateWithQuery;
