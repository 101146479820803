import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form-v7';

import { RadiusOfOperationOptions } from '../constants/operatingRadius';

const OperatingArea = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={5}>
        <Box paddingTop="10px">
          <InputLabel htmlFor="business-structure">
            Farthest Radius of Operations
          </InputLabel>
          <FormHelperText style={{ width: 288 }}>
            The farthest distance travelled by the fleet
          </FormHelperText>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <Controller
            control={control}
            defaultValue=""
            name="operationsForm.maxRadiusOfOperation"
            rules={{ required: 'Please select radius of operations' }}
            render={({ field: { value, onChange } }) => (
              <Select
                size="small"
                displayEmpty
                variant="outlined"
                error={
                  !!(errors.operationsForm as any)?.maxRadiusOfOperation
                    ?.message
                }
                value={value}
                onChange={onChange}
              >
                <MenuItem value="">
                  <Typography color="text.hint">Select</Typography>
                </MenuItem>
                {RadiusOfOperationOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {!!(errors.operationsForm as any)?.maxRadiusOfOperation?.message && (
            <FormHelperText error>
              {(errors.operationsForm as any)?.maxRadiusOfOperation?.message}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default OperatingArea;
