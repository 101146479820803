import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  GridDirection,
} from '@material-ui/core';

import { useStyles } from './styles';
import { getCommodityLabel } from './utils';
import { CommodityOption } from './commodities';

export interface IPrimaryCommodity {
  layout: GridDirection;
  list: CommodityOption[];
}

const PrimaryCommodity = ({ layout, list }: IPrimaryCommodity) => {
  const classes = useStyles();
  const { control, errors, setValue } = useFormContext();

  return (
    <Grid
      item
      container
      direction={layout}
      spacing={layout === 'row' ? 3 : 1}
      alignItems={layout === 'row' ? 'center' : 'flex-start'}
    >
      <Grid item xs={layout === 'row' ? 4 : 12}>
        <InputLabel className={classes.inputLabel}>
          Primary commodity
        </InputLabel>
      </Grid>
      <Grid item container spacing={3} xs={layout === 'row' ? 8 : 12}>
        <Grid item flexGrow={1} xs={layout === 'row' ? 8 : 4}>
          <FormControl fullWidth>
            <Controller
              name="primaryCommodity"
              defaultValue=""
              control={control}
              rules={{
                required: 'Please select primary commodity',
              }}
              render={(props) => {
                // TODO: Remove value selection using indices
                const value = list.findIndex(
                  (record) => record.commodity?.type === props.value?.type,
                );

                return (
                  <>
                    <Select
                      displayEmpty
                      variant="outlined"
                      value={value === -1 ? '' : value}
                      onChange={(event) => {
                        const record: CommodityOption =
                          list[event.target.value];
                        setValue('primaryOperatingClass', record.classId);
                        props.onChange(record.commodity);
                      }}
                      error={!!errors.primaryCommodity}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {list.map((record: CommodityOption, index: number) => (
                        <MenuItem key={record.commodity.type} value={index}>
                          {getCommodityLabel(record.commodity)}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors?.primaryCommodity && (
                      <FormHelperText error>
                        {errors?.primaryCommodity?.message}
                      </FormHelperText>
                    )}
                  </>
                );
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrimaryCommodity;
