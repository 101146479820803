import { Grid, styled, Typography } from '@material-ui/core';
import { LossRunSummaryRecord } from '@nirvana/api/quoting';
import { ITheme } from '@nirvana/ui-kit';
import { TableView } from 'src/components/table';

interface MTCUnfilledSkeletonProps {
  AutoLiabilityCoverage: any;
}

export const TableBordered = styled(Grid)(({ theme }: { theme: ITheme }) => ({
  border: '1px solid',
  borderColor: theme.palette.error.main,
  borderRadius: 5,
  padding: theme.spacing(0.75, 3),
}));

export const MTCUnfilledSkeleton = ({
  AutoLiabilityCoverage,
}: MTCUnfilledSkeletonProps) => {
  return (
    <Grid item xs={4}>
      <TableBordered>
        <Typography
          variant="caption"
          fontWeight={'fontWeightBold'}
          mb={1}
          component={'p'}
        >
          Motor Truck Cargo
        </Typography>
        <TableView
          headers={[
            {
              key: 'columnPolicyPeriod',
              content: 'Policy period',
            },
            {
              key: 'columnPowerUnits',
              content: 'Units',
            },
          ]}
          rows={AutoLiabilityCoverage.lossRunSummary
            .filter((record: LossRunSummaryRecord) => {
              if (record.isNirvanaPeriod) {
                return true;
              }

              return (
                !!record.numberOfPowerUnits ||
                !!record.lossIncurred ||
                !!record.numberOfClaims
              );
            })
            .map(() => ({
              columns: [
                {
                  content: (
                    <div className="">
                      <div className="h-4 bg-gray-100 rounded" />
                    </div>
                  ),
                },
                {
                  content: (
                    <div>
                      <div className="w-10 h-4 bg-gray-100 rounded" />
                    </div>
                  ),
                },
              ],
            }))}
        />
      </TableBordered>
      <Typography variant="caption" color="error">
        {'Please enter losses  for MTC'}
      </Typography>
    </Grid>
  );
};
