import * as React from 'react';
import clsx from 'clsx';
import { formatISO } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormHelperText,
  OutlinedInput,
} from '@material-ui/core';
import { InputNumeric } from '@nirvana/ui-kit';

import { utils as UtilsHelper } from 'src/helpers';

import { DateRange, getFirstErrorMessage } from './utils';
import { useStyles } from './styles';

export interface LossHistoryCoverageFields {
  coverageIndex: number;
  dateRanges: DateRange[];
  disabledPredicate?: (rangeIndex: number) => boolean;
}

const CoverageFields = ({
  coverageIndex,
  dateRanges,
  disabledPredicate,
}: LossHistoryCoverageFields) => {
  const classes = useStyles();
  const { control, errors, getValues } = useFormContext();

  return (
    <Table key={`coverageFields-${coverageIndex}`}>
      <TableHead>
        <TableRow>
          <TableCell
            className={clsx(classes.tableCell, classes.tableHeaderLabel)}
            align="left"
          >
            Loss incurred
          </TableCell>
          <TableCell
            className={clsx(classes.tableCell, classes.tableHeaderLabel)}
            align="left"
          >
            # of claims
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dateRanges.map((record: DateRange, rangeIndex: number) => (
          <TableRow key={`lossRunSummaryForm.lossRunSummary[${rangeIndex}]`}>
            <TableCell className={classes.tableCell}>
              <FormControl>
                <Controller
                  control={control}
                  name={`lossRunSummaryForm[${coverageIndex}].lossRunSummary[${rangeIndex}].policyPeriodStartDate`}
                  defaultValue={formatISO(record.from, {
                    representation: 'date',
                  })}
                  render={() => <></>}
                />
                <Controller
                  control={control}
                  name={`lossRunSummaryForm[${coverageIndex}].lossRunSummary[${rangeIndex}].policyPeriodEndDate`}
                  defaultValue={formatISO(record.to, {
                    representation: 'date',
                  })}
                  render={() => <></>}
                />
                <Controller
                  name={`lossRunSummaryForm[${coverageIndex}].lossRunSummary[${rangeIndex}].lossIncurred`}
                  control={control}
                  rules={{
                    validate: {
                      lossIncurred: (value) => {
                        if (
                          disabledPredicate &&
                          disabledPredicate(rangeIndex)
                        ) {
                          return true;
                        }

                        const values = getValues();
                        const record =
                          values.lossRunSummaryForm[coverageIndex]
                            .lossRunSummary[rangeIndex];

                        const hasLossIncurred = !!value;
                        const hasNumberOfClaims = !!record.numberOfClaims;

                        if (hasNumberOfClaims && !hasLossIncurred) {
                          return 'Loss incurred is required when claims are present.';
                        }

                        if (
                          (rangeIndex === 0 || rangeIndex === 1) &&
                          !value &&
                          value !== 0
                        ) {
                          return 'Please enter missing values';
                        } else if (rangeIndex > 1) {
                          // Check for partial data in rows
                          if (
                            !(value || value === 0) &&
                            (record.numberOfPowerUnits || record.numberOfClaims)
                          ) {
                            return 'Please enter missing values';
                          }
                        }

                        if (value && !UtilsHelper.isValidInt32(value)) {
                          return 'Please enter a valid number';
                        }

                        return true;
                      },
                    },
                  }}
                  render={(props) => {
                    if (disabledPredicate?.(rangeIndex)) {
                      return (
                        <OutlinedInput
                          size="small"
                          disabled={disabledPredicate?.(rangeIndex)}
                          value={'-'}
                          error={
                            !!errors?.lossRunSummaryForm?.[coverageIndex]
                              ?.lossRunSummary?.[rangeIndex]?.numberOfPowerUnits
                          }
                        />
                      );
                    }
                    return (
                      <InputNumeric
                        size="small"
                        placeholder="$ 0"
                        decimalScale={0}
                        value={props?.value}
                        disabled={disabledPredicate?.(rangeIndex)}
                        onChange={(event) => {
                          props.onChange(
                            typeof event.target.value === 'number'
                              ? +event.target.value
                              : undefined,
                          );
                        }}
                        prefix="$"
                        error={
                          errors?.lossRunSummaryForm?.[coverageIndex]
                            ?.lossRunSummary?.[rangeIndex]?.lossIncurred
                        }
                      />
                    );
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <FormControl>
                <Controller
                  name={`lossRunSummaryForm[${coverageIndex}].lossRunSummary[${rangeIndex}].numberOfClaims`}
                  control={control}
                  rules={{
                    validate: {
                      numberOfClaims: (value) => {
                        if (
                          disabledPredicate &&
                          disabledPredicate(rangeIndex)
                        ) {
                          return true;
                        }
                        const values = getValues();
                        const record =
                          values.lossRunSummaryForm[coverageIndex]
                            .lossRunSummary[rangeIndex];

                        const hasLossIncurred = !!record.lossIncurred;
                        const hasNumberOfClaims = !!value;

                        if (!hasNumberOfClaims && hasLossIncurred) {
                          return 'Number of claims is required when loss incurred is present.';
                        }

                        if (
                          (rangeIndex === 0 || rangeIndex === 1) &&
                          !value &&
                          value !== 0
                        ) {
                          return 'Please enter missing values';
                        } else if (rangeIndex > 1) {
                          // Check for partial data in rows
                          if (
                            !(value || value === 0) &&
                            (record.numberOfPowerUnits || record.lossIncurred)
                          ) {
                            return 'Please enter missing values';
                          }
                        }

                        if (value && !UtilsHelper.isValidInt32(value)) {
                          return 'Please enter a valid number';
                        }

                        // Check for claims with zero dollar loss incurred
                        if (rangeIndex >= 0 && value > 0) {
                          if (record.lossIncurred <= 0) {
                            return 'Claims with zero loss incurred are not valid';
                          }
                        }

                        // Check to see number of claims is within 100
                        if (rangeIndex >= 0 && value > 100) {
                          return 'Number of claims cannot exceed 100';
                        }

                        return true;
                      },
                    },
                  }}
                  render={(props) => {
                    if (disabledPredicate?.(rangeIndex)) {
                      return (
                        <OutlinedInput
                          size="small"
                          disabled={disabledPredicate?.(rangeIndex)}
                          value={'-'}
                          error={
                            !!errors?.lossRunSummaryForm?.[coverageIndex]
                              ?.lossRunSummary?.[rangeIndex]?.numberOfPowerUnits
                          }
                        />
                      );
                    }

                    return (
                      <InputNumeric
                        size="small"
                        placeholder="0"
                        decimalScale={0}
                        value={props?.value}
                        disabled={disabledPredicate?.(rangeIndex)}
                        onChange={(event) => {
                          props.onChange(
                            typeof event.target.value === 'number'
                              ? +event.target.value
                              : undefined,
                          );
                        }}
                        error={
                          !!errors?.lossRunSummaryForm?.[coverageIndex]
                            ?.lossRunSummary?.[rangeIndex]?.numberOfClaims
                        }
                      />
                    );
                  }}
                />
              </FormControl>
            </TableCell>
          </TableRow>
        ))}

        <TableRow>
          <TableCell className={classes.tableCell} colSpan={4}>
            <FormHelperText
              error
              sx={{
                marginTop: '-3px',
                lineHeight: 1,
              }}
            >
              {getFirstErrorMessage(errors, coverageIndex, 'lossIncurred') ||
                getFirstErrorMessage(
                  errors,
                  coverageIndex,
                  'numberOfClaims',
                ) || <>&nbsp;</>}
            </FormHelperText>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default CoverageFields;
