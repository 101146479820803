import { useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import {
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { HiCheck, HiOutlineDocumentDuplicate } from 'react-icons/hi';

import { Show } from '@nirvana/ui-kit';
import { TelematicsInfo } from '@nirvana/api/quoting';

import IconEdit from 'src/assets/icons/edit.svg';
import ConsentSuccessful from './consent-successful';

type TelematicsInfoProps = {
  telematicsInfo: TelematicsInfo;
};

export default function TelematicsDetails({
  telematicsInfo,
}: TelematicsInfoProps) {
  const clipboard = useClipboard({ copiedTimeout: 3000 });
  const [consentSuccessful, setConsentSuccessful] = useState(false);

  let connectionURL = telematicsInfo.link;
  const host = import.meta.env.VITE_WEB_URL;
  // Adding this validation in order to support older format of link
  // Removing it after we successfully migrate to link including Schema and host.
  if (!connectionURL.includes(host)) {
    connectionURL = `${host}/${connectionURL}`;
  }

  return (
    <>
      <div className="max-w-md px-4 py-2 mb-8 border rounded-md">
        <div className="flex mb-4 space-x-2">
          <Typography variant="body1" color="secondary">
            {telematicsInfo.name}
          </Typography>
          <span className="truncate text-text-primary">
            {telematicsInfo.email}
          </span>
          <div className="flex-1" />
          <IconButton size="small" onClick={() => setConsentSuccessful(true)}>
            <img src={IconEdit} alt="Edit telematics info" />
          </IconButton>
        </div>

        <OutlinedInput
          readOnly
          fullWidth
          value={connectionURL}
          className="mb-2 bg-primary-extraLight text-text-hint"
          data-testid="telematics-consent-link"
          endAdornment={
            <InputAdornment>
              <Link
                underline="none"
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                  alignItems: 'center',
                }}
                onClick={() => {
                  clipboard.copy(connectionURL);
                }}
              >
                <Show
                  when={clipboard.copied}
                  fallback={
                    <>
                      <Typography mr={0.5} color="primary" variant="caption">
                        Copy Link
                      </Typography>
                      <HiOutlineDocumentDuplicate />
                    </>
                  }
                >
                  <Typography variant="caption" color="primary" mr={0.5}>
                    Link Copied
                  </Typography>
                  <HiCheck />
                </Show>
              </Link>
            </InputAdornment>
          }
        />
      </div>

      <ConsentSuccessful
        open={consentSuccessful}
        connectionURL={connectionURL}
        onClose={() => setConsentSuccessful(false)}
      />
    </>
  );
}
