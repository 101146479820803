import { useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Controller, useFormContext } from 'react-hook-form-v7';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Grow,
  useTheme,
  GridSize,
} from '@material-ui/core';
import { Checkbox } from '@nirvana/ui-kit';
import { CoverageType } from '@nirvana/api/quoting';
import { CoverageOption } from '@nirvana/api/non-fleet';

import { application as ApplicationHelper } from 'src/helpers';
import { useApplicationDetailsContext } from 'src/features/admitted/hooks/useApplicationDetails';

import DeductiblesSkeleton from './skeleton';
import DeductibleSelector from './selector';

export interface IDeductibles {
  loading: boolean;
  disabled: boolean;
  options?: CoverageOption[];
  onChange: () => void;
  enableCombinedDeductibles?: boolean;
}

const Deductibles = ({
  loading,
  disabled,
  options = [],
  onChange,
  enableCombinedDeductibles = false,
}: IDeductibles) => {
  const theme = useTheme();
  const { control, setValue } = useFormContext();
  const { applicationDetails } = useApplicationDetailsContext();
  const combinedCoverage =
    !!applicationDetails?.admitted?.indicationForm?.coverages
      ?.isAPDMTCDeductibleCombined;
  const [deductibleColumnSize, setDeductibleColumnSize] = useState(4);
  const filteredOptions = useMemo(() => {
    return options.filter((record) => {
      if (
        combinedCoverage &&
        record.coverage === CoverageType.CoverageMotorTruckCargo
      ) {
        return false;
      }

      return !!record.deductibleOptions?.length;
    });
  }, [options, combinedCoverage]);

  const getLabelByCoverage = (coverage: CoverageType) => {
    if (
      coverage === CoverageType.CoverageAutoPhysicalDamage &&
      combinedCoverage
    ) {
      return `${ApplicationHelper.getIndicationOptionsFeatureLabel(
        CoverageType.CoverageAutoPhysicalDamage,
      )} + ${ApplicationHelper.getIndicationOptionsFeatureLabel(
        CoverageType.CoverageMotorTruckCargo,
      )}`;
    }

    return ApplicationHelper.getIndicationOptionsFeatureLabel(coverage);
  };

  if (loading) {
    return <DeductiblesSkeleton />;
  }

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={5}>
        <InputLabel
          htmlFor="deductiblesOnCoverage-input"
          sx={{
            color: 'text.primary',
            fontWeight: 'fontWeightMedium',
            whiteSpace: 'normal',
          }}
        >
          Deductibles
        </InputLabel>
        <FormHelperText>
          Premium may change based on the deductible
        </FormHelperText>
      </Grid>
      <Grid item xs={7} container direction="column" spacing={2}>
        {enableCombinedDeductibles && (
          <Grid item>
            <Controller
              control={control}
              name="indicationForm.coverages.isAPDMTCDeductibleCombined"
              defaultValue={false}
              render={({ field: { value, onChange: onValueChange } }) => {
                return (
                  <FormControlLabel
                    checked={value}
                    disabled={disabled}
                    onChange={(e: any) => {
                      onValueChange(e.target.checked);

                      if (e.target.checked) {
                        setTimeout(() => {
                          setDeductibleColumnSize(6);
                          onChange();
                        }, theme.transitions.duration.shortest + 100);
                      } else {
                        setDeductibleColumnSize(4);
                        setTimeout(() => {
                          onChange();
                        }, theme.transitions.duration.shortest + 50);
                      }
                    }}
                    control={<Checkbox />}
                    label="Combine APD & MTC deductibles"
                    sx={{ overflowX: 'hidden' }}
                  />
                );
              }}
            />
          </Grid>
        )}
        <Grid item container spacing={2} alignItems="center">
          <TransitionGroup component={null}>
            {filteredOptions.map((record) => {
              return (
                <Grow
                  timeout={theme.transitions.duration.shortest}
                  key={record.coverage}
                >
                  <Grid
                    item
                    xs={deductibleColumnSize as GridSize}
                    style={{
                      transition: theme.transitions.create('all', {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shortest,
                      }),
                    }}
                  >
                    <Controller
                      control={control}
                      name={`indicationForm.coverages.${record.coverage}.deductible`}
                      render={({
                        field: { value, onChange: onValueChange },
                      }) => {
                        return (
                          <DeductibleSelector
                            getLabel={getLabelByCoverage}
                            coverage={record.coverage}
                            disabled={disabled}
                            options={record.deductibleOptions || []}
                            value={value || record.defaultDeductible || 0}
                            onChange={(e) => {
                              onValueChange(e.target.value);

                              if (
                                combinedCoverage &&
                                record.coverage ===
                                  CoverageType.CoverageAutoPhysicalDamage
                              ) {
                                setValue(
                                  `indicationForm.coverages.${CoverageType.CoverageMotorTruckCargo}.deductible`,
                                  e.target.value,
                                );
                              }

                              onChange();
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grow>
              );
            })}
          </TransitionGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Deductibles;
