import {
  FormControlLabel,
  Grid,
  GridSize,
  InputLabel,
} from '@material-ui/core';
import { ApplicationDetail, CoverageType } from '@nirvana/api/quoting';
import { Checkbox } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DeductibleSelector from 'src/features/application/components/create/indicationOptions/deductibles/selector';
import DeductiblesSkeleton from 'src/features/application/components/create/indicationOptions/deductibles/skeleton';
import { application as ApplicationHelper } from 'src/helpers';
import { getIndicationOptions } from '../../../queries';
import { useStyles } from '../coveragesFormView';

export interface IDeductibles {
  applicationData: ApplicationDetail;
  onChange: (name: string, record: any) => void;
}

const Deductibles = ({ applicationData, onChange }: IDeductibles) => {
  const classes = useStyles();
  const { control, watch, setValue } = useFormContext();

  const coverages = React.useMemo(
    () => watch('coveragesRequired') || [],
    [watch],
  );

  const coveragesWithCombinedDeductibles = React.useMemo(
    () => watch('coveragesWithCombinedDeductibles') || [],
    [watch],
  );

  const hasAPDMTC = useMemo(() => {
    return (
      coverages?.some(
        (coverage: any) =>
          coverage.coverageType === CoverageType.CoverageAutoPhysicalDamage,
      ) &&
      coverages?.some(
        (coverage: any) =>
          coverage.coverageType === CoverageType.CoverageMotorTruckCargo,
      )
    );
  }, [coverages]);

  const {
    data: indicationOptionsData,
    isLoading,
    isFetching,
  } = useQuery(
    ['indicationOptions', applicationData.summary.applicationID],
    () =>
      getIndicationOptions({
        applicationID: applicationData.summary.applicationID,
      }),
  );

  const {
    coverageVariablesOptions,
    coveragesWithCombinedDeductibles:
      coveragesWithCombinedDeductiblesInitialState,
    deductiblesOptions,
  } = indicationOptionsData || {};
  const [combinedCoverage, setCombinedCoverage] = useState(false);

  useEffect(() => {
    if (coveragesWithCombinedDeductiblesInitialState?.length) {
      setCombinedCoverage(true);
      setValue(
        'coveragesWithCombinedDeductibles',
        coveragesWithCombinedDeductiblesInitialState,
      );
    } else {
      setCombinedCoverage(false);
      setValue('coveragesWithCombinedDeductibles', null);
    }
  }, [coveragesWithCombinedDeductiblesInitialState, setValue]);

  const [deductibleColumnSize, setDeductibleColumnSize] = useState(4);

  const deductiblesByCoverage: Partial<
    Record<CoverageType, { current: number; options: number[] }>
  > = {};

  const coverageArray =
    coverages?.map((record: any) => record.coverageType) || [];

  deductiblesOptions?.forEach((record) => {
    if (
      coverageArray.includes(record.coverage as CoverageType) &&
      record.coverage !== CoverageType.CoverageGeneralLiability
    ) {
      deductiblesByCoverage[record.coverage as CoverageType] = {
        current:
          coverageVariablesOptions?.find(
            (option) => option.coverage === record.coverage,
          )?.deductibles?.current || record.amounts[0],

        options: record.amounts,
      };
    }
  });

  if (
    combinedCoverage &&
    deductiblesByCoverage[CoverageType.CoverageMotorTruckCargo]
  ) {
    delete deductiblesByCoverage[CoverageType.CoverageMotorTruckCargo];
  }

  const getLabelByCoverage = (coverage: CoverageType) => {
    if (
      coverage === CoverageType.CoverageAutoPhysicalDamage &&
      combinedCoverage
    ) {
      return `${ApplicationHelper.getIndicationOptionsFeatureLabel(
        CoverageType.CoverageAutoPhysicalDamage,
      )} + ${ApplicationHelper.getIndicationOptionsFeatureLabel(
        CoverageType.CoverageMotorTruckCargo,
      )}`;
    }

    return ApplicationHelper.getIndicationOptionsFeatureLabel(coverage);
  };

  const handleDeductibleChange = (e: any, isCombined: boolean) => {
    const values = watch('deductibles');
    const reply = {
      deductibles: {} as Record<CoverageType, number>,
      coveragesWithCombinedDeductibles: isCombined
        ? [
            [
              CoverageType.CoverageAutoPhysicalDamage,
              CoverageType.CoverageMotorTruckCargo,
            ],
          ]
        : [],
    };

    Object.keys(deductiblesByCoverage).forEach((coverage) => {
      const coverageKey = coverage as CoverageType;
      if (coverageKey === e?.target.name) {
        reply.deductibles[coverageKey] = e.target.value;
      } else {
        if (values[coverageKey]) {
          reply.deductibles[coverageKey] = values?.[coverageKey];
        } else {
          reply.deductibles[coverageKey] =
            deductiblesByCoverage[coverageKey]?.current!;
        }
      }
    });

    onChange('deductibles', reply.deductibles);
    setValue(
      'coveragesWithCombinedDeductibles',
      reply.coveragesWithCombinedDeductibles,
    );
  };

  useEffect(() => {
    const isCombined = ApplicationHelper.isCombinedCoverage(
      CoverageType.CoverageAutoPhysicalDamage,
      coveragesWithCombinedDeductibles || [],
    );

    if (isCombined) {
      setCombinedCoverage(true);
      setDeductibleColumnSize(6);
    } else {
      setCombinedCoverage(false);
      setDeductibleColumnSize(4);
    }
  }, [coveragesWithCombinedDeductibles]);

  if (!Object.keys(deductiblesByCoverage).length) {
    return <DeductiblesSkeleton spacingGridLeft={4} spacingGridRight={8} />;
  }

  if (isLoading || isFetching) {
    return null;
  }

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={4}>
        <InputLabel
          htmlFor="deductiblesOnCoverage-input"
          className={classes.inputLabel}
        >
          Deductibles
        </InputLabel>
      </Grid>
      <Grid item xs={8} container direction="column" spacing={2}>
        {hasAPDMTC && (
          <Grid item>
            <FormControlLabel
              checked={combinedCoverage}
              disabled={false}
              onChange={(e: any) => {
                if (e.target.checked) {
                  setCombinedCoverage(true);
                  setDeductibleColumnSize(6);
                  handleDeductibleChange(null, true);
                } else {
                  setDeductibleColumnSize(4);
                  setCombinedCoverage(false);
                  handleDeductibleChange(null, false);
                }
              }}
              control={<Checkbox />}
              label="Combine APD & MTC deductibles"
              sx={{ overflowX: 'hidden' }}
            />
          </Grid>
        )}
        <Controller
          name="coveragesWithCombinedDeductibles"
          control={control}
          defaultValue={
            applicationData?.indicationForm?.operationsForm
              ?.coveragesWithCombinedDeductibles || []
          }
          render={() => {
            return <></>;
          }}
        />
        <Grid item container spacing={2} alignItems="center">
          {coverages?.map(({ coverageType }: any) => {
            const deductibleRecord =
              deductiblesByCoverage?.[coverageType as CoverageType];

            if (!deductibleRecord) {
              return null;
            }

            return (
              <Grid
                item
                xs={deductibleColumnSize as GridSize}
                key={coverageType}
              >
                <Controller
                  name={`deductibles.${coverageType}`}
                  control={control}
                  defaultValue={deductibleRecord.current}
                  render={({ value }) => (
                    <DeductibleSelector
                      getLabel={getLabelByCoverage}
                      coverage={coverageType as CoverageType}
                      disabled={false}
                      options={deductibleRecord.options || []}
                      value={value}
                      onChange={(e) => {
                        handleDeductibleChange(e, combinedCoverage);
                      }}
                    />
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Deductibles;
