// import { Create } from '@material-ui/icons';
import { FormHelperText, Grid, Typography } from '@material-ui/core';
import OperationsView from './operations';
import ClassesAndCommoditiesView from './commodities';
import EquipmentsView from './equipment';
import DriversView from './drivers';
import QuoteView from './quote';

const Review = () => {
  return (
    <Grid item>
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h4"
              fontWeight="fontWeightBold"
              color="textPrimary"
              gutterBottom
            >
              Application under review
            </Typography>
            <FormHelperText sx={{ marginBottom: '12px' }}>
              Your application was submitted successfuly and is under review
            </FormHelperText>
          </Grid>

          {/* <Grid item>
            <Button variant="outlined" startIcon={<Create />}>
              Modify Application
            </Button>
          </Grid> */}
        </Grid>

        <OperationsView />
        <ClassesAndCommoditiesView />
        <EquipmentsView />
        <DriversView />
        <QuoteView />
      </Grid>
    </Grid>
  );
};

export default Review;
