import { Box, Grid, Link, Typography } from '@material-ui/core';
import { useClipboard } from 'use-clipboard-copy';
import { TelematicsInfo } from '@nirvana/api/non-fleet';

import IconEdit from 'src/assets/icons/edit.svg';

import { useStyles } from './styles';

interface ITelematicsConnected {
  onEdit: () => void;
  value: TelematicsInfo;
}

const TelematicsConnected = ({ onEdit, value }: ITelematicsConnected) => {
  const classes = useStyles();
  const clipboard = useClipboard({
    copiedTimeout: 3000,
  });

  const handleCopyConnectionLink = (url: string) => {
    clipboard.copy(url);
  };

  return (
    <Box className={classes.telematicsConnectedCard} onClick={onEdit}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" color="secondary">
            {value?.name}
          </Typography>
          <Typography variant="caption" color="textHint" component="p">
            {value?.email}
          </Typography>

          <Link
            style={{ cursor: 'pointer' }}
            underline={clipboard.copied ? 'none' : 'hover'}
            onClick={(e) => {
              e.stopPropagation();

              let link = value?.link;
              const host = import.meta.env.VITE_WEB_URL;
              // Adding this validation in order to support older format of link
              // Removing it after we successfully migrate to link including Schema and host.
              if (!link.includes(host)) {
                link = `${host}/${link}`;
              }
              handleCopyConnectionLink(link);
            }}
          >
            {clipboard.copied ? (
              <Typography variant="caption" color="success.main">
                Copied!
              </Typography>
            ) : (
              <Typography variant="caption" color="primary">
                Copy Connection Link
              </Typography>
            )}
          </Link>
        </Grid>
        <Grid item display="flex" justifyContent="center">
          <img src={IconEdit} alt="Edit" width={16} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TelematicsConnected;
