import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

/**
 * Internal component that is mounted when application is loaded and displays a
 * progress indicator until all essential data is loaded fetched from the API.
 * Note: This component is not part of the design, but is useful for improving the UX.
 * @component
 */
const Init = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

export default Init;
