import Welcome from './welcome';
import { Provider } from './provider';
import Connected from './connected';
import Validation from './validation';
import * as TelematicsApplication from './applicationContext';
import PreviouslyConnected from './previously-connected';
import ReviewConnection from './review-connection';

export const TelematicsWelcome = () => (
  <TelematicsApplication.Provider>
    <TelematicsApplication.Context.Consumer>
      {(ctx) => <Welcome {...ctx} />}
    </TelematicsApplication.Context.Consumer>
  </TelematicsApplication.Provider>
);

export const TelematicsPreviouslyConnected = () => (
  <TelematicsApplication.Provider>
    <TelematicsApplication.Context.Consumer>
      {(ctx) => <PreviouslyConnected {...ctx} />}
    </TelematicsApplication.Context.Consumer>
  </TelematicsApplication.Provider>
);

export const TelematicsReviewConnection = () => (
  <TelematicsApplication.Provider>
    <TelematicsApplication.Context.Consumer>
      {(ctx) => <ReviewConnection {...ctx} />}
    </TelematicsApplication.Context.Consumer>
  </TelematicsApplication.Provider>
);

export const TelematicsProviderSelect = () => (
  <TelematicsApplication.Provider>
    <TelematicsApplication.Context.Consumer>
      {(ctx) => <Provider {...ctx} />}
    </TelematicsApplication.Context.Consumer>
  </TelematicsApplication.Provider>
);
export const TelematicsConnected = Connected;
export const TelematicsValidation = Validation;
