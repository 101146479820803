import AppetiteLiteService from 'src/helpers/appetiteLiteService';

export async function getAppetiteLiteDetails(dotNumber: number) {
  const { data } = await AppetiteLiteService.getAppetiteLiteDetails(dotNumber);
  return data;
}

export async function postAppetiteLiteDetails(dotNumber: number) {
  const { data } = await AppetiteLiteService.requestAppetiteLiteDetails(
    dotNumber,
  );
  return data;
}
