import { FormHelperText } from '@material-ui/core';
import {
  FileDestinationGroup,
  FileMetadata,
  FileType,
} from '@nirvana/api/quoting';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelApplicationFilesUpload,
  downloadApplicationFile,
  removeApplicationFileUpload,
  removeIftaApplicationFileUpload,
  resetIftaApplicationFileUpload,
  retryApplicationFilesUpload,
  uploadApplicationFiles,
} from 'src/features/application/actions';

import { getHelperText } from 'src/features/application/components/create/additionalInformation';
import {
  applicationSelector,
  FileUploadProgress,
} from 'src/features/application/slices';
import { FileUpload } from '../button';
const IftaUploadWithSummary = ({
  control,
  errors,
  setValue,
  watch,
  fileDefaultValue,
}: any) => {
  const application = useSelector(applicationSelector);
  const { iftasUploadedFiles: uploadedFiles } = application;

  const dispatch = useDispatch();

  const values = watch('iftaFiles');

  const [isUploading, setUploading] = React.useState<boolean>(false);

  const handleIftaFileUpload = (files: any) => {
    setUploading(true);

    files.forEach((file: any) => {
      dispatch(
        uploadApplicationFiles(file, {
          type: FileType.FileTypeIftaFile,
          destinationGroup: FileDestinationGroup.FileDestinationGroupQuoting,
        }),
      );
    });
  };

  const handleIftaFileUploadCancel = (progress: FileUploadProgress) => {
    dispatch(cancelApplicationFilesUpload(progress));
  };

  const handleIftaFileUploadRetry = (progress: FileUploadProgress) => {
    dispatch(retryApplicationFilesUpload(progress));
  };

  const handleFileDownload = (handleId?: string) => {
    if (!handleId) return;

    dispatch(downloadApplicationFile(handleId));
  };

  React.useEffect(() => {
    if (!isUploading) return;

    const uploadedFilesValues = Object.values(uploadedFiles);
    const newFiles: any = [];
    uploadedFilesValues.forEach((record) => {
      if (record.status === 'succeeded') {
        newFiles.push(record);
        dispatch(removeIftaApplicationFileUpload(record));
      }
    });
    setValue('iftaFiles', [...newFiles, ...values]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUploading, uploadedFiles, setValue]);

  return (
    <Controller
      name="iftaFiles"
      defaultValue={fileDefaultValue || []}
      control={control}
      rules={{
        validate: {
          required: (value: FileMetadata[]) => {
            if (value.length === 0) {
              return 'Please upload at least one file';
            }
            return true;
          },
        },
      }}
      render={(props) => {
        return (
          <>
            <FileUpload
              key="ifta-file-upload"
              title="IFTAs"
              value={Object.values(uploadedFiles).concat(props.value)}
              onChange={handleIftaFileUpload}
              onRemove={(file) => {
                if (file && file.handle && uploadedFiles[file.handle]) {
                  // Remove by filename
                  dispatch(removeApplicationFileUpload(file));
                } else if (file && file.handle) {
                  const newValue = props.value.filter(
                    (record: FileMetadata) => record.handle !== file.handle,
                  );
                  props.onChange(newValue);
                } else if (!file) {
                  // Remove all
                  dispatch(resetIftaApplicationFileUpload());
                  props.onChange([]);
                } else {
                  // Invalid case - do nothing
                }
              }}
              onCancel={handleIftaFileUploadCancel}
              onRetry={handleIftaFileUploadRetry}
              onDownload={(file) => handleFileDownload(file.handle)}
            />
            {!!errors.iftaFiles && (
              <FormHelperText error>
                {getHelperText('iftaFiles', errors)}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

export const IftaUploadWithSummaryContainer = (props: any) => {
  const methods = useFormContext();

  return <IftaUploadWithSummary {...methods} {...props} />;
};

export default IftaUploadWithSummaryContainer;
export { IftaUploadWithSummary };
