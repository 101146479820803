import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  bool: any;
  float64: any;
  int: any;
  int64: any;
  string: any;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['string'];
  state: Scalars['string'];
  streest2?: Maybe<Scalars['string']>;
  street1: Scalars['string'];
  zip: Scalars['string'];
};

export type Address_InputObject = {
  city: Scalars['string'];
  state: Scalars['string'];
  streest2?: InputMaybe<Scalars['string']>;
  street1: Scalars['string'];
  zip: Scalars['string'];
};

export type BasicScoreDetail = {
  __typename?: 'BasicScoreDetail';
  measure?: Maybe<Scalars['float64']>;
  name: Scalars['string'];
  percentile?: Maybe<Scalars['int64']>;
  threshold: Scalars['float64'];
};

export enum Category {
  /** ControlledSubstancesAlcohol */
  ControlledSubstancesAlcohol = 'ControlledSubstancesAlcohol',
  /** CrashIndicator */
  CrashIndicator = 'CrashIndicator',
  /** DriverFitness */
  DriverFitness = 'DriverFitness',
  /** HMCompliance */
  HmCompliance = 'HMCompliance',
  /** HOSCompliance */
  HosCompliance = 'HOSCompliance',
  /** InsuranceOther */
  InsuranceOther = 'InsuranceOther',
  /** UnsafeDriving */
  UnsafeDriving = 'UnsafeDriving',
  /** Unspecified */
  Unspecified = 'Unspecified',
  /** VehicleMaintenance */
  VehicleMaintenance = 'VehicleMaintenance',
}

export type Chart = {
  __typename?: 'Chart';
  geoJSON?: Maybe<GeoJson>;
  projection?: Maybe<LineChart>;
  table?: Maybe<Table>;
  title?: Maybe<Scalars['string']>;
  url?: Maybe<Scalars['string']>;
};

export type ClaimFeedback = {
  __typename?: 'ClaimFeedback';
  category: FeedbackCategory;
  claimId: Scalars['string'];
  createdAt: Scalars['Time'];
  createdBy: Scalars['string'];
  id: Scalars['string'];
  rating: Scalars['int'];
  value?: Maybe<Scalars['string']>;
};

export enum ClaimStatus {
  /** Closed */
  Closed = 'Closed',
  /** CreatedInError */
  CreatedInError = 'CreatedInError',
  /** Invalid */
  Invalid = 'Invalid',
  /** Open */
  Open = 'Open',
  /** Reopen */
  Reopen = 'Reopen',
}

export type ClaimVehicle_InputObject = {
  registrationNumber?: InputMaybe<Scalars['string']>;
  vIN?: InputMaybe<Scalars['string']>;
};

export type Data = {
  __typename?: 'Data';
  xVal?: Maybe<Scalars['string']>;
  yVals: Array<Scalars['float64']>;
};

export type DeleteSentInspectionsResponse = {
  __typename?: 'DeleteSentInspectionsResponse';
  deletedCount: Scalars['int64'];
};

export enum DotRating {
  /** ConditionalRating */
  ConditionalRating = 'ConditionalRating',
  /** SatisfactoryRating */
  SatisfactoryRating = 'SatisfactoryRating',
  /** Unrated */
  Unrated = 'Unrated',
  /** UnsatisfactoryRating */
  UnsatisfactoryRating = 'UnsatisfactoryRating',
}

export type ExplainabilityRecommendation = {
  __typename?: 'ExplainabilityRecommendation';
  category: Scalars['int'];
  iD: Scalars['string'];
  impactPercent: Scalars['float64'];
  markdown: Scalars['string'];
  title: Scalars['string'];
};

export enum FeedbackCategory {
  /** FasterClaimResolution */
  FasterClaimResolution = 'FasterClaimResolution',
  /** FrequentAdjusterCommunication */
  FrequentAdjusterCommunication = 'FrequentAdjusterCommunication',
  /** Invalid */
  Invalid = 'Invalid',
  /** OfferFairerCompensation */
  OfferFairerCompensation = 'OfferFairerCompensation',
  /** Other */
  Other = 'Other',
  /** OutstandingService */
  OutstandingService = 'OutstandingService',
  /** ProvideBetterSupport */
  ProvideBetterSupport = 'ProvideBetterSupport',
  /** RequireFewerDocuments */
  RequireFewerDocuments = 'RequireFewerDocuments',
}

export enum FlagCategory {
  /** BASICS */
  Basics = 'BASICS',
  /** Crashes */
  Crashes = 'Crashes',
  /** DOTRating */
  DotRating = 'DOTRating',
  /** Insurance */
  Insurance = 'Insurance',
  /** RelatedEntities */
  RelatedEntities = 'RelatedEntities',
  /** Violations */
  Violations = 'Violations',
}

export type FlagConnection = {
  __typename?: 'FlagConnection';
  edges: Array<FlagEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['int64'];
};

export type FlagEdge = {
  __typename?: 'FlagEdge';
  cursor: Scalars['string'];
  node: Flag;
};

export enum FlagSeverity {
  /** Critical */
  Critical = 'Critical',
  /** Minor */
  Minor = 'Minor',
  /** Moderate */
  Moderate = 'Moderate',
}

export type FleetSafetyReportConnection = {
  __typename?: 'FleetSafetyReportConnection';
  edges: Array<FleetSafetyReportEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['int64'];
};

export type FleetSafetyReportEdge = {
  __typename?: 'FleetSafetyReportEdge';
  cursor: Scalars['string'];
  node: FleetSafetyReport;
};

export type FleetSearchResult = {
  __typename?: 'FleetSearchResult';
  dotNumber: Scalars['string'];
  lastViewedAt?: Maybe<Scalars['Time']>;
  name: Scalars['string'];
  state?: Maybe<Scalars['string']>;
};

export type Fnol = {
  __typename?: 'Fnol';
  attachments: Array<FnolAttachment>;
  clientClaimNumber: Scalars['string'];
  contacts: Array<FnolContact>;
  createdAt: Scalars['Time'];
  createdBy: Scalars['string'];
  id: Scalars['string'];
  incidentDescription: Scalars['string'];
  injuriesInvolved: Scalars['bool'];
  lossDatetime: Scalars['Time'];
  lossLocation: Scalars['string'];
  lossState: Scalars['string'];
  nonVehiclePropertyDamageDescription?: Maybe<Scalars['string']>;
  noticeType: FnolNoticeType;
  policeAgencyName?: Maybe<Scalars['string']>;
  policeReportNumber?: Maybe<Scalars['string']>;
  policyNumber: Scalars['string'];
  status: FnolStatus;
  vehicles: Array<FnolVehicle>;
};

export type FnolAttachment = {
  __typename?: 'FnolAttachment';
  fnolId: Scalars['string'];
  handleId: Scalars['string'];
  id: Scalars['string'];
};

export type FnolContact = {
  __typename?: 'FnolContact';
  contactType: Scalars['int'];
  email?: Maybe<Scalars['string']>;
  firstName: Scalars['string'];
  fnolId: Scalars['string'];
  id: Scalars['string'];
  lastName: Scalars['string'];
  phone: Scalars['string'];
};

export enum FnolNoticeType {
  /** Claim */
  Claim = 'Claim',
  /** Invalid */
  Invalid = 'Invalid',
  /** Report */
  Report = 'Report',
}

export enum FnolStatus {
  /** Declined */
  Declined = 'Declined',
  /** Invalid */
  Invalid = 'Invalid',
  /** Processed */
  Processed = 'Processed',
  /** Sent */
  Sent = 'Sent',
}

export type FnolVehicle = {
  __typename?: 'FnolVehicle';
  fnolId: Scalars['string'];
  id: Scalars['string'];
  isInsuredVehicle: Scalars['bool'];
  registrationNumber?: Maybe<Scalars['string']>;
  vIN?: Maybe<Scalars['string']>;
};

export type GeoFeature = {
  __typename?: 'GeoFeature';
  geometry?: Maybe<Geometry>;
  properties?: Maybe<Properties>;
  type?: Maybe<Scalars['string']>;
};

export type GeoJson = {
  __typename?: 'GeoJSON';
  features: Array<GeoFeature>;
};

export type Geometry = {
  __typename?: 'Geometry';
  coordinates: Array<Array<Array<Scalars['float64']>>>;
  type: Scalars['string'];
};

export type IssScore = {
  __typename?: 'ISSScore';
  discount?: Maybe<Scalars['float64']>;
  errorMessage?: Maybe<Scalars['string']>;
  hardBrakingCountPer1000Miles?: Maybe<Scalars['float64']>;
  hardBrakingPercentile?: Maybe<Scalars['float64']>;
  month: Scalars['int'];
  speedingCountPer1000Miles?: Maybe<Scalars['float64']>;
  speedingPercentile?: Maybe<Scalars['float64']>;
  value?: Maybe<Scalars['float64']>;
  year: Scalars['int'];
};

export type InitiateDriverViolationFetchResults = {
  __typename?: 'InitiateDriverViolationFetchResults';
  browserWSEndpoint: Scalars['string'];
  twoFactorUrl: Scalars['string'];
};

export enum InspectionLevel {
  /** DriverOnly */
  DriverOnly = 'DriverOnly',
  /** Full */
  Full = 'Full',
  /** Material */
  Material = 'Material',
  /** SpecialStudy */
  SpecialStudy = 'SpecialStudy',
  /** Terminal */
  Terminal = 'Terminal',
  /** Unknown */
  Unknown = 'Unknown',
  /** WalkAround */
  WalkAround = 'WalkAround',
}

export type InspectionRecordConnection = {
  __typename?: 'InspectionRecordConnection';
  edges: Array<InspectionRecordEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['int64'];
};

export type InspectionRecordEdge = {
  __typename?: 'InspectionRecordEdge';
  cursor: Scalars['string'];
  node: Inspection;
};

export type InsuranceRecord = {
  __typename?: 'InsuranceRecord';
  carrier?: Maybe<Scalars['string']>;
  effectiveDateFrom?: Maybe<Scalars['Time']>;
  effectiveDateTo?: Maybe<Scalars['Time']>;
  policyNumber?: Maybe<Scalars['string']>;
  status?: Maybe<Scalars['string']>;
  types?: Maybe<Scalars['string']>;
};

export type LineChart = {
  __typename?: 'LineChart';
  data: Array<Data>;
  lineConfigs: Array<LineConfig>;
  threshold?: Maybe<Scalars['float64']>;
  xField?: Maybe<Scalars['string']>;
  xLabel?: Maybe<Scalars['string']>;
  yLabel?: Maybe<Scalars['string']>;
};

export type LineConfig = {
  __typename?: 'LineConfig';
  dashed?: Maybe<Scalars['bool']>;
  name?: Maybe<Scalars['string']>;
};

export enum LineOfBusiness {
  /** AutoLiability */
  AutoLiability = 'AutoLiability',
  /** GeneralLiability */
  GeneralLiability = 'GeneralLiability',
  /** Invalid */
  Invalid = 'Invalid',
  /** MotorTruckCargo */
  MotorTruckCargo = 'MotorTruckCargo',
}

export type Location = {
  __typename?: 'Location';
  countyCode?: Maybe<Scalars['string']>;
  countyName?: Maybe<Scalars['string']>;
};

export type MonthlyValues = {
  __typename?: 'MonthlyValues';
  mileage?: Maybe<Scalars['float64']>;
  month: Scalars['int'];
  pU?: Maybe<Scalars['float64']>;
  utilization?: Maybe<Scalars['float64']>;
  year: Scalars['int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateUser?: Maybe<ActivateUserResponse>;
  completeDriverViolationFetch: Scalars['bool'];
  createAgencyAndBDMapping?: Maybe<Agency>;
  createClaimFeedback?: Maybe<ClaimFeedback>;
  createFNOL?: Maybe<Fnol>;
  createFleetSafetyReport?: Maybe<FleetSafetyReport>;
  createRole?: Maybe<Role>;
  createUser?: Maybe<User>;
  deactivateUser: Scalars['bool'];
  deleteRole: Scalars['bool'];
  deleteSentInspections: DeleteSentInspectionsResponse;
  finalizeTelematics?: Maybe<FinalizeTelematicsResponse>;
  initiateTelematics?: Maybe<InitiateTelematicsResponse>;
  inviteAgencyUserFromAgents?: Maybe<AgencyUserInvite>;
  inviteAgencyUserFromSupport?: Maybe<AgencyUserInvite>;
  inviteFleetUser?: Maybe<FleetUserInvite>;
  patchAgency?: Maybe<Agency>;
  patchUser?: Maybe<User>;
  printFleetSafetyReport?: Maybe<PrintFleetSafetyReportLink>;
  setFleetStarredStatus: Scalars['bool'];
  shareSafetyReport?: Maybe<SafetyReportShare>;
  updateAgentDetail: Scalars['bool'];
  updateUserNotificationPreferences: Array<UserWorkflowPreference>;
};

export type MutationActivateUserArgs = {
  email: Scalars['string'];
  firstName: Scalars['string'];
  lastName: Scalars['string'];
  password: Scalars['string'];
  phoneNumber?: InputMaybe<Scalars['string']>;
  profilePicture?: InputMaybe<Scalars['string']>;
  title?: InputMaybe<Scalars['string']>;
};

export type MutationCompleteDriverViolationFetchArgs = {
  browserWSEndpoint: Scalars['string'];
  reportId: Scalars['string'];
  twoFA: Scalars['string'];
  twoFactorUrl: Scalars['string'];
};

export type MutationCreateAgencyAndBdMappingArgs = {
  address: Address_InputObject;
  bDUserID: Scalars['string'];
  name: Scalars['string'];
};

export type MutationCreateClaimFeedbackArgs = {
  category: FeedbackCategory;
  claimId: Scalars['string'];
  rating: Scalars['int'];
  value?: InputMaybe<Scalars['string']>;
};

export type MutationCreateFnolArgs = {
  attachmentKeys?: InputMaybe<Array<Scalars['string']>>;
  description?: InputMaybe<Scalars['string']>;
  injuriesInvolved?: InputMaybe<Scalars['bool']>;
  insuredName?: InputMaybe<Scalars['string']>;
  insuredVehicles?: InputMaybe<Array<ClaimVehicle_InputObject>>;
  lineOfBusiness: LineOfBusiness;
  lossDate: Scalars['Time'];
  lossLocation?: InputMaybe<Scalars['string']>;
  lossState: Scalars['string'];
  noticeType: FnolNoticeType;
  otherVehicles?: InputMaybe<Array<ClaimVehicle_InputObject>>;
  police?: InputMaybe<Police_InputObject>;
  policyNumber: Scalars['string'];
  reporter: Reporter_InputObject;
};

export type MutationCreateFleetSafetyReportArgs = {
  delegateUserID?: InputMaybe<Scalars['string']>;
  dotNumber: Scalars['string'];
  starred?: InputMaybe<Scalars['bool']>;
};

export type MutationCreateRoleArgs = {
  agencyID?: InputMaybe<Scalars['string']>;
  fleetID?: InputMaybe<Scalars['string']>;
  group: RoleGroupEnum;
  userID: Scalars['string'];
};

export type MutationCreateUserArgs = {
  email: Scalars['string'];
  firstName: Scalars['string'];
  lastName: Scalars['string'];
  password: Scalars['string'];
  phoneNumber?: InputMaybe<Scalars['string']>;
  profilePicture?: InputMaybe<Scalars['string']>;
  roles: Array<CreateUserRoleArgs_InputObject>;
  title?: InputMaybe<Scalars['string']>;
};

export type MutationDeactivateUserArgs = {
  userID: Scalars['string'];
};

export type MutationDeleteRoleArgs = {
  roleID: Scalars['string'];
  userID: Scalars['string'];
};

export type MutationDeleteSentInspectionsArgs = {
  inspectionIDs: Array<Scalars['int64']>;
  reportID: Scalars['string'];
};

export type MutationFinalizeTelematicsArgs = {
  authCode: Scalars['string'];
  error?: InputMaybe<Scalars['string']>;
  scope?: InputMaybe<Scalars['string']>;
  state: Scalars['string'];
  tsp: Tsp;
};

export type MutationInitiateTelematicsArgs = {
  safetyReportID: Scalars['string'];
  tsp: Tsp;
};

export type MutationInviteAgencyUserFromAgentsArgs = {
  agencyID: Scalars['string'];
  email: Scalars['string'];
  role: RoleGroupEnum;
};

export type MutationInviteAgencyUserFromSupportArgs = {
  agencyID: Scalars['string'];
  email: Scalars['string'];
  role: RoleGroupEnum;
};

export type MutationInviteFleetUserArgs = {
  email: Scalars['string'];
  fleetSafetyReportID: Scalars['string'];
};

export type MutationPatchAgencyArgs = {
  address?: InputMaybe<Address_InputObject>;
  id: Scalars['string'];
  name?: InputMaybe<Scalars['string']>;
};

export type MutationPatchUserArgs = {
  email?: InputMaybe<Scalars['string']>;
  firstName?: InputMaybe<Scalars['string']>;
  id: Scalars['string'];
  lastName?: InputMaybe<Scalars['string']>;
  oldPassword?: InputMaybe<Scalars['string']>;
  password?: InputMaybe<Scalars['string']>;
  phoneNumber?: InputMaybe<Scalars['string']>;
  profilePicture?: InputMaybe<Scalars['string']>;
  title?: InputMaybe<Scalars['string']>;
};

export type MutationPrintFleetSafetyReportArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  reportID: Scalars['string'];
  startTime?: InputMaybe<Scalars['Time']>;
};

export type MutationSetFleetStarredStatusArgs = {
  markStarred: Scalars['bool'];
  reportId: Scalars['string'];
  userID?: InputMaybe<Scalars['string']>;
};

export type MutationShareSafetyReportArgs = {
  delegateUserID?: InputMaybe<Scalars['string']>;
  id: Scalars['string'];
};

export type MutationUpdateAgentDetailArgs = {
  officeDepartmentRegion: Scalars['string'];
  phoneNumber: Scalars['string'];
  preferredStates: Array<Scalars['string']>;
  title: Scalars['string'];
};

export type MutationUpdateUserNotificationPreferencesArgs = {
  preferences: Array<UserWorkflowPreference_InputObject>;
};

export type Note = {
  __typename?: 'Note';
  category?: Maybe<Scalars['string']>;
  claimExternalId: Scalars['string'];
  createdAt: Scalars['Time'];
  externalId: Scalars['string'];
  id: Scalars['string'];
  modifiedAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
  value: Scalars['string'];
};

export type OosSummary = {
  __typename?: 'OOSSummary';
  category: Scalars['string'];
  inspections: Scalars['int'];
  nationalAverage: Scalars['float64'];
  oOSPercent: Scalars['float64'];
  oOSViolations: Scalars['int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['string'];
  hasNextPage: Scalars['bool'];
  hasPrevPage: Scalars['bool'];
  pages: Array<Scalars['string']>;
  startCursor: Scalars['string'];
};

export type Police_InputObject = {
  agencyName?: InputMaybe<Scalars['string']>;
  reportNumber?: InputMaybe<Scalars['string']>;
};

export type Policy = {
  __typename?: 'Policy';
  coverages: Array<PolicyCoverageEnums>;
  endDate: Scalars['Time'];
  id: Scalars['string'];
  insuredDOTNumber: Scalars['string'];
  insuredName: Scalars['string'];
  policyNumber: Scalars['string'];
  startDate: Scalars['Time'];
  state: PolicyState;
};

export enum PolicyCoverageEnums {
  /** CoverageAutoLiability */
  CoverageAutoLiability = 'CoverageAutoLiability',
  /** CoverageAutoPhysicalDamage */
  CoverageAutoPhysicalDamage = 'CoverageAutoPhysicalDamage',
  /** CoverageGeneralLiability */
  CoverageGeneralLiability = 'CoverageGeneralLiability',
  /** CoverageMotorTruckCargo */
  CoverageMotorTruckCargo = 'CoverageMotorTruckCargo',
}

export enum PolicyState {
  /** Active */
  Active = 'Active',
  /** CancellationFiled */
  CancellationFiled = 'CancellationFiled',
  /** Cancelled */
  Cancelled = 'Cancelled',
  /** Created */
  Created = 'Created',
  /** Expired */
  Expired = 'Expired',
  /** Generated */
  Generated = 'Generated',
  /** Invalid */
  Invalid = 'Invalid',
  /** Stale */
  Stale = 'Stale',
}

export type PresignedUploadLink = {
  __typename?: 'PresignedUploadLink';
  key: Scalars['string'];
  url: Scalars['string'];
};

export type PrintFleetSafetyReportLink = {
  __typename?: 'PrintFleetSafetyReportLink';
  id: Scalars['string'];
  url: Scalars['string'];
};

export type Properties = {
  __typename?: 'Properties';
  countyCode?: Maybe<Scalars['string']>;
  inspectionRecords: Array<Inspection>;
  inspection_count?: Maybe<Scalars['int64']>;
  inspection_percentage?: Maybe<Scalars['float64']>;
  name?: Maybe<Scalars['string']>;
  violation_count?: Maybe<Scalars['int64']>;
  violation_percentage?: Maybe<Scalars['float64']>;
};

export type Query = {
  __typename?: 'Query';
  agencies: Array<Agency>;
  agency?: Maybe<Agency>;
  claims: Array<Claims>;
  claimsByDOTNumber: Array<Claims>;
  claimsPresignedUploadLinks: Array<PresignedUploadLink>;
  fetchFleetSafetySearch: Array<FleetSearchResult>;
  fleet?: Maybe<Fleet>;
  fleetSafetyReport?: Maybe<FleetSafetyReport>;
  fleetSafetyReports: FleetSafetyReportConnection;
  fnols: Array<Fnol>;
  initiateDriverViolationFetch?: Maybe<InitiateDriverViolationFetchResults>;
  policies: Array<Policy>;
  user?: Maybe<User>;
  userNotificationPreferences: Array<UserWorkflowPreference>;
  users: Array<User>;
};

export type QueryAgencyArgs = {
  id: Scalars['string'];
};

export type QueryClaimsByDotNumberArgs = {
  dotNumber: Scalars['string'];
};

export type QueryClaimsPresignedUploadLinksArgs = {
  fileNames: Array<Scalars['string']>;
  policyNumber: Scalars['string'];
};

export type QueryFetchFleetSafetySearchArgs = {
  searchText?: InputMaybe<Scalars['string']>;
};

export type QueryFleetArgs = {
  dotNumber?: InputMaybe<Scalars['string']>;
  id?: InputMaybe<Scalars['string']>;
};

export type QueryFleetSafetyReportArgs = {
  id: Scalars['string'];
};

export type QueryFleetSafetyReportsArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  last?: InputMaybe<Scalars['int64']>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryInitiateDriverViolationFetchArgs = {
  password: Scalars['string'];
  reportId: Scalars['string'];
  username: Scalars['string'];
};

export type QueryPoliciesArgs = {
  activeDateIn?: InputMaybe<Scalars['Time']>;
  agencyId?: InputMaybe<Scalars['string']>;
  dotNumber?: InputMaybe<Scalars['string']>;
  hasClaims?: InputMaybe<Scalars['bool']>;
  policyState?: InputMaybe<PolicyState>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['string']>;
};

export type Reason = {
  __typename?: 'Reason';
  basicCategory?: Maybe<Scalars['string']>;
  link?: Maybe<Scalars['string']>;
  vINs: Array<Scalars['string']>;
  violationCount?: Maybe<Scalars['int']>;
  violationGroup?: Maybe<Scalars['string']>;
  weightage?: Maybe<Scalars['float64']>;
};

export type RecommendationConnection = {
  __typename?: 'RecommendationConnection';
  edges: Array<RecommendationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['int64'];
};

export type RecommendationEdge = {
  __typename?: 'RecommendationEdge';
  cursor: Scalars['string'];
  node: Recommendation;
};

export enum RecommendationImpact {
  /** High */
  High = 'High',
  /** Low */
  Low = 'Low',
  /** Medium */
  Medium = 'Medium',
}

export type RecommendationSection = {
  __typename?: 'RecommendationSection';
  chart?: Maybe<Chart>;
  markdown: Scalars['string'];
  reasons: Array<Reason>;
};

export type Reporter_InputObject = {
  email?: InputMaybe<Scalars['string']>;
  firstName?: InputMaybe<Scalars['string']>;
  lastName?: InputMaybe<Scalars['string']>;
  phone?: InputMaybe<Scalars['string']>;
};

export enum RoleGroupEnum {
  /** AgencyAccountManagerRole */
  AgencyAccountManagerRole = 'AgencyAccountManagerRole',
  /** AgencyAdminReaderRole */
  AgencyAdminReaderRole = 'AgencyAdminReaderRole',
  /** AgencyAdminRole */
  AgencyAdminRole = 'AgencyAdminRole',
  /** AgencyProducerRole */
  AgencyProducerRole = 'AgencyProducerRole',
  /** BillingAdminRole */
  BillingAdminRole = 'BillingAdminRole',
  /** CanopiusUnderwriterRole */
  CanopiusUnderwriterRole = 'CanopiusUnderwriterRole',
  /** ClaimsAdminRole */
  ClaimsAdminRole = 'ClaimsAdminRole',
  /** FleetAdminRole */
  FleetAdminRole = 'FleetAdminRole',
  /** FleetBDRole */
  FleetBdRole = 'FleetBDRole',
  /** FleetReaderRole */
  FleetReaderRole = 'FleetReaderRole',
  /** Level1UnderwriterRole */
  Level1UnderwriterRole = 'Level1UnderwriterRole',
  /** Level2UnderwriterRole */
  Level2UnderwriterRole = 'Level2UnderwriterRole',
  /** Level3UnderwriterRole */
  Level3UnderwriterRole = 'Level3UnderwriterRole',
  /** Level4UnderwriterRole */
  Level4UnderwriterRole = 'Level4UnderwriterRole',
  /** Level5UnderwriterRole */
  Level5UnderwriterRole = 'Level5UnderwriterRole',
  /** Level6UnderwriterRole */
  Level6UnderwriterRole = 'Level6UnderwriterRole',
  /** Level7UnderwriterRole */
  Level7UnderwriterRole = 'Level7UnderwriterRole',
  /** NirvanaAPIUserRole */
  NirvanaApiUserRole = 'NirvanaAPIUserRole',
  /** NonFleetBDRole */
  NonFleetBdRole = 'NonFleetBDRole',
  /** PolicyAdminReaderRole */
  PolicyAdminReaderRole = 'PolicyAdminReaderRole',
  /** PowerUserRole */
  PowerUserRole = 'PowerUserRole',
  /** QAUnderwriterRole */
  QaUnderwriterRole = 'QAUnderwriterRole',
  /** SafetyConsultantReader */
  SafetyConsultantReader = 'SafetyConsultantReader',
  /** SeniorSupportRole */
  SeniorSupportRole = 'SeniorSupportRole',
  /** SeniorUnderwriterRole */
  SeniorUnderwriterRole = 'SeniorUnderwriterRole',
  /** SharedFleetTelematicsRole */
  SharedFleetTelematicsRole = 'SharedFleetTelematicsRole',
  /** SharedSafetyReaderRole */
  SharedSafetyReaderRole = 'SharedSafetyReaderRole',
  /** SuperuserRole */
  SuperuserRole = 'SuperuserRole',
  /** UnderwriterManagerRole */
  UnderwriterManagerRole = 'UnderwriterManagerRole',
  /** UserOwnerRole */
  UserOwnerRole = 'UserOwnerRole',
}

export type SafetyReportShare = {
  __typename?: 'SafetyReportShare';
  expiresAt?: Maybe<Scalars['Time']>;
  id: Scalars['string'];
};

export type ScoreTrendItem = {
  __typename?: 'ScoreTrendItem';
  isConfidentScore: Scalars['bool'];
  lowConfidenceReason?: Maybe<Scalars['int']>;
  score: Scalars['float64'];
  timestamp: Scalars['Time'];
};

export type ShipperData = {
  __typename?: 'ShipperData';
  inspectionsCount?: Maybe<Scalars['int']>;
  shipperName?: Maybe<Scalars['string']>;
};

export enum SortOrder {
  /** 0 */
  Asc = 'asc',
  /** 1 */
  Desc = 'desc',
}

export enum Status {
  /** Created */
  Created = 'Created',
  /** Invalid */
  Invalid = 'Invalid',
  /** Undefined */
  Undefined = 'Undefined',
  /** Valid */
  Valid = 'Valid',
  /** Validating */
  Validating = 'Validating',
}

export enum Tsp {
  /** TSP3MD */
  Tsp3Md = 'TSP3MD',
  /** TSP888ELD */
  Tsp888Eld = 'TSP888ELD',
  /** TSPARIFleet */
  TspariFleet = 'TSPARIFleet',
  /** TSPATAndTFleet */
  TspatAndTFleet = 'TSPATAndTFleet',
  /** TSPATAndTFleetComplete */
  TspatAndTFleetComplete = 'TSPATAndTFleetComplete',
  /** TSPActsoftEncore */
  TspActsoftEncore = 'TSPActsoftEncore',
  /** TSPAdvantageAssetTracking */
  TspAdvantageAssetTracking = 'TSPAdvantageAssetTracking',
  /** TSPAdvantageOne */
  TspAdvantageOne = 'TSPAdvantageOne',
  /** TSPAgilisLinxup */
  TspAgilisLinxup = 'TSPAgilisLinxup',
  /** TSPAlfaELD */
  TspAlfaEld = 'TSPAlfaELD',
  /** TSPApolloELD */
  TspApolloEld = 'TSPApolloELD',
  /** TSPArgosConnectedSolutions */
  TspArgosConnectedSolutions = 'TSPArgosConnectedSolutions',
  /** TSPAssuredTelematics */
  TspAssuredTelematics = 'TSPAssuredTelematics',
  /** TSPAttriX */
  TspAttriX = 'TSPAttriX',
  /** TSPAwareGPS */
  TspAwareGps = 'TSPAwareGPS',
  /** TSPAzuga */
  TspAzuga = 'TSPAzuga',
  /** TSPBELLFAMELD */
  Tspbellfameld = 'TSPBELLFAMELD',
  /** TSPBadgerFleetSolutions */
  TspBadgerFleetSolutions = 'TSPBadgerFleetSolutions',
  /** TSPBigRoad */
  TspBigRoad = 'TSPBigRoad',
  /** TSPBlackBearELD */
  TspBlackBearEld = 'TSPBlackBearELD',
  /** TSPBlueArrow */
  TspBlueArrow = 'TSPBlueArrow',
  /** TSPBlueArrowTelematics */
  TspBlueArrowTelematics = 'TSPBlueArrowTelematics',
  /** TSPBlueInkTechnology */
  TspBlueInkTechnology = 'TSPBlueInkTechnology',
  /** TSPBlueStarELD */
  TspBlueStarEld = 'TSPBlueStarELD',
  /** TSPCNELD */
  Tspcneld = 'TSPCNELD',
  /** TSPCTELogELD */
  TspcteLogEld = 'TSPCTELogELD',
  /** TSPCarrierHQ */
  TspCarrierHq = 'TSPCarrierHQ',
  /** TSPClutchELD */
  TspClutchEld = 'TSPClutchELD',
  /** TSPColumbusELD */
  TspColumbusEld = 'TSPColumbusELD',
  /** TSPCommandGPS */
  TspCommandGps = 'TSPCommandGPS',
  /** TSPContiGO */
  TspContiGo = 'TSPContiGO',
  /** TSPCoretex */
  TspCoretex = 'TSPCoretex',
  /** TSPCyntrXELDPlus */
  TspCyntrXeldPlus = 'TSPCyntrXELDPlus',
  /** TSPDSGELOGS */
  Tspdsgelogs = 'TSPDSGELOGS',
  /** TSPDailyELD */
  TspDailyEld = 'TSPDailyELD',
  /** TSPDigitalELD */
  TspDigitalEld = 'TSPDigitalELD',
  /** TSPDreamELD */
  TspDreamEld = 'TSPDreamELD',
  /** TSPDriveEDR */
  TspDriveEdr = 'TSPDriveEDR',
  /** TSPELDFleet */
  TspeldFleet = 'TSPELDFleet',
  /** TSPELDMandate */
  TspeldMandate = 'TSPELDMandate',
  /** TSPELDMandatePlus */
  TspeldMandatePlus = 'TSPELDMandatePlus',
  /** TSPELDMandatePro */
  TspeldMandatePro = 'TSPELDMandatePro',
  /** TSPELDOne */
  TspeldOne = 'TSPELDOne',
  /** TSPELDRider */
  TspeldRider = 'TSPELDRider',
  /** TSPELDTab */
  TspeldTab = 'TSPELDTab',
  /** TSPELOG42 */
  Tspelog42 = 'TSPELOG42',
  /** TSPEROAD */
  Tsperoad = 'TSPEROAD',
  /** TSPEVOELD */
  Tspevoeld = 'TSPEVOELD',
  /** TSPEZELDSolutions */
  TspezeldSolutions = 'TSPEZELDSolutions',
  /** TSPEZFleet */
  TspezFleet = 'TSPEZFleet',
  /** TSPEZLogz */
  TspezLogz = 'TSPEZLogz',
  /** TSPEagleWireless */
  TspEagleWireless = 'TSPEagleWireless',
  /** TSPEnVueTelematics */
  TspEnVueTelematics = 'TSPEnVueTelematics',
  /** TSPExpressWayELD */
  TspExpressWayEld = 'TSPExpressWayELD',
  /** TSPFACTORELD */
  Tspfactoreld = 'TSPFACTORELD',
  /** TSPFMELD */
  Tspfmeld = 'TSPFMELD',
  /** TSPFleetBossGPS */
  TspFleetBossGps = 'TSPFleetBossGPS',
  /** TSPFleetComplete */
  TspFleetComplete = 'TSPFleetComplete',
  /** TSPFleetLocate21 */
  TspFleetLocate21 = 'TSPFleetLocate21',
  /** TSPFleetLocateAdvancedAndCompliance */
  TspFleetLocateAdvancedAndCompliance = 'TSPFleetLocateAdvancedAndCompliance',
  /** TSPFleetLocateELD */
  TspFleetLocateEld = 'TSPFleetLocateELD',
  /** TSPFleetNavSystems */
  TspFleetNavSystems = 'TSPFleetNavSystems',
  /** TSPFleetProfitCenter */
  TspFleetProfitCenter = 'TSPFleetProfitCenter',
  /** TSPFleetSharp */
  TspFleetSharp = 'TSPFleetSharp',
  /** TSPFleetistics */
  TspFleetistics = 'TSPFleetistics',
  /** TSPFleetmaster */
  TspFleetmaster = 'TSPFleetmaster',
  /** TSPFleetmatics */
  TspFleetmatics = 'TSPFleetmatics',
  /** TSPFlexport */
  TspFlexport = 'TSPFlexport',
  /** TSPForceByMojio */
  TspForceByMojio = 'TSPForceByMojio',
  /** TSPForwardThinkingELD */
  TspForwardThinkingEld = 'TSPForwardThinkingELD',
  /** TSPGPSCommander */
  TspgpsCommander = 'TSPGPSCommander',
  /** TSPGPSFleetFinder */
  TspgpsFleetFinder = 'TSPGPSFleetFinder',
  /** TSPGPSInsight */
  TspgpsInsight = 'TSPGPSInsight',
  /** TSPGPSSolutions */
  TspgpsSolutions = 'TSPGPSSolutions',
  /** TSPGPSTab */
  TspgpsTab = 'TSPGPSTab',
  /** TSPGPSTrackingCanada */
  TspgpsTrackingCanada = 'TSPGPSTrackingCanada',
  /** TSPGPSTrackit */
  TspgpsTrackit = 'TSPGPSTrackit',
  /** TSPGarmin */
  TspGarmin = 'TSPGarmin',
  /** TSPGeoforce */
  TspGeoforce = 'TSPGeoforce',
  /** TSPGeotab */
  TspGeotab = 'TSPGeotab',
  /** TSPGlobalELD */
  TspGlobalEld = 'TSPGlobalELD',
  /** TSPGlostone */
  TspGlostone = 'TSPGlostone',
  /** TSPGoFleet */
  TspGoFleet = 'TSPGoFleet',
  /** TSPGoGPS */
  TspGoGps = 'TSPGoGPS',
  /** TSPGoodDealGPS */
  TspGoodDealGps = 'TSPGoodDealGPS',
  /** TSPGorillaSafety */
  TspGorillaSafety = 'TSPGorillaSafety',
  /** TSPGrayboxSolutions */
  TspGrayboxSolutions = 'TSPGrayboxSolutions',
  /** TSPGridline */
  TspGridline = 'TSPGridline',
  /** TSPHighPointGPS */
  TspHighPointGps = 'TSPHighPointGPS',
  /** TSPHorizonPathELD */
  TspHorizonPathEld = 'TSPHorizonPathELD',
  /** TSPISAACInstruments */
  TspisaacInstruments = 'TSPISAACInstruments',
  /** TSPInTouchGPS */
  TspInTouchGps = 'TSPInTouchGPS',
  /** TSPInsightMobileData */
  TspInsightMobileData = 'TSPInsightMobileData',
  /** TSPIntellishift */
  TspIntellishift = 'TSPIntellishift',
  /** TSPIntouchELD */
  TspIntouchEld = 'TSPIntouchELD',
  /** TSPIoTab */
  TspIoTab = 'TSPIoTab',
  /** TSPJJKeller */
  TspjjKeller = 'TSPJJKeller',
  /** TSPKSKELD */
  Tspkskeld = 'TSPKSKELD',
  /** TSPKeepTruckin */
  TspKeepTruckin = 'TSPKeepTruckin',
  /** TSPKeepTruckinSG */
  TspKeepTruckinSg = 'TSPKeepTruckinSG',
  /** TSPKeepTruckinSafety */
  TspKeepTruckinSafety = 'TSPKeepTruckinSafety',
  /** TSPKonexial */
  TspKonexial = 'TSPKonexial',
  /** TSPLBTechnology */
  TsplbTechnology = 'TSPLBTechnology',
  /** TSPLEGACYELD */
  Tsplegacyeld = 'TSPLEGACYELD',
  /** TSPLogPlusELD */
  TspLogPlusEld = 'TSPLogPlusELD',
  /** TSPLookTruckELD */
  TspLookTruckEld = 'TSPLookTruckELD',
  /** TSPLynx */
  TspLynx = 'TSPLynx',
  /** TSPLytXDriveCam */
  TspLytXDriveCam = 'TSPLytXDriveCam',
  /** TSPMOONLIGHTELD */
  Tspmoonlighteld = 'TSPMOONLIGHTELD',
  /** TSPMTELD */
  Tspmteld = 'TSPMTELD',
  /** TSPMasterELD */
  TspMasterEld = 'TSPMasterELD',
  /** TSPMock */
  TspMock = 'TSPMock',
  /** TSPMonarchGPS */
  TspMonarchGps = 'TSPMonarchGPS',
  /** TSPMondo */
  TspMondo = 'TSPMondo',
  /** TSPMountainELD */
  TspMountainEld = 'TSPMountainELD',
  /** TSPMy20ELD */
  TspMy20Eld = 'TSPMy20ELD',
  /** TSPNetradyneInc */
  TspNetradyneInc = 'TSPNetradyneInc',
  /** TSPNextraq */
  TspNextraq = 'TSPNextraq',
  /** TSPNotConnected */
  TspNotConnected = 'TSPNotConnected',
  /** TSPOaneELD */
  TspOaneEld = 'TSPOaneELD',
  /** TSPOmnitracs */
  TspOmnitracs = 'TSPOmnitracs',
  /** TSPOmnitracsES */
  TspOmnitracsEs = 'TSPOmnitracsES',
  /** TSPOmnitracsXRS */
  TspOmnitracsXrs = 'TSPOmnitracsXRS',
  /** TSPOnTrakSolutions */
  TspOnTrakSolutions = 'TSPOnTrakSolutions',
  /** TSPOnePlusELD */
  TspOnePlusEld = 'TSPOnePlusELD',
  /** TSPOneStepGPS */
  TspOneStepGps = 'TSPOneStepGPS',
  /** TSPOntimeELD */
  TspOntimeEld = 'TSPOntimeELD',
  /** TSPOptimaELD */
  TspOptimaEld = 'TSPOptimaELD',
  /** TSPOrbcomm */
  TspOrbcomm = 'TSPOrbcomm',
  /** TSPOrion */
  TspOrion = 'TSPOrion',
  /** TSPOther */
  TspOther = 'TSPOther',
  /** TSPPeopleNet */
  TspPeopleNet = 'TSPPeopleNet',
  /** TSPPhoenixELD */
  TspPhoenixEld = 'TSPPhoenixELD',
  /** TSPPlatformScience */
  TspPlatformScience = 'TSPPlatformScience',
  /** TSPPositrace */
  TspPositrace = 'TSPPositrace',
  /** TSPPowerELD */
  TspPowerEld = 'TSPPowerELD',
  /** TSPPowerFleet */
  TspPowerFleet = 'TSPPowerFleet',
  /** TSPPrePassELD */
  TspPrePassEld = 'TSPPrePassELD',
  /** TSPProLogs */
  TspProLogs = 'TSPProLogs',
  /** TSPProRideELD */
  TspProRideEld = 'TSPProRideELD',
  /** TSPQualityGPS */
  TspQualityGps = 'TSPQualityGPS',
  /** TSPRMJTechnologies */
  TsprmjTechnologies = 'TSPRMJTechnologies',
  /** TSPRandMcNally */
  TspRandMcNally = 'TSPRandMcNally',
  /** TSPRealELD */
  TspRealEld = 'TSPRealELD',
  /** TSPReliableELD */
  TspReliableEld = 'TSPReliableELD',
  /** TSPRigbot */
  TspRigbot = 'TSPRigbot',
  /** TSPRightTruckingELD */
  TspRightTruckingEld = 'TSPRightTruckingELD',
  /** TSPRoadReadySolutions */
  TspRoadReadySolutions = 'TSPRoadReadySolutions',
  /** TSPRoadStarELD */
  TspRoadStarEld = 'TSPRoadStarELD',
  /** TSPRushEnterpises */
  TspRushEnterpises = 'TSPRushEnterpises',
  /** TSPSFELD */
  Tspsfeld = 'TSPSFELD',
  /** TSPSMARTCHOICELOGSELD */
  Tspsmartchoicelogseld = 'TSPSMARTCHOICELOGSELD',
  /** TSPSRELD */
  Tspsreld = 'TSPSRELD',
  /** TSPSTATEELOGS */
  Tspstateelogs = 'TSPSTATEELOGS',
  /** TSPSafetyComplianceSolutions */
  TspSafetyComplianceSolutions = 'TSPSafetyComplianceSolutions',
  /** TSPSafetyVision */
  TspSafetyVision = 'TSPSafetyVision',
  /** TSPSamsara */
  TspSamsara = 'TSPSamsara',
  /** TSPSamsaraSG */
  TspSamsaraSg = 'TSPSamsaraSG',
  /** TSPSamsaraSafety */
  TspSamsaraSafety = 'TSPSamsaraSafety',
  /** TSPSimpleELOG */
  TspSimpleElog = 'TSPSimpleELOG',
  /** TSPSimpleTruckELD */
  TspSimpleTruckEld = 'TSPSimpleTruckELD',
  /** TSPSmartDrive */
  TspSmartDrive = 'TSPSmartDrive',
  /** TSPSmartWitness */
  TspSmartWitness = 'TSPSmartWitness',
  /** TSPSpireonFleetLocate */
  TspSpireonFleetLocate = 'TSPSpireonFleetLocate',
  /** TSPStreetEagle */
  TspStreetEagle = 'TSPStreetEagle',
  /** TSPSwiftELD */
  TspSwiftEld = 'TSPSwiftELD',
  /** TSPTFMELD */
  Tsptfmeld = 'TSPTFMELD',
  /** TSPTMELD */
  Tsptmeld = 'TSPTMELD',
  /** TSPTMobile */
  TsptMobile = 'TSPTMobile',
  /** TSPTRUSTELD */
  Tsptrusteld = 'TSPTRUSTELD',
  /** TSPTTELD */
  Tsptteld = 'TSPTTELD',
  /** TSPTangerine */
  TspTangerine = 'TSPTangerine',
  /** TSPTeletracNavman */
  TspTeletracNavman = 'TSPTeletracNavman',
  /** TSPTrackEnsureInc */
  TspTrackEnsureInc = 'TSPTrackEnsureInc',
  /** TSPTrackOnHOS */
  TspTrackOnHos = 'TSPTrackOnHOS',
  /** TSPTransflo */
  TspTransflo = 'TSPTransflo',
  /** TSPTraxxisGPS */
  TspTraxxisGps = 'TSPTraxxisGPS',
  /** TSPTrendyELD */
  TspTrendyEld = 'TSPTrendyELD',
  /** TSPTrimble */
  TspTrimble = 'TSPTrimble',
  /** TSPTruPathSystems */
  TspTruPathSystems = 'TSPTruPathSystems',
  /** TSPTruckXELD */
  TspTruckXeld = 'TSPTruckXELD',
  /** TSPTruckerPathELDPro */
  TspTruckerPathEldPro = 'TSPTruckerPathELDPro',
  /** TSPUnityELD */
  TspUnityEld = 'TSPUnityELD',
  /** TSPVLogELD */
  TspvLogEld = 'TSPVLogELD',
  /** TSPVTS */
  Tspvts = 'TSPVTS',
  /** TSPVerizonConnect */
  TspVerizonConnect = 'TSPVerizonConnect',
  /** TSPVerizonConnectFleet */
  TspVerizonConnectFleet = 'TSPVerizonConnectFleet',
  /** TSPVerizonConnectReveal */
  TspVerizonConnectReveal = 'TSPVerizonConnectReveal',
  /** TSPVertrax */
  TspVertrax = 'TSPVertrax',
  /** TSPVistaELD */
  TspVistaEld = 'TSPVistaELD',
  /** TSPWebfleet */
  TspWebfleet = 'TSPWebfleet',
  /** TSPWorldTruckingELD */
  TspWorldTruckingEld = 'TSPWorldTruckingELD',
  /** TSPXELD */
  Tspxeld = 'TSPXELD',
  /** TSPZELD */
  Tspzeld = 'TSPZELD',
  /** TSPZenduit */
  TspZenduit = 'TSPZenduit',
  /** TSPZippyELD */
  TspZippyEld = 'TSPZippyELD',
  /** TSPZonar */
  TspZonar = 'TSPZonar',
  /** TSPeasiTrack */
  TsPeasiTrack = 'TSPeasiTrack',
}

export type Table = {
  __typename?: 'Table';
  records: Array<Array<Scalars['string']>>;
};

export type TelematicsVehicle = {
  __typename?: 'TelematicsVehicle';
  handleID: Scalars['string'];
  make: Scalars['string'];
  model: Scalars['string'];
  recommendations: Array<ExplainabilityRecommendation>;
  riskScores: Array<VehicleScoreTrendItem>;
  tspID: Scalars['string'];
  tspName: Scalars['string'];
  vIN: Scalars['string'];
  vin: Scalars['string'];
  year: Scalars['string'];
};

export type UserWorkflowPreference = {
  __typename?: 'UserWorkflowPreference';
  email: Scalars['bool'];
  sms: Scalars['bool'];
  workflow: Scalars['string'];
};

export type UserWorkflowPreference_InputObject = {
  email: Scalars['bool'];
  sms: Scalars['bool'];
  workflow: Scalars['string'];
};

export type VehicleStat = {
  __typename?: 'VehicleStat';
  keyBehaviour?: Maybe<Scalars['string']>;
  riskScore?: Maybe<Scalars['float64']>;
  riskScoreErrorMessages?: Maybe<Scalars['string']>;
  vIN?: Maybe<Scalars['string']>;
  vehicleName?: Maybe<Scalars['string']>;
  vehicleTSPName?: Maybe<Scalars['string']>;
  violationCount?: Maybe<Scalars['int']>;
  violationSeverityTimeWeighted?: Maybe<Scalars['float64']>;
  violationSeverityWeight?: Maybe<Scalars['float64']>;
};

export type VehiclesStatsList = {
  __typename?: 'VehiclesStatsList';
  vehicles: Array<VehicleStat>;
};

export type ViolationStat = {
  __typename?: 'ViolationStat';
  bASICCategory?: Maybe<Scalars['string']>;
  code?: Maybe<Scalars['string']>;
  description?: Maybe<Scalars['string']>;
  group?: Maybe<Scalars['string']>;
  inspectionDate?: Maybe<Scalars['Time']>;
  location?: Maybe<Location>;
  severityWeight?: Maybe<Scalars['int']>;
  vINs: Array<Scalars['string']>;
};

export type ViolationsStatsList = {
  __typename?: 'ViolationsStatsList';
  violations: Array<ViolationStat>;
};

export type ActivateUserResponse = {
  __typename?: 'activateUserResponse';
  expiration: Scalars['Time'];
  fleetSafetyReportId?: Maybe<Scalars['string']>;
  sessionId: Scalars['string'];
};

export type Agency = {
  __typename?: 'agency';
  address?: Maybe<Address>;
  createdAt: Scalars['Time'];
  id: Scalars['string'];
  isTestAgency: Scalars['bool'];
  name: Scalars['string'];
  updatedAt?: Maybe<Scalars['Time']>;
};

export type AgencyUserInvite = {
  __typename?: 'agencyUserInvite';
  shareID: Scalars['string'];
};

export type BasicScore = {
  __typename?: 'basicScore';
  discount?: Maybe<Scalars['float64']>;
  scoreDate: Scalars['string'];
  scores: Array<BasicScoreDetail>;
};

export type Claims = {
  __typename?: 'claims';
  adjusterEmail: Scalars['string'];
  adjusterName: Scalars['string'];
  canSubmitFeedback: Scalars['bool'];
  externalId: Scalars['string'];
  feedbacks: Array<ClaimFeedback>;
  id: Scalars['string'];
  insuredDOTNumber: Scalars['string'];
  insuredName: Scalars['string'];
  lineOfBusiness: Scalars['string'];
  modifiedAt: Scalars['Time'];
  notes: Array<Note>;
  policyNumber: Scalars['string'];
  reportedAt: Scalars['Time'];
  reportedBy?: Maybe<Scalars['string']>;
  status: ClaimStatus;
};

export type CreateUserRoleArgs_InputObject = {
  agencyID?: InputMaybe<Scalars['string']>;
  fleetID?: InputMaybe<Scalars['string']>;
  group: RoleGroupEnum;
};

export type DatagovInspection = {
  __typename?: 'datagovInspection';
  alcoholControlSub?: Maybe<Scalars['bool']>;
  changeDate: Scalars['Time'];
  countyCode: Scalars['string'];
  countyCodeState?: Maybe<Scalars['string']>;
  countyName: Scalars['string'];
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  docketNumber?: Maybe<Scalars['int64']>;
  dotNumber: Scalars['int64'];
  driverOOSTotal: Scalars['int'];
  driverViolTotal: Scalars['int'];
  drugIntrdctnArrests: Scalars['int'];
  drugIntrdctnSearch?: Maybe<Scalars['bool']>;
  grossCompVehicleWeight?: Maybe<Scalars['int']>;
  hazmatOOSTotal: Scalars['int'];
  hazmatViolTotal: Scalars['int'];
  id: Scalars['int64'];
  inspEndTime: Scalars['string'];
  inspFacility: Scalars['string'];
  inspInterstate?: Maybe<Scalars['bool']>;
  inspLevelID: Scalars['int'];
  inspStartTime: Scalars['string'];
  inspectionDate: Scalars['Time'];
  localEnfJurisdiction?: Maybe<Scalars['bool']>;
  location?: Maybe<Scalars['string']>;
  locationDesc?: Maybe<Scalars['string']>;
  mcmisAddDate?: Maybe<Scalars['Time']>;
  oosTotal: Scalars['int'];
  postAccInd?: Maybe<Scalars['bool']>;
  region: Scalars['string'];
  reportNumber: Scalars['string'];
  reportState: Scalars['string'];
  shipperName?: Maybe<Scalars['string']>;
  shippingPaperNumber?: Maybe<Scalars['string']>;
  sizeWeightEnf?: Maybe<Scalars['bool']>;
  statusCode: Scalars['string'];
  telematicsAssignments: Array<TelematicsAssignment>;
  trafficEnf?: Maybe<Scalars['bool']>;
  vehicleOOSTotal: Scalars['int'];
  vehicleViolTotal: Scalars['int'];
  vehicles: Array<DatagovVehicle>;
  violTotal: Scalars['int'];
  violations: Array<DatagovViolation>;
};

export type DatagovVehicle = {
  __typename?: 'datagovVehicle';
  changeDate: Scalars['Time'];
  company?: Maybe<Scalars['string']>;
  createdAt: Scalars['Time'];
  decalIssued?: Maybe<Scalars['bool']>;
  decalNumber?: Maybe<Scalars['string']>;
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['int64'];
  inspectionID: Scalars['int64'];
  licensePlate?: Maybe<Scalars['string']>;
  licenseState?: Maybe<Scalars['string']>;
  make?: Maybe<Scalars['string']>;
  sequenceNumber: Scalars['int'];
  vehicleTypeID: Scalars['int'];
  vin?: Maybe<Scalars['string']>;
};

export type DatagovViolation = {
  __typename?: 'datagovViolation';
  changeDate: Scalars['Time'];
  citationNumber?: Maybe<Scalars['string']>;
  code?: Maybe<Scalars['string']>;
  createdAt: Scalars['Time'];
  defectVerificationID?: Maybe<Scalars['int']>;
  deletedAt?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['string']>;
  group?: Maybe<ViolationGroup>;
  id: Scalars['int64'];
  inspViolUnit: Scalars['string'];
  inspViolationCategoryID: Scalars['int'];
  inspectionID: Scalars['int64'];
  oos?: Maybe<Scalars['bool']>;
  partNumber: Scalars['string'];
  partNumberSection: Scalars['string'];
  seqNumber: Scalars['int'];
  severity?: Maybe<Scalars['int']>;
  vehicle?: Maybe<DatagovVehicle>;
};

export type Driver = {
  __typename?: 'driver';
  id: Scalars['string'];
  licenseNumber: Scalars['string'];
  licenseState: Scalars['string'];
  name: Scalars['string'];
};

export type FinalizeTelematicsResponse = {
  __typename?: 'finalizeTelematicsResponse';
  error?: Maybe<Scalars['string']>;
  handleID: Scalars['string'];
  safetyReportID: Scalars['string'];
};

export type Flag = {
  __typename?: 'flag';
  category: FlagCategory;
  chart?: Maybe<Chart>;
  description: Scalars['string'];
  id: Scalars['string'];
  severity: FlagSeverity;
  title: Scalars['string'];
};

export type Fleet = {
  __typename?: 'fleet';
  createdAt: Scalars['Time'];
  dotNumber: Scalars['string'];
  id: Scalars['string'];
  name: Scalars['string'];
  updatedAt?: Maybe<Scalars['Time']>;
};

export type FleetSafetyReport = {
  __typename?: 'fleetSafetyReport';
  DotRating: DotRating;
  GeneralTrends: Array<MonthlyValues>;
  InsuranceSummary: Array<InsuranceRecord>;
  LocationStats?: Maybe<GeoJson>;
  OOSSummary: Array<OosSummary>;
  ShipperList: Array<ShipperData>;
  TspProvider: Tsp;
  VehiclesStatsList?: Maybe<VehiclesStatsList>;
  ViolationStats?: Maybe<ViolationsStatsList>;
  basicScores: Array<BasicScore>;
  createdAt: Scalars['Time'];
  createdBy: Scalars['string'];
  datagovInspections: Array<DatagovInspection>;
  dotNumber: Scalars['string'];
  explainabilityRecommendations: Array<ExplainabilityRecommendation>;
  flags: FlagConnection;
  fleet?: Maybe<Fleet>;
  fmcsaAuthStatus: Status;
  hasFMCSAPin: Scalars['bool'];
  hasTelematicsConnection: Scalars['bool'];
  id: Scalars['string'];
  inspections: Array<Inspection>;
  inspectionsConnection: InspectionRecordConnection;
  issScores: Array<IssScore>;
  name: Scalars['string'];
  recommendations: RecommendationConnection;
  recommendations_subset: RecommendationConnection;
  starred: Scalars['bool'];
  state?: Maybe<Scalars['string']>;
  telematicsRiskFleetPercentiles: Array<ScoreTrendItem>;
  telematicsRiskVinPercentiles: Array<TelematicsRiskVinPercentile>;
  telematicsVehicles: Array<TelematicsVehicle>;
};

export type FleetSafetyReportLocationStatsArgs = {
  countType: Scalars['string'];
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportVehiclesStatsListArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportViolationStatsArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportBasicScoresArgs = {
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportDatagovInspectionsArgs = {
  endDate?: InputMaybe<Scalars['Time']>;
  startDate?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportFlagsArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  category?: InputMaybe<FlagCategory>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  last?: InputMaybe<Scalars['int64']>;
  severity?: InputMaybe<FlagSeverity>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type FleetSafetyReportInspectionsArgs = {
  endDate?: InputMaybe<Scalars['Time']>;
  minViolations?: InputMaybe<Scalars['int64']>;
  startDate?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportInspectionsConnectionArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  endDate?: InputMaybe<Scalars['Time']>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  last?: InputMaybe<Scalars['int64']>;
  minViolations?: InputMaybe<Scalars['int64']>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportIssScoresArgs = {
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportRecommendationsArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  impact?: InputMaybe<RecommendationImpact>;
  last?: InputMaybe<Scalars['int64']>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type FleetSafetyReportRecommendations_SubsetArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  impact?: InputMaybe<RecommendationImpact>;
  last?: InputMaybe<Scalars['int64']>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type FleetSafetyReportTelematicsRiskFleetPercentilesArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportTelematicsRiskVinPercentilesArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportTelematicsVehiclesArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetUserInvite = {
  __typename?: 'fleetUserInvite';
  shareID: Scalars['string'];
};

export type InitiateTelematicsResponse = {
  __typename?: 'initiateTelematicsResponse';
  handleID: Scalars['string'];
  location?: Maybe<Scalars['string']>;
  state?: Maybe<Scalars['string']>;
  tsp: Tsp;
};

export type Inspection = {
  __typename?: 'inspection';
  combinationVehicleGrossWeight?: Maybe<Scalars['int']>;
  countyCode: Scalars['string'];
  countyName: Scalars['string'];
  dotNumber: Scalars['int64'];
  driver?: Maybe<Driver>;
  driverOOSTotal: Scalars['int'];
  hazmatOOSTotal: Scalars['int'];
  hazmatPlacardReq: Scalars['bool'];
  hazmatViolationsSent: Scalars['int'];
  inspectionDate: Scalars['Time'];
  inspectionID: Scalars['int64'];
  inspectionLevel: InspectionLevel;
  location?: Maybe<Scalars['string']>;
  publicVINs: Array<Scalars['string']>;
  publishedDate: Scalars['Time'];
  region?: Maybe<Scalars['string']>;
  reportNumber: Scalars['string'];
  reportState: Scalars['string'];
  rowID: Scalars['string'];
  shipperName?: Maybe<Scalars['string']>;
  telematicsAssignments: Array<TelematicsAssignment>;
  timeWeight: Scalars['int'];
  totalBASICViols: Scalars['int'];
  totalOOSViolations: Scalars['int'];
  vehicleOOSTotal: Scalars['int'];
  vehicles: Array<Vehicle>;
  violations: Array<Violation>;
  wasLocalEnforcement?: Maybe<Scalars['bool']>;
  wasPostAccident?: Maybe<Scalars['bool']>;
  wasSizeWeightEnforcement?: Maybe<Scalars['bool']>;
  wasTrafficEnforcement?: Maybe<Scalars['bool']>;
};

export type Recommendation = {
  __typename?: 'recommendation';
  engine: Scalars['int'];
  id: Scalars['string'];
  impact: RecommendationImpact;
  sections: Array<RecommendationSection>;
  title: Scalars['string'];
};

export type Role = {
  __typename?: 'role';
  agency?: Maybe<Agency>;
  agencyID?: Maybe<Scalars['string']>;
  createdAt: Scalars['Time'];
  domain: Scalars['string'];
  fleetID?: Maybe<Scalars['string']>;
  group: RoleGroupEnum;
  id: Scalars['string'];
  updatedAt?: Maybe<Scalars['Time']>;
  userID: Scalars['string'];
};

export type TelematicsAssignment = {
  __typename?: 'telematicsAssignment';
  assignedDurationMs: Scalars['int64'];
  driver?: Maybe<TelematicsDriver>;
  vehicle?: Maybe<TelematicsVehicle>;
};

export type TelematicsDriver = {
  __typename?: 'telematicsDriver';
  id: Scalars['string'];
  licenseNumber: Scalars['string'];
  licenseState: Scalars['string'];
  name: Scalars['string'];
};

export type TelematicsRiskVinPercentile = {
  __typename?: 'telematicsRiskVinPercentile';
  scores: Array<VehicleScoreTrendItem>;
  vin: Scalars['string'];
};

export type User = {
  __typename?: 'user';
  agencies: Array<Agency>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  email: Scalars['string'];
  firstName: Scalars['string'];
  id: Scalars['string'];
  lastLoginAt?: Maybe<Scalars['Time']>;
  lastName: Scalars['string'];
  phoneNumber?: Maybe<Scalars['string']>;
  profilePicture?: Maybe<Scalars['string']>;
  roles: Array<Role>;
  safetyReports: Array<FleetSafetyReport>;
  title?: Maybe<Scalars['string']>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type Vehicle = {
  __typename?: 'vehicle';
  company: Scalars['string'];
  make: Scalars['string'];
  model: Scalars['string'];
  vin: Scalars['string'];
};

export type VehicleScoreTrendItem = {
  __typename?: 'vehicleScoreTrendItem';
  fleetID: Scalars['string'];
  handleID: Scalars['string'];
  score: Scalars['float64'];
  telematicsAssignments: Array<TelematicsAssignment>;
  timestamp: Scalars['Time'];
  vin: Scalars['string'];
};

export type Violation = {
  __typename?: 'violation';
  category: Category;
  code: Scalars['string'];
  description: Scalars['string'];
  group: ViolationGroup;
  humanReadableCode: Scalars['string'];
  isDSMS: Scalars['bool'];
  oosIndicator: Scalars['bool'];
  oosWeight: Scalars['int'];
  publishedDate: Scalars['Time'];
  rowID: Scalars['string'];
  severityWeight: Scalars['int'];
  timeWeight: Scalars['int'];
  totalSeverityWeight: Scalars['int'];
  violationID?: Maybe<Scalars['int']>;
};

export type ViolationGroup = {
  __typename?: 'violationGroup';
  category: Category;
  humanReadable: Scalars['string'];
  name: Scalars['string'];
  severity: Scalars['int'];
};

export type UpdateAgentDetailMutationVariables = Exact<{
  title: Scalars['string'];
  phoneNumber: Scalars['string'];
  officeDepartmentRegion: Scalars['string'];
  preferredStates: Array<Scalars['string']> | Scalars['string'];
}>;

export type UpdateAgentDetailMutation = {
  __typename?: 'Mutation';
  updateAgentDetail: any;
};

export type ChangePasswordMutationVariables = Exact<{
  userId: Scalars['string'];
  oldPassword?: InputMaybe<Scalars['string']>;
  newPassword?: InputMaybe<Scalars['string']>;
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  patchUser?: { __typename?: 'user'; id: any } | null;
};

export type InviteUserMutationVariables = Exact<{
  email: Scalars['string'];
  role: RoleGroupEnum;
  agencyID: Scalars['string'];
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteAgencyUserFromAgents?: {
    __typename?: 'agencyUserInvite';
    shareID: any;
  } | null;
};

export type ActivateUserMutationVariables = Exact<{
  firstName: Scalars['string'];
  lastName: Scalars['string'];
  email: Scalars['string'];
  password: Scalars['string'];
}>;

export type ActivateUserMutation = {
  __typename?: 'Mutation';
  activateUser?: {
    __typename?: 'activateUserResponse';
    expiration: any;
    sessionId: any;
  } | null;
};

export type CreateFleetSafetyReportMutationVariables = Exact<{
  dotNumber: Scalars['string'];
}>;

export type CreateFleetSafetyReportMutation = {
  __typename?: 'Mutation';
  createFleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: any;
    name: any;
    dotNumber: any;
    createdAt: any;
  } | null;
};

export type PoliciesQueryVariables = Exact<{
  agencyId: Scalars['string'];
  hasClaims: Scalars['bool'];
}>;

export type PoliciesQuery = {
  __typename?: 'Query';
  policies: Array<{
    __typename?: 'Policy';
    id: any;
    insuredName: any;
    insuredDOTNumber: any;
  }>;
};

export const UpdateAgentDetailDocument = gql`
  mutation UpdateAgentDetail(
    $title: string!
    $phoneNumber: string!
    $officeDepartmentRegion: string!
    $preferredStates: [string!]!
  ) {
    updateAgentDetail(
      title: $title
      phoneNumber: $phoneNumber
      officeDepartmentRegion: $officeDepartmentRegion
      preferredStates: $preferredStates
    )
  }
`;
export type UpdateAgentDetailMutationFn = Apollo.MutationFunction<
  UpdateAgentDetailMutation,
  UpdateAgentDetailMutationVariables
>;

/**
 * __useUpdateAgentDetailMutation__
 *
 * To run a mutation, you first call `useUpdateAgentDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgentDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgentDetailMutation, { data, loading, error }] = useUpdateAgentDetailMutation({
 *   variables: {
 *      title: // value for 'title'
 *      phoneNumber: // value for 'phoneNumber'
 *      officeDepartmentRegion: // value for 'officeDepartmentRegion'
 *      preferredStates: // value for 'preferredStates'
 *   },
 * });
 */
export function useUpdateAgentDetailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAgentDetailMutation,
    UpdateAgentDetailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAgentDetailMutation,
    UpdateAgentDetailMutationVariables
  >(UpdateAgentDetailDocument, options);
}
export type UpdateAgentDetailMutationHookResult = ReturnType<
  typeof useUpdateAgentDetailMutation
>;
export type UpdateAgentDetailMutationResult =
  Apollo.MutationResult<UpdateAgentDetailMutation>;
export type UpdateAgentDetailMutationOptions = Apollo.BaseMutationOptions<
  UpdateAgentDetailMutation,
  UpdateAgentDetailMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword(
    $userId: string!
    $oldPassword: string
    $newPassword: string
  ) {
    patchUser(id: $userId, oldPassword: $oldPassword, password: $newPassword) {
      id
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const InviteUserDocument = gql`
  mutation InviteUser(
    $email: string!
    $role: RoleGroupEnum!
    $agencyID: string!
  ) {
    inviteAgencyUserFromAgents(
      email: $email
      role: $role
      agencyID: $agencyID
    ) {
      shareID
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *      agencyID: // value for 'agencyID'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserMutation,
    InviteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options,
  );
}
export type InviteUserMutationHookResult = ReturnType<
  typeof useInviteUserMutation
>;
export type InviteUserMutationResult =
  Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export const ActivateUserDocument = gql`
  mutation ActivateUser(
    $firstName: string!
    $lastName: string!
    $email: string!
    $password: string!
  ) {
    activateUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      expiration
      sessionId
    }
  }
`;
export type ActivateUserMutationFn = Apollo.MutationFunction<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useActivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateUserMutation,
    ActivateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateUserMutation,
    ActivateUserMutationVariables
  >(ActivateUserDocument, options);
}
export type ActivateUserMutationHookResult = ReturnType<
  typeof useActivateUserMutation
>;
export type ActivateUserMutationResult =
  Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;
export const CreateFleetSafetyReportDocument = gql`
  mutation createFleetSafetyReport($dotNumber: string!) {
    createFleetSafetyReport(dotNumber: $dotNumber) {
      id
      name
      dotNumber
      createdAt
    }
  }
`;
export type CreateFleetSafetyReportMutationFn = Apollo.MutationFunction<
  CreateFleetSafetyReportMutation,
  CreateFleetSafetyReportMutationVariables
>;

/**
 * __useCreateFleetSafetyReportMutation__
 *
 * To run a mutation, you first call `useCreateFleetSafetyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFleetSafetyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFleetSafetyReportMutation, { data, loading, error }] = useCreateFleetSafetyReportMutation({
 *   variables: {
 *      dotNumber: // value for 'dotNumber'
 *   },
 * });
 */
export function useCreateFleetSafetyReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFleetSafetyReportMutation,
    CreateFleetSafetyReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFleetSafetyReportMutation,
    CreateFleetSafetyReportMutationVariables
  >(CreateFleetSafetyReportDocument, options);
}
export type CreateFleetSafetyReportMutationHookResult = ReturnType<
  typeof useCreateFleetSafetyReportMutation
>;
export type CreateFleetSafetyReportMutationResult =
  Apollo.MutationResult<CreateFleetSafetyReportMutation>;
export type CreateFleetSafetyReportMutationOptions = Apollo.BaseMutationOptions<
  CreateFleetSafetyReportMutation,
  CreateFleetSafetyReportMutationVariables
>;
export const PoliciesDocument = gql`
  query Policies($agencyId: string!, $hasClaims: bool!) {
    policies(agencyId: $agencyId, hasClaims: $hasClaims) {
      id
      insuredName
      insuredDOTNumber
    }
  }
`;

/**
 * __usePoliciesQuery__
 *
 * To run a query within a React component, call `usePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoliciesQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      hasClaims: // value for 'hasClaims'
 *   },
 * });
 */
export function usePoliciesQuery(
  baseOptions: Apollo.QueryHookOptions<PoliciesQuery, PoliciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PoliciesQuery, PoliciesQueryVariables>(
    PoliciesDocument,
    options,
  );
}
export function usePoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PoliciesQuery,
    PoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PoliciesQuery, PoliciesQueryVariables>(
    PoliciesDocument,
    options,
  );
}
export type PoliciesQueryHookResult = ReturnType<typeof usePoliciesQuery>;
export type PoliciesLazyQueryHookResult = ReturnType<
  typeof usePoliciesLazyQuery
>;
export type PoliciesQueryResult = Apollo.QueryResult<
  PoliciesQuery,
  PoliciesQueryVariables
>;
