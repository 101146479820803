import { Fragment, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form-v7';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  formatISO,
  isBefore as isDateBefore,
  isAfter as isDateAfter,
  isValid,
  subYears,
  parseISO,
} from 'date-fns';
import { AdmittedAppDriverDetails } from '@nirvana/api/non-fleet';

import Button from 'src/components/button';
import { InputWithoutLabel as OutlinedInput } from 'src/components/input';
import IconPlus from 'src/assets/icons/plus-primary.svg';
import DeleteIcon from 'src/assets/icons/delete.svg';
import FileUploadIcon from 'src/assets/icons/upload-primary.svg';

import {
  CheckboxWithLabel as Checkbox,
  DatePicker,
  Dialog,
  InputNumeric,
  Show,
  constants,
} from '@nirvana/ui-kit';
import { useApplicationDetailsContext } from 'src/features/admitted/hooks/useApplicationDetails';

import FlatFileUploader from './flatFileUploader';
import { useStyles } from './styles';

const defaultValues = {
  licenseNumber: '',
  firstName: '',
  lastName: '',
  licenseState: '',
  yearsOfExp: '',
  dateOfBirth: '',
  dateOfHire: '',
};

const Driver = () => {
  const classes = useStyles();
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  const { applicationDetails } = useApplicationDetailsContext();
  const [openDriversListDialog, setOpenDriversListDialog] =
    useState<boolean>(false);
  const [selectedOwnerDriverDeleteIndex, setSelectedOwnerDriverDeleteIndex] =
    useState<number | undefined>();

  const formErrors = (errors?.driversForm as any)?.drivers;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'driversForm.drivers',
    rules: {
      required: 'Please add at least one driver',
    },
    shouldUnregister: true,
  });

  const handeFileUploadSuccess = (data?: AdmittedAppDriverDetails[]) => {
    data?.forEach((record, index) => {
      update(index, record);
    });
  };

  const isOwnerDriver = useCallback(
    (driver: any) => {
      return (
        driver.firstName ===
          applicationDetails?.admitted?.operationsForm?.businessOwner
            ?.firstName &&
        driver.lastName ===
          applicationDetails?.admitted?.operationsForm?.businessOwner
            ?.lastName &&
        driver.dateOfBirth ===
          applicationDetails?.admitted?.operationsForm?.businessOwner
            ?.dateOfBirth
      );
    },
    [applicationDetails?.admitted?.operationsForm?.businessOwner],
  );

  const getDiverByIndex = (index: number) => {
    return fields[index];
  };

  const removeDriver = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    } else {
      update(0, defaultValues);
    }
  };

  const handleDelete = (index: number) => {
    const driver = getDiverByIndex(index);

    // Prompt user to confirm deletion while deleting owner driver
    if (isOwnerDriver(driver)) {
      setSelectedOwnerDriverDeleteIndex(index);
      return;
    }

    removeDriver(index);
  };

  const handleOwnerDriverDeleteConfirm = () => {
    // Update the business owner driver flag to false
    setValue('operationsForm.businessOwner.driverOnPolicy', false);

    if (selectedOwnerDriverDeleteIndex !== undefined) {
      removeDriver(selectedOwnerDriverDeleteIndex);
    }

    setSelectedOwnerDriverDeleteIndex(undefined);
  };

  const handleOwnerDriverDeleteCancel = () => {
    setSelectedOwnerDriverDeleteIndex(undefined);
  };

  useEffect(() => {
    const hasOwnerDriver = fields.some((driver) => isOwnerDriver(driver));
    const driverOnPolicy =
      getValues().operationsForm.businessOwner.driverOnPolicy;

    if (!hasOwnerDriver && driverOnPolicy) {
      // Add owner driver to the list
      append(
        {
          ...defaultValues,
          firstName:
            applicationDetails?.admitted?.operationsForm?.businessOwner
              ?.firstName,
          lastName:
            applicationDetails?.admitted?.operationsForm?.businessOwner
              ?.lastName,
          dateOfBirth:
            applicationDetails?.admitted?.operationsForm?.businessOwner
              ?.dateOfBirth,
        },
        { focusIndex: 0 },
      );
    } else if (
      !fields.length &&
      !hasOwnerDriver &&
      !applicationDetails?.admitted?.operationsForm?.businessOwner
        ?.driverOnPolicy
    ) {
      // Add a blank row to the list
      append(defaultValues, { focusIndex: 0 });
    }
  }, [
    fields,
    append,
    applicationDetails?.admitted?.operationsForm?.businessOwner,
    isOwnerDriver,
    getValues,
  ]);

  return (
    <Box mt={4}>
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <Button
          variant="outlined"
          onClick={() => setOpenDriversListDialog(true)}
          startIcon={<img src={FileUploadIcon} alt="File Upload" />}
        >
          Upload Driver List
        </Button>
        <FlatFileUploader
          open={openDriversListDialog}
          onClose={() => setOpenDriversListDialog(false)}
          onStart={() => setOpenDriversListDialog(false)}
          onSuccess={handeFileUploadSuccess}
        />
      </Box>
      <table className={classes.table}>
        <thead>
          <tr>
            <th style={{ width: '220px' }}>
              <FormHelperText>CDL Number</FormHelperText>
            </th>
            <th style={{ width: '125px' }}>
              <FormHelperText>Driver’s Full Name</FormHelperText>
            </th>
            <th style={{ width: '125px' }}>&nbsp;</th>
            <th style={{ maxWidth: '145px' }}>
              <FormHelperText>License State</FormHelperText>
            </th>
            <th style={{ width: '130px' }}>
              <FormHelperText>Years of CDL Exp</FormHelperText>
            </th>
            <th style={{ width: '160px' }}>
              <FormHelperText>Date of Birth</FormHelperText>
            </th>
            <th style={{ width: '160px' }}>
              <FormHelperText>Date of Hire</FormHelperText>
            </th>
            <th style={{ width: '165px' }}>
              <FormHelperText>
                Any accidents / violations in the past 3 years?
              </FormHelperText>
            </th>
            <th style={{ minWidth: '40px' }}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => (
            <Fragment key={item.id}>
              <tr>
                <td>
                  <FormControl fullWidth>
                    <OutlinedInput
                      placeholder="CDL Number"
                      {...register(
                        `driversForm.drivers.${index}.licenseNumber`,
                        {
                          required: 'Please enter DL number',
                          setValueAs: (value) => {
                            return value?.trim();
                          },
                        },
                      )}
                      error={!!formErrors?.[index]?.licenseNumber?.message}
                    />
                  </FormControl>
                </td>
                <td>
                  <FormControl fullWidth>
                    <OutlinedInput
                      placeholder="First"
                      error={!!formErrors?.[index]?.firstName?.message}
                      {...register(`driversForm.drivers.${index}.firstName`, {
                        required: 'Please enter first name',
                      })}
                    />
                  </FormControl>
                </td>
                <td>
                  <FormControl fullWidth>
                    <OutlinedInput
                      placeholder="Last"
                      error={!!formErrors?.[index]?.lastName?.message}
                      {...register(`driversForm.drivers.${index}.lastName`, {
                        required: 'Please enter last name',
                      })}
                    />
                  </FormControl>
                </td>
                <td>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      defaultValue=""
                      name={`driversForm.drivers.${index}.licenseState`}
                      rules={{ required: 'Please select state' }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            displayEmpty
                            variant="outlined"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            error={!!formErrors?.[index]?.licenseState?.message}
                          >
                            <MenuItem value="">
                              <Typography color="text.hint">State</Typography>
                            </MenuItem>
                            {constants.usStates.map((record) => (
                              <MenuItem value={record.code} key={record.code}>
                                {record.name}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                </td>
                <td>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      defaultValue=""
                      name={`driversForm.drivers.${index}.yearsOfExp`}
                      rules={{
                        required: 'Please enter Years of Experience',
                        validate: {
                          isValidYears: (value) => {
                            // value should be a positive whole number
                            return (
                              /^[0-9]*$/.test(value) ||
                              'Please enter valid whole number for Years of Experience'
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, value }, fieldState }) => {
                        return (
                          <InputNumeric
                            placeholder="e.g. 10"
                            thousandSeparator={false}
                            value={value}
                            onChange={(e) => onChange(+e.target.value)}
                            error={!!fieldState.error}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </td>
                <td>
                  <Controller
                    control={control}
                    name={`driversForm.drivers.${index}.dateOfBirth`}
                    rules={{
                      required: 'Please enter date of birth',
                      validate: {
                        validDate: (value) => {
                          return (
                            /^([1-2]{1}[0-9]{3})-([0-9]{2})-([0-9]{2})$/.test(
                              value,
                            ) || 'Please enter a valid date of birth'
                          );
                        },
                        minAge: (value) => {
                          // Min age should be 14 years
                          const date = parseISO(value);
                          const minAgeDate = subYears(new Date(), 14);

                          return (
                            isDateBefore(date, minAgeDate) ||
                            'Please enter a valid date of birth'
                          );
                        },
                        maxAge: (value) => {
                          // Max age should be 125 years
                          const date = parseISO(value);
                          const maxAgeDate = subYears(new Date(), 125);

                          return (
                            isDateAfter(date, maxAgeDate) ||
                            'Please enter a valid date of birth'
                          );
                        },
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          value={field.value ? parseISO(field.value) : null}
                          InputProps={{
                            error: !!formErrors?.[index]?.dateOfBirth?.message,
                          }}
                          OpenPickerButtonProps={{
                            size: 'small',
                            sx: { padding: 0, width: '18px' },
                          }}
                          maxDate={new Date()}
                          onChange={(value) => {
                            return field.onChange(
                              value && isValid(value)
                                ? formatISO(value, {
                                    representation: 'date',
                                  })
                                : '',
                            );
                          }}
                        />
                      );
                    }}
                  />
                </td>
                <td>
                  <Controller
                    control={control}
                    name={`driversForm.drivers.${index}.dateOfHire`}
                    rules={{
                      required: 'Please enter date of hire',
                      validate: {
                        validDate: (value) => {
                          return (
                            /^([1-2]{1}[0-9]{3})-([0-9]{2})-([0-9]{2})$/.test(
                              value,
                            ) || 'Please enter a valid date of hire'
                          );
                        },
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          value={field.value ? parseISO(field.value) : null}
                          InputProps={{
                            error: !!formErrors?.[index]?.dateOfHire?.message,
                          }}
                          OpenPickerButtonProps={{
                            size: 'small',
                            sx: { padding: 0, width: '18px' },
                          }}
                          maxDate={new Date()}
                          onChange={(value) => {
                            return field.onChange(
                              value && isValid(value)
                                ? formatISO(value, {
                                    representation: 'date',
                                  })
                                : '',
                            );
                          }}
                        />
                      );
                    }}
                  />
                </td>
                <td align="center">
                  <Controller
                    control={control}
                    name={`driversForm.drivers.${index}.violationInLastThreeYears`}
                    render={({ field }) => (
                      <Checkbox
                        label={
                          <Typography color="text.primary" variant="body2">
                            Yes
                          </Typography>
                        }
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </td>
                <td>
                  <IconButton size="small" onClick={() => handleDelete(index)}>
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </td>
              </tr>
              {!!formErrors?.[index] && (
                <tr>
                  <td colSpan={7}>
                    <FormHelperText error sx={{ pl: 2 }}>
                      {
                        formErrors?.[index]?.[
                          Object.keys(formErrors?.[index])[0]
                        ]?.message
                      }
                    </FormHelperText>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>

      <Show when={fields.length > 0}>
        <Grid container my={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => append(defaultValues)}
              startIcon={<img src={IconPlus} alt="Add Driver" />}
            >
              Add Driver
            </Button>
          </Grid>
        </Grid>
      </Show>

      <Dialog
        open={selectedOwnerDriverDeleteIndex !== undefined}
        maxWidth="xs"
        onClose={handleOwnerDriverDeleteCancel}
        primaryAction={
          <Button
            variant="contained"
            color="primary"
            onClick={handleOwnerDriverDeleteConfirm}
          >
            Remove Driver
          </Button>
        }
        secondaryAction={
          <Button
            variant="outlined"
            onClick={handleOwnerDriverDeleteCancel}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
        }
      >
        <Typography variant="body2" lineHeight="24px">
          Are you sure you want to remove the owner as a driver from your
          policy?
        </Typography>
      </Dialog>
    </Box>
  );
};

export default Driver;
