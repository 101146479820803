import axios from 'axios';
import { storage } from '@nirvana/ui-kit';
import { Configuration, ApplicationApi } from '@nirvana/api/non-fleet';
import { AUTH_TOKEN_STORAGE_KEY } from 'src/constants';

const configOptions = new Configuration();
const apiService = new ApplicationApi(configOptions);

axios.interceptors.request.use((config) => {
  config.headers.JSESSIONID = storage.get(AUTH_TOKEN_STORAGE_KEY);
  return config;
});

export default apiService;
