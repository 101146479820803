import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { useStyles } from '@nirvana/ui-kit/src/components/applicationHeader/styles';

interface ApplicationHeaderClearedApplicationExistsProps {
  isSameAgency: boolean;
}

export default function ApplicationHeaderClearedApplicationExists({
  isSameAgency,
}: ApplicationHeaderClearedApplicationExistsProps) {
  const classes = useStyles();

  return (
    <>
      <div className="h-12 bg-[#F3F4FA] flex items-center justify-around">
        <div className="flex items-center">
          <span className="mr-2">
            <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
          </span>
          <span className="mr-2 font-semibold">Clearance Needed</span>

          {isSameAgency
            ? 'Another agent from your agency recently received preliminary clearance for this application.'
            : 'Another agent recently received preliminary clearance for this application'}
        </div>
      </div>
    </>
  );
}
