import { makeStyles } from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  inputLabel: {
    ...theme.typography.body1,
    whiteSpace: 'normal',
    color: theme.palette.text.hint,
  },
  card: {
    border: '1px solid #E6E7EF',
    boxShadow: '0px 4px 6px rgba(128, 145, 196, 0.16)',
  },
  cardHeader: {
    borderBottom: '1px solid #E6E7EF',
  },
  cardHeaderContent: {
    flex: 'unset',
  },
  editButton: {
    color: theme.palette.info.main,
    marginLeft: '12px',
  },
  summaryContainer: {
    width: 380,
  },
  footer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'end',
    backgroundColor: theme.palette.primary.extraLight,
    borderTop: '1px solid #E6E7EF',
    borderColor: theme.palette.border.main,
    height: 72,
    padding: theme.spacing(2, 2),
    width: '100%',
  },
  footerLabel: {
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  footerToggle: {
    marginRight: theme.spacing(2),

    '& .MuiSwitch-track': {
      backgroundColor: `${theme.palette.primary.track} `,
    },
  },
  markAsCompleted: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    height: 72,
    padding: theme.spacing(2, 2),
    width: '100%',
    border: '1px solid #74C982',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
  },
  completedHeader: {
    ...theme.typography.h5,
    fontWeight: theme.typography.fontWeightMedium,

    marginRight: theme.spacing(2),
  },
}));
