import {
  AdditionalInformationForm,
  AppetiteCheckRuleResponse,
  ApplicationApi,
  ApplicationDetail,
  ApplicationState,
  ApplicationSummary,
  Configuration,
  FileMetadata,
  IndicationForm,
  IndicationOption,
  Producer,
  ProgramType,
  QuoteData,
  QuoteDetails,
  StatusTracker,
  TelematicsInfo,
} from '@nirvana/api/quoting';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/redux/reducers';
import { fetchApplicationState } from '../actions';

export interface ApplicationError {
  message: string | Array<AppetiteCheckRuleResponse>;
}

export declare type RequestStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export const fetchState = createAsyncThunk(
  'application/fetchState',
  async (data: { applicationID: string }, thunkAPI: any) => {
    await thunkAPI.dispatch(fetchApplicationState(data.applicationID));
  },
);

type FetchApplicationStatusParams = {
  applicationId: string;
};
export const fetchApplicationStatus = createAsyncThunk(
  'application/fetchStatus',
  async (data: FetchApplicationStatusParams, thunkAPI: any) => {
    const { auth } = thunkAPI.getState();
    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    const response = await apiService.applicationApplicationIDStatusTrackerGet(
      data.applicationId,
    );

    return response.data;
  },
);

type CreateTelematicsApplicationConsentLinkParams = {
  applicationId: string;
  programType: ProgramType;
  insuredInformation: {
    name: string;
    email: string;
  };
};

type FetchQuoteDataParams = {
  applicationId: string;
};
export const fetchQuoteData = createAsyncThunk(
  'application/fetchQuote',
  async (data: FetchQuoteDataParams, thunkAPI: any) => {
    const { auth } = thunkAPI.getState();
    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    const response = await apiService.applicationApplicationIDQuoteDataGet(
      data.applicationId,
    );

    return response.data;
  },
);

type SubmitApplicationParams = {
  applicationId: string;
};
export const submitApplication = createAsyncThunk(
  'application/submit',
  async (data: SubmitApplicationParams, thunkAPI: any) => {
    const { auth } = thunkAPI.getState();
    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    const response = await apiService.applicationApplicationIDSubmitPost(
      data.applicationId,
    );

    return response.data;
  },
);

type GetQuoteParams = {
  applicationId: string;
};
export const getQuoteDetails = createAsyncThunk(
  'application/quoteDetails',
  async (data: GetQuoteParams, thunkAPI: any) => {
    const { auth } = thunkAPI.getState();
    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    const response = await apiService.applicationApplicationIDQuoteGet(
      data.applicationId,
    );

    return response.data;
  },
);

type SetActiveApplicationPayload = {
  key: string;
  value: any;
};

export interface FileUploadProgress extends FileMetadata {
  total?: number;
  loaded?: number;
  status?: 'loading' | 'succeeded' | 'failed' | 'cancelled';
  updatedAt?: number;
  handle: string;
}

export interface ApplicationListPagination {
  cursor?: string;
  totalCount?: number;
}

export interface ApplicationSliceState {
  list: Array<ApplicationSummary>;
  pagination: ApplicationListPagination;
  isLoading: boolean;
  activeApplication?: ApplicationDetail;
  activeApplicationId: string;
  applicationState?: ApplicationState;
  availableProducers?: Producer[];
  status: RequestStatus;
  applicationStateFetchStatus?: RequestStatus;
  error?: ApplicationError;
  uploadedFiles: { [filename: string]: FileUploadProgress };
  iftasUploadedFiles: { [filename: string]: FileUploadProgress };
  applicationStatus?: StatusTracker;
  quoteData?: QuoteData;
  quoteDetails?: QuoteDetails;
}

type ApplicationUpdatePayload = {
  applicationID: string;
  indicationForm?: IndicationForm;
  selectedIndication?: IndicationOption;
  additionalInfoForm?: AdditionalInformationForm;
};

export const initialState: ApplicationSliceState = {
  list: [],
  pagination: {},
  isLoading: false,
  activeApplicationId: '',
  status: 'idle',
  uploadedFiles: {},
  iftasUploadedFiles: {},
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.status = payload;
    },
    setError: (state, { payload }: PayloadAction<ApplicationError>) => {
      state.error = payload;
    },
    setApplicationsList: (
      state,
      { payload }: PayloadAction<Array<ApplicationSummary>>,
    ) => {
      state.list = payload;
      state.status = 'succeeded';
    },
    appendApplicationsList: (
      state,
      { payload }: PayloadAction<Array<ApplicationSummary>>,
    ) => {
      const existingApplicationsIds = state.list.map(
        ({ applicationID }) => applicationID,
      );
      const newApplications = payload.filter(({ applicationID }) => {
        return !existingApplicationsIds.includes(applicationID);
      });
      state.list = [...state.list, ...newApplications];
      state.status = 'succeeded';
    },
    setPagination: (
      state,
      { payload }: PayloadAction<ApplicationListPagination>,
    ) => {
      state.pagination = payload;
    },
    setActiveApplicationId: (
      state,
      { payload }: PayloadAction<{ applicationID: string }>,
    ) => {
      state.activeApplicationId = payload.applicationID;
    },
    setUpdateSuccess: (
      state,
      { payload }: PayloadAction<ApplicationUpdatePayload>,
    ) => {
      state.activeApplicationId = payload.applicationID;
      if (state.activeApplication && payload.indicationForm) {
        state.activeApplication.indicationForm = payload.indicationForm;
      }

      if (state.activeApplication && payload.additionalInfoForm) {
        state.activeApplication.additionalInfoForm = payload.additionalInfoForm;
      }

      if (state.activeApplication && payload.selectedIndication) {
        state.activeApplication.selectedIndication = {
          ...state.activeApplication.selectedIndication,
          ...payload.selectedIndication,
        };
      }

      state.applicationState = undefined;

      state.status = 'succeeded';
    },
    setActiveApplication: (
      state,
      { payload }: PayloadAction<ApplicationDetail>,
    ) => {
      state.activeApplication = payload;
      state.activeApplicationId = payload.summary.applicationID;
      state.status = 'succeeded';
    },
    setActiveApplicationAttribute: (
      state,
      { payload }: PayloadAction<SetActiveApplicationPayload>,
    ) => {
      if (state.activeApplication) {
        state.activeApplication[payload.key as keyof ApplicationDetail] =
          payload.value;
      }
    },
    resetActiveApplication: (state) => {
      state.activeApplication = undefined;
      state.activeApplicationId = '';
    },
    setApplicationState: (
      state,
      { payload }: PayloadAction<ApplicationState>,
    ) => {
      state.applicationState = payload;
    },
    setFileUpload: (state, { payload }: PayloadAction<FileUploadProgress>) => {
      state.uploadedFiles[payload.handle] = payload;
    },
    removeFileUpload: (
      state,
      { payload }: PayloadAction<FileUploadProgress>,
    ) => {
      delete state.uploadedFiles[payload.handle];
    },
    resetFileUpload: (state) => {
      state.uploadedFiles = {};
    },
    setIftasFileUpload: (
      state,
      { payload }: PayloadAction<FileUploadProgress>,
    ) => {
      state.iftasUploadedFiles[payload.handle] = payload;
    },
    removeIftasFileUpload: (
      state,
      { payload }: PayloadAction<FileUploadProgress>,
    ) => {
      delete state.iftasUploadedFiles[payload.handle];
    },
    resetIftasFileUpload: (state) => {
      state.iftasUploadedFiles = {};
    },

    deleteAvailableProducers: (state) => {
      delete state.availableProducers;
    },
    setAvailableProducers: (state, { payload }: PayloadAction<Producer[]>) => {
      state.availableProducers = payload;
    },
    updateTelematicsInfo: (
      state,
      { payload }: PayloadAction<TelematicsInfo>,
    ) => {
      if (state.activeApplication) {
        state.activeApplication.telematicsInfo = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchState.pending, (state) => {
      state.applicationStateFetchStatus = 'loading';
    });

    builder.addCase(fetchState.fulfilled, (state) => {
      state.applicationStateFetchStatus = 'succeeded';
    });

    builder.addCase(fetchState.rejected, (state) => {
      state.applicationStateFetchStatus = 'failed';
    });
    builder.addCase(fetchApplicationStatus.pending, () => {});
    builder.addCase(fetchApplicationStatus.fulfilled, (state, { payload }) => {
      state.applicationStatus = payload;
    });
    builder.addCase(fetchApplicationStatus.rejected, () => {});
    builder.addCase(fetchQuoteData.fulfilled, (state, { payload }) => {
      state.quoteData = payload;
    });
    builder.addCase(getQuoteDetails.fulfilled, (state, { payload }) => {
      state.quoteDetails = payload;
    });
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = applicationSlice;

// Extract and export each action creator by name
export const {
  setLoading,
  setStatus,
  setApplicationsList,
  appendApplicationsList,
  setPagination,
  setActiveApplicationId,
  setUpdateSuccess,
  setActiveApplication,
  setActiveApplicationAttribute,
  resetActiveApplication,
  updateTelematicsInfo,
  setApplicationState,
  setFileUpload,
  removeFileUpload,
  resetFileUpload,
  setIftasFileUpload,
  removeIftasFileUpload,
  resetIftasFileUpload,
  setAvailableProducers,
  deleteAvailableProducers,
  setError,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const applicationSelector = (state: RootState) => state.application;

export const createFleetConsentLink = createAsyncThunk(
  'application/createFleetConsentLink',
  async (data: CreateTelematicsApplicationConsentLinkParams, thunkAPI: any) => {
    const { auth } = thunkAPI.getState();
    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    const response = await apiService.postTelematicsApplicationConsentLink({
      applicationId: data.applicationId,
      programType: ProgramType.ProgramTypeFleet,
      insuredInformation: {
        name: data.insuredInformation.name,
        email: data.insuredInformation.email,
      },
    });

    thunkAPI.dispatch(
      updateTelematicsInfo({
        ...data.insuredInformation,
        link: response.data.link,
      }),
    );

    return response.data;
  },
);
