import * as React from 'react';

import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export interface IGlobalFilter {
  value?: string;
  onChange: (filterValue: any) => void;
  width?: number;
  placeholder?: string;
}

/**
 * Filter input that is supposed to be used with react-table (Refer: https://react-table.tanstack.com/docs/overview).
 * @component
 *
 * @param {Object} props
 * @param {string} props.value - Filter value typed in the text input.
 * @param {Function} props.onChange - Filter function provided by react-table.
 *
 * @example
 * const {setGlobalFilter} = useTable({}, useGlobalFilter);
 * return <GlobalFilter value="Test" onChange={setGlobalFilter} />
 */
const GlobalFilter = ({
  value,
  onChange,
  width = 220,
  placeholder = 'Search...',
}: IGlobalFilter) => {
  return (
    <TextField
      margin="none"
      variant="outlined"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="primary" />
          </InputAdornment>
        ),
      }}
      style={{
        width,
      }}
      size="small"
      className="search-input"
    />
  );
};

export default GlobalFilter;
