import { makeStyles } from '@material-ui/core';
import { ITheme } from '@nirvana/ui-kit';

export const useStyles = makeStyles((theme: ITheme) => ({
  footerAppBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -2px 4px rgba(128, 145, 196, 0.13)',
  },
  quoteContainer: {
    padding: theme.spacing(4),
  },
  blur: {
    filter: 'blur(6px)',
  },
  quote: {
    ...theme.typography.h5,
    paddingBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: `4px solid ${theme.palette.gold.main}`,
  },

  infoIcon: {
    color: theme.palette.info.main,
    fontSize: 14,
  },
}));
