import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Toolbar } from '@material-ui/core';

import { SidebarContext, SidebarProvider } from 'src/contexts/sidebar';
import Header from '../header';
import Sidebar from '../sidebar';

import { useStyles } from './styles';

/**
 * This is the authorized layout of the application. It renders a header, content, and footer.
 * The children passed in to this component are rendered under content.
 * @component
 *
 * @example
 * return <PrivateLayout>Add some private content here.</PrivateLayout>
 */
const PrivateLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header isPrivate={true} />
      <SidebarProvider>
        <Box flex={1} display="flex" position="relative">
          <SidebarContext.Consumer>
            {({ sidebarCollapsed }) => <Sidebar collapse={sidebarCollapsed} />}
          </SidebarContext.Consumer>
          <main className={classes.content}>
            <Toolbar variant="dense" sx={{ minHeight: '56px' }} />
            <Outlet />
          </main>
        </Box>
      </SidebarProvider>
    </div>
  );
};

export default PrivateLayout;
