import { makeStyles } from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  table: {
    width: '100%',
    tableCollapse: 'collapse',
    margin: 0,
    padding: 0,

    '& th': {
      verticalAlign: 'bottom',
    },

    '& td, th': {
      padding: theme.spacing(0.5, 0.5),
    },
  },
  dialogPaper: {
    width: 600,
  },
  dialogContent: {
    paddingBottom: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    borderBottom: '1px solid #E6E7EF',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
}));
