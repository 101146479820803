/*
 * @function
 * Create a random unique GUID
 */
export const createGuid = () => {
  const S4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  const guid = `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;

  return guid.toLowerCase();
};

/*
 * @function
 * Extract the extension part of a filename
 *
 * @param {string} filename - Filename with extension.
 */
export const getFileTypeByFilename = (filename: string = ''): string => {
  return filename.substr(filename.lastIndexOf('.') + 1);
};

/*
 * @function
 * Get value is a query parameter from a given URL
 *
 * @param {string} name - Name of URL query param for which the value is required.
 * @param {string} url - URL to extract the param from. Default to current browser URL (window.location.href)
 */
export const getURLParameterByName = (
  name: string,
  url: string = '',
): string => {
  let curl = url;
  let cname = name;
  if (!curl) {
    curl = window.location.href;
  }
  cname = cname.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${cname}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(curl);

  if (!results || !results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ''));
};

/*
 * @function
 * Use HTML5 anchor tag's ability to download files
 * by creating a virtual anchor tag element and simulate
 * a click event to trigger the download
 *
 * @param {string} url - URL to download from. The response of this URL should be a file.
 * @param {string} [filename] - Optional. Filename of the file to be downloaded. If no
 * filename is specified, the filename given by backend is used.
 */
export const downloadFile = (url: string, filename?: string) => {
  if (!url) return;

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  if (filename) {
    a.download = filename;
  }
  a.click();
  a.remove();
};

/*
 * @function
 * Check whether a number is a valid int32 number of not
 */
export const isValidInt32 = (value: number) => {
  return value <= 2147483647;
};

/*
 * @function
 * Check whether a given number is a valid US Zip code or not (5 digits)
 */
export const isValidZipCode = (zip: string) => {
  return /^(?!0{3,})[0-9]{5}(-[0-9]{4})?$/.test(zip);
};

/*
 * @function
 * Check whether a given value is numeric or not
 */
export const isNumeric = (num: any) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number);

/*
 * @function
 * Move the position of an element in a given array
 */
export const arrayMove = (
  arr: Array<any>,
  fromIndex: number,
  toIndex: number,
) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);

  return arr;
};

/*
 * @function
 * Validate a VIN number
 */
export const isValidVIN = (vin: string) => {
  vin = vin.toUpperCase();
  if (vin === '1'.repeat(17)) return false;

  function transliterate(c: string) {
    return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10;
  }

  function get_check_digit(vin: string) {
    const map = '0123456789X';
    const weights = '8765432X098765432';
    let sum = 0;
    for (let i = 0; i < 17; ++i) {
      sum += transliterate(vin[i]) * map.indexOf(weights[i]);
    }

    return map[sum % 11];
  }

  function validate(vin: string) {
    if (vin.length !== 17) return false;
    return get_check_digit(vin) === vin[8];
  }

  return validate(vin);
};

/*
 * @function
 * Round off a number to a given number of decimal places, only if decimal places are present
 */
export const roundOffNumber = (num: number, decimalPlaces: number = 2) => {
  const multiplier = 10 ** decimalPlaces;

  return Math.round(num * multiplier) / multiplier;
};

/*
 * @function
 * Add a given percentage to a number
 */

export const addPercents = (
  value: number = 0,
  percent: number,
  fixedVal: number = 0,
) => {
  return (value + (value * percent) / 100).toFixed(fixedVal);
};
