import {
  useFlags,
  useLDClient as useFeatureFlagClient,
  withLDProvider as withFeatureFlagProvider,
} from 'launchdarkly-react-client-sdk';
import { UserProfileResponse } from '@nirvana/api/auth';

export type FleetType = 'fleet' | 'retail-non-fleet' | 'wholesale-non-fleet';
export type AccessType = 'fleet' | 'non-fleet' | 'all';
export const ANONYMOUS_USER_KEY = '00000000-0000-0000-0000-000000000000';

export const Feature = {
  GENERAL_LIABILITY_INTERNAL_STATES: 'generalLiabilityInternalStates',
  GENERAL_LIABILITY_RELEASED_STATES: 'generalLiabilityReleasedStates',
  FLEET_TYPE: 'fleetType',
  FEATURE_RENEWALS: 'renewalApplication',
  RESOURCES_CONTENT: 'resourcesContent',
  SAFETY_CONTENT: 'safetyContent',
  FEATURE_SAFETY_TAB: 'featureSafetyTab',
  FEATURE_WORKRAMP_TAB: 'featureWorkrampTab',
  FEATURE_HELP: 'featureHelp',
  TELEMATICS_RESOURCE: 'telematicsResource',
  TELEMATICS_CONSENT_REVAMP: 'telematicsConsentRevamp',
  NEW_RENEWALS_FLOW: 'renewalFlow',
  FEATURE_POLICIES_LIST: 'policiesList',
  CLAIMS_NEW_PORTAL: 'claimsNewPortal',
  APPLICATION_TYPE_ACCESS: 'applicationTypeAccess',
  PRICELESS_INDICATION: 'pricelessIndication',
  DISABLE_POSTHOG_ANALYTICS: 'disablePosthogAnalytics',
  QUOTE_WITHOUT_TELEMATICS: 'quoteWithoutTelematics',
  FLATFILE_v4: 'flatfileV4',
  ANYTIME_TELEMATICS: 'anytimeTelematics',
  AGENCY_BD_MAPPING: 'agencyBdMapping',
  APPETITE_LITE: 'appetiteLite',
  IMPLER: 'impler',
  APPLICATION_PERSISTANCE: 'applicationPersistence',
  AGENTS_INVITE: 'agentsInvite',
  CLEARANCE_BANNER: 'clearanceBanner',
  INDICATION_RANGES: 'indicationRanges',
  CLAIMS_PORTAL: 'quotingClaims',
};

export const useFeatureFlag = () => {
  const flags = useFlags();

  return (flag: string, fallback: any) => flags[flag] ?? fallback;
};

export const getUserIdentity = (user: UserProfileResponse) => {
  return {
    kind: 'user',
    key: user.id,
    email: user.email.toLowerCase(),
    name: user.name,
    agencyId: user.defaultAgencyId || '',
    agencyRoles:
      user.roles?.agencyRoles
        ?.filter((record) => record.agency.id === user.defaultAgencyId)
        .map((record) => record.role) ?? '',
    nirvanaRoles: user.roles?.nirvanaRoles?.map((record) => record.role) ?? '',
  };
};

export { withFeatureFlagProvider, useFeatureFlagClient };
