import { CircularProgress } from '@material-ui/core';
import { DotPrefillResponse } from '@nirvana/api/quoting';
import { useQueries } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { prefillApplication } from 'src/features/nonFleet/queries/application';
import { APPETITE_LITE_PAGE_VIEW } from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import { getAppetiteLiteDetails } from './queries/appetite-lite';

import OtherConsiderations from './components/other-considerations';
import RecommendationCard from './components/recommendation-card';
import RiskDetails from './components/risk-details';

export default function AppetiteLite() {
  const { dotNumber = '' } = useParams();
  const { capture } = useAnalytics();
  const navigate = useNavigate();

  React.useEffect(() => {
    capture(APPETITE_LITE_PAGE_VIEW, { dotNumber: dotNumber });
  }, [dotNumber]);

  const [
    { data: appetiteLiteData, isLoading: isAppetiteLiteLoading },
    { data: prefillAppData, isLoading: isApplicationLoading },
  ] = useQueries({
    queries: [
      {
        queryKey: ['appetite-lite', dotNumber],
        queryFn: () => getAppetiteLiteDetails(+dotNumber),
      },
      {
        queryKey: ['application-prefill', dotNumber],
        queryFn: () => prefillApplication({ dotNumber: +dotNumber }),
        onError: () => {
          navigate('/applications/list');
        },
        onSuccess: (data: DotPrefillResponse) => {
          if (data.last_reported_power_unit_count <= 10) {
            navigate('/applications/list');
          }
        },
      },
    ],
  });

  const isLoading = isAppetiteLiteLoading || isApplicationLoading;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <CircularProgress />
      </div>
    );
  }

  if (!appetiteLiteData || !prefillAppData) return null;

  return (
    <div className="p-6 bg-background-default">
      <div className="px-6 mb-4 text-2xl font-bold text-text-secondary">
        {prefillAppData.name}
      </div>
      <div className="grid grid-cols-2 gap-8 px-6">
        <div className="flex flex-col max-h-72 ">
          <div className="flex-grow">
            <RecommendationCard data={appetiteLiteData} />
          </div>
        </div>
        <div className="space-y-4">
          <RiskDetails
            risk={appetiteLiteData.risk}
            score={appetiteLiteData.score}
          />
          <OtherConsiderations score={appetiteLiteData.score} />
        </div>
      </div>
    </div>
  );
}
