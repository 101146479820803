import ApplicationContainer from '../applicationContainer';
import Submitted from './submitted';

export default function () {
  return (
    <ApplicationContainer>
      <Submitted />
    </ApplicationContainer>
  );
}
