import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Toolbar,
} from '@material-ui/core';
import {
  ApplicationDetails,
  GetQuoteResponse,
  NRBApp,
  PageState,
} from '@nirvana/api/non-fleet';
import { ApplicationDetail } from '@nirvana/api/quoting';
import { ApplicationHeader, Show } from '@nirvana/ui-kit';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'src/components/button';
import ServerError from 'src/components/serverError';
import { getLoadingSteps } from 'src/features/application/components/create/indicationOptions/constants/steps';

import Loader from '../../../application/components/create/indicationOptions/indicationLoader';
import {
  fetchApplicationDetails,
  fetchIndication,
  submitApplication,
  submitQuote,
  updateApplicationDetails,
} from '../../queries/application';
import { getSteps } from '../create/constants/steps';
import AncillaryCoverages from './ancillary';
import AutoPhysicalDamage from './apd';
import IndicationMoreInfo from './moreInfo';
import MotorTruckCargo from './mtc';
import { useStyles } from './styles';
import IndicationSummary from './summary';

const Indication = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { applicationId = '' } = useParams();
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit, reset } = methods;
  const [indicationStatus, setIndicationStatus] = useState('running');
  const [loaderAnimationStatus, setLoaderAnimationStatus] = useState('idle');

  const [
    { data },
    {
      data: applicationDetails,
      isLoading: isLoadingApplication,
      isInitialLoading,
    },
  ] = useQueries({
    queries: [
      {
        queryKey: ['indication', applicationId],
        queryFn: () => fetchIndication(applicationId),
        enabled: indicationStatus === 'running',
        refetchInterval: 10 * 1000,
        onSuccess: (data: GetQuoteResponse) => {
          setIndicationStatus(data.status);
          queryClient.invalidateQueries(['application', applicationId]);
        },
      },
      {
        queryKey: ['application', applicationId],
        queryFn: () => fetchApplicationDetails(applicationId),
        refetchOnWindowFocus: false,
        onSuccess: ({ pageState }: ApplicationDetails) => {
          if (pageState !== PageState.PageStateSubmitted) {
            const Steps = getSteps(applicationId);
            const currentStep = Steps.find(
              (step) => step.pageState === pageState,
            );
            if (currentStep) {
              navigate(`/nrb/${applicationId}/create`);
            }
          }
        },
      },
    ],
  });

  useEffect(() => {
    if (!isInitialLoading) {
      reset({ coveragesForm: applicationDetails?.nrb?.coveragesForm });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, reset]);

  const { mutate } = useMutation(updateApplicationDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(['application', applicationId]);
    },
    onError: () => {
      navigate(`/nrb/${applicationId}/decline`);
    },
  });

  const { mutate: submit } = useMutation(submitApplication, {
    onSuccess: () => {
      queryClient.invalidateQueries(['application', applicationId]);
      navigate(`/nrb/${applicationId}/success`);
    },
  });

  const { mutate: recalculateQuote, isLoading: isRecalculating } = useMutation(
    submitQuote,
    {
      onSuccess: () => {
        setIndicationStatus('running');
      },
    },
  );

  const onSubmit = useCallback(
    (data: Partial<NRBApp>, e: any, doSubmit?: boolean) => {
      mutate(
        {
          applicationId,
          payload: {
            nrb: data,
            pageState: PageState.PageStateRecalculateQuote,
          },
        },
        {
          onSuccess: () => {
            if (doSubmit) {
              submit(applicationId);
            }
          },
        },
      );
    },
    [mutate, applicationId, submit],
  );

  const handleEditApplication = useCallback(() => {
    mutate(
      { applicationId, payload: { pageState: PageState.PageStateOperations } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['application', applicationId]);
        },
      },
    );
  }, [applicationId, mutate, queryClient]);

  const renderApplicationHeader = () => {
    return (
      <ApplicationHeader
        details={
          {
            summary: {
              shortID: applicationDetails?.shortID,
              companyName:
                applicationDetails?.nrb?.operationsForm?.companyInfo?.name,
              dotNumber:
                applicationDetails?.nrb?.operationsForm?.companyInfo?.dotNumber,
              underwriterName: 'Taylor Gosiaco',

              underwriterEmail: 'taylor@nirvanatech.com',
            },
          } as ApplicationDetail
        }
        elevation={false}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />
    );
  };

  if (data?.appStatus === 'AppStatePanic') {
    return (
      <>
        {renderApplicationHeader()}
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Container maxWidth="md">
            <ServerError />
          </Container>
        </Box>
      </>
    );
  }

  if (isLoadingApplication) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (
    (applicationDetails?.appStatus !== 'AppStateQuoteGenerated' &&
      indicationStatus === 'running' &&
      !isRecalculating) ||
    loaderAnimationStatus === 'loading'
  ) {
    return (
      <>
        {renderApplicationHeader()}
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item position="relative">
            <Box>
              <Loader
                onStatusChange={setLoaderAnimationStatus}
                steps={getLoadingSteps({ isPricelessIndication: false })}
              />
            </Box>
          </Grid>
        </Box>
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      {renderApplicationHeader()}
      <form>
        <Grid container direction="column" spacing={4}>
          <Grid item className="sticky z-10 top-10">
            <Paper
              elevation={0}
              sx={{
                boxShadow: '0px 0px 9px 0px rgba(128, 145, 196, 0.2)',
              }}
              square
            >
              <Container maxWidth="md" sx={{ position: 'relative' }}>
                <Show
                  when={data?.pageState !== PageState.PageStateReferral}
                  fallback={<IndicationMoreInfo />}
                >
                  <IndicationSummary
                    recalculate={
                      applicationDetails?.pageState ===
                      PageState.PageStateRecalculateQuote
                    }
                    recalculateInProgress={isRecalculating}
                    data={data?.quote}
                    onRecalculate={() => recalculateQuote(applicationId)}
                  />
                </Show>
              </Container>
            </Paper>
          </Grid>

          <Grid item>
            <Container maxWidth="md">
              <AutoPhysicalDamage
                coverages={data?.options ?? []}
                metadata={data?.metadata}
                onSubmit={handleSubmit(onSubmit)}
              />
            </Container>
          </Grid>

          <Grid item>
            <Container maxWidth="md">
              <MotorTruckCargo
                coverages={data?.options ?? []}
                metadata={data?.metadata}
                onSubmit={handleSubmit(onSubmit)}
              />
            </Container>
          </Grid>

          <Grid item>
            <Container maxWidth="md">
              <AncillaryCoverages
                coverages={data?.options ?? []}
                metadata={data?.metadata}
                onSubmit={handleSubmit(onSubmit)}
              />
            </Container>
          </Grid>
        </Grid>

        {/* Spacer to compensate for height of fixed bottom bar */}
        <Box py="64px" />

        <AppBar
          classes={{ root: classes.footerAppBar }}
          color="inherit"
          elevation={0}
          position="fixed"
          sx={{ top: 'auto', bottom: 0 }}
        >
          <Container>
            <Toolbar>
              <Grid container spacing={2}>
                <Grid item flexGrow={1} />
                <Grid item>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={handleEditApplication}
                  >
                    Edit Application
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSubmit((data, e) => {
                        onSubmit(data, e, true);
                      })();
                    }}
                  >
                    Submit for Review
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </form>
    </FormProvider>
  );
};

export default Indication;
