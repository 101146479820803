import { Column } from 'react-table';
import { differenceInDays, addYears, format, parseISO } from 'date-fns';
import { Chip } from '@nirvana/ui-kit';
import {
  ApplicationState,
  ApplicationSummary,
  ApplicationTab,
  ApplicationSummaryTelematicsDataStatus,
} from '@nirvana/api/quoting';

import { application as ApplicationHelper } from 'src/helpers';
import SuccessIcon from 'src/assets/icons/check-circle-sm.svg';
import ErrorIcon from 'src/assets/icons/cross-circle-sm.svg';
import RenewalsIcon from 'src/assets/icons/renewal.svg';
import Button from 'src/components/button';
import { ApplicationStatusByState } from 'src/helpers/application';

export interface ApplicationsListTableProps {
  onRenew?: (applicationId: string) => void;
  hasRenew: boolean;
  renewDisabled?: boolean;
  isQuoteWithoutTelematicsEnabled: boolean;
}

export const getColumns = ({
  onRenew,
  hasRenew,
  renewDisabled = false,
  isQuoteWithoutTelematicsEnabled,
}: ApplicationsListTableProps): Array<Column<ApplicationSummary>> => [
  {
    Header: 'Application ID',
    accessor: 'shortID',
    width: '15%',
    disableSortBy: true,
    Cell: ({ value }) => `#${value}`,
  },
  {
    Header: 'Company Name',
    accessor: 'companyName',
    width: '30%',
  },
  {
    Header: 'Producer Name',
    accessor: 'producerName',
    width: '20%',
  },
  {
    Header: 'Effective date',
    accessor: 'effectiveDate',
    width: '15%',
    Cell: ({ value }) => format(parseISO(value), 'MM/dd/yyyy'),
  },
  {
    Header: 'Status',
    accessor: 'state',
    width: '20%',
    disableSortBy: true,
    Cell: ({ value, row }) => {
      const isTSPConnected =
        row.original.telematicsDataStatus ===
        ApplicationSummaryTelematicsDataStatus.TelematicsDataStatusSuccess;
      const applicationStatus =
        ApplicationHelper.getApplicationStatusByState(value);

      const ELDTabValues =
        ApplicationStatusByState[
          ApplicationState.ApplicationStatePendingEldTelematics
        ];

      const isRenewalApp = row.original.renewalMetadata?.originalApplicationId;

      return (
        <div className="">
          <Chip
            icon={
              applicationStatus.icon === 'success' ? (
                <img src={SuccessIcon} />
              ) : applicationStatus.icon === 'error' ? (
                <img src={ErrorIcon} />
              ) : undefined
            }
            label={applicationStatus.label}
            color={applicationStatus.color}
            className="mb-0 mr-0"
          />

          {!isRenewalApp &&
            isQuoteWithoutTelematicsEnabled &&
            isTSPConnected === false &&
            applicationStatus.tab ===
              ApplicationTab.ApplicationTabReadyToBind && (
              <Chip
                label={ELDTabValues.label}
                color={ELDTabValues.color}
                className="mb-0 mr-0"
              />
            )}
        </div>
      );
    },
  },
  ...(hasRenew
    ? [
        {
          Header: '',
          accessor: 'applicationID',
          width: '15%',
          disableSortBy: true,
          Cell: ({ value, row }) => {
            const nextEffectiveDate = addYears(
              parseISO(row.original.effectiveDate),
              1,
            );

            const difference = differenceInDays(nextEffectiveDate, new Date());

            if (
              row.original.state === ApplicationState.ApplicationStateBound &&
              difference >= 0 &&
              difference <= 120
            ) {
              return (
                <Button
                  type="button"
                  variant="outlined"
                  size="small"
                  disabled={renewDisabled}
                  sx={{ lineHeight: 1 }}
                  onClick={() => {
                    if (onRenew) {
                      onRenew(value);
                    }
                  }}
                >
                  Renew
                </Button>
              );
            }

            if (row.original.renewalMetadata?.originalApplicationId) {
              return (
                <div className="flex items-center">
                  <img className="w-3 h-3 mr-3 max-w-none" src={RenewalsIcon} />
                  <p>Renewal</p>
                </div>
              );
            }

            return null;
          },
        } as Column<ApplicationSummary>,
      ]
    : []),
];
