import { Dialog, Show } from '@nirvana/ui-kit';

import Button from 'src/components/button';

import { DialogActions } from '@material-ui/core';
import ClearanceConflictIcon from 'src/assets/icons/clearance-conflict.svg';

interface ClearanceConflictDialogProps {
  open: boolean;
  onClose: () => void;
  handleCreateApplication?: () => void;
  isSameAgency?: boolean;
}

const ClearanceConflictDialog = ({
  onClose,
  open,
  handleCreateApplication,
  isSameAgency = false,
}: ClearanceConflictDialogProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <div className="flex flex-col justify-around items-center space-y-6 p-6 w-[758px] h-[400px]">
        <img src={ClearanceConflictIcon} />

        <div className="text-xl font-semibold text-center">
          <p>
            {isSameAgency
              ? 'Another agent from your agency recently received preliminary clearance for this application'
              : 'Another agent has already received preliminary clearance for this application'}
          </p>
          <div className="mt-4 mb-4 ml-10 space-y-6 text-base font-normal text-left">
            <div>
              To pursue this account, you will need to complete the application
              by:
              <div className="mt-4 space-y-2 list-decimal">
                <p>1. Obtaining a Telematics connection from the insured.</p>
                <p>
                  2. Uploading accurate loss runs or other requested
                  documentation.
                </p>
                <p>
                  3. Securing a{' '}
                  <a
                    className="text-primary-main"
                    href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/assets/agent-resources/BOR+Rules.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    BOR Letter
                  </a>{' '}
                  from the insured.
                </p>
              </div>
            </div>
            <div className="font-normal text-l">
              <div>
                Learn more in our{' '}
                <a
                  className="text-primary-main"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://intercom.help/nirvana-insurance/en/articles/9435293-guide-to-clearance"
                >
                  Guide to Clearance
                </a>
              </div>

              <div>
                Questions? Contact{' '}
                <a
                  className="underline text-primary-main"
                  href="mailto:support@nirvanatech.com"
                >
                  support@nirvanatech.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogActions className="flex flex-row justify-end mt-4 space-x-5 py-9 items-right">
        <Show when={!!handleCreateApplication}>
          <>
            <Button
              variant="outlined"
              onClick={handleClose}
              color="primary"
              className="h-10"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateApplication}
              className="h-10"
            >
              Continue
            </Button>
          </>
        </Show>
      </DialogActions>
    </Dialog>
  );
};

export default ClearanceConflictDialog;
