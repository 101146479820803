import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { CoverageType } from '@nirvana/api/quoting';
import { InputNumeric } from '@nirvana/ui-kit';
import clsx from 'clsx';
import { format, formatISO, parseISO } from 'date-fns';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ITheme } from 'src/assets/themes';
import {
  application as ApplicationHelper,
  utils as UtilsHelper,
} from 'src/helpers';

import { useParams } from 'react-router-dom';
import { LOSS_SUMMARY_PAGE_VIEW } from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import LossHistoryMTC from './lossHistoryMTC';
import { getDateRanges } from './utils';

export const useStyles = makeStyles((theme: ITheme) => ({
  inputLabel: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(1),
    whiteSpace: 'normal',
  },
  labelContainer: {
    paddingTop: theme.spacing(1),
  },
  labelContainerFirst: {
    paddingTop: '30px',
  },
  tableCell: {
    border: 0,
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightRegular,
    padding: '5px 10px',
    width: '25%',
  },
  tableHeaderLabel: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
  },
  periodLabelContainer: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: '5px',
    padding: theme.spacing(1, 2),
  },
}));

const getFirstErrorMessage = (errors: any, index: number) => {
  const fields = errors?.lossRunSummaryForm?.[index]?.lossRunSummary || [];
  for (let i = 0, l = fields.length; i < l; i += 1) {
    if (fields[i]) {
      return fields[i]?.message;
    }
  }

  return '';
};

export interface ILossRunSummaryProps {
  control: any;
  coverages: Array<CoverageType>;
  effectiveDate: string;
  errors: any;
}

type DateRange = {
  key: string;
  from: Date;
  to: Date;
};

/**
 * Form to capture loss runs for last 5 years.
 * The application is submitted for indication options generation after this step.
 * Corresponds to Loss History (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A13613&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const LossRunSummary = ({
  control,
  coverages = [],
  effectiveDate: effectiveDateISO,
  errors,
}: ILossRunSummaryProps) => {
  const classes = useStyles();
  const { applicationId = '' } = useParams();

  const { capture } = useAnalytics();

  React.useEffect(() => {
    capture(LOSS_SUMMARY_PAGE_VIEW, {
      applicationId,
    });
  }, []);
  const effectiveDate = React.useMemo(
    () => (effectiveDateISO ? parseISO(effectiveDateISO) : new Date()),
    [effectiveDateISO],
  );

  const dateRanges: Array<DateRange> = React.useMemo(
    () => getDateRanges(effectiveDate),
    [effectiveDate],
  );

  const applicableCoverages: Array<string> = [
    CoverageType.CoverageAutoLiability,
  ];
  if (coverages.includes(CoverageType.CoverageAutoPhysicalDamage)) {
    applicableCoverages.push(CoverageType.CoverageAutoPhysicalDamage);
  }

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography variant="h4" fontWeight="fontWeightBold">
          Loss History
        </Typography>
      </Grid>
      {applicableCoverages.map((coverage: string, index: number) => (
        <Grid key={coverage} item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={4}>
              <Box
                className={clsx(
                  index === 0
                    ? classes.labelContainerFirst
                    : classes.labelContainer,
                )}
              >
                <Controller
                  control={control}
                  name={`lossRunSummaryForm[${index}].coverageType`}
                  defaultValue={coverage}
                  render={() => (
                    <InputLabel className={classes.inputLabel}>
                      {ApplicationHelper.getIndicationOptionsFeatureLabel(
                        coverage,
                      )}{' '}
                      summary
                    </InputLabel>
                  )}
                />
                <>
                  <FormHelperText sx={{ marginTop: 1 }}>
                    Losses should include indemnity paid + case reserve dollars
                    only (no expense).
                  </FormHelperText>
                  <FormHelperText sx={{ marginTop: 1 }}>
                    Claim counts are to be entered 1 per claim event (not per
                    claimant) and should NOT include zero dollar indemnity
                    events.
                  </FormHelperText>
                </>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Table padding="checkbox">
                {index === 0 && (
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          classes.tableHeaderLabel,
                        )}
                        align="left"
                      >
                        Policy period
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          classes.tableHeaderLabel,
                        )}
                        align="left"
                      >
                        # of power units
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          classes.tableHeaderLabel,
                        )}
                        align="left"
                      >
                        Loss incurred
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          classes.tableHeaderLabel,
                        )}
                        align="left"
                      >
                        # of claims
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}

                <TableBody>
                  {dateRanges.map((record: DateRange, rangeIndex: number) => (
                    <Controller
                      control={control}
                      key={`lossRunSummaryForm[${index}].lossRunSummary[${rangeIndex}]`}
                      name={`lossRunSummaryForm[${index}].lossRunSummary[${rangeIndex}]`}
                      defaultValue={{
                        policyPeriodStartDate: formatISO(record.from, {
                          representation: 'date',
                        }),
                        policyPeriodEndDate: formatISO(record.to, {
                          representation: 'date',
                        }),
                      }}
                      rules={{
                        validate: {
                          numberOfPowerUnits: (value) => {
                            if (
                              (rangeIndex === 0 || rangeIndex === 1) &&
                              !value.numberOfPowerUnits
                            ) {
                              return 'Please enter missing values';
                            } else if (rangeIndex > 1) {
                              // Check for partial data in rows
                              if (
                                !(
                                  value.numberOfPowerUnits ||
                                  value.numberOfPowerUnits === 0
                                ) &&
                                (value.lossIncurred ||
                                  value.lossIncurred === 0 ||
                                  value.numberOfClaims ||
                                  value.numberOfClaims === 0)
                              ) {
                                return 'Please enter missing values';
                              }
                            }

                            if (
                              value.numberOfPowerUnits &&
                              !UtilsHelper.isValidInt32(
                                value.numberOfPowerUnits,
                              )
                            ) {
                              return 'Please enter a valid number';
                            }

                            return true;
                          },
                          lossIncurred: (value) => {
                            const hasLossIncurred = !!value.lossIncurred;
                            const hasNumberOfClaims = !!value.numberOfClaims;

                            if (hasNumberOfClaims && !hasLossIncurred) {
                              return 'Loss incurred is required when claims are present.';
                            }

                            if (
                              (rangeIndex === 0 || rangeIndex === 1) &&
                              !value.lossIncurred &&
                              value.lossIncurred !== 0
                            ) {
                              return 'Please enter missing values';
                            } else if (rangeIndex > 1) {
                              // Check for partial data in rows
                              if (
                                !(
                                  value.lossIncurred || value.lossIncurred === 0
                                ) &&
                                (value.numberOfPowerUnits ||
                                  value.numberOfClaims)
                              ) {
                                return 'Please enter missing values';
                              }
                            }

                            if (
                              value.lossIncurred &&
                              !UtilsHelper.isValidInt32(value.lossIncurred)
                            ) {
                              return 'Please enter a valid number';
                            }

                            return true;
                          },
                          numberOfClaims: (value) => {
                            const hasLossIncurred = !!value.lossIncurred;
                            const hasNumberOfClaims = !!value.numberOfClaims;

                            if (!hasNumberOfClaims && hasLossIncurred) {
                              return 'Number of claims is required when loss incurred is present.';
                            }

                            if (
                              (rangeIndex === 0 || rangeIndex === 1) &&
                              !value.numberOfClaims &&
                              value.numberOfClaims !== 0
                            ) {
                              return 'Please enter missing values';
                            } else if (rangeIndex > 1) {
                              // Check for partial data in rows
                              if (
                                !(
                                  value.numberOfClaims ||
                                  value.numberOfClaims === 0
                                ) &&
                                (value.numberOfPowerUnits || value.lossIncurred)
                              ) {
                                return 'Please enter missing values';
                              }
                            }

                            if (
                              value.numberOfClaims &&
                              !UtilsHelper.isValidInt32(value.numberOfClaims)
                            ) {
                              return 'Please enter a valid number';
                            }

                            // Check for claims with zero dollar loss incurred
                            if (rangeIndex >= 0 && value.numberOfClaims > 0) {
                              if (value.lossIncurred <= 0) {
                                return 'Claims with zero loss incurred are not valid';
                              }
                            }

                            // Check to see number of claims is within 100
                            if (rangeIndex >= 0 && value.numberOfClaims > 100) {
                              return 'Number of claims cannot exceed 100';
                            }

                            return true;
                          },
                        },
                      }}
                      render={(props) => {
                        return (
                          <TableRow key={record.key}>
                            <TableCell className={classes.tableCell}>
                              <Box className={classes.periodLabelContainer}>
                                <Typography
                                  color="textSecondary"
                                  fontWeight="fontWeightRegular"
                                  lineHeight="24px"
                                  whiteSpace="nowrap"
                                >
                                  {format(record.from, 'M/d/yy')}
                                  &nbsp;-&nbsp;
                                  {format(record.to, 'M/d/yy')}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <FormControl>
                                <InputNumeric
                                  size="small"
                                  placeholder="0"
                                  decimalScale={0}
                                  value={props?.value?.numberOfPowerUnits}
                                  onChange={(event) => {
                                    props.onChange({
                                      ...props?.value,
                                      numberOfPowerUnits: event.target.value
                                        ? +event.target.value
                                        : undefined,
                                    });
                                  }}
                                  error={
                                    errors?.lossRunSummaryForm?.[index]
                                      ?.lossRunSummary?.[rangeIndex]?.type ===
                                    'numberOfPowerUnits'
                                  }
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <FormControl>
                                <InputNumeric
                                  size="small"
                                  placeholder="$ 0"
                                  decimalScale={0}
                                  value={props?.value?.lossIncurred}
                                  onChange={(event) => {
                                    props.onChange({
                                      ...props?.value,
                                      lossIncurred:
                                        typeof event.target.value === 'number'
                                          ? +event.target.value
                                          : undefined,
                                    });
                                  }}
                                  prefix="$"
                                  error={
                                    errors?.lossRunSummaryForm?.[index]
                                      ?.lossRunSummary?.[rangeIndex]?.type ===
                                    'lossIncurred'
                                  }
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <FormControl>
                                <InputNumeric
                                  size="small"
                                  placeholder="0"
                                  decimalScale={0}
                                  value={props?.value?.numberOfClaims}
                                  onChange={(event) => {
                                    props.onChange({
                                      ...props?.value,
                                      numberOfClaims:
                                        typeof event.target.value === 'number'
                                          ? +event.target.value
                                          : undefined,
                                    });
                                  }}
                                  error={
                                    errors?.lossRunSummaryForm?.[index]
                                      ?.lossRunSummary?.[rangeIndex]?.type ===
                                    'numberOfClaims'
                                  }
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    />
                  ))}
                  {!!errors?.lossRunSummaryForm?.[index]?.lossRunSummary && (
                    <TableRow>
                      <TableCell className={classes.tableCell} colSpan={4}>
                        <FormHelperText error>
                          {getFirstErrorMessage(errors, index)}
                        </FormHelperText>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const LossRunSummaryContainer = (props: any) => {
  const methods = useFormContext();
  const { coverages } = props;

  if (coverages.includes(CoverageType.CoverageMotorTruckCargo)) {
    return <LossHistoryMTC {...props} {...methods} />;
  }

  return <LossRunSummary {...props} {...methods} />;
};

export default LossRunSummaryContainer;
