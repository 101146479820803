import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { SnackbarProvider } from 'notistack';

import { useLightTheme } from 'src/assets/themes';

import Routes from './routes';

const useStyles = makeStyles(() => ({
  snackbarContainer: {
    bottom: '90px',
  },
}));

const cache = createCache({
  key: 'css',
  prepend: true,
});

/**
 * Theme Wrapper component
 */
export default function ThemeWrapper() {
  const classes = useStyles();
  const theme = useLightTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CacheProvider value={cache}>
        <SnackbarProvider
          maxSnack={3}
          classes={{ containerRoot: classes.snackbarContainer }}
        >
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </SnackbarProvider>
      </CacheProvider>
    </ThemeProvider>
  );
}
