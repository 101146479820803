import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import {
  FormHelperText,
  Grid,
  InputLabel,
  FormControlLabel,
  Grow,
  GridSize,
  useTheme,
} from '@material-ui/core';
import { Checkbox } from '@nirvana/ui-kit';
import { CoverageType } from '@nirvana/api/quoting';

import { application as ApplicationHelper } from 'src/helpers';
import {
  DeductibleUpdateRecord,
  indicationOptionsSelector,
} from 'src/features/application/slices/indicationOptions';

import DeductiblesSkeleton from './skeleton';
import DeductibleSelector from './selector';
import { useStyles } from './../styles';

export interface IDeductibles {
  loading: boolean;
  hasMTC: boolean;
  coverages: CoverageType[];
  onChange: (record: DeductibleUpdateRecord) => void;
}

const Deductibles = ({ loading, coverages, onChange }: IDeductibles) => {
  const classes = useStyles();
  const theme = useTheme();
  const hasAPDMTC = useMemo(() => {
    return (
      coverages.includes(CoverageType.CoverageAutoPhysicalDamage) &&
      coverages.includes(CoverageType.CoverageMotorTruckCargo)
    );
  }, [coverages]);
  const indicationOptionsData = useSelector(indicationOptionsSelector);
  const { coverageVariablesOptions, coveragesWithCombinedDeductibles } =
    indicationOptionsData;
  const [combinedCoverage, setCombinedCoverage] = useState(false);
  const [deductibleColumnSize, setDeductibleColumnSize] = useState(4);
  const deductiblesCoverages = useMemo(() => {
    const possibleCoverages =
      coverageVariablesOptions?.map((record) => record.coverage) || [];

    return ApplicationHelper.getOrderedCoverages(possibleCoverages);
  }, [coverageVariablesOptions]);
  const deductiblesByCoverage = useMemo(() => {
    const result = {};

    for (let i = 0, l = coverageVariablesOptions.length; i < l; i += 1) {
      if (
        !coverageVariablesOptions[i].deductibles ||
        coverageVariablesOptions[i].coverage ===
          CoverageType.CoverageGeneralLiability ||
        (combinedCoverage &&
          coverageVariablesOptions[i].coverage ===
            CoverageType.CoverageMotorTruckCargo)
      ) {
        continue;
      }

      result[coverageVariablesOptions[i].coverage] =
        coverageVariablesOptions[i].deductibles;
    }

    return result;
  }, [coverageVariablesOptions, combinedCoverage]);

  const getLabelByCoverage = (coverage: CoverageType) => {
    if (
      coverage === CoverageType.CoverageAutoPhysicalDamage &&
      combinedCoverage
    ) {
      return `${ApplicationHelper.getIndicationOptionsFeatureLabel(
        CoverageType.CoverageAutoPhysicalDamage,
      )} + ${ApplicationHelper.getIndicationOptionsFeatureLabel(
        CoverageType.CoverageMotorTruckCargo,
      )}`;
    }

    return ApplicationHelper.getIndicationOptionsFeatureLabel(coverage);
  };

  const handleDeductibleChange = (e: any, isCombined: boolean) => {
    const reply = {
      deductibles: {},
      coveragesWithCombinedDeductibles: isCombined
        ? [
            [
              CoverageType.CoverageAutoPhysicalDamage,
              CoverageType.CoverageMotorTruckCargo,
            ],
          ]
        : [],
    };

    // Create a map of coverages and value
    Object.keys(deductiblesByCoverage).forEach((coverage) => {
      if (coverage === e?.target.name) {
        reply.deductibles[coverage] = e.target.value;
      } else {
        reply.deductibles[coverage] = deductiblesByCoverage[coverage].current;
      }
    });

    // Copy APD deductible value to MTC
    if (isCombined) {
      reply.deductibles[CoverageType.CoverageMotorTruckCargo] =
        reply.deductibles[CoverageType.CoverageAutoPhysicalDamage];
    }

    onChange(reply);
  };

  useEffect(() => {
    const isCombined = ApplicationHelper.isCombinedCoverage(
      CoverageType.CoverageAutoPhysicalDamage,
      coveragesWithCombinedDeductibles || [],
    );

    if (isCombined) {
      setCombinedCoverage(true);
    }
  }, [coveragesWithCombinedDeductibles]);

  if (!Object.keys(deductiblesByCoverage).length) {
    return <DeductiblesSkeleton />;
  }

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={5}>
        <InputLabel
          htmlFor="deductiblesOnCoverage-input"
          className={classes.inputLabel}
        >
          Deductibles
        </InputLabel>
        <FormHelperText>
          Premium may change based on the deductible
        </FormHelperText>
      </Grid>
      <Grid item xs={7} container direction="column" spacing={2}>
        {hasAPDMTC && (
          <Grid item>
            <FormControlLabel
              checked={combinedCoverage}
              disabled={loading}
              onChange={(e: any) => {
                if (e.target.checked) {
                  setCombinedCoverage(true);

                  setTimeout(() => {
                    setDeductibleColumnSize(6);
                    handleDeductibleChange(null, true);
                  }, theme.transitions.duration.shortest + 100);
                } else {
                  setDeductibleColumnSize(4);
                  setTimeout(() => {
                    setCombinedCoverage(false);
                    handleDeductibleChange(null, false);
                  }, theme.transitions.duration.shortest + 50);
                }
              }}
              control={<Checkbox />}
              label="Combine APD & MTC deductibles"
              sx={{ overflowX: 'hidden' }}
            />
          </Grid>
        )}
        <Grid item container spacing={2} alignItems="center">
          <TransitionGroup component={null}>
            {deductiblesCoverages.map((coverage) => {
              const deductibleRecord = deductiblesByCoverage?.[coverage];

              // Prevent render until data is available
              if (!deductibleRecord) {
                return null;
              }

              return (
                <Grow
                  timeout={theme.transitions.duration.shortest}
                  key={coverage}
                >
                  <Grid
                    item
                    xs={deductibleColumnSize as GridSize}
                    style={{
                      transition: theme.transitions.create('all', {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shortest,
                      }),
                    }}
                  >
                    <DeductibleSelector
                      getLabel={getLabelByCoverage}
                      coverage={coverage as CoverageType}
                      disabled={loading}
                      options={deductibleRecord.options || []}
                      value={deductibleRecord.current}
                      onChange={(e) =>
                        handleDeductibleChange(e, combinedCoverage)
                      }
                    />
                  </Grid>
                </Grow>
              );
            })}
          </TransitionGroup>
        </Grid>
        <Grid item>
          <span className="text-xs">
            Higher deductibles may be available. Please discuss with your
            underwriter after submission.
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Deductibles;
