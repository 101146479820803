import * as React from 'react';
import { Grid, FormControl, makeStyles } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form-v7';
import { useNavigate, useLocation } from 'react-router-dom';
import { EMAIL_REGEX, ITheme } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';

import Button from 'src/components/button';
import { InputWithLabel } from 'src/components/input';
import { ForgotPasswordRequest } from '@nirvana/api/auth';
import { forgotPassword } from '../../queries';
import PasswordHelper from './PasswordHelper';

const RESEND_TIMEOUT = 30000;

const useStyles = makeStyles((theme: ITheme) => ({
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formControl: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  formControlButton: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2.5),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [waiting, setWaiting] = React.useState(false);
  const [timer, setTimer] = React.useState<number | null>(null);
  const from = location.state?.from?.pathname || '/verification-email';
  const localStorageKey = 'resendLinkTime';

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordRequest>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const startCountdown = (TIME_OUT: number) => {
    setWaiting(true);
    const newTimer: any = setInterval(() => {
      setWaiting(false);
    }, TIME_OUT);
    setTimer(newTimer);
  };

  React.useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timer]);

  React.useEffect(() => {
    const storedTimeStamp = localStorage.getItem(localStorageKey);
    if (storedTimeStamp) {
      const currentTime = new Date().getTime();
      const timeDifference =
        parseInt(storedTimeStamp) - currentTime + RESEND_TIMEOUT;
      if (timeDifference < RESEND_TIMEOUT) {
        startCountdown(timeDifference);
      }
    }
  }, []);

  const { mutate } = useMutation(forgotPassword, {
    onSuccess: (_, variables) => {
      navigate(from, { state: { email: variables.email } });
    },
  });

  const onSubmit = (data: ForgotPasswordRequest) => {
    mutate(data);
  };

  return (
    <Grid container direction="column">
      <Grid item position="relative">
        <PasswordHelper
          heading="Registered Email"
          text="Please provide your registered email id to receive verification link"
        />
      </Grid>
      <Grid item>
        <Grid item>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.loginForm}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Please enter a email',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email address',
                },
              }}
              render={({ field: { value, onChange } }) => (
                <InputWithLabel
                  label="Email ID"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputLabelProps={{ color: 'primary' }}
                  placeholder="Email"
                  type="email"
                  fullWidth
                  value={value}
                  onChange={(e) =>
                    onChange((e.target?.value || '').toLowerCase())
                  }
                  error={!!errors.email}
                />
              )}
            />
            <FormControl className={classes.formControlButton} fullWidth>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!isValid || waiting}
              >
                Send Verification Link
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
