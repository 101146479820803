import { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { ApplicationHeader, HorizontalStepper, ITheme } from '@nirvana/ui-kit';

import IconStepper from 'src/assets/icons/stepper.svg';
import IconApplicationStatus from 'src/assets/icons/application-status.svg';
import { getLastStep, getTimeRangeNF } from 'src/helpers/statusTracker';

import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';
import { fetchApplicationStatus } from '../../queries/application';
import Review from './review';

const useStyles = makeStyles((theme: ITheme) => ({
  card: {
    border: '1px solid #E6E7EF',
    boxShadow: '0px 4px 6px rgba(128, 145, 196, 0.16)',
  },
  cardHeader: {
    borderBottom: '1px solid #E6E7EF',
    backgroundColor: '#FBFCFE',
    padding: theme.spacing(2, 4),
  },
  cardHeaderContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ApplicationSubmitted = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { applicationId, applitionHeaderInfo } = useApplicationDetailsContext();

  const { data } = useQuery({
    queryKey: ['application-status', applicationId],
    queryFn: () => fetchApplicationStatus(applicationId),
  });

  const steps = useMemo(
    () => [
      {
        key: 'statusSubmitted',
        label: 'Application submitted',
        extra: () => {
          if (data?.statusSubmitted?.finalizedDate) {
            return format(
              parseISO(data?.statusSubmitted?.finalizedDate),
              'MM/dd/yyyy',
            );
          } else return null;
        },
      },
      {
        key: 'statusWaitingELDTelematicsConsent',
        label: 'Waiting for ELD/Telematics consent',
        extra: () => {
          if (data?.statusWaitingELDTelematicsConsent.finalizedDate) {
            return format(
              parseISO(data?.statusWaitingELDTelematicsConsent.finalizedDate),
              'MM/dd/yyyy',
            );
          } else return null;
        },
      },
      {
        key: 'statusWaitingELDTelematicsData',
        label: 'Processing ELD/Telematics data',
        extra: () => {
          if (data?.statusWaitingELDTelematicsData.finalizedDate) {
            return format(
              parseISO(data?.statusWaitingELDTelematicsData.finalizedDate),
              'MM/dd/yyyy',
            );
          } else if (
            data?.statusWaitingELDTelematicsData.maxEstimatedDays &&
            data?.statusWaitingELDTelematicsData.minEstimatedDays
          ) {
            return (
              <>
                Data should be received in the next
                {getTimeRangeNF(
                  data?.statusWaitingELDTelematicsData.minEstimatedDays,
                  data?.statusWaitingELDTelematicsData.maxEstimatedDays,
                )}
              </>
            );
          } else return null;
        },
      },
      {
        key: 'statusUnderReviewForQuote',
        label: 'Underwriter review',
        extra: () => {
          if (data?.statusUnderReviewForQuote.finalizedDate) {
            return format(
              parseISO(data?.statusUnderReviewForQuote.finalizedDate),
              'MM/dd/yyyy',
            );
          } else if (
            data?.statusUnderReviewForQuote.maxEstimatedDays &&
            data?.statusUnderReviewForQuote.minEstimatedDays
          ) {
            return (
              <>
                Quote will be ready in{' '}
                {getTimeRangeNF(
                  data?.statusUnderReviewForQuote.minEstimatedDays,
                  data?.statusUnderReviewForQuote.maxEstimatedDays,
                )}
              </>
            );
          }

          return null;
        },
      },
      { key: 'statusQuoteFinalized', label: 'Quote finalized' },
    ],
    [data],
  );

  const activeStep = useMemo(() => {
    return getLastStep(data, steps);
  }, [steps, data]);

  const renderApplicationHeader = () => {
    return (
      <ApplicationHeader
        details={applitionHeaderInfo}
        elevation={false}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />
    );
  };

  return (
    <>
      {renderApplicationHeader()}
      <Container maxWidth="md">
        <Box py={3}>
          <Grid container direction="column" spacing={5}>
            <Grid item>
              <Card>
                <CardHeader
                  disableTypography
                  title={
                    <>
                      <img
                        src={IconApplicationStatus}
                        alt="Application Status Icon"
                      />
                      <Box px={3} flexGrow={1}>
                        <Typography variant="h5" color="textSecondary">
                          Application Status
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <img src={IconStepper} alt="Active Step Icon" />
                        <Typography variant="body1" color="text.hint" ml={1}>
                          Underwriter review
                        </Typography>
                      </Box>
                    </>
                  }
                  classes={{
                    root: classes.cardHeader,
                    content: classes.cardHeaderContent,
                  }}
                />
                <CardContent>
                  <Box p={2} pt={10}>
                    <HorizontalStepper
                      steps={steps}
                      activeStep={activeStep}
                      onChange={() => null}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Review />
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default ApplicationSubmitted;
