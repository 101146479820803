import { AttachmentView, Show, getFormattedDate } from '@nirvana/ui-kit';
import Section from 'src/features/application/components/create/review/section';
import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';
import { BusinessStructureOptions } from '../create/constants/business';

const OperationsView = () => {
  const { applicationDetails } = useApplicationDetailsContext();
  const operationsForm = applicationDetails?.nrb?.operationsForm;

  return (
    <Section
      title="Operations"
      onEdit={() => {}}
      applicationData={{} as any}
      data={[
        {
          key: 'producer',
          label: 'Producer',
          renderValue: () => operationsForm?.producerName,
        },
        {
          key: 'effectiveData',
          label: 'Effective Date',
          renderValue: () => (
            <Show when={operationsForm?.effectiveDate}>
              {(date) => getFormattedDate(date)}
            </Show>
          ),
        },
        {
          key: 'coveragesRequired',
          label: 'Coverages requested',
          renderValue: () => (
            <Show when={operationsForm?.coverageInfo?.primaryCovs}>
              {(coverages) =>
                coverages
                  .filter(({ isRequired }) => isRequired)
                  .map(({ label }) => label)
                  .join(', ')
              }
            </Show>
          ),
        },
        {
          key: 'companyName',
          label: 'Company Name',
          renderValue: () => operationsForm?.companyInfo?.name,
        },
        {
          key: 'businessStructure',
          label: 'Business Structure',
          renderValue: () => {
            return (
              BusinessStructureOptions.find(
                (record) =>
                  record.value ===
                  operationsForm?.companyInfo?.businessStructure,
              )?.label || ''
            );
          },
        },
        {
          key: 'physicalAddress',
          label: 'Physical Business Address',
          renderValue: () => (
            <Show when={operationsForm?.companyInfo?.address}>
              {({ city, state, street, zip }) =>
                `${street}, ${city}, ${state}, ${zip}`
              }
            </Show>
          ),
        },
        {
          key: 'recentBankruptcies',
          label: 'Recent Bankruptcies',
          renderValue: () =>
            operationsForm?.financialInfo?.recentBankruptcies ? 'Yes' : 'No',
        },
        {
          key: 'lossesOver20K',
          label: 'Losses Over 20K',
          renderValue: () =>
            operationsForm?.lossInfo?.hasLossesOver20k ? 'Yes' : 'No',
        },
        {
          key: 'lossRuns',
          label: 'Loss Runs',
          renderValue: () => {
            const lossRunFiles =
              applicationDetails?.nrb?.operationsForm?.lossInfo?.lossRunFiles;
            const numFiles = lossRunFiles?.length || 0;
            return (
              <AttachmentView
                files={[
                  {
                    filename:
                      numFiles === 0
                        ? 'No files uploaded'
                        : `${numFiles} uploaded files`,
                  },
                ]}
              />
            );
          },
        },
      ]}
    />
  );
};

export default OperationsView;
