import { Box, Grid, Typography } from '@material-ui/core';
import {
  ApplicationDetail,
  CoverageType,
  WeightedCommodityRecord,
} from '@nirvana/api/quoting';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { TableView } from 'src/components/table';
import { fetchApplicationById } from 'src/features/application/actions';
import Section, {
  SectionDataRecord,
  SectionDataRenderParams,
} from 'src/features/application/components/create/review/section';
import { useStyles } from 'src/features/application/components/create/review/styles';
import { application as ApplicationHelper } from 'src/helpers';
import { useDispatch } from 'src/redux';
import { z } from 'zod';
import { SECTION_NAMES } from '../constant';
import { ViewProps } from '../create/renewStep2';
import { updateClassesAndCommodities } from '../queries';
import getClassesAndCommoditiesValidationSchema from '../validationSchema/classAndCommodities';
import EditModeFooter from './footer/editModeFooter';
import MarkAsCompletedFooter from './footer/markAsCompletedFooter';
import ClassesAndCommoditiesFormView from './forms/classesAndCommoditiesFormView';
import CommoditiesUnfilledSkeleton from './skeleton/commoditiesUnfilledSkeleton';

const ClassesAndCommoditiesView = ({
  onMarkAsCompleted,
  isMarkAsCompleted,
  isEditable,
  title,
  activeApplication: applicationData,
  markAsCompletedMap,
  isSectionLoading,
}: ViewProps) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const dispatch = useDispatch();
  const { mutate: update } = useMutation(updateClassesAndCommodities, {
    onSuccess: () => {
      dispatch(fetchApplicationById(applicationData.summary.applicationID));
      setEditMode(false);
    },
  });
  const [validationErrors, setValidationErrors] = React.useState<
    z.ZodFormattedError<{
      commodityDistribution: any;
      operatingClassDistribution: {
        operatingClass: unknown;
        percentageOfFleet: unknown;
      }[];
      primaryCategory: unknown;
    }>
  >();

  const methods = useForm();

  const setEditModeVal = (value: boolean) => {
    setEditMode(value);
  };

  const coverages = React.useMemo(() => {
    return (
      applicationData.indicationForm?.operationsForm?.coveragesRequired?.map(
        (record) => record.coverageType,
      ) || []
    );
  }, [applicationData]);

  const hasMTC = React.useMemo(() => {
    return coverages.includes(CoverageType.CoverageMotorTruckCargo);
  }, [coverages]);

  const handleSave = () => {
    methods.handleSubmit((data) => {
      const sanitizedData = {
        ...data,
      };
      if (sanitizedData.commodityDistribution) {
        sanitizedData.commodityDistribution = {
          ...sanitizedData.commodityDistribution,
          commodities: sanitizedData.commodityDistribution.commodities.filter(
            (record: WeightedCommodityRecord) =>
              !!record && !!Object.keys(record).length,
          ),
        };
      }

      if (
        !sanitizedData.primaryOperatingClass &&
        sanitizedData.operatingClassDistribution
      ) {
        const sortedClasses = [
          ...(sanitizedData?.operatingClassDistribution || []),
        ];

        sortedClasses.sort((a: any, b: any) => {
          if (a.percentageOfFleet === b.percentageOfFleet) {
            return a.operatingClass < b.operatingClass ? -1 : 1;
          } else {
            return a.percentageOfFleet < b.percentageOfFleet ? -1 : 1;
          }
        });

        if (sortedClasses[0]) {
          sanitizedData.primaryOperatingClass =
            sortedClasses[0]?.operatingClass;
        }
      }

      if (sanitizedData.commodities) {
        sanitizedData.additionalCommodities = sanitizedData.commodities.filter(
          (record: WeightedCommodityRecord) =>
            !!record && !!Object.keys(record).length,
        );
      }

      update({
        applicationID: applicationData.summary.applicationID,
        classesAndCommodities: sanitizedData as any,
      });
    })();
  };

  const handleMarkAsCompleted = (checked: boolean) => {
    if (validationErrors) return;
    onMarkAsCompleted?.(SECTION_NAMES.CLASSES_AND_COMMODITIES, checked);
  };

  React.useEffect(() => {
    const validationSchema = getClassesAndCommoditiesValidationSchema(hasMTC);

    const validationData = {
      commodityDistribution:
        applicationData.indicationForm?.classesAndCommoditiesForm
          ?.commodityDistribution,
      operatingClassDistribution:
        applicationData.indicationForm?.classesAndCommoditiesForm
          ?.operatingClassDistribution,
      primaryCategory:
        applicationData.indicationForm?.classesAndCommoditiesForm
          ?.primaryCategory,
    };

    const validationResult = validationSchema.safeParse(validationData);

    if (!validationResult.success) {
      setValidationErrors(validationResult.error.format());
    } else {
      setValidationErrors(undefined);
    }
  }, [applicationData, hasMTC, markAsCompletedMap]);

  const data: SectionDataRecord[] = [
    {
      key: 'operatingClassDistribution',
      label: 'Operating class distribution',
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.classesAndCommoditiesForm
          ?.operatingClassDistribution;
      },
      renderValue: ({ value = [] }: SectionDataRenderParams) => {
        return (
          <TableView
            headers={[
              {
                key: 'columnOperatingClass',
                content: 'Operating class',
                width: '30%',
              },
              {
                key: 'columnPercentage',
                content: 'Percentage of operation',
              },
            ]}
            rows={value
              .filter((record: any) => !!record.percentageOfFleet)
              .map((record: any) => ({
                columns: [
                  {
                    content: ApplicationHelper.getOperatingClassLabel(
                      record.operatingClass,
                    ),
                    width: '30%',
                  },
                  { content: `${record.percentageOfFleet}%` },
                ],
              }))}
          />
        );
      },
    },
  ];

  if (hasMTC) {
    data.push(
      {
        key: 'commodityDistribution',
        label: 'Commodities hauled',
        getValue: (data: ApplicationDetail) => {
          return data.indicationForm?.classesAndCommoditiesForm
            ?.commodityDistribution;
        },
        renderValue: ({ value = {} }: SectionDataRenderParams) => {
          if (validationErrors?.commodityDistribution) {
            return <CommoditiesUnfilledSkeleton />;
          }

          const { commodities = [], additionalCommodities } = value;
          return (
            <>
              <TableView
                headers={[
                  {
                    key: 'commodityName',
                    content: 'Commodity',
                    width: '25%',
                  },
                  {
                    key: 'commodityCategory',
                    content: 'Category',
                    width: '25%',
                  },
                  {
                    key: 'avgDollarValueHauled',
                    content: 'Avg value',
                    width: '15%',
                  },
                  {
                    key: 'maxDollarValueHauled',
                    content: 'Max value',
                    width: '15%',
                  },
                  {
                    key: 'columnPercentage',
                    content: '% of hauls',
                    width: '20%',
                  },
                ]}
                rows={commodities?.map((record: WeightedCommodityRecord) => ({
                  columns: [
                    {
                      content: `${record.commodity.label}`,
                    },
                    {
                      content: `${record.category.label}`,
                    },
                    {
                      content: (
                        <NumberFormat
                          value={record.avgDollarValueHauled}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      ),
                    },
                    {
                      content: (
                        <NumberFormat
                          value={record.maxDollarValueHauled}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      ),
                    },
                    {
                      content: `${record.percentageOfHauls}%`,
                    },
                  ],
                }))}
              />
              {additionalCommodities?.commodities ? (
                <Box mt={3}>
                  <TableView
                    headers={[
                      {
                        key: 'commodities',
                        content: 'Other commodities',
                        width: '50%',
                      },
                      {
                        key: 'commodities-spacer',
                        content: '',
                        width: '30%',
                      },
                      {
                        key: '',
                        content: '',
                        width: '20%',
                      },
                    ]}
                    rows={[
                      {
                        columns: [
                          {
                            content: additionalCommodities?.commodities,
                            width: '50%',
                          },
                          {
                            content: '',
                            width: '30%',
                          },
                          {
                            content: `${additionalCommodities.percentageOfHauls}%`,
                            width: '20%',
                          },
                        ],
                      },
                    ]}
                  />
                </Box>
              ) : null}
            </>
          );
        },
      },
      {
        key: 'customerCommodity',
        label:
          'Select if any of these operations or commodities apply to the customer',

        getValue: (data: ApplicationDetail) => {
          return data.additionalInfoForm?.commodities;
        },
        renderValue: ({ value = [], hasChanged }: SectionDataRenderParams) => {
          if (!value.length) {
            return '-';
          }

          return hasChanged ? (
            <mark>
              {value.map((commodity: string) => (
                <p key={commodity}>
                  {ApplicationHelper.getAdditionInformationCommodityLabel(
                    commodity,
                  )}
                </p>
              ))}
            </mark>
          ) : (
            value.map((commodity: string) => (
              <p key={commodity}>
                {ApplicationHelper.getAdditionInformationCommodityLabel(
                  commodity,
                )}
              </p>
            ))
          );
        },
      },
    );
  }

  if (
    applicationData.indicationForm?.classesAndCommoditiesForm?.primaryCommodity
  ) {
    data.push({
      key: 'primaryCommodity',
      label: 'Primary commodity',
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.classesAndCommoditiesForm?.primaryCommodity
          ?.label;
      },
      renderValue: ({ value = '', hasChanged }) => {
        return hasChanged ? <mark>{value}</mark> : value;
      },
    });
  }

  if (
    applicationData.indicationForm?.classesAndCommoditiesForm
      ?.primaryCategory &&
    !hasMTC
  ) {
    data.push({
      key: 'primaryCategory',
      label: 'Primary commodity',
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.classesAndCommoditiesForm?.primaryCategory
          ?.label;
      },
      renderValue: ({ value = '', hasChanged }) => {
        return hasChanged ? <mark>{value}</mark> : value;
      },
    });
  }

  if (!hasMTC) {
    data.push({
      key: 'customerCommodity',
      label:
        'Select if any of these operations or commodities apply to the customer',

      getValue: (data: ApplicationDetail) => {
        return data.additionalInfoForm?.commodities;
      },
      renderValue: ({ value = [], hasChanged }: SectionDataRenderParams) => {
        if (!value.length) {
          return '-';
        }

        return hasChanged ? (
          <mark>
            {value.map((commodity: string) => (
              <p key={commodity}>
                {ApplicationHelper.getAdditionInformationCommodityLabel(
                  commodity,
                )}
              </p>
            ))}
          </mark>
        ) : (
          value.map((commodity: string) => (
            <p key={commodity}>
              {ApplicationHelper.getAdditionInformationCommodityLabel(
                commodity,
              )}
            </p>
          ))
        );
      },
    });
  }

  // Never true, check with @francisco
  if (!hasMTC && validationErrors?.primaryCategory) {
    data.push({
      key: 'primaryCategory',
      label: 'Primary commodity',
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.classesAndCommoditiesForm?.primaryCategory
          ?.label;
      },
      renderValue: () => {
        return (
          <div>
            <div className="p-2 border rounded w-60 border-error-main">
              <div className="h-4 p-2 bg-gray-100 rounded " />
            </div>
          </div>
        );
      },
    });
  }

  if (applicationData.additionalInfoForm?.commoditiesComment) {
    data.push({
      key: 'additional Comments',
      label: 'Additional information',
      getValue: (data: ApplicationDetail) => {
        return data.additionalInfoForm?.commoditiesComment;
      },
      renderValue: ({ value = '', hasChanged }) => {
        return hasChanged ? <mark>{value}</mark> : value;
      },
    });
  }
  if (isMarkAsCompleted) {
    return (
      <Grid classes={{ root: classes.markAsCompleted }}>
        <Typography classes={{ root: classes.completedHeader }}>
          {title}
        </Typography>
        <MarkAsCompletedFooter
          checked={isMarkAsCompleted}
          onChange={() => handleMarkAsCompleted?.(false)}
          isSectionLoading={isSectionLoading}
        />
      </Grid>
    );
  }

  return (
    <Section
      isEditable={!editMode && isEditable}
      applicationData={applicationData}
      title="Classes & Commodities "
      onEdit={() => setEditModeVal(true)}
      data={
        editMode
          ? [
              {
                key: 'classesAndCommoditiesForm',
                getValue: (data: ApplicationDetail) => {
                  return data;
                },
                renderValue: () => {
                  return (
                    <FormProvider {...methods}>
                      <ClassesAndCommoditiesFormView
                        applicationData={applicationData}
                      />
                    </FormProvider>
                  );
                },
              },
            ]
          : data
      }
      footer={
        editMode ? (
          <EditModeFooter
            onCancel={() => setEditModeVal(false)}
            onUpdate={handleSave}
          />
        ) : (
          <MarkAsCompletedFooter
            onChange={(checked) => handleMarkAsCompleted(checked)}
            isSectionLoading={isSectionLoading}
          />
        )
      }
    />
  );
};

export default ClassesAndCommoditiesView;
