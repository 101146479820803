import { useQuery } from '@tanstack/react-query';
import constate from 'constate';

import { fetchConstants } from '../queries/application';

function useConstants() {
  const query = useQuery({
    queryKey: ['constants'],
    queryFn: () => fetchConstants(),
    select: (data) => {
      return { ...data, categories: data.categories?.sort() };
    },
  });

  const getVehicleTypeLabel = (vehicleType: string) => {
    return query.data?.vehicleTypes.find(
      (record) => record.value === vehicleType,
    )?.label;
  };

  const getVehicleClassLabel = (vehicleType: string, vehicleClass: string) => {
    return query.data?.vehicleClassByType[vehicleType].find(
      (record) => record.value === vehicleClass,
    )?.label;
  };

  return { ...query, getVehicleClassLabel, getVehicleTypeLabel };
}

export const [AppConstantsProvider, useAppConstantsContext] =
  constate(useConstants);
