import { ITheme } from 'src/assets/themes';
import { Box, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme: ITheme) => ({
  buttonContainer: {
    width: theme.typography.pxToRem(142),
    height: theme.typography.pxToRem(79),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.typography.pxToRem(5),
    border: '1px solid #E6E7EF',
    boxShadow: '0px 2px 3px rgba(128, 145, 196, 0.08)',
    cursor: 'pointer',
    background: theme.palette.common.white,

    [theme.breakpoints.up('xl')]: {
      width: theme.typography.pxToRem(153),
      height: theme.typography.pxToRem(90),
    },
    [theme.breakpoints.up('xxl')]: {
      width: theme.typography.pxToRem(180),
      height: theme.typography.pxToRem(106),
    },

    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
      boxShadow: '0px 4px 6px rgba(0, 36, 138, 0.08)',
      backgroundColor: theme.palette.primary.extraLight,
    },
  },
  vendorIcon: {
    width: theme.spacing(11),

    [theme.breakpoints.up('xl')]: {
      width: theme.typography.pxToRem(105),
    },
    [theme.breakpoints.up('xxl')]: {
      width: theme.typography.pxToRem(120),
    },
  },
  otherText: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(0.3),

    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  selected: {
    border: `1px solid ${theme.palette.primary.light}`,
    boxShadow: '0px 4px 6px rgba(0, 36, 138, 0.08)',
    backgroundColor: theme.palette.primary.extraLight,
  },
  btnSubmit: {
    minWidth: theme.typography.pxToRem(172),
  },
  radioGroup: {
    flexDirection: 'row',
  },
  formControl: {
    width: 270,
  },
  formControlLabel: {
    marginRight: theme.spacing(2),
    marginLeft: 0,
  },
  radioLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
  dialogPaper: {
    width: theme.typography.pxToRem(500),
  },
}));

export const BackgroundGradient = styled(Box)(() => ({
  width: '100%',
  height: 315,
  background:
    'linear-gradient(172.99deg, rgba(244, 246, 255, 0) 2.16%, #FAFAFE 99.41%);',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
}));
