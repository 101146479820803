import { useImpler } from '@impler/react';
import { CircularProgress } from '@material-ui/core';
import { lightTheme } from '@nirvana/ui-kit';
import { useRef } from 'react';
import Button from 'src/components/button';

const getSchema = ({ useFullNames }: { useFullNames: boolean }) => [
  ...(useFullNames
    ? [
        {
          key: 'fullName',
          name: 'Full Name',
          alternateKeys: ['Name', 'Driver', 'Drivers Name', 'Full Name'],
          isRequired: true,
          isUnique: false,
          defaultValue: '',
          type: 'String',
        },
      ]
    : [
        {
          key: 'firstName',
          name: 'First Name',
          alternateKeys: ['First Name'],
          isRequired: true,
          isUnique: false,
          defaultValue: '',
          type: 'String',
        },
        {
          key: 'lastName',
          name: 'Last Name',
          alternateKeys: ['Name', 'Driver', 'Drivers Name'],
          isRequired: true,
          isUnique: false,
          defaultValue: '',
          type: 'String',
        },
      ]),
  {
    key: 'dlNumber',
    name: 'DL Number',
    alternateKeys: [
      'driver',
      'license',
      'License Number',
      'Driver License',
      'DL License',
      'CDL Number',
      'CDL License',
    ],
    isRequired: true,
    isUnique: true,
    defaultValue: '',
    type: 'String',
  },
  {
    key: 'usState',
    name: 'State',
    alternateKeys: [
      'state',
      'DL State',
      'CDL State',
      'License State',
      'State License',
      'Licenced Stated',
      'st',
      'St.',
    ],
    isRequired: true,
    isUnique: false,
    defaultValue: '',
  },
  {
    key: 'dateHired',
    name: 'Date Hired',
    alternateKeys: ['Hired Date', 'Hired', 'Hire Date', 'Date of Hire'],
    isRequired: true,
    isUnique: false,
    defaultValue: '',
  },
  {
    key: 'dateOfBirth',
    name: 'Date of Birth',
    alternateKeys: ['DOB', 'Birth Date', 'Birthdate', 'Date of Birth'],
    isRequired: true,
    isUnique: false,
    defaultValue: '',
  },
  {
    key: 'yearsOfExperience',
    name: 'Years of Experience',
    alternateKeys: ['Experience', 'Years', 'Experience Years', 'Year Licensed'],
    isRequired: true,
    isUnique: false,
    defaultValue: '',
  },
];

type FileUploadMetadata = {
  fileId: string;
  filename: string;
};

type ImplerProps = {
  usState?: string;
  useFullNames?: boolean;
  onChange: (
    result: Record<string, any>[],
    metadata?: FileUploadMetadata,
  ) => void;
  onMetaChange?: (metadata?: FileUploadMetadata) => void;
  isDisabled?: boolean;
};

const Impler = ({
  usState,
  useFullNames = false,
  onChange,
  onMetaChange,
  isDisabled,
}: ImplerProps) => {
  const fileMetadataRef = useRef<FileUploadMetadata | undefined>(undefined);

  const { closeWidget, showWidget, isImplerInitiated } = useImpler({
    templateId: import.meta.env.VITE_IMPLER_DRIVERS_TEMPLATE_ID,
    projectId: import.meta.env.VITE_IMPLER_PROJECT_ID,
    accessToken: import.meta.env.VITE_IMPLER_ACCESS_TOKEN,
    primaryColor: lightTheme.palette.primary.main,
    extra: {
      applicationUSState: usState,
    },
    title: 'Drivers List',
    texts: {
      COMMON: {
        UPLOAD_AGAIN: 'Restart',
      },
      FILE_DROP_AREA: {
        DROP_FILE: 'Drag and drop your file here',
        FILE_SELECTED: 'File uploaded',
      },
      PHASE1: {
        SELECT_SHEET_NAME: 'Select a sheet',
        SELECT_SHEET_NAME_PLACEHOLDER: 'Select sheet',
        SEE_MAPPING: 'Proceed',
      },
      PHASE2: {
        REVIEW_DATA: 'Proceed',
        IN_SCHEMA_TITLE: 'Required fields',
        IN_SHEET_TITLE: 'Select a field from the sheet',
      },
      PHASE3: {
        RE_REVIEW_DATA: 'Proceed',
        COMPLETE: 'Complete',
        ALL_RECORDS_VALID_TITLE: 'Validation successful!',
        ALL_RECORDS_VALID_DETAILS:
          'Importing {total} rows. Are you sure you want to proceed?',
      },
    },
    onUploadComplete: (data) => {
      const metadata = {
        fileId: data._id,
        filename: data.originalFileName,
      };
      fileMetadataRef.current = metadata;

      if (onMetaChange) {
        onMetaChange(metadata);
      }
    },
    onDataImported: (data) => {
      onChange(data, fileMetadataRef.current);
      closeWidget();
    },
  });

  return (
    <div>
      <Button
        variant="contained"
        type="button"
        disabled={!isImplerInitiated || isDisabled}
        onClick={() =>
          showWidget({
            schema: getSchema({ useFullNames }) as any,
            colorScheme: 'light',
          })
        }
        startIcon={
          !isImplerInitiated && (
            <CircularProgress className="text-white" size={18} />
          )
        }
      >
        {isImplerInitiated ? 'Proceed' : 'Initializing...'}
      </Button>
    </div>
  );
};

export default Impler;
