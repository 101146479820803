import {
  Box,
  DialogActions,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@material-ui/core';
import { RejectedApplicationResponse } from '@nirvana/api/non-fleet';
import { Dialog, ITheme, Show, Switch } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { useNavigate } from 'react-router-dom';

import {
  createApplicationFleet,
  createApplicationNonFleet,
} from 'src/features/nonFleet/queries/application';

import {
  INSURED_DETAILS_APPLICATION_CREATE,
  UNDESIRABLE_OPERATIONS_MODAL_VIEW,
} from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import AppetiteErrorDialog from './appetiteErrorDialog';
import ConflictErrorDialog from './ConflictErrorDialog';
import NFAppetiteErrorDialog from './nfAppetiteErrorDialog';

const useStyles = makeStyles((theme: ITheme) => ({
  categoryListItemIcon: {
    minWidth: '20px !important',
  },
  wrapper: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: 4,
    width: '520px',
  },
  nfWrapper: {
    backgroundColor: '#FAF6F3',
    borderRadius: 4,
    width: '580px',
  },
  toggleButton: {
    marginRight: 8,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: theme.palette.primary.main,
    borderRadius: '4px !important',
    padding: theme.spacing(1, 3),

    '&.Mui-selected': {
      backgroundColor: `${theme.palette.primary.tint2} !important`,
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.tint2,
      color: theme.palette.primary.main,
    },

    '&:focus': {
      backgroundColor: theme.palette.primary.tint2,
      color: theme.palette.primary.main,
    },
  },
  toggleButtonSelected: {
    backgroundColor: theme.palette.primary.tint2,
    color: theme.palette.primary.main,
  },
}));

interface UndesiredOperationsProps {
  open: boolean;
  isNonFleet?: boolean;
  onClose: () => void;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValues = {
  dotNumber: '',
  companyName: '',
  effectiveDate: null,
  numberOfPowerUnits: '',
  hasUndesiredOperations: '',
};

const UndesiredOperations = ({
  open,
  isNonFleet,
  onClose,
  openDialog,
}: UndesiredOperationsProps) => {
  const classes = useStyles();
  const { capture } = useAnalytics();
  const navigate = useNavigate();
  const [hasAppetiteError, setHasAppetiteError] = useState(false);
  const [appetiteError, setAppetiteError] =
    useState<RejectedApplicationResponse>();
  const [applicationError, setApplicationError] = useState<string>();
  const { control, handleSubmit, reset } = useFormContext();
  const [conflictError, setConflictError] = useState<any>();

  useEffect(() => {
    if (open) {
      capture(UNDESIRABLE_OPERATIONS_MODAL_VIEW);
    }
  }, [open]);

  function handleClose() {
    onClose();
    reset(defaultValues);
  }

  const getUndesiredOperatingClasses = (position: 'left' | 'right') => {
    if (position === 'left') {
      if (isNonFleet) {
        return [
          'Auto-haulers',
          'Coal',
          'Double / Triple trailer operations',
          'Driveaway',
          'Dump',
          'Expedited / Residential delivery',
          'Hazmat',
        ];
      } else {
        // Fleet
        return [
          'Auto-haulers',
          'Driveaway',
          'Livestock',
          'Logging',
          'PPT vehicles',
          'Waste',
        ];
      }
    } else {
      if (isNonFleet) {
        return [
          'Hotshots',
          'Household goods movers',
          'Livestock',
          'Logging',
          'Mobile home toters',
          'Scrap metal/recycling services',
          'Waste',
        ];
      } else {
        // Fleet
        return [
          'Dump',
          'Expedited/residential delivery',
          'Hazardous Materials',
          'Hot Shot',
          'Team-driving',
        ];
      }
    }
  };

  const { mutate: postFleetApplication, isLoading: isFleetLoading } =
    useMutation(createApplicationFleet, {
      onSuccess: (data) => {
        handleClose();

        const params: string[] = [];
        if (data.flags) {
          Object.keys(data.flags).forEach((key) => {
            params.push(`${key}=${data.flags?.[key] ? 1 : 0}`);
          });
        }

        // Redirect to application creation screen
        navigate(
          `/applications/${data.applicationID}/create?${params.join('&')}`,
        );
      },
      onError: (ex: AxiosError) => {
        onClose();

        if (ex.response?.status === 409) {
          setConflictError(ex.response?.data);
          return;
        }

        setHasAppetiteError(true);
        setAppetiteError(ex?.response?.data);
      },
    });

  const { mutate: postNFApplication, isLoading: isNonFleetLoading } =
    useMutation(createApplicationNonFleet, {
      onSuccess: (data) => {
        handleClose();

        const params: string[] = [];
        if (data.flags) {
          Object.keys(data.flags).forEach((key) => {
            if (data.flags) {
              params.push(`${key}=${data.flags[key] ? 1 : 0}`);
            }
          });
        }

        navigate(
          `/non-fleet/application/${data.applicationID}/create?${params.join(
            '&',
          )}`,
        );
      },
      onError: (error: any) => {
        onClose();

        if (error.response?.status === 409) {
          setConflictError(error.response?.data);
          return;
        }

        setApplicationError(
          error.response?.data?.declinedRules?.[0]?.details?.Reason,
        );
        setHasAppetiteError(true);
      },
    });

  function onSubmit(values: any) {
    capture(INSURED_DETAILS_APPLICATION_CREATE);
    if (!isNonFleet) {
      postFleetApplication({
        companyName: values.companyName,
        dotNumber: +values.dotNumber,
        effectiveDate: formatISO(values.effectiveDate, {
          representation: 'date',
        }),
        hasUndesiredOperations: values.hasUndesiredOperations === 'yes',
        producerId: values.producerId,
        numPowerUnits: +values.numberOfPowerUnits,
      });
    } else {
      postNFApplication({
        isAdmitted: true,
        companyName: values.companyName,
        dotNumber: +values.dotNumber,
        numberOfPowerUnits: +values.numberOfPowerUnits,
        effectiveDate: formatISO(values.effectiveDate, {
          representation: 'date',
        }),
        hasUndesiredOperations: values.hasUndesiredOperations === 'yes',
        producerID: values.producerId,
        producerName: values.producerName,
      });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box p={3} className={isNonFleet ? classes.nfWrapper : classes.wrapper}>
          <Switch>
            <Switch.Match when={!isNonFleet}>
              <Typography variant="body1" color="textPrimary" mb={1}>
                Does the insured have more than 5% of their operations in one of
                the following classes?
              </Typography>
            </Switch.Match>

            <Switch.Match when={isNonFleet}>
              <Typography variant="body1" color="textPrimary" mb={1}>
                Does the insured have <strong>any</strong> operations in one of
                the following classes?
              </Typography>
            </Switch.Match>
          </Switch>

          <Grid container spacing={1}>
            <Grid item flexGrow={1}>
              <List component="ul" aria-label="undesired categories">
                {getUndesiredOperatingClasses('left').map(
                  (item: string, index: number) => (
                    <ListItem dense disableGutters key={`item${index}`}>
                      <ListItemIcon
                        classes={{ root: classes.categoryListItemIcon }}
                      >
                        &bull;
                      </ListItemIcon>
                      <ListItemText
                        primary={item}
                        primaryTypographyProps={{
                          variant: 'caption',
                          fontWeight: '400',
                        }}
                      />
                    </ListItem>
                  ),
                )}
              </List>
            </Grid>
            <Grid item flexGrow={1}>
              <List component="ul" aria-label="undesired categories">
                {getUndesiredOperatingClasses('right').map(
                  (item: string, index: number) => (
                    <ListItem dense disableGutters key={`item${index}`}>
                      <ListItemIcon
                        classes={{ root: classes.categoryListItemIcon }}
                      >
                        &bull;
                      </ListItemIcon>
                      <ListItemText
                        primary={item}
                        primaryTypographyProps={{
                          variant: 'caption',
                          fontWeight: '400',
                        }}
                      />
                    </ListItem>
                  ),
                )}
              </List>
            </Grid>
          </Grid>

          <Grid item>
            <Box textAlign="right">
              <Controller
                defaultValue=""
                control={control}
                name="hasUndesiredOperations"
                render={({ field }) => (
                  <ToggleButtonGroup
                    exclusive
                    size="small"
                    value={field.value}
                    onChange={(_, value: 'yes' | 'no') => {
                      field.onChange(value);
                      handleSubmit(onSubmit)();
                    }}
                  >
                    <ToggleButton
                      value="yes"
                      disabled={isFleetLoading || isNonFleetLoading}
                      classes={{
                        root: classes.toggleButton,
                        selected: classes.toggleButtonSelected,
                      }}
                      data-attr="posthog-undesired-operations-yes"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      disabled={isFleetLoading || isNonFleetLoading}
                      value="no"
                      classes={{
                        root: classes.toggleButton,
                        selected: classes.toggleButtonSelected,
                      }}
                      data-attr="posthog-undesired-operations-no"
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            </Box>
          </Grid>
        </Box>
        <DialogActions />
      </Dialog>
      <Show
        when={isNonFleet}
        fallback={
          <AppetiteErrorDialog
            open={hasAppetiteError}
            error={appetiteError}
            onClose={() => setHasAppetiteError(false)}
          />
        }
      >
        <NFAppetiteErrorDialog
          open={hasAppetiteError}
          errorMessage={applicationError}
          onClose={() => setHasAppetiteError(false)}
        />
      </Show>

      <Show when={conflictError}>
        <ConflictErrorDialog
          open={!!conflictError}
          data={conflictError}
          onClose={() => setConflictError(null)}
          isNonFleet={isNonFleet}
          openDialog={openDialog}
        />
      </Show>
    </>
  );
};

export default UndesiredOperations;
