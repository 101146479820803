import { FlatfileListener } from '@flatfile/listener';
import api, { Flatfile } from '@flatfile/api';
import { recordHook } from '@flatfile/plugin-record-hook';
import { formatISO } from 'date-fns';

import {
  date as DateHelper,
  usAndMexicanStatesHelper,
  usStatesHelper,
} from 'src/helpers';

import stateLicenseRegex from './../stateLicenseRegex.json';

const getListener = ({ onComplete }: { onComplete: (data: any) => void }) => {
  return FlatfileListener.create((listener) => {
    listener.on('**', (event) => {
      console.log(`Received event: ${event.topic}`);
    });

    listener.use(
      recordHook('drivers', async (record, event) => {
        const { data: space } = await api.spaces.get(event?.context.spaceId);
        const { applicationState } = space.metadata;

        // Cleanup First Name
        const firstName = record.get('firstName');
        record.set(
          'firstName',
          typeof firstName === 'string' ? firstName.trim() : firstName,
        );

        // Cleanup Last Name
        const lastName = record.get('lastName');
        record.set(
          'lastName',
          typeof lastName === 'string' ? lastName.trim() : lastName,
        );

        // Cleanup US State
        let usState = record.get('usState');
        record.set(
          'usState',
          typeof usState === 'string' ? usState.trim().toUpperCase() : usState,
        );

        // Cleanup DL Number
        let dlNumber = record.get('dlNumber') as string;
        record.set(
          'dlNumber',
          typeof dlNumber === 'string'
            ? dlNumber.replace(/[^A-Za-z0-9*]/g, '').trim()
            : dlNumber,
        );

        // Cleanup years of experience - truncate to 1 decimal place
        let yearsOfExperience = record.get('yearsOfExperience') as string;
        record.set(
          'yearsOfExperience',
          typeof yearsOfExperience === 'string'
            ? parseFloat(parseFloat(yearsOfExperience).toFixed(1))
            : yearsOfExperience,
        );

        // Validate driver licensed state
        usState = record.get('usState') as string;
        const usStateRegex = new RegExp(
          applicationState === 'TX'
            ? usAndMexicanStatesHelper.usAndMexicanStatesRegex()
            : usStatesHelper.usStateRegex(),
        );

        if (!usStateRegex.test(usState)) {
          record.addError(
            'usState',
            applicationState === 'TX'
              ? 'License state is required. Please use two alphabet codes ("MN", "IN", "WI", etc) for US' +
                  ' states and "MX" for Mexican states.'
              : 'License state is required. Please use two alphabet codes ("MN", "IN", "WI", etc)',
          );
        }

        const dateRegex =
          /^\s*(\d{4}|(0?[1-9]|1[012]))[-\\/]((0?[1-9]|1[012])|(0?[1-9]|[12][0-9]|3[01]))[-\\/](\d{2}|\d{4}|(0?[1-9]|[12][0-9]|3[01]))\s*$/;

        // Validate date of hiring
        const dateHired = record.get('dateHired') as string;
        if (!dateRegex.test(dateHired)) {
          record.addError('dateHired', 'Not a valid date');
        }

        // Validate date of birth
        const dateOfBirth = record.get('dateOfBirth') as string;
        if (!dateRegex.test(dateOfBirth)) {
          record.addError('dateOfBirth', 'Not a valid date');
        }

        // Validate years of experience
        yearsOfExperience = record.get('yearsOfExperience') as string;
        const yearsOfExperienceRegex = /^(?:100(?:\.0)?|\d{1,2}(?:\.\d)?)$/;
        if (!yearsOfExperienceRegex.test(yearsOfExperience)) {
          record.addError(
            'yearsOfExperience',
            'Years of experience must be a number between 0 and 100 with a max of one decimal place',
          );
        }

        // Validate driver license number based on US state
        dlNumber = record.get('dlNumber') as string;
        const licenseRegex = stateLicenseRegex[usState as string];
        if (licenseRegex && !dlNumber.match(new RegExp(licenseRegex.rule))) {
          record.addError(
            'dlNumber',
            'Driver license number is invalid. Please enter a valid driver license number.',
          );
        }

        // Populate firstName & lastName from fullName
        // const fullName = record.get('fullName') as string;
        // if (fullName) {
        //   const names = fullName.split(' ');
        //   record.set('firstName', names[0]);
        //   record.set('lastName', names.slice(1).join(' '));
        // }

        return record;
      }),
    );

    listener.filter({ job: 'workbook:submitActionFg' }, (configure) => {
      configure.on('job:ready', async ({ context }) => {
        const { jobId, workbookId } = context;

        try {
          const { data: sheets } = await api.sheets.list({ workbookId });

          const records: { data: Flatfile.RecordsWithLinks } = { data: [] };
          const completeResponse: any = {
            data: [],
            meta: {
              workbookId,
            },
          };
          for (const [, element] of sheets.entries()) {
            records.data = (
              await api.records.get(element.id)
            )?.data.records?.filter((record) => !!record.valid);

            completeResponse.data = records.data.map((record) => {
              const { values } = record;
              return Object.keys(values).reduce((acc, key) => {
                if (key === 'dateOfBirth') {
                  acc[key] = values[key].value
                    ? formatISO(
                        // Use a reference date for DOB, to avoid issues with 2-digit years
                        DateHelper.parseDate(
                          values[key].value as string,
                          new Date(2003),
                        ),
                        {
                          representation: 'date',
                        },
                      )
                    : null;
                } else if (key === 'dateHired') {
                  acc[key] = values[key].value
                    ? formatISO(
                        DateHelper.parseDate(values[key].value as string),
                        {
                          representation: 'date',
                        },
                      )
                    : null;
                } else if (key === 'dlNumber') {
                  acc[key] = (values[key].value as string).replace(
                    /[^A-Za-z0-9]/g,
                    '',
                  );
                } else {
                  acc[key] = values[key].value;
                }

                return acc;
              }, {});
            });
          }

          await api.jobs.ack(jobId, {
            info: 'Verifying data...',
            progress: 30,
          });

          await api.jobs.ack(jobId, {
            info: 'Transforming...',
            progress: 70,
          });

          // NOTE: Make changes after cells in a Sheet have been updated

          await api.jobs.complete(jobId, {
            outcome: {
              acknowledge: true,
              message: 'Uploaded successfully.',
              next: {
                type: 'wait',
              },
            },
          });

          onComplete(completeResponse);
        } catch (error: any) {
          console.error('Error:', error.stack);

          await api.jobs.fail(jobId, {
            outcome: {
              message: 'This job encountered an error.',
            },
          });
        }
      });
    });
  });
};

export default getListener;
