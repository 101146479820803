import { AppetiteForm, ProgramType } from '@nirvana/api/quoting';
import {
  ApplicationCreationForm,
  ApplicationUpdateForm,
} from '@nirvana/api/non-fleet';
import apiServiceNF from 'src/helpers/apiService';
import apiService from 'src/helpers/fleetApiService';

export const prefillApplication = async ({
  dotNumber,
}: {
  dotNumber: number;
}) => {
  const { data } = await apiService.applicationPrefillDotNumberDotNumberGet(
    dotNumber,
  );
  return data;
};

export const fetchAvailableProducers = async () => {
  const { data } = await apiService.availableProducersGet();
  return data;
};

export const createApplicationFleet = async (payload: AppetiteForm) => {
  const { data } = await apiService.applicationPost(payload);
  return data;
};

export const createApplicationNonFleet = async (
  payload: ApplicationCreationForm,
) => {
  const { data } = await apiServiceNF.postApplication(payload);
  return data;
};

export const fetchApplicationDetails = async (applicationId: string) => {
  const { data } = await apiServiceNF.getApplication(applicationId);
  return data;
};

export const updateApplicationDetails = async ({
  applicationId,
  payload,
}: {
  applicationId: string;
  payload: ApplicationUpdateForm;
}) => {
  const { data } = await apiServiceNF.patchApplication(applicationId, payload);
  return data;
};

export const submitQuote = async (applicationId: string) => {
  const { data } = await apiServiceNF.postQuoteSubmit(applicationId);
  return data;
};

export const fetchIndication = async (applicationId: string) => {
  const { data } = await apiServiceNF.getAdmittedAppIndication(applicationId);
  return data;
};

export const fetchClassesAndCommodities = async () => {
  const { data } = await apiServiceNF.getClassesAndCommoditiesList();
  return data;
};

export const fetchVehicleInfo = async ({ vins }: { vins: string }) => {
  const { data } = await apiServiceNF.getVehicleInfo(vins);
  return data;
};

export const submitApplication = async (applicationId: string) => {
  const { data } = await apiServiceNF.postApplicationSubmit(applicationId);
  return data;
};

export const fetchConstants = async () => {
  const { data } = await apiServiceNF.getAdmittedConstants();
  return data;
};

export const createAdmittedConsentLink = async ({
  applicationId,
  insuredName,
  insuredEmail,
}: {
  applicationId: string;
  insuredName: string;
  insuredEmail: string;
}) => {
  const { data } = await apiService.postTelematicsApplicationConsentLink({
    applicationId: applicationId,
    programType: ProgramType.ProgramTypeNonFleetAdmitted,
    insuredInformation: {
      name: insuredName,
      email: insuredEmail,
    },
  });
  return data.link;
};

export const fetchApplicationStatus = async (applicationId: string) => {
  const { data } =
    await apiServiceNF.nonfleetApplicationApplicationIDStatusTrackerGet(
      applicationId,
    );
  return data;
};

export const decodeZipInfo = async (zipCode: string) => {
  const { data } = await apiService.zipcodeZipCodeDecodeGet(zipCode);

  return data;
};

export const decodeVehicleVINs = async (vinArray: string[]) => {
  const { data } = await apiServiceNF.decodeVins(vinArray);
  return data;
};
