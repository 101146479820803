import {
  Box,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  FileDestinationGroup,
  FileHandle,
  FileType,
} from '@nirvana/api/quoting';
import { Dialog, ITheme, Switch } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';

import Button, { FlatFileUpload } from 'src/components/button';
import { uploadFileMetadata } from 'src/features/application/queries/impler';
import { utils as UtilsHelper } from 'src/helpers';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import { useDispatch } from 'src/redux';

import { uploadFlatFile } from '../../../actions';
import EquipmentListUploadFlatfile7 from './flatfile-7';
import Impler from './impler';

export const useStyles = makeStyles((theme: ITheme) => ({
  dialogPaper: {
    width: theme.typography.pxToRem(600),
  },
  dialogContent: {
    paddingBottom: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    borderBottom: '1px solid #E6E7EF',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
}));

type EquipmentListDialogProps = {
  onChange: (list?: any) => void;
  onClose: () => void;
  open: boolean;
  value: any;
  isApdCoverage?: boolean;
  onUploadStatusChange: (status: 'start' | 'progress' | 'complete') => void;
};

const EquipmentListDialog = ({
  onChange,
  onClose,
  open,
  value,
  isApdCoverage,
  onUploadStatusChange,
}: EquipmentListDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getFeatureValue = useFeatureFlag();
  const isFlatFileV4Enabled = getFeatureValue(Feature.FLATFILE_v4, false);
  const isImplerEnabled = getFeatureValue(Feature.IMPLER, false);

  const { mutate } = useMutation(uploadFileMetadata);

  const handleStart = () => {
    onUploadStatusChange('start');
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      title=""
      open={open}
      maxWidth="md"
      onClose={handleClose}
      primaryAction={
        <Box onClick={handleStart}>
          <Switch
            fallback={
              <FlatFileUpload
                key={isApdCoverage ? 'flatfile-with-apd' : 'flatfile-with-al'}
                variant="contained"
                type="Equipment"
                title="Upload Equipment List"
                fields={[
                  {
                    label: 'VIN',
                    alternates: [
                      'Chassis',
                      'VIN#',
                      'VIN Number',
                      '17 Digit VIN',
                      '17 Digit VIN#',
                      'Trailer#',
                      'Serial',
                      'Serial#',
                      'Full VIN#',
                    ],
                    key: 'vin',
                    description: 'Uniquely identifies your vehicle',
                    validators: [
                      {
                        validate: 'required',
                        error:
                          'VIN is required for power units. For trailers without VINs, use "Trailer#" (example "Trailer1", "Trailer2", etc...)',
                      },
                      {
                        validate: 'regex_matches',
                        error:
                          'A valid VIN is required for power units. For trailers without VINs, use "Trailer#" (no spaces, example "Trailer1", "Trailer2", etc...)',
                        regex:
                          '^(?!(T|t)otal|TOTAL|(V|v)in|VIN|(V|v)alue|VALUE)([a-zA-Z0-9])[a-zA-Z0-9]*([a-zA-Z0-9])$',
                      },
                      {
                        validate: 'unique',
                        error:
                          'Duplicate VINs are not allowed. For trailers, use "Trailer#" (example "Trailer1", "Trailer2", etc...)',
                      },
                    ],
                  },
                  {
                    label: 'Stated Value',
                    alternates: [
                      'Amount',
                      'Cost',
                      'Renewal Value',
                      'Stated Value',
                      'Stated Amount',
                      'Stated Amt',
                      'Value',
                      'Renewal',
                      'Renewal Physical Damage',
                      'Physical Damage Value',
                      'Physical Damage Amount',
                    ],
                    key: 'statedValue',
                    description: 'Stated value for your vehicle',
                    validators: [
                      {
                        validate: 'required',
                        error: isApdCoverage
                          ? 'Stated value is required for Physical Damage. For no Physical Damage, use "0".'
                          : 'Stated value is required for Auto Liability. For trailers without stated value, use "0"',
                      },
                      {
                        validate: 'regex_matches',
                        error: isApdCoverage
                          ? 'Stated value should be a valid amount.'
                          : 'Stated value should be a valid amount.',
                        regex: '^(\\d*\\.?\\d+)(,\\d*\\.?\\d*)*$',
                      },
                      {
                        validate: 'regex_matches',
                        error: isApdCoverage
                          ? 'Valid stated value is required for Physical Damage. For no Physical Damage, use "0".'
                          : 'Valid stated value is required for Auto Liability. For trailers without stated value, use "0"',
                        regex:
                          '^(?!(V|v)alue|VALUE|(R|r)enewal|RENEWAL|(S|s)tated|STATED|(C|c)ost|COST|(A|a)mount)|AMOUNT',
                      },
                    ],
                  },
                ]}
                fieldHooks={{
                  vin: (values) => {
                    return values.map(([item, index]) => {
                      const newItem =
                        typeof item === 'string'
                          ? item.replace(/[$,.\s]/g, '').toUpperCase()
                          : item;

                      if (!UtilsHelper.isValidVIN(`${newItem}`)) {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message: 'VIN validation failed',
                                level: 'error',
                              },
                            ],
                          },
                          index,
                        ];
                      } else if (newItem === item) {
                        return [item, index] as any;
                      } else {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message:
                                  'Removed invalid characters and whitespaces',
                                level: 'info',
                              },
                            ],
                          },
                          index,
                        ];
                      }
                    });
                  },
                  statedValue: (values) => {
                    return values.map(([item, index]) => {
                      const newItem =
                        typeof item === 'string'
                          ? ~~item.replace(/[$,]/g, '')
                          : item;
                      const totalStatedValue = values.reduce((sum, [value]) => {
                        const tempItem =
                          typeof value === 'string'
                            ? ~~value.replace(/[$,]/g, '')
                            : value;

                        return (
                          sum + (typeof tempItem === 'number' ? tempItem : 0)
                        );
                      }, 0);
                      if (isApdCoverage && totalStatedValue < 1) {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message:
                                  'Total value of equipment must be > $0 when APD Coverage is selected',
                                level: 'error',
                              },
                            ],
                          },
                          index,
                        ];
                      } else if (newItem === item) {
                        return [item, index] as any;
                      } else if (
                        newItem &&
                        !/^(\d*\.?\d+)(,\d*\.?\d*)*$/.test(newItem.toString())
                      ) {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message:
                                  'Stated value should be a valid number.',
                                level: 'error',
                              },
                            ],
                          },
                        ];
                      } else {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message:
                                  'Removed invalid characters and decimals',
                                level: 'info',
                              },
                            ],
                          },
                          index,
                        ];
                      }
                    });
                  },
                }}
                onRecordChange={(record) => {
                  return {
                    vin: {
                      value:
                        typeof record.vin === 'string'
                          ? record.vin.toUpperCase()
                          : record.vin,
                      info: [
                        {
                          message: 'Converted to uppercase',
                          level: 'info',
                        },
                      ],
                    },
                  };
                }}
                value={value}
                onChange={(results) => {
                  if (results) {
                    onUploadStatusChange('progress');
                    const equipmentList: any = {
                      info: results.validData.map(
                        ({ vin, statedValue }: any) => ({
                          vin: vin,
                          statedValue:
                            typeof statedValue === 'number'
                              ? statedValue
                              : ~~statedValue.replace(/[$,]/g, '') ?? 0,
                        }),
                      ),
                      flatfileMetadata: {
                        flatfileHandle: results.$meta.batchID,
                        fileMetadata: {
                          name: results.$meta.filename,
                        },
                      },
                    };
                    const uploadPromise: any = equipmentList.flatfileMetadata
                      ?.fileMetadata?.name
                      ? dispatch(
                          uploadFlatFile({
                            flatfileMetadata: equipmentList.flatfileMetadata,
                            fileType: FileType.FileTypeEquipmentList,
                            fileDestinationGroup:
                              FileDestinationGroup.FileDestinationGroupQuoting,
                          }),
                        )
                      : Promise.reject(new Error('skip_file_upload'));
                    uploadPromise
                      .then((response: any) => {
                        equipmentList.flatfileMetadata.fileMetadata.handle =
                          response.payload.handle;

                        onChange(equipmentList);

                        onUploadStatusChange('complete');
                      })
                      .catch(() => {
                        if (
                          !equipmentList.flatfileMetadata?.fileMetadata?.name
                        ) {
                          equipmentList.flatfileMetadata.fileMetadata.name =
                            'EquipmentList.csv';
                        }
                        // Save the data anyways
                        onChange(equipmentList);

                        onUploadStatusChange('complete');
                      });
                  } else {
                    onChange(undefined);
                  }
                }}
                render={(_, launch) => {
                  return (
                    <Button
                      data-attr="operations-form-equipment-list-start-upload"
                      variant="contained"
                      onClick={launch}
                    >
                      Proceed
                    </Button>
                  );
                }}
              />
            }
          >
            <Switch.Match when={isImplerEnabled}>
              <Impler
                key="impler-equipment-list"
                isApdCoverage={isApdCoverage}
                onChange={(data, metadata) => {
                  onUploadStatusChange('progress');

                  const processEquipmentList = (response?: FileHandle) => {
                    const equipmentList: any = {
                      info: data.map(({ vin, statedValue }) => ({
                        vin: vin,
                        statedValue:
                          typeof statedValue === 'number'
                            ? statedValue
                            : ~~statedValue.replace(/[$,]/g, '') ?? 0,
                      })),
                      implerMetadata: {
                        fileMetadata: {
                          name: 'EquipmentList.csv',
                        },
                      },
                    };

                    if (response) {
                      equipmentList.implerMetadata = {
                        implerHandle: metadata?.fileId,
                        fileMetadata: {
                          name: metadata?.filename || 'EquipmentList.csv',
                          handle: response.handle,
                        },
                      };
                    }

                    onChange(equipmentList);

                    onUploadStatusChange('complete');
                  };

                  // Upload file metadata to backend
                  if (metadata?.fileId && metadata?.filename) {
                    mutate(
                      {
                        fileType: FileType.FileTypeEquipmentList,
                        fileDestinationGroup:
                          FileDestinationGroup.FileDestinationGroupQuoting,
                        implerMetadata: {
                          implerHandle: metadata.fileId,
                          fileMetadata: {
                            name: metadata?.filename,
                          },
                        },
                      },
                      {
                        onSuccess: (response) => {
                          processEquipmentList(response);
                        },
                      },
                    );
                  } else {
                    // Unable to get Impler file handle
                    processEquipmentList();
                  }
                }}
              />
            </Switch.Match>
            <Switch.Match when={isFlatFileV4Enabled}>
              <EquipmentListUploadFlatfile7
                onChange={(result) => {
                  const payload = {
                    info: result.data,
                    flatfileMetadata: {
                      flatfileHandle: result.meta.workbookId,
                      fileMetadata: {
                        name: result.meta.filename,
                      },
                    },
                  };

                  if (!payload.flatfileMetadata?.fileMetadata?.name) {
                    payload.flatfileMetadata.fileMetadata.name =
                      'EquipmentList.csv';
                  }
                  // Save the data
                  onChange(payload);

                  onUploadStatusChange('complete');
                  onClose();
                }}
              />
            </Switch.Match>
          </Switch>
        </Box>
      }
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Box className={classes.dialogContent}>
        <IconButton
          className={classes.dialogCloseButton}
          onClick={handleClose}
          edge="end"
          size="small"
        >
          <Close />
        </IconButton>
        <Grid container direction="column" spacing={3}>
          <Grid item container alignItems="center" flexWrap="nowrap">
            <Typography variant="h5" color="textPrimary">
              Upload equipment list
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <Typography variant="body2" mb={2} component="p">
                  Please upload the equipment list in <strong>CSV</strong> or{' '}
                  <strong>Excel</strong> format.
                </Typography>

                <Typography variant="body2" gutterBottom>
                  <strong>
                    <u>NOTE:</u>{' '}
                  </strong>
                  To ensure the upload works properly, please make sure that:
                  <ol>
                    <li>
                      - Tractors and trailers are on the same tab or sheet in
                      the file.
                    </li>
                    <li>
                      - There are NO merged cells in your file. For instructions
                      on how to remove merged cells, please{' '}
                      <Link
                        href="https://intercom.help/nirvana-insurance/en/articles/8685886-how-to-unmerge-all-merged-cells"
                        target="_blank"
                      >
                        check here
                      </Link>
                      .
                    </li>
                  </ol>
                </Typography>
                <Typography variant="body2">
                  If you prefer, you can download and use our{' '}
                  <Link
                    href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/Nirvana_upload_template.xlsx"
                    target="_blank"
                  >
                    <strong>template here</strong>
                  </Link>{' '}
                  to make sure the upload works.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default EquipmentListDialog;
