import { MaxRadiusOfOperation } from '@nirvana/api/non-fleet';

export const RadiusOfOperationOptions = [
  {
    label: '0-50 miles',
    value: MaxRadiusOfOperation.MaxRadiusOfOperation50,
  },
  {
    label: '51-100 miles',
    value: MaxRadiusOfOperation.MaxRadiusOfOperation100,
  },
  {
    label: '101-200 miles',
    value: MaxRadiusOfOperation.MaxRadiusOfOperation200,
  },
  {
    label: '201-300 miles',
    value: MaxRadiusOfOperation.MaxRadiusOfOperation300,
  },
  {
    label: '301-500 miles',
    value: MaxRadiusOfOperation.MaxRadiusOfOperation500,
  },
  {
    label: '501+ miles',
    value: MaxRadiusOfOperation.MaxRadiusOfOperation999plus,
  },
];

export const getRadiusOfOperationLabel = (value: MaxRadiusOfOperation) => {
  const option = RadiusOfOperationOptions.find(
    (option) => option.value === value,
  );
  return option ? option.label : '';
};
