import { makeStyles } from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    border: '1px solid #EAEDFC',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 9),
  },
}));
