import { Grid, Typography } from '@material-ui/core';
import { HorizontalStepper } from '@nirvana/ui-kit';
import { useParams } from 'react-router-dom';

import { getSteps } from './constants/steps';

interface IPageHeader {
  title: string;
  subtitle: string;
  activeStepIndex: number;
}

const PageHeader = ({ title, subtitle, activeStepIndex }: IPageHeader) => {
  const { applicationId = '' } = useParams();
  const Steps = getSteps(applicationId, {});

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h4" fontWeight="fontWeightBold" mb={1}>
          {title}
        </Typography>
        {!!subtitle && (
          <Typography
            variant="body2"
            fontSize={15}
            style={{ width: 420 }}
            mb={2}
          >
            {subtitle}
          </Typography>
        )}
      </Grid>

      <Grid item xs={6}>
        <HorizontalStepper
          steps={Steps.map((record) => ({
            key: record.key,
            label: record.title,
          }))}
          activeStep={activeStepIndex}
          onChange={() => null}
        />
      </Grid>
    </Grid>
  );
};

export default PageHeader;
