export const AUTH_TOKEN_STORAGE_KEY = 'nirvana__auth_token';
export const SHARE_TOKEN_STORAGE_KEY = 'nirvana__share_token';
export const TSP_OAUTH_STATE = 'nirvana__tsp_oauth_state';
export const CLAIMS_CONTACT_PHONE = '800-315-6090';
export const NIRVANA_CLAIMS_CONTACT_PHONE = '888-383-8614';
export const CLAIMS_CONTACT_EMAIL = 'reportaclaim@narisk.com';
export const NIRVANA_CLAIMS_CONTACT_EMAIL = 'reportaclaim@nirvanatech.com';
export const TELEMATICS_RESPONSES_STORAGE_KEY =
  'nirvana__telematics_responses_by_state';
export const INSURED_TYPE_STORAGE_KEY = 'nirvana__insured_type';
export const ONBOARDING_AGENCY_INFO_FLAG = 'nirvana__agency_info_flag';
