import Section from 'src/features/application/components/create/review/section';
import { TableView } from 'src/components/table';
import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';

const DriversView = () => {
  const { applicationDetails } = useApplicationDetailsContext();
  const drivers = applicationDetails?.nrb?.driversForm?.drivers;

  if (!applicationDetails) {
    return null;
  }

  return (
    <Section
      onEdit={() => {}}
      title="Drivers"
      applicationData={{} as any}
      data={[
        {
          key: 'commoditiesHauled',
          renderValue: () => (
            <TableView
              headers={[
                { key: 'cdlNumber', content: 'CDL Number' },
                { key: 'driverFullName', content: "Driver's Full Name" },
                { key: 'licenseState', content: 'License State' },
                { key: 'yearIssued', content: 'Year  Issued' },
                { key: 'dateOfBirth', content: 'Date of Birth' },
                { key: 'ownerOperator', content: ' Owner Operator' },
              ]}
              rows={
                drivers?.map((item) => ({
                  columns: [
                    { key: 'cdlNumber', content: item.licenseNumber },
                    {
                      key: 'driverFullName',
                      content: `${item.firstName} ${item.lastName}`,
                    },
                    { key: 'licenseState', content: item.licenseState },
                    { key: 'yearIssued', content: item.licenseIssueYear },
                    { key: 'dateOfBirth', content: item.dateOfBirth },
                    {
                      key: 'ownerOperator',
                      content: item.ownerOperator ? 'Yes' : 'No',
                    },
                  ],
                })) ?? []
              }
            />
          ),
        },
      ]}
    />
  );
};

export default DriversView;
