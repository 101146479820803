import { constants } from '@nirvana/ui-kit';

const { usStates } = constants;

export const usStateRegex = (): string => {
  const states: string[] = usStates.map((record) => record.code);
  const lowerCase: string[] = states.map((s) => s.toLowerCase());
  const regexStart: string = '^(';
  const regexEnd: string = ')$';

  return regexStart + states.join('|') + lowerCase.join('|') + regexEnd;
};
