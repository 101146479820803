import { useReducer } from 'react';
import { SECTION_NAMES } from '../constant';

export const initialState = {
  [SECTION_NAMES.COVERAGES]: false,
  [SECTION_NAMES.OPERATIONS]: false,
  [SECTION_NAMES.LOSS_HISTORY]: false,
  [SECTION_NAMES.ADDITIONAL_INFO]: false,
  [SECTION_NAMES.CLASSES_AND_COMMODITIES]: false,
};

export const actionTypes = {
  SET_LOADING: 'SET_LOADING',
  REMOVE_LOADING: 'REMOVE_LOADING',
  RESET: 'RESET',
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        [action.payload]: true,
      };
    case actionTypes.REMOVE_LOADING:
      return {
        ...state,
        [action.payload]: false,
      };
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

const useSectionLoadingReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLoading = (section: any) => {
    dispatch({ type: actionTypes.SET_LOADING, payload: section });
  };

  const removeLoading = (section: any) => {
    dispatch({ type: actionTypes.REMOVE_LOADING, payload: section });
  };

  const reset = () => {
    dispatch({ type: actionTypes.RESET });
  };

  return { state, setLoading, removeLoading, reset };
};

export default useSectionLoadingReducer;
