import { Box, makeStyles, styled } from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  inputLabel: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(1),
    whiteSpace: 'normal',
  },
  checkboxLabel: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
  labelContainer: {
    paddingTop: theme.spacing(1),
  },
  labelContainerFirst: {
    paddingTop: '30px',
  },
  tableCell: {
    border: 0,
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(0.5, 0.75),
    width: '50%',
  },
  tableHeaderLabel: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
  },
  periodLabelContainer: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: '5px',
    padding: theme.spacing(1, 2),
  },
}));

export const TableContainer = styled(Box)(({ theme }: { theme: ITheme }) => ({
  padding: theme.spacing(1),
  paddingTop: theme.spacing(2),
  borderRadius: 5,
}));

export const TableHeading = styled(Box)(({ theme }: { theme: ITheme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  alignItems: 'center',
}));
