import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  Driver,
  DriverChangeChangeTypeEnum,
} from '@nirvana/api/endorsementapp';
import { Show, TableV8 } from '@nirvana/ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  fetchEndorsementRequestDrivers,
  updateDrivers,
} from 'src/features/policy/queries/endorsement';
import {
  getDriverFromDriverData,
  getDriverListData,
} from 'src/features/policy/utils';
import { DriverData, getDriversColumns } from '../../../constants/driver';
import DriversForm from './form';

const DriversUpdate = () => {
  const [adding, setAdding] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<Driver>();
  const queryClient = useQueryClient();

  const { policyId = '', endorsementId = '' } = useParams();

  const { data = [] } = useQuery(
    ['endorsement-request-drivers', policyId, endorsementId],
    () => fetchEndorsementRequestDrivers(policyId, endorsementId),
    {
      select: (data) => data.drivers,
    },
  );

  const { mutate } = useMutation(updateDrivers, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        'endorsement-request-drivers',
        policyId,
        endorsementId,
      ]);
      queryClient.invalidateQueries([
        'endorsement-details',
        policyId,
        endorsementId,
      ]);

      if (adding) {
        setAdding(false);
      }

      if (selectedDriver) {
        setSelectedDriver(undefined);
      }
    },
  });

  const driversList = useMemo(() => {
    return getDriverListData(data);
  }, [data]);

  const driversListNew = useMemo(() => {
    return driversList.filter(
      (driver) => driver.changeType === DriverChangeChangeTypeEnum.Added,
    );
  }, [driversList]);

  const driversListExisting = useMemo(() => {
    return driversList.filter(
      (driver) => driver.changeType !== DriverChangeChangeTypeEnum.Added,
    );
  }, [driversList]);

  const handleSubmit = (formData: Driver) => {
    let drivers = driversList.map(getDriverFromDriverData);

    if (selectedDriver) {
      drivers = drivers.map((record) => {
        if (record.licenseNumber === selectedDriver.licenseNumber) {
          return formData;
        }

        return record;
      });
    } else {
      drivers = [
        ...drivers,
        { ...formData, isOutOfState: false, isOwner: false },
      ];
    }

    mutate({
      policyId,
      endorsementId,
      payload: { effectiveDate: '2029-12-01T00:00:00Z', drivers },
    });
  };

  const handleAddCancel = () => {
    setAdding(false);
  };

  const handleEditCancel = () => {
    setSelectedDriver(undefined);
  };

  const handleRemove = (driver: DriverData) => {
    const drivers = driversList
      .map(getDriverFromDriverData)
      .filter((record) => {
        return record.licenseNumber !== driver.licenseNumber;
      });

    mutate({
      policyId,
      endorsementId,
      payload: { effectiveDate: '2029-12-01T00:00:00Z', drivers },
    });
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2 text-base font-bold">
        <p>Current Drivers</p>
        <div className="overflow-hidden bg-white border rounded-lg border-text-disabled">
          <TableV8
            columns={getDriversColumns({
              onEdit: (selected) => {
                setSelectedDriver(getDriverFromDriverData(selected));
              },
              onRemove: (selected) => {
                handleRemove(selected);
              },
              onUndo: (selected) => {
                handleSubmit(getDriverFromDriverData(selected));
              },
            })}
            data={driversListExisting}
          />
        </div>
      </div>

      <Show when={!!selectedDriver}>
        <DriversForm
          title="Edit driver information"
          onSubmit={handleSubmit}
          onCancel={handleEditCancel}
          driver={selectedDriver}
          onRemove={handleRemove}
        />
      </Show>

      <Show when={!!driversListNew.length}>
        <div className="space-y-2 text-base font-bold">
          <p>To be added</p>
          <div className="overflow-hidden bg-white border rounded-lg border-text-disabled">
            <TableV8
              columns={getDriversColumns({
                onEdit: (selected) => {
                  setSelectedDriver(getDriverFromDriverData(selected));
                },
                onRemove: (selected) => {
                  handleRemove(selected);
                },
                onUndo: (selected) => {
                  handleSubmit(getDriverFromDriverData(selected));
                },
              })}
              data={driversListNew}
            />
          </div>
        </div>
      </Show>

      <Show
        when={adding}
        fallback={
          <Button
            startIcon={<Add />}
            variant="outlined"
            size="small"
            onClick={() => setAdding(true)}
          >
            Add Driver
          </Button>
        }
      >
        <div className="space-y-2 text-base font-bold">
          <p>To be added</p>
          <DriversForm
            title="New Driver"
            onSubmit={handleSubmit}
            onCancel={handleAddCancel}
          />
        </div>
      </Show>
    </div>
  );
};

export default DriversUpdate;
