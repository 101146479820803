import { IndicationOptionTag } from '@nirvana/api/quoting';

const IndicationOptionTagMap = {
  IndicationOptionTagBasic: 'Basic',
  IndicationOptionTagStandard: 'Standard',
  IndicationOptionTagComplete: 'Complete',
};

const getPackageName = (packageType: IndicationOptionTag) => {
  return IndicationOptionTagMap[packageType];
};

export default getPackageName;
