import * as React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IntercomProvider } from 'react-use-intercom';
import { PostHogProvider } from 'posthog-js/react';

import { apolloClient } from 'src/contexts/apollo';
import Logrocket from 'src/components/logrocket';
import ThemeWrapper from './themeWrapper';
import store from './redux';
import {
  ANONYMOUS_USER_KEY,
  withFeatureFlagProvider,
} from './helpers/featureFlags';

// Define global Window to use segment all around the app
declare global {
  interface Window {
    analytics: any;
  }
}

const queryClient = new QueryClient();

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID || '';

const POSTHOG_API_KEY = (import.meta.env.VITE_PUBLIC_POSTHOG_KEY ??
  '') as string;
const POSTHOG_HOST = (import.meta.env.VITE_PUBLIC_POSTHOG_HOST ?? '') as string;

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <IntercomProvider
            appId={INTERCOM_APP_ID}
            autoBoot
            initializeDelay={500}
          >
            <PostHogProvider
              apiKey={POSTHOG_API_KEY}
              options={{ api_host: POSTHOG_HOST }}
            >
              <ThemeWrapper />
            </PostHogProvider>

            {import.meta.env.PROD && <Logrocket />}
          </IntercomProvider>
        </Provider>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export default withFeatureFlagProvider({
  clientSideID: import.meta.env.VITE_LAUNCHDARKLY_KEY,
  user: {
    key: ANONYMOUS_USER_KEY,
  },
})(App);
