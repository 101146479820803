/* tslint:disable */
/* eslint-disable */
/**
 * Nirvana Appetite Lite API
 * Nirvana Appetite Lite APIs
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface AppetiteLiteInfo
 */
export interface AppetiteLiteInfo {
  /**
   * The appetite lite score of the company.
   * @type {string}
   * @memberof AppetiteLiteInfo
   */
  score: AppetiteLiteInfoScoreEnum;
  /**
   *
   * @type {AppetiteLiteInfoRisk}
   * @memberof AppetiteLiteInfo
   */
  risk: AppetiteLiteInfoRisk;
}

/**
 * @export
 * @enum {string}
 */
export enum AppetiteLiteInfoScoreEnum {
  Preferred = 'Preferred',
  Acceptable = 'Acceptable',
  Marginal = 'Marginal',
  HighRisk = 'HighRisk',
  Unknown = 'Unknown',
  OutOfAppetite = 'OutOfAppetite',
}

/**
 *
 * @export
 * @interface AppetiteLiteInfoRisk
 */
export interface AppetiteLiteInfoRisk {
  /**
   *
   * @type {RiskFactor}
   * @memberof AppetiteLiteInfoRisk
   */
  high: RiskFactor;
  /**
   *
   * @type {RiskFactor}
   * @memberof AppetiteLiteInfoRisk
   */
  moderate: RiskFactor;
}
/**
 *
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof ErrorMessage
   */
  code: number;
}
/**
 *
 * @export
 * @interface RiskFactor
 */
export interface RiskFactor {
  /**
   * The count of high risk factors.
   * @type {number}
   * @memberof RiskFactor
   */
  count: number;
  /**
   * summary of high risk factors.
   * @type {string}
   * @memberof RiskFactor
   */
  summary: string;
}

/**
 * AppetiteLiteApi - axios parameter creator
 * @export
 */
export const AppetiteLiteApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Retrieve the appetite lite score and risk factors for a company based on the given DOT number.
     * @summary Get appetite lite details by DOT number
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppetiteLiteDetails: async (
      dotNumber: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dotNumber' is not null or undefined
      assertParamExists('getAppetiteLiteDetails', 'dotNumber', dotNumber);
      const localVarPath = `/appetite-lite/{dotNumber}`.replace(
        `{${'dotNumber'}}`,
        encodeURIComponent(String(dotNumber)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Trigger a call to get more detailed appetite lite information for a company based on the given DOT number.
     * @summary Request more appetite lite details by DOT number
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestAppetiteLiteDetails: async (
      dotNumber: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dotNumber' is not null or undefined
      assertParamExists('requestAppetiteLiteDetails', 'dotNumber', dotNumber);
      const localVarPath = `/appetite-lite/{dotNumber}/request-details`.replace(
        `{${'dotNumber'}}`,
        encodeURIComponent(String(dotNumber)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AppetiteLiteApi - functional programming interface
 * @export
 */
export const AppetiteLiteApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AppetiteLiteApiAxiosParamCreator(configuration);
  return {
    /**
     * Retrieve the appetite lite score and risk factors for a company based on the given DOT number.
     * @summary Get appetite lite details by DOT number
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppetiteLiteDetails(
      dotNumber: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppetiteLiteInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppetiteLiteDetails(
          dotNumber,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Trigger a call to get more detailed appetite lite information for a company based on the given DOT number.
     * @summary Request more appetite lite details by DOT number
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestAppetiteLiteDetails(
      dotNumber: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestAppetiteLiteDetails(
          dotNumber,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AppetiteLiteApi - factory interface
 * @export
 */
export const AppetiteLiteApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppetiteLiteApiFp(configuration);
  return {
    /**
     * Retrieve the appetite lite score and risk factors for a company based on the given DOT number.
     * @summary Get appetite lite details by DOT number
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppetiteLiteDetails(
      dotNumber: number,
      options?: any,
    ): AxiosPromise<AppetiteLiteInfo> {
      return localVarFp
        .getAppetiteLiteDetails(dotNumber, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Trigger a call to get more detailed appetite lite information for a company based on the given DOT number.
     * @summary Request more appetite lite details by DOT number
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestAppetiteLiteDetails(
      dotNumber: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .requestAppetiteLiteDetails(dotNumber, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AppetiteLiteApi - object-oriented interface
 * @export
 * @class AppetiteLiteApi
 * @extends {BaseAPI}
 */
export class AppetiteLiteApi extends BaseAPI {
  /**
   * Retrieve the appetite lite score and risk factors for a company based on the given DOT number.
   * @summary Get appetite lite details by DOT number
   * @param {number} dotNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppetiteLiteApi
   */
  public getAppetiteLiteDetails(
    dotNumber: number,
    options?: AxiosRequestConfig,
  ) {
    return AppetiteLiteApiFp(this.configuration)
      .getAppetiteLiteDetails(dotNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Trigger a call to get more detailed appetite lite information for a company based on the given DOT number.
   * @summary Request more appetite lite details by DOT number
   * @param {number} dotNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppetiteLiteApi
   */
  public requestAppetiteLiteDetails(
    dotNumber: number,
    options?: AxiosRequestConfig,
  ) {
    return AppetiteLiteApiFp(this.configuration)
      .requestAppetiteLiteDetails(dotNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
