import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form-v7';

import { BusinessStructureOptions } from '../constants/business';

const BusinessStructure = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={5}>
        <Box paddingTop="10px">
          <InputLabel htmlFor="business-structure">
            Business Structure
          </InputLabel>
        </Box>
      </Grid>
      <Grid item xs={7}>
        <FormControl fullWidth>
          <Controller
            control={control}
            defaultValue=""
            name="operationsForm.companyInfo.businessStructure"
            rules={{ required: 'Please select business structure' }}
            render={({ field: { value, onChange } }) => (
              <Select
                size="small"
                displayEmpty
                value={value}
                variant="outlined"
                onChange={onChange}
                sx={{ maxWidth: 240 }}
              >
                <MenuItem value="">
                  <Typography color="text.hint">Select</Typography>
                </MenuItem>
                {BusinessStructureOptions.map((record) => (
                  <MenuItem key={record.value} value={record.value}>
                    {record.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {(errors.operationsForm as any)?.companyInfo?.businessStructure
            ?.message && (
            <FormHelperText error>
              {
                (errors.operationsForm as any)?.companyInfo?.businessStructure
                  ?.message
              }
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default BusinessStructure;
