import { CircularProgress, FormControlLabel } from '@material-ui/core';
import { Toggle } from '@nirvana/ui-kit';
import React from 'react';
import { useStyles } from '../../../create/review/styles';

interface MarkAsCompletedProps {
  onChange: (checked: boolean) => void;
  checked?: boolean;
  isSectionLoading?: boolean;
}

const MarkAsCompletedFooter = ({
  onChange,
  checked = false,
  isSectionLoading,
}: MarkAsCompletedProps) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  if (isSectionLoading) {
    return (
      <div className="flex items-center h-full">
        <CircularProgress size={20} />
      </div>
    );
  }

  return (
    <FormControlLabel
      classes={{ label: classes.footerLabel }}
      labelPlacement="start"
      control={
        <Toggle
          onChange={handleChange}
          checked={checked}
          className={classes.footerToggle}
        />
      }
      label="Mark as Completed"
    />
  );
};

export default MarkAsCompletedFooter;
