import { Box, Grid } from '@material-ui/core';
import {
  ApplicationState,
  ApplicationTab,
  RejectedApplicationResponse,
} from '@nirvana/api/quoting';
import {
  debounce,
  Show,
  VirtualTable as Table,
  TableTabs as Tabs,
} from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FLEET_PROGRAM_TYPE_PAGE_VIEW } from 'src/features/telematics/events';
import { application as ApplicationHelper } from 'src/helpers';
import { useAnalytics } from 'src/helpers/analytics';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import { useDispatch } from 'src/redux';
import {
  createRenewalApplication,
  fetchApplicationsList,
  removeActiveApplication,
} from '../../actions';
import { applicationSelector } from '../../slices';
import ConflictErrorDialog from '../create/ConflictErrorDialog';
import InsuredDetails from '../create/insuredDetails';
import { getColumns } from './constants/fleetColumns';
import { createRenewalApplication as createRenewalApplicationMutation } from './queries/fleet';
import RenewError from './renewError';

const PAGE_SIZE = 25;

interface FleetTableProps {
  searchText: string;
}

const FleetTable = ({ searchText }: FleetTableProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const getFeatureValue = useFeatureFlag();
  const { capture } = useAnalytics();

  useEffect(() => {
    capture(FLEET_PROGRAM_TYPE_PAGE_VIEW);
  }, []);

  const { list: rawList, pagination } = useSelector(applicationSelector);
  const methods = useForm();
  const applicationTypeAccess = getFeatureValue(
    Feature.APPLICATION_TYPE_ACCESS,
    'fleet',
  );
  const isQuoteWithoutTelematicsEnabled = getFeatureValue(
    Feature.QUOTE_WITHOUT_TELEMATICS,
    false,
  );

  const [isLoading, setLoading] = useState(true);
  const [renewApplicationError, setRenewApplicationError] =
    useState<RejectedApplicationResponse>();
  const [selectedTab, setSelectedTab] = useState(
    ApplicationTab.ApplicationTabAll,
  );
  const [isInsuredDetailsDialogVisible, setInsuredDetailsDialogVisibility] =
    useState(false);
  const [conflictError, setConflictError] = useState<any>();

  const { mutate: createApplicationRenewals, isLoading: isRenewalLoading } =
    useMutation(createRenewalApplicationMutation, {
      onSuccess: (res) => {
        const { applicationID } = res;
        navigate(`/applications/${applicationID}/renew`);
      },
      onError: (ex: AxiosError) => {
        if (ex.response?.status === 409) {
          setConflictError(ex.response?.data);
          return;
        }

        enqueueSnackbar('Failed to create renewal application', {
          variant: 'error',
        });
      },
    });

  const isNewRenewalsFlowEnabled = getFeatureValue(
    Feature.NEW_RENEWALS_FLOW,
    false,
  );

  const getPageSize = useCallback(() => {
    // TODO: Remove this when backend is able to sync with FE states
    if (selectedTab === ApplicationTab.ApplicationTabUnderUwReview) {
      return 2000;
    }

    return PAGE_SIZE;
  }, [selectedTab]);

  useEffect(() => {
    dispatch(
      fetchApplicationsList({ pageSize: getPageSize(), tab: selectedTab }),
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, selectedTab, getPageSize]);

  const handleSearchChange = useMemo((): ((q: string) => void) => {
    return debounce((q: string) => {
      dispatch(
        fetchApplicationsList({ pageSize: getPageSize(), tab: selectedTab, q }),
      ).then(() => {
        setLoading(false);
      });
    }, 600);
  }, [dispatch, selectedTab, getPageSize]);

  useEffect(() => {
    handleSearchChange(searchText);
  }, [searchText, handleSearchChange]);

  const list = useMemo(() => {
    let filteredList = [...rawList];

    if (selectedTab && selectedTab !== ApplicationTab.ApplicationTabAll) {
      filteredList = filteredList.filter((record) => {
        return (
          ApplicationHelper.getApplicationStatusByState(record.state).tab ===
          selectedTab
        );
      });
    }

    return filteredList;
  }, [rawList, selectedTab]);

  const handleRenew = useCallback(
    (applicationId: string) => {
      dispatch(createRenewalApplication({ applicationId })).then((response) => {
        if (createRenewalApplication.fulfilled.match(response)) {
          const { applicationID } = response.payload;
          navigate(`/applications/${applicationID}/create`);
        } else {
          setRenewApplicationError(
            response.payload as RejectedApplicationResponse,
          );
        }
      });
    },
    [dispatch, navigate],
  );

  const handleNewRenewalFlow = useCallback(
    (applicationId: string) => {
      dispatch(removeActiveApplication());
      createApplicationRenewals(applicationId);
    },
    [createApplicationRenewals, dispatch],
  );

  const columns = useMemo(() => {
    return getColumns({
      hasRenew: getFeatureValue(Feature.FEATURE_RENEWALS, false),
      onRenew: isNewRenewalsFlowEnabled ? handleNewRenewalFlow : handleRenew,
      renewDisabled: isRenewalLoading,
      isQuoteWithoutTelematicsEnabled,
    });
  }, [
    getFeatureValue,
    handleNewRenewalFlow,
    handleRenew,
    isNewRenewalsFlowEnabled,
    isQuoteWithoutTelematicsEnabled,
    isRenewalLoading,
  ]);

  const handleRowSelect = ({ applicationID }: { applicationID?: string }) => {
    const matchedApplication = list.find(
      (record) => record.applicationID === applicationID,
    );

    if (matchedApplication?.renewalMetadata && isNewRenewalsFlowEnabled) {
      navigate(`/applications/${applicationID}/renew`);
      return;
    }

    // If application is under review, redirect to UW Review screen
    if (ApplicationHelper.isAppUnderReviewForQuote(matchedApplication?.state)) {
      navigate(`/applications/${applicationID}/submitted`);
    } else if (
      matchedApplication?.state ===
      ApplicationState.ApplicationStateQuoteGenerated
    ) {
      navigate(`/applications/${applicationID}/quote`);
    } else {
      navigate(`/applications/${applicationID}/create`);
    }
  };

  const tabs = [
    {
      label: 'All',
      value: ApplicationTab.ApplicationTabAll,
      count: pagination.totalCount,
    },
    { label: 'In progress', value: ApplicationTab.ApplicationTabInProgress },
    {
      label: 'Pending ELD/Telematics',
      value: ApplicationTab.ApplicationTabPendingEldTelematics,
    },
    { label: 'UW review', value: ApplicationTab.ApplicationTabUnderUwReview },
    {
      label: 'Ready to bind',
      value: ApplicationTab.ApplicationTabReadyToBind,
    },
    { label: 'Declined', value: ApplicationTab.ApplicationTabDeclined },
    { label: 'Bound', value: ApplicationTab.ApplicationTabBound },
    { label: 'Closed', value: ApplicationTab.ApplicationTabClosed },
  ];

  return (
    <>
      <Box px={3} py={1} className="h-full">
        <Grid container direction="column" spacing={5} className="h-full">
          <Grid item>
            <Tabs
              tabs={tabs}
              value={selectedTab}
              tabsProps={{ variant: 'scrollable' }}
              onChange={(value: ApplicationTab) => setSelectedTab(value)}
            />
          </Grid>

          <Grid item flexGrow={1}>
            <div className="flex-1 h-full mb-8" role="table">
              <Table
                data={list}
                columns={columns}
                isLoading={isLoading}
                hasNextPage={!!pagination.cursor}
                onLoadMore={() => {
                  dispatch(
                    fetchApplicationsList({
                      pageSize: getPageSize(),
                      tab: selectedTab,
                      q: searchText,
                      ...pagination,
                    }),
                  );
                }}
                onCell={() => ({ className: '!p-4' })}
                onRow={({ applicationID, state }, index) => {
                  const disabledStates: ApplicationState[] = [
                    ApplicationState.ApplicationStateDeclined,
                    // TODO: Remove this once there is a seperate page for policy details
                    ApplicationState.ApplicationStateBound,
                    ApplicationState.ApplicationStateClosed,
                  ];

                  const isDisabled: boolean = disabledStates.includes(state);

                  return {
                    role: 'row',
                    className: clsx('hover:bg-primary-extraLight/[0.65]', {
                      'bg-primary-extraLight/[0.45]': index % 2,
                      'cursor-not-allowed': isDisabled,
                      'cursor-pointer': !isDisabled,
                    }),
                    onClick: () => {
                      if (isDisabled) return;

                      handleRowSelect({ applicationID });
                    },
                  };
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>

      <RenewError
        open={!!renewApplicationError}
        applicationError={renewApplicationError}
        onClose={() => {
          setRenewApplicationError(undefined);
        }}
      />

      <FormProvider {...methods}>
        <Show when={conflictError}>
          <ConflictErrorDialog
            open={!!conflictError}
            data={conflictError}
            onClose={() => setConflictError(null)}
            openDialog={setInsuredDetailsDialogVisibility}
          />
        </Show>
        <InsuredDetails
          open={isInsuredDetailsDialogVisible}
          onClose={() => setInsuredDetailsDialogVisibility(false)}
          openDialog={setInsuredDetailsDialogVisibility}
          accessType={applicationTypeAccess}
        />
      </FormProvider>
    </>
  );
};

export default FleetTable;
