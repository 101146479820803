import {
  CommodityDistribution,
  CommodityHauled,
  PrimaryCommodityHauled,
  WeightedCommodityRecord,
} from '@nirvana/api/quoting';

export const getCommodityLabel = (
  commodity?: CommodityHauled | PrimaryCommodityHauled,
): string | undefined => {
  if (!commodity) return;

  return commodity.label || 'Unknown';
};

export const getPrimaryCommodityDistribution = (
  commodityDistribution: CommodityDistribution,
) => {
  return commodityDistribution?.commodities?.reduce((acc, record) => {
    return acc + (record?.percentageOfHauls || 0);
  }, 0);
};

export const getAdditionalCommodityDistribution = (
  commodityDistribution: CommodityDistribution,
) => {
  return commodityDistribution?.additionalCommodities?.percentageOfHauls || 0;
};

export const isEmptyCommodityRecord = (record: WeightedCommodityRecord) => {
  return (
    !record.commodity?.label &&
    !record.category?.type &&
    !record.avgDollarValueHauled &&
    !record.maxDollarValueHauled &&
    !record.percentageOfHauls
  );
};

export const getOperatingClassErrorMessage = (errors: any) => {
  const error = errors.operatingClassDistribution || [];

  if (error.length) {
    // Check if a number error is available
    const numberError = error.find(
      (record: any) => record && record.type === 'number',
    );
    if (numberError) return numberError.message;

    const matchedError =
      error.find((record: any) => record && record.message) || {};

    return matchedError.message;
  }
  return '';
};

export const hasError = (errors: any, type: string): boolean => {
  if (!errors) return false;

  const commodities = errors?.commodityDistribution?.commodities || [];
  const additionalCommodities =
    errors?.commodityDistribution?.additionalCommodities;

  for (let i = 0, l = commodities.length; i < l; i += 1) {
    // Do not consider minPrimatyCommodity an error if it is not on the first row
    if (type === 'minPrimaryCommodity' && i > 0) {
      continue;
    }

    if (commodities?.[i]?.type === type) {
      return true;
    }
  }

  if (additionalCommodities?.type === type) {
    return true;
  }

  return false;
};

export const getFirstErrorMessage = (errors: any) => {
  if (!errors) return '';

  const commodities = errors?.commodityDistribution?.commodities || [];
  const additionalCommodities =
    errors?.commodityDistribution?.additionalCommodities;

  for (let i = 0, l = commodities.length; i < l; i += 1) {
    if (commodities?.[i]?.message) {
      return commodities?.[i]?.message;
    }
  }

  if (additionalCommodities?.message) {
    return additionalCommodities?.message;
  }

  return '';
};
