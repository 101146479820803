import { ApplicationState, ApplicationSummary } from '@nirvana/api/quoting';

export const getCoverageType = (coverageType: string) => {
  return coverageType
    .replace(/([a-z])[a-z]*([A-Z])/g, '$1$2')
    .replace(/([A-Z])[a-z]*/g, '$1')
    .replace(/^./, '')
    .toUpperCase();
};

export const isStateNotProgress = ({ state }: ApplicationSummary) => {
  return [
    ApplicationState.ApplicationStatePendingEldTelematics,
    ApplicationState.ApplicationStateProcessingEldTelematics,
    ApplicationState.ApplicationStateUnderReviewForQuote,
    ApplicationState.ApplicationStateApproved,
  ].includes(state);
};
