import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import useSnackbar from 'src/hooks/useSnackbar';
import { fetchWorkrampInstantAuthURL } from '../queries/instant_auth';

const Workramp = () => {
  const enqueueSnackbar = useSnackbar();

  const { isLoading } = useQuery(
    ['workramp-instant-auth'],
    () => fetchWorkrampInstantAuthURL({}),
    {
      onSuccess: (response) => {
        window.location.href = response.absoluteLoginUrl;
      },
      onError: () => {
        enqueueSnackbar(
          'Workramp LMS login error',
          'Failed to fetch workramp login token. Please try again later.',
          { variant: 'error' },
        );
      },
    },
  );

  if (isLoading) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return null;
};

export default Workramp;
