import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { ApplicationDetail, CoverageType } from '@nirvana/api/quoting';
import { InputNumeric } from '@nirvana/ui-kit';
import clsx from 'clsx';
import { format, formatISO, parseISO } from 'date-fns';
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import {
  application as ApplicationHelper,
  utils as UtilsHelper,
} from 'src/helpers';
import { useStyles } from '../../../create/lossHistory/lossHistory';
import { DateRange, getDateRanges } from '../../../create/lossHistory/utils';

interface LossRunFormViewProps {
  applicationData: ApplicationDetail;
}

const getFirstErrorMessage = (errors: any, index: number) => {
  const fields = errors?.lossRunSummaryForm?.[index]?.lossRunSummary || [];
  for (let i = 0, l = fields.length; i < l; i += 1) {
    if (fields[i]) {
      return fields[i]?.message;
    }
  }

  return '';
};

const LossRunFormView = ({ applicationData }: LossRunFormViewProps) => {
  const classes = useStyles();

  const { control, errors, setValue } = useFormContext();

  const effectiveDateISO = applicationData?.summary.effectiveDate;

  const effectiveDate = React.useMemo(
    () => (effectiveDateISO ? parseISO(effectiveDateISO) : new Date()),
    [effectiveDateISO],
  );
  const dateRanges: DateRange[] = React.useMemo(() => {
    const dateRanges = getDateRanges(effectiveDate);
    return dateRanges;
  }, [effectiveDate]);

  const applicableCoverages: Array<string> = React.useMemo(() => {
    const coverages =
      applicationData?.indicationForm?.operationsForm?.coveragesRequired?.map(
        (coverage) => coverage.coverageType,
      ) || [];
    const newCoverages = [CoverageType.CoverageAutoLiability];
    if (coverages.includes(CoverageType.CoverageAutoPhysicalDamage)) {
      newCoverages.push(CoverageType.CoverageAutoPhysicalDamage);
    }

    return newCoverages;
  }, [applicationData?.indicationForm?.operationsForm?.coveragesRequired]);

  React.useEffect(() => {
    const lossSummaryForm =
      applicationData?.indicationForm?.lossRunSummaryForm || [];

    // loop through the existing loss run summary and set the values
    lossSummaryForm.forEach((lossSummary, index) => {
      setValue(
        `lossRunSummaryForm[${index}].coverageType`,
        lossSummary.coverageType,
      );
      lossSummary.lossRunSummary.forEach(
        (lossRunSummary, lossRunSummaryIndex) => {
          setValue(
            `lossRunSummaryForm[${index}].lossRunSummary[${lossRunSummaryIndex}]`,
            { ...lossRunSummary },
          );
        },
      );
    });
  }, [applicationData?.indicationForm?.lossRunSummaryForm]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <FormHelperText>
          Losses should include indemnity paid + case reserve dollars only (no
          expense).
          <br />
          Claim counts are to be entered 1 per claim event (not per claimant)
          and should NOT include zero dollar indemnity events.
        </FormHelperText>
      </Grid>
      {applicableCoverages?.map((coverage: string, index: number) => (
        <Grid key={coverage} item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={4}>
              <Box
                className={clsx(
                  index === 0
                    ? classes.labelContainerFirst
                    : classes.labelContainer,
                )}
              >
                <Controller
                  control={control}
                  name={`lossRunSummaryForm[${index}].coverageType`}
                  defaultValue={coverage}
                  render={() => (
                    <InputLabel className={classes.inputLabel}>
                      {ApplicationHelper.getIndicationOptionsFeatureLabel(
                        coverage,
                      )}{' '}
                      summary
                    </InputLabel>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Table padding="checkbox">
                {index === 0 && (
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          classes.tableHeaderLabel,
                        )}
                        align="left"
                      >
                        Policy period
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          classes.tableHeaderLabel,
                        )}
                        align="left"
                      >
                        # of power units
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          classes.tableHeaderLabel,
                        )}
                        align="left"
                      >
                        Loss incurred
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          classes.tableHeaderLabel,
                        )}
                        align="left"
                      >
                        # of claims
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}

                <TableBody>
                  {dateRanges.map((record: DateRange, rangeIndex: number) => (
                    <Controller
                      control={control}
                      key={`lossRunSummaryForm[${index}].lossRunSummary[${rangeIndex}]`}
                      name={`lossRunSummaryForm[${index}].lossRunSummary[${rangeIndex}]`}
                      defaultValue={{
                        policyPeriodStartDate: formatISO(record.from, {
                          representation: 'date',
                        }),
                        policyPeriodEndDate: formatISO(record.to, {
                          representation: 'date',
                        }),
                      }}
                      rules={{
                        validate: {
                          numberOfPowerUnits: (value) => {
                            if (value.isNirvanaPeriod) {
                              return true;
                            }

                            if (
                              (rangeIndex === 0 || rangeIndex === 1) &&
                              !value.numberOfPowerUnits
                            ) {
                              return 'Please enter missing values';
                            } else if (rangeIndex > 1) {
                              // Check for partial data in rows
                              if (
                                !(
                                  value.numberOfPowerUnits ||
                                  value.numberOfPowerUnits === 0
                                ) &&
                                (value.lossIncurred ||
                                  value.lossIncurred === 0 ||
                                  value.numberOfClaims ||
                                  value.numberOfClaims === 0)
                              ) {
                                return 'Please enter missing values';
                              }
                            }

                            if (
                              value.numberOfPowerUnits &&
                              !UtilsHelper.isValidInt32(
                                value.numberOfPowerUnits,
                              )
                            ) {
                              return 'Please enter a valid number';
                            }

                            return true;
                          },
                          lossIncurred: (value) => {
                            if (value.isNirvanaPeriod) {
                              return true;
                            }
                            if (
                              (rangeIndex === 0 || rangeIndex === 1) &&
                              !value.lossIncurred &&
                              value.lossIncurred !== 0
                            ) {
                              return 'Please enter missing values';
                            } else if (rangeIndex > 1) {
                              // Check for partial data in rows
                              if (
                                !(
                                  value.lossIncurred || value.lossIncurred === 0
                                ) &&
                                (value.numberOfPowerUnits ||
                                  value.numberOfClaims)
                              ) {
                                return 'Please enter missing values';
                              }
                            }

                            if (
                              value.lossIncurred &&
                              !UtilsHelper.isValidInt32(value.lossIncurred)
                            ) {
                              return 'Please enter a valid number';
                            }

                            return true;
                          },
                          numberOfClaims: (value) => {
                            if (value.isNirvanaPeriod) {
                              return true;
                            }
                            if (
                              (rangeIndex === 0 || rangeIndex === 1) &&
                              !value.numberOfClaims &&
                              value.numberOfClaims !== 0
                            ) {
                              return 'Please enter missing values';
                            } else if (rangeIndex > 1) {
                              // Check for partial data in rows
                              if (
                                !(
                                  value.numberOfClaims ||
                                  value.numberOfClaims === 0
                                ) &&
                                (value.numberOfPowerUnits || value.lossIncurred)
                              ) {
                                return 'Please enter missing values';
                              }
                            }

                            if (
                              value.numberOfClaims &&
                              !UtilsHelper.isValidInt32(value.numberOfClaims)
                            ) {
                              return 'Please enter a valid number';
                            }

                            // Check for claims with zero dollar loss incurred
                            if (rangeIndex >= 0 && value.numberOfClaims > 0) {
                              if (value.lossIncurred <= 0) {
                                return 'Claims with zero loss incurred are not valid';
                              }
                            }

                            // Check to see number of claims is within 100
                            if (rangeIndex >= 0 && value.numberOfClaims > 100) {
                              return 'Number of claims cannot exceed 100';
                            }

                            return true;
                          },
                        },
                      }}
                      render={(props) => {
                        return (
                          <TableRow key={record.key}>
                            <TableCell className={classes.tableCell}>
                              <Box className={classes.periodLabelContainer}>
                                <Typography
                                  color="textSecondary"
                                  fontWeight="fontWeightRegular"
                                  lineHeight="24px"
                                >
                                  {format(record.from, 'M/d/yy')}
                                  &nbsp;-&nbsp;
                                  {format(record.to, 'M/d/yy')}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <FormControl>
                                {props.value.isNirvanaPeriod ? (
                                  <OutlinedInput
                                    size="small"
                                    disabled={props.value.isNirvanaPeriod}
                                    value="-"
                                  />
                                ) : (
                                  <InputNumeric
                                    size="small"
                                    placeholder="0"
                                    decimalScale={0}
                                    value={props?.value?.numberOfPowerUnits}
                                    onChange={(event) => {
                                      props.onChange({
                                        ...props?.value,
                                        numberOfPowerUnits: event.target.value
                                          ? +event.target.value
                                          : undefined,
                                      });
                                    }}
                                    error={
                                      errors?.lossRunSummaryForm?.[index]
                                        ?.lossRunSummary?.[rangeIndex]?.type ===
                                      'numberOfPowerUnits'
                                    }
                                  />
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <FormControl>
                                {props.value.isNirvanaPeriod ? (
                                  <OutlinedInput
                                    size="small"
                                    disabled={props.value.isNirvanaPeriod}
                                    value="-"
                                  />
                                ) : (
                                  <InputNumeric
                                    size="small"
                                    placeholder="$ 0"
                                    decimalScale={0}
                                    value={props?.value?.lossIncurred}
                                    disabled={props.value.isNirvanaPeriod}
                                    onChange={(event) => {
                                      props.onChange({
                                        ...props?.value,
                                        lossIncurred: event.target.value
                                          ? +event.target.value
                                          : undefined,
                                      });
                                    }}
                                    prefix="$"
                                    error={
                                      errors?.lossRunSummaryForm?.[index]
                                        ?.lossRunSummary?.[rangeIndex]?.type ===
                                      'lossIncurred'
                                    }
                                  />
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <FormControl>
                                {props.value.isNirvanaPeriod ? (
                                  <OutlinedInput
                                    size="small"
                                    disabled={props.value.isNirvanaPeriod}
                                    value="-"
                                  />
                                ) : (
                                  <InputNumeric
                                    size="small"
                                    disabled={props.value.isNirvanaPeriod}
                                    placeholder="0"
                                    decimalScale={0}
                                    value={props?.value?.numberOfClaims}
                                    onChange={(event) => {
                                      props.onChange({
                                        ...props?.value,
                                        numberOfClaims: event.target.value
                                          ? +event.target.value
                                          : undefined,
                                      });
                                    }}
                                    error={
                                      errors?.lossRunSummaryForm?.[index]
                                        ?.lossRunSummary?.[rangeIndex]?.type ===
                                      'numberOfClaims'
                                    }
                                  />
                                )}
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    />
                  ))}
                  {!!errors?.lossRunSummaryForm?.[index]?.lossRunSummary && (
                    <TableRow>
                      <TableCell className={classes.tableCell} colSpan={4}>
                        <FormHelperText error>
                          {getFirstErrorMessage(errors, index)}
                        </FormHelperText>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default LossRunFormView;
