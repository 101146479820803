import { Box, Grid } from '@material-ui/core';
import { ApplicationTab } from '@nirvana/api/non-fleet';
import { TableTabs as Tabs, VirtualTable } from '@nirvana/ui-kit';
import { useInfiniteQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NONFLEET_PROGRAM_TYPE_PAGE_VIEW } from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import { getColumns } from './constants/nonFleetColumns';
import { fetchApplicationsList } from './queries/nonFleet';

const PAGE_SIZE = 25;

interface NonFleetTableProps {
  searchText: string;
}

const NonFleetTable = ({ searchText }: NonFleetTableProps) => {
  const navigate = useNavigate();
  const { capture } = useAnalytics();

  const [selectedTab, setSelectedTab] = useState(
    ApplicationTab.ApplicationTabAll,
  );

  useEffect(() => {
    capture(NONFLEET_PROGRAM_TYPE_PAGE_VIEW);
  }, []);

  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['applications', { selectedTab, searchText }],
    queryFn: ({ pageParam }) =>
      fetchApplicationsList({
        q: searchText,
        pageSize: PAGE_SIZE,
        tab: selectedTab,
        cursor: pageParam,
      }),
    getNextPageParam: (lastPage) => lastPage.cursor,
  });

  const list = useMemo(() => {
    return _.flatMap(data?.pages || [], (page) => page.data || []);
  }, [data?.pages]);

  const tabs = [
    {
      label: 'All',
      value: ApplicationTab.ApplicationTabAll,
      count: data?.pages?.[data?.pages.length - 1].totalCount,
    },
    { label: 'In progress', value: ApplicationTab.ApplicationTabInProgress },
    { label: 'UW review', value: ApplicationTab.ApplicationTabUnderUwReview },
    {
      label: 'Ready to bind',
      value: ApplicationTab.ApplicationTabReadyToBind,
    },
    { label: 'Declined', value: ApplicationTab.ApplicationTabDeclined },
    { label: 'Bound', value: ApplicationTab.ApplicationTabBound },
    { label: 'Closed', value: ApplicationTab.ApplicationTabClosed },
  ];

  const columns = useMemo(() => getColumns(), []);

  return (
    <Box px={3} py={1} className="h-full">
      <Grid container direction="column" spacing={5} className="h-full">
        <Grid item>
          <Tabs
            tabs={tabs}
            value={selectedTab}
            tabsProps={{ variant: 'scrollable' }}
            onChange={(value: ApplicationTab) => setSelectedTab(value)}
          />
        </Grid>

        <Grid item flexGrow={1}>
          <div className="flex-1 h-full mb-8" role="table">
            <VirtualTable
              data={list}
              isLoading={isLoading}
              hasNextPage={!!hasNextPage}
              columns={columns}
              onLoadMore={() => {
                fetchNextPage();
              }}
              onCell={() => ({ className: '!p-4' })}
              onRow={(row, index) => {
                const disabledStates = [
                  'AppStatePolicyCreated',
                  'AppStateClosed',
                ];

                const isDisabled: boolean = disabledStates.includes(
                  row.appStatus ?? '',
                );

                return {
                  role: 'row',
                  className: clsx('hover:bg-primary-extraLight/[0.65]', {
                    'bg-primary-extraLight/[0.45]': index % 2,
                    'cursor-not-allowed': isDisabled,
                    'cursor-pointer': !isDisabled,
                  }),
                  onClick: () => {
                    if (isDisabled) {
                      return;
                    }

                    if (
                      row.appStatus === 'AppStateUnderUWReview' ||
                      row.appStatus === 'AppStateApproved'
                    ) {
                      navigate(
                        `/non-fleet/application/${row.applicationID}/submitted`,
                      );
                    } else if (row.appStatus === 'AppStateDeclined') {
                      navigate(
                        `/non-fleet/application/${row.applicationID}/decline`,
                      );
                    } else if (
                      row.appStatus === 'AppStateBindableQuoteGenerated'
                    ) {
                      navigate(
                        `/non-fleet/application/${row.applicationID}/quote`,
                      );
                    } else {
                      navigate(
                        `/non-fleet/application/${row.applicationID}/create`,
                      );
                    }
                  },
                };
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NonFleetTable;
