import { Box, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Complete from 'src/features/application/components/create/complete';

const useStyles = makeStyles({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const EndorsementSuccess = () => {
  const classes = useStyles();
  const { policyId = '' } = useParams();

  return (
    <Box flex={1} className={classes.center}>
      <Complete
        title="Your endorsement is successfully submitted"
        subtitle="Once we review your request, we will notify you when the quote is ready. In the meantime you can review or edit your submission if needed."
        buttonLabel="Back to Policy Details"
        buttonUrl={`/policies/${policyId}?tab=endorsements`}
      />
    </Box>
  );
};

export default EndorsementSuccess;
