import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch } from 'react-redux';
import logger from 'redux-logger';

import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (import.meta.env.PROD) {
      return getDefaultMiddleware();
    }

    return getDefaultMiddleware().concat(logger);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useDispatch = () => useAppDispatch<AppDispatch>();

export default store;
