import clsx from 'clsx';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Box,
  Typography,
  makeStyles,
  styled,
  Container,
  Grid,
  AccordionProps,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { ITheme } from '@nirvana/ui-kit';

export const TypographySemiBold = styled(Typography)(
  ({ theme }: { theme: ITheme }) => ({
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightSemiBold,
    display: 'inline-block',
  }),
);

export const TypographySemiBoldCaption = styled(Typography)(
  ({ theme }: { theme: ITheme }) => ({
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightSemiBold,
    display: 'inline-block',
  }),
);

interface IAccordionSummaryProps extends AccordionSummaryProps {
  expanded?: boolean;
}

export const AccordionSummary = styled(
  ({ children, expanded, ...rest }: IAccordionSummaryProps) => {
    return (
      <MuiAccordionSummary {...rest}>
        <Container maxWidth="md">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography
                variant="h6"
                fontWeight="fontWeightRegular"
                color="textPrimary"
              >
                {children}
              </Typography>
            </Grid>
            <Grid item>
              <span
                className={clsx('AccordionSummary-expandIconWrapper', {
                  'AccordionSummary-expandIconWrapper--expanded': expanded,
                })}
              >
                <ExpandMore color="primary" />
              </span>
            </Grid>
          </Grid>
        </Container>
      </MuiAccordionSummary>
    );
  },
)(({ theme }: { theme: ITheme }) => ({
  border: `1px solid ${theme.palette.primary.extraLight}`,
  boxShadow: '0px 3px 4px rgba(128, 145, 196, 0.08)',
  marginBottom: -1,

  '& .MuiAccordionSummary-content': {
    margin: 0,
  },

  '& .AccordionSummary-expandIconWrapper': {
    display: 'flex',
    color: theme.palette.action.active,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),

    '&.AccordionSummary-expandIconWrapper--expanded': {
      transform: 'rotate(180deg)',
    },
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  backgroundColor: '#FCFCFF',
}));

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 0,

  '&:before': {
    display: 'none',
  },
}));

export const AlertWrapper = styled(Box)(({ theme }: { theme: ITheme }) => ({
  backgroundColor: '#F0FEF4',
  height: theme.typography.pxToRem(50),
  display: 'flex',
  alignItems: 'center',
}));

export const useStyles = makeStyles((theme: ITheme) => ({
  quoteContainer: {
    padding: theme.spacing(4),
  },
  quote: {
    ...theme.typography.h6,
    paddingBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: `4px solid ${theme.palette.gold.main}`,
  },
  infoIcon: {
    color: theme.palette.info.main,
    fontSize: 14,
  },
  footerAppBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -2px 4px rgba(128, 145, 196, 0.13)',
  },
}));
