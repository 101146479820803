import * as React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Box,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { AdmittedApp } from '@nirvana/api/non-fleet';

import IconEdit from 'src/assets/icons/edit-info.svg';
import Button from 'src/components/button';

import { useStyles } from './styles';

export interface SectionCommonProps {
  editable: boolean;
  applicationData: AdmittedApp;
}

export type SectionDataRenderParams = {
  value: any;
};

export type SectionDataRecord = {
  key: string;
  label?: string;
  getValue?: (data: AdmittedApp) => any;
  renderValue?: (data: SectionDataRenderParams) => React.ReactNode;
  type?: 'default' | 'divider';
};

type SectionProps = {
  data: Array<SectionDataRecord>;
  onEdit: () => void;
  title: string;
  applicationData: AdmittedApp;
  editable?: boolean;
  footer?: React.ReactNode;
};

const Section = ({
  data,
  onEdit,
  title,
  applicationData,
  editable,
  footer,
}: SectionProps) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Card classes={{ root: classes.card }}>
        <CardHeader
          title={title}
          action={
            !!editable && (
              <Button
                type="button"
                onClick={onEdit}
                startIcon={<img src={IconEdit} alt="Edit" />}
                className={classes.editButton}
              >
                Edit
              </Button>
            )
          }
          titleTypographyProps={{
            variant: 'h5',
            color: 'textPrimary',
          }}
          classes={{
            root: classes.cardHeader,
            content: classes.cardHeaderContent,
          }}
        />
        <CardContent>
          <Grid container direction="column" spacing={3}>
            {data.map((record: SectionDataRecord) => {
              const value = record.getValue?.(applicationData);

              return (
                <Grid
                  key={record.key}
                  item
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="flex-start"
                  spacing={3}
                >
                  {record.type === 'divider' ? (
                    <Grid item sx={{ width: '100%' }}>
                      <Divider />
                    </Grid>
                  ) : (
                    <>
                      {record.label && (
                        <Grid item xs={4}>
                          <Box display="flex" alignItems="center">
                            <InputLabel className={classes.inputLabel}>
                              {record.label}
                            </InputLabel>
                          </Box>
                        </Grid>
                      )}
                      {/* Support for rendering in section without a label */}
                      <Grid item xs={record.label ? 8 : 12}>
                        <Typography variant="body2" color="secondary">
                          {record.renderValue?.({
                            value,
                          })}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
        {!!footer && <Grid classes={{ root: classes.footer }}>{footer}</Grid>}
      </Card>
    </Grid>
  );
};

export default Section;
