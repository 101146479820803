import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  OutlinedInput,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, InputZip, ITheme, constants } from '@nirvana/ui-kit';
import { TerminalLocation } from '@nirvana/api/quoting';
import { useMutation } from '@tanstack/react-query';

import { TerminalLocations } from 'src/constants/terminals';
import { decodeZipInfo } from 'src/features/admitted/queries/application';

const { usStates } = constants;

const useStyles = makeStyles((theme: ITheme) => ({
  label: {
    color: theme.palette.text.hint,
    ...theme.typography.caption,
  },
  formControl: {
    marginBottom: 0,
    width: '100%',
  },
  formControlLabel: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
}));

type LocationFormProps = {
  index: number;
  terminal?: TerminalLocation;
  onConfirm: (index: number, data: TerminalLocation) => void;
  onRemove: (index: number) => void;
};

const LocationForm = React.forwardRef(
  ({ index, terminal, onConfirm, onRemove }: LocationFormProps, ref) => {
    const classes = useStyles();
    const { control, errors, handleSubmit, reset } = useForm();

    const { mutateAsync } = useMutation(decodeZipInfo);

    const onSubmit = (data: any) => {
      onConfirm(index, data);
    };

    React.useImperativeHandle(ref, () => ({
      submitForm(success: () => void, error: () => void = () => {}) {
        handleSubmit((data: any) => {
          onSubmit(data);
          success();
        }, error)();
      },
    }));

    React.useEffect(() => {
      if (terminal && Object.keys(terminal).length) {
        reset(terminal);
      }
    }, [reset, terminal]);

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item container>
          <Grid item xs={12}>
            <Controller
              name="addressLineOne"
              defaultValue=""
              control={control}
              rules={{
                validate: {
                  required: (val) => {
                    return (
                      val.trim().length > 0 || 'Please enter a valid address'
                    );
                  },
                },
                setValueAs: (value) => (value || '').trim(),
              }}
              render={(props) => (
                <>
                  <FormLabel className={classes.label}>
                    Please enter the terminal location&apos;s information
                  </FormLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Address Line 1"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    error={!!errors.addressLineOne}
                  />
                  {!!errors.addressLineOne && (
                    <FormHelperText error>
                      {errors.addressLineOne.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={7}>
            <Controller
              name="addressLineTwo"
              defaultValue=""
              control={control}
              rules={{
                validate: {
                  sanityCheck: (val) => {
                    return (
                      !val ||
                      val.trim().length > 0 ||
                      'Please enter a valid address'
                    );
                  },
                },
                setValueAs: (value) => (value || '').trim(),
              }}
              render={(props) => (
                <>
                  <OutlinedInput
                    fullWidth
                    placeholder="Address Line 2 (optional)"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    error={!!errors.addressLineTwo}
                  />
                  {!!errors.addressLineTwo && (
                    <FormHelperText error>
                      {errors.addressLineTwo.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              name="zipCode"
              defaultValue=""
              control={control}
              rules={{
                required: 'Please enter a valid zip code',
                validate: {
                  isValidZip: async (value) => {
                    try {
                      await mutateAsync(value);

                      return true;
                    } catch (ex) {
                      return 'Please enter a valid ZIP code';
                    }
                  },
                },
              }}
              render={(props) => (
                <FormControl className={classes.formControl}>
                  <InputZip
                    placeholder="ZIP code"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    error={!!errors.zipCode}
                  />
                  {!!errors.zipCode && (
                    <FormHelperText error>
                      {errors.zipCode.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={7} container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="usState"
                defaultValue=""
                rules={{
                  required: 'Please select a state',
                }}
                control={control}
                render={(props) => (
                  <FormControl className={classes.formControl}>
                    <Select
                      displayEmpty
                      variant="outlined"
                      placeholder="State"
                      value={props.value}
                      onChange={props.onChange}
                      error={!!errors.usState}
                    >
                      <MenuItem value="">State</MenuItem>
                      {usStates.map((record) => {
                        return (
                          <MenuItem value={record.code} key={record.code}>
                            {record.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!!errors.usState && (
                      <FormHelperText error>
                        {errors.usState.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="typeOfTerminal"
                defaultValue=""
                rules={{
                  required: 'Please select a location type',
                }}
                control={control}
                render={(props) => (
                  <FormControl className={classes.formControl}>
                    <Select
                      displayEmpty
                      variant="outlined"
                      placeholder="Type"
                      value={props.value}
                      onChange={props.onChange}
                      error={!!errors.typeOfTerminal}
                    >
                      <MenuItem value="">Location</MenuItem>
                      {TerminalLocations.map((record) => {
                        return (
                          <MenuItem value={record.code} key={record.code}>
                            {record.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!!errors.typeOfTerminal && (
                      <FormHelperText error>
                        {errors.typeOfTerminal.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={5} />
        </Grid>
        <Grid item>
          <>
            <FormLabel className={classes.label}>
              Please select all that apply
            </FormLabel>
            <Grid item container>
              <Grid item>
                <Controller
                  name="isGuarded"
                  control={control}
                  defaultValue={false}
                  render={(props) => {
                    return (
                      <FormControlLabel
                        classes={{
                          root: classes.formControlLabel,
                        }}
                        onChange={(_, checked: boolean) => {
                          props.onChange(checked);
                        }}
                        control={<Checkbox checked={props.value} />}
                        label="Guarded"
                      />
                    );
                  }}
                />

                <Controller
                  name="isGated"
                  control={control}
                  defaultValue={false}
                  render={(props) => {
                    return (
                      <FormControlLabel
                        onChange={(_, checked: boolean) => {
                          props.onChange(checked);
                        }}
                        control={<Checkbox checked={props.value} />}
                        label="Gated"
                        classes={{
                          root: classes.formControlLabel,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </>
        </Grid>
        <Grid item>
          <Divider />
          <Box display="flex" justifyContent="flex-end" pt={1}>
            <Button size="small" onClick={() => onRemove(index)}>
              Delete
            </Button>
            <Button size="small" onClick={handleSubmit(onSubmit)}>
              Confirm
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  },
);

export default LocationForm;
