import { combineReducers } from '@reduxjs/toolkit';

import application from 'src/features/application/slices';
import auth from 'src/features/auth/slices';
import init from 'src/features/init/slices';
import operation from 'src/features/application/slices/classesAndCommodities';
import indicationOptions from 'src/features/application/slices/indicationOptions';
import telematics from 'src/features/telematics/slices';

const rootReducer = combineReducers({
  application,
  auth,
  init,
  operation,
  indicationOptions,
  telematics,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
