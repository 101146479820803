import NumberFormat from 'react-number-format';
import {
  AdmittedAppVehicleDetails,
  CoverageType,
} from '@nirvana/api/non-fleet';

import { TableView } from 'src/components/table';
import { useAppConstantsContext } from 'src/features/admitted/hooks/useConstants';

import Section, {
  SectionCommonProps,
  SectionDataRenderParams,
} from '../section';
import { getWeightClassLabel } from '../../constants/equipment';

export interface IEquipmentSectionProps extends SectionCommonProps {
  onEdit: (key: string) => void;
}

const EquipmentSection = ({
  onEdit,
  applicationData,
  ...rest
}: IEquipmentSectionProps) => {
  const { getVehicleClassLabel, getVehicleTypeLabel } =
    useAppConstantsContext();
  const coverages =
    applicationData?.operationsForm?.coverages
      ?.filter((record) => {
        return record.isRequired;
      })
      .map((record) => record.coverageType) ?? [];

  const hasStatedValue = coverages.includes(
    CoverageType.CoverageAutoPhysicalDamage,
  );

  return (
    <Section
      {...rest}
      applicationData={applicationData}
      title="Equipment"
      onEdit={() => onEdit('equipment')}
      data={[
        {
          key: 'vin',
          label: '',
          getValue: (data) => {
            return data.equipmentsForm?.vehicles;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            return (
              <TableView
                headers={[
                  {
                    key: 'name',
                    content: 'VIN',
                    width: '20%',
                  },
                  {
                    key: 'category',
                    content: 'Vehicle',
                    width: '20%',
                  },
                  {
                    key: 'vehicleType',
                    content: 'Vehicle Type',
                    width: hasStatedValue ? '15%' : '20%',
                  },
                  {
                    key: 'vehicleClass',
                    content: 'Vehicle Class',
                    width: hasStatedValue ? '15%' : '20%',
                  },
                  {
                    key: 'weightClass',
                    content: 'GVW, lbs',
                    width: hasStatedValue ? '20%' : '20%',
                  },
                  ...(hasStatedValue
                    ? [
                        {
                          key: 'statedValue',
                          content: 'Stated value',
                          width: '15%',
                        },
                      ]
                    : []),
                ]}
                rows={value?.map((record: AdmittedAppVehicleDetails) => ({
                  columns: [
                    {
                      content: `${record.vin}`,
                    },
                    {
                      content: `${record.year} ${record.make} ${record.model}`,
                    },
                    {
                      content: getVehicleTypeLabel(record.vehicleType),
                    },
                    {
                      content: `${getVehicleClassLabel(
                        record.vehicleType,
                        record.vehicleClass,
                      )}`,
                    },
                    {
                      content: getWeightClassLabel(record.weightClass),
                    },
                    ...(hasStatedValue
                      ? [
                          {
                            content:
                              record.statedValue && record.statedValue !== 0 ? (
                                <NumberFormat
                                  value={record.statedValue}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                />
                              ) : (
                                '-'
                              ),
                          },
                        ]
                      : []),
                  ],
                }))}
              />
            );
          },
        },
      ]}
    />
  );
};

export default EquipmentSection;
