import { Avatar } from '@material-ui/core';
import { GetInsuranceBundleResponse } from '@nirvana/api/endorsementapp';

export default function BusinessOwnerDetail({
  data,
}: {
  data?: GetInsuranceBundleResponse;
}) {
  return (
    <section className="flex-1">
      <div className="flex gap-2 mb-5">
        <Avatar className="bg-primary-extraLight">
          <p className="text-sm font-bold text-black">
            {data?.owner?.firstName[0]}
          </p>
        </Avatar>

        <div className="mr-20">
          <p className="text-base font-bold text-secondary">
            {[data?.owner?.firstName, data?.owner?.lastName]
              .filter((record) => !!record)
              .join(' ')}
          </p>
          <p className="text-sm">{data?.owner?.email}</p>
          <p className="text-sm">{data?.owner?.phoneNumber}</p>
        </div>

        <div className="space-y-4">
          <div className="flex flex-col space-y-1">
            <p className="text-xs text-text-hint">Mailing Address</p>

            <p className="text-sm font-medium text-primary">
              {[
                data?.mailingAddress?.street,
                data?.mailingAddress?.city,
                data?.mailingAddress?.state,
                data?.mailingAddress?.zip,
              ]
                .filter((record) => !!record)
                .join(', ') || 'N/A'}
            </p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-xs text-text-hint">Terminal Address</p>

            <p className="text-sm font-medium text-primary">
              {[
                data?.terminalAddress?.street,
                data?.terminalAddress?.city,
                data?.terminalAddress?.state,
                data?.terminalAddress?.zip,
              ]
                .filter((record) => !!record)
                .join(', ') || 'N/A'}
            </p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-xs text-text-hint">Producer Contact</p>

            <p className="text-sm font-medium text-primary">
              {data?.producer.firstName} {data?.producer.lastName},{' '}
              {data?.producer.email}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
