import { createAsyncThunk } from '@reduxjs/toolkit';
import { Configuration, DefaultApi } from '@nirvana/api/quoting';

import { setListSuccess, setListFail } from '../slices/classesAndCommodities';

export const fetchOperations = createAsyncThunk(
  'operations/supported',
  async (
    { applicationId }: { applicationId: string },
    { dispatch, getState, rejectWithValue }: any,
  ) => {
    const { auth } = getState();

    try {
      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new DefaultApi(configOptions);

      const response =
        await apiService.applicationApplicationIDSupportedOperationsGet(
          applicationId,
        );
      const { data } = response;

      dispatch(setListSuccess(data));

      return data;
    } catch (ex: any) {
      dispatch(setListFail({ message: ex.response?.message }));

      return rejectWithValue(ex.response?.message);
    }
  },
);
