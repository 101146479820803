import { TableV8 } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getDriversColumns } from '../../constants/driver';
import { fetchEndorsementRequestDrivers } from '../../queries/endorsement';
import { getDriverListData } from '../../utils';
import EditLayout, { EndorsementType } from './layout';

export default function Drivers() {
  const { policyId = '', endorsementId = '' } = useParams();

  const { data = [] } = useQuery(
    ['endorsement-request-drivers', policyId, endorsementId],
    () => fetchEndorsementRequestDrivers(policyId, endorsementId),
    {
      select: (data) => data.drivers,
    },
  );

  const driversList = useMemo(() => {
    return getDriverListData(data);
  }, [data]);

  return (
    <EditLayout title="Drivers" endorsementType={EndorsementType.Drivers}>
      <TableV8 columns={getDriversColumns({})} data={driversList} />
    </EditLayout>
  );
}
