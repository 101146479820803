import {
  Box,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';

import { utils as UtilsHelper } from 'src/helpers';
import IconDownload from 'src/assets/icons/download.svg';

import { useStyles } from './styles';

const QuoteSignature = ({
  signaturePacketLink,
}: {
  signaturePacketLink?: string;
}) => {
  const classes = useStyles();

  const handleSignaturePacketDownload = () => {
    if (signaturePacketLink) {
      UtilsHelper.downloadFile(signaturePacketLink);
    }
  };

  return (
    <Paper
      square
      elevation={0}
      sx={{ position: 'relative' }}
      classes={{ root: 'border border-primary-extraLight -mt-6' }}
    >
      <Container maxWidth="md" className={classes.quoteContainer}>
        <Grid item container spacing={3} direction="column">
          <Grid item>
            <Typography variant="h5" fontWeight="fontWeightSemiBold">
              Signature
            </Typography>
          </Grid>

          <Grid item>
            <Box display="flex" alignItems="center">
              <Link
                href=""
                onClick={(e) => {
                  e.preventDefault();

                  handleSignaturePacketDownload();
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: 1,
                  cursor: 'pointer',
                }}
              >
                <Box
                  mr={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={IconDownload} />
                </Box>
                Download Signature packet
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default QuoteSignature;
