import { VehicleClass, VehicleType } from '@nirvana/api/endorsementapp';

const WeightClassLabel: { [key: string]: string } = {
  G: '< 6,000',
  H: '6,001 to 10,000',
  3: '10,001 to 14,000',
  4: '14,001 to 16,000',
  5: '16,001 to 19,500',
  6: '19,501 to 26,000',
  7: '26,001 to 33,000',
  8: '> 33,000',
};

type WeightClassOption = {
  value: string;
  label: string;
  subtext?: string;
};

export const getWeightClassOptions = (
  vehicleType: VehicleType,
  vehicleClass: VehicleClass,
): WeightClassOption[] => {
  switch (vehicleType) {
    case VehicleType.Truck: {
      if (vehicleClass === VehicleClass.Garbage) {
        return [
          { value: '7', label: '26,001 to 33,000' },
          { value: '8', label: '> 33,000' },
        ];
      }
      return [
        { value: '3', label: '10,001 to 14,000' },
        { value: '4', label: '14,001 to 16,000' },
        { value: '5', label: '16,001 to 19,500' },
        { value: '6', label: '19,501 to 26,000' },
        { value: '7', label: '26,001 to 33,000' },
        { value: '8', label: '> 33,000' },
      ];
    }

    case VehicleType.Tractor: {
      return [
        { value: '5', label: '16,001 to 19,500' },
        { value: '6', label: '19,501 to 26,000' },
        { value: '7', label: '26,001 to 33,000' },
        { value: '8', label: '> 33,000' },
      ];
    }

    case VehicleType.Trailer: {
      return [
        {
          value: '7',
          label: '1,000-33,000',
          subtext: 'Non-semi trailer',
        },
        { value: '8', label: '> 33,000', subtext: 'Semi-trailer' },
      ];
    }

    case VehicleType.Pickup: {
      return [
        { value: '3', label: '10,001 to 14,000' },
        { value: '4', label: '14,001 to 16,000' },
      ];
    }

    default:
      return [];
  }
};

export const getWeightClassLabel = (value?: string) => {
  if (!value) return '';

  return WeightClassLabel[value] || '';
};
