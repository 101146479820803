import { format, parse, parseISO } from 'date-fns';

export const DATE_FORMATS_US = [
  {
    format: 'MM/dd/yyyy',
    pattern: /^\d{1,2}\/\d{1,2}\/\d{4}$/,
  },
  {
    format: 'MM/dd/yy',
    pattern: /^\d{1,2}\/\d{1,2}\/\d{2}$/,
  },
];

/*
 * @function
 * Return the date parsed from string using the supported date formats.
 * Currently, we only support 2 date formats:
 * - Middle-endian format (MM/dd/yyyy)
 * - ISO date format (yyyy-MM-dd)
 */
export const parseDate = (
  dateString: string,
  referenceDate: Date = new Date(),
) => {
  const sanitizedDateString = dateString.replace(/[-]/g, '/');
  const matchedFormat = DATE_FORMATS_US.find(({ pattern }) =>
    pattern.test(sanitizedDateString),
  )?.format;

  if (matchedFormat) {
    return parse(sanitizedDateString, matchedFormat, referenceDate);
  }

  return parseISO(dateString);
};

/*
 * @function
 * Return the ISO date string, formatted in US date style.
 */
export const formatDate = (dateString: string) => {
  return format(parseISO(dateString), 'MM/dd/yyyy');
};

export const formatDateForRenewals = (dateString: Date) => {
  return format(dateString, 'yyyy-MM-dd');
};
