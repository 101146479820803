import { AdmittedVehicleClass, VehicleType } from '@nirvana/api/non-fleet';

const WeightClassLabel = {
  WeightClassG: '< 6,000',
  WeightClassH: '6,001 to 10,000',
  WeightClass3: '10,001 to 14,000',
  WeightClass4: '14,001 to 16,000',
  WeightClass5: '16,001 to 19,500',
  WeightClass6: '19,501 to 26,000',
  WeightClass7: '26,001 to 33,000',
  WeightClass8: '> 33,000',
};

type WeightClassOption = {
  value: string;
  label: string;
  subtext?: string;
};

export const getWeightClassOptions = (
  vehicleType: VehicleType,
  vehicleClass: AdmittedVehicleClass,
): WeightClassOption[] => {
  switch (vehicleType) {
    case VehicleType.VehicleTypeTruck: {
      if (vehicleClass === AdmittedVehicleClass.VehicleClassGarbage) {
        return [
          { value: 'WeightClass7', label: '26,001 to 33,000' },
          { value: 'WeightClass8', label: '> 33,000' },
        ];
      }
      return [
        { value: 'WeightClass3', label: '10,001 to 14,000' },
        { value: 'WeightClass4', label: '14,001 to 16,000' },
        { value: 'WeightClass5', label: '16,001 to 19,500' },
        { value: 'WeightClass6', label: '19,501 to 26,000' },
        { value: 'WeightClass7', label: '26,001 to 33,000' },
        { value: 'WeightClass8', label: '> 33,000' },
      ];
    }

    case VehicleType.VehicleTypeTractor: {
      return [
        { value: 'WeightClass5', label: '16,001 to 19,500' },
        { value: 'WeightClass6', label: '19,501 to 26,000' },
        { value: 'WeightClass7', label: '26,001 to 33,000' },
        { value: 'WeightClass8', label: '> 33,000' },
      ];
    }

    case VehicleType.VehicleTypeTrailer: {
      return [
        {
          value: 'WeightClass7',
          label: '1,000-33,000',
          subtext: 'Non-semi trailer',
        },
        { value: 'WeightClass8', label: '> 33,000', subtext: 'Semi-trailer' },
      ];
    }

    case VehicleType.VehicleTypePickup: {
      return [
        { value: 'WeightClass3', label: '10,001 to 14,000' },
        { value: 'WeightClass4', label: '14,001 to 16,000' },
      ];
    }

    default:
      return [];
  }
};

export const getWeightClassLabel = (value?: string) => {
  if (!value) return '';

  return WeightClassLabel[value] || '';
};
