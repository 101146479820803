import * as React from 'react';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  makeStyles,
  Paper,
} from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

const useToolbarStyles = makeStyles((theme: ITheme) => ({
  root: {
    backgroundColor: theme.palette.primary.extraLight,
    padding: theme.spacing(1.5, 2),
    minHeight: 'auto',
  },
  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.light,
  },
}));

interface HeaderToolbarProps {
  title: React.ReactNode;
}

const HeaderToolbar = ({ title }: HeaderToolbarProps) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        fontWeight="fontWeightBold"
        component="div"
      >
        {title}
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: ITheme) => ({
  paper: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    boxShadow: '0px 2px 4px #EBECF5',
  },
  tableContainer: {
    padding: theme.spacing(1, 0),
  },
  noDivider: {
    '&> td, &> th': {
      border: 0,
    },
  },
  tableRow: {
    '&> th': {
      color: '#828282',
      fontWeight: theme.typography.fontWeightMedium,
      padding: 0,
    },
    '&> td': {
      padding: theme.spacing(1, 2),
    },
  },
  textLabel: {
    color: theme.palette.text.hint,
  },
  textValue: {
    color: theme.palette.grey[900],
  },
}));

export interface SummaryViewRow {
  key?: string;
  label: React.ReactNode;
  value: React.ReactNode;
}

export interface ISummaryViewProps {
  title: React.ReactNode;
  rows: Array<SummaryViewRow>;
}

/**
 * Summary component that renders a table with header using Material UI components.
 * Refer: https://next.material-ui.com/components/tables for usage and props
 * @component
 *
 * @param {Object} props
 * @param {Object} props.title - Title of the table.
 * @param {Object[]} props.rows - List of rows to be renderd (Refer: SummaryViewRow for details).
 *
 * @example
 * const rows = [{
 *   key: 'radius',
 *   label: 'Radius',
 *   value: 10,
 * }, {
 *   key: 'percentage',
 *   label: 'Percentage'
 *   value: 20,
 * }];
 * return <SummaryView title="Document Summary" rows={rows} />
 */
const SummaryView = ({ title, rows }: ISummaryViewProps) => {
  const classes = useStyles();

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <HeaderToolbar title={title} />
        <div className={classes.tableContainer}>
          <Table size="small">
            <TableBody>
              {rows.map((row: SummaryViewRow) => (
                <TableRow
                  key={row.key}
                  className={clsx(classes.noDivider, classes.tableRow)}
                >
                  <TableCell align="left">
                    <Typography className={classes.textLabel}>
                      {row.label}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography className={classes.textValue}>
                      {row.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
};

export default SummaryView;
