export const getLastStep = (status: any, steps: Array<any>) => {
  let step = 0;
  for (let i = 0, l = steps.length; i < l; i += 1) {
    if (status && status[steps[i].key] && status[steps[i].key].finalizedDate) {
      step = i;
    }
  }

  return step + 1;
};

export const getTimeRange = (min: number, max: number) => {
  if (max > 5) {
    let maxWeek;

    const minWeek = Math.floor(min / 7) || 1;
    maxWeek = Math.ceil(max / 7);

    if (minWeek === maxWeek) {
      maxWeek += 1;
    }

    return (
      <>
        &nbsp;<strong>{minWeek}</strong>&nbsp;to&nbsp;
        <strong>{maxWeek}</strong>&nbsp;weeks
      </>
    );
  } else {
    return (
      <>
        &nbsp;<strong>{min}</strong>&nbsp;to&nbsp;
        <strong>{max}</strong>&nbsp;days
      </>
    );
  }
};

export const getTimeRangeNF = (min: number, max: number) => {
  if (max > 5) {
    let maxWeek;

    const minWeek = Math.floor(min / 7) || 1;
    maxWeek = Math.ceil(max / 7);

    if (minWeek === maxWeek) {
      maxWeek += 1;
    }

    return (
      <>
        &nbsp;<strong>{minWeek}</strong>&nbsp;to&nbsp;
        <strong>{maxWeek}</strong>&nbsp;weeks
      </>
    );
  } else {
    return (
      <>
        &nbsp;<strong>{min}</strong>&nbsp;to&nbsp;
        <strong>{max}</strong>&nbsp;business&nbsp;days
      </>
    );
  }
};
