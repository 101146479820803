import { useMemo, useState } from 'react';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { Table } from '@nirvana/ui-kit';

import PageHeader from 'src/components/pageHeader';
import GlobalFilter from 'src/components/globalFilter';

import { usePoliciesQuery } from 'src/types/graphql-types';
import { useSelector } from 'react-redux';
import { initSelector } from 'src/features/init/slices';

import { getColumns } from './constants/columns';

/**
 * Policy list page that list out all policies for the logged in user.
 * Corresponds to Policy List Page (https://www.figma.com/design/gvPyTgW3kafm11I6uEzqSy/Safety?node-id=25997-3539&t=PRtQbj8Y0Cg1m9Md-4)
 * @component
 */
const List = () => {
  const [searchText, setSearchText] = useState<string>('');

  const init = useSelector(initSelector);
  const { user } = init;

  const { data, loading: isLoading } = usePoliciesQuery({
    variables: {
      agencyId: user?.defaultAgencyId,
      hasClaims: true,
    },
  });

  const filteredData = useMemo(() => {
    // Filter by selectedTab and searchText

    let newData = [...(data?.policies ?? [])];

    if (searchText) {
      newData = newData.filter((item) => {
        return (
          item.insuredDOTNumber
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.insuredName.toLowerCase().includes(searchText.toLowerCase())
        );
      });
    }

    return newData;
  }, [data, searchText]);

  const columns = useMemo(() => getColumns(), []);

  return (
    <Box padding={4}>
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h4"
              color="textSecondary"
              fontWeight="fontWeightBold"
            >
              Customers with Claims
            </Typography>
          </Grid>
          <Grid item>
            <PageHeader
              title={
                <GlobalFilter
                  width={300}
                  placeholder="Search by DOT#, or insured name"
                  value={searchText}
                  onChange={setSearchText}
                />
              }
              actions={null}
            />
          </Grid>
          <Grid item>
            <Paper variant="outlined">
              <Box px={3} py={1}>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <div role="table">
                      <Table
                        isLoading={isLoading}
                        columns={columns}
                        isPaginated
                        data={filteredData}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default List;
