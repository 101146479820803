import * as React from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { useForm } from 'react-hook-form';
import {
  Button,
  Grid,
  FormHelperText,
  Typography,
  Box,
  InputAdornment,
  Tooltip,
  makeStyles,
  Link,
} from '@material-ui/core';
import { CheckOutlined as CheckIcon } from '@material-ui/icons/';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TelematicsInfo } from '@nirvana/api/non-fleet';
import { Chip, EMAIL_REGEX, Show } from '@nirvana/ui-kit';

import Drawer from 'src/components/drawer';
import { InputWithLabel } from 'src/components/input';
import { ITheme } from 'src/assets/themes';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import { analytics as Analytics } from 'src/helpers';

import NFTelematicsInfoIcon from 'src/assets/icons/nf-telematics-info.png';
import AcrobatIcon from 'src/assets/icons/acrobat.svg';
import ImageLink from 'src/assets/icons/link.svg';

import { createAdmittedConsentLink } from 'src/features/admitted/queries/application';
import { useApplicationDetailsContext } from 'src/features/admitted/hooks/useApplicationDetails';
import { useSelector } from 'react-redux';
import { initSelector } from 'src/features/init/slices';

const useStyles = makeStyles((theme: ITheme) => ({
  iconCheck: {
    color: theme.palette.success.main,
    cursor: 'pointer',
    minWidth: '30px',
  },
  telematicsCard: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
  },
  telematicsCardButton: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    padding: '27px 0 0 0',
    width: 230,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  telematicsCardIcon: {
    width: 152,
  },
  telematicsCardButtonText: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[500],
    maxWidth: 154,
    marginLeft: 4,
    height: '100%',
  },
  telematicsDownloadBar: {
    display: 'flex',
    alignItems: 'center',
    padding: 14,
    justifyContent: 'space-between',
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  downloadSubBar: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface ConnectionDrawerProps {
  open: boolean;
  onClose: (isLinkGenerated: boolean) => void;
  applicationId: string;
  telematicsInfo?: TelematicsInfo;
}

type ConnectionInfo = {
  name: string;
  email: string;
};

const TelematicsConnection = ({
  open,
  onClose,
  applicationId,
  telematicsInfo,
}: ConnectionDrawerProps) => {
  const classes = useStyles();
  const clipboard = useClipboard();
  const queryClient = useQueryClient();
  const { applicationDetails } = useApplicationDetailsContext();
  const businessOwnerName =
    [
      applicationDetails?.admitted?.operationsForm?.businessOwner?.firstName ||
        '',
      applicationDetails?.admitted?.operationsForm?.businessOwner?.lastName ||
        '',
    ]
      .join(' ')
      .trim() || '';

  const copySuccessTimeout = React.useRef<any>(null);
  const [copiedStatus, setCopiedStatus] = React.useState<boolean>(false);
  const [copyStatusTooltipVisibile, setCopyStatusTooltip] =
    React.useState<boolean>(false);
  const [consentLink, setConsentLink] = React.useState<string | undefined>();
  const [isInvitationLinkVisible, setInvitationLinkVisibility] =
    React.useState<boolean>(false);
  const [linkGenerateError, setLinkGenerateError] = React.useState<string>('');

  const { errors, handleSubmit, register, reset } = useForm<ConnectionInfo>({
    defaultValues: {
      email: '',
      name: businessOwnerName, // Pre-populate the name field with the business owner name
    },
  });

  const handleLinkCopy = (url: string) => {
    clipboard.copy(url);

    setTimeout(() => {
      setCopiedStatus(true);
      setCopyStatusTooltip(true);

      if (copySuccessTimeout.current) {
        clearTimeout(copySuccessTimeout.current);
      }

      // Dissmiss success notification after 3s
      copySuccessTimeout.current = setTimeout(() => {
        setCopiedStatus(false);
        setCopyStatusTooltip(false);
      }, 3000);
    }, 300);
  };

  const { mutateAsync: handleCreateAdmittedConsentLink } = useMutation(
    createAdmittedConsentLink,
    {
      onSuccess: (link) => {
        setConsentLink(link);
        setInvitationLinkVisibility(true);
        handleLinkCopy(link);

        // Invalidate application
        queryClient.invalidateQueries(['application', applicationId]);
      },
      onError: () => {
        setLinkGenerateError('Failed to generate link');
      },
    },
  );

  const handleClose = () => {
    // Wrap the visibility change in the timeout to let the
    // drawer close animation finish
    setTimeout(() => {
      setInvitationLinkVisibility(false);
    }, 900);

    onClose(!!consentLink);
  };

  const TELEMATIC_CONNECTION_MARKETING_URL =
    'https://nirvana-public-assets.s3.us-east-2.amazonaws.com/assets/agent-resources/Non-fleet+Telematics+Overview.pdf';

  const init = useSelector(initSelector);
  const { user } = init;

  const getFeatureValue = useFeatureFlag();
  const isTelematicsResourceEnabled = getFeatureValue(
    Feature.TELEMATICS_RESOURCE,
    false,
  );

  const trackTelematicsInfoClick = () => {
    Analytics.trackEvent({
      event: Analytics.AnalyticsEvent.QuotingTelematicsOverviewResourceOpen,
      properties: {
        applicationId,
        user: {
          id: user?.id,
          email: user?.email,
        },
      },
    });
  };

  React.useEffect(() => {
    if (telematicsInfo?.link) {
      reset(telematicsInfo);

      let link = telematicsInfo.link;
      const host = import.meta.env.VITE_WEB_URL;
      // Adding this validation in order to support older format of link
      // Removing it after we successfully migrate to link including Schema and host.
      if (!link.includes(host)) {
        link = `${host}/${link}`;
      }
      setConsentLink(link);
    }
  }, [telematicsInfo, reset]);

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Grid container direction="column" spacing={7}>
        <Grid item>
          <Typography
            variant="h6"
            color="textPrimary"
            fontWeight="fontWeightBold"
          >
            Connect ELD/Telematics
          </Typography>
          <Box maxWidth={280} color="text.hint">
            <Typography variant="caption" fontWeight="fontWeightRegular">
              Send a request or generate a link to share with insured to grant
              access to their ELD/telematics
            </Typography>
            <Show when={consentLink}>
              <Chip label="Link Generated" color="warning" />
            </Show>
          </Box>
        </Grid>
        <Grid item>
          <form
            onSubmit={(event) => {
              event.stopPropagation();
              event.preventDefault();

              handleSubmit(async (data) => {
                await handleCreateAdmittedConsentLink({
                  applicationId: applicationId,
                  insuredName: data.name,
                  insuredEmail: data.email,
                });
              })();
            }}
          >
            <InputWithLabel
              name="name"
              label="Insured's full name"
              formControlProps={{
                fullWidth: true,
              }}
              inputLabelProps={{ color: 'primary' }}
              placeholder="e.g. John Doe"
              type="text"
              fullWidth
              inputRef={register({
                required: 'Please enter full name',
                pattern: {
                  // Enforce the name to start with a letter or number (supporting company names)
                  // A very strict validation might not be required here
                  value: /^[a-zA-Z0-9].*$/,
                  message: 'Please enter a valid full name',
                },
                setValueAs: (value) => (value || '').trim(),
              })}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <InputWithLabel
              name="email"
              label="Insured's email address"
              formControlProps={{
                fullWidth: true,
              }}
              inputLabelProps={{ color: 'primary' }}
              placeholder="e.g. john.doe@gmail.com"
              type="text"
              fullWidth
              inputRef={register({
                required: 'Please enter an email',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Please enter a valid email',
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />

            <Box py={3}>
              {isInvitationLinkVisible ? (
                <InputWithLabel
                  label="Copy Link"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputLabelProps={{ color: 'primary' }}
                  placeholder="Invite link"
                  value={consentLink}
                  type="text"
                  fullWidth
                  noMargin
                  endAdornment={
                    <InputAdornment>
                      <Tooltip
                        open={copyStatusTooltipVisibile}
                        title={copiedStatus ? 'Copied to clipboard!' : 'Copy'}
                      >
                        {copiedStatus ? (
                          <CheckIcon
                            fontSize="small"
                            className={classes.iconCheck}
                          />
                        ) : (
                          <Link
                            sx={{ cursor: 'pointer' }}
                            underline="none"
                            onClick={() => {
                              handleLinkCopy(consentLink || '');
                            }}
                            onMouseEnter={() => {
                              setCopyStatusTooltip(true);
                            }}
                            onMouseLeave={() => {
                              setCopyStatusTooltip(false);
                            }}
                          >
                            <Typography variant="caption" color="primary">
                              Copy
                            </Typography>
                          </Link>
                        )}
                      </Tooltip>
                    </InputAdornment>
                  }
                  readOnly
                />
              ) : (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {consentLink ? 'Copy Link' : 'Generate Link'}
                  </Button>
                  <FormHelperText error>{linkGenerateError}</FormHelperText>
                </>
              )}
            </Box>
          </form>
        </Grid>
        <Show when={isTelematicsResourceEnabled}>
          <Grid item className="!pt-0 ">
            <Box className={classes.telematicsCard}>
              <Typography className="text-[16px]" color="primary">
                Fleets can save up to 20% up front!
              </Typography>

              <Typography className="text-[16px]">
                Learn more about the benefits of connecting telematics:
              </Typography>

              <Box mt={2} className="flex justify-center">
                <Link
                  href={TELEMATIC_CONNECTION_MARKETING_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  onClick={trackTelematicsInfoClick}
                >
                  <Box className={classes.telematicsCardButton}>
                    <img
                      src={NFTelematicsInfoIcon}
                      className={classes.telematicsCardIcon}
                      alt="A link to download the pdf and understand the importance of enabling telematics connection"
                    />

                    <Box className={classes.telematicsDownloadBar}>
                      <Box className={classes.downloadSubBar}>
                        <img src={AcrobatIcon} alt="Acrobat Icon" />
                        <Typography
                          classes={{ root: classes.telematicsCardButtonText }}
                        >
                          Telematics Overview
                        </Typography>
                      </Box>
                      <img
                        src={ImageLink}
                        alt="Download pdf that contains benefits of connecting to telematics"
                      />
                    </Box>
                  </Box>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Show>
      </Grid>
    </Drawer>
  );
};

export default TelematicsConnection;
