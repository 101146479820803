import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { setLoginSuccess } from 'src/features/auth/slices';
import { storage } from 'src/helpers';
import { AUTH_TOKEN_STORAGE_KEY } from 'src/constants';

import { Configuration, ConfigurationParameters } from '@nirvana/api/quoting';
import { AuthApi, UserProfileResponse } from '@nirvana/api/auth';

import { RootState } from 'src/redux/reducers';
import { logout } from 'src/features/auth/actions';

import store from 'src/redux';
import { initComplete, setError, setUser } from '../slices';

// TODO: Modify this Axios instance so that is can be passed in all the
// requests and intercept 401 to logout.
const getAxiosInstance = (): AxiosInstance => {
  const axiosInterceptor = axios.create({});

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response && error.response.status === 401) {
        store.dispatch(logout());
      }
      return Promise.reject(error);
    },
  );

  return axiosInterceptor;
};

const getAuthToken = () => {
  return storage.get(AUTH_TOKEN_STORAGE_KEY);
};

export const fetchMyProfile =
  () => async (dispatch: any, getState: () => RootState) => {
    try {
      const { auth } = getState();

      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new AuthApi(
        configOptions,
        undefined,
        getAxiosInstance(),
      );

      const response: AxiosResponse<UserProfileResponse> =
        await apiService.meGet();
      const { data } = response;

      // Dispatch action to store active application in global app state
      return dispatch(setUser(data));
    } catch (ex: any) {
      return dispatch(setError({ message: ex.message }));
    }
  };

export const preloadApp = () => (dispatch: any) => {
  try {
    // Check if auth token is available in storage
    const authToken = getAuthToken();

    if (authToken) {
      const configOptions: ConfigurationParameters = {
        apiKey: authToken,
      };

      dispatch(setLoginSuccess(configOptions));

      dispatch(fetchMyProfile());
    }

    return dispatch(initComplete());
  } catch (e) {
    // console.error('Failed to preload app', e);

    return Promise.reject(e);
  }
};
