import * as React from 'react';
import { useParams } from 'react-router-dom';

const SidebarContext = React.createContext({
  sidebarCollapsed: false,
});

const SidebarProvider: React.FC = ({ children }) => {
  const params = useParams();
  let sidebarCollapsed = false;

  if (params?.applicationId || params?.dotNumber || params?.policyId) {
    sidebarCollapsed = true;
  }

  return (
    <SidebarContext.Provider
      value={{
        sidebarCollapsed,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export { SidebarContext, SidebarProvider };
