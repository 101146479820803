import { TinyEmitter } from 'tiny-emitter';

const emitter = new TinyEmitter();

// Subscribe to an event
export function eventSubscriber(
  eventName: string,
  listener: (data?: any) => void,
) {
  emitter.on(eventName, listener);
}

// Dispatch (emit) an event
export function eventDispatcher(eventName: string, data?: any) {
  emitter.emit(eventName, data);
}

// Unsubscribe from an event
export function eventUnsubscriber(
  eventName: string,
  listener?: (data?: any) => void,
) {
  emitter.off(eventName, listener);
}
