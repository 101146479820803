import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { TransparentHeader } from '../header';

import { useStyles } from './styles';

interface ILayoutProps {
  application?: any;
}

/**
 * This is the authorized layout of the application. It renders a header, content, and footer.
 * The children passed in to this component are rendered under content.
 * @component
 *
 * @example
 * return <PublicLayout>Add some private content here.</PublicLayout>
 */
const PublicLayout: React.FC<ILayoutProps> = ({ application }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TransparentHeader application={application} />
      <main className={classes.content}>
        <Outlet />
      </main>
    </div>
  );
};

export default PublicLayout;
