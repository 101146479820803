import { IconButton } from '@material-ui/core';
import {
  Driver,
  DriverChangeChangeTypeEnum,
} from '@nirvana/api/endorsementapp';
import { Chip, getFormattedDate, Show } from '@nirvana/ui-kit';
import {
  AccessorColumnDef,
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import clsx from 'clsx';
import PencilIcon from 'src/assets/icons/pencil.svg';
import TrashIcon from 'src/assets/icons/trash.svg';
import Button from 'src/components/button';
import { getChangeTypeMeta } from '../utils';

export interface DriverData extends Driver {
  changeType?: DriverChangeChangeTypeEnum;
  oldValue?: Driver;
}

export const mockDriverData: DriverData[] = [
  {
    firstName: 'KHALIFF',
    lastName: 'JAFFARA',
    isOwner: true,
    licenseNumber: '054117921',
    licenseState: 'GA',
    isOutOfState: false,
    dateOfBirth: '1974-01-10',
    dateOfHire: '2021-10-27',
    yearsOfExperience: 29,
  },
  {
    firstName: 'AWIL',
    lastName: 'AHMED',
    isOwner: false,
    licenseNumber: '054117921',
    licenseState: 'GA',
    isOutOfState: false,
    dateOfBirth: '1974-01-10',
    dateOfHire: '2021-10-27',
    yearsOfExperience: 29,
  },
  {
    firstName: 'DEREJE',
    lastName: 'WEYO',
    isOwner: false,
    licenseNumber: '054117921',
    licenseState: 'GA',
    isOutOfState: false,
    dateOfBirth: '1974-01-10',
    dateOfHire: '2021-10-27',
    yearsOfExperience: 29,
  },
  {
    firstName: 'BEREKET',
    lastName: 'TADEGE',
    isOwner: false,
    licenseNumber: '054117921',
    licenseState: 'GA',
    isOutOfState: false,
    dateOfBirth: '1974-01-10',
    dateOfHire: '2021-10-27',
    yearsOfExperience: 29,
  },
  {
    firstName: 'MEBRATU',
    lastName: 'GETE',
    isOwner: false,
    licenseNumber: '054117921',
    licenseState: 'GA',
    isOutOfState: false,
    dateOfBirth: '1974-01-10',
    dateOfHire: '2021-10-27',
    yearsOfExperience: 29,
  },
];

const columnHelper = createColumnHelper<DriverData>();

type GetDriverColumnParams = {
  onRemove?: (row: DriverData) => void;
  onEdit?: (row: DriverData) => void;
  onUndo?: (row: DriverData) => void;
};

export const getDriversColumns = ({
  onRemove,
  onEdit,
  onUndo,
}: GetDriverColumnParams) => {
  const columns: Array<
    ColumnDef<DriverData> | AccessorColumnDef<DriverData, any>
  > = [
    columnHelper.accessor('firstName', {
      header: 'Driver Name',
      size: 240,
      cell: ({ getValue, row }) => (
        <p className="capitalize">
          <span
            className={clsx({
              'line-through text-text-hint':
                row.original.changeType === DriverChangeChangeTypeEnum.Deleted,
            })}
          >
            {[getValue(), row.original.lastName]
              .filter((item) => !!item)
              .join(' ')}
          </span>
          &nbsp;
          {/* <Show when={row.original.isOwner}>
            <Chip label="Owner" color="info" />
          </Show> */}
          <Show
            when={
              row.original.changeType &&
              row.original.changeType !== DriverChangeChangeTypeEnum.Unchanged
            }
          >
            <Chip
              label={row.original.changeType}
              color={getChangeTypeMeta(row.original.changeType)?.color}
            />
          </Show>
        </p>
      ),
    }),
    columnHelper.accessor('licenseNumber', { header: 'CDL Number' }),

    columnHelper.accessor('licenseState', {
      header: 'License State',
      size: 60,
    }),

    columnHelper.accessor('dateOfBirth', {
      header: 'Date of Birth',
      cell: ({ getValue }) => getFormattedDate(getValue()),
    }),

    columnHelper.accessor('dateOfHire', {
      header: 'Date of Hire',
      cell: ({ getValue }) => getFormattedDate(getValue()),
    }),

    columnHelper.accessor('yearsOfExperience', {
      header: 'Years of CDL',
      size: 80,
    }),
  ];

  if (onRemove && onEdit && onUndo) {
    columns.push(
      columnHelper.display({
        id: 'actions',
        cell: (props) => (
          <div className="space-x-6 text-right">
            <Show
              when={
                props.row.original.changeType !==
                DriverChangeChangeTypeEnum.Deleted
              }
              fallback={
                <Button
                  variant="text"
                  size="small"
                  type="button"
                  onClick={() => onUndo(props.row.original)}
                >
                  Undo
                </Button>
              }
            >
              <IconButton
                size="small"
                onClick={() => {
                  onEdit(props.row.original);
                }}
              >
                <img src={PencilIcon} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  onRemove(props.row.original);
                }}
              >
                <img src={TrashIcon} />
              </IconButton>
            </Show>
          </div>
        ),
      }),
    );
  }

  return columns;
};
