import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Driver } from '@nirvana/api/endorsementapp';
import {
  constants,
  DatePicker,
  InputNumeric,
  InputWithLabel,
  Show,
} from '@nirvana/ui-kit';
import { formatISO, isValid, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form-v7';
import TrashIcon from 'src/assets/icons/trash.svg';

type DriversFormProps = {
  title: string;
  driver?: Driver;
  onSubmit: (data: Driver) => void;
  onCancel: () => void;
  onRemove?: (driver: Driver) => void;
};

const DriversForm = ({
  title,
  driver,
  onRemove,
  onSubmit,
  onCancel,
}: DriversFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<Driver>();

  useEffect(() => {
    if (driver) {
      reset(driver);
    }
  }, [driver, reset]);

  return (
    <form
      className="p-6 space-y-3 overflow-hidden border rounded-lg border-text-disabled "
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex items-center justify-between ">
        <p className="text-sm text-black">{title}</p>
        <div className="space-x-4">
          <Button size="small" type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="small" type="submit" variant="contained">
            Save
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-6 gap-4">
        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="First Name"
            placeholder=""
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors?.firstName?.message}
            {...register('firstName', {
              required: 'Please enter first name',
            })}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="Last Name"
            placeholder=""
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors?.lastName?.message}
            {...register('lastName', {
              required: 'Please enter last name',
            })}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="CDL"
            placeholder=""
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors?.licenseNumber?.message}
            {...register('licenseNumber', {
              required: 'Please enter DL number',
            })}
          />
        </FormControl>
        <FormControl>
          <FormHelperText className="text-primary-main">State</FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name={'licenseState'}
            rules={{ required: 'Please select state' }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  displayEmpty
                  variant="outlined"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  error={!!errors?.licenseState?.message}
                >
                  <MenuItem value="">
                    <Typography color="text.hint">State</Typography>
                  </MenuItem>
                  {constants.usStates.map((record) => (
                    <MenuItem value={record.code} key={record.code}>
                      {record.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <FormHelperText className="text-primary-main">
            Date of Birth
          </FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name={'dateOfBirth'}
            rules={{ required: 'Please enter date of birth' }}
            render={({ field }) => (
              <DatePicker
                value={
                  (field.value && typeof field.value === 'string'
                    ? parseISO(field.value)
                    : field.value) as any
                }
                InputProps={{
                  error: !!errors?.dateOfBirth?.message,
                }}
                OpenPickerButtonProps={{
                  size: 'small',
                  sx: { padding: 0, width: '18px' },
                }}
                maxDate={new Date()}
                onChange={(value) =>
                  field.onChange(
                    value && isValid(value)
                      ? formatISO(value, {
                          representation: 'date',
                        })
                      : '',
                  )
                }
              />
            )}
          />
        </FormControl>
        <FormControl className="col-span-2">
          <FormHelperText className="text-primary-main">
            Date of hire
          </FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name={'dateOfHire'}
            rules={{ required: 'Please enter date of hire' }}
            render={({ field }) => (
              <DatePicker
                value={
                  (field.value && typeof field.value === 'string'
                    ? parseISO(field.value)
                    : field.value) as any
                }
                InputProps={{
                  error: !!errors?.dateOfHire?.message,
                }}
                OpenPickerButtonProps={{
                  size: 'small',
                  sx: { padding: 0, width: '18px' },
                }}
                maxDate={new Date()}
                onChange={(value) =>
                  field.onChange(
                    value && isValid(value)
                      ? formatISO(value, {
                          representation: 'date',
                        })
                      : '',
                  )
                }
              />
            )}
          />
        </FormControl>
        <FormControl>
          <FormHelperText className="text-primary-main">
            Yrs of CDL
          </FormHelperText>
          <Controller
            control={control}
            name={'yearsOfExperience'}
            rules={{ required: 'Please enter year' }}
            render={({ field: { onChange, value } }) => {
              return (
                <InputNumeric
                  placeholder="Year"
                  thousandSeparator={false}
                  value={value}
                  onChange={(e) => onChange(+e.target.value)}
                  error={!!errors?.yearsOfExperience?.message}
                />
              );
            }}
          />
        </FormControl>
      </div>
      <Show when={!!onRemove}>
        <Button
          size="small"
          type="button"
          onClick={onRemove && driver ? () => onRemove(driver) : onCancel}
          startIcon={<img src={TrashIcon} />}
          className="-ml-4 leading-4"
        >
          Remove Driver
        </Button>
      </Show>
    </form>
  );
};

export default DriversForm;
