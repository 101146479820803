export const TerminalLocations = [
  {
    name: 'Terminal',
    code: 'Terminal',
  },
  {
    name: 'Dock',
    code: 'Dock',
  },
  {
    name: 'Office',
    code: 'Office',
  },
  {
    name: 'Drop Lot',
    code: 'DropLot',
  },
];
