import Quote from './quote';
import ApplicationContainer from '../applicationContainer';

const NFCreate = () => {
  return (
    <ApplicationContainer>
      <Quote />
    </ApplicationContainer>
  );
};

export default NFCreate;
