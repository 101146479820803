import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.03)',
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    minHeight: theme.typography.pxToRem(56),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
  },
  brandLogo: {
    display: 'flex',
    alignItems: 'center',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.5, 0, 0.5, 0.5),
    borderRadius: theme.typography.pxToRem(20),

    '&: hover': {
      borderRadius: 20,
    },
  },
  listItemAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  listItemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    height: 30,
    width: 30,
    textTransform: 'capitalize',
  },
}));
