import { RoleGroupEnum } from 'src/types/graphql-types';

export const agencyRoles = [
  {
    role: RoleGroupEnum.AgencyAdminRole,
    description: (
      <>
        Full application permissions; <br />
        can manage (add/edit/delete) users <br />
        on behalf of the agency
      </>
    ),
  },
  {
    role: RoleGroupEnum.AgencyProducerRole,
    description: (
      <>
        Can submit applications either
        <br />
        as a Producer or on behalf of
        <br />
        another Producer
      </>
    ),
  },
  {
    role: RoleGroupEnum.AgencyAccountManagerRole,
    description: (
      <>
        Can submit applications solely
        <br />
        on behalf of another Producer
      </>
    ),
  },
];

export const roleLabels: { [key in RoleGroupEnum]?: string } = {
  [RoleGroupEnum.AgencyAdminRole]: 'Administrator',
  [RoleGroupEnum.AgencyProducerRole]: 'Producer',
  [RoleGroupEnum.AgencyAccountManagerRole]: 'Marketer',
};
