import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { storage } from '@nirvana/ui-kit';
import { setContext } from '@apollo/client/link/context';
import { AUTH_TOKEN_STORAGE_KEY, SHARE_TOKEN_STORAGE_KEY } from 'src/constants';

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = storage.get(AUTH_TOKEN_STORAGE_KEY);
  const shareToken = storage.get(SHARE_TOKEN_STORAGE_KEY);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      JSESSIONID: accessToken ?? '',
      SHAREID: shareToken,
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
