import { useQuery } from '@tanstack/react-query';
import constate from 'constate';

import {
  fetchApplicationDetails,
  fetchAvailableProducers,
} from '../queries/application';

function useApplicationDetails({ applicationId }: { applicationId: string }) {
  const {
    data: applicationDetails,
    isLoading: isApplicationLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['application', applicationId],
    queryFn: () => fetchApplicationDetails(applicationId),
  });

  const { data: producers, isLoading: isProducersLoading } = useQuery(
    ['producers'],
    fetchAvailableProducers,
  );

  return {
    applicationId,
    applicationDetails,
    producers,
    isLoading: isApplicationLoading || isProducersLoading,
    error,
    refetch,
  };
}

export const [ApplicationDetailsProvider, useApplicationDetailsContext] =
  constate(useApplicationDetails);
