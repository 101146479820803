import { Box, Grid, styled, Typography } from '@material-ui/core';
import { ITheme } from '@nirvana/ui-kit';
import { TableView } from 'src/components/table';

export const TableBordered = styled(Box)(({ theme }: { theme: ITheme }) => ({
  border: '1px solid',
  borderColor: theme.palette.error.main,
  borderRadius: 5,
  padding: theme.spacing(0.75, 3),
}));

export const APDUnfilledSkeleton = () => {
  return (
    <>
      <TableBordered>
        <Grid container spacing={10}>
          <Grid item xs={5}>
            <Typography
              variant="caption"
              fontWeight={'fontWeightBold'}
              mb={1}
              component={'p'}
            >
              &nbsp;
            </Typography>
            <TableView
              headers={[
                {
                  key: 'columnPolicyPeriod',
                  content: 'Policy period',
                },
                {
                  key: 'columnPowerUnits',
                  content: 'Units',
                },
              ]}
              rows={[1, 2, 3, 4].map(() => ({
                columns: [
                  {
                    content: (
                      <div className="w-32">
                        <div className="h-4 pr-0 bg-gray-100 rounded w-28" />
                      </div>
                    ),
                  },
                  {
                    content: (
                      <div>
                        <div className="w-10 h-4 bg-gray-100 rounded" />
                      </div>
                    ),
                  },
                ],
              }))}
            />
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="caption"
              fontWeight={'fontWeightBold'}
              mb={1}
              component={'p'}
            >
              Physical Damage
            </Typography>
            <TableView
              headers={[
                {
                  key: 'columnLossIncured',
                  content: 'Loss incurred',
                  width: '60%',
                },
                {
                  key: 'columnClaims',
                  content: 'Claims',
                  width: '40%',
                },
              ]}
              rows={[1, 2, 3, 4].map(() => ({
                columns: [
                  {
                    content: (
                      <div className="w-40">
                        <div className="h-4 bg-gray-100 rounded w-36" />
                      </div>
                    ),
                  },
                  {
                    content: (
                      <div>
                        <div className="w-10 h-4 bg-gray-100 rounded" />
                      </div>
                    ),
                  },
                ],
              }))}
            />
          </Grid>
        </Grid>
      </TableBordered>
      <Typography variant="caption" color="error">
        {'Please enter losses required for APD'}
      </Typography>
    </>
  );
};
