import { Button, Typography } from '@material-ui/core';
import { Dialog } from '@nirvana/ui-kit';
import React from 'react';
import { eventDispatcher } from 'src/helpers/eventEmitter';

interface VinConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onSkipProceed: () => void;
  invalidVins: string[];
}

const VinConfirmationModal: React.FC<VinConfirmationModalProps> = ({
  open,
  onClose,
  onSkipProceed,
  invalidVins,
}) => {
  const handleFixVins = () => {
    eventDispatcher('invalidVins', invalidVins);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{ style: { width: '36rem' } }}
      title={
        <p className="text-sm font-bold text-text-primary">
          Correct Invalid VINs
        </p>
      }
      fullWidth
      primaryAction={
        <Button onClick={handleFixVins} color="primary" variant="contained">
          Fix VINs
        </Button>
      }
      secondaryAction={
        <Button onClick={onSkipProceed} color="primary" variant="outlined">
          Skip & Proceed
        </Button>
      }
    >
      <hr className="-mx-6 -mt-6 border-gray-300" />

      <div className="mt-4 text-sm text-text-primary">
        The following VINs are invalid. Please correct them before proceeding to
        <br />
        provide an accurate quote.
      </div>

      <div className="mt-4">
        <span className="text-xs text-text-hint">VINs:</span>
        {invalidVins?.map((vin) => (
          <Typography
            key={vin}
            variant="body2"
            className="mt-2 text-sm font-medium text-text-primary"
          >
            {vin}
          </Typography>
        ))}
      </div>
    </Dialog>
  );
};

export default VinConfirmationModal;
