import apiServiceNF from 'src/helpers/apiService';

export async function fetchBindableQuote(applicationId: string) {
  const { data } = await apiServiceNF.getBindableQuote(applicationId);
  return data;
}

export async function bindQuote(applicationId: string) {
  const { data } = await apiServiceNF.bindQuote(applicationId);
  return data;
}
