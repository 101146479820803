import { z } from 'zod';

const getClassesAndCommoditiesValidationSchema = (hasMTC?: boolean) =>
  z.object({
    operatingClassDistribution: z.any(),
    commodityDistribution: hasMTC
      ? z.object({
          additionalCommodities: z.any(),
          commodities: z
            .unknown()
            .refine((val) => val !== null && val !== undefined),
        })
      : z.undefined(),
    primaryCategory: !hasMTC
      ? z.object({
          label: z.string(),
          type: z.string(),
        })
      : z.any(),
  });

export default getClassesAndCommoditiesValidationSchema;
