import {
  CoverageOption,
  CoverageOptionMetadata,
  CoverageType,
} from '@nirvana/api/non-fleet';

export const getCoverageOption = (
  coverages: CoverageOption[],
  coverageType: CoverageType,
) => {
  return coverages.find((record) => record.coverage === coverageType);
};

export const getReeferDeductible = (mtcDeductible: number) => {
  if (mtcDeductible === 1000) return 2500;

  return 5000;
};

export const isMandatoryCoverage = (
  coverage: CoverageType,
  metadata?: CoverageOptionMetadata,
) => {
  return metadata?.mandatoryCoverages?.includes(coverage) || false;
};
