import * as React from 'react';
import { format, parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';
import { Grid } from '@material-ui/core';

import { AttachmentView } from '@nirvana/ui-kit';
import {
  ApplicationDetail,
  CoverageRecord,
  EquipmentListRecord,
  Producer,
} from '@nirvana/api/quoting';

import { TableView, SummaryView } from 'src/components/table';
import { LocationList } from 'src/components/terminalLocation';
import { application as ApplicationHelper } from 'src/helpers';

import Section, {
  SectionCommonProps,
  SectionDataRenderParams,
} from './../section';
import { useStyles } from './../styles';

export interface IOperationsViewProps extends SectionCommonProps {
  availableProducers?: Producer[];
  onEdit: (key: string) => void;
}

const OperationsView = ({
  onEdit,
  applicationData,
  ...rest
}: IOperationsViewProps) => {
  const classes = useStyles();

  const getEquipmentSummaryRows = (
    equipmentList: Array<EquipmentListRecord>,
  ) => {
    const equipmentSummaryValues =
      ApplicationHelper.getEquipmentSummary(equipmentList);

    const equipmentSummaryRows = [
      {
        label: 'Total # of trucks and trailers',
        value: equipmentSummaryValues.totalUnits,
      },
      {
        label: 'Total value',
        value: (
          <NumberFormat
            value={equipmentSummaryValues.totalValue}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        ),
      },
    ];

    return equipmentSummaryRows;
  };

  return (
    <Section
      {...rest}
      applicationData={applicationData}
      title="Operations"
      onEdit={() => onEdit('operationsForm')}
      data={[
        {
          key: 'coveragesRequired',
          label: 'Coverage requested',
          getValue: (data: ApplicationDetail) => {
            return data?.indicationForm?.operationsForm?.coveragesRequired;
          },
          renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
            return hasChanged ? (
              <mark>
                {value
                  ?.map((record: CoverageRecord) =>
                    ApplicationHelper.getIndicationOptionsFeatureLabel(
                      record.coverageType,
                    ),
                  )
                  .join(', ')}
              </mark>
            ) : (
              value
                ?.map((record: CoverageRecord) =>
                  ApplicationHelper.getIndicationOptionsFeatureLabel(
                    record.coverageType,
                  ),
                )
                .join(', ')
            );
          },
        },
        {
          key: 'effectiveDate',
          label: 'Effective Date',
          getValue: (data: ApplicationDetail) => {
            return data.summary?.effectiveDate;
          },
          renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
            return hasChanged ? (
              <mark>{format(parseISO(value), 'MM/dd/yyyy')}</mark>
            ) : (
              format(parseISO(value), 'MM/dd/yyyy')
            );
          },
        },
        {
          key: 'producerName',
          label: 'Producer',
          getValue: (data: ApplicationDetail) => {
            return data.summary?.producerName;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            return value;
          },
        },
        {
          key: 'numberOfPowerUnits',
          label: 'Projected number of power units',
          getValue: (data: ApplicationDetail) => {
            return data.indicationForm?.operationsForm?.numberOfPowerUnits;
          },
          renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
            return hasChanged ? <mark>{value}</mark> : value;
          },
        },
        {
          key: 'projectedMileage',
          label: 'Projected mileage',
          getValue: (data: ApplicationDetail) => {
            return data.indicationForm?.operationsForm?.projectedMileage;
          },
          renderValue: ({ value, hasChanged }: SectionDataRenderParams) => {
            return hasChanged ? (
              <mark>
                <NumberFormat
                  value={value}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </mark>
            ) : (
              <NumberFormat
                value={value}
                displayType={'text'}
                thousandSeparator={true}
              />
            );
          },
        },
        {
          key: 'radiusOfOperation',
          label: 'Radius of operation',
          getValue: (data: ApplicationDetail) => {
            return data.indicationForm?.operationsForm?.radiusOfOperation;
          },
          renderValue: ({
            value = [],
            previousValue = [],
            hasChanged,
          }: SectionDataRenderParams) => {
            const previousPercentageByBucket = {};
            for (let i = 0, l = previousValue.length; i < l; i += 1) {
              previousPercentageByBucket[previousValue[i].mileageRadiusBucket] =
                previousValue[i].percentageOfFleet;
            }
            return (
              <TableView
                headers={[
                  {
                    key: 'columnRadiusRange',
                    content: 'Radius range',
                  },
                  {
                    key: 'columnPercentage',
                    content: 'Percentage of operation',
                  },
                ]}
                rows={value
                  .filter((record: any) => !!record.percentageOfFleet)
                  .map((record: any) => ({
                    columns: [
                      {
                        content: ApplicationHelper.getMileageRadiusLabel(
                          record.mileageRadiusBucket,
                        ),
                      },
                      {
                        content:
                          hasChanged &&
                          previousPercentageByBucket[
                            record.mileageRadiusBucket
                          ] !== record.percentageOfFleet ? (
                            <mark>{record.percentageOfFleet}%</mark>
                          ) : (
                            `${record.percentageOfFleet}%`
                          ),
                      },
                    ],
                  }))}
              />
            );
          },
        },
        {
          key: 'equipmentList',
          label: 'Equipment list',
          getValue: (data: ApplicationDetail) => {
            return data.indicationForm?.operationsForm?.equipmentList;
          },
          renderValue: ({
            value,
            previousValue,
            hasChanged,
          }: SectionDataRenderParams) => {
            const previousEquipmentSummary = getEquipmentSummaryRows(
              previousValue?.info,
            );
            return (
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <AttachmentView
                    files={[
                      {
                        filename: (
                          value?.flatfileMetadata || value?.implerMetadata
                        )?.fileMetadata.name,
                      },
                    ]}
                  />
                </Grid>
                {value?.info ? (
                  <Grid item>
                    <div className={classes.summaryContainer}>
                      <SummaryView
                        title="Document Summary"
                        rows={getEquipmentSummaryRows(value?.info).map(
                          (record, index) => {
                            return {
                              ...record,
                              value:
                                hasChanged &&
                                previousEquipmentSummary[index].value !==
                                  record.value ? (
                                  <mark>{record.value}</mark>
                                ) : (
                                  record.value
                                ),
                            };
                          },
                        )}
                      />
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            );
          },
        },
        ...(applicationData.indicationForm?.operationsForm?.terminalLocations
          ? [
              {
                key: 'terminalLocations',
                label: 'Physical business addresses',
                getValue: (data: ApplicationDetail) => {
                  return data.indicationForm?.operationsForm?.terminalLocations;
                },
                renderValue: ({ value }: SectionDataRenderParams) => {
                  return (
                    <LocationList
                      list={value}
                      onChange={() => null}
                      disableEdit
                    />
                  );
                },
              },
            ]
          : []),
      ]}
    />
  );
};

export default OperationsView;
