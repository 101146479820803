import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { addPercents } from 'src/helpers/utils';

interface RangeIndicationProps {
  indicationNumber?: number;
  rangePercentLow: number;
  rangePercentHigh: number;
  numberFormatProps?: any;
}

export const RangeIndication = ({
  indicationNumber,
  rangePercentLow,
  rangePercentHigh,
}: RangeIndicationProps) => {
  return (
    <div className="flex flex-row items-center justify-center space-x-1">
      <div className="flex items-center justify-center">
        <NumberFormat
          value={addPercents(indicationNumber, rangePercentLow)}
          displayType={'text'}
          thousandSeparator={true}
          prefix="$"
        />
      </div>

      <Typography
        variant="body1"
        component="span"
        fontWeight="fontWeightRegular"
        color="textPrimary"
        className="font-bold"
      >
        <>-</>
      </Typography>

      <div className="flex items-center justify-center">
        <NumberFormat
          value={addPercents(indicationNumber, rangePercentHigh)}
          displayType={'text'}
          thousandSeparator={true}
          prefix="$"
        />
      </div>
    </div>
  );
};
