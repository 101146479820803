import { words } from 'lodash';
import type { Column } from 'react-table';
import {
  AdmittedApp,
  ApplicationDetails,
  NRBApp,
} from '@nirvana/api/non-fleet';
import { Chip, getFormattedDate, IChipProps, Show } from '@nirvana/ui-kit';

import ErrorIcon from 'src/assets/icons/cross-circle-sm.svg';
import SuccessIcon from 'src/assets/icons/check-circle-sm.svg';

function getChipProps(value: string): IChipProps {
  switch (value) {
    case 'AppStateIncomplete':
      return { color: 'info', label: 'In Progress' };
    case 'AppStateQuoteGenerating':
      return { color: 'info', label: 'In Progress' };
    case 'AppStateQuoteGenerated':
      return { color: 'info', label: 'In Progress' };
    case 'AppStateComplete':
      return { color: 'info', label: 'In Progress' };
    case 'AppStateDeclined':
      return {
        color: 'error',
        label: 'Declined',
        icon: <img src={ErrorIcon} />,
      };
    case 'AppStateUnderUWReview':
    case 'AppStateUnderReferralReview':
    case 'AppStatePanic':
    case 'AppStateApproved':
      return { color: 'info', label: 'UW Review' };
    case 'AppStateBindableQuoteGenerated':
      return {
        color: 'success',
        label: 'Quote Generated',
      };
    case 'AppStatePolicyCreated':
    case 'AppStateBound':
      return {
        color: 'success',
        label: 'Bound',
        icon: <img src={SuccessIcon} />,
      };
    case 'AppStateClosed':
      return {
        label: 'Closed',
        color: 'grey',
      };
    default:
      return {
        color: 'info',
        label: words(value.replace('AppState', '')).join(' '),
      };
  }
}

export const getColumns = (): Array<Column<ApplicationDetails>> => [
  {
    Header: 'Application ID',
    accessor: 'shortID',
    width: '15%',
    disableSortBy: true,
    Cell: ({ value }) => `#${value}`,
  },
  {
    id: 'companyName',
    Header: 'Company Name',
    accessor: (row) => row?.nrb || row?.admitted || {},
    width: '30%',
    Cell: ({ value }: { value: NRBApp | AdmittedApp }) =>
      value?.operationsForm?.companyInfo?.name,
  },
  {
    id: 'producerName',
    Header: 'Producer Name',
    accessor: (row) => row?.nrb || row?.admitted || {},
    Cell: ({ value }: { value: NRBApp | AdmittedApp }) =>
      value?.operationsForm?.producerName ?? '',
    width: '20%',
  },
  {
    Header: 'Effective date',
    accessor: (row) => row?.nrb || row?.admitted || {},
    Cell: ({ value }: { value: NRBApp | AdmittedApp }) => (
      <Show when={value?.operationsForm?.effectiveDate}>
        {(date) => getFormattedDate(date)}
      </Show>
    ),
    width: '15%',
  },
  {
    Header: 'Status',
    accessor: 'appStatus',
    width: '20%',
    disableSortBy: true,
    Cell: ({ value }) => (
      <Show when={value}>{(value) => <Chip {...getChipProps(value)} />}</Show>
    ),
  },
];
