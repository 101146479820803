import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import {
  Dialog,
  InputWithLabel,
  Checkbox,
  Chip,
  storage,
} from '@nirvana/ui-kit';
import { usStates } from '@nirvana/ui-kit/src/constants';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ONBOARDING_AGENCY_INFO_FLAG } from 'src/constants';
import { useAnalytics } from 'src/helpers/analytics';
import { useUpdateAgentDetailMutation } from 'src/types/graphql-types';
import { initSelector } from '../init/slices';
import {
  USER_INVITE_AGENCY_INFO_MODAL_CLOSE_CLICK,
  USER_INVITE_AGENCY_INFO_MODAL_SUBMIT_CLICK,
  USER_INVITE_AGENCY_INFO_MODAL_VIEW,
} from '../telematics/events';

type UserDetailsUpdate = {
  title: string;
  phoneNumber: string;
  officeDepartmentRegion: string;
  preferredStates: string[];
};

const AgencyInfo = () => {
  const [updateAgent] = useUpdateAgentDetailMutation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const init = useSelector(initSelector);
  const { user } = init;
  const { capture } = useAnalytics();
  const [agencyInfoModal, setAgencyInfoModal] = useState(true);

  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<UserDetailsUpdate>({
    defaultValues: {
      title: '',
      phoneNumber: '',
      officeDepartmentRegion: '',
      preferredStates: [],
    },
    mode: 'onSubmit',
  });

  const onSubmit = (data: UserDetailsUpdate) => {
    capture(USER_INVITE_AGENCY_INFO_MODAL_SUBMIT_CLICK, { email: user?.email });

    updateAgent({
      variables: {
        ...data,
      },
      onCompleted: () => {
        enqueueSnackbar('Details Updated', { variant: 'success' });
        navigate('/applications/list');
      },
      onError: () => {
        enqueueSnackbar(
          'Unexpected error while updating details. Please try again later.',
          { variant: 'error' },
        );
      },
    });
  };

  useEffect(() => {
    capture(USER_INVITE_AGENCY_INFO_MODAL_VIEW, { email: user?.email });
  }, []);

  const handleClose = () => {
    capture(USER_INVITE_AGENCY_INFO_MODAL_CLOSE_CLICK, { email: user?.email });

    storage.set(ONBOARDING_AGENCY_INFO_FLAG, 'true');
    setAgencyInfoModal(false);
    navigate('/applications/list');
  };

  return (
    <Dialog
      open={agencyInfoModal}
      onClose={handleClose}
      title={<p className="text-sm font-bold">Agency Info</p>}
    >
      <div className="space-y-6 pl-2 w-[430px]">
        <hr className="-mx-8 -mt-6" />
        <div className="space-y-4">
          <div className="space-y-1">
            <p className="text-xl font-bold">Welcome to the Agent Platform!</p>
            <p className="text-sm">
              Complete your profile to get started. <br />
              This helps our team customize outreach according to your book.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-3 mb-6">
              <FormControl fullWidth error={!!errors.title}>
                <InputWithLabel
                  noMargin
                  label="Title/ Role"
                  placeholder=""
                  formControlProps={{
                    fullWidth: true,
                  }}
                  {...register('title', {
                    required: 'Please enter title',
                  })}
                  error={!!errors.title}
                  helperText={(errors?.title?.message as string) ?? ' '}
                />
              </FormControl>
              <FormControl fullWidth error={!!errors.phoneNumber}>
                <InputWithLabel
                  noMargin
                  label="Mobile Number"
                  placeholder=""
                  formControlProps={{
                    fullWidth: true,
                  }}
                  {...register('phoneNumber', {
                    required: 'Please enter mobile number',
                  })}
                  error={!!errors.phoneNumber}
                  helperText={(errors?.phoneNumber?.message as string) ?? ' '}
                />
              </FormControl>
              <FormControl fullWidth error={!!errors.officeDepartmentRegion}>
                <InputWithLabel
                  noMargin
                  label="Office Name/ Department/ Region"
                  placeholder=""
                  formControlProps={{
                    fullWidth: true,
                  }}
                  {...register('officeDepartmentRegion', {
                    required: 'Please enter office or department or region',
                  })}
                  error={!!errors.officeDepartmentRegion}
                  helperText={
                    (errors?.officeDepartmentRegion?.message as string) ?? ' '
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <p className="text-xs text-primary-main pb-1">
                  Which states do you write in most?
                </p>
                <Controller
                  name="preferredStates"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      disableCloseOnSelect
                      disableClearable
                      options={usStates.map((state) => state.name)}
                      value={getValues('preferredStates')}
                      onChange={(_, newValue) => {
                        setValue('preferredStates', newValue);
                      }}
                      renderTags={(value: string[], getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={<p className="ml-1 mr-3">{option}</p>}
                            {...getTagProps({ index })}
                            layout="rounded"
                            key={index}
                          />
                        ))
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            checked={getValues('preferredStates').includes(
                              option,
                            )}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.preferredStates}
                        />
                      )}
                      limitTags={2}
                      getLimitTagsText={(more) => (
                        <p className="text-xs text-primary-main">
                          + {more} More
                        </p>
                      )}
                    />
                  )}
                  rules={{ required: 'Please select atleast one state' }}
                />
                <FormHelperText error>
                  {errors?.preferredStates?.message ?? ' '}
                </FormHelperText>
              </FormControl>
              <FormControl className="flex items-end">
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </FormControl>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default AgencyInfo;
