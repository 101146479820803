import { useParams } from 'react-router-dom';
import { ApplicationDetailsProvider } from '../../hooks/useApplicationDetails';

import ApplicationCreate from './create';

const NFCreate = () => {
  const { applicationId = '' } = useParams();

  return (
    <ApplicationDetailsProvider applicationId={applicationId}>
      <ApplicationCreate />
    </ApplicationDetailsProvider>
  );
};

export default NFCreate;
