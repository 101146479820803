import { useMemo, useState } from 'react';
import { HiTruck, HiUser } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { Box, Container, Grid, Typography } from '@material-ui/core';

import { Show, TableBasic } from '@nirvana/ui-kit';
import { CoverageDetails, CoverageType, NRBApp } from '@nirvana/api/non-fleet';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TypographySemiBoldCaption,
} from './styles';
import { getAncillaryCoverages, getCoverageAbbrevation } from './utils';

interface IteratableCoverages extends Omit<CoverageDetails, 'coverageType'> {
  ancillaryCoverages?: CoverageDetails[];
  coverageType: CoverageType | 'AncillaryCoverages';
  coverageAbbreviation?: string;
}

interface IQuoteCoverages {
  appDetails?: NRBApp;
  mainCoverages?: CoverageDetails[];
  ancillaryCoverages?: CoverageDetails[];
}

const QuoteCoverages = ({
  appDetails,
  mainCoverages = [],
  ancillaryCoverages = [],
}: IQuoteCoverages) => {
  const [expandedPanel, setExpandedPanel] = useState(0);

  const excludedDrivers = useMemo(
    () =>
      appDetails?.driversForm?.drivers?.filter(
        ({ isIncluded }) => !isIncluded,
      ) ?? [],
    [appDetails?.driversForm?.drivers],
  );

  const excludedVehicles = useMemo(
    () =>
      appDetails?.equipmentsForm?.vehicles?.filter(
        ({ ownershipType }) => ownershipType === 'Short term lease',
      ) ?? [],
    [appDetails?.equipmentsForm?.vehicles],
  );

  const coverages = useMemo(() => {
    const allCoverages: IteratableCoverages[] = [];

    mainCoverages.forEach((coverage) => {
      allCoverages.push({
        ...coverage,
        coverageAbbreviation: getCoverageAbbrevation(coverage.label),
        ancillaryCoverages: getAncillaryCoverages(
          coverage.coverageType,
          ancillaryCoverages,
        ),
      });
    });

    allCoverages.push({
      coverageType: 'AncillaryCoverages',
      label: 'Ancillary Coverages',
      isRequired: true,
      ancillaryCoverages: getAncillaryCoverages(
        'AncillaryCoverages',
        ancillaryCoverages,
      ),
    });

    return allCoverages;
  }, [mainCoverages, ancillaryCoverages]);

  const handleExpandChange =
    (panelIndex: number) => (e: any, expanded: boolean) => {
      if (expanded) {
        setExpandedPanel(panelIndex);
      } else {
        setExpandedPanel(-1);
      }
    };

  return (
    <Accordion expanded={expandedPanel === 1} onChange={handleExpandChange(1)}>
      <AccordionSummary
        id="coverage-header"
        expanded={expandedPanel === 1}
        aria-controls="coverage-content"
      >
        Coverages
      </AccordionSummary>
      <AccordionDetails>
        <Container maxWidth="md">
          <Grid container direction="column" spacing={3}>
            <Show when={excludedDrivers.length > 0}>
              <Grid item>
                <div className="flex flex-col p-4 space-y-2 border rounded bg-error-extraLight/25 border-error-main">
                  <TypographySemiBoldCaption color="error">
                    Excluded Drivers
                  </TypographySemiBoldCaption>

                  <Typography variant="caption">
                    If bound, the following drivers will be excluded from the
                    underlying coverages due to their driving history records
                  </Typography>
                  <div>
                    {excludedDrivers.map(
                      ({ firstName, lastName, licenseNumber }) => (
                        <div
                          key={licenseNumber}
                          className="flex items-center space-x-1 text-xs text-error-main"
                        >
                          <HiUser />
                          <span>
                            {firstName} {lastName}
                          </span>
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </Grid>
            </Show>

            <Show when={excludedVehicles.length > 0}>
              <Grid item>
                <div className="flex flex-col p-4 space-y-2 border rounded bg-error-extraLight/25 border-error-main">
                  <TypographySemiBoldCaption color="error">
                    Excluded Vehicles
                  </TypographySemiBoldCaption>

                  <Typography variant="caption">
                    If bound, the following vehicles will not be covered by the
                    policy due to short term lease
                  </Typography>
                  <div>
                    {excludedVehicles.map(({ vin, make, model, year }) => (
                      <div
                        key={vin}
                        className="flex items-center space-x-1 text-xs text-error-main"
                      >
                        <HiTruck />
                        <span>
                          {vin} - {year} {make} {model}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            </Show>

            <Grid item>
              <TableBasic
                headers={[
                  { key: 'coverages', content: <>Coverages</> },
                  { key: 'limits', content: <>Limits</> },
                  { key: 'deductibles', content: <>Deductibles</> },
                ]}
                rows={coverages.map((record) => ({
                  key: `coverage-${record.coverageType}`,
                  columns: [
                    {
                      content: (
                        <Box py={1}>
                          <TypographySemiBoldCaption>
                            {record.label}
                          </TypographySemiBoldCaption>
                        </Box>
                      ),
                      styles: { width: '40%' },
                    },
                    {
                      content: (
                        <Box py={1}>
                          <TypographySemiBoldCaption>
                            <NumberFormat
                              prefix="$"
                              thousandSeparator
                              displayType="text"
                              value={record?.limit}
                            />
                          </TypographySemiBoldCaption>
                        </Box>
                      ),
                      styles: { width: '20%' },
                    },
                    {
                      content: (
                        <Box py={1}>
                          <TypographySemiBoldCaption>
                            <NumberFormat
                              prefix="$"
                              thousandSeparator
                              displayType="text"
                              value={record?.deductible}
                            />
                          </TypographySemiBoldCaption>
                        </Box>
                      ),
                      styles: { width: '20%' },
                    },
                  ],
                  subTable: {
                    rows:
                      record.ancillaryCoverages?.map((ancillaryRecord) => ({
                        key: ancillaryRecord.coverageType,
                        columns: [
                          {
                            content: (
                              <Box py={1}>
                                <Typography variant="caption">
                                  {ancillaryRecord.label}
                                </Typography>
                              </Box>
                            ),
                            styles: { width: '40%' },
                          },
                          {
                            content: (
                              <Box py={1}>
                                <Typography variant="caption">
                                  <Show
                                    when={ancillaryRecord.limit}
                                    fallback={
                                      record.coverageAbbreviation
                                        ? `${record.coverageAbbreviation} limit`
                                        : ''
                                    }
                                  >
                                    <NumberFormat
                                      prefix="$"
                                      thousandSeparator
                                      displayType="text"
                                      value={ancillaryRecord.limit}
                                    />
                                  </Show>
                                </Typography>
                              </Box>
                            ),
                            styles: { width: '20%' },
                          },
                          {
                            content: (
                              <Box py={1}>
                                <Typography variant="caption">
                                  <Show
                                    when={ancillaryRecord.deductible}
                                    fallback={
                                      record.coverageAbbreviation
                                        ? `${record.coverageAbbreviation} deductible`
                                        : ''
                                    }
                                  >
                                    <NumberFormat
                                      prefix="$"
                                      thousandSeparator
                                      displayType="text"
                                      value={ancillaryRecord.deductible}
                                    />
                                  </Show>
                                </Typography>
                              </Box>
                            ),
                            styles: { width: '20%' },
                          },
                        ],
                      })) ?? [],
                  },
                }))}
              />
            </Grid>
          </Grid>
        </Container>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuoteCoverages;
