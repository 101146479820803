import * as React from 'react';
import { Button } from '@material-ui/core';

import { Dialog } from '@nirvana/ui-kit';

import AppetiteError, { IAppetiteError } from '../create/appetiteCheck/error';

export interface IRenewError extends IAppetiteError {
  open: boolean;
}

/**
 * Renew failure dialog.
 * @component
 */
const RenewError = ({ open, onClose, applicationError }: IRenewError) => {
  return (
    <Dialog
      title=""
      open={open}
      maxWidth="md"
      onClose={onClose}
      primaryAction={
        <Button variant="contained" color="primary" onClick={onClose}>
          Back to Home
        </Button>
      }
      disableBackdropClose
    >
      <AppetiteError onClose={onClose} applicationError={applicationError} />
    </Dialog>
  );
};

export default RenewError;
