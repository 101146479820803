import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import Button from 'src/components/button';
import NotFoundIcon from 'src/assets/icons/404Icon.svg';

const NotFound404 = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      flexGrow={1}
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="center"
      spacing={9}
    >
      <Grid item xs={6} textAlign="right" pr={6}>
        <img src={NotFoundIcon} alt="Not Found" />
      </Grid>
      <Grid item xs={6} textAlign="left">
        <Typography
          variant="h4"
          color="textPrimary"
          mb={1}
          fontWeight="fontWeightBold"
        >
          Error 404 ! Page not found
        </Typography>

        <Typography variant="body2" color="textSecondary" mb={5} maxWidth={410}>
          unfortunately the page you are looking for couldn’t be found. It is
          probably deleted or the link is broken
        </Typography>

        <Button
          color="primary"
          onClick={() => navigate('/')}
          variant="contained"
        >
          Go to Home
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound404;
