import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authSelector } from '../../slices';

/**
 * HOC to handle redirection logic for unauthenticated pages.
 *
 * @param {Object} props.children - ReactNode
 *
 * @returns
 * If user is already logged in, Redirect to base path (/applications/list),
 * otherwise return the children
 */
const RedirectHoc: React.FC = ({ children }) => {
  const { isLoggedIn } = useSelector(authSelector);

  if (isLoggedIn) {
    return <Navigate to="/applications/list" />;
  }

  return <>{children}</>;
};

export default RedirectHoc;
