import { Button, FormHelperText, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  HiCheck,
  HiOutlineExclamationCircle,
  HiOutlineUsers,
  HiTrendingDown,
} from 'react-icons/hi';

import { TelematicsInfo } from '@nirvana/api/quoting';
import { Menu, Show, Switch } from '@nirvana/ui-kit';

import IconFiles from 'src/assets/icons/files.svg';
import IconMessage from 'src/assets/icons/message.svg';
import IconStatusOnline from 'src/assets/icons/status-online.svg';
import IconFileUpload from 'src/assets/icons/upload-primary.svg';

import { FileUploadDialog } from 'src/components/dialog';
import { sendTelematicsConsentRequestEmail } from 'src/features/application/actions';
import { FileUploadProgress } from 'src/features/application/slices';
import { useDispatch } from 'src/redux';

import { ADDITION_INFO_PAGE_CREATE_LINK_CLICK } from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import RequestConsent from './request-consent';
import TelematicsDetails from './telematics-details';

type LossRunUploadProps = {
  value: FileUploadProgress[];
  onChange: (files: any) => void;
  onRetry: (progress: FileUploadProgress) => void;
  onCancel: (progress: FileUploadProgress) => void;
  onDownload: (file: FileUploadProgress) => void;
  onRemove: (file?: FileUploadProgress) => void;
};

type ConnectEldProps = {
  errors: any;
  applicationId: string;
  telematicsInfo: TelematicsInfo | undefined;
  onDriversListDiaglogOpen: () => void;
  lossRunUploadProps: LossRunUploadProps;
};

export default function ConnectEld({
  errors,
  applicationId,
  telematicsInfo,
  onDriversListDiaglogOpen,
  lossRunUploadProps,
}: ConnectEldProps) {
  const { capture } = useAnalytics();
  const dispatch = useDispatch();

  const { clearErrors, control, watch, setValue } = useFormContext();
  const values = watch(['lossRunFiles', 'driverList']);

  const [openConsentDialog, setOpenConsentDialog] = useState(false);
  const [lossRunDialogOpen, setLossRunDialogOpen] = useState(false);

  useEffect(() => {
    if (telematicsInfo?.linkEmailedAt) {
      setValue('telematicsInfo.linkEmailedAt', telematicsInfo.linkEmailedAt);
      clearErrors('telematicsInfo.linkEmailedAt');
    }

    if (telematicsInfo?.link) {
      setValue('telematicsInfo.link', telematicsInfo.link);
      clearErrors('telematicsInfo.link');
    }
  }, [clearErrors, setValue, telematicsInfo]);

  function handleEmailSentConfirmation() {
    dispatch(
      sendTelematicsConsentRequestEmail({
        applicationId,
        payload: { completed: true },
      }),
    );
  }

  function renderIcon(condition: boolean, icon: string) {
    return (
      <Show
        when={condition}
        fallback={
          <div className="flex justify-center w-8 h-8 p-1.5 rounded bg-primary-extraLight">
            <img src={icon} alt="icon" />
          </div>
        }
      >
        <div className="bg-error-light text-error-main flex items-center justify-center w-8 h-8 p-1.5 rounded-full">
          <HiOutlineExclamationCircle className="w-full h-full" />
        </div>
      </Show>
    );
  }

  return (
    <>
      <Grid item>
        <div className="p-8 bg-white border rounded-lg shadow">
          <Controller
            control={control}
            name="telematicsInfo.link"
            render={() => (
              <div className="flex mb-8 space-x-3">
                {renderIcon(errors?.telematicsInfo?.link, IconStatusOnline)}
                <div>
                  <p className="mb-1 font-bold">
                    Create telematics connection link
                  </p>
                  <p className="text-xs">
                    We need your insured to connect their telematics in order to
                    get you a quote.
                  </p>

                  <Switch>
                    <Switch.Match when={errors?.telematicsInfo?.link}>
                      <FormHelperText error>
                        Please provide a contact and generate a link to send.
                      </FormHelperText>
                    </Switch.Match>

                    <Switch.Match when={!telematicsInfo?.link}>
                      <p className="text-xs">
                        Please provide a contact and generate a link to send.
                      </p>
                    </Switch.Match>
                  </Switch>
                </div>
                <div className="flex-1" />

                <Show
                  when={!telematicsInfo?.link}
                  fallback={
                    <div className="flex items-center space-x-2 text-success-main">
                      <p>Created</p>
                      <HiCheck />
                    </div>
                  }
                >
                  <div>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        capture(ADDITION_INFO_PAGE_CREATE_LINK_CLICK, {
                          applicationId,
                        });
                        setOpenConsentDialog(true);
                      }}
                    >
                      Create Link
                    </Button>
                  </div>
                </Show>
              </div>
            )}
          />

          <Show when={telematicsInfo}>
            {(tspinfo) => <TelematicsDetails telematicsInfo={tspinfo} />}
          </Show>

          <Controller
            control={control}
            rules={{
              required:
                "Please confirm that you understand that you're responsible for providing this link to the Insured.",
            }}
            name="telematicsInfo.linkEmailedAt"
            render={() => (
              <div className="flex mb-8 space-x-3">
                {renderIcon(errors?.telematicsInfo?.linkEmailedAt, IconMessage)}
                <div>
                  <p className="mb-1 font-bold">
                    Message the link to the Insured{' '}
                  </p>
                  <Show
                    when={errors?.telematicsInfo?.linkEmailedAt}
                    fallback={
                      <p className="text-xs">
                        Please confirm that you sent the email to the Insured
                        once you&apos;ve completed that step.
                      </p>
                    }
                  >
                    <FormHelperText error>
                      {errors?.telematicsInfo?.linkEmailedAt?.message}
                    </FormHelperText>
                  </Show>
                </div>
                <div className="flex-1" />
                <Show
                  when={!telematicsInfo?.linkEmailedAt}
                  fallback={
                    <div className="flex items-center space-x-2 text-success-main">
                      <p>Email sent</p>
                      <HiCheck />
                    </div>
                  }
                >
                  <div>
                    <Button
                      variant="outlined"
                      disabled={!telematicsInfo}
                      onClick={handleEmailSentConfirmation}
                    >
                      Confirm Email Sent
                    </Button>
                  </div>
                </Show>
              </div>
            )}
          />

          <div className="flex space-x-3">
            {renderIcon(errors?.driverList || errors?.lossRunFiles, IconFiles)}
            <div>
              <p className="mb-1 font-bold">Upload Files</p>
              <Show
                when={errors?.driverList || errors?.lossRunFiles}
                fallback={
                  <p className="text-xs">
                    Upload drivers list and loss run history from the past five
                    years
                  </p>
                }
              >
                <FormHelperText error>
                  Upload drivers list and loss run history from the past five
                  years
                </FormHelperText>
              </Show>
            </div>
            <div className="flex-1" />

            <Show
              when={
                (!!values.driverList || !!values.lossRunFiles?.length) &&
                !(values.driverList && values.lossRunFiles.length)
              }
            >
              <div className="flex items-center space-x-2 text-warning-main">
                <p>1/2 completed</p>
              </div>
            </Show>

            <Show
              when={!!values.driverList && !!values.lossRunFiles.length}
              fallback={
                <Menu
                  trigger={
                    <Button
                      type="button"
                      variant="outlined"
                      startIcon={<img src={IconFileUpload} alt="File Upload" />}
                    >
                      Upload
                    </Button>
                  }
                >
                  {[
                    {
                      label: 'Drivers list',
                      icon: <HiOutlineUsers />,
                      disabled: !!values.driverList,
                      onClick: onDriversListDiaglogOpen,
                    },
                    {
                      label: 'Loss run history',
                      icon: <HiTrendingDown />,
                      disabled: !!values.lossRunFiles?.length,
                      onClick: () => setLossRunDialogOpen(true),
                    },
                  ].map(({ label, disabled, icon, onClick }) => (
                    <Menu.Item
                      key={label}
                      onClick={onClick}
                      disabled={disabled}
                    >
                      <div className="flex items-center gap-2">
                        {icon}
                        {label}
                      </div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <div className="flex items-center space-x-2 text-success-main">
                <p>Uploaded 2/2</p>
                <HiCheck />
              </div>
            </Show>
          </div>
        </div>
      </Grid>

      <RequestConsent
        open={openConsentDialog}
        applicationId={applicationId}
        telematicsInfo={telematicsInfo}
        onClose={() => setOpenConsentDialog(false)}
      />

      <FileUploadDialog
        {...lossRunUploadProps}
        dialogOpen={lossRunDialogOpen}
        onClose={() => setLossRunDialogOpen(false)}
      />
    </>
  );
}
