import { differenceInYears, parseISO } from 'date-fns';
import {
  AdditionalInformationCommodity,
  AppetiteCheckRule,
  AppetiteCheckRuleResponse,
  ApplicationState,
  ApplicationTab,
  CoverageType,
  DriverListRecord,
  EquipmentListRecord,
  IndicationOptionTag,
  MileageRadiusBucket,
  PaymentOption,
} from '@nirvana/api/quoting';

export const ApplicationLabelByState: { [key: string]: string } = {
  [ApplicationState.ApplicationStateCreated]: 'Created',
  [ApplicationState.ApplicationStateInProgress]: 'In progress',
  [ApplicationState.ApplicationStateUnderReviewForIndication]: 'UW review',
  [ApplicationState.ApplicationStatePanic]: 'Invalid',
  [ApplicationState.ApplicationStateIndicationGenerated]:
    'Indication generated',
  [ApplicationState.ApplicationStateIndicationSelected]: 'Indication selected',
  [ApplicationState.ApplicationStateUnderReviewForQuote]: 'Under UW review',
  [ApplicationState.ApplicationStateQuoteGenerated]: 'Quote generated',
  [ApplicationState.ApplicationStateDeclined]: 'Declined',
  [ApplicationState.ApplicationStatePendingEldTelematics]:
    'Pending ELD/Telematics',
  [ApplicationState.ApplicationStateProcessingEldTelematics]:
    'Processing ELD/Telematics',
  [ApplicationState.ApplicationStateBound]: 'Policy Bound',
};

export const getApplicationStateLabel = (state: string): string => {
  return ApplicationLabelByState[state] || 'Unknown';
};

type ApplicationStatus = {
  key: string;
  tab?: ApplicationTab;
  label: string;
  color: 'success' | 'error' | 'info' | 'warning' | 'tint' | 'grey';
  icon?: 'success' | 'error';
};

export const ApplicationStatusByState: { [key: string]: ApplicationStatus } = {
  [ApplicationState.ApplicationStateCreated]: {
    key: 'in_progress',
    tab: ApplicationTab.ApplicationTabInProgress,
    label: 'In progress',
    color: 'info',
  },
  [ApplicationState.ApplicationStateInProgress]: {
    key: 'in_progress',
    tab: ApplicationTab.ApplicationTabInProgress,
    label: 'In progress',
    color: 'info',
  },
  [ApplicationState.ApplicationStateUnderReviewForIndication]: {
    key: 'in_progress',
    tab: ApplicationTab.ApplicationTabInProgress,
    label: 'In progress',
    color: 'info',
  },
  [ApplicationState.ApplicationStateIndicationDelay]: {
    key: 'in_progress',
    tab: ApplicationTab.ApplicationTabInProgress,
    label: 'In progress',
    color: 'info',
  },
  [ApplicationState.ApplicationStatePanic]: {
    key: 'uw_review',
    tab: ApplicationTab.ApplicationTabUnderUwReview,
    label: 'UW review',
    color: 'error',
  },
  [ApplicationState.ApplicationStateIndicationGenerated]: {
    key: 'in_progress',
    tab: ApplicationTab.ApplicationTabInProgress,
    label: 'In progress',
    color: 'info',
  },
  [ApplicationState.ApplicationStateIndicationSelected]: {
    key: 'in_progress',
    tab: ApplicationTab.ApplicationTabInProgress,
    label: 'In progress',
    color: 'info',
  },
  [ApplicationState.ApplicationStateApproved]: {
    key: 'uw_review',
    tab: ApplicationTab.ApplicationTabUnderUwReview,
    label: 'UW review',
    color: 'info',
  },
  [ApplicationState.ApplicationStateUnderReviewForQuote]: {
    key: 'uw_review',
    tab: ApplicationTab.ApplicationTabUnderUwReview,
    label: 'UW review',
    color: 'info',
  },
  [ApplicationState.ApplicationStatePendingEldTelematics]: {
    key: 'pending_eld',
    tab: ApplicationTab.ApplicationTabPendingEldTelematics,
    label: 'Pending ELD/Telematics',
    color: 'warning',
  },
  [ApplicationState.ApplicationStateProcessingEldTelematics]: {
    key: 'pending_eld', // belongs to the same group (pending_eld)
    tab: ApplicationTab.ApplicationTabPendingEldTelematics,
    label: 'Processing ELD/Telematics',
    color: 'warning',
  },
  [ApplicationState.ApplicationStateQuoteGenerated]: {
    key: 'ready_to_bind',
    tab: ApplicationTab.ApplicationTabReadyToBind,
    label: 'Quote generated',
    color: 'success',
  },
  [ApplicationState.ApplicationStateBound]: {
    key: 'bound',
    tab: ApplicationTab.ApplicationTabBound,
    label: 'Bound',
    color: 'success',
    icon: 'success',
  },
  [ApplicationState.ApplicationStateDeclined]: {
    key: 'declined',
    tab: ApplicationTab.ApplicationTabDeclined,
    label: 'Declined',
    color: 'error',
    icon: 'error',
  },
  [ApplicationState.ApplicationStateClosed]: {
    key: 'closed',
    tab: ApplicationTab.ApplicationTabClosed,
    label: 'Closed',
    color: 'grey',
  },
};

export const getApplicationStatusByState = (
  state: string,
): ApplicationStatus => {
  return (
    ApplicationStatusByState[state] || {
      key: 'unknown',
      tab: 'unknown',
      label: 'Unknown',
      color: 'error',
    }
  );
};

export const isAppUnderReviewForQuote = (
  status?: ApplicationState,
): boolean => {
  switch (status) {
    case ApplicationState.ApplicationStatePendingEldTelematics:
      return true;
    case ApplicationState.ApplicationStateProcessingEldTelematics:
      return true;
    case ApplicationState.ApplicationStateUnderReviewForQuote:
      return true;
    case ApplicationState.ApplicationStateApproved:
      return true;
    default:
      return false;
  }
};

export const AdditionalInformationCommodityLabel: { [key: string]: string } = {
  [AdditionalInformationCommodity.AddlInfoHazardousMaterialsInclClass9]:
    'Hazardous Materials (including Class 9)',
  [AdditionalInformationCommodity.AddlInfoLiftGateOrWhiteGloveService]:
    'Lift-gate service/white glove delivery',
  [AdditionalInformationCommodity.AddlInfoResidentialDelivery]:
    'Residential Delivery',
  [AdditionalInformationCommodity.AddlInfoDoubleOrTripleTrailers]:
    'Double/Triple Trailers',
  [AdditionalInformationCommodity.AddlInfoMeatOnHook]: 'Meat-on-hook',
};

export const getAdditionInformationCommodityLabel = (state: string): string => {
  return AdditionalInformationCommodityLabel[state] || 'Unknown';
};

export const MileageRadiusLabelByBucket: { [key: string]: string } = {
  [MileageRadiusBucket.MileageRadiusBucketZeroToFifty]: '0-50 miles',
  [MileageRadiusBucket.MileageRadiusBucketFiftyToTwoHundred]: '51-200 miles',
  [MileageRadiusBucket.MileageRadiusBucketTwoHundredToFiveHundred]:
    '201-500 miles',
  [MileageRadiusBucket.MileageRadiusBucketFiveHundredPlus]: '501+ miles',
};

export const getMileageRadiusLabel = (key: string) => {
  return MileageRadiusLabelByBucket[key] || 'Unknown';
};

export const getMileageRadiusList = () => {
  return Object.keys(MileageRadiusLabelByBucket).map((key: string) => ({
    bucket: key,
    label: MileageRadiusLabelByBucket[key],
  }));
};

export const IndicationOptionsFeatureLabelsByCoverage: {
  [key: string]: string;
} = {
  [CoverageType.CoverageAutoLiability]: 'Auto Liability',
  [CoverageType.CoverageAutoPhysicalDamage]: 'Physical Damage',
  [CoverageType.CoverageGeneralLiability]: 'General Liability',
  [CoverageType.CoverageMotorTruckCargo]: 'Motor Truck Cargo',
  [CoverageType.CoverageUnderinsuredMotoristBodilyInjury]: 'UIM BI',
  [CoverageType.CoverageUninsuredMotoristBodilyInjury]: 'UM BI',
  [CoverageType.CoverageUninsuredMotoristPropertyDamage]: 'UM PD',
  [CoverageType.CoverageMedicalPayments]: 'Med Pay',
  [CoverageType.CoveragePersonalInjuryProtection]: 'PIP',
  [CoverageType.CoverageTrailerInterchange]: 'Trailer Interchange',
  [CoverageType.CoverageBroadenedPollution]: 'Broadened Pollution',
  [CoverageType.CoverageUiia]: 'UIIA',
  [CoverageType.CoverageUm]: 'UM',
  [CoverageType.CoverageUim]: 'UIM',
  [CoverageType.CoverageUmuim]: 'UMUIM',
  [CoverageType.CoverageUmbiuimbi]: 'UMBIUIMBI',
  [CoverageType.CoverageTerrorism]: 'Terrorism',
  [CoverageType.CoveragePipExcessAttendantCare]: 'PIP Excess Attendant Care',
  [CoverageType.CoverageEnhancedPackageTowingLimit]:
    'Enhanced Package Towing Limit',
  [CoverageType.CoveragePropertyProtectionInsurance]:
    'Property Protection Insurance',
  [CoverageType.CoverageReeferWithHumanError]: 'Reefer With Human Error',
  [CoverageType.CoverageReeferWithoutHumanError]: 'Reefer Without Human Error',
};

export const isCoverageStatutory = (coverage: CoverageType): boolean => {
  switch (coverage) {
    case CoverageType.CoverageUnderinsuredMotoristBodilyInjury:
      return true;
    case CoverageType.CoverageUninsuredMotoristBodilyInjury:
      return true;
    case CoverageType.CoverageUninsuredMotoristPropertyDamage:
      return true;
    case CoverageType.CoverageMedicalPayments:
      return true;
    case CoverageType.CoveragePersonalInjuryProtection:
      return true;
    default:
      return false;
  }
};

export const getStatutoryCoveragesLabel = (): string => {
  return 'Statutory Coverages';
};

export const orderedPrimaryCoverages = [
  CoverageType.CoverageAutoLiability,
  CoverageType.CoverageAutoPhysicalDamage,
  CoverageType.CoverageMotorTruckCargo,
  CoverageType.CoverageGeneralLiability,
];

export const getOrderedCoverages = (possibleCoverages?: CoverageType[]) => {
  return orderedPrimaryCoverages.filter((value) =>
    (possibleCoverages || []).includes(value),
  );
};

export const isCoveragePrimary = (coverage: CoverageType) => {
  return orderedPrimaryCoverages.includes(coverage);
};

export const getIndicationOptionsFeatureLabel = (
  coverage: string = '',
): string => {
  return IndicationOptionsFeatureLabelsByCoverage[coverage] || '';
};

export const getBlanketCoverageLabel = (type: 'additional' | 'waiver') => {
  return type === 'additional'
    ? 'Blanket Additional Insured'
    : 'Blanket Waiver of Subrogation';
};

export const IndicationOptionLabelByTag: { [key: string]: string } = {
  [IndicationOptionTag.IndicationOptionTagBasic]: 'Basic',
  [IndicationOptionTag.IndicationOptionTagStandard]: 'Standard',
  [IndicationOptionTag.IndicationOptionTagComplete]: 'Complete',
};

export const getIndicationOptionTagLabel = (tag: string = ''): string => {
  return IndicationOptionLabelByTag[tag];
};

export const getOperatingClassLabel = (operation: string = ''): string => {
  return operation
    .replace('OperatingClass', '')
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/^ /, '');
};

export const getCommodityHauledLabel = (operation: string = ''): string => {
  return operation
    .replace('CommodityHauled', '')
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([0-9]+)/g, ' $1')
    .replace(/^ /, '')
    .replaceAll(' Or ', '/');
};

export const getAppetiteCheckRuleText = (
  rule: AppetiteCheckRuleResponse,
): string => {
  let ruleText = '';
  switch (rule.rule) {
    case AppetiteCheckRule.AppetiteCheckRuleIsValidOrActive:
      if (rule.details.isValid) {
        ruleText = 'The DOT# is NOT valid.';
      } else if (rule.details.isActive) {
        ruleText = 'The DOT# is NOT active.';
      }
      return ruleText;
    case AppetiteCheckRule.AppetiteCheckRuleUnitMeasure:
      if (rule.details.unitMeasure && rule.details.unitMeasure === '0') {
        ruleText = "The DOT filing doesn't show any active power units.";
      } else if (rule.details.unitMeasure && rule.details.unitMeasure !== '0') {
        ruleText = 'The DOT filing shows over 500 power units.';
      }
      return ruleText;
    case AppetiteCheckRule.AppetiteCheckRuleDotRating:
      if (rule.details.dotRatingMinYears) {
        const dotRatingMinYears = rule.details.dotRatingMinYears;
        ruleText = `The insured received conditional rating less than ${dotRatingMinYears} years ago.`;
      }
      return ruleText;
    case AppetiteCheckRule.AppetiteCheckRuleCompanyAddress:
      if (rule.details.companyAddressState) {
        const state = rule.details.companyAddressState;
        ruleText = `We currently don't support operations domiciled in ${state}.`;
      }
      return ruleText;
    case AppetiteCheckRule.AppetiteCheckRuleYearsInOperation:
      if (rule.details.yearsInOperation) {
        ruleText = 'The insured has been in operation for less than a year.';
      }
      return ruleText;
    case AppetiteCheckRule.AppetiteCheckRuleLapsesInOperation:
      if (rule.details.lapsesInOperation) {
        ruleText =
          'The insured has had multiple and/or long involuntary revocations of authority.';
      }
      return ruleText;
    case AppetiteCheckRule.AppetiteCheckRuleCarrierLoyalty:
      if (
        rule.details.numCarriers &&
        rule.details.eligibleYears &&
        rule.details.authorityType
      ) {
        const numCarriers = rule.details.numCarriers;
        const eligibleYears = rule.details.eligibleYears;
        ruleText = `The company has had ${numCarriers} insurance carriers in the last ${eligibleYears} years.`;
      }
      return ruleText;
    default:
      return ruleText;
  }
};

export const getDriversSummary = (drivers: Array<DriverListRecord> = []) => {
  const summary = {
    total: drivers.length,
    '18-30': 0,
    '31-50': 0,
    '51+': 0,
  };
  const today = new Date();
  drivers.forEach((record: DriverListRecord) => {
    if (!record.dateOfBirth) return;

    const diff = differenceInYears(today, parseISO(record.dateOfBirth));

    if (diff >= 18 && diff <= 30) {
      summary['18-30'] += 1;
    } else if (diff >= 31 && diff <= 50) {
      summary['31-50'] += 1;
    } else {
      summary['51+'] += 1;
    }
  });

  return summary;
};

export const getEquipmentSummary = (
  equipments: Array<EquipmentListRecord> = [],
) => {
  const summary = {
    totalUnits: equipments.length,
    totalValue: 0,
  };

  equipments.forEach((record: EquipmentListRecord) => {
    if (!record.statedValue) return;

    summary.totalValue += +record.statedValue;
  });

  return summary;
};

const PaymentOptionLabels = {
  [PaymentOption.PaymentOptionPaidInFull]: 'Paid in full',
  [PaymentOption.PaymentOptionMonthlyReporter]: 'Monthly Reporter',
};
export const getPaymentOptionLabel = (option: PaymentOption): string => {
  return PaymentOptionLabels[option] ?? option;
};

export const isEndorsementCoverage = (coverage: CoverageType) => {
  switch (coverage) {
    case CoverageType.CoverageUiia:
      return true;
    case CoverageType.CoverageBlanketAdditional:
      return true;
    case CoverageType.CoverageBlanketWaiverOfSubrogation:
      return true;
    case CoverageType.CoverageGlBlanketWaiverOfSubrogation:
      return true;
    case CoverageType.CoverageGlBlanketAdditional:
      return true;
    case CoverageType.CoverageMtcBlanketAdditional:
      return true;
    case CoverageType.CoverageMtcBlanketWaiverOfSubrogation:
      return true;
    case CoverageType.CoverageBroadenedPollution:
      return true;
    default:
      return false;
  }
};

export const isCombinedCoverage = (
  coverage: CoverageType,
  coveragesWithCombinedDeductibles: CoverageType[][],
) => {
  let isCombined = false;
  if (
    coveragesWithCombinedDeductibles &&
    coveragesWithCombinedDeductibles.length
  ) {
    coveragesWithCombinedDeductibles.forEach((record) => {
      if (
        coverage === CoverageType.CoverageAutoPhysicalDamage &&
        record.includes(CoverageType.CoverageAutoPhysicalDamage) &&
        record.includes(CoverageType.CoverageMotorTruckCargo)
      ) {
        isCombined = true;
      }
    });
  }

  return isCombined;
};

export const getLabelByCoverage = (
  coverage: CoverageType,
  coveragesWithCombinedDeductibles?: CoverageType[][],
) => {
  if (
    coverage === CoverageType.CoverageAutoPhysicalDamage &&
    coveragesWithCombinedDeductibles &&
    isCombinedCoverage(
      CoverageType.CoverageAutoPhysicalDamage,
      coveragesWithCombinedDeductibles,
    )
  ) {
    return `${getIndicationOptionsFeatureLabel(
      CoverageType.CoverageAutoPhysicalDamage,
    )} + ${getIndicationOptionsFeatureLabel(
      CoverageType.CoverageMotorTruckCargo,
    )}`;
  }

  return getIndicationOptionsFeatureLabel(coverage);
};
