export const getLoadingSteps = ({
  isPricelessIndication,
}: {
  isPricelessIndication: boolean;
}) => [
  'Pulling FMCSA inspections & crashes',
  'Pulling BASIC scores',
  'Pulling Company history',
  'Processing Loss summary',
  isPricelessIndication ? 'Generating packages' : 'Generating indication',
];
