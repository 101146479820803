// TELEMATICS CONNECTION PAGE EVENTS
export const PERMISSION_VIEW_TELEMATICS = 'permission_view_telematics';
export const LOADING_VIEW_TELEMATICS = 'loading_view_telematics';
export const LOGIN_VIEW_TELEMATICS = 'login_view_telematics';
export const SUCCESS_VIEW_TELEMATICS = 'success_view_telematics';

export const CONTINUE_CLICK_ON_TELEMATICS_PERMISSION_MODAL_INFO =
  'continue_click_telematics_permission_modal_info';
export const CONTINUE_CLICK_ON_TELEMATICS_LOGIN_PAGE =
  'continue_click_telematics_login_page';
export const CONTINUE_CLICK_ON_TELEMATICS_LOADING_PAGE =
  'continue_click_telematics_lOADING_page';

// INDICATION EVENTS
export const INDICATION_PAGE_VIEW = 'indication_page_view';
export const INDICATION_PAGE_DEDUCTIBLE_CHANGE =
  'indication_page_deductible_change';
export const INDICATION_PAGE_LIMIT_CHANGE = 'indication_page_limit_change';

export const INDICATION_SELECT = 'indication_select';
export const INDICATION_PAGE_PROCEED = 'indication_page_proceed';

export const INDICATION_PAGE_INDICATION_RANGE_SHOW =
  'indication_page_indication_range_show';

// ADDITIONAL INFO PAGE EVENTS
export const ADDITIONAL_INFO_PAGE_VIEW = 'additional_info_page_view';
export const ADDITION_INFO_PAGE_CREATE_LINK_CLICK =
  'additional_info_page_create_link_click';

export const TELEMATICS_CONNECTION_MODAL_OPEN =
  'telematics_connection_modal_open';
export const TELEMATICS_CONNECTION_MODAL_CLOSE =
  'telematics_connection_modal_close';

export const TELEMATICS_LINK_CREATE = 'telematics_link_create';

export const TELEMATICS_LINK_SENT = 'telematics_link_sent';

// CLASSES AND COMMODITIES PAGE EVENTS
export const CLASSES_AND_COMMODITIES_PAGE_VIEW =
  'classes_and_commodities_page_view';
export const CLASSES_AND_COMMODITIES_PAGE_PROCEED =
  'classes_and_commodities_page_proceed';
export const CLASSES_AND_COMMODITIES_PAGE_OPERATING_CLASS_DISTRIBUTION_CHANGE =
  'classes_and_commodities_page_operating_class_distribution_change';

// OPERATIONS FORM
export const OPERATIONS_PAGE_VIEW = 'operations_page_view';
export const OPERATIONS_PAGE_PROCEED = 'operations_page_proceed';

// LOSS SUMMARY PAGE EVENTS
export const LOSS_SUMMARY_PAGE_VIEW = 'loss_summary_page_view';
export const LOSS_SUMMARY_PAGE_PROCEED = 'loss_summary_page_proceed';

// insuredDetails events
export const INSURED_DETAILS_CONTINUE_CLICK = 'insured_details_continue_click';
export const INSURED_DETAILS_APPLICATION_CREATE =
  'insured_details_application_create';

// Appetite Screen
export const APPETITE_CHECK_MODAL_VIEW = 'appetite_check_modal_view';
export const UNDESIRABLE_OPERATIONS_MODAL_VIEW =
  'undesirable_operations_modal_view';
export const CLEARANCE_CONFLICT_MODAL_VIEW = 'clearance_conflict_modal_view';
export const APPETITE_ERROR_MODAL_VIEW = 'appetite_error_modal_view';
export const NF_APPETITE_ERROR_MODAL_VIEW = 'nf_appetite_error_modal_view';

// Review Page
export const REVIEW_PAGE_VIEW = 'review_page_view';

// Confirmation Page
export const CONFIRMATION_PAGE_VIEW = 'confirmation_page_view';

// Global telematics
export const CREATE_TELEMATICS_CONNECTION_LINK =
  'global_telematics_connection_link_create';
export const EDIT_TELEMATICS_CONNECTION_LINK =
  'global_telematics_connection_link_edit';
export const COPY_TELEMATICS_LINK = 'global_telematics_connection_link_copy';
export const COPY_TELEMATICS_LINK_SUCCESS =
  'telematics_connection_link_copy_success';
export const CREATE_TELEMATICS_CONNECTION_LINK_CLICK =
  'global_telematics_connection_link_create_click';

export const CREATE_TELEMATICS_CONNECTION_LINK_IN_MODAL_OPEN_STATE =
  'global_telematics_connection_link_in_modal_open_state';

// Appetite Lite
export const CHECK_APPETITE_LITE_CLICK = 'check_appetite_lite_click';
export const APPETITE_LITE_DOT_NUMBER_INPUT = 'appetite_lite_dot_number_input';
export const APPETITE_LITE_PAGE_VIEW = 'appetite_lite_page_view';
export const APPETITE_LITE_CONTINUE_CLICK = 'appetite_lite_continue_click';
export const APPETITE_LITE_WANT_MORE_DETAIL_CLICK =
  'appetite_lite_want_more_detail_click';
export const APPETITE_LITE_CREATE_APPLICATION_CLICK =
  'appetite_lite_create_application_click';
export const APPETITE_LITE_MODAL_CLOSE = 'appetite_lite_modal_close';
export const APPETITE_LITE_SCORE_FETCHED = 'appetite_lite_score_fetched';

// User Invite
export const USER_INVITE_NEW_USER_CLICK = 'user_invite_add_new_user_click';
export const USER_INVITE_SIGN_UP_PAGE_VIEW = 'user_invite_sign_up_page_view';
export const USER_INVITE_SIGN_UP_CLICK = 'user_invite_sign_up_click';
export const USER_INVITE_AGENCY_INFO_MODAL_VIEW =
  'user_invite_agency_info_modal_view';
export const USER_INVITE_AGENCY_INFO_MODAL_CLOSE_CLICK =
  'user_invite_agency_info_modal_close_click';
export const USER_INVITE_AGENCY_INFO_MODAL_SUBMIT_CLICK =
  'user_invite_agency_info_modal_submit_click';

// Home Page
export const FLEET_PROGRAM_TYPE_PAGE_VIEW = 'fleet_program_type_page_view';
export const NONFLEET_PROGRAM_TYPE_PAGE_VIEW =
  'nonfleet_program_type_page_view';
