import { APIProvider } from '@vis.gl/react-google-maps';
import { AutocompleteAddress, AutocompleteAddressProps } from './autocomplete';

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const AddressSearch = (props: AutocompleteAddressProps) => {
  return (
    <APIProvider apiKey={API_KEY}>
      <AutocompleteAddress {...props} />
    </APIProvider>
  );
};

export default AddressSearch;
