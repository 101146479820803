import { createColumnHelper } from '@tanstack/react-table';

type MiscellaneousData = {
  field: string;
  value: string;
};

const columnHelper = createColumnHelper<MiscellaneousData>();

export const MiscellaneousColumns = [
  columnHelper.accessor('field', {
    size: 200,
    header: 'Type',
    cell: ({ getValue }) => <p className="font-bold">{getValue()}</p>,
  }),
  columnHelper.accessor('value', {
    header: 'Description',
  }),
];
