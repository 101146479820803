import { Chip, getFormattedDate, IChipProps } from '@nirvana/ui-kit';
import { createColumnHelper } from '@tanstack/react-table';
import { words } from 'lodash';
import {
  GetInsuranceBundleListRecord,
  GetInsuranceBundleListRecordStatusEnum,
} from '@nirvana/api/endorsementapp';

function getChipProps(value: string): IChipProps {
  switch (value) {
    case GetInsuranceBundleListRecordStatusEnum.Active:
      return { color: 'success', label: 'Active' };
    case GetInsuranceBundleListRecordStatusEnum.Expired:
      return { color: 'default', label: 'Expired' };
    case GetInsuranceBundleListRecordStatusEnum.Cancelled:
      return { color: 'error', label: 'Canceled' };
    case GetInsuranceBundleListRecordStatusEnum.Renewed:
      return { color: 'warning', label: 'Renewed' };
    case GetInsuranceBundleListRecordStatusEnum.UpForRenewal:
      return { color: 'info', label: 'Up For Renewal' };
    case GetInsuranceBundleListRecordStatusEnum.PendingCancellation:
      return { color: 'gold', label: 'Pending Cancellation' };
    default:
      return {
        color: 'grey',
        label: words(value).join(' '),
      };
  }
}

const columnHelper = createColumnHelper<GetInsuranceBundleListRecord>();

export const getColumns = () => [
  columnHelper.accessor('policy', {
    size: 150,
    header: 'Policy Number',
    cell: ({ getValue }) => getValue()?.policyNumber,
  }),
  columnHelper.accessor('primaryInsured', {
    size: 200,
    header: 'Company Name',
    cell: ({ getValue }) => getValue()?.name,
  }),
  columnHelper.accessor('primaryInsured', {
    size: 120,
    header: 'DOT',
    cell: ({ getValue }) => getValue()?.DOTNumber,
  }),
  columnHelper.accessor('expirationDate', {
    size: 120,
    header: 'Expiration Date',
    cell: ({ getValue }) => {
      return getFormattedDate(getValue());
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: ({ getValue }) => <Chip {...getChipProps(getValue() as string)} />,
  }),
];
