import {
  CreateEndorsementRequestBody,
  PatchEndorsementRequestDriversRequestBody,
  PatchEndorsementRequestEquipmentsRequestBody,
} from '@nirvana/api/endorsementapp';
import { endorsementApiService } from 'src/helpers/endorsementApiService';

export const listEndorsements = async (policyId: string) => {
  const { data } = await endorsementApiService.getAllEndorsementRequests(
    policyId,
  );
  return data;
};

export async function createEndorsement({
  policyId,
  payload,
}: {
  policyId: string;
  payload: CreateEndorsementRequestBody;
}) {
  const { data } = await endorsementApiService.createEndorsementRequest(
    policyId,
    payload,
  );

  return data;
}

export const fetchEndorsementDetails = async ({
  policyId,
  endorsementId,
}: {
  policyId: string;
  endorsementId: string;
}) => {
  const { data } = await endorsementApiService.getEndorsementRequest(
    policyId,
    endorsementId,
  );
  return data;
};

export const fetchEndorsementRequestCoverages = async (
  policyId: string,
  endorsementRequestId: string,
) => {
  const { data } = await endorsementApiService.getEndorsementRequestCoverages(
    policyId,
    endorsementRequestId,
  );
  return data;
};

export const fetchEndorsementRequestDrivers = async (
  policyId: string,
  endorsementRequestId: string,
) => {
  const { data } = await endorsementApiService.getEndorsementRequestDrivers(
    policyId,
    endorsementRequestId,
  );
  return data;
};

export const fetchEndorsementRequestEquipments = async (
  policyId: string,
  endorsementRequestId: string,
) => {
  const { data } = await endorsementApiService.getEndorsementRequestEquipments(
    policyId,
    endorsementRequestId,
  );
  return data;
};

export const fetchEndorsementRequestMiscellaneous = async (
  policyId: string,
  endorsementRequestId: string,
) => {
  const { data } =
    await endorsementApiService.getEndorsementRequestMiscellaneous(
      policyId,
      endorsementRequestId,
    );
  return data;
};

export const updateDrivers = async ({
  policyId,
  endorsementId,
  payload,
}: {
  policyId: string;
  endorsementId: string;
  payload: PatchEndorsementRequestDriversRequestBody;
}) => {
  const { data } = await endorsementApiService.updateEndorsementRequestDrivers(
    policyId,
    endorsementId,
    payload,
  );

  return data;
};

export const updateEquipment = async ({
  policyId,
  endorsementId,
  payload,
}: {
  policyId: string;
  endorsementId: string;
  payload: PatchEndorsementRequestEquipmentsRequestBody;
}) => {
  const { data } =
    await endorsementApiService.updateEndorsementRequestEquipments(
      policyId,
      endorsementId,
      payload,
    );

  return data;
};

export const updateEffectiveDate = async ({
  policyId,
  endorsementId,
  effectiveDate,
}: {
  policyId: string;
  endorsementId: string;
  effectiveDate: string;
}) => {
  const { data } = await endorsementApiService.updateEndorsementRequest(
    policyId,
    endorsementId,
    {
      defaultEffectiveDate: effectiveDate,
    },
  );
  return data;
};

export const submitEndorsement = async ({
  policyId,
  endorsementId,
}: {
  policyId: string;
  endorsementId: string;
}) => {
  const { data } =
    await endorsementApiService.submitEndorsementRequestForReview(
      policyId,
      endorsementId,
    );
  return data;
};
