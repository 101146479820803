import { CoverageDetails, CoverageType } from '@nirvana/api/non-fleet';

const AncillaryCoveragesAPD = [
  CoverageType.CoverageTowingLaborAndStorage,
  CoverageType.CoverageNonOwnedTrailer,
];
const AncillaryCoveragesMTC = [
  CoverageType.CoverageReefer,
  CoverageType.CoverageDebrisRemoval,
  CoverageType.CoverageEarnedFreight,
  CoverageType.CoverageUnattendedTruck,
];
const AncillaryCoveragesOther = [
  CoverageType.CoverageTrailerInterchange,
  CoverageType.CoverageUiia,
];

/*
 * This function is used to get the ancillary coverages
 * @param mainCoverage - Main coverage
 * @param ancillaryCoverages - Ancillary coverages
 * @returns - Ancillary coverages
 * @example - getAncillaryCoverages('CoverageAutoPhysicalDamage', [{coverageType: 'CoverageTowingLaborAndStorage', isRequired: true}]) => [{coverageType: 'CoverageTowingLaborAndStorage', isRequired: true}]
 */
export const getAncillaryCoverages = (
  mainCoverage: CoverageType | 'AncillaryCoverages',
  ancillaryCoverages: CoverageDetails[],
) => {
  switch (mainCoverage) {
    case CoverageType.CoverageAutoPhysicalDamage:
      return ancillaryCoverages.filter(
        (coverage) =>
          coverage.isRequired &&
          AncillaryCoveragesAPD.includes(coverage.coverageType),
      );

    case CoverageType.CoverageMotorTruckCargo:
      return ancillaryCoverages.filter(
        (coverage) =>
          coverage.isRequired &&
          AncillaryCoveragesMTC.includes(coverage.coverageType),
      );

    default:
      return ancillaryCoverages.filter(
        (coverage) =>
          coverage.isRequired &&
          AncillaryCoveragesOther.includes(coverage.coverageType),
      );
  }
};

/*
 * This function is used to get the abbreviation of the coverage label
 * @param coverageLabel - Coverage label
 * @returns - Coverage abbreviation
 * @example - getCoverageAbbrevation('Auto Physical Damage') => 'APD'
 * @example - getCoverageAbbrevation('Motor Truck Cargo') => 'MTC'
 */
export const getCoverageAbbrevation = (coverageLabel: string) => {
  return coverageLabel.match(/\b([A-Z])/g)?.join('');
};
