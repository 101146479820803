import { Chip } from '@nirvana/ui-kit';
import NumberFormat from 'react-number-format';
import { useClipboard } from 'use-clipboard-copy';
import { Box, Link, Typography } from '@material-ui/core';
import { TableView } from 'src/components/table';
import { CoverageDetails, CoverageType } from '@nirvana/api/non-fleet';
import { application as ApplicationHelper } from 'src/helpers';

import { getIndicationPackageName } from '../../indication/utils';
import Section, {
  SectionCommonProps,
  SectionDataRenderParams,
} from './../section';

export interface IIndicationSectionProps extends SectionCommonProps {
  onEdit: (key: string) => void;
}

const IndicationView = ({
  onEdit,
  applicationData,
  ...rest
}: IIndicationSectionProps) => {
  const clipboard = useClipboard({
    copiedTimeout: 3000,
  });

  const handleCopyConnectionLink = (url: string) => {
    clipboard.copy(url);
  };

  return (
    <Section
      {...rest}
      applicationData={applicationData}
      title="Indication"
      onEdit={() => onEdit('indication')}
      data={[
        {
          key: 'deductibles',
          label: 'Deductibles',
          getValue: (data) => {
            return data.indicationForm?.coverages;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            const possibleCoverages = (
              Object.values(value) as CoverageDetails[]
            )
              ?.filter((record: CoverageDetails) => !!record.isRequired)
              ?.map((record: CoverageDetails) => record.coverageType);

            const orderedCoverages = ApplicationHelper.getOrderedCoverages(
              possibleCoverages,
            ).filter((record) => {
              if (
                applicationData.indicationForm?.coverages
                  ?.isAPDMTCDeductibleCombined &&
                record === CoverageType.CoverageMotorTruckCargo
              ) {
                return false;
              }

              return record !== CoverageType.CoverageAutoLiability;
            });

            return (
              <TableView
                headers={orderedCoverages
                  .concat('' as CoverageType) // Fake column to compensate for the filtered out AutoLiability above
                  .map((coverage: CoverageType) => ({
                    key: coverage,
                    content: (
                      <>
                        {ApplicationHelper.getLabelByCoverage(
                          coverage,
                          applicationData.indicationForm?.coverages
                            ?.isAPDMTCDeductibleCombined
                            ? [
                                [
                                  CoverageType.CoverageAutoPhysicalDamage,
                                  CoverageType.CoverageMotorTruckCargo,
                                ],
                              ]
                            : [],
                        )}
                      </>
                    ),
                    width: '25%',
                  }))}
                rows={[
                  {
                    columns: orderedCoverages?.map(
                      (coverage: CoverageType) => ({
                        content: (
                          <NumberFormat
                            value={value[coverage]?.deductible || 0}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        ),
                        width: '25%',
                      }),
                    ),
                  },
                ]}
              />
            );
          },
        },
        {
          key: 'limits',
          label: 'Limits',
          getValue: (data) => {
            return data.indicationForm?.coverages;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            const possibleCoverages = (
              Object.values(value) as CoverageDetails[]
            )
              ?.filter((record: CoverageDetails) => !!record.isRequired)
              ?.map((record: CoverageDetails) => record.coverageType);

            const orderedCoverages =
              ApplicationHelper.getOrderedCoverages(possibleCoverages);

            return (
              <TableView
                headers={orderedCoverages.map((coverage: CoverageType) => ({
                  key: coverage,
                  content: (
                    <>{ApplicationHelper.getLabelByCoverage(coverage)}</>
                  ),
                  width: '25%',
                }))}
                rows={[
                  {
                    columns: orderedCoverages?.map(
                      (coverage: CoverageType) => ({
                        content: (
                          <NumberFormat
                            value={value[coverage]?.limit || 0}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        ),
                        width: '25%',
                      }),
                    ),
                  },
                ]}
              />
            );
          },
        },
        {
          key: 'telematicsInfo',
          label: 'ELD/Telematics connection',
          getValue: (data) => {
            return data?.indicationForm?.telematicsInfo;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            return (
              <Box
                flexDirection="column"
                display="flex"
                alignItems="flex-start"
              >
                <Box mb={1}>
                  <Typography variant="body1" color="secondary">
                    {value?.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    color="textSecondary"
                  >
                    {value?.email}
                  </Typography>

                  <Link
                    style={{ cursor: 'pointer' }}
                    underline={clipboard.copied ? 'none' : 'hover'}
                    onClick={() => {
                      let link = value?.link;
                      const host = import.meta.env.VITE_WEB_URL;
                      // Adding this validation in order to support older format of link
                      // Removing it after we successfully migrate to link including Schema and host.
                      if (!link.includes(host)) {
                        link = `${host}/${link}`;
                      }
                      handleCopyConnectionLink(link);
                    }}
                  >
                    {clipboard.copied ? (
                      <Typography variant="caption" color="success.main">
                        Copied!
                      </Typography>
                    ) : (
                      <Typography variant="caption" color="primary">
                        Copy Connection Link
                      </Typography>
                    )}
                  </Link>
                </Box>
                <Box>
                  <Chip label="Link Generated" color="warning" />
                </Box>
              </Box>
            );
          },
        },
        {
          key: 'selectedIndication',
          label: 'Plan selected',
          getValue: (data) => {
            return data.indicationForm?.selectedIndication;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            if (!value) return null;

            return (
              <Box display="flex" alignItems="center">
                <Typography variant="body2">
                  {getIndicationPackageName(value?.packageName)} plan :&nbsp;
                </Typography>
                <Typography variant="body1">
                  <NumberFormat
                    value={value?.totalPremium}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </Typography>
              </Box>
            );
          },
        },
      ]}
    />
  );
};

export default IndicationView;
