import { InputPassword, InputWithLabel, storage } from '@nirvana/ui-kit';
import { SHARE_TOKEN_STORAGE_KEY } from 'src/constants';
import { useActivateUserMutation } from 'src/types/graphql-types';
import { getURLParameterByName } from 'src/helpers/utils';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form-v7';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/redux';
import { useAnalytics } from 'src/helpers/analytics';
import {
  USER_INVITE_SIGN_UP_CLICK,
  USER_INVITE_SIGN_UP_PAGE_VIEW,
} from 'src/features/telematics/events';
import { useEffect } from 'react';
import { signUp } from '../../actions';

export interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUp = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { capture } = useAnalytics();
  const shareId = getURLParameterByName('shareid');
  const email = getURLParameterByName('email');

  const [activateUserInvite] = useActivateUserMutation();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: email,
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onSubmit',
  });
  const password = watch('password');

  const onSubmit = (data: FormData) => {
    capture(USER_INVITE_SIGN_UP_CLICK, {
      email: data.email,
    });

    storage.set(SHARE_TOKEN_STORAGE_KEY, shareId);

    activateUserInvite({
      variables: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
      },
      onError: (error: any) => {
        const graphqlError = error?.graphQLErrors?.[0] as unknown as
          | string
          | undefined;
        if (graphqlError?.includes('already active')) {
          enqueueSnackbar(
            'This user already has an account, please click login, or contact support@nirvanatech.com for more questions',
            { variant: 'error' },
          );
          return;
        }
        if (graphqlError?.includes('Failed to update user')) {
          enqueueSnackbar(
            'This emails is already in use, please use a different email',
            { variant: 'error' },
          );
          return;
        }
        enqueueSnackbar(
          'There was an error while activating your account, please try again later',
          { variant: 'error' },
        );
      },
      onCompleted: (data: any) => {
        if (!data.activateUser) {
          enqueueSnackbar(
            'There was an error while activating your account, please try again later',
            { variant: 'error' },
          );
          return;
        }

        storage.remove(SHARE_TOKEN_STORAGE_KEY);

        dispatch(signUp(data.activateUser.sessionId))
          .then(() => {
            navigate('/applications/list', { replace: true });
          })
          .catch(() => {
            enqueueSnackbar(
              'There was an error while activating your account, please try again later',
              { variant: 'error' },
            );
          });
      },
    });
  };

  useEffect(() => {
    capture(USER_INVITE_SIGN_UP_PAGE_VIEW, { email: email });
  }, []);

  return (
    <div className="pt-4 pb-8 space-y-8">
      <p className="text-xl font-medium">Sign up</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-2">
          <div className="space-x-3">
            <InputWithLabel
              noMargin
              label="First Name"
              {...register('firstName', {
                required: 'Please enter first name',
              })}
              error={!!errors.firstName}
              helperText={(errors?.firstName?.message as string) ?? ' '}
            />

            <InputWithLabel
              noMargin
              label="Last Name"
              {...register('lastName', {
                required: 'Please enter last name',
              })}
              error={!!errors.lastName}
              helperText={(errors?.lastName?.message as string) ?? ' '}
            />
          </div>
          <div className="space-y-3">
            <InputWithLabel
              label="Email"
              formControlProps={{
                fullWidth: true,
              }}
              {...register('email')}
              readOnly
            />

            <InputPassword
              noMargin
              label="Password"
              formControlProps={{
                fullWidth: true,
              }}
              {...register('password', {
                required: 'Please enter a password',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long',
                },
                validate: {
                  noSpaces: (value) =>
                    !/\s/.test(value) || 'Password cannot contain spaces',
                },
              })}
              error={!!errors.password}
              helperText={(errors?.password?.message as string) ?? ' '}
              onContextMenu={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
            />

            <InputPassword
              noMargin
              label="Confirm Password"
              formControlProps={{
                fullWidth: true,
              }}
              {...register('confirmPassword', {
                required: 'Password confirmation is required',
                minLength: {
                  value: 8,
                  message: 'Password repeat doesn’t match',
                },
                validate: {
                  noSpaces: (value) =>
                    !/\s/.test(value) || 'Password cannot contain spaces',
                  matchPassword: (value) => {
                    return (
                      value === password || 'Password repeat does not match'
                    );
                  },
                },
              })}
              error={!!errors.confirmPassword}
              helperText={(errors?.confirmPassword?.message as string) ?? ' '}
              onContextMenu={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
            />
          </div>
        </div>
        <div className="mt-5 space-y-5">
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Create Account
          </Button>
          <div className="flex justify-center text-xs">
            <NavLink to="/login">Sign in</NavLink>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
