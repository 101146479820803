import NumberFormat from 'react-number-format';
import { Container, Grid, Typography } from '@material-ui/core';

import { Show, Switch, TableBasic } from '@nirvana/ui-kit';
import { CoverageType, NRBApp, QuoteOption } from '@nirvana/api/non-fleet';

import { application as ApplicationHelper } from 'src/helpers';
import { TypographySemiBold } from './styles';

interface QuoteDetailsProps {
  appDetails: NRBApp | undefined;
  data: QuoteOption | undefined;
}

const QuoteDetails = ({ data, appDetails }: QuoteDetailsProps) => {
  const mainCoverages = data?.coverages.filter((record) => {
    return (
      record.coverageType === CoverageType.CoverageAutoPhysicalDamage ||
      record.coverageType === CoverageType.CoverageMotorTruckCargo
    );
  });

  const numberOfUnits =
    appDetails?.equipmentsForm?.vehicles?.filter(
      ({ ownershipType }) => ownershipType !== 'Short term lease',
    ).length ?? 0;

  return (
    <Container maxWidth="md">
      <Grid container direction="column">
        {mainCoverages?.map((record) => (
          <Grid item key={`premium-coverage-${record.coverageType}`}>
            <Grid container alignItems="center" mb={1} spacing={2}>
              <Grid item>
                <Typography
                  variant="h6"
                  color="seconday"
                  fontWeight="fontWeightSemiBold"
                >
                  {ApplicationHelper.getIndicationOptionsFeatureLabel(
                    record.coverageType,
                  )}
                </Typography>
              </Grid>
            </Grid>

            <TableBasic
              headers={[
                {
                  key: 'projectedExposure',
                  content: <>Projected Exposure</>,
                  styles: { width: '20%' },
                },
                {
                  key: 'rate',
                  content: <>Rate</>,
                  styles: { width: '20%' },
                },
                {
                  key: 'deductible',
                  content: <>Deductible</>,
                  styles: { width: '25%' },
                },
                {
                  key: 'limit',
                  content: <>Limit</>,
                  styles: { width: '15%' },
                },
                {
                  key: 'linePremium',
                  content: <>Line Premium</>,
                  styles: {
                    width: '20%',
                    textAlign: 'right',
                  },
                },
              ]}
              rows={[
                {
                  columns: [
                    {
                      content: (
                        <Switch>
                          <Switch.Match
                            when={
                              record.coverageType ===
                              CoverageType.CoverageAutoPhysicalDamage
                            }
                          >
                            <NumberFormat
                              value={data?.TIV}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                              suffix=" TIV"
                            />
                          </Switch.Match>

                          <Switch.Match
                            when={
                              record.coverageType ===
                              CoverageType.CoverageMotorTruckCargo
                            }
                          >
                            <Show
                              when={numberOfUnits > 1}
                              fallback={`${numberOfUnits} Unit`}
                            >
                              {numberOfUnits} Units
                            </Show>
                          </Switch.Match>
                        </Switch>
                      ),
                    },
                    {
                      content: (
                        <Switch>
                          <Switch.Match
                            when={
                              record.coverageType ===
                              CoverageType.CoverageAutoPhysicalDamage
                            }
                          >
                            <NumberFormat
                              value={data?.TIVPercentage}
                              displayType={'text'}
                              thousandSeparator={true}
                              suffix="% per TIV"
                            />
                          </Switch.Match>

                          <Switch.Match
                            when={
                              record.coverageType ===
                              CoverageType.CoverageMotorTruckCargo
                            }
                          >
                            <NumberFormat
                              prefix="$"
                              suffix=" per Unit"
                              displayType={'text'}
                              value={record.premiumPerUnit}
                              thousandSeparator={true}
                            />
                          </Switch.Match>
                        </Switch>
                      ),
                    },
                    {
                      content: (
                        <NumberFormat
                          prefix="$"
                          value={record.deductible}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                      ),
                    },
                    {
                      content: (
                        <Show when={record.limit} fallback="N/A">
                          {(value) => (
                            <NumberFormat
                              prefix="$"
                              value={value}
                              displayType={'text'}
                              thousandSeparator={true}
                            />
                          )}
                        </Show>
                      ),
                    },
                    {
                      content: (
                        <TypographySemiBold>
                          <NumberFormat
                            prefix="$"
                            value={record.premium}
                            displayType={'text'}
                            thousandSeparator={true}
                          />
                        </TypographySemiBold>
                      ),
                      styles: {
                        textAlign: 'right',
                      },
                    },
                  ],
                },
              ]}
            />
          </Grid>
        ))}
        <Grid item>
          <TableBasic
            headers={[]}
            rows={[
              {
                columns: [
                  {
                    content: (
                      <Typography variant="h6" fontWeight="fontWeightBold">
                        Total Premium
                      </Typography>
                    ),
                  },
                  {
                    content: (
                      <Typography variant="h5" fontWeight="fontWeightBold">
                        <NumberFormat
                          value={data?.totalPremium}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </Typography>
                    ),
                    styles: {
                      textAlign: 'right',
                    },
                  },
                ],
              },
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuoteDetails;
