import * as React from 'react';
import { FormControlLabel } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';

import Checkbox from './checkbox';

interface ICheckBoxRowProps extends CheckboxProps {
  label: React.ReactNode;
  className?: any;
}

/**
 * Checkbox component with label.
 * Note: All props except label and className are passed down to MuiCheckbox.
 * @component
 *
 * @param {Object} props - Extends MUI Checkbox (Refer: https://next.material-ui.com/components/checkboxes)
 * @param {string} props.label - Checkbox label to describe usage.
 * @param {string} props.className - Additional classes passed on to checkbox container.
 *
 * @example
 * return <Checkbox label="Check something here" disabled />
 */
const CheckboxRow = ({ label, className, ...rest }: ICheckBoxRowProps) => {
  return (
    <FormControlLabel
      control={<Checkbox {...rest} color="primary" />}
      label={label}
      className={className}
    />
  );
};

export default CheckboxRow;
