import * as React from 'react';
import NumberFormat from 'react-number-format';
import {
  FormControl,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { CoverageType } from '@nirvana/api/quoting';

export interface IDeductibleSelector {
  coverage: CoverageType;
  disabled: boolean;
  getLabel: (coverage: CoverageType) => string;
  options: number[];
  value: number;
  onChange: (e: any) => void;
  isDefaultValue?: boolean;
}

const DeductibleSelector = ({
  coverage,
  disabled,
  getLabel,
  options,
  value,
  onChange,
}: IDeductibleSelector) => {
  return (
    <FormGroup>
      <FormHelperText
        sx={{ fontSize: 12, whiteSpace: 'nowrap', overflowX: 'hidden' }}
      >
        {getLabel(coverage)}
      </FormHelperText>
      <FormControl margin="dense">
        <Select
          name={coverage}
          value={value}
          disabled={disabled}
          renderValue={(value: any) => {
            return (
              <NumberFormat
                value={value}
                displayType={'text'}
                thousandSeparator={true}
                prefix="$"
              />
            );
          }}
          onChange={onChange}
          variant="outlined"
        >
          {options?.map((amount: number) => (
            <MenuItem value={amount} key={amount}>
              <NumberFormat
                value={amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormGroup>
  );
};

export default DeductibleSelector;
