import { Button, DialogActions } from '@material-ui/core';
import { Dialog } from '@nirvana/ui-kit';
import ClearanceConflictIcon from 'src/assets/icons/clearance-renewal-mid-term.svg';

interface MidtermPossibleRenewalProps {
  open: boolean;
  onClose: () => void;
  handleCreateApplication?: () => void;
  renewalData?: {
    effectiveDate?: string;
  };
  isSameAgency?: boolean;
}

const MidTermPolicyRenewal = ({
  onClose,
  open,
  renewalData,
  isSameAgency,
}: MidtermPossibleRenewalProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <div className="flex flex-col items-center space-y-8 p-6 w-[710px] h-[310px]">
        <img src={ClearanceConflictIcon} />
        <div className="space-y-6">
          <div className="text-xl text-center">
            This account already has an active policy with Nirvana
          </div>

          <div className="text-base font-medium text-center">
            <div className="mt-4 mb-4 ml-10 space-y-6 text-base font-normal text-left">
              <div className="mt-6">
                {isSameAgency
                  ? 'Our underwriters will review this account during the renewal period and will reach out to fill out the renewal application if desired.'
                  : `Please submit this account again for the upcoming renewal in ${renewalData?.effectiveDate}
                `}
              </div>
              <div className="font-normal text-l">
                <div>
                  Please reach out to{' '}
                  <a
                    className="underline text-primary-main"
                    href="mailto:support@nirvanatech.com"
                  >
                    support@nirvanatech.com
                  </a>{' '}
                  if you have any questions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogActions className="flex flex-row justify-end mt-4 space-x-5 py-9 items-right">
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          className="h-10"
        >
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MidTermPolicyRenewal;
