import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ApplicationApi,
  TelematicsApi,
  TSPConnectionResponse,
  TSPConnectionCompleteResponse,
  TSPRecord,
  TSPConnectionCompleteRequest,
  TelematicsFleetInfoResponse,
  TSPConnection,
  ProgramType,
} from '@nirvana/api/quoting';

import { storage } from 'src/helpers';
import { TELEMATICS_RESPONSES_STORAGE_KEY } from 'src/constants';
import {
  setBasicConsentInfo,
  setTSPProviders,
  setTSPProviderConnection,
  setStatus,
  setError,
  setTelematicsResponse,
  setTelematicsInfo,
} from '../slices';
export const fetchBasicApplicationInfo =
  (applicationId: string, programType?: ProgramType) =>
  async (dispatch: any) => {
    const apiService = new ApplicationApi();

    try {
      const { data } = await apiService.telematicsConsentInfoGet(
        applicationId,
        programType || ProgramType.ProgramTypeFleet,
      );

      if (data.isExpired) {
        dispatch(setStatus('expired'));
      } else if (data.isRevoked) {
        dispatch(setStatus('revoked'));
      } else {
        dispatch(setStatus('succeeded'));
      }

      // Dispatch action to store application information in global app state
      if (data.telematicsConsentInfo) {
        return dispatch(setBasicConsentInfo(data.telematicsConsentInfo));
      }
    } catch (ex) {
      dispatch(setStatus('failed'));
      throw ex;
    }
  };

export const fetchTSPProviders =
  (token: string, programType: ProgramType) => async (dispatch: any) => {
    try {
      dispatch(setStatus('loading'));

      const apiService = new TelematicsApi();

      const response: AxiosResponse<Array<TSPRecord>> =
        await apiService.telematicsProvidersGet(token, programType);
      const { data } = response;

      dispatch(setStatus('succeeded'));

      // Dispatch action to store providers in global app state
      return dispatch(setTSPProviders(data));
    } catch (ex) {
      dispatch(setStatus('failed'));
    }
  };

export const fetchTSPProviderConnection =
  (applicationId: string, payload: TSPConnection) => async (dispatch: any) => {
    try {
      dispatch(setStatus('loading'));

      const apiService = new TelematicsApi();

      const response: AxiosResponse<TSPConnectionResponse> =
        await apiService.applicationApplicationIDTelematicsConnectionPost(
          applicationId,
          payload,
        );
      const { data } = response;

      dispatch(setStatus('succeeded'));

      // Dispatch action to store provider connection in global app state
      return dispatch(
        setTSPProviderConnection({ provider: payload.tsp, connection: data }),
      );
    } catch (ex) {
      dispatch(setStatus('failed'));
    }
  };

export const completeTSPProviderConnection = createAsyncThunk(
  'telematics/connectionComplete',
  async (payload: TSPConnectionCompleteRequest, { dispatch }: any) => {
    try {
      dispatch(setStatus('loading'));

      const apiService = new TelematicsApi();

      const response: AxiosResponse<TSPConnectionCompleteResponse> =
        await apiService.telematicsConnectionCompletePost(payload);
      const { data } = response;

      dispatch(setStatus('succeeded'));
      dispatch(setTelematicsResponse(data));

      // Save this telematics response to our local storage dictionary.
      const state = new URLSearchParams(window.location.search).get('state');
      if (state) {
        const responsesByOAuth2State = JSON.parse(
          storage.get(TELEMATICS_RESPONSES_STORAGE_KEY) ?? '{}',
        );
        responsesByOAuth2State[state] = data;
        storage.set(
          TELEMATICS_RESPONSES_STORAGE_KEY,
          JSON.stringify(responsesByOAuth2State),
        );
      }

      dispatch(
        fetchBasicApplicationInfo(data.applicationID, payload.programType),
      );

      return data;
    } catch (err) {
      dispatch(setStatus('failed'));
      if (axios.isAxiosError(err) && err.response) {
        dispatch(setError({ message: err.response.data.message }));
      } else {
        dispatch(setError({ message: String(err) }));
      }
    }
  },
);

export const fetchTelematicsInfo = createAsyncThunk(
  'telematics/fetchInfo',
  async (
    { handleId, programType }: { handleId: string; programType?: ProgramType },
    { dispatch }: any,
  ) => {
    try {
      dispatch(setStatus('loading'));

      const apiService = new TelematicsApi();

      const response: AxiosResponse<TelematicsFleetInfoResponse> =
        await apiService.telematicsHandleIDFleetInfoGet(handleId, programType);
      const { data } = response;

      dispatch(setStatus('succeeded'));

      // Dispatch action to store provider connection in global app state
      dispatch(setTelematicsInfo(data));

      return data;
    } catch (ex) {
      dispatch(setStatus('succeeded'));
    }
  },
);
