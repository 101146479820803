import { SubCoverageChangeGroup } from '@nirvana/api/endorsementapp';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<SubCoverageChangeGroup>();

export const coverageColumns = [
  columnHelper.accessor('coverage', {
    header: 'Coverage Line',
    size: 200,
    cell: ({ getValue }) => {
      const coverage = getValue();
      return (
        <>
          <p className="font-bold mb-0.5">{coverage.name}</p>
          <p className="text-xs">{coverage.description}</p>
        </>
      );
    },
  }),
  columnHelper.accessor('coverage', {
    header: 'Limits',
    cell: ({ getValue }) => {
      const coverage = getValue();
      return <p className="mb-0.5">{coverage.limits || '-'}</p>;
    },
  }),
];
