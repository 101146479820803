import {
  Driver,
  DriverChange,
  DriverChangeChangeTypeEnum,
  Vehicle,
  VehicleChange,
  VehicleChangeChangeTypeEnum,
} from '@nirvana/api/endorsementapp';
import { IChipColors } from '@nirvana/ui-kit/src/components/chip/chip';
import { DriverData } from './constants/driver';
import { VehicleData } from './constants/vehicle';

export const getDescriptionByCoverage = (coverage: string) => {
  switch (coverage) {
    case 'Auto Liability':
      return 'Covers injuries/damage caused to others during accidents';

    case 'Auto Physical Damage':
      return 'Covers injuries/damage caused to others during accidents';

    case 'Motor Truck Cargo':
      return 'Protects cargo hauled damaged or lost during transit';

    case 'General Liability':
      return 'Covers injuries/damage caused to others unrelated to accidents';

    default:
      return '';
  }
};

export const getDriverListData = (data: DriverChange[]): DriverData[] => {
  return data.map((record) => {
    return {
      ...record.driver,
      changeType: record.changeType,
      oldValue: record.oldValue,
    };
  });
};

export const getDriverFromDriverData = (data: DriverData): Driver => {
  const driver = { ...data };
  delete driver.changeType;
  delete driver.oldValue;

  return driver;
};

const getChangeColor = {
  [DriverChangeChangeTypeEnum.Added]: 'success',
  [DriverChangeChangeTypeEnum.Updated]: 'warning',
  [DriverChangeChangeTypeEnum.Deleted]: 'error',
  [DriverChangeChangeTypeEnum.Unchanged]: 'info',
};

export const getChangeTypeMeta = (
  type?: DriverChangeChangeTypeEnum | VehicleChangeChangeTypeEnum,
) => {
  if (!type) return {};

  return { color: getChangeColor[type] as IChipColors };
};

export const getEquipmentListData = (data: VehicleChange[]): VehicleData[] => {
  return data.map((record) => {
    return {
      ...record.vehicle,
      changeType: record.changeType,
      oldValue: record.oldValue,
    };
  });
};

export const getEquipmentFromEquipmentData = (data: VehicleData): Vehicle => {
  const vehicle = { ...data };
  delete vehicle.changeType;
  delete vehicle.oldValue;

  return vehicle;
};
