import {
  createSearchParams,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';

const useNavigateParams = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (pathname: string) => {
    const path = {
      pathname,
      search: createSearchParams(searchParams).toString(),
    };
    navigate(path);
  };
};

export default useNavigateParams;
