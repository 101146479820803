/* tslint:disable */
/* eslint-disable */
/**
 * Nirvana Academy API
 * Nirvana Academy APIs
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 * Completed Workramp certification
 * @export
 * @interface AcademyCertification
 */
export interface AcademyCertification {
  /**
   *
   * @type {string}
   * @memberof AcademyCertification
   */
  awardedByName?: string;
  /**
   *
   * @type {string}
   * @memberof AcademyCertification
   */
  awardedByTitle?: string;
  /**
   *
   * @type {string}
   * @memberof AcademyCertification
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AcademyCertification
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof AcademyCertification
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AcademyCertification
   */
  name?: string;
}
/**
 * Workramp certification enrollment response from https://developers.workramp.com/reference/get-all-certifications-for-a-contact
 * @export
 * @interface AcademyCertificationEnrollment
 */
export interface AcademyCertificationEnrollment {
  /**
   *
   * @type {boolean}
   * @memberof AcademyCertificationEnrollment
   */
  awarded: boolean;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  awardedAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof AcademyCertificationEnrollment
   */
  canRecertify: boolean;
  /**
   *
   * @type {AcademyCertification}
   * @memberof AcademyCertificationEnrollment
   */
  certification?: AcademyCertification;
  /**
   *
   * @type {boolean}
   * @memberof AcademyCertificationEnrollment
   */
  completed: boolean;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  completedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  dueAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof AcademyCertificationEnrollment
   */
  expired: boolean;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  expiresAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof AcademyCertificationEnrollment
   */
  hasLearnerSeenAward: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AcademyCertificationEnrollment
   */
  hasLearnerSeenAwardAndRequiresGrading: boolean;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof AcademyCertificationEnrollment
   */
  isCompletedAndRequiresGrading: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AcademyCertificationEnrollment
   */
  isStarted: boolean;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  lastAccessedAt?: string;
  /**
   * Decimal percent the certification is completed.
   * @type {number}
   * @memberof AcademyCertificationEnrollment
   */
  progressPercentage: number;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  publicURL?: string;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  shortID?: string;
  /**
   *
   * @type {number}
   * @memberof AcademyCertificationEnrollment
   */
  timeSpentCache?: number;
  /**
   *
   * @type {string}
   * @memberof AcademyCertificationEnrollment
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface AcademyUserCertificationsResponse
 */
export interface AcademyUserCertificationsResponse {
  /**
   * List of workramp academy certifications for the user.
   * @type {Array<AcademyCertificationEnrollment>}
   * @memberof AcademyUserCertificationsResponse
   */
  certifications: Array<AcademyCertificationEnrollment>;
}

/**
 * AcademyApi - axios parameter creator
 * @export
 */
export const AcademyApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get workramp academy certifications for the requested user. A special userID param of \'me\' may be used to return certifications for the authenticated user.
     * @param {string} userID Either a user UUID or the string \&#39;me\&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAcademyUserCertifications: async (
      userID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userID' is not null or undefined
      assertParamExists('getAcademyUserCertifications', 'userID', userID);
      const localVarPath = `/academy/users/{userID}/certifications`.replace(
        `{${'userID'}}`,
        encodeURIComponent(String(userID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AcademyApi - functional programming interface
 * @export
 */
export const AcademyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AcademyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get workramp academy certifications for the requested user. A special userID param of \'me\' may be used to return certifications for the authenticated user.
     * @param {string} userID Either a user UUID or the string \&#39;me\&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAcademyUserCertifications(
      userID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AcademyUserCertificationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAcademyUserCertifications(
          userID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AcademyApi - factory interface
 * @export
 */
export const AcademyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AcademyApiFp(configuration);
  return {
    /**
     *
     * @summary Get workramp academy certifications for the requested user. A special userID param of \'me\' may be used to return certifications for the authenticated user.
     * @param {string} userID Either a user UUID or the string \&#39;me\&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAcademyUserCertifications(
      userID: string,
      options?: any,
    ): AxiosPromise<AcademyUserCertificationsResponse> {
      return localVarFp
        .getAcademyUserCertifications(userID, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AcademyApi - object-oriented interface
 * @export
 * @class AcademyApi
 * @extends {BaseAPI}
 */
export class AcademyApi extends BaseAPI {
  /**
   *
   * @summary Get workramp academy certifications for the requested user. A special userID param of \'me\' may be used to return certifications for the authenticated user.
   * @param {string} userID Either a user UUID or the string \&#39;me\&#39;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AcademyApi
   */
  public getAcademyUserCertifications(
    userID: string,
    options?: AxiosRequestConfig,
  ) {
    return AcademyApiFp(this.configuration)
      .getAcademyUserCertifications(userID, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
