import { BusinessStructure } from '@nirvana/api/non-fleet';

export const BusinessStructureOptions = [
  {
    label: 'Sole Proprietor',
    value: BusinessStructure.BusinessStructureSoleProprietor,
  },
  {
    label: 'Partnership',
    value: BusinessStructure.BusinessStructurePartnership,
  },
  {
    label: 'Corporation',
    value: BusinessStructure.BusinessStructureCorporation,
  },
  {
    label: 'LLC',
    value: BusinessStructure.BusinessStructureLlc,
  },
];
