import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import type { LoginRequest } from '@nirvana/api/auth';
import { LoginRequestSourceEnum } from '@nirvana/api/auth';
import { EMAIL_REGEX } from '@nirvana/ui-kit';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import IconEye from 'src/assets/icons/eye.svg';
import Button from 'src/components/button';
import { InputWithLabel } from 'src/components/input';
import Page from 'src/components/page';
import { AppDispatch, RootState } from 'src/redux';

import { login } from '../../actions';
import RedirectHoc from './Redirect';

const useStyles = makeStyles((theme: Theme) => ({
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  formControlButton: {
    marginTop: theme.spacing(6),
  },
  forgotPasswordGrid: {
    paddingTop: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
}));

/**
 * Login form that accepts username and password and submits to the API.
 * Corresponds to Login (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A12200&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const Login = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    (location.state?.from?.pathname || '/applications/list') + location.search;
  const [error, setError] = React.useState<boolean>(false);
  const [passwordFieldType, setPasswordFieldType] = React.useState<
    'text' | 'password'
  >('password');

  const cookies = document.cookie?.split(';');
  const utmCookies = cookies
    ?.filter((cookie) => {
      return cookie.includes('utm_');
    })
    .map((cookie) => cookie.trim());

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm<LoginRequest>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handlePasswordTypeToggle = () => {
    if (passwordFieldType === 'text') {
      setPasswordFieldType('password');
    } else {
      setPasswordFieldType('text');
    }
  };

  const onSubmit = (data: LoginRequest) => {
    const apiData = { ...data };

    // Get cookies from utmParams
    if (document.cookie) {
      // Add utmParams to data
      if (utmCookies?.length) {
        apiData.utmTags = {};
        apiData.source = LoginRequestSourceEnum.Agent;

        utmCookies?.forEach((cookie) => {
          const [key, value] = cookie?.split('=');
          if (key && apiData.utmTags) {
            apiData.utmTags[key] = value;
          }
        });
      }
    }

    dispatch(login(apiData))
      .then(() => {
        // Clear cookies from utmParams
        utmCookies.forEach((cookie) => {
          const [key] = cookie?.split('=');
          document.cookie = `${key}=; Domain=nirvanatech.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        });

        // Redirect to Applications
        navigate(from, { replace: true });
      })
      .catch(() => {
        // Show errors
        setError(true);
      });
  };

  const loading = useSelector((state: RootState) => state.auth.isLoading);

  return (
    <RedirectHoc>
      <Page title="Nirvana Insurance">
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
              fontWeight="fontWeightBold"
            >
              Login
            </Typography>
            <FormHelperText error>
              {error ? 'Invalid credentials, please check and try again' : ' '}
            </FormHelperText>
          </Grid>
          <Grid item>
            <>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.loginForm}
              >
                <InputWithLabel
                  label="Email ID"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputLabelProps={{ color: 'primary' }}
                  placeholder="Email"
                  type="email"
                  name="email"
                  fullWidth
                  inputRef={register({
                    required: true,
                    pattern: EMAIL_REGEX,
                  })}
                  error={!!errors.email}
                />
                <InputWithLabel
                  label="Password"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputLabelProps={{ color: 'primary' }}
                  placeholder="Password"
                  name="password"
                  type={passwordFieldType}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={handlePasswordTypeToggle}
                      >
                        <img src={IconEye} alt="Toggle Password Visibility" />
                      </IconButton>
                    </InputAdornment>
                  }
                  inputRef={register({
                    required: true,
                    setValueAs: (value: string) => value.trim(),
                  })}
                  error={!!errors.password}
                />
                <FormControl className={classes.formControlButton} fullWidth>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValid || loading}
                    startIcon={
                      loading && (
                        <CircularProgress className="text-white" size={18} />
                      )
                    }
                  >
                    {loading ? 'Signing in...' : 'Login'}
                  </Button>
                </FormControl>
              </form>
            </>
            <Grid item className={classes.forgotPasswordGrid}>
              <NavLink to="/forgot-password">
                <Typography variant="caption">Forgot password?</Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </Page>
    </RedirectHoc>
  );
};

export default Login;
