import { HiX } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import { Branding, BrandingTypes, Dialog } from '@nirvana/ui-kit';
import { applicationSelector } from 'src/features/application/slices';

type EmailPreviewProps = {
  open: boolean;
  onClose: () => void;
};

export default function EmailPreview({ open, onClose }: EmailPreviewProps) {
  const { activeApplication } = useSelector(applicationSelector);

  const summary = activeApplication?.summary;
  const connectionURL = `${import.meta.env.VITE_WEB_URL}/${
    activeApplication?.telematicsInfo?.link
  }`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: 'bg-primary-extraLight' }}
    >
      <div className="relative w-full px-12">
        <IconButton
          size="small"
          onClick={onClose}
          className="absolute top-0 right-0"
        >
          <HiX />
        </IconButton>

        <div className="flex items-center justify-center mb-4">
          <Branding brandingType={BrandingTypes.Icon} />
        </div>

        <div className="w-full max-w-lg px-10 py-6 mx-auto mb-5 bg-white rounded-md shadow text-text-primary">
          <h1 className="mb-5 text-xl font-bold text-center">
            Action Required: Connect telematics for {summary?.companyName}
          </h1>

          <p className="mb-6">
            {summary?.producerName} has started an application with Nirvana
            Insurance for {summary?.companyName} - {summary?.dotNumber}. You
            must authorize a connection to your telematics provider to complete
            your application and receive a quote.
          </p>

          <div className="flex items-center justify-center mb-6">
            <Button variant="contained">Connect Telematics</Button>
          </div>

          <p className="mb-4">
            You can also copy and paste this link into your browser:
          </p>

          <a className="mb-4 text-primary-main">{connectionURL}</a>

          <p className="mt-4">
            <span className="text-primary-main">Learn more</span> about how
            Nirvana uses and protects your telematics data.
          </p>
        </div>

        <div className="max-w-md mb-4">
          <p className="text-xs text-center text-text-hint">
            If you have any questions or trouble entering the information, just
            reply to this email. We&apos;re happy to help!
          </p>
        </div>
      </div>
    </Dialog>
  );
}
