import { CoverageType } from '@nirvana/api/non-fleet';

export const classItems = [
  [
    'Ambulances & other medical transport',
    'Carnival / circus operations',
    'Emergency vehicles',
    'Light delivery vehicles',
    'Logging operations',
  ],
  [
    'Municipal fleets',
    'Personnel van pools',
    'Private passenger vehicles',
    'Truck brokers',
  ],
];

export const commoditiesItems = {
  [CoverageType.CoverageAutoPhysicalDamage]: [
    'Coal',
    'Cotton',
    'Salvage / Scrap Metal operations including scrap cars / trucks',
    'Live animals',
    'Oversized / overweight loads or those requiring a spotter',
  ],
  [CoverageType.CoverageMotorTruckCargo]: [
    'Alcohol, Electronics, Seafood or Tobacco (>50% of exposure, combined or individually)',
    'Autos',
    'Eggs (higher than 10%)',
    'Fine Arts',
    'Hanging Meat',
    'High fashion clothing',
    'Household Goods',
    'Money, Currency and Bullion Owned Goods',
    'Oversized loads or those requiring a spotter',
    'Owned Goods',
    'Perfume',
    'Precious stones, jewelry or other similar valuable',
  ],
  common: [
    'Any product where the DOT requires a $5,000,000 limit of liability',
    'Arms, ammunition, dynamite, nitroglycerine & other highly explosive materials',
    'Boats and other watercraft',
    'Buildings, including mobile homes',
    'Cannabis',
    'Gasoline & related petroleum products (e.g. LPG/Propane)',
    'Hazmat',
    'Mobile cranes or similar equipment',
    'Salvage / scrap metal including vehicles',
  ],
};

export const getCommoditiesItems = (coverages: CoverageType[] = []) => {
  let items = commoditiesItems.common;

  coverages.forEach((coverage) => {
    if (commoditiesItems[coverage]) {
      items = [...items, ...commoditiesItems[coverage]];
    }
  });

  return items;
};
