import { useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  Grid,
  Box,
  FormHelperText,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { Show } from '@nirvana/ui-kit';
import {
  AdmittedCommodityConstant,
  AdmittedCommodityWithLabel,
} from '@nirvana/api/non-fleet';

import { useAppConstantsContext } from 'src/features/admitted/hooks/useConstants';
import { AutoComplete } from 'src/components/autoComplete';
import CommoditiesHalued from './commoditiesHauled';

const Commodities = ({ mtcEnabled }: { mtcEnabled: boolean }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { data } = useAppConstantsContext();

  const commoditiesList = useMemo(() => {
    const modifyCommodities = Object.entries(
      data?.commoditiesByCategory ?? {},
    ).reduce(
      (acc, [key, list]) => ({
        ...acc,
        [key]: list.sort((a, b) => {
          if (a.label.startsWith('Other')) return 1;
          if (b.label.startsWith('Other')) return -1;
          return a.label.localeCompare(b.label);
        }),
      }),
      {} as Record<string, AdmittedCommodityWithLabel[]>,
    );

    return Object.entries(modifyCommodities ?? {}).reduce(
      (acc, [key, list]) => [
        ...acc,
        ...list.map((item) => ({ ...item, category: key })),
      ],
      [] as AdmittedCommodityConstant[],
    );
  }, [data?.commoditiesByCategory]);

  return (
    <>
      <Show when={!mtcEnabled}>
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={5}>
            <Box paddingTop="10px">
              <InputLabel htmlFor="producer">Primary Commodity</InputLabel>
              <FormHelperText style={{ width: 288 }}>
                Please enter the primary commodity hauled
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <FormHelperText>Commodity</FormHelperText>
              <Controller
                control={control}
                defaultValue=""
                name="operationsForm.primaryCommodityLabel"
                rules={{ required: 'Select primary commodity' }}
                render={({ field: { value, onChange } }) => (
                  <AutoComplete
                    value={value}
                    placeholder="Select"
                    list={commoditiesList}
                    focusPlaceholder="Start typing to search..."
                    autoCompleteProps={{
                      classes: { listbox: 'space-y-4' },
                      groupBy: (option) => option.category,
                      renderGroup: (params) => (
                        <li key={params.key}>
                          <div className="px-4 text-xs text-text-hint">
                            {params.group}
                          </div>
                          <ul className="text-sm">{params.children}</ul>
                        </li>
                      ),
                      onChange: (_, newValue, reason) => {
                        if (reason === 'clear') {
                          setValue('operationsForm.primaryCategory', '');
                          setValue('operationsForm.primaryCommodity', '');
                          onChange('');
                        } else {
                          const { category, value, label } = newValue;
                          setValue('operationsForm.primaryCategory', category);
                          setValue('operationsForm.primaryCommodity', value);
                          onChange(label);
                        }
                      },
                    }}
                  />
                )}
              />
              {(errors.operationsForm as any)?.primaryCommodity?.message && (
                <FormHelperText error>
                  {(errors.operationsForm as any)?.primaryCommodity?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Show>

      <Show when={mtcEnabled}>
        <CommoditiesHalued commoditiesList={commoditiesList} />
      </Show>
    </>
  );
};

export default Commodities;
