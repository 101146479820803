import { CoverageType } from '@nirvana/api/quoting';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from '@material-ui/core';

import { application as ApplicationHelper } from 'src/helpers';

import CoverageFields from './coverageFields';
import { DateRange } from './utils';
import { TableContainer, TableHeading } from './styles';

export interface SummaryBlockCoverageFields {
  coverage: CoverageType;
  coverageIndex: number;
  dateRanges: DateRange[];
  disabledPredicate?: (rangeIndex: number) => boolean;
}

const SummaryFieldsBlock = ({
  coverage,
  coverageIndex,
  dateRanges,
  disabledPredicate,
}: SummaryBlockCoverageFields) => {
  const { control } = useFormContext();

  return (
    <>
      <TableHeading>
        <Controller
          control={control}
          name={`lossRunSummaryForm[${coverageIndex}].coverageType`}
          defaultValue={coverage}
          render={() => (
            <Typography variant="body1" color="texrPrimary" gutterBottom>
              {`${ApplicationHelper.getIndicationOptionsFeatureLabel(
                coverage,
              )} Summary`}
            </Typography>
          )}
        />
      </TableHeading>
      <TableContainer bgcolor={'grey.50'}>
        <CoverageFields
          disabledPredicate={disabledPredicate}
          coverageIndex={coverageIndex}
          dateRanges={dateRanges}
        />
      </TableContainer>
    </>
  );
};

export default SummaryFieldsBlock;
