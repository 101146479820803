import { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { Controller, useFormContext, useFormState } from 'react-hook-form-v7';
import { Checkbox, constants, Show } from '@nirvana/ui-kit';
import { NRBApp } from '@nirvana/api/non-fleet';

const { usStates } = constants;

const MailingAddress = () => {
  const { control, getValues, register, setValue, watch } = useFormContext();
  const { dirtyFields } = useFormState<NRBApp>({ control });
  const fmcsaDataPresent = watch('fmcsaDataPresent');
  const [isAddressSame, setIsAddressSame] = useState(!fmcsaDataPresent);

  useEffect(() => {
    setIsAddressSame(!fmcsaDataPresent);
  }, [fmcsaDataPresent]);

  const mailingAddressDirty =
    dirtyFields?.operationsForm?.companyInfo?.mailingAddress;

  useEffect(() => {
    if (fmcsaDataPresent && mailingAddressDirty) {
      setIsAddressSame(false);
    }
  }, [mailingAddressDirty, fmcsaDataPresent]);

  return (
    <>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="company-name">Mailing Address</InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Please specify the address where the insured should receive policy
              related documents
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={7} container flexDirection="column" spacing={2}>
          <Grid item>
            <FormControl>
              <Checkbox
                checked={isAddressSame}
                name="sameAsPhysicalAddress"
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked && fmcsaDataPresent) {
                    setValue(
                      'operationsForm.companyInfo.mailingAddress',
                      getValues('operationsForm.companyInfo.address'),
                    );
                  }
                  setIsAddressSame(checked);
                }}
              >
                Same as Physical Address registered with FMCSA
              </Checkbox>
            </FormControl>
          </Grid>

          <Show when={!isAddressSame || fmcsaDataPresent}>
            <Grid item>
              <FormControl style={{ width: 288 }}>
                <FormHelperText>Street</FormHelperText>
                <OutlinedInput
                  id="street"
                  placeholder="Enter street name"
                  {...register(
                    'operationsForm.companyInfo.mailingAddress.street',
                  )}
                  sx={{ maxWidth: 240 }}
                />
              </FormControl>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <FormControl>
                  <FormHelperText>City</FormHelperText>
                  <OutlinedInput
                    id="city"
                    placeholder="eg. 'Austin'"
                    {...register(
                      'operationsForm.companyInfo.mailingAddress.city',
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <FormHelperText>State</FormHelperText>
                  <Controller
                    control={control}
                    defaultValue="state"
                    name="operationsForm.companyInfo.mailingAddress.state"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        size="small"
                        displayEmpty
                        value={value}
                        id="state-select"
                        variant="outlined"
                        onChange={onChange}
                        renderValue={(value) =>
                          value || <span className="text-text-hint">AA</span>
                        }
                      >
                        {usStates.map((state) => (
                          <MenuItem key={state.code} value={state.code}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <FormHelperText>Zip code</FormHelperText>
                  <OutlinedInput
                    id="city"
                    placeholder="eg. '12345'"
                    {...register(
                      'operationsForm.companyInfo.mailingAddress.zip',
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Show>
        </Grid>
      </Grid>
    </>
  );
};

export default MailingAddress;
