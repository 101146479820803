import { Button, FormHelperText, Grid, Typography } from '@material-ui/core';
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from '@material-ui/icons';
import { HorizontalStepper } from '@nirvana/ui-kit';
import { useMutation, useQuery } from '@tanstack/react-query';
import { formatISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import {
  fetchEndorsementDetails,
  submitEndorsement,
  updateEffectiveDate,
} from '../../queries/endorsement';
import { fetchPolicyDetail } from '../../queries/policy';
import EndorsementSidebar from './sidebar';
import { usePageInfo } from './utils';

const EndorsementLayout = () => {
  const { policyId = '', endorsementId = '' } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const pageInfo = usePageInfo();
  const [effectiveDate, setEffectiveDate] = useState<Date | undefined>();
  const [effectiveDateError, setEffectiveDateError] = useState<
    string | undefined
  >();

  const { data } = useQuery(
    ['endorsement-details', policyId, endorsementId],
    () => fetchEndorsementDetails({ policyId, endorsementId }),
  );

  const { data: policyDetails } = useQuery(['policy-detail', policyId], () =>
    fetchPolicyDetail(policyId),
  );

  const { mutate: mutateEffectiveDate } = useMutation(updateEffectiveDate);

  const { mutate } = useMutation(submitEndorsement, {
    onSuccess: () => {
      navigate(`/policies/${policyId}/endorsement/${endorsementId}/success`);
    },
  });

  const steps = useMemo(() => {
    return [
      {
        key: 'selectChanges',
        label: <>Start</>,
      },
      {
        key: 'enterDetails',
        label: <>Make Changes</>,
      },
      {
        key: 'review',
        label: <>Review</>,
      },
    ];
  }, []);

  const handleEffectiveDateChange = (date?: Date) => {
    setEffectiveDate(date);
    setEffectiveDateError(undefined);

    if (date) {
      mutateEffectiveDate({
        policyId,
        endorsementId,
        effectiveDate:
          formatISO(date, { representation: 'date' }) + 'T00:00:00Z',
      });
    }
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    navigate(-1);
  };

  const handleSubmit = () => {
    if (!effectiveDate) {
      setEffectiveDateError('Effective date is required');
      return;
    }

    mutate({
      policyId,
      endorsementId,
    });
  };

  return (
    <div className="relative flex flex-col flex-1">
      <div className="flex flex-1">
        <div className="flex-1 p-10">
          <Grid container alignItems="center">
            <Grid item flexGrow={1} className="mb-8">
              <Typography
                variant="h4"
                fontWeight="fontWeightBold"
                color="textPrimary"
                gutterBottom
              >
                {pageInfo.title}
              </Typography>
              <FormHelperText>{pageInfo.description}</FormHelperText>
            </Grid>

            <Grid item flexGrow={1}>
              <HorizontalStepper
                steps={steps}
                activeStep={activeStep}
                onChange={() => null}
              />
            </Grid>
          </Grid>
          <div>
            <Outlet />
          </div>
        </div>
        <div className="w-[256px] bg-white px-6 py-10">
          <EndorsementSidebar
            endorsementDetails={data}
            policyDetails={policyDetails}
            effectiveDate={effectiveDate}
            onEffectiveDateChange={(e) => handleEffectiveDateChange(e)}
            effectiveDateError={effectiveDateError}
          />
        </div>
      </div>
      <div
        className="sticky bottom-0 z-10 px-8 py-2 bg-white"
        style={{ boxShadow: '0px -2px 4px 0px rgba(128, 145, 196, 0.13' }}
      >
        <div className="flex justify-between item-center">
          <Button
            type="button"
            onClick={() => {
              navigate(`/policies/${policyId}/endorsement/${endorsementId}`);
            }}
            size="small"
          >
            Cancel
          </Button>

          <div className="space-x-2">
            <Button
              variant="outlined"
              type="button"
              startIcon={<ArrowBackIosRounded />}
              onClick={handlePrevious}
              size="small"
            >
              Previous
            </Button>
            <Button
              variant="contained"
              type="button"
              endIcon={<ArrowForwardIosRounded />}
              onClick={handleSubmit}
              size="small"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndorsementLayout;
