import * as React from 'react';
import {
  Grid,
  Skeleton,
  FormHelperText,
  InputLabel,
  GridSize,
} from '@material-ui/core';

interface DeductiblesSkeletonProps {
  spacingGridLeft?: GridSize;
  spacingGridRight?: GridSize;
}

const DeductiblesSkeleton = ({
  spacingGridLeft,
  spacingGridRight,
}: DeductiblesSkeletonProps) => {
  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={spacingGridLeft || 5}>
        <InputLabel>
          <Skeleton animation="pulse" width={150} />
        </InputLabel>
        <FormHelperText>
          <Skeleton animation="pulse" />
        </FormHelperText>
      </Grid>
      <Grid
        item
        xs={spacingGridRight || 7}
        container
        flexDirection={'column'}
        spacing={3}
      >
        <Grid item container alignItems="center">
          <Skeleton animation="pulse" width={16} height={24} />
          <InputLabel sx={{ marginLeft: 1 }}>
            <Skeleton animation="pulse" width={350} />
          </InputLabel>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <FormHelperText>
              <Skeleton animation="pulse" width={120} />
            </FormHelperText>
            <Skeleton animation="pulse" width={200} height={60} />
          </Grid>
          <Grid item xs={6}>
            <FormHelperText>
              <Skeleton animation="pulse" width={120} />
            </FormHelperText>
            <Skeleton animation="pulse" width={200} height={60} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeductiblesSkeleton;
