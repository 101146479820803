import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useMutation } from '@tanstack/react-query';
import { FocusEvent, Fragment, ReactNode, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form-v7';
import { eventSubscriber, eventUnsubscriber } from 'src/helpers/eventEmitter';

import {
  CoverageType,
  VehicleDetails,
  VehicleType,
} from '@nirvana/api/non-fleet';
import { InputNumeric, Show, Tooltip } from '@nirvana/ui-kit';

import DeleteIcon from 'src/assets/icons/delete.svg';
import IconExclamation from 'src/assets/icons/exclamation-warning.svg';
import IconInfo from 'src/assets/icons/info-warning.svg';
import IconPlus from 'src/assets/icons/plus-primary.svg';
import FileUploadIcon from 'src/assets/icons/upload-primary.svg';
import Button from 'src/components/button';
import { InputWithoutLabel as OutlinedInput } from 'src/components/input';
import { useApplicationDetailsContext } from 'src/features/admitted/hooks/useApplicationDetails';
import { useAppConstantsContext } from 'src/features/admitted/hooks/useConstants';
import {
  decodeVehicleVINs,
  fetchVehicleInfo,
} from 'src/features/admitted/queries/application';

import {
  getWeightClassLabel,
  getWeightClassOptions,
} from '../constants/equipment';
import FlatFileUploader from './flatFileUploader';
import { useStyles } from './styles';

const defaultValues = {
  vin: '',
  vehicleType: '',
  year: '',
  make: '',
  model: '',
  weightClass: '',
  statedValue: null,
  ownershipType: '',
  parkingLocationZIP: '',
};

const Equipment = () => {
  const classes = useStyles();
  const {
    register,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const vehiclesValues = watch('equipmentsForm.vehicles');
  const { data } = useAppConstantsContext();
  const { applicationDetails } = useApplicationDetailsContext();
  const coverages =
    applicationDetails?.admitted?.operationsForm?.coverages
      ?.filter((record) => {
        return record.isRequired;
      })
      .map((record) => record.coverageType) ?? [];

  const formErrors = (errors?.equipmentsForm as any)?.vehicles;
  const [formWarnings, setFormWarnings] = useState<ReactNode[]>([]);
  const [hasLimitWarning, setHasLimitWarning] = useState(false);
  const [openFlatFileDialog, setFlatFileDialogOpen] = useState(false);
  const isStatedValueRequired = coverages.includes(
    CoverageType.CoverageAutoPhysicalDamage,
  );

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'equipmentsForm.vehicles',
    rules: {
      required: 'Please add at least one vehicle',
    },
    shouldUnregister: true,
  });

  const { mutate: fetchVehicleInfoMutate } = useMutation(fetchVehicleInfo);
  const { mutate: decodeVINMutate } = useMutation(decodeVehicleVINs);

  const handeFileUploadSuccess = (data?: VehicleDetails[]) => {
    if (!data?.length) {
      return;
    }

    const vins = data?.map(({ vin }) => vin).join(',') ?? '';

    const formData = [...data];
    if (!isStatedValueRequired) {
      // Remove stated value if APD is not required
      formData.forEach((record) => {
        record.statedValue = 0;
      });
    }

    setValue('equipmentsForm.vehicles', formData);

    fetchVehicleInfoMutate(
      { vins },
      {
        onSuccess: ({ data: vechileData }) => {
          if (vechileData?.length) {
            const newFields = vechileData.map(
              ({ typeAdmitted: type, modelYear, ...rest }, index) => ({
                vehicleType: type,
                year: +modelYear,
                ...rest,
                statedValue: data?.[index]?.statedValue ?? '',
                vin: data?.[index]?.vin ?? '',
              }),
            );

            setValue('equipmentsForm.vehicles', newFields);
          }
        },
      },
    );
  };

  const handleVINBlur = async (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const vinValue = e.target.value.trim();
    const keyPrefix = `equipmentsForm.vehicles.${index}`;

    // If VIN is empty, clear the associated warnings and do nothing
    if (!vinValue) {
      const newWarnings = [...formWarnings];
      delete newWarnings[index];
      setFormWarnings(newWarnings);
      return;
    }

    const vinArray = [vinValue];

    // Call the VIN decoding API only if VIN is provided
    decodeVINMutate(vinArray, {
      onSuccess: (vinDecodingData) => {
        const vinResponse = vinDecodingData.find(
          (item) => item.vin === vinValue,
        );

        if (vinResponse?.hasDecodingError) {
          // Show "Invalid VIN" only if the API returns an error
          const newWarnings = [...formWarnings];
          newWarnings[index] = (
            <Box
              display="flex"
              alignItems="center"
              className="flex items-center text-warning-main"
            >
              <img
                src={IconExclamation}
                alt="Warning"
                className="w-3 h-3 mr-1"
              />
              <span className="text-xs">Invalid VIN</span>
            </Box>
          );
          setFormWarnings(newWarnings);
          return;
        }

        // If VIN is valid, clear any VIN-specific warning
        const newWarnings = [...formWarnings];
        delete newWarnings[index]; // Clear warning for this VIN
        setFormWarnings(newWarnings);

        // Fetch the vehicle info after VIN is successfully decoded
        fetchVehicleInfoMutate(
          { vins: vinValue },
          {
            onSuccess: ({ data }) => {
              if (data?.length) {
                const {
                  typeAdmitted: type,
                  modelYear,
                  make,
                  model,
                  weightClass,
                } = data[0];

                setValue(
                  `${keyPrefix}.vehicleType`,
                  type === VehicleType.VehicleTypeUnknown ? '' : type,
                );
                setValue(`${keyPrefix}.year`, +modelYear);
                setValue(`${keyPrefix}.make`, make);
                setValue(`${keyPrefix}.model`, model);

                if (type !== VehicleType.VehicleTypeUnknown) {
                  setValue(`${keyPrefix}.weightClass`, weightClass);
                }
              }
            },
          },
        );
      },
    });
  };

  useEffect(() => {
    // Listen for the 'invalidVins' event using the new eventSubscriber function
    const handleInvalidVins = (invalidVins: string[]) => {
      const newWarnings = { ...formWarnings };

      // Loop through the form's vehicles and check if each VIN is invalid
      vehiclesValues.forEach((vehicle: VehicleDetails, index: number) => {
        if (invalidVins.includes(vehicle.vin)) {
          // Only add warning for the specific invalid VINs
          newWarnings[index] = (
            <Box
              key={vehicle.vin}
              display="flex"
              alignItems="center"
              className="flex items-center text-warning-main"
            >
              <img
                src={IconExclamation}
                alt="Warning"
                className="w-3 h-3 mr-1"
              />
              <span className="text-xs">Invalid VIN</span>
            </Box>
          );
        } else {
          // Clear any existing warnings for valid VINs
          delete newWarnings[index];
        }
      });

      setFormWarnings(newWarnings);
    };

    eventSubscriber('invalidVins', handleInvalidVins);

    return () => {
      eventUnsubscriber('invalidVins', handleInvalidVins);
    };
  }, [vehiclesValues, formWarnings]);

  useEffect(() => {
    if (!fields.length) {
      append(defaultValues, { focusIndex: 0 });
    }
  }, [fields, append]);

  return (
    <Box mt={4}>
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <Button
          variant="outlined"
          onClick={() => setFlatFileDialogOpen(true)}
          startIcon={<img src={FileUploadIcon} alt="File Upload" />}
        >
          Upload Equipment List
        </Button>
        <FlatFileUploader
          open={openFlatFileDialog}
          skipStatedValue={!isStatedValueRequired}
          onClose={() => setFlatFileDialogOpen(false)}
          onSuccess={handeFileUploadSuccess}
        />
      </Box>
      <Grid container direction="column" alignItems="center" spacing={4}>
        <Grid item xs={7} container flexDirection="column" spacing={2}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th style={{ width: '260px' }}>
                  <FormHelperText>VIN</FormHelperText>
                </th>
                <th style={{ width: '64px' }}>
                  <FormHelperText>Vehicle</FormHelperText>
                </th>
                <th style={{ width: '155px' }}>&nbsp;</th>
                <th style={{ width: '120px' }}>&nbsp;</th>
                <th style={{ maxWidth: '262px' }}>
                  <FormHelperText>Vehicle type</FormHelperText>
                </th>
                <th style={{ width: '155px' }}>
                  <FormHelperText>Vehicle class</FormHelperText>
                </th>
                <th style={{ width: '160px' }}>
                  <FormHelperText>GVW, lbs</FormHelperText>
                </th>

                {isStatedValueRequired && (
                  <th style={{ width: '112px' }}>
                    <FormHelperText>
                      Stated value&nbsp;
                      <Tooltip
                        title={
                          <div className="gap-2.5 ml-1 text-xs rounded p-2 text-left leading-[20px] tracking-[0.15px] h-28 w-44 font-normal">
                            Value of the equipment, including all permanently
                            attached equipment. Enter $0 to exclude the vehicle
                            from APD coverage
                          </div>
                        }
                        placement="top-end"
                        arrow
                      >
                        <InfoOutlined
                          className="text-info-main"
                          fontSize="small"
                        />
                      </Tooltip>
                    </FormHelperText>
                  </th>
                )}
                <th style={{ width: '40px' }}>&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              {fields.map((item, index) => {
                const hasAPDWarning = !!formWarnings[index];

                return (
                  <Fragment key={item.id}>
                    <tr>
                      <td>
                        <FormControl fullWidth>
                          <OutlinedInput
                            placeholder="Please enter VIN"
                            {...register(
                              `equipmentsForm.vehicles.${index}.vin`,
                              {
                                required: 'Please enter VIN',
                                validate: (value) => {
                                  if (!value) {
                                    return 'Please enter a VIN';
                                  }

                                  return true;
                                },
                                setValueAs: (value) => {
                                  return value?.trim();
                                },
                              },
                            )}
                            onBlur={(e) => handleVINBlur(e, index)}
                            error={!!formErrors?.[index]?.vin?.message}
                            sx={
                              hasAPDWarning
                                ? {
                                    '& > fieldset': {
                                      borderColor: 'warning.main',
                                    },
                                  }
                                : {}
                            }
                          />
                        </FormControl>
                      </td>

                      <td>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            defaultValue=""
                            name={`equipmentsForm.vehicles.${index}.year`}
                            rules={{
                              required: 'Please enter year',
                              validate: {
                                validYear: (value) => {
                                  if (value && (value < 1900 || value > 2100)) {
                                    return 'Please enter a valid year for the vehicle';
                                  }
                                },
                              },
                            }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputNumeric
                                  placeholder="Year"
                                  thousandSeparator={false}
                                  value={value}
                                  onChange={(e) =>
                                    onChange(
                                      e.target.value ? +e.target.value : '',
                                    )
                                  }
                                  error={!!formErrors?.[index]?.year?.message}
                                  sx={
                                    hasAPDWarning
                                      ? {
                                          '& > fieldset': {
                                            borderColor: 'warning.main',
                                          },
                                        }
                                      : {}
                                  }
                                />
                              );
                            }}
                          />
                        </FormControl>
                      </td>

                      <td>
                        <FormControl fullWidth>
                          <OutlinedInput
                            placeholder="Make"
                            {...register(
                              `equipmentsForm.vehicles.${index}.make`,
                              {
                                required: 'Please enter make',
                              },
                            )}
                            error={!!formErrors?.[index]?.make?.message}
                            sx={
                              hasAPDWarning
                                ? {
                                    '& > fieldset': {
                                      borderColor: 'warning.main',
                                    },
                                  }
                                : {}
                            }
                          />
                        </FormControl>
                      </td>

                      <td>
                        <FormControl fullWidth>
                          <OutlinedInput
                            placeholder="Model"
                            {...register(
                              `equipmentsForm.vehicles.${index}.model`,
                              {
                                required: 'Please enter model',
                              },
                            )}
                            error={!!formErrors?.[index]?.model?.message}
                            sx={
                              hasAPDWarning
                                ? {
                                    '& > fieldset': {
                                      borderColor: 'warning.main',
                                    },
                                  }
                                : {}
                            }
                          />
                        </FormControl>
                      </td>

                      <td>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            defaultValue=""
                            name={`equipmentsForm.vehicles.${index}.vehicleType`}
                            rules={{ required: 'Please select vehicle type' }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  displayEmpty
                                  variant="outlined"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                    setValue(
                                      `equipmentsForm.vehicles.${index}.vehicleClass`,
                                      '',
                                    );
                                    setValue(
                                      `equipmentsForm.vehicles.${index}.weightClass`,
                                      '',
                                    );
                                  }}
                                  error={
                                    !!formErrors?.[index]?.vehicleType?.message
                                  }
                                  sx={
                                    hasAPDWarning
                                      ? {
                                          '& > fieldset': {
                                            borderColor: 'warning.main',
                                          },
                                        }
                                      : {}
                                  }
                                >
                                  <MenuItem value="">
                                    <Typography color="text.hint">
                                      Select Type
                                    </Typography>
                                  </MenuItem>
                                  {data?.vehicleTypes?.map((record) => (
                                    <MenuItem
                                      value={record.value}
                                      key={record.value}
                                    >
                                      {record.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              );
                            }}
                          />
                        </FormControl>
                      </td>

                      <td>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            defaultValue=""
                            name={`equipmentsForm.vehicles.${index}.vehicleClass`}
                            rules={{
                              required: 'Please select vehicle class',
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState,
                            }) => {
                              return (
                                <Select
                                  displayEmpty
                                  variant="outlined"
                                  value={value}
                                  disabled={!vehiclesValues[index].vehicleType}
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                    setValue(
                                      `equipmentsForm.vehicles.${index}.weightClass`,
                                      '',
                                    );
                                  }}
                                  error={!!fieldState.error}
                                  sx={
                                    hasAPDWarning
                                      ? {
                                          '& > fieldset': {
                                            borderColor: 'warning.main',
                                          },
                                        }
                                      : {}
                                  }
                                >
                                  <MenuItem value="">
                                    <Typography color="text.hint">
                                      Select Class
                                    </Typography>
                                  </MenuItem>
                                  {(
                                    (vehiclesValues[index]?.vehicleType &&
                                      data?.vehicleClassByType?.[
                                        vehiclesValues[index].vehicleType
                                      ]) ||
                                    []
                                  ).map((record: any) => (
                                    <MenuItem
                                      value={record.value}
                                      key={record.value}
                                    >
                                      {record.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              );
                            }}
                          />
                        </FormControl>
                      </td>

                      <td>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            defaultValue=""
                            name={`equipmentsForm.vehicles.${index}.weightClass`}
                            rules={{ required: 'Please select weight class' }}
                            render={({
                              field: { onChange, value, ref },
                              fieldState: { error },
                            }) => (
                              <Select
                                displayEmpty
                                variant="outlined"
                                value={value}
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                inputRef={ref}
                                error={!!error}
                                sx={
                                  hasAPDWarning
                                    ? {
                                        '& > fieldset': {
                                          borderColor: 'warning.main',
                                        },
                                      }
                                    : {}
                                }
                                renderValue={getWeightClassLabel}
                              >
                                <MenuItem value="">
                                  <Typography color="text.hint">
                                    Select GVW
                                  </Typography>
                                </MenuItem>
                                {getWeightClassOptions(
                                  vehiclesValues[index].vehicleType,
                                  vehiclesValues[index].vehicleClass,
                                ).map((record) => (
                                  <MenuItem
                                    value={record.value}
                                    key={record.value}
                                  >
                                    <Box>
                                      {record.label}
                                      {!!record?.subtext && (
                                        <FormHelperText>
                                          {record.subtext}
                                        </FormHelperText>
                                      )}
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </td>

                      {isStatedValueRequired && (
                        <td>
                          <FormControl fullWidth>
                            <Controller
                              control={control}
                              defaultValue=""
                              name={`equipmentsForm.vehicles.${index}.statedValue`}
                              rules={{
                                required: 'Please enter stated value',
                                validate: {
                                  minValue: (value) => {
                                    // Value should be greather than or equal to 5000 or 0
                                    if (value && value < 5000) {
                                      return 'Please enter a valid stated amount. Minimum value is $5,000';
                                    }
                                  },
                                },
                              }}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <InputNumeric
                                    prefix="$"
                                    placeholder="e.g. $90,000"
                                    value={value}
                                    decimalScale={0}
                                    onChange={(e) => onChange(+e.target.value)}
                                    onBlur={() => {
                                      if (value === 0) {
                                        const newWarnings = [...formWarnings];
                                        newWarnings[index] =
                                          'Vehicle will not be covered for Auto Physical Damage';
                                        setFormWarnings(newWarnings);
                                      } else if (
                                        (vehiclesValues[index].vehicleType ===
                                          VehicleType.VehicleTypeTruck ||
                                          vehiclesValues[index].vehicleType ===
                                            VehicleType.VehicleTypeTractor) &&
                                        value > 250000
                                      ) {
                                        const newWarnings = [...formWarnings];
                                        newWarnings[index] = (
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            Higher than usual&nbsp;
                                            <Tooltip
                                              title={
                                                <p>
                                                  The stated value of
                                                  trucks/tractors usually does
                                                  not exceed 250k
                                                </p>
                                              }
                                            >
                                              <img
                                                src={IconInfo}
                                                alt="more info"
                                              />
                                            </Tooltip>
                                          </Box>
                                        );
                                        setFormWarnings(newWarnings);
                                        setHasLimitWarning(true);
                                      } else if (
                                        (vehiclesValues[index].vehicleType ===
                                          VehicleType.VehicleTypePickup ||
                                          vehiclesValues[index].vehicleType ===
                                            VehicleType.VehicleTypeTrailer) &&
                                        value > 100000
                                      ) {
                                        const newWarnings = [...formWarnings];
                                        newWarnings[index] = (
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            Higher than usual&nbsp;
                                            <Tooltip
                                              title={
                                                <p>
                                                  The stated value of
                                                  pickup/trailers usually does
                                                  not exceed 100k
                                                </p>
                                              }
                                            >
                                              <img
                                                src={IconInfo}
                                                alt="more info"
                                              />
                                            </Tooltip>
                                          </Box>
                                        );
                                        setFormWarnings(newWarnings);
                                        setHasLimitWarning(true);
                                      } else {
                                        const newWarnings = [...formWarnings];
                                        delete newWarnings[index];
                                        setFormWarnings(newWarnings);
                                        setHasLimitWarning(false);
                                      }
                                    }}
                                    error={
                                      !!formErrors?.[index]?.statedValue
                                        ?.message
                                    }
                                    sx={
                                      hasAPDWarning
                                        ? {
                                            '& > fieldset': {
                                              borderColor: 'warning.main',
                                            },
                                          }
                                        : {}
                                    }
                                  />
                                );
                              }}
                            />
                          </FormControl>
                        </td>
                      )}

                      <td>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (fields.length > 1) {
                              remove(index);
                            } else {
                              update(0, defaultValues);
                            }
                          }}
                        >
                          <img src={DeleteIcon} alt="Delete" />
                        </IconButton>
                      </td>
                    </tr>
                    {hasAPDWarning && (
                      <FormHelperText sx={{ pl: 2, color: 'warning.main' }}>
                        {formWarnings[index]}
                      </FormHelperText>
                    )}
                    {!!formErrors?.[index] && (
                      <FormHelperText error sx={{ pl: 2 }}>
                        {
                          formErrors?.[index]?.[
                            Object.keys(formErrors?.[index])[0]
                          ]?.message
                        }
                      </FormHelperText>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </Grid>
      </Grid>

      <Show when={fields.length > 0}>
        <Grid container my={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => append(defaultValues)}
              startIcon={<img src={IconPlus} alt="Add Driver" />}
            >
              Add Equipment
            </Button>
          </Grid>
        </Grid>
      </Show>

      <Snackbar
        open={hasLimitWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ mb: 8 }}
      >
        <Alert
          severity="warning"
          icon={<img src={IconExclamation} />}
          variant="outlined"
          sx={{ bgcolor: 'warning.extraLight' }}
        >
          <Typography color="warning.main">
            The stated values of {formWarnings.length} of the equipment seem
            higher than usual.
            <br />
            Please check highlighted values before you proceed
          </Typography>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Equipment;
