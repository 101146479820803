import {
  Button,
  Grid,
  GridProps,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import IconSubmitSuccess from 'src/assets/icons/submit-success.svg';
import React from 'react';
import { useAnalytics } from '../../../../helpers/analytics';
import { CONFIRMATION_PAGE_VIEW } from '../../../telematics/events';

const useStyles = makeStyles(() => ({
  iconCheck: {},
}));

interface CompleteProps {
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
  buttonUrl?: string;
  containerProps?: GridProps;
}

/**
 * Confirmation screen that is displayed on completion of application creation process.
 * Corresponds to Review Page (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A15431&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const Complete = ({
  title = 'Congratulations! Your application is successfully submitted',
  // subtitle = 'We will notify you when the quote is ready. It usually takes around 2 to 4 days to process applications.',
  buttonLabel = 'Back to Home',
  buttonUrl = '/applications/list',
  containerProps = {},
}: CompleteProps) => {
  const classes = useStyles();
  const { capture } = useAnalytics();

  React.useEffect(() => {
    capture(CONFIRMATION_PAGE_VIEW);
  }, []);

  return (
    <Grid
      container
      direction="column"
      spacing={6}
      alignItems="center"
      {...containerProps}
    >
      <Grid item>
        <img
          src={IconSubmitSuccess}
          className={classes.iconCheck}
          alt="Success"
        />
      </Grid>
      <Grid item>
        <Typography
          variant="h4"
          fontWeight="fontWeightBold"
          textAlign="center"
          maxWidth={700}
          mb={1}
        >
          {title}
        </Typography>
      </Grid>

      <Grid item>
        <NavLink to={buttonUrl} style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              textTransform: 'none',
            }}
          >
            {buttonLabel}
          </Button>
        </NavLink>
      </Grid>
    </Grid>
  );
};

export default Complete;
