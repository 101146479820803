import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { ApplicationDetail } from '@nirvana/api/quoting';
import { ApplicationHeader, HorizontalStepper, ITheme } from '@nirvana/ui-kit';

import IconStepper from 'src/assets/icons/stepper.svg';
import IconApplicationStatus from 'src/assets/icons/application-status.svg';

import { fetchApplicationDetails } from '../../queries/application';
import { ApplicationDetailsProvider } from '../../hooks/useApplicationDetails';
import Review from './review';

const useStyles = makeStyles((theme: ITheme) => ({
  card: {
    border: '1px solid #E6E7EF',
    boxShadow: '0px 4px 6px rgba(128, 145, 196, 0.16)',
  },
  cardHeader: {
    borderBottom: '1px solid #E6E7EF',
    backgroundColor: '#FBFCFE',
    padding: theme.spacing(2, 4),
  },
  cardHeaderContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ApplicationSubmitted = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { applicationId = '' } = useParams();

  const { data: applicationDetails } = useQuery({
    queryKey: ['application', applicationId],
    queryFn: () => fetchApplicationDetails(applicationId),
  });

  const steps = useMemo(
    () => [
      {
        key: 'statusSubmitted',
        label: 'Application submitted',
        // extra: () => '05/12/2022',
      },
      { key: 'statusUnderwriterReview', label: 'Underwriter review' },
      { key: 'statusQuoteFinalized', label: 'Quote finalized' },
    ],
    [],
  );

  const renderApplicationHeader = () => {
    return (
      <ApplicationHeader
        details={
          {
            summary: {
              shortID: applicationDetails?.shortID,
              companyName:
                applicationDetails?.nrb?.operationsForm?.companyInfo?.name,
              dotNumber:
                applicationDetails?.nrb?.operationsForm?.companyInfo?.dotNumber,
              underwriterName: 'Taylor Gosiaco',

              underwriterEmail: 'taylor@nirvanatech.com',
            },
          } as ApplicationDetail
        }
        elevation={false}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />
    );
  };

  return (
    <ApplicationDetailsProvider applicationId={applicationId}>
      {renderApplicationHeader()}
      <Container maxWidth="md">
        <Box py={3}>
          <Grid container direction="column" spacing={5}>
            <Grid item>
              <Card>
                <CardHeader
                  disableTypography
                  title={
                    <>
                      <img
                        src={IconApplicationStatus}
                        alt="Application Status Icon"
                      />
                      <Box px={3} flexGrow={1}>
                        <Typography variant="h5" color="textSecondary">
                          Application Status
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <img src={IconStepper} alt="Active Step Icon" />
                        <Typography variant="body1" color="text.hint" ml={1}>
                          Underwriter review
                        </Typography>
                      </Box>
                    </>
                  }
                  classes={{
                    root: classes.cardHeader,
                    content: classes.cardHeaderContent,
                  }}
                />
                <CardContent>
                  <Box p={2} pt={10}>
                    <HorizontalStepper
                      steps={steps}
                      activeStep={1}
                      onChange={() => null}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Review />
          </Grid>
        </Box>
      </Container>
    </ApplicationDetailsProvider>
  );
};

export default ApplicationSubmitted;
