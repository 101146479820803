import { useState } from 'react';
import clsx from 'clsx';
import { formatISO } from 'date-fns';
import {
  Box,
  Card,
  CardActionArea,
  Divider,
  FormHelperText,
  Grid,
  Link,
  makeStyles,
  Radio,
  styled,
  Typography,
} from '@material-ui/core';
import { AdmittedAppDriverDetails } from '@nirvana/api/non-fleet';
import { Dialog, ITheme } from '@nirvana/ui-kit';

import Button, { FlatFileUpload } from 'src/components/button';
import { date as DateHelper, usStatesHelper } from 'src/helpers';
import stateLicenseRegex from 'src/features/application/components/create/stateLicenseRegex.json';

import FullNameImage from 'src/assets/icons/full-name.svg';
import FirstAndLastNameImage from 'src/assets/icons/first-and-last.svg';

interface FlatFileDriverRecord extends AdmittedAppDriverDetails {
  fullName?: string;
}

const DialogContent = styled(Box)(({ theme }: { theme: ITheme }) => ({
  paddingBottom: theme.spacing(3),
  position: 'relative',
  overflow: 'hidden',
  borderBottom: '1px solid #E6E7EF',
  maxWidth: 650,
}));

const TypographyRegular = styled(Typography)(
  ({ theme }: { theme: ITheme }) => ({
    color: theme.palette.text.primary,
    ...theme.typography.body2,
  }),
);

const TypographyMedium = styled(Typography)(({ theme }: { theme: ITheme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightBold,
  display: 'inline-block',
}));

const TabRadio = styled(Radio)(({ theme }: { theme: ITheme }) => ({
  color: theme.palette.text.disabled,
  padding: 0,
  marginRight: theme.spacing(1),

  '&.Mui-checked': {
    color: theme.palette.text.primary,
  },
}));

const TabLabel = styled(Box)(({ theme }: { theme: ITheme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const DiskList = styled('ul')(({ theme }: { theme: ITheme }) => ({
  listStyleType: 'disc',
  paddingLeft: theme.spacing(2.5),
  maxWidth: '85%',
  lineHeight: 1.7,
}));

const SelectableCard = styled(Card)(() => ({
  border: '1px solid #DFE4F1',
  boxShadow: '4px 0px 8px rgba(51, 80, 161, 0.2)',
  borderRadius: 10,
  height: '100%',
}));

const useStyles = makeStyles((theme: ITheme) => ({
  selected: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
}));

type FlatFileUploaderProps = {
  onSuccess: (driversList?: AdmittedAppDriverDetails[]) => void;
  onClose: () => void;
  onStart: () => void;
  open: boolean;
};

const FlatFileUploader = ({
  onSuccess,
  onClose,
  onStart,
  open,
}: FlatFileUploaderProps) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<Number>();
  const [error, setError] = useState<string>('');
  const isDisabled = selectedTab === undefined;

  const handleStart = () => {
    if (isDisabled) {
      setError('Please select an upload option');
    } else {
      setSelectedTab(undefined);
      setError('');
      onStart();
    }
  };

  const handleClose = () => {
    setSelectedTab(undefined);
    setError('');
    onClose();
  };

  return (
    <Dialog
      title={
        <Typography variant="h5" color="textPrimary">
          Upload Drivers List
        </Typography>
      }
      open={open}
      maxWidth="md"
      onClose={handleClose}
      primaryAction={
        <Box onClick={handleStart}>
          <FlatFileUpload
            key={`drivers-list-${selectedTab}`}
            type="Drivers"
            title="Upload Drivers List"
            fields={[
              {
                label: 'CDL Number',
                alternates: [
                  'driver',
                  'license',
                  'License Number',
                  'Driver License',
                  'DL License',
                  'CDL Number',
                  'CDL License',
                ],
                key: 'licenseNumber',
                description: "Driver's license number",
                validators: [{ validate: 'required' }],
              },
              ...(selectedTab === 0
                ? [
                    {
                      label: 'First Name',
                      alternates: ['First Name'],
                      key: 'firstName',
                      validators: [{ validate: 'required' }],
                    },
                    {
                      label: 'Last Name',
                      alternates: ['Last Name'],
                      key: 'lastName',
                    },
                  ]
                : []),
              ...(selectedTab === 1
                ? [
                    {
                      label: 'Full Name',
                      alternates: ['Name', 'Driver', 'Drivers Name'],
                      key: 'fullName',
                      validators: [{ validate: 'required' }],
                    },
                  ]
                : []),
              {
                label: 'State',
                key: 'licenseState',
                alternates: [
                  'state',
                  'DL State',
                  'CDL State',
                  'License State',
                  'State License',
                  'Licenced Stated',
                  'st',
                  'St.',
                ],
                validators: [
                  {
                    validate: 'regex_matches',
                    error:
                      'License state is required. Please use two alphabet codes ("MN", "IN", "WI", etc)',
                    regex: usStatesHelper.usStateRegex(),
                  },
                ],
              },
              {
                label: 'Years of CDL Experience',
                key: 'yearsOfExp',
                alternates: [
                  'Experience',
                  'Exp Year',
                  'Years',
                  'Number of Years',
                  'Experience Years',
                  'CDL Experience',
                  'Yrs Exp',
                ],
                validators: [
                  {
                    validate: 'regex_matches',
                    error: 'Please enter a valid number',
                    regex: '^\\d+$',
                  },
                ],
              },
              {
                label: 'Date of birth',
                alternates: [
                  'DOB',
                  'Date of birth',
                  'Birth date',
                  'D.O.B.',
                  'Birthdate',
                ],
                key: 'dateOfBirth',
                validators: [
                  {
                    validate: 'regex_matches',
                    error: 'Not a valid date',
                    regex:
                      '^\\s*(\\d{4}|(0?[1-9]|1[012]))[-\\/]((0?[1-9]|1[012])|(0?[1-9]|[12][0-9]|3[01]))[-\\/](\\d{2}|\\d{4}|(0?[1-9]|[12][0-9]|3[01]))\\s*$',
                  },
                ],
              },
              {
                label: 'Date of hire',
                alternates: [
                  'DOH',
                  'Date of hire',
                  'hire date',
                  'D.O.H.',
                  'Hiredate',
                ],
                key: 'dateOfHire',
                validators: [
                  {
                    validate: 'regex_matches',
                    error: 'Not a valid date',
                    regex:
                      '^\\s*(\\d{4}|(0?[1-9]|1[012]))[-\\/]((0?[1-9]|1[012])|(0?[1-9]|[12][0-9]|3[01]))[-\\/](\\d{2}|\\d{4}|(0?[1-9]|[12][0-9]|3[01]))\\s*$',
                  },
                ],
              },
            ]}
            fieldHooks={{
              firstName: (values) => {
                return values.map(([item, index]) => {
                  const newItem = typeof item === 'string' ? item.trim() : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Removed extra whitespaces',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
              lastName: (values) => {
                return values.map(([item, index]) => {
                  const newItem = typeof item === 'string' ? item.trim() : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Removed extra whitespaces',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
              licenseNumber: (values) => {
                return values.map(([item, index]) => {
                  const newItem =
                    typeof item === 'string'
                      ? item.replace(/[^A-Za-z0-9]/g, '')
                      : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message:
                              'Removed invalid characters and whitespaces',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
              licenseState: (values) => {
                return values.map(([item, index]) => {
                  const newItem =
                    typeof item === 'string' ? item.toUpperCase() : '';

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Changed to uppercase',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
            }}
            value={{} as any}
            onChange={(results) => {
              if (results) {
                const driverList = {
                  drivers: results.validData.map(
                    (record: Partial<FlatFileDriverRecord>) => {
                      const newData = {
                        ...record,
                        licenseNumber: record?.licenseNumber?.replace(
                          /[^A-Za-z0-9]/g,
                          '',
                        ),
                      };

                      if (record?.dateOfBirth && record?.dateOfBirth?.trim()) {
                        newData.dateOfBirth = formatISO(
                          DateHelper.parseDate(
                            record?.dateOfBirth?.trim(),
                            new Date(2003),
                          ),
                          {
                            representation: 'date',
                          },
                        );
                      }

                      if (record?.dateOfHire && record?.dateOfHire?.trim()) {
                        newData.dateOfHire = formatISO(
                          DateHelper.parseDate(record?.dateOfHire?.trim()),
                          {
                            representation: 'date',
                          },
                        );
                      }

                      if (record?.licenseState) {
                        newData.licenseState =
                          record.licenseState.toUpperCase();
                      }

                      if (record.yearsOfExp) {
                        newData.yearsOfExp = Math.floor(
                          +record.yearsOfExp ?? 0,
                        );
                      }

                      if (newData?.fullName) {
                        delete newData.fullName;
                      }

                      return newData;
                    },
                  ),
                  flatfileMetadata: {
                    flatfileHandle: results.$meta.batchID,
                    fileMetadata: {
                      name: results.$meta.filename,
                    },
                  },
                };

                onSuccess(driverList.drivers);
              } else {
                onSuccess(undefined);
              }
            }}
            render={(importer, launch) => {
              if (importer) {
                importer.registerStepHook(
                  'review',
                  ({ headers_matched = [] }) => {
                    if (
                      headers_matched.find((v) => v.matched_key === 'fullName')
                    ) {
                      importer.addVirtualField(
                        {
                          label: 'First Name',
                          key: 'firstName',
                          description: 'Automatically generated from Full Name',
                        },
                        {
                          order: 0,
                          hideFields: ['fullName'],
                        },
                      );

                      importer.addVirtualField(
                        {
                          label: 'Last Name',
                          key: 'lastName',
                          description: 'Automatically generated from Full Name',
                        },
                        {
                          order: 1,
                        },
                      );
                    }
                    return true;
                  },
                );

                importer.registerRecordHook(
                  (record: any, index: number, mode: string) => {
                    const out: any = {};
                    const licenseRegex =
                      stateLicenseRegex[record.licenseState as string];
                    const driverLicenseNumber = record?.licenseNumber as string;

                    if (
                      licenseRegex &&
                      !driverLicenseNumber.match(new RegExp(licenseRegex.rule))
                    ) {
                      out.licenseNumber = {
                        info: [
                          {
                            message: 'not a valid license number',
                            level: 'error',
                          },
                        ],
                      };
                    }

                    if (record.fullName && mode === 'change') {
                      let firstSpaceIndex = (record.fullName || '')
                        .trim()
                        .indexOf(' ');

                      if (firstSpaceIndex === -1) {
                        firstSpaceIndex = record.fullName.length;
                      }

                      if (record.firstName && !record.lastName) {
                        out.lastName = {
                          value: record.fullName
                            .substring(firstSpaceIndex + 1)
                            .trim(),
                        };
                      }

                      if (record.lastName && !record.firstName) {
                        out.firstName = {
                          value: record.fullName
                            .substring(0, firstSpaceIndex)
                            .trim(),
                        };
                      }
                    }

                    if (record.fullName && mode === 'init') {
                      let firstSpaceIndex = (record.fullName || '')
                        .trim()
                        .indexOf(' ');

                      if (firstSpaceIndex === -1) {
                        firstSpaceIndex = record.fullName.length;
                      }

                      out.firstName = {
                        value: record.fullName
                          .substring(0, firstSpaceIndex)
                          .trim(),
                        info: [
                          {
                            message:
                              'This value has been created using Full Name',
                            level: 'info',
                          },
                        ],
                      };

                      out.lastName = {
                        value: record.fullName
                          .substring(firstSpaceIndex + 1)
                          .trim(),
                        info: [
                          {
                            message:
                              'This value has been created using Full Name',
                            level: 'info',
                          },
                        ],
                      };
                    }

                    return out;
                  },
                );
              }

              return (
                <Button variant="contained" onClick={launch}>
                  Proceed
                </Button>
              );
            }}
          />
        </Box>
      }
    >
      <DialogContent>
        <Typography variant="body1" mb={3}>
          Please upload the drivers list in <strong>CSV</strong> or{' '}
          <strong>Excel</strong> format.
        </Typography>
        <TypographyRegular mb={3} color="textPrimary">
          <TypographyMedium
            sx={{
              textDecoration: 'underline',
            }}
          >
            NOTE:{' '}
          </TypographyMedium>{' '}
          To ensure the upload works properly:
          <DiskList>
            <li>
              <TypographyRegular>
                Please make sure that dates of birth and dates of hire are valid
                dates
              </TypographyRegular>
            </li>
            <li>
              <TypographyRegular>
                Select <TypographyMedium>First Name</TypographyMedium> and{' '}
                <TypographyMedium>Last Name</TypographyMedium> {''} option if
                your drivers’ first and last names are currently in 2 different
                columns. Select <TypographyMedium>Full Name</TypographyMedium>{' '}
                option if the names are currently in the same column.
              </TypographyRegular>
            </li>
          </DiskList>
        </TypographyRegular>
        {!!error && (
          <FormHelperText error sx={{ mb: 0.75 }}>
            {error}
          </FormHelperText>
        )}
        <Grid container spacing={4} flexWrap="nowrap">
          <Grid item>
            <SelectableCard
              className={clsx({
                [classes.selected]: selectedTab === 0,
              })}
            >
              <CardActionArea
                onClick={() => {
                  setError('');
                  setSelectedTab(0);
                }}
              >
                <Grid container direction="column" p={2} spacing={2}>
                  <Grid item>
                    <TabLabel>
                      <TabRadio size="small" checked={selectedTab === 0} />
                      <Typography
                        variant="body1"
                        color={selectedTab === 0 ? 'text.primary' : 'text.hint'}
                      >
                        Use First and Last Name
                      </Typography>
                    </TabLabel>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <TypographyRegular>
                      First and Last Name template{' '}
                      <Link
                        variant="body1"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/Nirvana_nf_upload_template_first_last_name.xlsx"
                      >
                        download
                      </Link>
                    </TypographyRegular>
                  </Grid>

                  <Grid item>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={'center'}
                    >
                      <img src={FullNameImage} />
                    </Box>
                  </Grid>
                </Grid>
              </CardActionArea>
            </SelectableCard>
          </Grid>

          <Box display={'flex'} ml={4} mt={4}>
            <Divider orientation="vertical" flexItem />
          </Box>

          <Grid item>
            <SelectableCard
              className={clsx({
                [classes.selected]: selectedTab === 1,
              })}
            >
              <CardActionArea
                onClick={() => {
                  setError('');
                  setSelectedTab(1);
                }}
              >
                <Grid container direction="column" p={2} spacing={2}>
                  <Grid item>
                    <TabLabel>
                      <TabRadio size="small" checked={selectedTab === 1} />
                      <Typography
                        variant="body1"
                        color={selectedTab === 1 ? 'text.primary' : 'text.hint'}
                      >
                        Use Full Name
                      </Typography>
                    </TabLabel>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <TypographyRegular>
                      Full Name template{' '}
                      <Link
                        variant="body1"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/Nirvana_nf_upload_template_full_name.xlsx"
                      >
                        download
                      </Link>
                    </TypographyRegular>
                  </Grid>
                  <Grid item>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={'center'}
                    >
                      <img src={FirstAndLastNameImage} />
                    </Box>
                  </Grid>
                </Grid>
              </CardActionArea>
            </SelectableCard>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FlatFileUploader;
