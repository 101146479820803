import type { FocusEvent } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  IconButton,
  InputProps,
  OutlinedInput,
  Popper,
  styled,
} from '@material-ui/core';
import { CategoryWithLabel } from '@nirvana/api/non-fleet';
import { Show } from '@nirvana/ui-kit';
import { HiOutlineX } from 'react-icons/hi';

const placeholder = 'Select';
const focusPlaceholder = 'Start typing to search...';

export function getCommodityLabel(commodity: CategoryWithLabel) {
  if (commodity) {
    return commodity.label ?? 'Unknown';
  }
  return '';
}

const StyledPopper = styled(Popper)({
  minWidth: '16rem',
});

type CommodityComboboxProps = Omit<
  AutocompleteProps<CategoryWithLabel, boolean, boolean, boolean>,
  'renderInput' | 'options'
> & {
  list: CategoryWithLabel[];
  value: string;
  InputProps?: Partial<InputProps>;
  onRemove: () => void;
};

export default function CommodityCombobox({
  list,
  value = '',
  onRemove,
  InputProps = {},
  ...restProps
}: CommodityComboboxProps) {
  return (
    <Show
      when={!value}
      fallback={
        <div className="flex items-center justify-between w-full col-span-2 px-4 py-2 rounded-md text-text-primary bg-primary-extraLight">
          <span className="truncate" title={value}>
            {value}
          </span>{' '}
          <IconButton size="small" onClick={onRemove}>
            <HiOutlineX className="text-sm text-primary-light" />
          </IconButton>
        </div>
      }
    >
      <Autocomplete
        freeSolo
        openOnFocus
        blurOnSelect
        value={value}
        options={list}
        className="col-span-2"
        PopperComponent={StyledPopper}
        getOptionLabel={getCommodityLabel}
        renderInput={(params) => (
          <OutlinedInput
            {...params}
            {...params.InputProps}
            {...InputProps}
            type="text"
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              onFocus: (e: FocusEvent<HTMLInputElement>) => {
                if (params.inputProps.onFocus) {
                  params.inputProps.onFocus(e);
                }
                e.target.placeholder = focusPlaceholder;
              },
              onBlur: (e: FocusEvent<HTMLInputElement>) => {
                if (params.inputProps.onBlur) {
                  params.inputProps.onBlur(e);
                }
                e.target.placeholder = placeholder;
              },
            }}
          />
        )}
        {...restProps}
      />
    </Show>
  );
}
