import axios from 'axios';
import { storage } from '@nirvana/ui-kit';
import {
  Configuration,
  ApplicationApi,
  PolicySetApi,
} from '@nirvana/api/quoting';
import { AUTH_TOKEN_STORAGE_KEY } from 'src/constants';

const configOptions = new Configuration();
const apiService = new ApplicationApi(configOptions);
const policySetApiService = new PolicySetApi(configOptions);

axios.interceptors.request.use((config) => {
  config.headers.JSESSIONID = storage.get(AUTH_TOKEN_STORAGE_KEY);
  return config;
});

export { apiService as default, policySetApiService };
