import NumberFormat from 'react-number-format';
import { TableView } from 'src/components/table';
import Section from 'src/features/application/components/create/review/section';
import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';

const EquipmentsView = () => {
  const { applicationDetails } = useApplicationDetailsContext();
  const vehicles = applicationDetails?.nrb?.equipmentsForm?.vehicles;

  return (
    <Section
      onEdit={() => {}}
      title="Equipment"
      applicationData={{} as any}
      data={[
        {
          key: 'commoditiesHauled',
          renderValue: () => (
            <TableView
              headers={[
                { key: 'vin', content: 'VIN' },
                { key: 'vehicleType', content: 'Vehicle Type' },
                { key: 'vehicle', content: 'Vehicle' },
                { key: 'totalvalue', content: 'Total Value' },
                { key: 'ownershipType', content: 'Ownership type' },
              ]}
              rows={
                vehicles?.map((item) => ({
                  columns: [
                    { key: 'vin', content: item.vin },
                    { key: 'vehicleType', content: item.vehicleType },
                    {
                      key: 'vehicle',
                      content: `${item.year} ${item.make} ${item.model}`,
                    },
                    {
                      key: 'totalvalue',
                      content: (
                        <NumberFormat
                          prefix="$"
                          displayType="text"
                          thousandSeparator
                          value={item.statedValue}
                        />
                      ),
                    },
                    { key: 'ownershipType', content: item.ownershipType },
                  ],
                })) ?? []
              }
            />
          ),
        },
      ]}
    />
  );
};

export default EquipmentsView;
