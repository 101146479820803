import { Grid, Typography } from '@material-ui/core';

import IconClaims from 'src/assets/icons/claims-large.svg';

const IndicationMoreInfo = () => {
  return (
    <Grid container p={4} justifyContent="center">
      <Grid item textAlign="right" pr={4}>
        <img src={IconClaims} alt="More Info" style={{ height: '100px' }} />
      </Grid>
      <Grid item textAlign="left">
        <Typography
          variant="h4"
          color="textPrimary"
          fontWeight="fontWeightBold"
          mb={1}
        >
          We need a little more information to review your application
        </Typography>

        <Typography variant="body2" color="textSecondary">
          The quote will be generated after the application is reviewed. Our
          underwriter may reach out for additional questions.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IndicationMoreInfo;
