import { Dialog, Show } from '@nirvana/ui-kit';

import Button from 'src/components/button';

import { CircularProgress, DialogActions } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconSubmitSuccess from 'src/assets/icons/submit-success.svg';
import { useFormContext } from 'react-hook-form-v7';
interface ConflictErrorDialogProps {
  open: boolean;
  data: any;
  onClose: () => void;
  isNonFleet?: boolean;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const unsubmittedStates = [
  'AppStateUnsubmitted',
  'AppStateIncomplete',
  'AppStateIndicationeGenerated',
];

const DEFAULT_REDIRECT_TIME = 5;
const DEFAULT_SUBMITTED_REDIRECT_TIME = 15;

const approvedStates = ['AppStateUnderUWReview', 'AppStateApproved'];

const getErrorHeading = (data: any) => {
  if (unsubmittedStates.includes(data.existingApplicationStatus)) {
    return 'Continue your Application';
  } else {
    return 'You have already submitted';
  }
};

const getErrorMessage = (data: any, time: number) => {
  if (unsubmittedStates.includes(data.existingApplicationStatus)) {
    return (
      <div className="text-sm text-center w-[356px]">
        We found another application that matches the DOT and producer your
        provided. Continue below or wait to be automatically redirected in{' '}
        {time} seconds
      </div>
    );
  } else {
    return (
      <div className="text-sm text-center w-[356px]">
        You have already submitted an application that matches the DOT and
        producer you provided.
      </div>
    );
  }
};

const ConflictErrorDialog = ({
  data,
  open,
  onClose,
  isNonFleet,
  openDialog,
}: ConflictErrorDialogProps) => {
  const { reset } = useFormContext();
  const navigate = useNavigate();
  const [time, setTime] = useState(
    unsubmittedStates.includes(data.existingApplicationStatus)
      ? DEFAULT_REDIRECT_TIME
      : DEFAULT_SUBMITTED_REDIRECT_TIME,
  );
  function handleClose() {
    onClose();
  }

  const handleRedirect = () => {
    const baseUri = isNonFleet ? '/non-fleet/application' : '/applications';

    reset();

    if (
      data.isRenewal &&
      unsubmittedStates.includes(data.existingApplicationStatus)
    ) {
      navigate(`${baseUri}/${data.existingApplicationId}/renew`);
      return;
    }

    if (
      data.isRenewal &&
      approvedStates.includes(data.existingApplicationStatus)
    ) {
      navigate(`${baseUri}/${data.existingApplicationId}/submitted`);
      return;
    }

    if (unsubmittedStates.includes(data.existingApplicationStatus)) {
      navigate(`${baseUri}/${data.existingApplicationId}/create`);
      return;
    }

    if (approvedStates.includes(data.existingApplicationStatus)) {
      navigate(`${baseUri}/${data.existingApplicationId}/submitted`);
      return;
    }

    if (data.existingApplicationStatus === 'AppStateQuoteGenerated') {
      navigate(`${baseUri}/${data.existingApplicationId}/quote`);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (time === 1) {
        handleRedirect();
        clearInterval(interval);
      } else {
        setTime(time - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [time]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <div className="flex flex-col justify-around items-center space-y-6 w-[530px] h-[275px]">
        <div className="text-lg font-semibold">{getErrorHeading(data)}</div>

        <div>
          <Show
            when={!unsubmittedStates.includes(data.existingApplicationStatus)}
          >
            <img className="h-[134px] w-[140px]" src={IconSubmitSuccess} />
          </Show>

          <Show
            when={unsubmittedStates.includes(data.existingApplicationStatus)}
          >
            <CircularProgress />
          </Show>
        </div>
        <div className="text-sm text-center w-[356px]">
          {getErrorMessage(data, time)}
        </div>
      </div>
      <DialogActions className="flex flex-row items-center justify-between py-6">
        <div
          className="cursor-pointer text-primary-main"
          onClick={() => {
            reset();
            handleClose();
            openDialog(true);
          }}
        >
          Submit with a new DOT or producer
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={handleRedirect}
          className="h-10"
        >
          View Application Status
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConflictErrorDialog;
