import { Link } from '@material-ui/core';
import { Show } from '@nirvana/ui-kit';
// import { useMutation } from '@tanstack/react-query';
// import { useSnackbar } from 'notistack';
// import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';
// import CheckIcon from 'src/assets/icons/appetite-lite/check.svg?react';
// import { APPETITE_LITE_WANT_MORE_DETAIL_CLICK } from 'src/features/telematics/events';
// import { useAnalytics } from 'src/helpers/analytics';
import { RiskDetailsProps } from '../constants/types';
// import { postAppetiteLiteDetails } from '../queries/appetite-lite';
import { getRiskCount, getRiskIcon, getRiskSummaries } from '../utils';

// Temp commented out want more detail button, will be uncommented in future PR
const RiskDetails = ({ risk, score }: RiskDetailsProps) => {
  // const { dotNumber = '' } = useParams();
  // const [requestStatus, setRequestStatus] = useState<string | null>(null);
  // const { enqueueSnackbar } = useSnackbar();
  // const { capture } = useAnalytics();

  // const { mutate, isLoading } = useMutation(postAppetiteLiteDetails, {
  //   onSuccess: () => {
  //     setRequestStatus(
  //       'Request received! A report will be emailed within 2 days',
  //     );
  //   },
  //   onError: () => {
  //     enqueueSnackbar('Error updating section completion map', {
  //       variant: 'error',
  //     });
  //   },
  // });

  if (score === 'Unknown') {
    return (
      <div className="px-4 space-y-6">
        <div className="space-y-4">
          <div className="text-xl font-bold text-text-primary">
            How we evaluate
          </div>
          <p className="text-sm text-text-primary">
            While we don’t have a preliminary evaluation at this time, you can
            review our{' '}
            <Link
              className="font-bold text-primary-main"
              onClick={(e) => {
                e.stopPropagation();
              }}
              target="_blank"
              href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/assets/agent-resources/Underwriting+Appetite.pdf"
            >
              appetite guide.
            </Link>
          </p>
        </div>
      </div>
    );
  }

  // const handleRequestDetails = (e: React.MouseEvent<HTMLAnchorElement>) => {
  //   capture(APPETITE_LITE_WANT_MORE_DETAIL_CLICK, {
  //     dotNumber: dotNumber,
  //     score: score,
  //   });
  //   e.preventDefault();
  //   mutate(Number(dotNumber));
  // };

  const riskCount = getRiskCount(risk);
  const riskSummaries = getRiskSummaries(risk);

  return (
    <div className="px-4 space-y-6">
      <div className="space-y-4">
        <div className="text-xl font-bold text-text-primary">
          How we evaluate
        </div>
        <p className="text-sm text-text-primary">
          This preliminary evaluation was created using FMCSA carrier, licensing
          and insurance data.
        </p>
      </div>
      <div className="flex flex-col p-4 space-y-2 bg-white border rounded-lg border-text-disabled">
        <div className="flex items-center">
          <div className="flex-shrink-0 mr-4">
            {getRiskIcon(risk.high.count, risk.moderate.count)}
          </div>
          <div>
            <div className="text-sm font-bold text-text-primary">
              {riskCount}
            </div>
          </div>
        </div>
        <Show when={riskSummaries.length > 0}>
          <div className="mb-2 ml-2">
            <ul className="mt-2 text-xs list-disc list-inside text-text-primary">
              {riskSummaries.map((summary, index) => (
                <li key={`risk-summary-${index}`}>{summary}</li>
              ))}
            </ul>
          </div>
        </Show>

        {/* <Switch>
          <Switch.Match when={isLoading}>
            <CircularProgress size={16} />
          </Switch.Match>
          <Switch.Match when={requestStatus}>
            <div className="flex items-center">
              <CheckIcon className="mr-1" />
              <span className="text-sm text-text-primary">{requestStatus}</span>
            </div>
          </Switch.Match>
          <Switch.Match when={!requestStatus}>
            <a
              href="#"
              onClick={handleRequestDetails}
              className="text-sm font-bold text-primary-main"
            >
              Want more detail?
            </a>
          </Switch.Match>
        </Switch> */}
      </div>
    </div>
  );
};

export default RiskDetails;
