import * as React from 'react';
import {
  Grid,
  FormControl,
  Box,
  InputAdornment,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form-v7';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { ITheme } from '@nirvana/ui-kit';

import Button from 'src/components/button';
import { InputWithLabel } from 'src/components/input';
import IconEye from 'src/assets/icons/eye.svg';
import IconChecked from 'src/assets/icons/password-check-icon.svg';
import useSnackbar from 'src/hooks/useSnackbar';
import { VerifyTokenRequest } from '@nirvana/api/auth';
import { resetPassword } from '../../queries';
import PasswordHelper from './PasswordHelper';

interface IVerificationTokenRequest extends VerifyTokenRequest {
  confirmPassword: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(5),
  },
  formControl: {
    marginBottom: theme.spacing(1),
  },
  formControlButton: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  email: {
    color: theme.palette.text.primary,
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();
  const [newPasswordFieldType, setNewPasswordFieldType] = React.useState<
    'text' | 'password'
  >('password');
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] =
    React.useState<'text' | 'password'>('password');

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token') || '';
  const email = decodeURIComponent(urlParams.get('user') || '');
  const localStorageKey = 'resendLinkTime';

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<IVerificationTokenRequest>({
    mode: 'onChange',
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });
  const newPassword = watch('newPassword');

  const handleNewPasswordTypeToggle = () => {
    if (newPasswordFieldType === 'text') {
      setNewPasswordFieldType('password');
    } else {
      setNewPasswordFieldType('text');
    }
  };

  const handleConfirmPasswordTypeToggle = () => {
    if (confirmPasswordFieldType === 'text') {
      setConfirmPasswordFieldType('password');
    } else {
      setConfirmPasswordFieldType('text');
    }
  };

  const { mutate } = useMutation(resetPassword, {
    onSuccess: () => {
      navigate('/login', { replace: true });
      enqueueSnackbar(
        'Password Updated',
        'Your password has been successfully changed',
        { variant: 'success' },
      );
    },
    onError: (err) => {
      const errMessage = (err as any)?.response?.data;
      navigate('/login', { replace: true });
      enqueueSnackbar(
        errMessage.message === 'token not found'
          ? 'Link Expired'
          : 'Failed to Reset Password',
        <div>
          Please try resetting it again or{' '}
          <a href="https://intercom.help/nirvana-insurance/en/" color="#3350A1">
            contact us
          </a>
        </div>,
        { variant: 'error' },
      );
    },
  });

  const onSubmit = (data: VerifyTokenRequest) => {
    const payload: VerifyTokenRequest = {
      email,
      token,
      newPassword: data.newPassword,
    };
    mutate(payload);
  };

  React.useEffect(() => {
    localStorage.removeItem(localStorageKey);
  });

  if (!token || !email) {
    navigate('/login', { replace: true });
  }

  return (
    <Grid container direction="column">
      <Grid item position="relative">
        <PasswordHelper
          heading="Reset password"
          text={
            <span>
              Set a new password for{' '}
              <span className={classes.email}>{email}</span>
            </span>
          }
        />
      </Grid>
      <Grid item>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.loginForm}>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: 'Please enter a password',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters long',
              },
              validate: {
                noSpaces: (value) =>
                  !/\s/.test(value) || 'Password cannot contain spaces',
              },
            }}
            render={({ field, fieldState }) => (
              <InputWithLabel
                label={
                  <Box display="flex" alignItems="center">
                    <span style={{ marginBottom: '2px' }}>New Password</span>
                    {field.value && !fieldState.invalid && (
                      <img
                        src={IconChecked}
                        alt="Password Matched"
                        style={{ marginLeft: '5px', marginTop: '-4px' }}
                      />
                    )}
                  </Box>
                }
                formControlProps={{
                  fullWidth: true,
                  className: classes.formControl,
                }}
                inputLabelProps={{ color: 'primary' }}
                placeholder="Minimum 8 characters"
                type={newPasswordFieldType}
                endAdornment={
                  field.value && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={handleNewPasswordTypeToggle}
                      >
                        <img src={IconEye} alt="Toggle Password Visibility" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                helperText={fieldState.error?.message || ' '}
                fullWidth
                noMargin
                {...field}
                error={!!errors.newPassword}
                onContextMenu={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: 'Password confirmation is required',
              minLength: {
                value: 8,
                message: 'Password repeat doesn’t match',
              },
              validate: {
                noSpaces: (value) =>
                  !/\s/.test(value) || 'Password cannot contain spaces',
                matchPassword: (value) => {
                  return (
                    value === newPassword || 'Password repeat does not match'
                  );
                },
              },
            }}
            render={({ field, fieldState }) => (
              <InputWithLabel
                label={
                  <Box display="flex" alignItems="center">
                    <span style={{ marginBottom: '2px' }}>
                      Confirm New Password
                    </span>
                    {field.value && !fieldState.invalid && (
                      <img
                        src={IconChecked}
                        alt="Password Matched"
                        style={{ marginLeft: '5px', marginTop: '-4px' }}
                      />
                    )}
                  </Box>
                }
                formControlProps={{
                  fullWidth: true,
                  className: classes.formControl,
                }}
                inputLabelProps={{ color: 'primary' }}
                placeholder="Repeat New Password"
                type={confirmPasswordFieldType}
                endAdornment={
                  field.value && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={handleConfirmPasswordTypeToggle}
                      >
                        <img src={IconEye} alt="Toggle Password Visibility" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                helperText={fieldState.error?.message || ' '}
                fullWidth
                {...field}
                error={!!errors.confirmPassword}
                onContextMenu={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
              />
            )}
          />
          <FormControl className={classes.formControlButton} fullWidth>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isValid}
            >
              Save New Password
            </Button>
          </FormControl>
        </form>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
