import { Column } from 'react-table';
import Button from 'src/components/button';
import ArrowRight from 'src/assets/icons/arrow-right.svg';
import {
  PoliciesQuery,
  useCreateFleetSafetyReportMutation,
} from 'src/types/graphql-types';
import useSnackbar from 'src/hooks/useSnackbar';
import { CircularProgress } from '@material-ui/core';

export const getColumns = (): Array<Column<PoliciesQuery['policies'][0]>> => [
  {
    Header: 'Insured',
    accessor: 'insuredName',
  },
  {
    Header: 'DOT #',
    accessor: 'insuredDOTNumber',
    Cell: ({ value }) => {
      const enqueueSnackbar = useSnackbar();

      const [createReport, { loading }] = useCreateFleetSafetyReportMutation({
        onCompleted: ({ createFleetSafetyReport }) => {
          if (createFleetSafetyReport) {
            window.open(
              `${import.meta.env.VITE_SAFETY_URL}/${
                createFleetSafetyReport?.id
              }/claims`,
              '_blank',
            );
          }
        },
        onError: () => {
          enqueueSnackbar('Error', 'There was an error when redirecting', {
            variant: 'error',
          });
        },
      });

      return (
        <div className="flex items-center justify-between">
          <div>{value}</div>
          <Button
            variant="outlined"
            className="m-0"
            onClick={() => {
              createReport({
                variables: {
                  dotNumber: value.toString(),
                },
              });
            }}
          >
            {loading ? (
              <CircularProgress size="16px" />
            ) : (
              <div className="flex items-center">
                <img alt="View claims (Opens in new tab)" src={ArrowRight} />
              </div>
            )}
          </Button>
        </div>
      );
    },
  },
];
