import { useEffect } from 'react';
import clsx from 'clsx';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import {
  Controller,
  FieldError,
  useFieldArray,
  useFormContext,
} from 'react-hook-form-v7';
import { InputNumeric, ITheme } from '@nirvana/ui-kit';
import {
  AdmittedCommodityConstant,
  CommodityRecord,
} from '@nirvana/api/non-fleet';
import { AutoComplete } from 'src/components/autoComplete';

const useStyles = makeStyles((theme: ITheme) => ({
  totalItem: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    fontWeight: theme.typography.fontWeightRegular,
    height: '100%',
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(1, 2),
  },
  totalLabel: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: '5px 0 0 5px',
  },
  totalValue: {
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: '0 5px 5px 0',
  },
}));

const defaultValues = {
  name: '',
  categoryLabel: '',
  category: '',
  percentage: '',
};

const getDefaultRows = (existingData: CommodityRecord[] = []) => {
  // Existing data can have any number of rows, but we need to ensure a minimum of 5 rows
  const rows = existingData.length > 5 ? existingData : [...existingData];

  while (rows.length < 4) {
    rows.push(defaultValues as any);
  }

  return rows;
};

const getFirstErrorMessage = (errors: any[] = []) => {
  if (!errors?.length) {
    return null;
  }

  for (let i = 0, l = errors.length; i < l; i += 1) {
    if (errors[i]) {
      const entries = Object.entries(errors[i]);
      if (!entries.length) {
        return null;
      }

      const [, value] = entries[0];

      return (value as FieldError)?.message;
    }
  }
};

interface CommiditiesHauledProps {
  commoditiesList: AdmittedCommodityConstant[];
}

const CommoditiesHalued = ({ commoditiesList }: CommiditiesHauledProps) => {
  const classes = useStyles();
  const {
    clearErrors,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'operationsForm.commodityDistribution',
    shouldUnregister: true,
  });

  const commodityDistribution = watch('operationsForm.commodityDistribution');
  const firstErrorMessage = (errors.operationsForm as any)
    ?.commodityDistribution?.message
    ? (errors.operationsForm as any)?.commodityDistribution?.message
    : getFirstErrorMessage(
        (errors?.operationsForm as any)?.commodityDistribution,
      );

  const totalPercentage =
    commodityDistribution?.reduce(
      (acc: number, item: CommodityRecord) => acc + (+item.percentage ?? 0),
      0,
    ) || 0;

  useEffect(() => {
    setTimeout(() => {
      setValue(
        'operationsForm.commodityDistribution',
        getDefaultRows(getValues('operationsForm.commodityDistribution')),
      );
    }, 300);
  }, [getValues, setValue]);

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={5}>
        <Box paddingTop="10px">
          <InputLabel htmlFor="restricted-classes">
            Commodities Hauled
          </InputLabel>
          <FormHelperText style={{ width: 288 }}>
            Please add your top 4 primary commodities.
          </FormHelperText>
        </Box>
      </Grid>

      <Grid item xs={7} container flexDirection={'column'} spacing={2}>
        <Grid item container alignItems="center" spacing={2} flexWrap="nowrap">
          <Grid item xs={5}>
            <FormHelperText>Commodity</FormHelperText>
          </Grid>
          <Grid item xs={2}>
            <FormHelperText>% of Hauls</FormHelperText>
          </Grid>
        </Grid>

        <Grid item container flexDirection="column" spacing={1}>
          {fields.map((item, index) => (
            <Grid
              item
              container
              key={item.id}
              spacing={2}
              flexWrap="nowrap"
              alignItems="flex-start"
            >
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`operationsForm.commodityDistribution.${index}.categoryLabel`}
                    rules={{
                      validate: (value) => {
                        if (!value && commodityDistribution[index].percentage) {
                          return 'Commodity is required';
                        }
                      },
                    }}
                    render={({ field: { value, onChange }, fieldState }) => (
                      <AutoComplete
                        value={value}
                        placeholder="Select"
                        list={commoditiesList}
                        InputProps={{ error: !!fieldState.error }}
                        focusPlaceholder="Start typing to search..."
                        autoCompleteProps={{
                          classes: { listbox: 'space-y-4' },
                          groupBy: (option) => option.category,
                          renderGroup: (params) => (
                            <li key={params.key}>
                              <div className="px-4 text-xs text-text-hint">
                                {params.group}
                              </div>
                              <ul className="text-sm">{params.children}</ul>
                            </li>
                          ),
                          onChange: (_, newValue, reason) => {
                            if (reason === 'clear') {
                              setValue(
                                `operationsForm.commodityDistribution.${index}.category`,
                                '',
                              );
                              setValue(
                                `operationsForm.commodityDistribution.${index}.name`,
                                '',
                              );
                              onChange('');
                            } else {
                              // Set the commodity enum value
                              setValue(
                                `operationsForm.commodityDistribution.${index}.category`,
                                newValue.value,
                              );

                              // Set the commodity category
                              setValue(
                                `operationsForm.commodityDistribution.${index}.name`,
                                newValue.category,
                              );

                              // Set the commodity label
                              onChange(newValue.label);
                            }
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`operationsForm.commodityDistribution.${index}.percentage`}
                    rules={{
                      validate: (value) => {
                        if (
                          !value &&
                          (commodityDistribution[index].name ||
                            commodityDistribution[index].category)
                        ) {
                          return 'Percentage is required';
                        }
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <InputNumeric
                        value={value}
                        placeholder="0%"
                        suffix="%"
                        decimalScale={0}
                        onChange={(e) => {
                          clearErrors('operationsForm.commodityDistribution');
                          onChange(+e.target.value);
                        }}
                        error={
                          !!(errors.operationsForm as any)
                            ?.commodityDistribution?.[index]?.percentage
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid xs={7} item container direction="row" wrap="nowrap" spacing={0}>
          <Grid item>
            <Box className={clsx(classes.totalItem, classes.totalLabel)}>
              Total
            </Box>
          </Grid>
          <Grid item flexGrow={1}>
            <Box className={clsx(classes.totalItem, classes.totalValue)}>
              {totalPercentage}%
            </Box>
          </Grid>
        </Grid>

        {!!firstErrorMessage && (
          <Grid item>
            <FormHelperText error>{firstErrorMessage}</FormHelperText>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CommoditiesHalued;
