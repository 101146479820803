import { Close } from '@material-ui/icons';
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Dialog, ITheme } from '@nirvana/ui-kit';
import IconApplicationError from 'src/assets/icons/application-error.svg';
import { useFormContext } from 'react-hook-form-v7';

const useStyles = makeStyles((theme: ITheme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: 4,
  },
}));

const defaultValues = {
  dotNumber: '',
  companyName: '',
  effectiveDate: null,
  numberOfPowerUnits: '',
  hasUndesiredOperations: '',
};

interface NFPowerUnitsModalProps {
  open: boolean;
  onClose: () => void;
}

const NFPowerUnitsModal = ({ open, onClose }: NFPowerUnitsModalProps) => {
  const classes = useStyles();
  const { reset } = useFormContext();

  function handleClose() {
    reset(defaultValues);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      primaryAction={
        <Button variant="contained" color="primary" onClick={handleClose}>
          Back to Home
        </Button>
      }
      disableBackdropClose
    >
      <Box pt={4} position="relative" px={10} pb={2}>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          edge="end"
          size="small"
        >
          <Close />
        </IconButton>

        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <img src={IconApplicationError} alt="Application Error" />
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              color="textPrimary"
              textAlign="center"
              gutterBottom
            >
              Unfortunately, you don&apos;t have access to our fleet program yet
            </Typography>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Box className={classes.wrapper} px={3} py={2}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                textAlign="center"
                mx="auto"
                maxWidth={620}
                align="center"
              >
                This application is eligible for our fleet program. Please
                contact your business development representative to get access.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default NFPowerUnitsModal;
