import { Button, FormHelperText, Grid, Typography } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import {
  ApplicationDetail,
  CoverageType,
  Producer,
  SectionCompletionMap,
} from '@nirvana/api/quoting';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { fetchApplicationById } from 'src/features/application/actions';
import { useDispatch } from 'src/redux';
import { ClassesAndCommoditiesView } from '../../create/review/sections';
import { SECTION_NAMES } from '../constant';
import { updateSectionCompletionMap } from '../queries';
import AdditionInformartionView from '../views/additionalInformationView';
import CoveragesView from '../views/coveragesView';
import LossHistoryView from '../views/lossHistoryView';
import OperationsView from '../views/operationsView';

interface ReviewProps {
  application: ApplicationDetail;
  availableProducers?: Producer[];
}

const Review = ({ application, availableProducers }: ReviewProps) => {
  const hasMTC =
    application?.indicationForm?.operationsForm?.coveragesRequired?.some(
      (coverage) =>
        coverage.coverageType === CoverageType.CoverageMotorTruckCargo,
    );

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: update } = useMutation(updateSectionCompletionMap, {
    onSuccess: () => {
      dispatch(fetchApplicationById(application.summary.applicationID));
    },
    onError: () => {
      enqueueSnackbar('Error updating section completion map', {
        variant: 'error',
      });
      dispatch(fetchApplicationById(application.summary.applicationID));
    },
  });

  const handleModifyApplication = () => {
    update({
      applicationID: application.summary.applicationID,
      sectionCompletionMap: {
        [SECTION_NAMES.COVERAGES]: true,
        [SECTION_NAMES.OPERATIONS]: true,
        [SECTION_NAMES.LOSS_HISTORY]: true,
        [SECTION_NAMES.ADDITIONAL_INFO]: true,
        [SECTION_NAMES.CLASSES_AND_COMMODITIES]: true,
      } as unknown as SectionCompletionMap,
    });
  };

  return (
    <Grid item>
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h4"
              fontWeight="fontWeightBold"
              color="textPrimary"
              gutterBottom
            >
              Application under review
            </Typography>
            <FormHelperText sx={{ marginBottom: '12px' }}>
              Your application was submitted successfuly and is under review
            </FormHelperText>
          </Grid>
          <Grid item>
            <Button
              onClick={handleModifyApplication}
              variant="outlined"
              color="primary"
              startIcon={<Create />}
            >
              Modify Application
            </Button>
          </Grid>
        </Grid>

        {application && (
          <>
            <CoveragesView
              activeApplication={application}
              availableProducers={availableProducers}
              isEditable={false}
              hideFooter
            />
            <OperationsView
              activeApplication={application}
              isEditable={false}
              hideFooter
            />
            <ClassesAndCommoditiesView
              applicationData={application}
              isEditable={false}
              hasMTC={hasMTC || false}
              showChanges={false}
            />
            <LossHistoryView
              activeApplication={application}
              isEditable={false}
              hideFooter
            />
            <AdditionInformartionView
              activeApplication={application}
              isEditable={false}
              hideFooter
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Review;
