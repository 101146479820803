import axios from 'axios';
import { storage } from '@nirvana/ui-kit';
import {
  Configuration,
  InsuranceBundleApi,
  EndorsementappIntakeApi,
} from '@nirvana/api/endorsementapp';
import { AUTH_TOKEN_STORAGE_KEY } from 'src/constants';

const configOptions = new Configuration();
const apiService = new InsuranceBundleApi(configOptions);
const endorsementApiService = new EndorsementappIntakeApi(configOptions);

axios.interceptors.request.use((config) => {
  config.headers.JSESSIONID = storage.get(AUTH_TOKEN_STORAGE_KEY);
  return config;
});

export { apiService as default, endorsementApiService };
