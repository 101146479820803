import { Button, DialogActions } from '@material-ui/core';
import { Dialog, Show } from '@nirvana/ui-kit';
import ClearanceConflictIcon from 'src/assets/icons/clearance-conflict.svg';

interface PossibleRenewalProps {
  open: boolean;
  onClose: () => void;
  handleCreateApplication?: () => void;
  renewalData?: {
    accountName: string;
    dotNumber: string;
  };
}

const PossibleRenewalDialog = ({
  onClose,
  open,
  handleCreateApplication,
  renewalData,
}: PossibleRenewalProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <div className="flex flex-col items-center space-y-8 p-6 w-[710px] h-[500px]">
        <img src={ClearanceConflictIcon} />
        <div className="space-y-6">
          <div className="text-xl text-center">
            Another agent already received preliminary clearance
          </div>

          <div className="text-base font-medium text-center">
            <p>
              We currently have an active policy with this{' '}
              {renewalData?.accountName} ({renewalData?.dotNumber}), and the
              incumbent agent has already received preliminary clearance
            </p>
            <div className="mt-4 mb-4 ml-10 space-y-6 text-base font-normal text-left">
              <div className="mt-6">
                To pursue this account, you will need to complete the
                application by:
                <div className="mt-2 list-decimal">
                  <p>1. Obtaining a Telematics connection from the insured.</p>
                  <p>
                    2. Securing a{' '}
                    <a
                      className="text-primary-main"
                      href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/assets/agent-resources/BOR+Rules.pdf"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      BOR Letter
                    </a>{' '}
                    from the insured.
                  </p>
                </div>
              </div>
              <div className="font-normal text-l">
                <div>
                  Please reach out to{' '}
                  <a
                    className="underline text-primary-main"
                    href="mailto:support@nirvanatech.com"
                  >
                    support@nirvanatech.com
                  </a>{' '}
                  if you have any questions, or view our Guide to clearance{' '}
                  <a
                    className="text-primary-main"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://intercom.help/nirvana-insurance/en/articles/9435293-guide-to-clearance"
                  >
                    here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogActions className="flex flex-row justify-end mt-4 space-x-5 py-9 items-right">
        <Show when={!!handleCreateApplication}>
          <>
            <Button
              variant="outlined"
              onClick={handleClose}
              color="primary"
              className="h-10"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateApplication}
              className="h-10"
            >
              Continue
            </Button>
          </>
        </Show>
      </DialogActions>
    </Dialog>
  );
};

export default PossibleRenewalDialog;
