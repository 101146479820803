import * as React from 'react';
import Lottie from 'lottie-react';
import { Box, Grid, Typography } from '@material-ui/core';

import IconCircle from 'src/assets/icons/circle-light.svg';
import AnimSearching from 'src/assets/animations/searching.json';
import AnimComplete from 'src/assets/animations/complete.json';

export interface IIndicationLoaderOptions {
  isLast: boolean;
}

export type IndicationLoaderStatus = 'idle' | 'loading' | 'complete';

export interface IIndicationLoaderProps {
  steps: string[];
  onStepChange?: (stepIndex: number, options: IIndicationLoaderOptions) => void;
  onStatusChange?: (status: IndicationLoaderStatus) => void;
}

const IndicationLoader = ({
  steps,
  onStepChange,
  onStatusChange,
}: IIndicationLoaderProps) => {
  const [activeStepIndex, setActiveStep] = React.useState<number>(0);
  const searchingAnimRef = React.useRef<any>(null);
  const completeAnimRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (completeAnimRef.current) {
      completeAnimRef.current.setSpeed(1.5);
    }

    !!onStatusChange && onStatusChange('loading');
  }, [onStatusChange]);

  React.useEffect(() => {
    if (activeStepIndex < steps.length - 1) {
      if (onStepChange) {
        onStepChange(activeStepIndex, {
          isLast: activeStepIndex === steps.length,
        });
      }

      if (completeAnimRef.current) {
        completeAnimRef.current.setSpeed(1.5);
      }
    }
  }, [activeStepIndex, onStepChange, steps.length]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={9}
    >
      <Grid item>
        <Lottie
          animationData={AnimSearching}
          lottieRef={searchingAnimRef}
          style={{ width: '150px' }}
        />
      </Grid>
      <Grid item>
        <Box style={{ minWidth: '320px' }}>
          {steps.map((text, index) => {
            return (
              <Grid
                key={`Step${index}`}
                container
                direction="row"
                spacing={1}
                mb={1}
              >
                <Grid item>
                  {index <= activeStepIndex ? (
                    <Lottie
                      animationData={AnimComplete}
                      lottieRef={completeAnimRef}
                      loop={false}
                      style={{ width: '22px' }}
                      onComplete={() => {
                        if (
                          !!onStatusChange &&
                          activeStepIndex === steps.length - 1
                        ) {
                          onStatusChange('complete');
                        } else {
                          setActiveStep((step) => step + 1);
                        }
                      }}
                    />
                  ) : (
                    <img src={IconCircle} width={22} />
                  )}
                </Grid>
                <Grid item>
                  <Typography
                    color={
                      index === activeStepIndex ? 'secondary.main' : 'text.hint'
                    }
                    variant={index === activeStepIndex ? 'h6' : 'body2'}
                    fontWeight="fontWeightRegular"
                  >
                    {text}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default IndicationLoader;
