import { TableV8 } from '@nirvana/ui-kit';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { fetchEndorsementRequestEquipments } from '../../queries/endorsement';
import { getEquipmentListData } from '../../utils';

import { getVehicleColumns } from '../../constants/vehicle';
import EditLayout, { EndorsementType } from './layout';

export default function Equipments() {
  const { policyId = '', endorsementId = '' } = useParams();

  const { data = [] } = useQuery(
    ['endorsement-request-equipment', policyId, endorsementId],
    () => fetchEndorsementRequestEquipments(policyId, endorsementId),
    {
      select: (data) => data.equipments,
    },
  );

  const equipmentList = useMemo(() => {
    return getEquipmentListData(data);
  }, [data]);

  return (
    <EditLayout title="Equipment" endorsementType={EndorsementType.Equipment}>
      <TableV8 columns={getVehicleColumns({})} data={equipmentList} />
    </EditLayout>
  );
}
