import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Grid,
  Box,
} from '@material-ui/core';
import { Show } from '@nirvana/ui-kit';
import { ApplicationDetail, TelematicsInfo } from '@nirvana/api/quoting';
import { useAnalytics } from 'src/helpers/analytics';
import {
  CREATE_TELEMATICS_CONNECTION_LINK,
  CREATE_TELEMATICS_CONNECTION_LINK_CLICK,
  CREATE_TELEMATICS_CONNECTION_LINK_IN_MODAL_OPEN_STATE,
} from 'src/features/telematics/events';

import IconStatusOnline from 'src/assets/icons/status-online.svg';

import RequestConsent from '../additionalInformation/request-consent';
import { EditTelematics } from './editTelematics';

const ConnectTelematcis = ({
  applicationId,
  details,
  step,
}: {
  applicationId: string;
  details?: ApplicationDetail;
  step?: string;
}) => {
  const [openConsentDialog, setOpenConsentDialog] = useState(false);
  const [createLinkData, setCreateLinkData] = useState<TelematicsInfo>({
    email: '',
    name: '',
    link: '',
  });
  const { capture } = useAnalytics();

  useEffect(() => {
    if (details?.telematicsInfo) {
      setCreateLinkData(details.telematicsInfo);
    }
  }, [details?.telematicsInfo]);

  useEffect(() => {
    capture(CREATE_TELEMATICS_CONNECTION_LINK, {
      applicationId,
      step,
    });
  }, [applicationId, step]);

  let connectionURL = createLinkData.link;
  const host = import.meta.env.VITE_WEB_URL;
  // Adding this validation in order to support older format of link
  // Removing it after we successfully migrate to link including Schema and host.
  if (!connectionURL.includes(host)) {
    connectionURL = `${host}/${connectionURL}`;
  }

  const handleCreateConnectionLinkClick = () => {
    capture(CREATE_TELEMATICS_CONNECTION_LINK_CLICK, {
      applicationId,
      step,
    });
    setOpenConsentDialog(true);
  };

  return (
    <>
      <Card className="px-6 py-4 mt-4">
        <CardContent className="p-0">
          <Grid container spacing={2}>
            <Grid item>
              <Box display="flex">
                <img src={IconStatusOnline} className="w-6 h-6 mr-2" />
                <Typography variant="body1" fontWeight="fontWeightBold">
                  Connect Telematics
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Show
            when={!createLinkData.link}
            fallback={
              <EditTelematics
                email={createLinkData.email ?? ''}
                name={createLinkData.name}
                url={connectionURL}
                applicationId={applicationId}
                step={step}
              />
            }
          >
            <Grid item>
              <Typography className="mt-4 text-xs font-normal leading-5">
                We need your insured to connect their telematics in order to get
                you a quote. Connect early to potentially expedite your review.
              </Typography>
            </Grid>
          </Show>
        </CardContent>
        {createLinkData.link === '' && (
          <CardActions sx={{ mt: 2, ml: -1 }}>
            <Button
              variant="outlined"
              onClick={handleCreateConnectionLinkClick}
              size="medium"
            >
              Create Connection Link
            </Button>
          </CardActions>
        )}
      </Card>

      <RequestConsent
        open={openConsentDialog}
        applicationId={applicationId}
        onClose={() => setOpenConsentDialog(false)}
        onCreateLink={(data) => {
          capture(CREATE_TELEMATICS_CONNECTION_LINK_IN_MODAL_OPEN_STATE, {
            applicationId,
            step,
          });
          setCreateLinkData({
            email: data.email,
            name: data.name,
            link: data.url,
          });
        }}
      />
    </>
  );
};

export default ConnectTelematcis;
