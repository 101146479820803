import { useCallback } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form-v7';
import { Checkbox } from '@nirvana/ui-kit';
import { useApplicationDetailsContext } from 'src/features/nonFleet/hooks/useApplicationDetails';

const Coverage = () => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { producers } = useApplicationDetailsContext();

  const { fields } = useFieldArray({
    control,
    name: 'operationsForm.coverageInfo.primaryCovs',
  });

  const validateCoverages = useCallback(() => {
    const errorMessage = 'Please select at least one coverage';
    const values = getValues(
      fields.map(
        (v, index) =>
          `operationsForm.coverageInfo.primaryCovs.${index}.isRequired`,
      ),
    );
    const isValid = values.some((v) => v);
    return isValid || errorMessage;
  }, [fields, getValues]);

  return (
    <>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="producer">Producer</InputLabel>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <Controller
              control={control}
              defaultValue=""
              name="operationsForm.producerId"
              render={({ field: { value, onChange } }) => (
                <Select
                  displayEmpty
                  value={value}
                  variant="outlined"
                  onChange={onChange}
                  sx={{ maxWidth: 240 }}
                >
                  <MenuItem value="">
                    <Typography color="text.hint">Select</Typography>
                  </MenuItem>
                  {producers?.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              rules={{ required: 'Please select a producer' }}
            />
            {(errors.operationsForm as any)?.producerId?.message && (
              <FormHelperText error>
                {(errors.operationsForm as any)?.producerId?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="start-date">
              Primary Coverages requested
            </InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Your limits and deductibles will be selected at the quote step
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box display="flex">
            {fields.map((field: any, index) => (
              <Controller
                key={field.coverageType}
                name={`operationsForm.coverageInfo.primaryCovs.${index}.isRequired`}
                rules={{ validate: validateCoverages }}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    checked={value}
                    onChange={(_, checked) => onChange(checked)}
                  >
                    {field.label}
                  </Checkbox>
                )}
              />
            ))}
          </Box>
          {(errors?.operationsForm as any)?.coverageInfo?.primaryCovs?.[0]
            ?.isRequired?.message && (
            <FormHelperText error>
              {
                (errors?.operationsForm as any)?.coverageInfo?.primaryCovs?.[0]
                  ?.isRequired?.message
              }
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Coverage;
