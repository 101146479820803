import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { styled } from '@material-ui/styles';
import {
  ProgramType,
  TSP,
  TelematicsFleetInfoResponseValidationErrorsEnum,
} from '@nirvana/api/quoting';
import { Show, getProviderInfo, ProviderInfo } from '@nirvana/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { ITheme } from 'src/assets/themes';
import { HorizontalStepper } from 'src/components/stepper';
import { hooks as Hooks } from 'src/helpers';
import { useDispatch } from 'src/redux';

import IconApplicationError from 'src/assets/icons/application-error-icon.svg';
import IconBack from 'src/assets/icons/arrow-left.svg';
import IconCheck from 'src/assets/icons/check-circle-sm.svg';
import IconClose from 'src/assets/icons/cross.svg';

import Sync from 'src/assets/icons/sync.svg';

import { useAnalytics } from 'src/helpers/analytics';
import { fetchTelematicsInfo } from '../actions';
import { telematicsSelector } from '../slices';

const CustomTableCell = styled(TableCell)(() => ({
  verticalAlign: 'bottom',
}));

const ListHead = styled(Typography)(({ theme }: { theme: ITheme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.hint,
}));

const ListText = styled(Typography)(({ theme }: { theme: ITheme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'flex-end',
}));

const ListError = styled(Typography)(({ theme }: { theme: ITheme }) => ({
  ...theme.typography.body2,
  color: theme.palette.error.main,
  display: 'flex',
  justifyContent: 'flex-end',
}));

const ErrorCardContent = styled(CardContent)(
  ({ theme }: { theme: ITheme }) => ({
    padding: theme.spacing(4),
  }),
);

const ErrorCard = styled(Card)(() => ({
  border: '1px solid #F3F4FA',
  boxShadow: '0px 4px 8px rgba(128, 145, 196, 0.11)',
  borderRadius: 5,
}));

const ErrorIcon = styled(Error)(({ theme }: { theme: ITheme }) => ({
  fontSize: 14,
  marginRight: theme.spacing(0.5),
}));

const ErrorAlert = styled(Box)(({ theme }: { theme: ITheme }) => ({
  backgroundColor: theme.palette.error.light,
  border: '1px solid #FDAAB7',
  borderRadius: 5,
  padding: theme.spacing(4),
  color: theme.palette.error.main,
  ...theme.typography.body2,
}));

type ErrorMessageMeta = {
  matchedVINs?: number;
};

const getErrorMessage = (
  reason: TelematicsFleetInfoResponseValidationErrorsEnum,
  providerInfo: ProviderInfo,
  { matchedVINs }: ErrorMessageMeta,
) => {
  switch (reason) {
    case TelematicsFleetInfoResponseValidationErrorsEnum.MissingTelematicsVehicles: {
      if (matchedVINs && matchedVINs !== 0) {
        return `The list of VINs in your ${providerInfo.name} account does not match the equipment list in the application.`;
      }

      return `We found 0 VINs in your ${providerInfo.name} Account`;
    }

    case TelematicsFleetInfoResponseValidationErrorsEnum.InvalidDotNumber:
      return `DOT submitted in the application does not match the one we received from ${providerInfo.name}.`;

    // TODO: change for no VINs
    // Error message copy should be: `${providerInfo.name} is not showing any VINs.`;
    case TelematicsFleetInfoResponseValidationErrorsEnum.InvalidFleetName:
      return `Your fleet name does not match the fleet name that we got from ${providerInfo.name}.`;
  }
};

const getResolutionInstructions = (
  tsp: TSP | undefined,
  providerInfo: ProviderInfo,
) => {
  switch (tsp) {
    case TSP.TspSamsara:
      return [
        <Typography variant="body2" key="instruction-1">
          After you click “Re-Connect” please check:
        </Typography>,
        <Typography variant="body2" key="instruction-2">
          <ErrorIcon />
          Are you using the correct {providerInfo?.name} account to log in?
        </Typography>,
        <Typography variant="body2" key="instruction-3">
          <ErrorIcon />
          Did you provide access to the organization that contains your entire
          fleet?
        </Typography>,
        <Typography variant="body2" key="instruction-4">
          <ErrorIcon />
          Did you grant tag access to the entire organization?
        </Typography>,
      ];

    case TSP.TspKeepTruckin:
      return [
        <Typography variant="body2" key="instruction-1">
          After you click “Re-Connect” please check:
        </Typography>,
        <Typography variant="body2" key="instruction-2">
          <ErrorIcon />
          Are you using the correct {providerInfo?.name} account to log in?
        </Typography>,
        <Typography variant="body2" key="instruction-3">
          <ErrorIcon />
          Does your account have admin role or “App Marketplace” permissions?
        </Typography>,
      ];

    default:
      return [
        <Typography variant="body2" key="instruction-1">
          After you click “Re-Connect” please check:
        </Typography>,
        <Typography variant="body2" key="instruction-2">
          <ErrorIcon />
          Are you using the correct {providerInfo?.name} account to log in?
        </Typography>,
        <Typography variant="body2" key="instruction-3">
          <ErrorIcon />
          Does your account have admin role or permissions?
        </Typography>,
      ];
  }
};

const getHelpLink = (tsp?: TSP) => {
  switch (tsp) {
    case TSP.TspSamsara:
      return 'https://intercom.help/nirvana-insurance/en/articles/6245937-troubleshooting-samsara-connection?utm_source=product&utm_medium=organic&utm_campaign=telematics_connection_validation_flow';

    case TSP.TspKeepTruckin:
      return 'https://intercom.help/nirvana-insurance/en/articles/6246764-troubleshooting-motive-formerly-keeptruckin-connection?utm_source=product&utm_medium=organic&utm_campaign=telematics_connection_validation_flow';

    default:
      return 'https://intercom.help/nirvana-insurance/en/articles/6246680-troubleshooting-eld-telematics-connections?utm_source=product&utm_medium=organic&utm_campaign=telematics_connection_validation_flow';
  }
};

const Validation = () => {
  const telematics = useSelector(telematicsSelector);
  const navigate = useNavigate();
  const { capture } = useAnalytics();
  const dispatch = useDispatch();
  const query = Hooks.useQuery();
  const applicationId = query.get('token');
  const handleId = query.get('handle');
  const programType = (query.get('programType') as ProgramType) || undefined;
  const telematicsInformation = telematics.telematicsInfo;
  const {
    applicationInfo,
    telematicsInfo,
    tsp,
    validationErrors = [],
  } = telematicsInformation || {};
  const providerInfo = tsp ? getProviderInfo(tsp) : { name: '' };

  const captureHelpClick = () => {
    capture('telematics_validation_help_click', {
      tsp: tsp,
      programType: programType,
      applicationId: applicationId,
    });
  };

  const captureReconnectClick = () => {
    capture('telematics_validation_reconnect_click', {
      tsp: tsp,
      programType: programType,
      applicationId: applicationId,
    });
  };

  React.useEffect(() => {
    if (handleId) {
      dispatch(
        fetchTelematicsInfo({
          handleId,
          programType,
        }),
      );
    }
  }, [dispatch, handleId, programType]);

  if (!telematicsInformation) {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {/* <BackgroundGradient /> */}
      <Box pt={9} px={3} position="relative" zIndex={10}>
        <Button
          sx={{ color: 'text.secondary' }}
          startIcon={<img src={IconBack} alt="Back" />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      </Box>
      <Container
        maxWidth={'sm'}
        component={Box}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          zIndex: 10,
          maxWidth: {
            sm: 850,
            xxl: 960,
          },
        }}
      >
        <Grid flexGrow={1} p={4} pb={2} pt={1} container>
          <Grid container direction="column" flexGrow={1} spacing={3}>
            <Grid item>
              <Box display="flex" justifyContent="center">
                <Box width={362}>
                  <HorizontalStepper
                    steps={[
                      {
                        key: 1,
                        label: '',
                      },
                      {
                        key: 2,
                        label: '',
                      },
                      { key: 3, label: '' },
                      { key: 4, label: '' },
                    ]}
                    activeStep={3}
                    onChange={() => {}}
                    error
                  />
                </Box>
              </Box>
            </Grid>
            <>
              <Grid item>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  fontWeight="fontWeightBold"
                  textAlign="center"
                  mt={2}
                  mb={2}
                >
                  We’ve encountered some errors
                </Typography>
              </Grid>

              <Grid item>
                <ErrorAlert>
                  {validationErrors?.map(
                    (
                      record: TelematicsFleetInfoResponseValidationErrorsEnum,
                    ) => {
                      return (
                        <Typography variant="body2" key={record}>
                          {getErrorMessage(record, providerInfo, {
                            matchedVINs: telematicsInfo?.vehicles?.length,
                          })}
                        </Typography>
                      );
                    },
                  )}
                  <Show when={!!tsp}>
                    {getResolutionInstructions(tsp, providerInfo)}
                  </Show>
                </ErrorAlert>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item flex={1}>
                  <ErrorCard>
                    <ErrorCardContent>
                      <Box display={'flex'} alignItems="center" mb={2}>
                        <img
                          src={IconApplicationError}
                          alt="Aplication Error"
                        />
                        <Typography
                          variant="h6"
                          color="textPrimary"
                          fontWeight="fontWeightBold"
                          ml={1}
                        >
                          Application Errors
                        </Typography>
                      </Box>

                      <Table>
                        <TableHead>
                          <TableRow>
                            <CustomTableCell>{''}</CustomTableCell>
                            <CustomTableCell align="right">
                              <ListHead>What We Expected</ListHead>
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              <ListHead>
                                What’s In Your{' '}
                                {providerInfo?.name || 'Telematics'} Account
                              </ListHead>
                            </CustomTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <CustomTableCell>
                              <ListHead>DOT Number</ListHead>
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              <Typography variant="overline" color="text.hint">
                                {applicationInfo?.fleetName}
                              </Typography>
                              <ListText>{applicationInfo?.dotNumber}</ListText>
                            </CustomTableCell>
                            <CustomTableCell align="right">
                              <Typography variant="overline" color="text.hint">
                                {telematicsInfo?.fleetName}
                              </Typography>
                              {applicationInfo?.dotNumber !==
                              telematicsInfo?.dotNumber ? (
                                <ListError>
                                  {' '}
                                  <img src={IconClose} />
                                  &nbsp; {telematicsInfo?.dotNumber}
                                </ListError>
                              ) : (
                                <ListText>{telematicsInfo?.dotNumber}</ListText>
                              )}
                            </CustomTableCell>
                          </TableRow>
                          <Show
                            when={
                              !validationErrors.includes(
                                TelematicsFleetInfoResponseValidationErrorsEnum.InvalidDotNumber,
                              ) && telematicsInfo?.vehicles?.length === 0
                            }
                          >
                            <TableRow>
                              <CustomTableCell>
                                <ListHead>Number of VINs</ListHead>
                              </CustomTableCell>
                              <CustomTableCell align="right">
                                <ListText>
                                  {applicationInfo?.numPowerUnits}
                                </ListText>
                              </CustomTableCell>
                              <CustomTableCell align="right">
                                {telematicsInfo?.vehicles?.length === 0 ? (
                                  <ListError>
                                    {' '}
                                    <img src={IconClose} />
                                    &nbsp; {telematicsInfo?.vehicles?.length}
                                  </ListError>
                                ) : (
                                  <ListText>
                                    {telematicsInfo?.vehicles?.length}
                                  </ListText>
                                )}
                              </CustomTableCell>
                            </TableRow>
                          </Show>
                          <Show
                            when={
                              !validationErrors.includes(
                                TelematicsFleetInfoResponseValidationErrorsEnum.InvalidDotNumber,
                              ) && !!telematicsInfo?.vehicles?.length
                            }
                          >
                            <TableRow>
                              <CustomTableCell>
                                <ListHead>Matching VINs</ListHead>
                              </CustomTableCell>
                              <CustomTableCell align="right">
                                <ListText>
                                  {applicationInfo?.numPowerUnits}
                                </ListText>
                              </CustomTableCell>
                              <CustomTableCell align="right">
                                {applicationInfo?.numPowerUnits !==
                                telematicsInfo?.matchingPowerUnits ? (
                                  <ListError>
                                    {' '}
                                    <img src={IconClose} />
                                    &nbsp; {telematicsInfo?.matchingPowerUnits}
                                  </ListError>
                                ) : (
                                  <ListText>
                                    <img src={IconCheck} />
                                    &nbsp; {telematicsInfo?.matchingPowerUnits}
                                  </ListText>
                                )}
                              </CustomTableCell>
                            </TableRow>
                          </Show>
                        </TableBody>
                      </Table>
                    </ErrorCardContent>
                  </ErrorCard>
                </Grid>
              </Grid>

              <Grid
                item
                container
                spacing={3}
                justifyContent="center"
                alignItems={'center'}
              >
                <Grid item>
                  <NavLink to={`/telematics/providers?token=${applicationId}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={captureReconnectClick}
                      startIcon={<img src={Sync} alt="Reconnect" />}
                    >
                      Re-Connect
                    </Button>
                  </NavLink>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      height: 40,
                    }}
                    onClick={captureHelpClick}
                    href={getHelpLink(tsp)}
                    target="_blank"
                  >
                    Need More Help ?
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                justifyItems={'flex-end'}
                container
                flexDirection={'column'}
                alignItems="center"
              >
                <Typography
                  textAlign={'center'}
                  variant="overline"
                  color="textSecondary"
                >
                  Nirvana uses state-of-the-art security & ensures your data is
                  kept confidential. Please see our FAQs for additional
                  questions
                </Typography>
              </Grid>
            </>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Validation;
