import { useEffect, useState } from 'react';
import { HiChevronRight, HiX } from 'react-icons/hi';
import { Button, IconButton } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form-v7';

import { ProgramType, TelematicsInfo } from '@nirvana/api/quoting';
import { Dialog, InputWithLabel } from '@nirvana/ui-kit';

import { createFleetConsentLink } from 'src/features/application/slices';
import { useDispatch } from 'src/redux';

import {
  TELEMATICS_CONNECTION_MODAL_CLOSE,
  TELEMATICS_CONNECTION_MODAL_OPEN,
  TELEMATICS_LINK_CREATE,
} from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import ConsentSuccessful from './consent-successful';

type RequestConsentProps = {
  open: boolean;
  onClose: () => void;
  applicationId: string;
  telematicsInfo?: TelematicsInfo | undefined;
  onCreateLink?: (data: { email: string; name: string; url: string }) => void;
};

export default function RequestConsent({
  open,
  onClose,
  applicationId,
  telematicsInfo,
  onCreateLink,
}: RequestConsentProps) {
  const { capture } = useAnalytics();
  const dispatch = useDispatch();
  const [consentSuccessful, setConsentSuccessful] = useState(false);
  const [connectionURL, setConnectionURL] = useState<string>();

  useEffect(() => {
    capture(TELEMATICS_CONNECTION_MODAL_OPEN, { applicationId });

    return () => {
      capture(TELEMATICS_CONNECTION_MODAL_CLOSE, { applicationId });
    };
  }, [applicationId, capture]);

  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: { email: '', name: '' },
    mode: 'onChange',
  });

  function handleClose() {
    reset();
    onClose();
  }

  async function onSubmit(data: { email: string; name: string }) {
    const response = await dispatch(
      createFleetConsentLink({
        applicationId: applicationId,
        programType: ProgramType.ProgramTypeFleet,
        insuredInformation: {
          name: data.name.trim(),
          email: data.email.trim(),
        },
      }),
    );

    if (createFleetConsentLink.fulfilled.match(response)) {
      // Set & show invite link
      const url = response.payload.link;
      setConnectionURL(url);
      setConsentSuccessful(true);
      onCreateLink?.({
        name: data.name.trim(),
        email: data.email.trim(),
        url: url,
      });
      handleClose();
    }
  }

  useEffect(() => {
    if (telematicsInfo?.link) {
      reset(telematicsInfo);
      let link = telematicsInfo.link;
      const host = import.meta.env.VITE_WEB_URL;
      // Adding this validation in order to support older format of link
      // Removing it after we successfully migrate to link including Schema and host.
      if (!link.includes(host)) {
        link = `${host}/${link}`;
      }
      setConnectionURL(link);
    }
  }, [reset, telematicsInfo]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <form
          className="max-w-lg"
          onSubmit={(event) => {
            capture(TELEMATICS_LINK_CREATE, {
              applicationId,
            });
            event.stopPropagation();
            event.preventDefault();

            handleSubmit(onSubmit)(event);
          }}
        >
          <div className="flex items-start justify-between px-6 pb-2 mb-6 -mx-6 -my-2 border-b">
            <p className="font-bold">Request telematics consent</p>
            <IconButton size="small" onClick={handleClose}>
              <HiX />
            </IconButton>
          </div>

          <div className="flex items-start justify-between mb-2">
            <p className="text-xl font-bold">Generate a unique link</p>
          </div>
          <p className="mb-6">
            First, enter the insured&apos;s contact information to generate a
            connection request link
          </p>

          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
              validate: {
                noWhitespace: (value) => value.trim().length > 0,
              },
            }}
            render={({ field }) => (
              <InputWithLabel
                label="Name"
                type="text"
                placeholder="eg John Doe"
                formControlProps={{ fullWidth: true }}
                {...field}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputWithLabel
                label="Email"
                type="text"
                placeholder="eg john.doe@gmail.com"
                formControlProps={{ fullWidth: true }}
                {...field}
              />
            )}
          />

          <div className="flex justify-end mb-4">
            <Button
              type="submit"
              variant="contained"
              endIcon={<HiChevronRight />}
              disabled={!isValid}
            >
              Create Link
            </Button>
          </div>
        </form>
      </Dialog>

      <ConsentSuccessful
        open={consentSuccessful}
        connectionURL={connectionURL}
        onClose={() => setConsentSuccessful(false)}
      />
    </>
  );
}
