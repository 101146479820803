import { format, parseISO } from 'date-fns';
import { Typography } from '@material-ui/core';
import {
  AdmittedApp,
  AdmittedCommodityRecord,
  CoverageDetails,
  CoverageType,
} from '@nirvana/api/non-fleet';
import { AttachmentView, Show } from '@nirvana/ui-kit';

import { TableView } from 'src/components/table';
import { application as ApplicationHelper } from 'src/helpers';

import { getRadiusOfOperationLabel } from '../../constants/operatingRadius';
import Section, {
  SectionCommonProps,
  SectionDataRenderParams,
} from './../section';

export interface IOperationsSectionProps extends SectionCommonProps {
  onEdit: (key: string) => void;
}

const OperationsView = ({
  onEdit,
  applicationData,
  ...rest
}: IOperationsSectionProps) => {
  const enabledCoverages = applicationData?.operationsForm?.coverages
    ?.filter((record: CoverageDetails) => !!record.isRequired)
    .map((record: CoverageDetails) => record.coverageType);

  return (
    <Section
      {...rest}
      applicationData={applicationData}
      title="Operations"
      onEdit={() => onEdit('operations')}
      data={[
        {
          key: 'effectiveDate',
          label: 'Effective Date',
          getValue: (data) => {
            return data.operationsForm?.effectiveDate;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            return format(parseISO(value), 'MM/dd/yyyy');
          },
        },
        {
          key: 'coveragesRequired',
          label: 'Coverages',
          getValue: (data) => {
            return data?.operationsForm?.coverages;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            return value
              ?.filter((record: CoverageDetails) => record.isRequired)
              ?.map((record: CoverageDetails) =>
                ApplicationHelper.getIndicationOptionsFeatureLabel(
                  record.coverageType,
                ),
              )
              .join(', ');
          },
        },
        {
          key: 'producerId',
          label: 'Producer',
          getValue: (data) => {
            return data.operationsForm?.producerName;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            return value;
          },
        },
        {
          key: 'businessOwner',
          label: 'Business Owner',
          getValue: (data) => {
            return data.operationsForm?.businessOwner;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            return (
              <>
                <Typography variant="body2" mb={1}>
                  {[value?.firstName, value?.lastName]
                    .filter((record) => !!record)
                    .join(' ')}
                </Typography>
                <Typography variant="body2" mb={1}>
                  {format(parseISO(value.dateOfBirth), 'MM/dd/yyyy')}
                </Typography>
                <Typography variant="body2">
                  {[
                    value?.address.street,
                    value?.address.city,
                    value?.address.state,
                    value?.address.zip,
                  ]
                    .filter((record) => !!record)
                    .join(' ')}
                </Typography>
                <Show when={value.driverOnPolicy}>
                  <Typography variant="body2" mt={1}>
                    Driver on policy
                  </Typography>
                </Show>
              </>
            );
          },
        },
        ...(applicationData.operationsForm?.terminalLocation
          ? [
              {
                key: 'terminalLocation',
                label: 'Terminal Location',
                getValue: (data: AdmittedApp) => {
                  return data.operationsForm?.terminalLocation
                    ?.sameAsPhysicalAddress
                    ? data.operationsForm.businessOwner?.address
                    : data.operationsForm?.terminalLocation?.address;
                },
                renderValue: ({ value }: SectionDataRenderParams) => {
                  return [value?.street, value?.city, value?.state, value?.zip]
                    .filter((record) => !!record)
                    .join(' ');
                },
              },
            ]
          : []),
        {
          key: 'maxRadiusOfOperation',
          label: 'Farthest Radius of Operations',
          getValue: (data) => {
            return data.operationsForm?.maxRadiusOfOperation;
          },
          renderValue: ({ value = [] }: SectionDataRenderParams) => {
            return getRadiusOfOperationLabel(value);
          },
        },
        {
          key: 'atFaultAccidents',
          label: 'Auto Liability Claims',
          getValue: (data) => {
            return data.operationsForm?.atFaultAccidents;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            return value || '0';
          },
        },
        ...(applicationData.operationsForm?.lossRunFiles?.length
          ? [
              {
                key: 'lossRuns',
                label: 'Loss Runs',
                getValue: (data: AdmittedApp) => {
                  return data.operationsForm?.lossRunFiles;
                },
                renderValue: ({ value }: SectionDataRenderParams) => {
                  return (
                    <AttachmentView
                      files={
                        value?.map((item: any) => {
                          return {
                            filename: item.name || item.handle,
                          };
                        }) || []
                      }
                    />
                  );
                },
              },
            ]
          : []),
        ...(applicationData.operationsForm?.primaryCommodityLabel &&
        !enabledCoverages?.includes(CoverageType.CoverageMotorTruckCargo)
          ? [
              {
                key: 'primaryCommodity',
                label: 'Primary Commodity',
                getValue: (data: AdmittedApp) => {
                  return data.operationsForm?.primaryCommodityLabel;
                },
                renderValue: ({ value }: SectionDataRenderParams) => {
                  return value;
                },
              },
            ]
          : []),
        ...(applicationData.operationsForm?.commodityDistribution?.length
          ? [
              {
                key: 'commodityDistribution',
                label: 'Commodities Hauled',
                getValue: (data: AdmittedApp) => {
                  return data.operationsForm?.commodityDistribution;
                },
                renderValue: ({ value }: SectionDataRenderParams) => {
                  return (
                    <TableView
                      headers={[
                        {
                          key: 'name',
                          content: 'Commodity',
                          width: '33%',
                        },
                        {
                          key: 'category',
                          content: 'Category',
                          width: '33%',
                        },
                        {
                          key: 'percentage',
                          content: '% of hauls',
                          width: '33%',
                        },
                      ]}
                      rows={value?.map((record: AdmittedCommodityRecord) => ({
                        columns: [
                          {
                            content: `${record.name}`,
                          },
                          {
                            content: `${record.categoryLabel}`,
                          },
                          {
                            content: `${record.percentage}%`,
                          },
                        ],
                      }))}
                    />
                  );
                },
              },
            ]
          : []),
      ]}
    />
  );
};

export default OperationsView;
