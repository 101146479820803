import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { NavigateWithQuery } from 'src/components/navigate';
import { authSelector } from 'src/features/auth/slices';
import { useAnalytics } from 'src/helpers/analytics';

const AuthGuard: React.FC = ({ children }) => {
  const auth = useSelector(authSelector);
  const { isLoggedIn, isLoggedOut } = auth;
  const { update: intercomUpdate } = useIntercom();
  const { resetIdentity } = useAnalytics();
  const location = useLocation();

  if (!isLoggedIn) {
    // Clear intercom cache
    intercomUpdate({ name: '', email: '', userId: '' });

    // Clear analytics cache
    resetIdentity();

    return (
      <NavigateWithQuery
        to={{
          pathname: '/login',
        }}
        // Do not preserve the current location in case of logout
        state={{ from: isLoggedOut ? {} : location }}
      />
    );
  }

  return <>{children}</>;
};

export default AuthGuard;
