import { Box, CircularProgress, Grid } from '@material-ui/core';

import { useApplicationDetailsContext } from 'src/features/admitted/hooks/useApplicationDetails';

import {
  DriverSection,
  EquipmentSection,
  OperationsView,
  IndicationSection,
} from './sections';

export interface IReviewProps {
  onEdit: (key: string) => void;
}

/**
 * Review screen that displays the data filled up in each step of the application creation process.
 * The user is allowed to move to any previous step and edit the information that was previously submitted.
 * Corresponds to Review Page (https://www.figma.com/file/FsHg1J71OVKiejQYo8TxtR/Agents?type=design&node-id=29980-29157&mode=design&t=Ymx2Yp0mbbpUrr8Q-0)
 * @component
 */
const Review = ({ onEdit }: IReviewProps) => {
  const { applicationDetails, isLoading } = useApplicationDetailsContext();

  if (isLoading || !applicationDetails?.admitted) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container direction="column" wrap="nowrap" spacing={3}>
      <OperationsView
        editable
        onEdit={onEdit}
        applicationData={applicationDetails?.admitted}
      />
      <EquipmentSection
        editable
        onEdit={onEdit}
        applicationData={applicationDetails?.admitted}
      />
      <DriverSection
        editable
        onEdit={onEdit}
        applicationData={applicationDetails?.admitted}
      />
      <IndicationSection
        editable
        onEdit={onEdit}
        applicationData={applicationDetails?.admitted}
      />
    </Grid>
  );
};

export default Review;
