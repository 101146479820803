import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton,
  Link,
  FormHelperText,
} from '@material-ui/core';
import { InputWithLabel, Show } from '@nirvana/ui-kit';
import { useFormContext } from 'react-hook-form-v7';

import IconEye from 'src/assets/icons/eye.svg';
import IconExternalLink from 'src/assets/icons/external-link-primary.svg';

import { ITheme } from 'src/assets/themes';
import { useAnalytics } from 'src/helpers/analytics';
import { LOGIN_VIEW_TELEMATICS } from 'src/features/telematics/events';
import { TSPRecord, TelematicsConsentKind } from '@nirvana/api/quoting';
import { hooks } from 'src/helpers';
import {
  getCredentialsUsageHelperText,
  getForgotPasswordLink,
  getLoginIdFieldHelperText,
  getLoginIdHelpUrl,
} from '../../utils';

const useStyles = makeStyles((theme: ITheme) => ({
  dialogPaper: {
    width: theme.typography.pxToRem(480),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
  },
  logo: {
    width: theme.typography.pxToRem(180),
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: 0,
  },
  loginForm: {
    marginBottom: theme.spacing(6),
  },
}));

const getConnectionErrorMessage = (code: string = '') => {
  if (code.includes('internal_error')) {
    return 'An internal error occurred. Please try again later.';
  } else if (
    code
      .toLowerCase()
      .includes('please ensure that user has the correct permissions')
  ) {
    return (
      <>
        An administrator profile is required to grant access. Reach out to your
        administrator for assistance or{' '}
        <Link
          href="https://intercom.help/nirvana-insurance/en/"
          target="_blank"
        >
          click here
        </Link>{' '}
        for instructions on how to get administrator permissions.
      </>
    );
  } else if (code.toLowerCase().includes('please check your credentials')) {
    return 'Incorrect username or password. Please try again.';
  }

  return code;
};

type FormData = {
  username: string;
  password: string;
  loginId?: string;
};

const LoginForm = ({
  connectionError,
  selectedProvider,
}: {
  connectionError: string;
  selectedProvider: TSPRecord;
}) => {
  const classes = useStyles();
  const query = hooks.useQuery();
  const { capture } = useAnalytics();
  const applicationId = query.get('token') || '';

  useEffect(() => {
    capture(LOGIN_VIEW_TELEMATICS, {
      provider: selectedProvider.tsp,
      applicationId,
    });
  }, [selectedProvider.tsp, applicationId, capture]);

  const [passwordFieldType, setPasswordFieldType] = React.useState<
    'text' | 'password'
  >('password');
  const {
    register,
    formState: { errors },
  } = useFormContext<FormData>();

  const handlePasswordTypeToggle = () => {
    if (passwordFieldType === 'text') {
      setPasswordFieldType('password');
    } else {
      setPasswordFieldType('text');
    }
  };

  return (
    <Grid container direction="column" spacing={2} mb={1}>
      <Grid item alignSelf="center">
        <Typography
          textAlign="center"
          variant="h6"
          fontWeight="fontWeightRegular"
          mb={1}
        >
          Please enter your <strong>{selectedProvider.name}</strong> credentials
        </Typography>
        <Typography
          textAlign={'center'}
          variant="caption"
          color="text.hint"
          component="p"
        >
          Your credentials will be used to establish a secure connection.
          <p>{getCredentialsUsageHelperText(selectedProvider.tsp)}</p>
        </Typography>
      </Grid>
      <Grid item flexGrow={1}>
        <form className={classes.loginForm}>
          <InputWithLabel
            label="Username"
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            inputLabelProps={{ color: 'primary' }}
            placeholder="Username"
            fullWidth
            {...register('username', { required: true })}
            error={!!errors.username}
          />

          <InputWithLabel
            label="Password"
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            inputLabelProps={{ color: 'primary' }}
            placeholder="Password"
            type={passwordFieldType}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton size="small" onClick={handlePasswordTypeToggle}>
                  <img src={IconEye} alt="Toggle Password Visibility" />
                </IconButton>
              </InputAdornment>
            }
            {...register('password', { required: true })}
            error={!!errors.password}
          />
          <Typography variant="caption" color="text.hint" component={'p'}>
            Forgot your password? Click{' '}
            <Link
              href={getForgotPasswordLink(selectedProvider.tsp)}
              target="_blank"
            >
              here
            </Link>{' '}
            to reset it.
          </Typography>

          {selectedProvider.consentKind ===
            TelematicsConsentKind.TelematicsConsentKindBasicAuthWithLoginId && (
            <>
              <InputWithLabel
                label="Company ID"
                formControlProps={{
                  fullWidth: true,
                  className: classes.formControl,
                }}
                inputLabelProps={{ color: 'primary' }}
                placeholder="Company ID"
                fullWidth
                {...register('loginId', { required: false })}
                error={!!errors.loginId}
              />
              <FormHelperText>
                {getLoginIdFieldHelperText(selectedProvider.tsp)}
              </FormHelperText>
              <Typography
                textAlign="center"
                variant="caption"
                color="secondary.dark"
                component={'p'}
                fontWeight="fontWeightMedium"
                className="flex items-center justify-center"
                mt={4}
              >
                Need help in finding your Company ID?&nbsp;
                <Link
                  href={getLoginIdHelpUrl(selectedProvider.tsp)}
                  target="_blank"
                  fontWeight="fontWeightSemiBold"
                  className="flex items-end -mt-[1px]"
                >
                  Learn more
                  <img src={IconExternalLink} alt="External Link" />
                </Link>
              </Typography>
            </>
          )}

          <Show when={!!connectionError}>
            <Typography
              color="error"
              variant="caption"
              component={'p'}
              lineHeight={1.25}
              className="mt-2"
            >
              {getConnectionErrorMessage(connectionError)}
            </Typography>
          </Show>
        </form>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
