import { UserProfileResponse } from '@nirvana/api/auth';
import { usePostHog } from 'posthog-js/react';

export interface ISegmentTrackPageView {
  category?: string;
  name: string;
  properties?: any;
  options?: any;
}

export const trackPageView = (request: ISegmentTrackPageView) => {
  window.analytics.page(
    request.category,
    request.name,
    request.properties,
    request.options,
  );
};

export interface ISegmentTrackUserIdentify {
  userId: string;
  traits: any;
  options?: any;
}

export const trackUserIdentify = (request: ISegmentTrackUserIdentify) => {
  window.analytics.identify(request.userId, request.traits, request.options);
};

export const useAnalytics = () => {
  const posthog = usePostHog();

  const identify = (user: UserProfileResponse) => {
    posthog?.identify(user.id, user);
  };

  const resetIdentity = () => {
    posthog?.reset();
  };

  const enableCapture = () => {
    posthog?.opt_in_capturing();
  };

  const disableCapture = () => {
    posthog?.opt_out_capturing();
  };

  const capture = (eventName: string, properties?: Record<string, any>) => {
    if (!posthog?.has_opted_out_capturing()) {
      posthog?.capture(eventName, properties);
    }
  };

  return {
    identify,
    resetIdentity,
    disableCapture,
    enableCapture,
    capture,
  };
};

export enum AnalyticsEvent {
  TelematicsConsentSafetyClick = 'EventTelematicsConsentScreenSafetyLinkClicked',
  QuotingTelematicsOverviewResourceOpen = 'QuotingTelematicsOverviewResourceOpen',
}

export interface ISegmentTrackEvent {
  event: AnalyticsEvent;
  properties?: any;
  options?: any;
}

export const trackEvent = (request: ISegmentTrackEvent) => {
  const properties = request.properties !== undefined ? request.properties : {};
  const options = request.options !== undefined ? request.options : {};
  // Set default Slack integration to false
  const defaultIntegrations = { All: true, Slack: false };
  options.integrations =
    options.integrations !== undefined
      ? { ...defaultIntegrations, ...options.integrations }
      : defaultIntegrations;
  window.analytics.track(request.event, properties, options);
};
