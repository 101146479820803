import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form-v7';
import {
  CoverageOption,
  CoverageOptionMetadata,
  CoverageType,
} from '@nirvana/api/non-fleet';
import { Checkbox, InputNumeric, Show } from '@nirvana/ui-kit';

import { getCoverageOption, isMandatoryCoverage } from './utils';

const AutoPhysicalDamage = ({
  coverages,
  metadata,
  onSubmit,
}: {
  coverages: CoverageOption[];
  metadata?: CoverageOptionMetadata;
  onSubmit: () => void;
}) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const values = watch('coveragesForm.ancillaryCovs') ?? {};

  const apdCoverage = getCoverageOption(
    coverages,
    CoverageType.CoverageAutoPhysicalDamage,
  );
  const towingLaborStorageCoverage = getCoverageOption(
    coverages,
    CoverageType.CoverageTowingLaborAndStorage,
  );
  const nonOwnedTrailerCoverage = getCoverageOption(
    coverages,
    CoverageType.CoverageNonOwnedTrailer,
  );

  if (!apdCoverage) return null;

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={5}>
            <Box paddingTop="10px">
              <InputLabel htmlFor="apd">Auto Physical Damage</InputLabel>
              <FormHelperText style={{ width: 288 }}>
                Coverages that apply to the APD policy
              </FormHelperText>
            </Box>
          </Grid>

          <Grid item xs={7} container spacing={0.5}>
            <Grid item container alignItems="center" spacing={4}>
              <Grid item xs={5}>
                <FormHelperText>Deductible</FormHelperText>
              </Grid>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              spacing={4}
              flexWrap="nowrap"
            >
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <input
                    {...register(
                      `coveragesForm.primaryCovs.${CoverageType.CoverageAutoPhysicalDamage}.coverageType`,
                    )}
                    type="hidden"
                    value={CoverageType.CoverageAutoPhysicalDamage}
                  />
                  <input
                    {...register(
                      `coveragesForm.primaryCovs.${CoverageType.CoverageAutoPhysicalDamage}.isRequired`,
                      { setValueAs: (v) => Boolean(v) },
                    )}
                    type="hidden"
                    value={1}
                  />

                  <Controller
                    control={control}
                    name={`coveragesForm.primaryCovs.${CoverageType.CoverageAutoPhysicalDamage}.deductible`}
                    defaultValue={apdCoverage?.defaultDeductible}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          variant="outlined"
                          value={value}
                          onChange={(e) => {
                            onChange(+e.target.value);

                            onSubmit();
                          }}
                        >
                          {apdCoverage?.deductibleOptions?.map((record) => (
                            <MenuItem
                              value={record}
                              key={`apd-deductible-${record}`}
                            >
                              <NumberFormat
                                value={record}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Show when={towingLaborStorageCoverage}>
          <Grid item container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={5} />

            <Grid item xs={4}>
              <input
                {...register(
                  `coveragesForm.ancillaryCovs.${CoverageType.CoverageTowingLaborAndStorage}.coverageType`,
                )}
                type="hidden"
                value={CoverageType.CoverageTowingLaborAndStorage}
              />
              <Controller
                control={control}
                defaultValue={false}
                name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageTowingLaborAndStorage}.isRequired`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        onSubmit();
                      }}
                      disabled={isMandatoryCoverage(
                        CoverageType.CoverageTowingLaborAndStorage,
                        metadata,
                      )}
                    >
                      Towing, labor & storage coverage
                    </Checkbox>
                  );
                }}
              />
            </Grid>

            <Grid item xs={3} container spacing={0.5} direction="column">
              <Grid item>
                <FormHelperText>Limit</FormHelperText>
              </Grid>

              <Grid item>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageTowingLaborAndStorage}.limit`}
                    defaultValue={towingLaborStorageCoverage?.defaultLimit}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          variant="outlined"
                          value={value}
                          onChange={(e) => {
                            onChange(+e.target.value);

                            onSubmit();
                          }}
                          disabled={
                            !values[CoverageType.CoverageTowingLaborAndStorage]
                              ?.isRequired
                          }
                        >
                          {towingLaborStorageCoverage?.limitOptions?.map(
                            (record) => (
                              <MenuItem
                                value={record}
                                key={`towing-limit-${record}`}
                              >
                                <NumberFormat
                                  value={record}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                />
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Show>

        <Show when={nonOwnedTrailerCoverage}>
          <Grid item container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={5} />

            <Grid item xs={4}>
              <input
                {...register(
                  `coveragesForm.ancillaryCovs.${CoverageType.CoverageNonOwnedTrailer}.coverageType`,
                )}
                type="hidden"
                value={CoverageType.CoverageNonOwnedTrailer}
              />
              <Controller
                control={control}
                defaultValue={false}
                name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageNonOwnedTrailer}.isRequired`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        onSubmit();
                      }}
                      disabled={isMandatoryCoverage(
                        CoverageType.CoverageNonOwnedTrailer,
                        metadata,
                      )}
                    >
                      Non-owned trailer
                    </Checkbox>
                  );
                }}
              />
            </Grid>

            <Grid item xs={3} container spacing={0.5} direction="column">
              <Grid item>
                <FormHelperText>
                  Limit&nbsp;
                  <Tooltip title="This is the total insured value for each non-owned trailer">
                    <InfoOutlined className="text-info-main" fontSize="small" />
                  </Tooltip>
                </FormHelperText>
              </Grid>

              <Grid item>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    defaultValue={0}
                    name={`coveragesForm.ancillaryCovs.${CoverageType.CoverageNonOwnedTrailer}.limit`}
                    rules={{
                      validate: (v) => {
                        if (v && v > 150000) {
                          return 'Must be $150K or lower';
                        }

                        return true;
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputNumeric
                          prefix="$"
                          placeholder="e.g. $90,000"
                          value={value}
                          onChange={(e) => onChange(+e.target.value)}
                          onBlur={onSubmit}
                          disabled={
                            !values[CoverageType.CoverageNonOwnedTrailer]
                              ?.isRequired
                          }
                          error={
                            !!(errors?.coveragesForm as any)?.ancillaryCovs?.[
                              CoverageType.CoverageNonOwnedTrailer
                            ]?.limit?.message
                          }
                        />
                      );
                    }}
                  />
                  {!!(errors?.coveragesForm as any)?.ancillaryCovs?.[
                    CoverageType.CoverageNonOwnedTrailer
                  ]?.limit?.message && (
                    <FormHelperText error>
                      {
                        (errors?.coveragesForm as any)?.ancillaryCovs?.[
                          CoverageType.CoverageNonOwnedTrailer
                        ]?.limit?.message
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Show>
      </Grid>

      <Divider sx={{ mt: 4 }} />
    </>
  );
};

export default AutoPhysicalDamage;
