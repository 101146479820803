import React from 'react';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  Box,
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { TSP, TSPRecord, TelematicsConsentKind } from '@nirvana/api/quoting';

import { ITheme } from 'src/assets/themes';
import { InputWithLabel } from 'src/components/input';
import { Show } from '@nirvana/ui-kit';

const useStyles = makeStyles((theme: ITheme) => ({
  dialogPaper: {
    width: theme.typography.pxToRem(500),
  },
  radioGroup: {
    flexDirection: 'column',
  },
  formControlLabel: {
    marginRight: theme.spacing(0),
    borderBottom: '1px solid #E5E7EF',
    marginLeft: 0,
    color: theme.palette.text.primary,

    '&:last-child': {
      border: 0,
      color: theme.palette.primary.main,
    },
  },
  radioSelected: {
    border: '1px solid #8091C4',
    boxShadow: '0px 4px 6px rgba(0, 36, 138, 0.08)',
    borderRadius: '5px',
    background: theme.palette.primary.extraLight,

    '&:last-child': {
      border: '1px solid #8091C4',
      color: theme.palette.primary.main,
    },
  },
  radioLabel: {
    ...theme.typography.body1,
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
  },
  otherContainer: {
    marginTop: theme.spacing(2.5),
  },
  otherSelected: {
    padding: theme.spacing(1, 2),
  },
  otherRadioLabel: {
    color: theme.palette.primary.main,
  },
}));

interface VendorDialogProps {
  open: boolean;
  onClose: () => void;
  list: TSPRecord[];
  value?: TSP;
  onChange: (provider: TSPRecord) => void;
  otherValue?: string;
  onOtherChange: (value?: string) => void;
}

const VendorDialog = ({
  open,
  onClose,
  list,
  value,
  onChange,
  otherValue,
  onOtherChange,
}: VendorDialogProps) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const otherInputError = !(otherValue ?? '').trim();
  const filteredList = React.useMemo(() => {
    let sortableList = list;

    if (searchValue) {
      sortableList = list.filter((record) => {
        return record.name.toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    sortableList = sortableList.filter((record) => record.tsp !== TSP.TspOther);

    sortableList = sortableList.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    // Other always exists at the end of the list
    if (!searchValue && !otherValue && value !== TSP.TspOther) {
      sortableList = sortableList.concat([
        {
          tsp: TSP.TspOther,
          consentKind: TelematicsConsentKind.TelematicsConsentKindSpeedgauge,
          name: 'Other / Provider Not in the List',
        },
      ]);
    }

    return sortableList;
  }, [list, searchValue, otherValue, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const matchedProvider = list.find(
      (record) => record.tsp === event.target.value,
    );

    if (matchedProvider) {
      onChange(matchedProvider);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        sx={{
          position: 'relative',
        }}
      >
        <Typography variant="h5" color="textPrimary">
          Select your telematics vendor
        </Typography>

        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="small"
          >
            <Close />
          </IconButton>
        ) : null}

        <OutlinedInput
          fullWidth
          className="mt-8"
          placeholder="Search"
          onChange={handleSearch}
          endAdornment={
            <InputAdornment>
              <Search />
            </InputAdornment>
          }
        />
      </DialogTitle>
      <DialogContent dividers sx={{ borderTop: 0 }}>
        <Grid
          container
          direction="column"
          spacing={4}
          className="pt-[24px] pl-[24px]"
        >
          <RadioGroup
            aria-label="coverage"
            value={value}
            onChange={handleChange}
            className={classes.radioGroup}
          >
            {filteredList.map((vendor) => {
              if (!vendor) return null;

              return (
                <FormControlLabel
                  key={vendor.tsp}
                  value={vendor.tsp}
                  control={<Radio color="primary" size="small" />}
                  label={vendor.name}
                  classes={{
                    root: clsx(classes.formControlLabel, {
                      [classes.radioSelected]: vendor.tsp === value,
                    }),
                    label: classes.radioLabel,
                  }}
                />
              );
            })}

            <Show
              when={!!searchValue || !!otherValue || value === TSP.TspOther}
            >
              <Box className={classes.otherContainer}>
                <Typography
                  component="p"
                  variant="caption"
                  color="text-primary"
                  mb={2}
                >
                  If your provider is not listed, select “Other” and enter its
                  name.
                </Typography>

                <Box
                  className={clsx(
                    { [classes.radioSelected]: TSP.TspOther === value },
                    classes.otherSelected,
                  )}
                >
                  <FormControlLabel
                    value={TSP.TspOther}
                    control={<Radio color="primary" size="small" />}
                    label={'Other / Provider Not in the List'}
                    classes={{
                      label: clsx(classes.radioLabel, classes.otherRadioLabel),
                    }}
                  />

                  <Show when={TSP.TspOther === value}>
                    <Box className="mt-2 ml-7">
                      <InputWithLabel
                        label="Telematics provider name"
                        formControlProps={{ fullWidth: true }}
                        value={otherValue}
                        onChange={(e) => onOtherChange(e.target.value)}
                        error={otherInputError}
                        helperText={
                          otherInputError
                            ? 'Please enter telematics provider name'
                            : ''
                        }
                      />
                    </Box>
                  </Show>
                </Box>
              </Box>
            </Show>
          </RadioGroup>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          type="button"
          color="primary"
          variant="contained"
          disabled={!value || (value === TSP.TspOther && otherInputError)}
          onClick={onClose}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VendorDialog;
