import { Flatfile } from '@flatfile/api';

const workbook: Pick<
  Flatfile.CreateWorkbookConfig,
  'name' | 'labels' | 'sheets' | 'actions'
> = {
  name: 'All Data',
  labels: ['pinned'],
  sheets: [
    {
      name: 'Equipment',
      slug: 'equipment',
      mappingConfidenceThreshold: 0.8,
      fields: [
        {
          key: 'vin',
          alternativeNames: [
            'Chassis',
            'VIN#',
            'VIN Number',
            '17 Digit VIN',
            '17 Digit VIN#',
            'Trailer#',
            'Serial',
            'Serial#',
            'Full VIN#',
          ],
          type: 'string',
          label: 'VIN',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'statedValue',
          alternativeNames: [
            'Amount',
            'Cost',
            'Renewal Value',
            'Stated Value',
            'Stated Amount',
            'Stated Amt',
            'Value',
            'Renewal',
            'Renewal Physical Damage',
            'Physical Damage Value',
            'Physical Damage Amount',
          ],
          type: 'number',
          label: 'Stated Value',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit',
      description: 'Submit equipments data',
      primary: true,
      requireAllValid: true,
    },
  ],
};

export default workbook;
