import {
  AppetiteForm,
  PreApplicationChecksRuleRequest,
} from '@nirvana/api/quoting';
import {
  ApplicationCreationForm,
  ApplicationUpdateForm,
} from '@nirvana/api/non-fleet';
import apiServiceNF from 'src/helpers/apiService';
import apiService from 'src/helpers/fleetApiService';

export const prefillApplication = async ({
  dotNumber,
}: {
  dotNumber: number;
}) => {
  const { data } = await apiService.applicationPrefillDotNumberDotNumberGet(
    dotNumber,
  );
  return data;
};

export const fetchAvailableProducers = async () => {
  const { data } = await apiService.availableProducersGet();
  return data;
};

export const createApplicationFleet = async (payload: AppetiteForm) => {
  const { data } = await apiService.applicationPost(payload);
  return data;
};

export const createApplicationNonFleet = async (
  payload: ApplicationCreationForm,
) => {
  const { data } = await apiServiceNF.postApplication(payload);
  return data;
};

export const fetchApplicationDetails = async (applicationId: string) => {
  const { data } = await apiServiceNF.getApplication(applicationId);
  return data;
};

export const runPreCreateValidationChecks = async (
  payload: PreApplicationChecksRuleRequest,
) => {
  const { data } = await apiService.applicationPreCreateValidatePost(payload);
  return data;
};

export const fetchNonFleetFleetExistingApplicationMetadata = async ({
  dotNumber,
  producerId,
}: {
  dotNumber: number;
  producerId: any;
}) => {
  const { data } = await apiServiceNF.nonFleetApplicationPreCreateValidatePost(
    dotNumber,
    producerId,
  );
  return data;
};

export const updateApplicationDetails = async ({
  applicationId,
  payload,
}: {
  applicationId: string;
  payload: ApplicationUpdateForm;
}) => {
  const { data } = await apiServiceNF.patchApplication(applicationId, payload);
  return data;
};

export const submitQuote = async (applicationId: string) => {
  const { data } = await apiServiceNF.postQuoteSubmit(applicationId);
  return data;
};

export const fetchIndication = async (applicationId: string) => {
  const { data } = await apiServiceNF.getQuoteIndication(applicationId);
  return data;
};

export const fetchClassesAndCommodities = async () => {
  const { data } = await apiServiceNF.getClassesAndCommoditiesList();
  return data;
};

export const fetchVehicleInfo = async ({ vins }: { vins: string }) => {
  const { data } = await apiServiceNF.getVehicleInfo(vins);
  return data;
};

export const submitApplication = async (applicationId: string) => {
  const { data } = await apiServiceNF.postApplicationSubmit(applicationId);
  return data;
};
