import clsx from 'clsx';
import { Box, Grid, Typography } from '@material-ui/core';
import { TelematicsInfo } from '@nirvana/api/non-fleet';

import Button from 'src/components/button';
import PiggyBank from 'src/assets/images/piggy-bank.png';

import TelematicsConnected from '../plans/telematics-connected';
import { useStyles } from './styles';

interface ITelematicsCard {
  telematicsInfo?: TelematicsInfo;
  onEditTelematics: () => void;
  error?: boolean;
}

function TelematicsCard({
  telematicsInfo,
  onEditTelematics,
  error,
}: ITelematicsCard) {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, {
        [classes.error]: error,
      })}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item md={3}>
          <img src={PiggyBank} alt="Savings" width="184" />
        </Grid>
        <Grid item flexGrow={1} md={5}>
          <Typography variant="h6" fontWeight="fontWeightRegular">
            <Typography component="span" fontWeight="fontWeightBold">
              Save
            </Typography>{' '}
            up to{' '}
            <Typography
              component="span"
              color="success.dark"
              fontWeight="fontWeightBold"
            >
              20%
            </Typography>{' '}
            after connecting telematics
          </Typography>
          <Typography color="text.hint" variant="caption">
            Telematics connection is required to quote
          </Typography>
        </Grid>

        <Grid item md={4} display="flex" justifyContent="center">
          {telematicsInfo && telematicsInfo.link ? (
            <TelematicsConnected
              value={telematicsInfo}
              onEdit={onEditTelematics}
            />
          ) : (
            <Button
              variant="outlined"
              size="small"
              onClick={onEditTelematics}
              className={clsx({
                [classes.error]: error,
              })}
            >
              Connect Telematics
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default TelematicsCard;
