import * as React from 'react';
import LogRocket from 'logrocket';
import { useSelector } from 'react-redux';

import { utils as UtilsHelper } from 'src/helpers';
import { initSelector } from 'src/features/init/slices';

export default function Logrocket() {
  const init = useSelector(initSelector);
  const { user } = init;

  const token = UtilsHelper.getURLParameterByName('token');
  const state = UtilsHelper.getURLParameterByName('state');

  React.useEffect(() => {
    if (user) {
      LogRocket.init(import.meta.env.VITE_LOGROCKET_KEY);
      LogRocket.identify(user.id, { name: user.name, email: user.email });
    } else if (token) {
      LogRocket.init(import.meta.env.VITE_LOGROCKET_KEY);
      LogRocket.identify('', { applicationId: token });
    } else if (state) {
      LogRocket.init(import.meta.env.VITE_LOGROCKET_KEY);
      LogRocket.identify('', { state });
    }
  }, [user, token, state]);

  return null;
}
