import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Create } from '@material-ui/icons';
import {
  ApplicationDetail,
  ApplicationState,
  PotentialClearanceStatus,
} from '@nirvana/api/quoting';
import { ApplicationHeader, Show } from '@nirvana/ui-kit';
import { format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import IconApplicationStatus from 'src/assets/icons/application-status.svg';
import IconStepper from 'src/assets/icons/stepper.svg';
import { ITheme } from 'src/assets/themes';
import Button from 'src/components/button';
import ApplicationHeaderClearedApplicationExists from 'src/components/header/applicationHeaderClearedApplicationExists';
import ApplicationHeaderClearedApplicationExistsDeclined from 'src/components/header/applicationHeaderClearedApplicationExistsDeclined';
import { HorizontalStepper } from 'src/components/stepper';
import { getLastStep, getTimeRange } from 'src/helpers/statusTracker';
import { useDispatch } from 'src/redux';
import { fetchApplicationById } from '../../actions';
import {
  applicationSelector,
  fetchApplicationStatus,
  fetchQuoteData,
} from '../../slices';
import ConnectionDrawer from '../create/connectionDrawer';
import Review from '../create/review';

const useStyles = makeStyles((theme: ITheme) => ({
  card: {
    border: '1px solid #E6E7EF',
    boxShadow: '0px 4px 6px rgba(128, 145, 196, 0.16)',
  },
  cardHeader: {
    borderBottom: '1px solid #E6E7EF',
    backgroundColor: '#FBFCFE',
    padding: theme.spacing(2, 4),
  },
  cardHeaderContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type RouteParams = {
  applicationId?: string;
};

/**
 * Applications Review page after submission.
 * Corresponds to Landing Page (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A11817&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const Submitted = () => {
  const classes = useStyles();
  const params: RouteParams = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const application = useSelector(applicationSelector);

  const { activeApplication: details, applicationStatus } = application;
  const { applicationId = '' } = params;

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [isEditable, setEditbale] = React.useState<boolean>(false);

  const [drawerVisibility, setDrawerVisibility] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    // Fetch application information
    dispatch(fetchApplicationById(applicationId));
    dispatch(fetchApplicationStatus({ applicationId }));
    dispatch(fetchQuoteData({ applicationId }));
  }, [dispatch, applicationId]);

  const handleSectionEdit = (key: string) => {
    navigate(`/applications/${applicationId}/create`, {
      state: {
        activeStep: key,
      },
    });
  };

  const handleResendRequest = () => {
    setDrawerVisibility(true);
  };

  const steps = React.useMemo(() => {
    return [
      {
        key: 'statusSubmitted',
        label: (
          <>
            Application
            <br />
            Submitted
          </>
        ),
        extra: () => {
          if (applicationStatus?.statusSubmitted?.finalizedDate) {
            return format(
              parseISO(applicationStatus?.statusSubmitted?.finalizedDate),
              'MM/dd/yyyy',
            );
          } else return null;
        },
      },
      {
        key: 'statusWaitingELDTelematicsConsent',
        label:
          activeStep > 1
            ? 'ELD/Telematics consent recieved'
            : 'Waiting for ELD/Telematics consent',
        action: (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleResendRequest}
          >
            Resend Request
          </Button>
        ),
        extra: () => {
          if (
            applicationStatus?.statusWaitingELDTelematicsConsent.finalizedDate
          ) {
            return format(
              parseISO(
                applicationStatus?.statusWaitingELDTelematicsConsent
                  .finalizedDate,
              ),
              'MM/dd/yyyy',
            );
          } else return null;
        },
      },
      {
        key: 'statusWaitingELDTelematicsData',
        label:
          activeStep > 2
            ? 'ELD/Telematics data processed'
            : 'Processing ELD/Telematics data',

        extra: () => {
          if (applicationStatus?.statusWaitingELDTelematicsData.finalizedDate) {
            return format(
              parseISO(
                applicationStatus?.statusWaitingELDTelematicsData.finalizedDate,
              ),
              'MM/dd/yyyy',
            );
          } else if (
            applicationStatus?.statusWaitingELDTelematicsData
              .maxEstimatedDays &&
            applicationStatus?.statusWaitingELDTelematicsData.minEstimatedDays
          ) {
            return (
              <>
                Data should be received in the next
                {getTimeRange(
                  applicationStatus?.statusWaitingELDTelematicsData
                    .minEstimatedDays,
                  applicationStatus?.statusWaitingELDTelematicsData
                    .maxEstimatedDays,
                )}
              </>
            );
          } else return null;
        },
      },
      {
        key: 'statusUnderReviewForQuote',
        label:
          activeStep > 3 ? (
            <>
              Underwriter
              <br />
              Reviewed
            </>
          ) : (
            <>
              Underwriter
              <br />
              Review
            </>
          ),
        extra: () => {
          if (applicationStatus?.statusUnderReviewForQuote.finalizedDate) {
            return format(
              parseISO(
                applicationStatus?.statusUnderReviewForQuote.finalizedDate,
              ),
              'MM/dd/yyyy',
            );
          }
          return null;
        },
      },
      {
        key: 'statusQuoteFinalized',
        label: (
          <>
            Quote
            <br />
            Finalized
          </>
        ),
        action: (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate(`/applications/${applicationId}/quote`);
            }}
          >
            View Quote
          </Button>
        ),
      },
    ];
  }, [
    activeStep,
    applicationId,
    applicationStatus?.statusSubmitted?.finalizedDate,
    applicationStatus?.statusUnderReviewForQuote.finalizedDate,
    applicationStatus?.statusUnderReviewForQuote.maxEstimatedDays,
    applicationStatus?.statusUnderReviewForQuote.minEstimatedDays,
    applicationStatus?.statusWaitingELDTelematicsConsent.finalizedDate,
    applicationStatus?.statusWaitingELDTelematicsData.finalizedDate,
    applicationStatus?.statusWaitingELDTelematicsData.maxEstimatedDays,
    applicationStatus?.statusWaitingELDTelematicsData.minEstimatedDays,
    navigate,
  ]);

  const isQuoteGenerated = () => {
    return (
      details?.summary.state ===
        ApplicationState.ApplicationStateQuoteGenerated ||
      details?.summary.state === ApplicationState.ApplicationStateBound
    );
  };

  useEffect(() => {
    if (applicationStatus) {
      setActiveStep(getLastStep(applicationStatus, steps));
    }
  }, [applicationStatus, steps]);

  return (
    <>
      <ApplicationHeader
        details={details || ({} as ApplicationDetail)}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />
      <Show
        when={
          details?.summary?.clearanceStatus ===
          PotentialClearanceStatus.ClearedApplicationExists
        }
      >
        <ApplicationHeaderClearedApplicationExists isSameAgency={false} />
      </Show>

      <Show
        when={
          details?.summary?.clearanceStatus ===
          PotentialClearanceStatus.ClearedApplicationExistsSameAgency
        }
      >
        <ApplicationHeaderClearedApplicationExists isSameAgency />
      </Show>

      <Show
        when={
          details?.summary?.clearanceStatus ===
          PotentialClearanceStatus.DeclinedClearedApplicationExists
        }
      >
        <ApplicationHeaderClearedApplicationExistsDeclined />
      </Show>

      <Container maxWidth="md">
        <Box py={3}>
          <Grid container direction="column" spacing={5}>
            <Grid item>
              <Card>
                <CardHeader
                  title={
                    <>
                      <img
                        src={IconApplicationStatus}
                        alt="Application Status Icon"
                      />
                      <Box px={3} flexGrow={1}>
                        <Typography variant="h5" color="textSecondary">
                          Application Status
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <img src={IconStepper} alt="Active Step Icon" />
                        <Typography variant="body1" color="text.hint" ml={1}>
                          {steps[activeStep].label}
                        </Typography>
                      </Box>
                    </>
                  }
                  disableTypography
                  classes={{
                    root: classes.cardHeader,
                    content: classes.cardHeaderContent,
                  }}
                />
                <CardContent>
                  <Box p={2} pt={10}>
                    <HorizontalStepper
                      steps={steps}
                      activeStep={activeStep}
                      onChange={() => null}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Review
                coverages={
                  details?.indicationForm?.operationsForm?.coveragesRequired?.map(
                    (record) => record.coverageType,
                  ) || []
                }
                onEdit={handleSectionEdit}
                isEditable={isEditable}
                title={
                  isQuoteGenerated()
                    ? 'Application'
                    : 'Application under review'
                }
                subtitle={
                  isQuoteGenerated() ? null : (
                    <>
                      Your application was submitted successfully and is under
                      review.
                      <br />
                      Any changes to the application may change the quote.
                    </>
                  )
                }
                showUWChanges
                action={
                  <Show when={!isEditable && !isQuoteGenerated()}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<Create />}
                      onClick={() => setEditbale(true)}
                    >
                      Modify Application
                    </Button>
                  </Show>
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ConnectionDrawer
        open={drawerVisibility}
        onClose={() => setDrawerVisibility(false)}
        applicationId={applicationId}
        telematicsInfo={details?.telematicsInfo}
      />
    </>
  );
};

export default Submitted;
