import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { ApplicationDetailsProvider } from '../hooks/useApplicationDetails';
import { AppConstantsProvider } from '../hooks/useConstants';

const ApplicationContainer: FC = ({ children }) => {
  const { applicationId = '' } = useParams();

  return (
    <ApplicationDetailsProvider applicationId={applicationId}>
      <AppConstantsProvider>{children}</AppConstantsProvider>
    </ApplicationDetailsProvider>
  );
};

export default ApplicationContainer;
