import { ReactNode } from 'react';
import { PageState } from '@nirvana/api/non-fleet';

import Driver from '../driver';
import Equipment from '../equipment';
import Operations from '../operations';
import Indication from '../indication';
import Review from '../review';

const noop = () => {};

export type Step = {
  key: string;
  title: string;
  subtitle: string;
  pageState: PageState;
  render: () => ReactNode;
};

export const getSteps = (
  applicationId: string,
  {
    onIndicationLoadingChange,
    onEdit,
  }: {
    onIndicationLoadingChange?: (isLoading: boolean) => void;
    onEdit?: (key: string) => void;
  },
): Step[] => [
  {
    key: 'operations',
    title: 'Operations',
    subtitle: 'Let’s enter information about the insured’s business',
    pageState: PageState.PageStateOperations,
    render: () => <Operations />,
  },
  {
    key: 'equipment',
    title: 'Equipment',
    subtitle: 'Please add all power units and trailers on the policy',
    pageState: PageState.PageStateEquipments,
    render: () => <Equipment />,
  },
  {
    key: 'drivers',
    title: 'Drivers',
    subtitle: 'Please enter all drivers who will be on the policy',
    pageState: PageState.PageStateDrivers,
    render: () => <Driver />,
  },
  {
    key: 'indication',
    title: 'Indication',
    subtitle:
      'Choose a preliminary indication. Although we expect it to be close, the final quote is subject to underwriter approval once the application is submitted.',
    pageState: PageState.PageStateSubmitted,
    render: () => (
      <Indication
        applicationId={applicationId}
        onLoadingChange={onIndicationLoadingChange}
      />
    ),
  },
  {
    key: 'review',
    title: 'Review',
    subtitle: 'You’re almost there! Review the application before you submit',
    pageState: PageState.PageStateReview,
    render: () => <Review onEdit={onEdit || noop} />,
  },
];
