import React from 'react';
import {
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import IconBack from 'src/assets/icons/arrow-left.svg';

interface IHelperProps {
  heading: string;
  text: React.ReactNode | string;
}

const useStyles = makeStyles(() => ({
  grid: {
    position: 'absolute',
    right: 395,
    top: 12,
  },
}));

const PasswordHelper = ({ heading, text }: IHelperProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Grid item className={classes.grid}>
        <InputAdornment>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={IconBack} alt="Navigate Back" />
          </IconButton>
        </InputAdornment>
      </Grid>
      <Grid item container direction="column" spacing={5}>
        <Grid item mb={2}>
          <Typography variant="h5">{heading}</Typography>
          <Typography variant="caption" color="text.hint">
            {text}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PasswordHelper;
