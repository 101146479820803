/* global google */

// Find street from Google Place Details
const getStreet = (placeDetails: google.maps.places.PlaceResult | null) => {
  return placeDetails?.name ?? '';
};

// Find ZIP code from address_components of Google Place Details
const getZipCode = (
  addressComponents: google.maps.GeocoderAddressComponent[],
) => {
  return (
    addressComponents.find((component) =>
      component.types.includes('postal_code'),
    )?.short_name ?? ''
  );
};

// Find city from address_components of Google Place Details
const getCity = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  return (
    addressComponents.find((component) => component.types.includes('locality'))
      ?.long_name ?? ''
  );
};

// Find state shortname from address_components of Google Place Details
const getStateShortname = (
  addressComponents: google.maps.GeocoderAddressComponent[],
) => {
  return (
    addressComponents.find((component) =>
      component.types.includes('administrative_area_level_1'),
    )?.short_name ?? ''
  );
};

// Find state longname from address_components of Google Place Details
const getStateLongname = (
  addressComponents: google.maps.GeocoderAddressComponent[],
) => {
  return (
    addressComponents.find((component) =>
      component.types.includes('administrative_area_level_1'),
    )?.long_name ?? ''
  );
};

export { getStreet, getZipCode, getCity, getStateShortname, getStateLongname };
