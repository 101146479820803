import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form-v7';
import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  Paper,
} from '@material-ui/core';
import {
  ArrowBackIosRounded as ArrowBackIosRoundedIcon,
  ArrowForwardIosRounded as ArrowForwardIosRoundedIcon,
} from '@material-ui/icons';
import { NRBAppUpdateForm } from '@nirvana/api/non-fleet';
import { ApplicationDetail } from '@nirvana/api/quoting';
import { ApplicationHeader } from '@nirvana/ui-kit';

import Button from 'src/components/button';

import { getSteps } from './constants/steps';
import PageHeader from './pageHeader';
import { useStyles } from './styles';
import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';
import {
  submitQuote,
  updateApplicationDetails,
} from '../../queries/application';

const ApplicationCreate = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { applicationId = '' } = useParams();
  const { applicationDetails, isLoading: isLoadingApplicationDetails } =
    useApplicationDetailsContext();

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const Steps = useMemo(() => getSteps(applicationId), [applicationId]);
  const activeStep = Steps[activeStepIndex];
  const nextStep = Steps[Math.min(activeStepIndex + 1, Steps.length - 1)];
  const methods = useForm();
  const { handleSubmit, reset, setError } = methods;

  const handlePrevious = () => {
    setActiveStepIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const queryClient = useQueryClient();

  const { mutate: submitMutation } = useMutation(submitQuote, {
    onSuccess: () => {
      navigate(`/nrb/${applicationId}/indication`);
    },
  });

  const { mutate, isLoading: isUpdatingApplication } = useMutation(
    updateApplicationDetails,
    {
      onSuccess: (data) => {
        if (data.appStatus === 'AppStateDeclined') {
          navigate(`/nrb/${applicationId}/decline`);
        } else if (activeStepIndex < 3) {
          setActiveStepIndex((prev) => prev + 1);
        } else {
          // Submit application
          submitMutation(applicationId);
        }

        queryClient.invalidateQueries(['application', applicationId]);
      },
      onError: () => {
        navigate(`/nrb/${applicationId}/decline`);
      },
    },
  );

  const onSubmit = (data: NRBAppUpdateForm) => {
    delete data.operationsForm?.effectiveDate;

    // Commodities distribution validation
    if (
      activeStepIndex === 1 &&
      data.classesAndCommoditiesForm?.commodityInfo?.commodityDistribution &&
      data.classesAndCommoditiesForm?.commodityInfo?.commodityDistribution
        ?.length
    ) {
      // Calculate total and set error
      const total =
        data.classesAndCommoditiesForm?.commodityInfo?.commodityDistribution?.reduce(
          (acc, { category, percentage }) => {
            if (category && percentage) {
              return acc + percentage;
            }

            return acc;
          },
          0,
        );

      if (total !== 100) {
        setError(
          'classesAndCommoditiesForm.commodityInfo.commodityDistribution',
          {
            type: 'manual',
            message: 'Total percentage must be 100',
          },
        );

        return;
      }
    }

    mutate({
      applicationId,
      payload: {
        pageState: nextStep.pageState,
        nrb: {
          operationsForm: data.operationsForm,
          classesAndCommoditiesForm: {
            ...data.classesAndCommoditiesForm,
            commodityInfo: {
              ...data.classesAndCommoditiesForm?.commodityInfo,
              commodityDistribution:
                data.classesAndCommoditiesForm?.commodityInfo?.commodityDistribution?.filter(
                  ({ category }) => category,
                ),
            },
          },
          equipmentsForm: data.equipmentsForm,
          driversForm: data.driversForm,
        },
      },
    });
  };

  useEffect(() => {
    // Find step with matching pageState
    const stepIndex = Steps.findIndex(
      (step) => step.pageState === applicationDetails?.pageState,
    );

    // Set active step index based on pageState
    if (stepIndex >= 0) {
      setActiveStepIndex(stepIndex);
    }
  }, [Steps, applicationDetails?.pageState]);

  useEffect(() => {
    reset(applicationDetails?.nrb);
  }, [applicationDetails, reset]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStepIndex]);

  if (isLoadingApplicationDetails) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ApplicationHeader
        details={
          {
            summary: {
              shortID: applicationDetails?.shortID,
              companyName:
                applicationDetails?.nrb?.operationsForm?.companyInfo?.name,
              dotNumber:
                applicationDetails?.nrb?.operationsForm?.companyInfo?.dotNumber,
              underwriterName: 'Taylor Gosiaco',

              underwriterEmail: 'taylor@nirvanatech.com',
            },
          } as ApplicationDetail
        }
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />

      <Paper elevation={0} square sx={{ position: 'relative', flex: 1 }}>
        <Container>
          <Box py={5} px={3}>
            <PageHeader
              title={activeStep.title}
              subtitle={activeStep.subtitle}
              activeStepIndex={activeStepIndex}
            />

            <FormProvider {...methods}>{activeStep.render()}</FormProvider>
          </Box>
        </Container>
      </Paper>

      {/* Spacer to compensate for height of fixed bottom bar */}
      <Box py="32px" />

      {/* Sticky bottom bar  */}
      <AppBar
        classes={{ root: classes.footerToolbar }}
        color="inherit"
        elevation={0}
        position="fixed"
        sx={{ top: 'auto', bottom: 0, py: 1 }}
      >
        <Container>
          <Box display="flex" justifyContent="flex-end">
            {activeStepIndex >= 1 ? (
              <Box mx={1}>
                <Button
                  startIcon={<ArrowBackIosRoundedIcon />}
                  variant="outlined"
                  onClick={handlePrevious}
                >
                  Previous
                </Button>
              </Box>
            ) : null}

            <Button
              endIcon={<ArrowForwardIosRoundedIcon />}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isUpdatingApplication}
            >
              {activeStepIndex === 4 ? 'Submit' : 'Proceed'}
            </Button>
          </Box>
        </Container>
      </AppBar>
    </>
  );
};

export default ApplicationCreate;
