import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { UserProfileResponse } from '@nirvana/api/auth';

import { RootState } from 'src/redux/reducers';

export interface ApplicationError {
  message: string;
}

export interface InitState {
  isInitializing: boolean;
  user?: UserProfileResponse;
  error?: ApplicationError;
}

export const initialState: InitState = {
  isInitializing: true,
};

const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    initComplete: (state) => {
      state.isInitializing = false;
    },
    setUser: (state, { payload }: PayloadAction<UserProfileResponse>) => {
      state.user = payload;
    },
    setError: (state, { payload }: PayloadAction<ApplicationError>) => {
      state.error = payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = initSlice;

// Extract and export each action creator by name
export const { initComplete, setUser, setError } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const initSelector = (state: RootState) => state.init;
