import { FormControl, FormHelperText } from '@material-ui/core';
import {
  GetEndorsementRequestResponse,
  GetInsuranceBundleResponse,
} from '@nirvana/api/endorsementapp';
import { DatePicker, Show } from '@nirvana/ui-kit';
import { parseISO, sub } from 'date-fns';
import { useMemo } from 'react';
import EndorsementIcon from 'src/assets/icons/endorsement.svg';

type EndorsementSidebarProps = {
  endorsementDetails?: GetEndorsementRequestResponse;
  policyDetails?: GetInsuranceBundleResponse;
  effectiveDate?: Date;
  onEffectiveDateChange?: (date?: Date) => void;
  effectiveDateError?: string;
};

const EndorsementSidebar = ({
  endorsementDetails,
  policyDetails,
  effectiveDate,
  onEffectiveDateChange,
  effectiveDateError,
}: EndorsementSidebarProps) => {
  const filteredChanges = useMemo(() => {
    return endorsementDetails?.changes?.filter(
      (change) => change.descriptions.length,
    );
  }, [endorsementDetails?.changes]);

  return (
    <>
      <div className="flex items-center mb-8">
        <div className="mr-4">
          <img src={EndorsementIcon} />
        </div>
        <div>
          <p className="text-sm">Endorsement ID</p>
          <p className="text-xl font-bold">#004</p>
        </div>
      </div>
      <FormControl fullWidth className="mb-8">
        <FormHelperText className="text-primary-main">
          Effective Date
        </FormHelperText>
        <DatePicker
          inputFormat="MM/dd/yyyy"
          value={effectiveDate || null}
          minDate={sub(new Date(), { days: 5 })}
          maxDate={
            policyDetails?.effectiveDateTo
              ? sub(parseISO(policyDetails?.effectiveDateTo), { days: 1 })
              : null
          }
          onChange={(e) => !!onEffectiveDateChange && onEffectiveDateChange(e)}
          PopperProps={{
            placement: 'bottom',
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['bottom', 'right', 'top'],
                },
              },
            ],
          }}
          InputProps={{
            placeholder: 'eg. 12/31/2021',
            fullWidth: true,
          }}
        />
        <FormHelperText error>{effectiveDateError}</FormHelperText>
      </FormControl>

      <Show
        when={!!filteredChanges?.length}
        fallback={
          <div
            className="flex items-center justify-center w-full bg-grey-100 h-[146px]"
            style={{ background: '#F9FAFC' }}
          >
            <p className="text-xs text-text-hint">No changes added yet</p>
          </div>
        }
      >
        <div className="flex flex-col gap-4">
          <p>Changes</p>
          {filteredChanges?.map((change, index) => (
            <div
              key={`change-${change.changeType}-${index}`}
              className="flex flex-col"
            >
              <p className="text-xs font-bold leading-5 text-black">
                {change.changeType}
              </p>
              <div>
                {change.descriptions.map((description, idx) => {
                  return (
                    <p
                      key={`change-desc-${change.changeType}-${index}-${idx}`}
                      className="text-xs leading-5 text-black"
                    >
                      {description}
                    </p>
                  );
                })}
              </div>
            </div>
          ))}
          <p className="text-xs leading-5 text-text-hint">
            Changes will be applied to all policies
          </p>
        </div>
      </Show>
    </>
  );
};

export default EndorsementSidebar;
