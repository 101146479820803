import * as React from 'react';
import { Grid, Skeleton, FormHelperText, InputLabel } from '@material-ui/core';

const LimitsSkeleton = () => {
  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={5}>
        <InputLabel>
          <Skeleton animation="pulse" width={150} />
        </InputLabel>
        <FormHelperText>
          <Skeleton animation="pulse" />
        </FormHelperText>
      </Grid>
      <Grid item xs={7}>
        <FormHelperText>
          <Skeleton animation="pulse" width={120} />
        </FormHelperText>
        <Skeleton animation="pulse" width={200} height={60} />
      </Grid>
    </Grid>
  );
};

export default LimitsSkeleton;
