import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  GridDirection,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { CheckboxIconText, InputNumeric } from '@nirvana/ui-kit';
import clsx from 'clsx';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IOperationClass } from 'src/features/application/slices/classesAndCommodities';

import { useParams } from 'react-router-dom';
import {
  CLASSES_AND_COMMODITIES_PAGE_OPERATING_CLASS_DISTRIBUTION_CHANGE,
  CLASSES_AND_COMMODITIES_PAGE_VIEW,
} from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import { useStyles } from './styles';
import { getOperatingClassErrorMessage } from './utils';

export interface IOperatingClasses {
  layout: GridDirection;
  list: IOperationClass[];
  totalFleetPercentage: number;
}

const OperatingClasses = ({
  layout,
  list,
  totalFleetPercentage,
}: IOperatingClasses) => {
  const classes = useStyles();
  const { capture } = useAnalytics();
  const { applicationId = '' } = useParams();
  const { control, errors, setValue, watch } = useFormContext();

  const operatingClassDistribution = watch('operatingClassDistribution');

  React.useEffect(() => {
    capture(CLASSES_AND_COMMODITIES_PAGE_VIEW, {
      operatingClassDistribution,
      applicationId,
    });
  }, []);

  React.useEffect(() => {
    capture(CLASSES_AND_COMMODITIES_PAGE_OPERATING_CLASS_DISTRIBUTION_CHANGE, {
      operatingClassDistribution,
      applicationId,
    });
  }, [capture, operatingClassDistribution]);

  return (
    <Grid container spacing={3} direction={layout}>
      <Grid item xs={4}>
        <Box paddingTop="10px">
          <InputLabel className={classes.inputLabel}>
            Operating class distribution
          </InputLabel>
          <FormHelperText>
            Select operating classes that apply and specify percentage in each
          </FormHelperText>
        </Box>
      </Grid>
      <Grid item xs={8} container spacing={2} direction="row">
        {list.map((item: IOperationClass, index: number) => (
          <Controller
            key={`operatingClassDistribution[${index}]`}
            name={`operatingClassDistribution[${index}]`}
            defaultValue={{
              operatingClass: item.value,
              percentageOfFleet: undefined,
              selected: false,
            }}
            control={control}
            rules={{
              validate: {
                required: (record) =>
                  !record ||
                  !record.selected ||
                  (record.selected && record.percentageOfFleet) ||
                  'Field cannot be empty',
                number: (record) =>
                  !record ||
                  !record.percentageOfFleet ||
                  (record && /^[0-9]+$/.test(record.percentageOfFleet)) ||
                  'Please enter a valid number',
                minOperatingClass: () => {
                  if (totalFleetPercentage === 0) {
                    return 'Please select at least 1 operating class';
                  }

                  return true;
                },
                total: () =>
                  !totalFleetPercentage ||
                  totalFleetPercentage === 100 ||
                  'Total value of operating class distribution should be equal to 100',
              },
            }}
            render={(props) => {
              return (
                <Grid key={item.value} item xs={6}>
                  <CheckboxIconText
                    value="100"
                    checked={props.value.selected}
                    icon={
                      <img
                        src={
                          props.value.selected
                            ? item.iconActive
                            : item.iconInactive
                        }
                        alt={item.label}
                        className={classes.checkboxIcon}
                      />
                    }
                    label={
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="body2">{item.label}</Typography>
                        </Grid>
                        {props.value.selected && (
                          <Grid item>
                            <FormControl
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <InputNumeric
                                placeholder="0%"
                                decimalScale={0}
                                value={props.value?.percentageOfFleet}
                                onChange={(event) => {
                                  if (event.target.value) {
                                    const newValue = +event.target.value;
                                    if (newValue > totalFleetPercentage) {
                                      setValue('primaryCommodity', undefined);
                                      setValue(
                                        'primaryOperatingClass',
                                        undefined,
                                      );
                                    }
                                    props.onChange({
                                      ...props.value,
                                      percentageOfFleet: newValue,
                                    });
                                  } else {
                                    props.onChange({
                                      ...props.value,
                                      percentageOfFleet: undefined,
                                    });
                                  }
                                }}
                                error={!!errors.operatingClassDistribution}
                                suffix="%"
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    }
                    onToggleCheck={(selected) => {
                      props.onChange({
                        ...props.value,
                        percentageOfFleet: undefined,
                        selected,
                      });
                    }}
                    onValueChange={() => null}
                  />
                </Grid>
              );
            }}
          />
        ))}

        <Grid item container direction="row" wrap="nowrap" spacing={0}>
          <Grid item>
            <Box className={clsx(classes.totalItem, classes.totalLabel)}>
              Total
            </Box>
          </Grid>
          <Grid item flexGrow={1}>
            <Box className={clsx(classes.totalItem, classes.totalValue)}>
              {totalFleetPercentage}%
            </Box>
          </Grid>
        </Grid>

        <Grid item container direction="row" wrap="nowrap" spacing={0}>
          <Grid item>
            {!!errors?.operatingClassDistribution && (
              <FormHelperText error>
                {getOperatingClassErrorMessage(errors)}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OperatingClasses;
