import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  Paper,
} from '@material-ui/core';
import { Tooltip } from '@nirvana/ui-kit';

import { utils as UtilsHelper } from 'src/helpers';
import IconDownload from 'src/assets/icons/download.svg';
import IconUpload from 'src/assets/icons/upload.svg';

import { useStyles } from './styles';

const QuoteSignature = ({
  signaturePacketLink,
}: {
  signaturePacketLink?: string;
}) => {
  const classes = useStyles();

  const handleSignaturePacketDownload = () => {
    if (signaturePacketLink) {
      UtilsHelper.downloadFile(signaturePacketLink);
    }
  };

  return (
    <Paper
      square
      elevation={0}
      sx={{ position: 'relative' }}
      classes={{ root: 'border border-primary-extraLight -mt-6' }}
    >
      <Container maxWidth="md">
        <Grid
          item
          container
          spacing={3}
          wrap="nowrap"
          direction="row"
          className={classes.quoteContainer}
        >
          <Grid item xs={5}>
            <Box paddingTop="10px">
              <InputLabel htmlFor="company-name">Signature Packet</InputLabel>
              <FormHelperText style={{ width: 288 }}>
                Please download, sign and re-upload a signed packet
              </FormHelperText>
            </Box>
          </Grid>

          <Grid item xs={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Link
                    href=""
                    onClick={(e) => {
                      e.preventDefault();

                      handleSignaturePacketDownload();
                    }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      opacity: 1,
                      cursor: 'pointer',
                    }}
                  >
                    <Box
                      mr={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={IconDownload} />
                    </Box>
                    Download Signature packet
                  </Link>
                </Box>
              </Grid>

              <Grid item>
                <Tooltip title="Coming soon">
                  <span>
                    <Button
                      startIcon={<img src={IconUpload} alt="Upload" />}
                      variant="outlined"
                      size="small"
                      onClick={() => {}}
                      disabled
                    >
                      Upload
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default QuoteSignature;
