import * as React from 'react';
import { FlatfileButton } from '@flatfile/react';
import { useTheme } from '@material-ui/core';
import { FlatfileButtonProps } from '@flatfile/react/dist/components/FlatFileButton';
import { FlatfileMetadata } from '@nirvana/api/quoting';

export interface IFileUploadValue {
  flatfileMetadata: FlatfileMetadata;
}

export interface IFileUploadProps
  extends Pick<
    FlatfileButtonProps,
    'fieldHooks' | 'render' | 'onRecordChange'
  > {
  variant?: 'contained' | 'outlined';
  type: string;
  title: string;
  fields: Array<any>;
  value?: IFileUploadValue;
  onChange: (results: any) => void;
}

/**
 * FlatFile file upload button.
 * Refer: https://flatfile.io/developers/react/getting-started for FlatFile usage
 * @component
 *
 * @param {Object} props
 * @param {Object} props.children - Text or any valid ReactNode.
 * @param {string} props.type - Type of import (https://flatfile.io/developers/react/options#type).
 * @param {string} props.title - Title (https://flatfile.io/developers/react/options#title).
 * @param {string} props.fields - File schema (https://flatfile.io/developers/react/options#fields).
 * @param {string} [props.value] - Current value of file upload content (Refer: IFileUploadValue).
 * @param {Function} props.onChange - The Callback that is invoked when data is received from FlatFile.
 *
 * @example
 * return <ButtonFileUpload type="Equipment">Upload</ButtonFileUpload>
 */
const ButtonFileUpload = ({
  type,
  title,
  fields,
  fieldHooks,
  render,
  onChange,
  onRecordChange,
}: IFileUploadProps) => {
  const theme = useTheme();
  const [isUploading, setUploading] = React.useState<boolean>(false);

  return (
    <>
      <FlatfileButton
        type="button"
        disabled={isUploading}
        // TODO: Find a way not to expose this on the frontend
        // If there is no way - move it to config file
        licenseKey="493a36b4-a6af-4e5c-9ea3-5b47600789fe"
        customer={{
          // TODO: Change this to the userid of the logged in user
          userId: '12345',
        }}
        render={render}
        onRecordChange={onRecordChange}
        settings={{
          type,
          fields,
          title,
          managed: true,
          allowInvalidSubmit: false,
          theme: {
            buttons: {
              primary: {
                backgroundColor: theme.palette.primary.main,
                border: 'transparent',
                ':hover': {
                  backgroundColor: theme.palette.primary.dark,
                  border: 'transparent',
                },
              },
            },
          },
          devMode: import.meta.env.DEV,
        }}
        fieldHooks={fieldHooks}
        onData={async (results: any) => {
          setUploading(true);

          onChange(results);

          return null;
        }}
      />
    </>
  );
};

export default ButtonFileUpload;
