import { ApplicationTab } from '@nirvana/api/non-fleet';
import apiService from 'src/helpers/apiService';

export const fetchApplicationsList = async ({
  pageSize,
  cursor,
  q,
  tab,
}: {
  pageSize?: number;
  cursor?: string;
  q?: string;
  tab?: ApplicationTab;
}) => {
  const { data } = await apiService.getApplicationListV2(
    pageSize,
    cursor,
    q,
    tab,
  );

  return data;
};
