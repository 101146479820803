import { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { InfoOutlined } from '@material-ui/icons';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import {
  CoverageDetails,
  CoverageType,
  QuoteOption,
} from '@nirvana/api/non-fleet';

import { utils as UtilsHelper } from 'src/helpers';
import IconDownload from 'src/assets/icons/download.svg';

import { getFormattedCoverageInfo } from '../indication/summary';
import { useStyles } from './styles';

interface SummaryProps {
  data?: QuoteOption;
  quotePDFLink?: string;
}

const Summary = ({ data, quotePDFLink }: SummaryProps) => {
  const classes = useStyles();

  const coverages = useMemo(() => {
    const sortedData = data?.coverages.sort((a: CoverageDetails) => {
      if (a.coverageType === CoverageType.CoverageAutoPhysicalDamage) {
        return -1;
      }

      if (a.coverageType === CoverageType.CoverageMotorTruckCargo) {
        return 1;
      }

      return 0;
    });

    return sortedData || [];
  }, [data?.coverages]);

  const handleQuoteDownload = () => {
    if (quotePDFLink) {
      UtilsHelper.downloadFile(quotePDFLink);
    }
  };

  return (
    <Container maxWidth="md" sx={{ position: 'relative' }}>
      <Grid container spacing={5} className={classes.quoteContainer}>
        <Grid item>
          <Grid container direction="column" alignItems="center" spacing={1}>
            <Grid item>
              <Typography className={classes.quote}>Final Quote</Typography>
            </Grid>

            <Grid item container alignItems="end" justifyContent="center">
              <Typography variant="h2">
                <Typography variant="caption" fontSize={16} fontWeight={400}>
                  $
                </Typography>{' '}
                <NumberFormat
                  value={data?.totalPremium}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={''}
                />
              </Typography>
            </Grid>

            <Grid item>
              <Box display="flex" alignItems="center">
                <Link
                  href=""
                  onClick={(e) => {
                    e.preventDefault();

                    handleQuoteDownload();
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    opacity: 1,
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    mr={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={IconDownload} />
                  </Box>
                  Download Quote
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item flexGrow={1} display="flex" justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>

        {coverages.map((record) => {
          const details = getFormattedCoverageInfo(record, data);

          if (!details) return null;

          return (
            <Grid item key={record.coverageType}>
              <Typography
                color="primary"
                component="p"
                fontWeight="fontWeightRegular"
                mb={1}
                textAlign="center"
                variant="caption"
              >
                {details?.name}
              </Typography>
              <Typography
                color="textPrimary"
                component="p"
                fontWeight="fontWeightBold"
                mb={0.4}
                textAlign="center"
                variant="h6"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {details?.primary}
                &nbsp;&nbsp;
                <InfoOutlined className={classes.infoIcon} fontSize="small" />
              </Typography>
              <Typography
                color="textSecondary"
                fontWeight="fontWeightRegular"
                textAlign="center"
              >
                {details?.secondary}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Summary;
