import { constants } from '@nirvana/ui-kit';

const { mexicanStates, usStates } = constants;

export const usAndMexicanStatesRegex = (): string => {
  const mxStateCodes = mexicanStates.map((record) => record.code);
  const usaStates = usStates.map((record) => record.code);

  const states = [...usaStates, ...mxStateCodes];
  const lowerCase = states.map((s) => s.toLowerCase());
  const upperCase = states.map((s) => s.toUpperCase());
  const regexStart: string = '^(';
  const regexEnd: string = ')$';

  return (
    regexStart +
    states.join('|') +
    lowerCase.join('|') +
    upperCase.join('|') +
    regexEnd
  );
};
