import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { ITheme } from 'src/assets/themes';

const useStyles = makeStyles((theme: ITheme) => ({
  noDivider: {
    '&> td, &> th': {
      border: 0,
    },
  },
  tableRow: {
    '&> th': {
      ...theme.typography.caption,
      color: theme.palette.text.hint,
      padding: 0,
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&> td': {
      padding: theme.spacing(1, 1, 1, 0),
    },
  },
}));

export interface TableViewColumn {
  key?: string;
  align?: 'left' | 'right' | 'center';
  content: React.ReactNode;
  width?: string;
}

export interface TableViewRow {
  key?: string;
  columns: Array<TableViewColumn>;
}

export interface ITableViewProps {
  headers: Array<TableViewColumn>;
  rows: Array<TableViewRow>;
}

const renderCell = (cell: TableViewColumn, cellIndex: number) => {
  const cellKey = cell.key || `cell-${cellIndex}`;
  return (
    <TableCell key={cellKey} align={cell.align} width={cell.width}>
      {cell.content}
    </TableCell>
  );
};

/**
 * Table component that renders a table using Material UI components.
 * Refer: https://next.material-ui.com/components/tables for usage and props
 * @component
 *
 * @param {Object} props
 * @param {Object[]} props.headers - List of header columns (Refer: TableViewColumn for details).
 * @param {Object[]} props.rows - List of rows to be renderd (Refer: TableViewRow for details).
 *
 * @example
 * const headers = [{
 *   key: 'radius',
 *   content: 'Radius'
 * }, {
 *   key: 'percentage',
 *   content: 'Percentage'
 * }];
 * const rows = [{
 *    content: '100mi'
 * }, {
 *    content: '50%'
 * }];
 * return <TableView headers={headers} rows={rows} />
 */
const TableView = ({ headers, rows = [] }: ITableViewProps) => {
  const classes = useStyles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow className={clsx(classes.noDivider, classes.tableRow)}>
          {headers.map(renderCell)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row: TableViewRow) => (
          <TableRow
            key={row.key}
            className={clsx(classes.noDivider, classes.tableRow)}
          >
            {row.columns.map(renderCell)}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableView;
