import * as React from 'react';
import {
  Box,
  Grid,
  Typography,
  Divider,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { parseISO } from 'date-fns';
import { CoverageType, LossRunSummaryPerCoverage } from '@nirvana/api/quoting';
import { Checkbox } from '@nirvana/ui-kit';

import { utils as UtilsHelper } from 'src/helpers';

import { DateRange, getDateRanges } from './utils';
import BaseFields from './baseFields';
import SummaryFieldsBlock from './summaryBlock';
import { TableContainer, TableHeading, useStyles } from './styles';

export interface ILossRunSummaryProps {
  coverages: Array<CoverageType>;
  lossSummary: LossRunSummaryPerCoverage[];
  effectiveDate: string;
  setValue: any;
  watch: any;
}

/**
 * Form to capture loss runs for last 5 years.
 * The application is submitted for indication options generation after this step.
 * Corresponds to Loss History (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A13613&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const LossRunSummary = ({
  coverages = [],
  lossSummary = [],
  effectiveDate: effectiveDateISO,
  setValue,
  watch,
}: ILossRunSummaryProps) => {
  const classes = useStyles();
  const [isALAPDBound, setALAPDBinding] = React.useState<boolean>();

  const effectiveDate = React.useMemo(
    () => (effectiveDateISO ? parseISO(effectiveDateISO) : new Date()),
    [effectiveDateISO],
  );

  const dateRanges: DateRange[] = React.useMemo(
    () => getDateRanges(effectiveDate),
    [effectiveDate],
  );

  const applicableCoverages = React.useMemo(() => {
    const filteredCoverages = coverages.filter(
      (coverage) => coverage !== CoverageType.CoverageGeneralLiability,
    );
    const coverageByIndex = {
      [CoverageType.CoverageAutoLiability]: filteredCoverages.indexOf(
        CoverageType.CoverageAutoLiability,
      ),
    };

    if (filteredCoverages.includes(CoverageType.CoverageAutoPhysicalDamage)) {
      coverageByIndex[CoverageType.CoverageAutoPhysicalDamage] =
        filteredCoverages.indexOf(CoverageType.CoverageAutoPhysicalDamage);
    }

    if (filteredCoverages.includes(CoverageType.CoverageMotorTruckCargo)) {
      coverageByIndex[CoverageType.CoverageMotorTruckCargo] =
        filteredCoverages.indexOf(CoverageType.CoverageMotorTruckCargo);
    }

    return coverageByIndex;
  }, [coverages]);

  const summaryAL =
    watch(
      `lossRunSummaryForm[${
        applicableCoverages[CoverageType.CoverageAutoLiability]
      }].lossRunSummary`,
    ) || [];

  React.useEffect(() => {
    if (isALAPDBound === undefined) {
      if (!lossSummary || !lossSummary.length) {
        // No data = new application
        setALAPDBinding(true);
      } else {
        // Check if AL power units = APD power units
        const summaryAL = lossSummary.find(
          (record) =>
            record.coverageType === CoverageType.CoverageAutoLiability,
        );
        const summaryAPD = lossSummary.find(
          (record) =>
            record.coverageType === CoverageType.CoverageAutoPhysicalDamage,
        );
        const powerUnitsAL = summaryAL?.lossRunSummary.map(
          (record) => record.numberOfPowerUnits,
        );
        const powerUnitsAPD = summaryAPD?.lossRunSummary.map(
          (record) => record.numberOfPowerUnits,
        );

        if (JSON.stringify(powerUnitsAL) === JSON.stringify(powerUnitsAPD)) {
          setALAPDBinding(true);
        } else {
          setALAPDBinding(false);
        }
      }
    }
  }, [isALAPDBound, lossSummary]);

  React.useEffect(() => {
    // Always update MTC, if available
    if (applicableCoverages[CoverageType.CoverageMotorTruckCargo]) {
      for (let i = 0, l = summaryAL.length; i < l; i += 1) {
        setValue(
          `lossRunSummaryForm[${
            applicableCoverages[CoverageType.CoverageMotorTruckCargo]
          }].lossRunSummary[${i}].numberOfPowerUnits`,
          summaryAL[i].numberOfPowerUnits,
        );
      }
    }

    // Auto update APD if bound
    if (isALAPDBound) {
      for (let i = 0, l = summaryAL.length; i < l; i += 1) {
        setValue(
          `lossRunSummaryForm[${
            applicableCoverages[CoverageType.CoverageAutoPhysicalDamage]
          }].lossRunSummary[${i}].numberOfPowerUnits`,
          summaryAL[i].numberOfPowerUnits,
        );
      }
    } else {
      // Do nothing
    }
  }, [summaryAL, isALAPDBound, applicableCoverages, setValue]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography variant="h4" fontWeight="fontWeightBold">
          Loss History
        </Typography>
        <FormHelperText sx={{ mt: 2 }}>
          Losses should include indemnity paid + case reserve dollars only (no
          expense).
          <br />
          Claim counts are to be entered 1 per claim event (not per claimant)
          and should NOT include zero dollar indemnity events.
        </FormHelperText>
        <Divider sx={{ mt: 3 }} />
      </Grid>

      {/* AL & MTC */}
      <Grid item>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item xs={4}>
            <TableContainer>
              <BaseFields
                dateRanges={dateRanges}
                coverageIndex={
                  applicableCoverages[CoverageType.CoverageAutoLiability]
                }
                readOnly={false}
              />
            </TableContainer>
          </Grid>
          <Grid item xs={4}>
            <SummaryFieldsBlock
              coverage={CoverageType.CoverageAutoLiability}
              coverageIndex={
                applicableCoverages[CoverageType.CoverageAutoLiability]
              }
              dateRanges={dateRanges}
            />
          </Grid>
          {UtilsHelper.isNumeric(
            applicableCoverages[CoverageType.CoverageMotorTruckCargo],
          ) && (
            <Grid item xs={4}>
              <Box sx={{ display: 'none' }}>
                <BaseFields
                  dateRanges={dateRanges}
                  coverageIndex={
                    applicableCoverages[CoverageType.CoverageMotorTruckCargo]
                  }
                  readOnly={isALAPDBound}
                />
              </Box>
              <SummaryFieldsBlock
                coverage={CoverageType.CoverageMotorTruckCargo}
                coverageIndex={
                  applicableCoverages[CoverageType.CoverageMotorTruckCargo]
                }
                dateRanges={dateRanges}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* APD */}
      {UtilsHelper.isNumeric(
        applicableCoverages[CoverageType.CoverageAutoPhysicalDamage],
      ) && (
        <>
          {/* Separator */}
          <Grid item ml={2}>
            <Divider />
          </Grid>
          {/* APD */}
          <Grid item>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              alignItems="flex-end"
              spacing={1}
            >
              <Grid item xs={4}>
                <TableHeading>
                  <FormControlLabel
                    classes={{
                      label: classes.checkboxLabel,
                    }}
                    sx={{ paddingLeft: 4 }}
                    onChange={(_, checked: boolean) => {
                      setALAPDBinding(checked);
                    }}
                    control={<Checkbox checked={!!isALAPDBound} />}
                    label="# of power units is the same"
                  />
                </TableHeading>
                <TableContainer>
                  <BaseFields
                    dateRanges={dateRanges}
                    coverageIndex={
                      applicableCoverages[
                        CoverageType.CoverageAutoPhysicalDamage
                      ]
                    }
                    readOnly={isALAPDBound}
                  />
                </TableContainer>
              </Grid>
              <Grid item xs={4}>
                <SummaryFieldsBlock
                  coverage={CoverageType.CoverageAutoPhysicalDamage}
                  coverageIndex={
                    applicableCoverages[CoverageType.CoverageAutoPhysicalDamage]
                  }
                  dateRanges={dateRanges}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LossRunSummary;
