import {
  Grid,
  Typography,
  Box,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { RejectedApplicationResponse } from '@nirvana/api/quoting';
import { ITheme } from '@nirvana/ui-kit';

import IconApplicationError from 'src/assets/icons/application-error.svg';
import React from 'react';
import { CLEARANCE_CONFLICT_MODAL_VIEW } from '../../features/telematics/events';
import { useAnalytics } from '../../helpers/analytics';

export const useStyles = makeStyles((theme: ITheme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
}));

export interface IClearanceError {
  onClose: () => void;
  applicationError?: RejectedApplicationResponse;
}

const ClearanceError = ({ onClose }: IClearanceError) => {
  const classes = useStyles();
  const { capture } = useAnalytics();

  React.useEffect(() => {
    capture(CLEARANCE_CONFLICT_MODAL_VIEW);
  }, []);

  return (
    <Box pt={4} position="relative" px={10} pb={2}>
      <IconButton
        className={classes.closeButton}
        onClick={onClose}
        edge="end"
        size="small"
      >
        <Close />
      </IconButton>
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid item>
          <img src={IconApplicationError} alt="Application Error" />
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            color="textPrimary"
            textAlign="center"
            mb={2}
          >
            Heads up! Existing application found
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            textAlign="center"
            mb={2}
          >
            An application for this customer has recently been submitted by
            another brokerage partner. Contact your business development
            representative to determine next steps.
          </Typography>
          <Typography variant="body2" color="textSecondary" textAlign="center">
            We recommend continuing and completing this application if you would
            like to obtain clearance.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClearanceError;
