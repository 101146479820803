import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { CoverageOption } from '@nirvana/api/non-fleet';

import LimitsSkeleton from './skeleton';
import LimitsSelector from './selector';

export interface ILimits {
  loading: boolean;
  disabled: boolean;
  options?: CoverageOption[];
  onChange: () => void;
}

const Limits = ({ loading, disabled, options = [], onChange }: ILimits) => {
  const { control } = useFormContext();
  const filteredOptions = options.filter(
    (record) => !!record.limitOptions?.length,
  );

  if (loading) {
    return <LimitsSkeleton />;
  }

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={5}>
        <InputLabel
          htmlFor="deductiblesOnCoverage-input"
          sx={{
            color: 'text.primary',
            fontWeight: 'fontWeightMedium',
            whiteSpace: 'normal',
          }}
        >
          Limits
        </InputLabel>
        <FormHelperText>Coverage limit per claim</FormHelperText>
      </Grid>
      <Grid item xs={7} container spacing={2} alignItems="center">
        {filteredOptions.map((record) => {
          return (
            <Grid item xs={6} key={record.coverage}>
              <Controller
                control={control}
                name={`indicationForm.coverages.${record.coverage}.limit`}
                render={({ field: { value, onChange: onValueChange } }) => {
                  return (
                    <LimitsSelector
                      coverage={record.coverage}
                      disabled={disabled}
                      options={record.limitOptions || []}
                      value={value || record.defaultLimit || 0}
                      onChange={(e) => {
                        onValueChange(e.target.value);

                        onChange();
                      }}
                    />
                  );
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Limits;
