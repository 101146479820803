import { makeStyles } from '@material-ui/core';

import { ITheme } from 'src/assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  footerToolbar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -2px 4px rgba(128, 145, 196, 0.13)',
  },
  appBar: {
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.06)',
    padding: theme.spacing(1, 0),
    position: 'relative',
    top: 1,
    zIndex: 4,
  },
  toolbar: {
    minHeight: theme.spacing(3),
  },
  divider: {
    borderColor: theme.palette.common.black,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepperWrapper: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: 10,
    padding: theme.spacing(4, 2),
    paddingLeft: theme.typography.pxToRem(21),
  },
  infoIcon: {
    color: theme.palette.info.main,
  },
  colorLight: {
    color: theme.palette.grey[400],
  },
}));
