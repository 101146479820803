import { PageState } from '@nirvana/api/non-fleet';
import { Navigate } from 'react-router-dom';

import ClassesAndCommodities from '../classesCommodities';
import Driver from '../driver';
import Equipment from '../equipment';
import Operations from '../operations';

export const getSteps = (applicationId: string) => [
  {
    key: 'operations',
    title: 'Operations',
    subtitle: '',
    pageState: PageState.PageStateOperations,
    render: () => <Operations />,
  },
  {
    key: 'classes',
    title: 'Classes & Commodities',
    subtitle: '',
    pageState: PageState.PageStateClassesAndCommodities,
    render: () => <ClassesAndCommodities />,
  },
  {
    key: 'equipment',
    title: 'Equipment',
    subtitle: '',
    pageState: PageState.PageStateEquipments,
    render: () => <Equipment />,
  },
  {
    key: 'drivers',
    title: 'Drivers',
    subtitle: '',
    pageState: PageState.PageStateDrivers,
    render: () => <Driver />,
  },
  {
    key: 'quote',
    title: 'Quote',
    subtitle: '',
    pageState: PageState.PageStateSubmitted,
    render: () => <Navigate to={`/nrb/${applicationId}/indication`} />,
  },
];
