import * as React from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Link,
} from '@material-ui/core';

import ImageClaims from 'src/assets/icons/claims-large.svg';
import {
  CLAIMS_CONTACT_EMAIL,
  CLAIMS_CONTACT_PHONE,
  NIRVANA_CLAIMS_CONTACT_PHONE,
  NIRVANA_CLAIMS_CONTACT_EMAIL,
} from 'src/constants';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';

export const Instructions = () => {
  const getFeatureValue = useFeatureFlag();

  const isNirvanaClaimsEnabled = getFeatureValue(
    Feature.CLAIMS_NEW_PORTAL,
    false,
  );

  return (
    <Box p={4}>
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h4"
              color="textSecondary"
              fontWeight="fontWeightBold"
            >
              Claims
            </Typography>
          </Grid>

          <Grid item>
            <Paper variant="outlined" elevation={0}>
              <Box px={3} py={2}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item>
                    <img src={ImageClaims} alt="Arrow Right" />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h5"
                      color="textPrimary"
                      marginBottom={4}
                      lineHeight={1.5}
                    >
                      Need to report a claim?
                      <br />
                      Reach out any time, day or night.
                    </Typography>
                    <Grid container spacing={10}>
                      <Grid item>
                        <Typography variant="subtitle1" color="textPrimary">
                          Call 24/7
                        </Typography>
                        <Link
                          href={`tel:${
                            isNirvanaClaimsEnabled
                              ? NIRVANA_CLAIMS_CONTACT_PHONE
                              : CLAIMS_CONTACT_PHONE
                          }`}
                          variant="h6"
                          fontWeight="fontWeightRegular"
                          color="primary"
                          underline={'always'}
                        >
                          {isNirvanaClaimsEnabled
                            ? NIRVANA_CLAIMS_CONTACT_PHONE
                            : CLAIMS_CONTACT_PHONE}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="textPrimary">
                          Email
                        </Typography>
                        <Link
                          href={`mailto:${
                            isNirvanaClaimsEnabled
                              ? NIRVANA_CLAIMS_CONTACT_EMAIL
                              : CLAIMS_CONTACT_EMAIL
                          }`}
                          variant="h6"
                          underline={'always'}
                          fontWeight="fontWeightRegular"
                          color="primary"
                        >
                          {isNirvanaClaimsEnabled
                            ? NIRVANA_CLAIMS_CONTACT_EMAIL
                            : CLAIMS_CONTACT_EMAIL}
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
