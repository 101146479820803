import { Divider, Grid } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { Show } from '@nirvana/ui-kit';
import { CoverageDetails, CoverageType } from '@nirvana/api/non-fleet';

import { fetchClassesAndCommodities } from 'src/features/nonFleet/queries/application';
import { useApplicationDetailsContext } from 'src/features/nonFleet/hooks/useApplicationDetails';

import CommoditiesHalued from './commoditiesHauled';
import RestrictedClasses from './restrictedClasses';
import RestrcitedCommodities from './restrictedCommodities';

const ClassesAndCommodities = () => {
  const { applicationDetails } = useApplicationDetailsContext();

  const { data } = useQuery({
    queryKey: ['classesAndCommodities'],
    queryFn: fetchClassesAndCommodities,
  });

  const primaryCovs: CoverageDetails[] = Object.values(
    applicationDetails?.nrb?.operationsForm?.coverageInfo?.primaryCovs ?? {},
  );

  const isMTCRequired = primaryCovs.find(
    ({ coverageType }) => coverageType === CoverageType.CoverageMotorTruckCargo,
  )?.isRequired;

  return (
    <Grid container direction="column" spacing={4} pt={4}>
      <Grid item container spacing={3}>
        <RestrictedClasses operatingClasses={data?.classes} />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <RestrcitedCommodities />
        <Show when={isMTCRequired}>
          <CommoditiesHalued categories={data?.commodities} />
        </Show>
      </Grid>
    </Grid>
  );
};

export default ClassesAndCommodities;
