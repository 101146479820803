import { FocusEvent, Fragment } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form-v7';

import { InputNumeric, Show } from '@nirvana/ui-kit';
import { VehicleDetails } from '@nirvana/api/non-fleet';

import Button from 'src/components/button';
import { InputWithoutLabel as OutlinedInput } from 'src/components/input';
import { isValidVIN } from 'src/helpers/utils';
import DeleteIcon from 'src/assets/icons/delete.svg';
import IconPlus from 'src/assets/icons/plus-primary.svg';
import { fetchVehicleInfo } from 'src/features/nonFleet/queries/application';

import FlatFileUploader from './flatFileUploader';

const defaultValues = {
  vin: '',
  vehicleType: '',
  year: '',
  make: '',
  model: '',
  statedValue: '',
  ownershipType: '',
  parkingLocationZIP: '',
};

const Equipment = () => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const formErrors = (errors?.equipmentsForm as any)?.vehicles;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'equipmentsForm.vehicles',
    rules: {
      required: 'Please add at least one vehicle',
    },
    shouldUnregister: true,
  });
  const vehicles = useWatch({ control, name: 'equipmentsForm.vehicles' });
  const hasShortTermLease =
    vehicles?.some((record: VehicleDetails) => {
      return record.ownershipType === 'Short term lease';
    }) || false;

  const { mutate } = useMutation(fetchVehicleInfo);

  const handeFileUploadSuccess = (data?: VehicleDetails[]) => {
    const vins = data?.map(({ vin }) => vin).join(',') ?? '';
    setValue('equipmentsForm.vehicles', data);
    mutate(
      { vins },
      {
        onSuccess: ({ data: vechileData }) => {
          if (vechileData?.length) {
            const newFields = vechileData.map(
              ({ type, modelYear, ...rest }, index) => ({
                vehicleType: type,
                year: +modelYear,
                ...rest,
                statedValue: data?.[index]?.statedValue ?? '',
                vin: data?.[index]?.vin ?? '',
              }),
            );
            setValue('equipmentsForm.vehicles', newFields);
          }
        },
      },
    );
  };

  const handleVINBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    mutate(
      { vins: e.target.value },
      {
        onSuccess: ({ data }) => {
          if (data?.length) {
            const { type, modelYear, make, model, weightClass } = data[0];
            const initialKey = `equipmentsForm.vehicles.${index}`;
            setValue(`${initialKey}.vehicleType`, type);
            setValue(`${initialKey}.year`, +modelYear);
            setValue(`${initialKey}.make`, make);
            setValue(`${initialKey}.model`, model);
            setValue(`${initialKey}.weightClass`, weightClass);
          }
        },
      },
    );
  };

  return (
    <Box mt={12}>
      <Grid container direction="column" alignItems="center" spacing={4}>
        <FlatFileUploader onSuccess={handeFileUploadSuccess} />

        <Show when={fields.length === 0}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => append(defaultValues)}
              startIcon={<img src={IconPlus} alt="Add vehicle" />}
            >
              Add a Vehicle
            </Button>

            {!!formErrors?.root?.message && (
              <FormHelperText error>{formErrors.root.message}</FormHelperText>
            )}
          </Grid>
        </Show>

        <Show when={fields.length > 0}>
          <Grid item xs={7} container flexDirection="column" spacing={2}>
            <Grid
              item
              container
              alignItems="center"
              spacing={2}
              flexWrap="nowrap"
            >
              <Grid item xs={6}>
                <FormHelperText>Ownership type</FormHelperText>
              </Grid>

              <Grid item xs={6}>
                <FormHelperText>VIN</FormHelperText>
              </Grid>

              <Grid item xs={3}>
                <FormHelperText>Vehicle type</FormHelperText>
              </Grid>

              <Grid item xs={3}>
                <FormHelperText>Year</FormHelperText>
              </Grid>

              <Grid item xs={4}>
                <FormHelperText>Make</FormHelperText>
              </Grid>

              <Grid item xs={3}>
                <FormHelperText>Model</FormHelperText>
              </Grid>

              <Grid item xs={5}>
                <FormHelperText>Total value</FormHelperText>
              </Grid>

              <Grid item xs={2} />
            </Grid>

            {fields.map((item, index) => (
              <Fragment key={item.id}>
                <Grid
                  item
                  container
                  spacing={2}
                  alignItems="flex-start"
                  flexWrap="nowrap"
                >
                  <input
                    type="hidden"
                    {...register(
                      `equipmentsForm.vehicles.${index}.weightClass`,
                    )}
                  />
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        defaultValue=""
                        name={
                          `equipmentsForm.vehicles.${index}.ownershipType` as const
                        }
                        rules={{ required: 'Please select ownership type' }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              displayEmpty
                              variant="outlined"
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                              error={
                                !!formErrors?.[index]?.ownershipType?.message
                              }
                            >
                              <MenuItem value="">
                                <Typography color="text.hint">
                                  Ownership type
                                </Typography>
                              </MenuItem>
                              <MenuItem value="Owned">Owned</MenuItem>
                              <MenuItem value="Long term lease">
                                Long term lease
                              </MenuItem>
                              <MenuItem value="Short term lease">
                                Short term lease
                              </MenuItem>
                            </Select>
                          );
                        }}
                      />
                      {/* {!!formErrors?.[index]?.ownershipType?.message && (
                        <FormHelperText error>
                          {formErrors?.[index]?.ownershipType?.message}
                        </FormHelperText>
                      )} */}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <OutlinedInput
                        placeholder="Please enter VIN"
                        {...register(`equipmentsForm.vehicles.${index}.vin`, {
                          required: 'Please enter VIN',
                          validate: (value) => {
                            if (value && !isValidVIN(value)) {
                              return 'Please enter a valid VIN';
                            }

                            return true;
                          },
                        })}
                        error={!!formErrors?.[index]?.vin?.message}
                        // helperText={formErrors?.[index]?.vin?.message}
                        onBlur={(e) => handleVINBlur(e, index)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        defaultValue=""
                        name={`equipmentsForm.vehicles.${index}.vehicleType`}
                        rules={{ required: 'Please select vehicle type' }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              displayEmpty
                              variant="outlined"
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                              error={
                                !!formErrors?.[index]?.vehicleType?.message
                              }
                              sx={{ width: '100px' }}
                            >
                              <MenuItem value="">
                                <Typography color="text.hint">Type</Typography>
                              </MenuItem>
                              <MenuItem value="Truck">Truck</MenuItem>
                              <MenuItem value="Pickup Truck">
                                Pickup Truck
                              </MenuItem>
                              <MenuItem value="Tractor">Tractor</MenuItem>
                              <MenuItem value="Trailer">Trailer</MenuItem>
                            </Select>
                          );
                        }}
                      />
                      {/* {!!formErrors?.[index]?.vehicleType?.message && (
                        <FormHelperText error>
                          {formErrors?.[index]?.vehicleType?.message}
                        </FormHelperText>
                      )} */}
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        defaultValue=""
                        name={`equipmentsForm.vehicles.${index}.year`}
                        rules={{ required: 'Please enter year' }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputNumeric
                              placeholder="Year"
                              thousandSeparator={false}
                              value={value}
                              onChange={(e) => onChange(+e.target.value)}
                              error={!!formErrors?.[index]?.year?.message}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <OutlinedInput
                        placeholder="Make"
                        error={!!formErrors?.[index]?.make?.message}
                        {...register(`equipmentsForm.vehicles.${index}.make`, {
                          required: 'Please enter make',
                        })}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <OutlinedInput
                        placeholder="Model"
                        error={!!formErrors?.[index]?.model?.message}
                        {...register(`equipmentsForm.vehicles.${index}.model`, {
                          required: 'Please enter model',
                        })}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        defaultValue=""
                        name={`equipmentsForm.vehicles.${index}.statedValue`}
                        rules={{ required: 'Please enter stated value' }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputNumeric
                              prefix="$"
                              placeholder="Total value"
                              value={value}
                              onChange={(e) => onChange(+e.target.value)}
                              error={
                                !!formErrors?.[index]?.statedValue?.message
                              }
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <IconButton size="small" onClick={() => remove(index)}>
                      <img src={DeleteIcon} alt="Delete" />
                    </IconButton>
                  </Grid>
                </Grid>
                {!!formErrors?.[index] && (
                  <FormHelperText error sx={{ pl: 2 }}>
                    {
                      formErrors?.[index]?.[Object.keys(formErrors?.[index])[0]]
                        ?.message
                    }
                  </FormHelperText>
                )}
              </Fragment>
            ))}
          </Grid>
        </Show>
      </Grid>

      <Show when={hasShortTermLease}>
        <FormHelperText error sx={{ mt: 2 }}>
          Short term lease vehicles will be excluded from the policy
        </FormHelperText>
      </Show>

      <Show when={fields.length > 0}>
        <Grid container my={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => append(defaultValues)}
              startIcon={<img src={IconPlus} alt="Add Driver" />}
            >
              Add New Row
            </Button>
          </Grid>
        </Grid>
      </Show>
    </Box>
  );
};

export default Equipment;
