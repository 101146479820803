import { CoverageDetails, CoverageType } from '@nirvana/api/non-fleet';
import { AncillaryCoverages } from '@nirvana/ui-kit/src/constants';

import { orderedPrimaryCoverages } from 'src/helpers/application';

export const getMainCoverages = (coverages: CoverageDetails[]) => {
  return coverages
    .filter(
      (coverage) =>
        coverage.coverageType === CoverageType.CoverageAutoLiability || // Auto Liability
        coverage.coverageType === CoverageType.CoverageAutoPhysicalDamage || // Auto Physical Damage
        coverage.coverageType === CoverageType.CoverageMotorTruckCargo || // Motor Truck Cargo
        coverage.coverageType === CoverageType.CoverageGeneralLiability, // General Liability
    )
    .sort((a: CoverageDetails, b: CoverageDetails) => {
      const aIndex = orderedPrimaryCoverages.indexOf(a.coverageType);
      const bIndex = orderedPrimaryCoverages.indexOf(b.coverageType);

      return aIndex - bIndex;
    });
};

/*
 * This function is used to get the ancillary coverages
 * @param mainCoverage - Main coverage
 * @param ancillaryCoverages - Ancillary coverages
 * @returns - Ancillary coverages
 * @example - getAncillaryCoverages('CoverageAutoPhysicalDamage', [{coverageType: 'CoverageTowingLaborAndStorage', isRequired: true}]) => [{coverageType: 'CoverageTowingLaborAndStorage', isRequired: true}]
 */
export const getAncillaryCoverages = (
  mainCoverage: CoverageType,
  allCoverages: CoverageDetails[],
) => {
  const coverageByType = allCoverages
    .filter((record) => record.isRequired)
    .reduce(
      (acc, coverage) => ({ ...acc, [coverage.coverageType]: coverage }),
      {},
    );

  return AncillaryCoverages[mainCoverage]
    .filter((record) => !!coverageByType[record.coverageType])
    .map((record) => coverageByType[record.coverageType]);
};

/*
 * This function is used to get the abbreviation of the coverage label
 * @param coverageLabel - Coverage label
 * @returns - Coverage abbreviation
 * @example - getCoverageAbbrevation('Auto Physical Damage') => 'APD'
 * @example - getCoverageAbbrevation('Motor Truck Cargo') => 'MTC'
 */
export const getCoverageAbbrevation = (coverageLabel: string) => {
  return coverageLabel.match(/\b([A-Z])/g)?.join('');
};

const symbolDefinitionByCoverage = {
  [CoverageType.CoverageAutoLiability]: {
    symbol: '67',
    definition:
      'Only those "autos" described in Item Three of the Declarations for which a premium charge is shown (and for Covered Autos Liability Coverage any "trailers" you don\'t own while attached to any power unit described in Item Three)',
  },
  [CoverageType.CoverageAutoPhysicalDamage]: {
    symbol: '67',
    definition:
      'Only those "autos" described in Item Three of the Declarations for which a premium charge is shown (and for Covered Autos Liability Coverage any "trailers" you don\'t own while attached to any power unit described in Item Three)',
  },
  [CoverageType.CoveragePersonalInjuryProtection]: {
    symbol: '65',
    definition:
      'Owned Autos Subject to No-Fault (when this coverage is mandated by law)',
  },
  [CoverageType.CoverageUm]: {
    symbol: '66',
    definition: 'Owned Autos Subject to a Compulsory Uninsured Motorist Law',
  },
  [CoverageType.CoverageUim]: {
    symbol: '66',
    definition: 'Owned Autos Subject to a Compulsory Uninsured Motorist Law',
  },
  [CoverageType.CoverageUmuim]: {
    symbol: '66',
    definition: 'Owned Autos Subject to a Compulsory Uninsured Motorist Law',
  },
  [CoverageType.CoverageUninsuredMotoristBodilyInjury]: {
    symbol: '66',
    definition: 'Owned Autos Subject to a Compulsory Uninsured Motorist Law',
  },
  [CoverageType.CoverageUninsuredMotoristPropertyDamage]: {
    symbol: '66',
    definition: 'Owned Autos Subject to a Compulsory Uninsured Motorist Law',
  },
  [CoverageType.CoverageTrailerInterchange]: {
    symbol: '69',
    definition:
      '"Trailers" In Your Possession Under A Written Trailer Or Equipment Interchange Agreement',
  },
};

export const getSymbolDefinitionByCoverage = (coverage: CoverageType) => {
  return symbolDefinitionByCoverage[coverage];
};

export const getSymbolDefinitionLabelByCoverage = (coverage: CoverageType) => {
  const value = symbolDefinitionByCoverage[coverage];
  return value ? `${value.symbol} | ${value.definition}` : '--';
};
