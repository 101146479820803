import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigurationParameters } from '@nirvana/api/quoting';
import { RootState } from 'src/redux/reducers';

export interface AuthError {
  message: string;
}

export interface AuthState {
  apiConfig?: ConfigurationParameters;
  isLoading: boolean;
  isLoggedIn: boolean;
  isLoggedOut: boolean;
  error: AuthError | undefined;
}

export const initialState: AuthState = {
  apiConfig: {},
  isLoading: false,
  isLoggedIn: false,
  isLoggedOut: false,
  error: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setLoginSuccess: (
      state,
      { payload }: PayloadAction<ConfigurationParameters>,
    ) => {
      state.apiConfig = payload;
      state.isLoggedIn = true;
      state.error = undefined;
    },
    setLoginFail: (state, { payload }: PayloadAction<AuthError>) => {
      state.error = payload;
      state.isLoggedIn = false;
    },
    setLogOut: (state) => {
      state.isLoggedOut = true;
      state.isLoggedIn = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = authSlice;

// Extract and export each action creator by name
export const { setLoading, setLoginSuccess, setLoginFail, setLogOut } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const authSelector = (state: RootState) => state.auth;
