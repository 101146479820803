import { useState } from 'react';
import { formatISO } from 'date-fns';
import { Button, FormHelperText, Grid, Typography } from '@material-ui/core';
import { DriverDetails } from '@nirvana/api/non-fleet';

import { FlatFileUpload } from 'src/components/button';
import { date as DateHelper, usStatesHelper } from 'src/helpers';
import IconFileUpload from 'src/assets/icons/upload-primary.svg';
import stateLicenseRegex from 'src/features/application/components/create/stateLicenseRegex.json';

import { useStyles } from './styles';

const FlatFileUploader = ({
  onSuccess,
}: {
  onSuccess: (data?: DriverDetails[]) => void;
}) => {
  const classes = useStyles();
  const [renderKey, setRenderKey] = useState(1);

  return (
    <Grid item>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <Typography variant="subtitle1">
            Please upload the list of drivers
          </Typography>
        </Grid>
        <Grid item key={renderKey}>
          <FlatFileUpload
            type="Drivers"
            title="Upload Drivers List"
            fields={[
              {
                label: 'First Name',
                alternates: ['First Name'],
                key: 'firstName',
              },
              {
                label: 'Last Name',
                alternates: ['Last Name'],
                key: 'lastName',
              },
              {
                label: 'CDL Number',
                alternates: [
                  'driver',
                  'license',
                  'License Number',
                  'Driver License',
                  'DL License',
                  'CDL Number',
                  'CDL License',
                ],
                key: 'licenseNumber',
                description: "Driver's license number",
                validators: [{ validate: 'required' }],
              },
              {
                label: 'State',
                key: 'licenseState',
                alternates: [
                  'state',
                  'DL State',
                  'CDL State',
                  'License State',
                  'State License',
                  'Licenced Stated',
                  'st',
                  'St.',
                ],
                validators: [
                  {
                    validate: 'regex_matches',
                    error:
                      'License state is required. Please use two alphabet codes ("MN", "IN", "WI", etc)',
                    regex: usStatesHelper.usStateRegex(),
                  },
                ],
              },
              {
                label: 'Year Licensed',
                key: 'licenseIssueYear',
                alternates: [
                  'License Year',
                  'Issue Year',
                  'Issue Date',
                  'Issued',
                  'License Date',
                  'Year Licensed',
                ],
                validators: [
                  {
                    validate: 'regex_matches',
                    error:
                      'Invalid year. Please use a four digit year (e.g. 2020)',
                    regex: '^(19|20)\\d{2}$',
                  },
                ],
              },
              {
                label: 'Date of birth',
                alternates: [
                  'DOB',
                  'Date of birth',
                  'Birth date',
                  'D.O.B.',
                  'Birthdate',
                ],
                key: 'dateOfBirth',
                validators: [
                  {
                    validate: 'regex_matches',
                    error: 'Not a valid date',
                    regex:
                      '^\\s*(\\d{4}|(0?[1-9]|1[012]))[-\\/]((0?[1-9]|1[012])|(0?[1-9]|[12][0-9]|3[01]))[-\\/](\\d{2}|\\d{4}|(0?[1-9]|[12][0-9]|3[01]))\\s*$',
                  },
                ],
              },
            ]}
            fieldHooks={{
              firstName: (values) => {
                return values.map(([item, index]) => {
                  const newItem = typeof item === 'string' ? item.trim() : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Removed extra whitespaces',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
              lastName: (values) => {
                return values.map(([item, index]) => {
                  const newItem = typeof item === 'string' ? item.trim() : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Removed extra whitespaces',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
              licenseNumber: (values) => {
                return values.map(([item, index]) => {
                  const newItem =
                    typeof item === 'string'
                      ? item.replace(/[^A-Za-z0-9]/g, '')
                      : item;

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message:
                              'Removed invalid characters and whitespaces',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
              licenseState: (values) => {
                return values.map(([item, index]) => {
                  const newItem =
                    typeof item === 'string' ? item.toUpperCase() : '';

                  if (newItem === item) {
                    return [item, index] as any;
                  } else {
                    return [
                      {
                        value: newItem,
                        info: [
                          {
                            message: 'Changed to uppercase',
                            level: 'info',
                          },
                        ],
                      },
                      index,
                    ];
                  }
                });
              },
            }}
            value={{} as any}
            onChange={(results) => {
              if (results) {
                const driverList = {
                  drivers: results.validData.map(
                    (record: Partial<DriverDetails>) => {
                      const newData = {
                        ...record,
                        licenseNumber: record?.licenseNumber?.replace(
                          /[^A-Za-z0-9]/g,
                          '',
                        ),
                      };

                      if (record?.dateOfBirth && record?.dateOfBirth?.trim()) {
                        newData.dateOfBirth = formatISO(
                          DateHelper.parseDate(
                            record?.dateOfBirth?.trim(),
                            new Date(2003),
                          ),
                          {
                            representation: 'date',
                          },
                        );
                      }

                      if (record?.licenseState) {
                        newData.licenseState =
                          record.licenseState.toUpperCase();
                      }

                      if (record.licenseIssueYear) {
                        newData.licenseIssueYear = +record.licenseIssueYear;
                      }

                      return newData;
                    },
                  ),
                  flatfileMetadata: {
                    flatfileHandle: results.$meta.batchID,
                    fileMetadata: {
                      name: results.$meta.filename,
                    },
                  },
                };

                onSuccess(driverList.drivers);
              } else {
                onSuccess(undefined);
              }

              setRenderKey((prevState) => prevState + 1);
            }}
            render={(importer, launch) => {
              if (importer) {
                importer.registerStepHook(
                  'review',
                  ({ headers_matched = [] }) => {
                    if (
                      headers_matched.find((v) => v.matched_key === 'fullName')
                    ) {
                      importer.addVirtualField(
                        {
                          label: 'First Name',
                          key: 'firstName',
                          description: 'Automatically generated from Full Name',
                        },
                        {
                          order: 0,
                          hideFields: ['fullName'],
                        },
                      );

                      importer.addVirtualField(
                        {
                          label: 'Last Name',
                          key: 'lastName',
                          description: 'Automatically generated from Full Name',
                        },
                        {
                          order: 1,
                        },
                      );
                    }
                    return true;
                  },
                );

                importer.registerRecordHook(
                  (record: any, index: number, mode: string) => {
                    const out: any = {};
                    const licenseRegex =
                      stateLicenseRegex[record.licenseState as string];
                    const driverLicenseNumber = record?.licenseNumber as string;

                    if (
                      licenseRegex &&
                      !driverLicenseNumber.match(new RegExp(licenseRegex.rule))
                    ) {
                      out.licenseNumber = {
                        info: [
                          {
                            message: 'not a valid license number',
                            level: 'error',
                          },
                        ],
                      };
                    }

                    if (record.fullName && mode === 'change') {
                      let firstSpaceIndex = (record.fullName || '')
                        .trim()
                        .indexOf(' ');

                      if (firstSpaceIndex === -1) {
                        firstSpaceIndex = record.fullName.length;
                      }

                      if (record.firstName && !record.lastName) {
                        out.lastName = {
                          value: record.fullName
                            .substring(firstSpaceIndex + 1)
                            .trim(),
                        };
                      }

                      if (record.lastName && !record.firstName) {
                        out.firstName = {
                          value: record.fullName
                            .substring(0, firstSpaceIndex)
                            .trim(),
                        };
                      }
                    }

                    if (record.fullName && mode === 'init') {
                      let firstSpaceIndex = (record.fullName || '')
                        .trim()
                        .indexOf(' ');

                      if (firstSpaceIndex === -1) {
                        firstSpaceIndex = record.fullName.length;
                      }

                      out.firstName = {
                        value: record.fullName
                          .substring(0, firstSpaceIndex)
                          .trim(),
                        info: [
                          {
                            message:
                              'This value has been created using Full Name',
                            level: 'info',
                          },
                        ],
                      };

                      out.lastName = {
                        value: record.fullName
                          .substring(firstSpaceIndex + 1)
                          .trim(),
                        info: [
                          {
                            message:
                              'This value has been created using Full Name',
                            level: 'info',
                          },
                        ],
                      };
                    }

                    return out;
                  },
                );
              }

              return (
                <Button
                  variant="outlined"
                  onClick={launch}
                  startIcon={<img src={IconFileUpload} alt="File Upload" />}
                >
                  Upload
                </Button>
              );
            }}
          />
        </Grid>
        <Grid item>
          <FormHelperText>
            or click on the button below to add them
          </FormHelperText>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FlatFileUploader;
