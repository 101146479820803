import { Divider, Grid } from '@material-ui/core';
import { Show } from '@nirvana/ui-kit';

import { useApplicationDetailsContext } from 'src/features/nonFleet/hooks/useApplicationDetails';

import Company from './company';
import Losses from './losses';
import Coverage from './coverage';
import MailingAddress from './mailing-address';
import BusinessStructure from './business-structure';

const Operations = () => {
  const { applicationDetails } = useApplicationDetailsContext();

  return (
    <Grid container direction="column" spacing={4} pt={4}>
      <Grid item container spacing={3}>
        <Coverage />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container spacing={3}>
        <Show when={!applicationDetails?.fmcsaDataPresent}>
          <Company />
        </Show>
        <BusinessStructure />
        <MailingAddress />
      </Grid>

      <Grid item container spacing={3}>
        <Losses />
      </Grid>
    </Grid>
  );
};

export default Operations;
