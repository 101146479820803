import {
  Box,
  Card,
  CardActionArea,
  Divider,
  FormHelperText,
  Grid,
  Link,
  makeStyles,
  Radio,
  styled,
  Typography,
} from '@material-ui/core';
import {
  FileDestinationGroup,
  FileHandle,
  FileType,
} from '@nirvana/api/quoting';
import { Dialog, ITheme, Switch } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { formatISO } from 'date-fns';
import * as React from 'react';

import FirstAndLastNameImage from 'src/assets/icons/first-and-last.svg';
import FullNameImage from 'src/assets/icons/full-name.svg';
import Button, { FlatFileUpload } from 'src/components/button';
import { uploadFileMetadata } from 'src/features/application/queries/impler';
import {
  date as DateHelper,
  usAndMexicanStatesHelper,
  usStatesHelper,
} from 'src/helpers';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import { useDispatch } from 'src/redux';

import { uploadFlatFile } from '../../../actions';
import stateLicenseRegex from './../stateLicenseRegex.json';
import DriverListUploadFlatfile7 from './flatfile-7';
import Impler from './impler';

const DialogContent = styled(Box)(({ theme }: { theme: ITheme }) => ({
  paddingBottom: theme.spacing(3),
  position: 'relative',
  overflow: 'hidden',
  borderBottom: '1px solid #E6E7EF',
  maxWidth: 650,
}));

const TypographyRegular = styled(Typography)(
  ({ theme }: { theme: ITheme }) => ({
    color: theme.palette.text.primary,
    ...theme.typography.body2,
  }),
);

const TypographyMedium = styled(Typography)(({ theme }: { theme: ITheme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightBold,
  display: 'inline-block',
}));

const TabRadio = styled(Radio)(({ theme }: { theme: ITheme }) => ({
  color: theme.palette.text.disabled,
  padding: 0,
  marginRight: theme.spacing(1),

  '&.Mui-checked': {
    color: theme.palette.text.primary,
  },
}));

const TabLabel = styled(Box)(({ theme }: { theme: ITheme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const DiskList = styled('ul')(({ theme }: { theme: ITheme }) => ({
  listStyleType: 'disc',
  paddingLeft: theme.spacing(2.5),
  maxWidth: '85%',
  lineHeight: 1.7,
}));

const SelectableCard = styled(Card)(() => ({
  border: '1px solid #DFE4F1',
  boxShadow: '4px 0px 8px rgba(51, 80, 161, 0.2)',
  borderRadius: 10,
  height: '100%',
}));

const useStyles = makeStyles((theme: ITheme) => ({
  selected: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
}));

type DriversListDialogProps = {
  onChange: (list?: any) => void;
  onClose: () => void;
  onUploadComplete: () => void;
  onStart: () => void;
  open: boolean;
  value: any;
  usState?: string;
};

const DriversListDialog = ({
  onChange,
  onClose,
  onUploadComplete,
  onStart,
  open,
  value,
  usState,
}: DriversListDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = React.useState<Number>();
  const [error, setError] = React.useState<string>('');
  const isDisabled = selectedTab === undefined;
  const getFeatureValue = useFeatureFlag();
  const isFlatFileV4Enabled = getFeatureValue(Feature.FLATFILE_v4, false);
  const isImplerEnabled = getFeatureValue(Feature.IMPLER, false);

  const { mutate } = useMutation(uploadFileMetadata);

  const handleStart = () => {
    if (isDisabled) {
      setError('Please select an upload option');
    } else {
      setSelectedTab(undefined);
      setError('');
      onStart();
    }
  };

  const handleClose = () => {
    setSelectedTab(undefined);
    setError('');
    onClose();
  };

  return (
    <Dialog
      title={
        <Typography variant="h5" color="textPrimary">
          Upload Drivers List
        </Typography>
      }
      open={open}
      maxWidth="md"
      onClose={handleClose}
      primaryAction={
        <Box onClick={handleStart}>
          <Switch
            fallback={
              <FlatFileUpload
                key={`drivers-list-${selectedTab}`}
                type="Drivers"
                title="Upload Drivers List"
                fields={[
                  ...(selectedTab === 0
                    ? [
                        {
                          label: 'First Name',
                          alternates: ['First Name'],
                          key: 'firstName',
                          validators: [{ validate: 'required' }],
                        },
                        {
                          label: 'Last Name',
                          alternates: ['Last Name'],
                          key: 'lastName',
                        },
                      ]
                    : []),
                  ...(selectedTab === 1
                    ? [
                        {
                          label: 'Full Name',
                          alternates: ['Name', 'Driver', 'Drivers Name'],
                          key: 'fullName',
                          validators: [{ validate: 'required' }],
                        },
                      ]
                    : []),
                  {
                    label: 'DL Number',
                    alternates: [
                      'driver',
                      'license',
                      'License Number',
                      'Driver License',
                      'DL License',
                      'CDL Number',
                      'CDL License',
                    ],
                    key: 'dlNumber',
                    description: "Driver's license number",
                    validators: [
                      { validate: 'required' },
                      { validate: 'unique' },
                    ],
                  },
                  {
                    label: 'State',
                    key: 'usState',
                    alternates: [
                      'state',
                      'DL State',
                      'CDL State',
                      'License State',
                      'State License',
                      'Licenced Stated',
                      'st',
                      'St.',
                    ],
                    validators: [
                      { validate: 'required' },
                      {
                        validate: 'regex_matches',
                        error:
                          usState === 'TX'
                            ? 'License state is required. Please use two alphabet codes ("MN", "IN", "WI", etc) for US' +
                              ' states and "MX" for Mexican states.'
                            : 'License state is required. Please use two alphabet codes ("MN", "IN", "WI", etc)',
                        regex:
                          usState === 'TX'
                            ? usAndMexicanStatesHelper.usAndMexicanStatesRegex()
                            : usStatesHelper.usStateRegex(),
                      },
                    ],
                  },
                  {
                    label: 'Date of hire',
                    alternates: [
                      'Hiring Date',
                      'Hired Date',
                      'Date',
                      'DOH',
                      'D.O.H.',
                      'Date hired',
                      'Date of Hire',
                    ],
                    key: 'dateHired',
                    validators: [
                      { validate: 'required' },
                      {
                        validate: 'regex_matches',
                        error: 'Not a valid date',
                        regex:
                          '^\\s*(\\d{4}|(0?[1-9]|1[012]))[-\\/]((0?[1-9]|1[012])|(0?[1-9]|[12][0-9]|3[01]))[-\\/](\\d{2}|\\d{4}|(0?[1-9]|[12][0-9]|3[01]))\\s*$',
                      },
                    ],
                  },
                  {
                    label: 'Date of birth',
                    alternates: [
                      'DOB',
                      'Date of birth',
                      'Birth date',
                      'D.O.B.',
                      'Birthdate',
                    ],
                    key: 'dateOfBirth',
                    validators: [
                      { validate: 'required' },
                      {
                        validate: 'regex_matches',
                        error: 'Not a valid date',
                        regex:
                          '^\\s*(\\d{4}|(0?[1-9]|1[012]))[-\\/]((0?[1-9]|1[012])|(0?[1-9]|[12][0-9]|3[01]))[-\\/](\\d{2}|\\d{4}|(0?[1-9]|[12][0-9]|3[01]))\\s*$',
                      },
                    ],
                  },
                  {
                    label: 'CDL Years of Experience',
                    alternates: [
                      'Years of Experience',
                      'Years of experience',
                      'Years Experience',
                      'Years experience',
                      'Experience',
                      'Experience (years)',
                      'Experience (Yrs)',
                      'YOE',
                      'Drivers Experience',
                      'YOE for Drivers',
                      'YOE [DRIVERS]',
                      'License Recieved',
                      'Total Driving Experience',
                    ],
                    key: 'yearsOfExperience',
                    validators: [
                      { validate: 'required' },
                      {
                        validate: 'regex_matches',
                        error:
                          'Years of experience must be a number between 0 and 100 with a max of one decimal place',
                        regex: '^(?:100(?:\\.0)?|\\d{1,2}(?:\\.\\d)?)$',
                      },
                    ],
                  },
                ]}
                fieldHooks={{
                  firstName: (values) => {
                    return values.map(([item, index]) => {
                      const newItem =
                        typeof item === 'string' ? item.trim() : item;

                      if (newItem === item) {
                        return [item, index] as any;
                      } else {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message: 'Removed extra whitespaces',
                                level: 'info',
                              },
                            ],
                          },
                          index,
                        ];
                      }
                    });
                  },
                  lastName: (values) => {
                    return values.map(([item, index]) => {
                      const newItem =
                        typeof item === 'string' ? item.trim() : item;

                      if (newItem === item) {
                        return [item, index] as any;
                      } else {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message: 'Removed extra whitespaces',
                                level: 'info',
                              },
                            ],
                          },
                          index,
                        ];
                      }
                    });
                  },
                  dlNumber: (values) => {
                    return values.map(([item, index]) => {
                      const newItem =
                        typeof item === 'string'
                          ? item.replace(/[^A-Za-z0-9*]/g, '').trim()
                          : item;

                      if (newItem === item) {
                        return [item, index] as any;
                      } else {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message:
                                  'Removed invalid characters and whitespaces',
                                level: 'info',
                              },
                            ],
                          },
                          index,
                        ];
                      }
                    });
                  },
                  usState: (values) => {
                    return values.map(([item, index]) => {
                      const newItem =
                        typeof item === 'string'
                          ? item.toUpperCase().trim()
                          : '';

                      if (newItem === item) {
                        return [item, index] as any;
                      } else {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message:
                                  'Changed to uppercase and removed whitespaces',
                                level: 'info',
                              },
                            ],
                          },
                          index,
                        ];
                      }
                    });
                  },
                  yearsOfExperience: (values) => {
                    // Truncate to 1 decimal place
                    return values.map(([item, index]) => {
                      // .tofixed(1) will return a string, so we need to convert it back to a number
                      const newItem =
                        typeof item === 'string'
                          ? parseFloat(parseFloat(item).toFixed(1))
                          : item;

                      if (newItem === item) {
                        return [item, index] as any;
                      } else {
                        return [
                          {
                            value: newItem,
                            info: [
                              {
                                message: 'Truncated to 1 decimal place',
                                level: 'info',
                              },
                            ],
                          },
                          index,
                        ];
                      }
                    });
                  },
                }}
                value={value}
                onChange={(results) => {
                  if (results) {
                    const driverList: any = {
                      drivers: results.$data
                        .filter((block: any) => block.valid)
                        .map((block: any) => {
                          const record = block.data;
                          const dob = record.dateOfBirth?.trim();
                          const doh = record.dateHired?.trim();

                          if (record?.fullName) {
                            delete record.fullName;
                          }

                          return {
                            ...record,
                            dateOfBirth: dob
                              ? formatISO(
                                  DateHelper.parseDate(dob, new Date(2003)),
                                  {
                                    representation: 'date',
                                  },
                                )
                              : null,
                            dateHired: doh
                              ? formatISO(DateHelper.parseDate(doh), {
                                  representation: 'date',
                                })
                              : null,
                            dlNumber: record.dlNumber.replace(
                              /[^A-Za-z0-9]/g,
                              '',
                            ),
                            usState: record.usState.toUpperCase(),
                            yearsOfExperience: parseFloat(
                              parseFloat(record.yearsOfExperience).toFixed(1),
                            ),
                          };
                        }),
                      flatfileMetadata: {
                        flatfileHandle: results.$meta.batchID,
                        fileMetadata: {
                          name: results.$meta.filename,
                        },
                      },
                    };
                    const uploadPromise: any = driverList.flatfileMetadata
                      ?.fileMetadata?.name
                      ? dispatch(
                          uploadFlatFile({
                            flatfileMetadata: driverList.flatfileMetadata,
                            fileType: FileType.FileTypeDriversList,
                            fileDestinationGroup:
                              FileDestinationGroup.FileDestinationGroupQuoting,
                          }),
                        )
                      : Promise.reject(new Error('skip_file_upload'));
                    uploadPromise
                      .then((response: any) => {
                        onUploadComplete();

                        driverList.flatfileMetadata.fileMetadata.handle =
                          response.payload.handle;

                        onChange(driverList);
                      })
                      .catch(() => {
                        if (!driverList.flatfileMetadata?.fileMetadata?.name) {
                          driverList.flatfileMetadata.fileMetadata.name =
                            'DriversList.csv';
                        }
                        // Save the data anyways
                        onChange(driverList);

                        onUploadComplete();
                      });
                  } else {
                    onChange(undefined);
                  }
                }}
                render={(importer, launch) => {
                  if (importer) {
                    importer.registerStepHook(
                      'review',
                      ({ headers_matched = [] }) => {
                        if (
                          headers_matched.find(
                            (v) => v.matched_key === 'fullName',
                          )
                        ) {
                          importer.addVirtualField(
                            {
                              label: 'First Name',
                              key: 'firstName',
                              description:
                                'Automatically generated from Full Name',
                            },
                            {
                              order: 0,
                              hideFields: ['fullName'],
                            },
                          );

                          importer.addVirtualField(
                            {
                              label: 'Last Name',
                              key: 'lastName',
                              description:
                                'Automatically generated from Full Name',
                            },
                            {
                              order: 1,
                            },
                          );
                        }
                        return true;
                      },
                    );

                    importer.registerRecordHook(
                      (record: any, index: number, mode: string) => {
                        const out: any = {};
                        const licenseRegex =
                          stateLicenseRegex[record.usState as string];
                        const driverLicenseNumber = record?.dlNumber as string;

                        if (
                          licenseRegex &&
                          !driverLicenseNumber.match(
                            new RegExp(licenseRegex.rule),
                          )
                        ) {
                          out.dlNumber = {
                            info: [
                              {
                                message: 'not a valid license number',
                                level: 'error',
                              },
                            ],
                          };
                        }

                        if (record.fullName && mode === 'change') {
                          let firstSpaceIndex = (record.fullName || '')
                            .trim()
                            .indexOf(' ');

                          if (firstSpaceIndex === -1) {
                            firstSpaceIndex = record.fullName.length;
                          }

                          if (record.firstName && !record.lastName) {
                            out.lastName = {
                              value: record.fullName
                                .substring(firstSpaceIndex + 1)
                                .trim(),
                            };
                          }

                          if (record.lastName && !record.firstName) {
                            out.firstName = {
                              value: record.fullName
                                .substring(0, firstSpaceIndex)
                                .trim(),
                            };
                          }
                        }

                        if (record.fullName && mode === 'init') {
                          let firstSpaceIndex = (record.fullName || '')
                            .trim()
                            .indexOf(' ');

                          if (firstSpaceIndex === -1) {
                            firstSpaceIndex = record.fullName.length;
                          }

                          out.firstName = {
                            value: record.fullName
                              .substring(0, firstSpaceIndex)
                              .trim(),
                            info: [
                              {
                                message:
                                  'This value has been created using Full Name',
                                level: 'info',
                              },
                            ],
                          };

                          out.lastName = {
                            value: record.fullName
                              .substring(firstSpaceIndex + 1)
                              .trim(),
                            info: [
                              {
                                message:
                                  'This value has been created using Full Name',
                                level: 'info',
                              },
                            ],
                          };
                        }

                        return out;
                      },
                    );
                  }

                  return (
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (isDisabled) return;

                        launch();
                      }}
                    >
                      Proceed
                    </Button>
                  );
                }}
              />
            }
          >
            <Switch.Match when={isImplerEnabled}>
              <Impler
                usState={usState}
                useFullNames={selectedTab === 1}
                isDisabled={isDisabled}
                onChange={(data, metadata) => {
                  const processDriversList = (response?: FileHandle) => {
                    const driverList: any = {
                      drivers: data.map((record) => {
                        const dob = record.dateOfBirth?.trim();
                        const doh = record.dateHired?.trim();

                        if (record?.fullName) {
                          record.firstName = record.fullName.split(' ')[0];
                          record.lastName = record.fullName
                            .split(' ')
                            .slice(1)
                            .join(' ');
                          delete record.fullName;
                        }

                        return {
                          ...record,
                          dateOfBirth: dob
                            ? formatISO(
                                DateHelper.parseDate(dob, new Date(2003)),
                                {
                                  representation: 'date',
                                },
                              )
                            : null,
                          dateHired: doh
                            ? formatISO(DateHelper.parseDate(doh), {
                                representation: 'date',
                              })
                            : null,
                          dlNumber: record.dlNumber.replace(
                            /[^A-Za-z0-9]/g,
                            '',
                          ),
                          usState: record.usState.toUpperCase(),
                          yearsOfExperience: parseFloat(
                            parseFloat(record.yearsOfExperience).toFixed(1),
                          ),
                        };
                      }),
                      implerMetadata: {
                        fileMetadata: {
                          name: 'DriversList.csv',
                        },
                      },
                    };

                    if (response) {
                      driverList.implerMetadata = {
                        flatfileHandle: response.handle,
                        fileMetadata: {
                          name: metadata?.filename,
                        },
                      };
                    }

                    onChange(driverList);

                    onUploadComplete();
                  };
                  // Upload file metadata to backend
                  if (metadata?.fileId && metadata?.filename) {
                    mutate(
                      {
                        fileType: FileType.FileTypeDriversList,
                        fileDestinationGroup:
                          FileDestinationGroup.FileDestinationGroupQuoting,
                        implerMetadata: {
                          implerHandle: metadata.fileId,
                          fileMetadata: {
                            name: metadata?.filename,
                          },
                        },
                      },
                      {
                        onSuccess: (response) => {
                          processDriversList(response);
                        },
                      },
                    );
                  } else {
                    processDriversList();
                  }
                }}
              />
            </Switch.Match>
            <Switch.Match when={isFlatFileV4Enabled}>
              <DriverListUploadFlatfile7
                isDisabled={isDisabled}
                applicationState={usState}
                onChange={(result) => {
                  const payload = {
                    drivers: result.data,
                    flatfileMetadata: {
                      flatfileHandle: result.meta.workbookId,
                      fileMetadata: {
                        name: result.meta.filename,
                      },
                    },
                  };

                  if (!payload.flatfileMetadata?.fileMetadata?.name) {
                    payload.flatfileMetadata.fileMetadata.name =
                      'DriversList.csv';
                  }
                  // Save the data
                  onChange(payload);

                  onUploadComplete();
                }}
              />
            </Switch.Match>
          </Switch>
        </Box>
      }
    >
      <DialogContent>
        <Typography variant="body1" mb={3}>
          Please upload the drivers list in <strong>CSV</strong> or{' '}
          <strong>Excel</strong> format.
        </Typography>
        <TypographyRegular mb={3} color="textPrimary">
          <TypographyMedium
            sx={{
              textDecoration: 'underline',
            }}
          >
            NOTE:{' '}
          </TypographyMedium>{' '}
          To ensure the upload works properly:
          <DiskList>
            <li>
              <TypographyRegular>
                Please make sure that dates of birth and dates of hire are valid
                dates
              </TypographyRegular>
            </li>
            <li>
              <TypographyRegular>
                Select <TypographyMedium>First Name</TypographyMedium> and{' '}
                <TypographyMedium>Last Name</TypographyMedium> {''} option if
                your drivers’ first and last names are currently in 2 different
                columns. Select <TypographyMedium>Full Name</TypographyMedium>{' '}
                option if the names are currently in the same column.
              </TypographyRegular>
            </li>
          </DiskList>
        </TypographyRegular>
        {!!error && (
          <FormHelperText error sx={{ mb: 0.75 }}>
            {error}
          </FormHelperText>
        )}
        <Grid container spacing={4} flexWrap="nowrap">
          <Grid item>
            <SelectableCard
              className={clsx({
                [classes.selected]: selectedTab === 0,
              })}
            >
              <CardActionArea
                onClick={() => {
                  setError('');
                  setSelectedTab(0);
                }}
              >
                <Grid container direction="column" p={2} spacing={2}>
                  <Grid item>
                    <TabLabel>
                      <TabRadio size="small" checked={selectedTab === 0} />
                      <Typography
                        variant="body1"
                        color={selectedTab === 0 ? 'text.primary' : 'text.hint'}
                      >
                        Use First and Last Name
                      </Typography>
                    </TabLabel>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <TypographyRegular>
                      First and Last Name template{' '}
                      <Link
                        variant="body1"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/Nirvana_upload_template.xlsx"
                      >
                        download
                      </Link>
                    </TypographyRegular>
                  </Grid>

                  <Grid item>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={'center'}
                    >
                      <img src={FullNameImage} />
                    </Box>
                  </Grid>
                </Grid>
              </CardActionArea>
            </SelectableCard>
          </Grid>

          <Box display={'flex'} ml={4} mt={4}>
            <Divider orientation="vertical" flexItem />
          </Box>

          <Grid item>
            <SelectableCard
              className={clsx({
                [classes.selected]: selectedTab === 1,
              })}
            >
              <CardActionArea
                onClick={() => {
                  setError('');
                  setSelectedTab(1);
                }}
              >
                <Grid container direction="column" p={2} spacing={2}>
                  <Grid item>
                    <TabLabel>
                      <TabRadio size="small" checked={selectedTab === 1} />
                      <Typography
                        variant="body1"
                        color={selectedTab === 1 ? 'text.primary' : 'text.hint'}
                      >
                        Use Full Name
                      </Typography>
                    </TabLabel>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <TypographyRegular>
                      Full Name template{' '}
                      <Link
                        variant="body1"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/Nirvana_upload_template_full_name.xlsx"
                      >
                        download
                      </Link>
                    </TypographyRegular>
                  </Grid>
                  <Grid item>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={'center'}
                    >
                      <img src={FirstAndLastNameImage} />
                    </Box>
                  </Grid>
                </Grid>
              </CardActionArea>
            </SelectableCard>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DriversListDialog;
