import * as React from 'react';
import {
  Drawer as MuiDrawer,
  makeStyles,
  Box,
  IconButton,
} from '@material-ui/core';

import { ITheme } from 'src/assets/themes';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme: ITheme) => ({
  drawer: {
    flexShrink: 0,
  },
  paper: {
    width: theme.typography.pxToRem(413),
    boxShadow: '-4px 4px 4px rgba(0, 0, 0, 0.07)',
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

export interface IDrawerProps {
  children: React.ReactNode;
  open: boolean;
  anchor: 'left' | 'bottom' | 'right' | 'top' | undefined;
  onClose: () => void;
}

/**
 * The drawer UI component, which is an extension of Mui Drawer, styled to our theme.
 * Refer: https://next--material-ui.netlify.app/components/drawers/ for usage and props
 * @component
 *
 * @param {Object} props
 * @param {Function} [props.onClose] - Callback invoked when cancel/close button is clicked.
 *
 * @example
 * return <Drawer open={true} anchor={['Start', 'End']}></Drawer>
 */
const Drawer = ({
  children,
  open,
  anchor = 'right',
  onClose,
}: IDrawerProps) => {
  const classes = useStyles();
  return (
    <MuiDrawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      classes={{
        root: classes.drawer,
        paper: classes.paper,
      }}
    >
      <Box p={4} position="relative" flexGrow={1}>
        <IconButton
          size="small"
          onClick={onClose}
          className={classes.closeIcon}
        >
          <Close />
        </IconButton>
        {children}
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
