import { format, parseISO } from 'date-fns';
import { AdmittedAppDriverDetails } from '@nirvana/api/non-fleet';
import { TableView } from 'src/components/table';

import Section, {
  SectionCommonProps,
  SectionDataRenderParams,
} from '../section';

export interface IDriversSectionProps extends SectionCommonProps {
  onEdit: (key: string) => void;
}

const DriversSection = ({
  onEdit,
  applicationData,
  ...rest
}: IDriversSectionProps) => {
  return (
    <Section
      {...rest}
      applicationData={applicationData}
      title="Drivers"
      onEdit={() => onEdit('drivers')}
      data={[
        {
          key: 'vin',
          label: '',
          getValue: (data) => {
            return data.driversForm?.drivers;
          },
          renderValue: ({ value }: SectionDataRenderParams) => {
            return (
              <TableView
                headers={[
                  {
                    key: 'licenseNumber',
                    content: 'CDL Number',
                    width: '16%',
                  },
                  {
                    key: 'name',
                    content: 'Driver’s Full Name',
                    width: '16%',
                  },
                  {
                    key: 'licenseState',
                    content: 'License State',
                    width: '10%',
                  },
                  {
                    key: 'yearsOfExp',
                    content: 'Years of CDL',
                    width: '11%',
                  },
                  {
                    key: 'dateOfBirth',
                    content: 'Date of Birth',
                    width: '15%',
                  },
                  {
                    key: 'dateOfHire',
                    content: 'Date of Hire',
                    width: '15%',
                  },
                  {
                    key: 'violationInLastThreeYears',
                    content: 'Any accidents / violations in the past 3 years',
                    width: '17%',
                  },
                ]}
                rows={value?.map((record: AdmittedAppDriverDetails) => ({
                  columns: [
                    {
                      content: `${record.licenseNumber}`,
                    },
                    {
                      content: `${record.firstName} ${record.lastName}`,
                    },
                    {
                      content: `${record.licenseState}`,
                    },
                    {
                      content: `${record.yearsOfExp}`,
                    },
                    {
                      content: record.dateOfBirth
                        ? `${format(
                            parseISO(record.dateOfBirth),
                            'MM/dd/yyyy',
                          )}`
                        : '',
                    },
                    {
                      content: record.dateOfHire
                        ? `${format(parseISO(record.dateOfHire), 'MM/dd/yyyy')}`
                        : '',
                    },
                    {
                      content: `${
                        record.violationInLastThreeYears ? 'Yes' : 'No'
                      }`,
                    },
                  ],
                }))}
              />
            );
          },
        },
      ]}
    />
  );
};

export default DriversSection;
