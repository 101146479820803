import * as React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Box,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { ApplicationDetail, QuoteData } from '@nirvana/api/quoting';

import IconEdit from 'src/assets/icons/edit-info.svg';
import IconSyncExclamation from 'src/assets/icons/sync-exclamation.svg';
import Button from 'src/components/button';

import { useStyles } from './styles';

export interface SectionCommonProps {
  isEditable: boolean;
  showChanges: boolean;
  applicationData: ApplicationDetail;
  quoteData?: QuoteData;
  hasMTC: boolean;
}

export type SectionDataRenderParams = {
  value: any;
  previousValue?: any;
  hasChanged?: boolean;
};

export type SectionDataRecord = {
  key: string;
  label?: string;
  getValue?: (data: ApplicationDetail) => any;
  renderValue?: (data: SectionDataRenderParams) => React.ReactNode;
  type?: 'default' | 'divider';
};

type SectionProps = {
  data: Array<SectionDataRecord>;
  onEdit: () => void;
  title: string;
  applicationData: ApplicationDetail;
  isEditable?: boolean;
  showChanges?: boolean;
  quoteData?: QuoteData;
  footer?: React.ReactNode;
};

const Section = ({
  data,
  onEdit,
  title,
  applicationData,
  isEditable,
  showChanges,
  quoteData,
  footer,
}: SectionProps) => {
  const classes = useStyles();
  const fieldsChangedByUW: Array<string> = quoteData?.fieldsChangedByUW ?? [];
  const finalData: ApplicationDetail | undefined = quoteData?.finalAppDetails;

  return (
    <Grid item>
      <Card classes={{ root: classes.card }}>
        <CardHeader
          title={title}
          action={
            !!isEditable && (
              <Button
                type="button"
                onClick={onEdit}
                startIcon={<img src={IconEdit} alt="Edit" />}
                className={classes.editButton}
              >
                Edit
              </Button>
            )
          }
          titleTypographyProps={{
            variant: 'h5',
            color: 'textPrimary',
          }}
          classes={{
            root: classes.cardHeader,
            content: classes.cardHeaderContent,
          }}
        />
        <CardContent>
          <Grid container direction="column" spacing={3}>
            {data.map((record: SectionDataRecord) => {
              const hasUWChanges =
                showChanges && fieldsChangedByUW.includes(record.key);
              let value: any;
              let previousValue: any;
              if (hasUWChanges && !!finalData) {
                previousValue = record.getValue?.(applicationData);
                value = record.getValue?.(finalData);
              } else {
                value = record.getValue?.(applicationData);
              }

              return (
                <Grid
                  key={record.key}
                  item
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="flex-start"
                  spacing={3}
                >
                  {record.type === 'divider' ? (
                    <Grid item sx={{ width: '100%' }}>
                      <Divider />
                    </Grid>
                  ) : (
                    <>
                      {record.label && (
                        <Grid item xs={4}>
                          <Box display="flex" alignItems="center">
                            <InputLabel className={classes.inputLabel}>
                              {record.label}
                            </InputLabel>
                            &nbsp;
                            {hasUWChanges && (
                              <img src={IconSyncExclamation} alt="UW changes" />
                            )}
                          </Box>
                        </Grid>
                      )}
                      {/* Support for rendering in section without a label */}
                      <Grid item xs={record.label ? 8 : 12}>
                        <Typography variant="body2" color="secondary">
                          {record.renderValue?.({
                            value,
                            previousValue,
                            hasChanged: hasUWChanges,
                          })}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
        {!!footer && <Grid classes={{ root: classes.footer }}>{footer}</Grid>}
      </Card>
    </Grid>
  );
};

export default Section;
