import { ReactNode } from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  CoverageRecord,
  CoverageType,
  IndicationOption,
} from '@nirvana/api/quoting';
import NumberFormat from 'react-number-format';

import Button from 'src/components/button';
import IconCheckPrimary from 'src/assets/icons/check-primary.svg';
import { application as ApplicationHelper } from 'src/helpers';
import {
  getOrderedCoverages,
  isCoveragePrimary,
} from 'src/helpers/application';

import { useStyles } from './styles';

export interface PrimaryCoverageInfo extends CoverageRecord {
  name: string;
}

const getPrimaryCoverageInfo = (
  list: CoverageRecord[],
  type: string,
): PrimaryCoverageInfo => {
  for (let i = 0, l = list.length; i < l; i += 1) {
    if (list[i].coverageType === type) {
      return {
        ...list[i],
        name: `${ApplicationHelper.getIndicationOptionsFeatureLabel(
          list[i].coverageType,
        )}`,
      };
    }
  }

  return {} as PrimaryCoverageInfo;
};

export interface AdditionalCoverageInfo {
  name: string;
  secondary: ReactNode;
}

const getAdditionalCoverages = (
  list: CoverageRecord[],
): AdditionalCoverageInfo[] => {
  let includedStatutoryCoverages = false;
  const coverages = [];
  const otherInclusions = [];
  const blanketAdditionalCoverages = (list || [])
    .filter((record) => {
      return (
        record.coverageType === CoverageType.CoverageBlanketAdditional ||
        record.coverageType === CoverageType.CoverageGlBlanketAdditional ||
        record.coverageType === CoverageType.CoverageMtcBlanketAdditional
      );
    })
    .map((record) => record.coverageType);
  const blanketWaiverCoverages = (list || [])
    .filter((record) => {
      return (
        record.coverageType ===
          CoverageType.CoverageBlanketWaiverOfSubrogation ||
        record.coverageType ===
          CoverageType.CoverageGlBlanketWaiverOfSubrogation ||
        record.coverageType ===
          CoverageType.CoverageMtcBlanketWaiverOfSubrogation
      );
    })
    .map((record) => record.coverageType);

  for (let i = 0, l = list.length; i < l; i += 1) {
    if (
      list[i].coverageType === CoverageType.CoverageAutoLiability ||
      list[i].coverageType === CoverageType.CoverageAutoPhysicalDamage ||
      list[i].coverageType === CoverageType.CoverageMotorTruckCargo ||
      list[i].coverageType === CoverageType.CoverageGeneralLiability ||
      list[i].coverageType === CoverageType.CoverageBlanketAdditional ||
      list[i].coverageType === CoverageType.CoverageBlanketWaiverOfSubrogation
    ) {
      continue;
    }
    if (
      ApplicationHelper.isCoverageStatutory(list[i].coverageType) &&
      !includedStatutoryCoverages
    ) {
      coverages.push({
        name: ApplicationHelper.getStatutoryCoveragesLabel(),
        secondary:
          '(UM/UIM/PIP/PPI/Med Pay) Offered at statutory minimum limits only where available',
      });
      includedStatutoryCoverages = true;
    } else if (
      list[i].coverageType === CoverageType.CoverageTrailerInterchange
    ) {
      otherInclusions.push(
        <div>
          {ApplicationHelper.getIndicationOptionsFeatureLabel(
            list[i].coverageType,
          )}{' '}
          - Limit{' '}
          <NumberFormat
            value={list[i].limit}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </div>,
      );
    } else if (!ApplicationHelper.isCoverageStatutory(list[i].coverageType)) {
      otherInclusions.push(
        <div>
          {ApplicationHelper.getIndicationOptionsFeatureLabel(
            list[i].coverageType,
          )}
        </div>,
      );
    }
  }

  if (blanketAdditionalCoverages.length) {
    otherInclusions.push(
      <div>{ApplicationHelper.getBlanketCoverageLabel('additional')}</div>,
    );
  }

  if (blanketWaiverCoverages.length) {
    otherInclusions.push(
      <div>{ApplicationHelper.getBlanketCoverageLabel('waiver')}</div>,
    );
  }

  if (otherInclusions.length) {
    coverages.push({
      name: 'Additional Coverages',
      secondary: otherInclusions,
    });
  }

  return coverages;
};

type IndicationOptionWithoutPriceProps = {
  data: IndicationOption;
  value: string;
  onChange: (value: string) => void;
};

const IndicationOptionWithoutPrice = ({
  data,
  value,
  onChange,
}: IndicationOptionWithoutPriceProps) => {
  const classes = useStyles();

  const primaryCoverages = getOrderedCoverages(
    data.coverages
      .filter((record) => isCoveragePrimary(record.coverageType))
      .map((record) => record.coverageType),
  ).map((record) => getPrimaryCoverageInfo(data.coverages, record));

  const additionalCoverages = getAdditionalCoverages(data.coverages);

  return (
    <Grid item flexGrow={1} style={{ display: 'flex', flex: 1 }}>
      <Card
        variant="outlined"
        className={clsx(classes.card, 'cursor-pointer', {
          [classes.selected]: value === data.id,
          [classes.recommended]: data?.isRecommended,
        })}
        onClick={() => {
          onChange(data.id);
        }}
      >
        {data?.isRecommended ? (
          <Box className={classes.cardChip}>
            <Typography variant="caption" fontWeight="600">
              Recommended
            </Typography>
          </Box>
        ) : null}

        <CardHeader
          title={
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h6"
                color="textPrimary"
                fontWeight="fontWeightBold"
              >
                {ApplicationHelper.getIndicationOptionTagLabel(data.optionTag)}
              </Typography>
              <div className={classes.cardHeaderBar} />
            </Box>
          }
          subheader={
            <Grid item>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                sx={
                  value === data.id
                    ? {
                        backgroundColor: () => '#D7DCFE !important',
                        marginTop: (theme) => theme.spacing(2),
                        '&:hover': {
                          backgroundColor: () => '#D7DCFE',
                        },
                      }
                    : {
                        marginTop: (theme) => theme.spacing(2),
                      }
                }
                fullWidth
                size="large"
              >
                {value === data.id ? 'Selected' : 'Select'}
              </Button>
            </Grid>
          }
          titleTypographyProps={{
            className:
              value === data.id ? classes.selectedTitle : classes.title,
            variant: 'h6',
            fontWeight: 'regular',
          }}
          subheaderTypographyProps={{
            variant: 'h3',
            color: 'secondary',
          }}
          classes={{
            root: classes.cardHeader,
            content: classes.cardHeaderContent,
          }}
        />
        <CardContent className={classes.cardContent}>
          <Grid container direction="column" spacing={2} alignItems="center">
            {primaryCoverages.map((coverage) => {
              if (!coverage || !coverage.name) {
                return null;
              }

              return (
                <Grid
                  key={coverage.name}
                  item
                  container
                  direction="column"
                  justifyContent="center"
                >
                  <Typography
                    color="primary"
                    component="p"
                    variant="subtitle1"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <img
                      src={IconCheckPrimary}
                      className={classes.coverageIcon}
                    />
                    {coverage.name}
                  </Typography>
                </Grid>
              );
            })}

            {additionalCoverages.map((coverage) => {
              if (!coverage || !coverage.name) {
                return null;
              }

              return (
                <Grid
                  key={coverage.name}
                  item
                  container
                  direction="column"
                  justifyContent="center"
                >
                  <Typography
                    color="primary"
                    component="p"
                    variant="subtitle1"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <img
                      src={IconCheckPrimary}
                      className={classes.coverageIcon}
                    />
                    {coverage.name}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    className={classes.coverageSubtext}
                  >
                    {coverage.secondary}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default IndicationOptionWithoutPrice;
