import Panic from './panic';
import ApplicationContainer from '../applicationContainer';

const PanicWrapper = () => {
  return (
    <ApplicationContainer>
      <Panic />
    </ApplicationContainer>
  );
};

export default PanicWrapper;
