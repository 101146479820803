import * as React from 'react';
import clsx from 'clsx';
import { Grid, Typography, Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { RejectedApplicationResponse } from '@nirvana/api/quoting';

import { application as ApplicationHelper } from 'src/helpers';
import IconApplicationError from 'src/assets/icons/application-error.svg';

import { useStyles } from './styles';

export interface IAppetiteError {
  onClose: () => void;
  applicationError?: RejectedApplicationResponse;
}

const AppetiteError = ({ onClose, applicationError }: IAppetiteError) => {
  const classes = useStyles();

  const isUndesired: boolean =
    !!applicationError?.declinedRules &&
    applicationError?.declinedRules.findIndex(
      (record: any) =>
        record.rule === 'AppetiteCheckRuleHasUndesiredOperations',
    ) !== -1;

  const getOptions = (): Array<string> => {
    if (isUndesired) {
      return [
        'Logging',
        'Driveaway',
        'PPT vehicles',
        'Auto-haulers',
        'Livestock',
        'Dump',
        'Waste',
        'Hazardous Materials',
        'Hot Shot',
        'Team-driving',
        'Expedited/residential delivery',
      ];
    } else if (!isUndesired && applicationError?.declinedRules) {
      return applicationError.declinedRules.map((resp) =>
        ApplicationHelper.getAppetiteCheckRuleText(resp),
      );
    }

    return [];
  };

  return (
    <Box pt={4} position="relative" px={10} pb={2}>
      <IconButton
        className={classes.closeButton}
        onClick={onClose}
        edge="end"
        size="small"
      >
        <Close />
      </IconButton>
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid item>
          <img src={IconApplicationError} alt="Application Error" />
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            color="textPrimary"
            textAlign="center"
            gutterBottom
          >
            Unfortunately, this application doesn&apos;t fit our appetite
          </Typography>
          <Typography variant="body2" color="textSecondary" textAlign="center">
            {isUndesired
              ? `Since the insured has more than 5% of operations in one of 
                the following classes:`
              : 'Since we identified the following issues:'}
          </Typography>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Box className={classes.wrapper} px={3} py={2}>
            <ul
              className={clsx(classes.reasonsList, {
                [classes.centeralize]: !isUndesired,
                [classes.splitThree]: isUndesired,
              })}
            >
              {getOptions().map((item: string, index: number) => (
                <li key={`item${index}`}>
                  <Typography
                    variant="caption"
                    fontWeight="fontWeightRegular"
                    color="textSecondary"
                  >
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppetiteError;
