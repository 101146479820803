import { Button, makeStyles } from '@material-ui/core';
import { ITheme } from '@nirvana/ui-kit';

interface EditModeFooterProps {
  onUpdate: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles((theme: ITheme) => ({
  cancelButton: {
    marginRight: theme.spacing(2),
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    height: 40,
    border: `1px solid ${theme.palette.primary.main}`,
  },

  updateButton: {
    height: 40,
  },
}));

const EditModeFooter = ({ onUpdate, onCancel }: EditModeFooterProps) => {
  const classes = useStyles();
  return (
    <div>
      <Button className={classes.cancelButton} onClick={onCancel}>
        Cancel
      </Button>

      <Button
        className={classes.updateButton}
        variant="contained"
        onClick={onUpdate}
      >
        Update
      </Button>
    </div>
  );
};

export default EditModeFooter;
