import { useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import Lottie from 'lottie-react';

import { TSPRecord } from '@nirvana/api/quoting';
import LogoNirvana from 'src/assets/logo/Nirvana_Lockup--Tertiary_Lockup.svg';
import AnimConnecting from 'src/assets/animations/connecting.json';
import { useAnalytics } from 'src/helpers/analytics';
import { LOADING_VIEW_TELEMATICS } from 'src/features/telematics/events';
import { hooks } from 'src/helpers';
import { getProviderInfo } from '@nirvana/ui-kit';

const Loader = ({
  onDone,
  selectedProvider,
}: {
  onDone: () => void;
  selectedProvider: TSPRecord;
}) => {
  const animationRef = useRef<any>(null);
  const query = hooks.useQuery();
  const { capture } = useAnalytics();
  const applicationId = query.get('token') || '';
  const providerInfo = getProviderInfo(selectedProvider.tsp);

  useEffect(() => {
    capture(LOADING_VIEW_TELEMATICS, {
      provider: selectedProvider.tsp,
      applicationId,
    });
  }, [applicationId, capture, selectedProvider.tsp]);

  useEffect(() => {
    setTimeout(() => {
      onDone();
    }, 5000);
  }, []);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" py={12}>
      <img src={LogoNirvana} alt="Nirvana" width={150} />
      <Lottie
        animationData={AnimConnecting}
        lottieRef={animationRef}
        style={{ width: '150px' }}
      />
      <img src={providerInfo.logo} alt={selectedProvider.name} width={150} />
    </Box>
  );
};

export default Loader;
