import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { Checkbox, InputZip, constants } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';

import { InputWithoutLabel as OutlinedInput } from 'src/components/input';
import AddressSearch from 'src/components/address-search';
import { decodeZipInfo } from 'src/features/admitted/queries/application';

const { usStates } = constants;

const TerminalLocation = () => {
  const {
    control,
    formState: { errors },
    register,
    getValues,
    setValue,
    watch,
  } = useFormContext();
  const terminalLocationAddressErrors = (errors.operationsForm as any)
    ?.terminalLocation?.address;
  const sameAsPhysicalAddress = watch(
    'operationsForm.terminalLocation.sameAsPhysicalAddress',
    true,
  );

  const { mutateAsync } = useMutation(decodeZipInfo);

  return (
    <>
      <Grid
        item
        container
        spacing={3}
        wrap="nowrap"
        direction="row"
        alignItems="flex-start"
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="producer">Terminal Location</InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Where are the insured’s vehicles generally parked?
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Grid item>
            <FormControl>
              <Controller
                control={control}
                defaultValue={true}
                name="operationsForm.terminalLocation.sameAsPhysicalAddress"
                render={({ field: { value, onChange } }) => {
                  return (
                    <Checkbox
                      checked={value}
                      name="ownerIsDriver"
                      onChange={(e) => {
                        const values = getValues();
                        const address =
                          values?.operationsForm?.terminalLocation?.address ||
                          {};

                        if (e.target.checked) {
                          setValue(
                            'operationsForm.terminalLocation.address',
                            address,
                          );
                        } else {
                          setValue('operationsForm.terminalLocation.address', {
                            street: '',
                            city: '',
                            state: '',
                            zip: '',
                          });
                        }

                        onChange(e);
                      }}
                    >
                      Same as Business Owner’s Address
                    </Checkbox>
                  );
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {!sameAsPhysicalAddress && (
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={5}>
            &nbsp;
          </Grid>
          <Grid item xs={3} container flexDirection={'column'} spacing={2}>
            <Grid item sx={{ mt: -4 }}>
              <Controller
                control={control}
                defaultValue=""
                name="operationsForm.terminalLocation.address.street"
                rules={{
                  required: 'Please enter street name',
                }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <FormHelperText>Street</FormHelperText>
                      <AddressSearch
                        value={value}
                        onPlaceSelect={(placeDetails) => {
                          onChange(placeDetails?.street);
                          setValue(
                            'operationsForm.terminalLocation.address.city',
                            placeDetails?.city,
                          );
                          setValue(
                            'operationsForm.terminalLocation.address.state',
                            placeDetails?.state,
                          );
                          setValue(
                            'operationsForm.terminalLocation.address.zip',
                            placeDetails?.zip,
                          );
                        }}
                      />

                      {!!(errors?.operationsForm as any)?.terminalLocation
                        ?.address?.street && (
                        <FormHelperText error>
                          {
                            (errors?.operationsForm as any)?.terminalLocation
                              ?.address?.street?.message
                          }
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item container spacing={1} wrap="nowrap">
              <Grid item>
                <FormControl>
                  <FormHelperText>City</FormHelperText>
                  <OutlinedInput
                    id="city"
                    placeholder="eg. 'Austin'"
                    error={!!terminalLocationAddressErrors?.city?.message}
                    helperText={terminalLocationAddressErrors?.city?.message}
                    {...register(
                      'operationsForm.terminalLocation.address.city',
                      { required: 'Enter city' },
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <FormHelperText>State</FormHelperText>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="operationsForm.terminalLocation.address.state"
                    rules={{ required: 'Enter state' }}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        size="small"
                        displayEmpty
                        variant="outlined"
                        error={!!terminalLocationAddressErrors?.state?.message}
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem value="">
                          <Typography color="text.hint">Select</Typography>
                        </MenuItem>
                        {usStates.map((state) => (
                          <MenuItem key={state.code} value={state.name}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {!!terminalLocationAddressErrors?.state?.message && (
                    <FormHelperText error>
                      {terminalLocationAddressErrors?.state?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <FormHelperText>Zip code</FormHelperText>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="operationsForm.terminalLocation.address.zip"
                    rules={{
                      required: 'Enter zip',
                      validate: {
                        isExtendedZip: (value) => {
                          return (
                            value.length <= 5 ||
                            'Please remove the zip code extension'
                          );
                        },
                        isValidZip: async (value) => {
                          try {
                            await mutateAsync(value);

                            return true;
                          } catch (ex) {
                            return 'Please enter a valid ZIP code';
                          }
                        },
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <InputZip
                        placeholder="eg. 12345"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        error={!!terminalLocationAddressErrors?.zip?.message}
                        helperText={terminalLocationAddressErrors?.zip?.message}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TerminalLocation;
