import { FlatfileListener } from '@flatfile/listener';
import api, { Flatfile } from '@flatfile/api';
import { recordHook, bulkRecordHook } from '@flatfile/plugin-record-hook';

import { utils as UtilsHelper } from 'src/helpers';

const getListener = ({ onComplete }: { onComplete: (data: any) => void }) => {
  let fileId: string | undefined;
  return FlatfileListener.create((listener) => {
    listener.on('**', (event) => {
      console.log(`Received event: ${event.topic}`);

      if (event.topic === 'file:created' || event.topic === 'file:updated') {
        fileId = event.context.fileId;
      }
    });

    listener.use(
      recordHook('equipment', async (record, event) => {
        const { data: space } = await api.spaces.get(event?.context.spaceId);
        const { isApdCoverage } = space.metadata;

        // Cleanup VIN
        let vin = record.get('vin');
        record.set(
          'vin',
          typeof vin === 'string'
            ? vin
                .replace(/[$,.\s]/g, '')
                .toUpperCase()
                .trim()
            : vin,
        );

        // Cleanup Stated Value
        let statedValue = record.get('statedValue');
        record.set(
          'statedValue',
          typeof statedValue === 'string'
            ? ~~statedValue.replace(/[$,]/g, '')
            : statedValue,
        );

        // TODO: Validate total stated value

        // Validate VIN
        vin = record.get('vin') as string;
        if (!UtilsHelper.isValidVIN(vin)) {
          record.addError('vin', 'VIN validation failed');
        }

        if (
          !/^(?!(T|t)otal|TOTAL|(V|v)in|VIN|(V|v)alue|VALUE)([a-zA-Z0-9])[a-zA-Z0-9]*([a-zA-Z0-9])$/.test(
            vin,
          )
        ) {
          record.addError(
            'vin',
            'A valid VIN is required for power units. For trailers without VINs, use "Trailer#" (no spaces, example "Trailer1", "Trailer2", etc...)',
          );
        }

        // Validate stated value
        statedValue = record.get('statedValue') as string;
        if (!/^(\d*\.?\d+)(,\d*\.?\d*)*$/.test(statedValue)) {
          record.addError(
            'statedValue',
            'Stated value should be a valid amount.',
          );
        }

        if (
          !/^(?!(V|v)alue|VALUE|(R|r)enewal|RENEWAL|(S|s)tated|STATED|(C|c)ost|COST|(A|a)mount)|AMOUNT/.test(
            statedValue,
          )
        ) {
          record.addError(
            'statedValue',
            isApdCoverage
              ? 'Valid stated value is required for Physical Damage. For no Physical Damage, use "0".'
              : 'Valid stated value is required for Auto Liability. For trailers without stated value, use "0".',
          );
        }

        return record;
      }),
    );

    listener.use(
      bulkRecordHook('equipment', async (records, event) => {
        const { data: space } = await api.spaces.get(event?.context.spaceId);
        const { isApdCoverage } = space.metadata;

        const totalStatedValue = records.reduce((acc, record) => {
          const statedValue = record.get('statedValue') as number;
          return acc + statedValue;
        }, 0);

        if (isApdCoverage && totalStatedValue < 1) {
          records.forEach((record) => {
            record.addError(
              'statedValue',
              'Total value of equipment must be > $0 when APD Coverage is selected',
            );
          });
        }

        return records;
      }),
    );

    listener.filter({ job: 'workbook:submitActionFg' }, (configure) => {
      configure.on('job:ready', async ({ context }) => {
        const { jobId, workbookId } = context;

        try {
          const { data: sheets } = await api.sheets.list({ workbookId });

          const records: { data: Flatfile.RecordsWithLinks } = { data: [] };
          const completeResponse: any = {
            data: [],
            meta: {
              fileId,
            },
          };
          for (const [, element] of sheets.entries()) {
            records.data = (
              await api.records.get(element.id)
            )?.data.records?.filter((record) => !!record.valid);

            completeResponse.data = records.data.map((record) => {
              const { values } = record;
              return Object.keys(values).reduce((acc, key) => {
                acc[key] = values[key].value;

                return acc;
              }, {});
            });
          }

          await api.jobs.ack(jobId, {
            info: 'Verifying data...',
            progress: 30,
          });

          await api.jobs.ack(jobId, {
            info: 'Transforming...',
            progress: 70,
          });

          // NOTE: Make changes after cells in a Sheet have been updated

          await api.jobs.complete(jobId, {
            outcome: {
              acknowledge: true,
              message: 'Uploaded successfully.',
              next: {
                type: 'wait',
              },
            },
          });

          onComplete(completeResponse);
        } catch (error: any) {
          console.error('Error:', error.stack);

          await api.jobs.fail(jobId, {
            outcome: {
              message: 'This job encountered an error.',
            },
          });
        }
      });
    });
  });
};

export default getListener;
