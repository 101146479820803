import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { Show, Switch, storage } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useLocation, useNavigate } from 'react-router-dom';
import AddIcon from 'src/assets/icons/add.svg';
import DocumentSearchIcon from 'src/assets/icons/document-search.svg';
import SparklesIcon from 'src/assets/icons/sparkles.svg';
import GlobalFilter from 'src/components/globalFilter';
import PageHeader from 'src/components/pageHeader';
import {
  INSURED_TYPE_STORAGE_KEY,
  ONBOARDING_AGENCY_INFO_FLAG,
} from 'src/constants';
import { agentDetailStatus } from 'src/features/auth/queries';
import { CHECK_APPETITE_LITE_CLICK } from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import { AccessType, Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { useDispatch } from 'src/redux';
import { resetActiveApplication } from '../../slices';
import CheckAppetiteModal from '../create/checkAppetiteModal';
import InsuredDetails from '../create/insuredDetails';
import FleetTable from './fleetTable';
import NonFleetTable from './nonFleetTable';

const getInsuredType = (applicationTypeAccess: AccessType) => {
  if (applicationTypeAccess === 'fleet') {
    return 'FLEET';
  }
  if (applicationTypeAccess === 'non-fleet') {
    return 'NON_FLEET';
  }
  return 'FLEET';
};

/**
 * Applications list page that list out all applications by the logged in user.
 * Corresponds to Landing Page (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A11817&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */

const List = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const getFeatureValue = useFeatureFlag();
  const { capture } = useAnalytics();
  const navigate = useNavigate();
  const location = useLocation();

  const applicationTypeAccess = getFeatureValue(
    Feature.APPLICATION_TYPE_ACCESS,
    'fleet',
  );

  const isAppetiteLiteEnabled = getFeatureValue(Feature.APPETITE_LITE, false);

  const [searchText, setSearchText] = useState('');
  const [selectedApplicationType, setSelectedApplicationType] = useLocalStorage<
    'FLEET' | 'NON_FLEET'
  >(INSURED_TYPE_STORAGE_KEY, getInsuredType(applicationTypeAccess));

  const insuredType =
    applicationTypeAccess === 'all'
      ? selectedApplicationType
      : getInsuredType(applicationTypeAccess);
  const [isInsuredDetailsDialogVisible, setInsuredDetailsDialogVisibility] =
    useState(false);
  const [isAppetiteCheckDialogVisible, setAppetiteCheckDialogVisibility] =
    useState(false);

  useQuery(['agent-details'], () => agentDetailStatus(), {
    onSuccess: (data) => {
      const agencyInfoFlag = storage.get(ONBOARDING_AGENCY_INFO_FLAG);
      if (data && !data.isDetailsComplete && !agencyInfoFlag) {
        navigate('/onboarding/agency/info', {
          state: { backgroundLocation: { pathname: '/applications/list' } },
        });
      }
    },
  });

  const handleApplicateCreate = () => {
    setInsuredDetailsDialogVisibility(true);
  };

  const handleAppetiteCheck = () => {
    if (isAppetiteLiteEnabled) {
      capture(CHECK_APPETITE_LITE_CLICK);
      setAppetiteCheckDialogVisibility(true);
      navigate('/applications/list?appetiteChecker=1');
    }
  };

  const handleAppetiteCheckClose = (navigateBack = false) => {
    setAppetiteCheckDialogVisibility(false);
    if (navigateBack) {
      navigate('/applications/list', { replace: true });
    }
  };

  useEffect(() => {
    dispatch(resetActiveApplication());

    const params = new URLSearchParams(location.search);
    const openAppetiteCheck = params.get('appetiteChecker') === '1';
    if (openAppetiteCheck) {
      setAppetiteCheckDialogVisibility(true);
    }
  }, [dispatch, location.search]);

  return (
    <Box padding={4} flex={1} display="flex" flexDirection={'column'}>
      <Container
        maxWidth="lg"
        sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <Grid container direction="column" spacing={2} flexGrow={1}>
          <Grid item>
            <PageHeader
              title={
                <>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="fontWeightBold"
                    marginBottom={3}
                  >
                    Applications
                  </Typography>
                  <div className="flex gap-3">
                    <GlobalFilter value={searchText} onChange={setSearchText} />
                    <Show when={applicationTypeAccess === 'all'}>
                      <div className="flex py-1">
                        <Divider orientation="vertical" flexItem />
                      </div>
                      <FormControl variant="outlined">
                        <InputLabel>Program</InputLabel>
                        <Select
                          label="Program"
                          className="w-[220px]"
                          variant="outlined"
                          value={insuredType}
                          onChange={(e) => {
                            setSelectedApplicationType(
                              e.target.value as 'FLEET' | 'NON_FLEET',
                            );
                          }}
                          renderValue={(value) => {
                            switch (value) {
                              case 'FLEET':
                                return 'Fleet';
                              case 'NON_FLEET':
                                return 'Non-fleet';
                              default:
                                return '';
                            }
                          }}
                        >
                          <MenuItem value="FLEET">Fleet (10+ PUs)</MenuItem>
                          <MenuItem value="NON_FLEET">
                            Non-fleet (1-9 PUs)
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Show>
                  </div>
                </>
              }
              actions={
                <>
                  <Show when={insuredType === 'FLEET' && isAppetiteLiteEnabled}>
                    <div className="flex flex-row items-start w-full p-2 mb-4 border rounded-lg border-gold-main">
                      <div className="p-1 mx-2 rounded-lg bg-gold-tint1-opacity-35">
                        <img src={SparklesIcon} className="w-6 h-6" />
                      </div>
                      <div className="flex flex-col">
                        <span className="font-semibold">
                          Preview risk for a DOT (Fleets 10+ units)
                        </span>
                        <span>
                          See how an account&apos;s FMCSA carrier and insurance
                          data
                          <br />
                          affects quote considerations.
                        </span>
                      </div>
                    </div>
                  </Show>
                  <div className="flex space-x-2">
                    <Show
                      when={insuredType === 'FLEET' && isAppetiteLiteEnabled}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        size="large"
                        startIcon={<img src={DocumentSearchIcon} />}
                        onClick={handleAppetiteCheck}
                        className="w-56"
                      >
                        Check Appetite
                      </Button>
                    </Show>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      startIcon={<img src={AddIcon} alt="+" />}
                      onClick={handleApplicateCreate}
                      data-attr="posthog-create-new-application"
                      className="w-56"
                    >
                      New Application
                    </Button>
                  </div>
                </>
              }
            />
          </Grid>
          <Grid item flexGrow={1}>
            <Paper variant="outlined" className="h-full">
              <Switch>
                <Switch.Match when={insuredType === 'FLEET'}>
                  <FleetTable searchText={searchText} />
                </Switch.Match>

                <Switch.Match when={insuredType === 'NON_FLEET'}>
                  <NonFleetTable searchText={searchText} />
                </Switch.Match>
              </Switch>
            </Paper>
          </Grid>
        </Grid>
        <FormProvider {...methods}>
          <InsuredDetails
            open={isInsuredDetailsDialogVisible}
            onClose={() => setInsuredDetailsDialogVisibility(false)}
            openDialog={setInsuredDetailsDialogVisibility}
            accessType={applicationTypeAccess}
          />
          <Show when={isAppetiteCheckDialogVisible}>
            <CheckAppetiteModal
              open={isAppetiteCheckDialogVisible}
              onClose={handleAppetiteCheckClose}
            />
          </Show>
        </FormProvider>
      </Container>
    </Box>
  );
};

export default List;
