import * as React from 'react';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ApplicationHeader } from '@nirvana/ui-kit';

import AnimSearching from 'src/assets/animations/searching.json';

import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';

const Panic = () => {
  const searchingAnimRef = React.useRef<any>(null);
  const { show } = useIntercom();
  const navigate = useNavigate();
  const { applitionHeaderInfo } = useApplicationDetailsContext();

  return (
    <>
      <ApplicationHeader
        details={applitionHeaderInfo}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={9}
        >
          <Grid item>
            <Lottie
              animationData={AnimSearching}
              lottieRef={searchingAnimRef}
              style={{ width: '150px' }}
            />
          </Grid>
          <Grid item>
            <Box maxWidth="350px">
              <Typography
                variant="h5"
                color="textPrimary"
                lineHeight="32px"
                mb={1}
              >
                It&apos;ll take us a little longer to generate an indication
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.hint"
                lineHeight="24px"
                mb={5}
              >
                We&apos;ll notify you once it&apos;s ready! If you have any
                questions in the meanwhile, please contact us here.
              </Typography>
              <Button
                variant="outlined"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  show();
                }}
              >
                Contact us
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Panic;
